import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
    allReleaseAndTrackNames,
    allCountries,
    allStores,
    salesByReleaseSelector,
    salesByCountrySelector,
    salesByStoreSelector,
    salesByTracksSelector,
    totalSalesSelector,
    selectedReleaseSelector,
    selectedCountrySelector,
    selectedDspSelector,
    getOverviewRevenueFor,
    getOverviewDspsByYearAndMonth,
    salesByTrackSelector,
    getTotalRevenue,
} from 'selectors/sales'

import {
    fetchSalesBreakdown,
    getSalesOverview,
} from 'actions/SaleActions'

import { fetchCountries } from 'actions/CountryActions'

import SalesReportDetailed from 'components/SalesReportDetailed/SalesReportDetailed'
import { AppStoreState } from 'store/store-types'

type RouteProps = RouteComponentProps<{
    year: string,
    month: string
}>
const mapStateToProps = (state: AppStoreState, props: RouteProps) => {
    const { match: { params: { year, month } } } = props
    const isAllTimeReport = (typeof year === 'undefined' && typeof month === 'undefined')
    const selectedDetailedYear = !isAllTimeReport ? Number(year) : null
    const selectedDetailedMonth = !isAllTimeReport ? Number(month) : null
    return {
        isLoading: state.sales.isFetchingBreakdown || state.countries.isFetching,
        failedFetchingBreakdown: state.sales.failedFetchingBreakdown,
        failedFetchingOverview: state.sales.failedFetchingOverview,
        selectedRelease: selectedReleaseSelector(state),
        selectedCountry: selectedCountrySelector(state),
        selectedDsp: selectedDspSelector(state),
        overviewRevenue: getOverviewRevenueFor({
            year: selectedDetailedYear,
            month: selectedDetailedMonth,
        })(state),
        totalRevenue: getTotalRevenue(state),
        overviewDspYrMonth: getOverviewDspsByYearAndMonth(selectedDetailedYear, selectedDetailedMonth)(state),
        totalSales: totalSalesSelector(state),
        salesByRelease: salesByReleaseSelector(state),
        salesByTracks: salesByTracksSelector(state),
        salesByTrack: salesByTrackSelector(state),
        salesByCountry: salesByCountrySelector(state),
        salesByStore: salesByStoreSelector(state),
        allReleaseAndTrackNames: allReleaseAndTrackNames(state),
        allCountries: allCountries(state),
        allStores: allStores(state),
        selectedDetailedMonth,
        selectedDetailedYear,
        isAllTimeReport,
        balanceErrors: state.wallet.balanceErrors,
    }
}

const mapDispatchToProps = {
    fetchCountries,
    getSalesOverview,
    fetchSalesBreakdown,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export type SalesReportDetailedContainerProps = ConnectedProps<typeof connector> & RouteProps
export default withRouter(connector(SalesReportDetailed))
