import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MfaFrame from 'components/mfa/MfaFrame'
import Button from 'components/Button/Button'
import PhoneInputGroup from 'containers/PhoneInputGroup/PhoneInputGroup'
import countryPrefixPropType from 'constants/CountryPrefixPropType'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { fetchCountriesPrefixes as fetchCountriesPrefixesAction } from 'actions/CountryPrefixActions'
import { connect } from 'react-redux'
import { propTypes as formPropTypes, reduxForm } from 'redux-form'
import { mfaPhoneFormName } from 'constants/MfaFormNames'
import { validatePhone } from 'validations/mfa'
import { getCountryPrefixOption } from 'selectors/countriesPrefixes'

const defaultCountryCode = 'US'

const EnterPhone = ({
    handleSubmit,
    invalid,
    componentTitle,
    closeButton,
    displayCloseButton = true,
    isLoading,
    initialSelectedCountry,
    fetchCountriesPrefixes,
}) => {
    const { t } = useLocaleContext()

    useEffect(() => {
        fetchCountriesPrefixes()
    }, [])

    return (
        <div className="v-mfa">
            <div className="v-mfa-text">
                {componentTitle}
            </div>
            <MfaFrame>
                <form className="v-mfa-content" onSubmit={handleSubmit}>
                    <TranslateMarkdown id="viewMfaEnterPhoneDescription" />

                    <PhoneInputGroup
                        label={t('viewMfaEnterPhoneLabel')}
                        numberPlaceholder={t('viewMfaEnterPhoneNumberPlaceholder')}
                        isLoading={isLoading}
                        disableCountryCode
                        initialSelectedCountry={initialSelectedCountry}
                    />

                    <PhoneInputGroup
                        label={t('viewMfaEnterPhoneConfirmationLabel')}
                        numberPlaceholder={t('viewMfaEnterPhoneNumberConfirmationPlaceholder')}
                        isLoading={isLoading}
                        isConfirmation
                        initialSelectedCountry={initialSelectedCountry}
                    />

                    <p className="v-mfa-security-info">{t('viewMfaEnterPhoneSecurityInfo')}</p>

                    <div className="v-mfa-buttons-wrapper">
                        <Button
                            isSubmit
                            isLoading={isLoading}
                            disabled={isLoading || invalid}
                            className="v-mfa-button"
                        >
                            {t('viewMfaButtonContinue')}
                        </Button>
                        {displayCloseButton && closeButton}
                    </div>
                </form>
            </MfaFrame>
        </div>
    )
}

EnterPhone.propTypes = {
    componentTitle: PropTypes.node.isRequired,
    closeButton: PropTypes.node,
    displayCloseButton: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    initialSelectedCountry: PropTypes.shape(countryPrefixPropType),
    fetchCountriesPrefixes: PropTypes.func.isRequired,
    ...formPropTypes,
}

export const mapStateToProps = (state, ownProps) => ({
    initialSelectedCountry: getCountryPrefixOption(ownProps.userRegisteredCountry || defaultCountryCode)(state),
    initialValues: {
        phonePrefixCountryCode: ownProps.userRegisteredCountry || defaultCountryCode,
    },
})

const mapDispatchToProps = {
    fetchCountriesPrefixes: fetchCountriesPrefixesAction,
}

export const EnterPhoneForm = reduxForm({
    form: mfaPhoneFormName,
    validate: validatePhone,
    enableReinitialize: true,
})(EnterPhone)

export default connect(mapStateToProps, mapDispatchToProps)(EnterPhoneForm)
