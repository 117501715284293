import { REHYDRATE } from 'redux-persist/constants'
import * as types from 'constants/PostActionTypes'
import { ApiPost } from 'models/api/post'
import { AnyAction } from 'redux'

type PostsReducer = {
    isFetching: boolean
    posts: ApiPost[]
    fetchedAt: number | Date
}

const initialState: PostsReducer = {
    isFetching: false,
    posts: [] as ApiPost[],
    fetchedAt: 0,
}

export default function posts(state = initialState, action: AnyAction): PostsReducer {
    switch (action.type) {
        // FETCH

        case types.FETCH_POSTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_POSTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                posts: action.payload.map((p: any) => p as ApiPost), // postCollection.fromArray(action.payload),
                fetchedAt: new Date(),
            }

        case types.FETCH_POSTS_FAILURE:
            return {
                ...state,
                isFetching: false,
            }

            // REHYDRATE

        case REHYDRATE:
            if (action.payload.posts) {
                return {
                    ...action.payload.posts,
                    posts: action.payload.posts.posts,
                }
            }
            return state

            // DEFAULT

        default:
            return state
    }
}
