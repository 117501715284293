import { REHYDRATE } from 'redux-persist/constants'
import moment from 'moment'
import * as types from 'constants/AuthActionTypes'
import * as userTypes from 'constants/UserActionTypes'
import { authViewNames } from 'constants/MfaRoutes'
import { types as mfaTypes } from 'actions/MfaActions'

function formatCredentials(credentials) {
    return {
        ...credentials,
        expiresAt: parseInt(moment().add(credentials.expiresIn, 'seconds').format('x'), 10),
    }
}

const initialState = {
    isAuthenticated: false,
    isAuthenticating: false,
    isRefreshing: false,
    isBlocked: false,
    credentials: {},
    clientCredentials: {},
    error: false,
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        // AUTH

        case types.AUTH_REQUEST:
            return {
                ...state,
                isAuthenticating: true,
                isBlocked: false,
                onMfaStep: false,
                isVerifyingMfaCode: false,
                error: false,
            }

        case types.AUTH_SUCCESS:
            const responseContainsAuthToken = !!(action.meta.view && action.meta.view === authViewNames.viewOAuthToken)
            const newState = {
                ...state,
                isAuthenticated: responseContainsAuthToken,
                isAuthenticating: false,
                isBlocked: false,
                onMfaStep: !responseContainsAuthToken,
                isVerifyingMfaCode: false,
            }

            if (responseContainsAuthToken) {
                newState.credentials = formatCredentials(action.payload)
            }

            return newState

        case types.AUTH_FAILURE:
            const isBlocked = (
                typeof action.error === 'object'
                && Object.prototype.hasOwnProperty.call(action.error, 'status')
                && action.error.status === 'backoff'
                && action.error.ttl > 0
            )
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                isBlocked,
                onMfaStep: false,
                isVerifyingMfaCode: false,
                credentials: {},
                error: action.error,
            }

        case mfaTypes.MFA_VERIFY_REQUEST:
            return {
                ...state,
                isAuthenticating: false,
                isBlocked: false,
                onMfaStep: true,
                isVerifyingMfaCode: true,
                error: false,
            }

        case mfaTypes.MFA_VERIFY_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isAuthenticating: false,
                isBlocked: false,
                onMfaStep: false,
                isVerifyingMfaCode: false,
                credentials: formatCredentials(action.payload),
            }

        case mfaTypes.MFA_VERIFY_FAILURE:
            const isBlockedMfa = (
                typeof action.error === 'object'
                && Object.prototype.hasOwnProperty.call(action.error, 'status')
                && action.error.status === 'backoff'
                && action.error.ttl > 0
            )
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: false,
                isBlocked: isBlockedMfa,
                onMfaStep: true,
                isVerifyingMfaCode: false,
                credentials: {},
                error: action.error,
            }

            // REFRESH

        case types.REFRESH_REQUEST:
            return {
                ...state,
                isRefreshing: true,
            }

        case types.REFRESH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isRefreshing: false,
                credentials: formatCredentials(action.payload),
            }

        case types.REFRESH_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                isRefreshing: false,
                credentials: {},
            }

            // CLIENT

        case types.CLIENT_AUTH_SUCCESS:
            return {
                ...state,
                clientCredentials: formatCredentials(action.payload),
            }

            // UNAUTH

        case types.UNAUTH:
            return { ...initialState }

        case userTypes.UPDATE_USER_SUCCESS:
            if (!action.payload.emailVerified) {
                return { ...initialState }
            } else {
                return state
            }

            // UNBLOCK

        case types.BACKOFF_UNBLOCK:
            return {
                ...state,
                isBlocked: false,
            }

            // REHYDRATE

        case REHYDRATE:
            if (action.payload.auth) {
                return {
                    ...initialState,
                    isAuthenticated: action.payload.auth.isAuthenticated,
                    credentials: action.payload.auth.credentials,
                    clientCredentials: action.payload.auth.clientCredentials,
                }
            }
            return state

            // DEFAULT

        default:
            return state
    }
}
