import React, { useState } from 'react'

import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import t, { isTranslatableMarket } from 'utilities/translate'

import Button from 'components/Button/Button'
import TitleTranslationModalManager from 'containers/TitleTranslationModalManager/TitleTranslationModalManager'
import Popover from 'components/Popover/Popover'

const TranslatableInput = (props) => {
    const {
        marketId,
        value,
        showTrackTitleTranslationModal,
        showReleaseTitleTranslationModal,
        children,
        source,
        translations,
        onSave,
        disabled,
    } = props
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const isTranslateDisabled = !value || value === ''

    const displayTranslationOptions = isTranslatableMarket(marketId)

    const buttonClassName = classnames(
        'c-button-inline',
        {
            'c-button-inline-disabled': isTranslateDisabled,
        }
    )

    const handleHover = (isHover) => {
        setIsPopoverOpen(isHover)
    }

    const onButtonClick = () => {
        if (!isTranslateDisabled) {
            return (source === 'release.title') ? showReleaseTitleTranslationModal() : showTrackTitleTranslationModal()
        }
        return false
    }

    const hasTranslations = !!translations && translations.length > 0

    const inputGroup = (
        <div className="input-group">
            {
                children
            }
            <div
                className="input-group-append"
                onMouseEnter={() => {
                    handleHover(true)
                }}
                onMouseLeave={() => {
                    handleHover(false)
                }}
            >
                <Button
                    className={buttonClassName}
                    disabled={disabled}
                    onClick={onButtonClick}
                >
                    { hasTranslations ? t('editTranslationsButtonText') : t('inputTranslateButtonText')}
                </Button>
            </div>
        </div>
    )

    if (displayTranslationOptions) {
        const modalId = (source === 'release.title') ? 'translatable-release-title-input'
            : 'translatable-track-title-input'

        return (
            <div className="c-translatable-input">
                { isPopoverOpen ? (
                    <Popover
                        className="c-translatable-popover"
                        text={t('inputTranslateTooltipText')}
                        rightArrow
                    >
                        {inputGroup}
                    </Popover>
                ) : (
                    <>
                        {inputGroup}
                    </>
                )}
                <TitleTranslationModalManager
                    translations={translations}
                    data={{
                        value: value ? [value] : [],
                    }}
                    onSave={onSave}
                    modalId={modalId}
                />
            </div>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}

TranslatableInput.propTypes = {
    marketId: PropTypes.number.isRequired,
    value: PropTypes.string,
    showTrackTitleTranslationModal: PropTypes.func,
    showReleaseTitleTranslationModal: PropTypes.func,
    children: PropTypes.node,
    translations: PropTypes.array,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

export default TranslatableInput
