import * as types from 'constants/ResetActionTypes'
import { formatErrors } from 'utilities/error'

const initialState = {
    isRequesting: false,
    isReseting: false,
    errors: {},
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        // FORGOT

        case types.FORGOT_REQUEST:
            return {
                ...state,
                isRequesting: true,
                errors: {},
            }

        case types.FORGOT_SUCCESS:
            return {
                ...state,
                isRequesting: false,
            }

        case types.FORGOT_FAILURE:
            return {
                ...state,
                isRequesting: false,
                errors: formatErrors(action.error.errors),
            }

            // RESET

        case types.RESET_REQUEST:
            return {
                ...state,
                isReseting: true,
                errors: {},
            }

        case types.RESET_SUCCESS:
            return {
                ...state,
                isReseting: false,
            }

        case types.RESET_FAILURE:
            return {
                ...state,
                isReseting: false,
                errors: formatErrors(action.error.errors),
            }

            // DEFAULT

        default:
            return state
    }
}
