import { connect } from 'react-redux'
import Popup from 'components/Popup/Popup'
import { removePopup } from 'actions/PopupActions'

const mapStateToProps = state => ({
    popupData: state.popups.length ? state.popups[0] : null,
})

const mapDispatchToProps = dispatch => ({
    closePopup: id => dispatch(removePopup(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Popup)
