import * as types from 'constants/ReleaseActionTypes'
import * as releaseTypeActionTypes from 'constants/ReleaseTypeActionTypes'
import * as authTypes from 'constants/AuthActionTypes'
import { hydrateRelease } from 'utilities/hydrator'

import {
    REQUEST_SAVE,
    RECEIVE_SAVE_SUCCESS,
    RECEIVE_SAVE_FAILURE,
    RECEIVE_PATCH_SUCCESS,
    RECEIVE_PATCH_FAILURE,
} from 'modules/release'
import Release from 'models/release'
import { AnyAction } from 'redux'
import { ApiRelease } from 'models/api/release'

type ReleasesState = {
    isFetching: boolean
    isAdding: boolean
    isUpdating: boolean
    releases: Record<string, Release>
    selectedId?: number
    error: boolean | Record<string, any>
    failedFetching: boolean
}

const initialState: ReleasesState = {
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    releases: {},
    selectedId: undefined,
    error: false,
    failedFetching: false,
}

export default function releases(state = initialState, action: AnyAction): ReleasesState {
    switch (action.type) {
        case types.FETCH_RELEASES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.FETCH_RELEASES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                releases: {
                    ...state.releases,
                    ...action.payload.reduce((map: Record<string, Release>, release: ApiRelease) => ({
                        ...map,
                        [String(release.id)]: hydrateRelease(release),
                    }), {}),
                },
            }
        case types.FETCH_RELEASES_FAILURE:
            return {
                ...state,
                isFetching: false,
                failedFetching: true,
            }

        case types.FETCH_RELEASE_SUCCESS:
            return {
                ...state,
                releases: {
                    ...state.releases,
                    [String(action.payload.id)]: hydrateRelease(action.payload),
                },
            }

            // CREATE

        case types.CREATE_RELEASE_REQUEST:
            return {
                ...state,
                isAdding: true,
                error: false,
            }

        case types.CREATE_RELEASE_SUCCESS:
            return {
                ...state,
                isAdding: false,
                releases: {
                    ...state.releases,
                    [String(action.payload.id)]: hydrateRelease(action.payload),
                },
            }

        case types.CREATE_RELEASE_FAILURE:
            return {
                ...state,
                isAdding: false,
                error: true,
            }

        // UPDATE
        case REQUEST_SAVE:
        case types.UPDATE_RELEASE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            }

        case RECEIVE_SAVE_SUCCESS:
        case RECEIVE_PATCH_SUCCESS:
        case types.UPDATE_RELEASE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                releases: {
                    ...state.releases,
                    [String(action.payload.id)]: hydrateRelease(action.payload),
                },
            }

        case RECEIVE_SAVE_FAILURE:
        case RECEIVE_PATCH_FAILURE:
        case types.UPDATE_RELEASE_FAILURE:
            return {
                ...state,
                isUpdating: false,
            }

            // REMOVE

        case types.REMOVE_RELEASE_REQUEST:
        case types.ARCHIVE_RELEASE_REQUEST:
            const removeId = String(action.meta.id)
            return {
                ...state,
                releases: {
                    ...state.releases,
                    [removeId]: state.releases[removeId].clone({ isRemoving: true }),
                },
            }

        case types.REMOVE_RELEASE_SUCCESS:
        case types.ARCHIVE_RELEASE_SUCCESS:
            const updatedReleases = { ...state.releases }
            delete updatedReleases[String(action.meta.id)]

            return {
                ...state,
                releases: updatedReleases,
            }

        case types.REMOVE_RELEASE_FAILURE:
        case types.ARCHIVE_RELEASE_FAILURE:
            const failedId = String(action.meta.id)
            return {
                ...state,
                releases: {
                    ...state.releases,
                    [failedId]: state.releases[failedId].clone({ isRemoving: false }),
                },
            }

        case types.SELECT_RELEASE:
            return {
                ...state,
                selectedId: action.payload,
            }

        case types.TAKEDOWN_RELEASE_SUCCESS:
        case types.CANCEL_RELEASE_SUCCESS:
            return {
                ...state,
                releases: {
                    ...state.releases,
                    [String(action.payload.id)]: hydrateRelease(action.payload),
                },
            }

        case types.DELIVER_SOCIAL_RELEASE_REQUEST:
            return {
                ...state,
                releases: {
                    ...state.releases,
                    [String(action.meta.id)]: state.releases[String(action.meta.id)].clone({ isOptingIn: true }),
                },
            }
        case types.DELIVER_SOCIAL_RELEASE_SUCCESS:
            return {
                ...state,
                releases: {
                    ...state.releases,
                    [String(action.payload.id)]: hydrateRelease(action.payload),
                },
            }
        case types.DELIVER_SOCIAL_RELEASE_FAILURE:
            return {
                ...state,
                releases: {
                    ...state.releases,
                    [String(action.meta.id)]: state.releases[String(action.meta.id)].clone({
                        isOptingIn: false,
                        errors: {
                            socialDeliveryError: 1,
                        },
                    }),
                },
            }

        case releaseTypeActionTypes.VOUCHER_SUCCESS:
            return {
                ...state,
                [String(action.meta.releaseId)]: state.releases[String(action.meta.releaseId)].clone({
                    voucher: action.payload,
                }),
            }

        case releaseTypeActionTypes.VOUCHER_FAILURE:
            return {
                ...state,
                [String(action.meta.releaseId)]: state.releases[String(action.meta.releaseId)].clone({
                    errors: action.error.errors,
                }),
            }

        case releaseTypeActionTypes.REMOVE_VOUCHER:
            return {
                ...state,
                [String(action.meta.releaseId)]: state.releases[String(action.meta.releaseId)].clone({
                    errors: [],
                    voucher: undefined,
                }),
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
