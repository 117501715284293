import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import ReactSelect, { createFilter, components } from 'react-select'
import classnames from 'classnames'
import {
    sortBy, differenceBy, last, isEmpty,
} from 'lodash'
import { device as d } from 'device.js'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Translate from 'components/Translate/translate'
import FontSelectOption from './FontSelectOption'

export default class Select extends Component {
    static defaultOptionLabelRenderer = option => option.label

    static defaultMobileOptionLabelRenderer = (option, index) => (
        (option.indent === true) ? `${index} - ${option.label}` : option.label
    )

    selectFilter = createFilter({
        matchFrom: 'start',
        ignoreCase: true,
        ignoreAccents: true,
    })

    static propTypes = {
        placeholder: PropTypes.string,

        options: PropTypes.array.isRequired,
        importantOptionValues: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.number),
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        disableSort: PropTypes.bool,
        disableSortImportant: PropTypes.bool,
        selectImportantOptionsBy: PropTypes.string,
        sortedBy: PropTypes.oneOf(['value', 'selected', 'disabled', 'label']),
        searchable: PropTypes.bool,

        selectClassName: PropTypes.string,
        emptyValueLabel: PropTypes.string,
        isDisplayingFonts: PropTypes.bool,

        optionLabelRenderer: PropTypes.func,
        mobileOptionLabelRenderer: PropTypes.func,
        valueRenderer: PropTypes.func,
        mobileValueRenderer: PropTypes.func,
    };

    static defaultProps = {
        disableSort: false,
        disableSortImportant: true,
        sortedBy: 'label',
        importantOptionValues: [],
        searchable: false,
        isDisplayingFonts: false,
        optionLabelRenderer: Select.defaultOptionLabelRenderer,
        mobileOptionLabelRenderer: Select.defaultMobileOptionLabelRenderer,
        selectImportantOptionsBy: 'value',
    };

    dropdownIndicatorRenderer = ({ innerProps, selectProps, isDisabled }) => {
        const classes = classnames(
            'c-select__dropdown-indicator',
            {
                'c-select__dropdown-indicator--is-disabled': isDisabled,
            }
        )
        return (
            <div className={classes} {...innerProps}>
                <FontAwesomeIcon
                    icon={selectProps.menuIsOpen ? 'faChevronUp' : 'faChevronDown'}
                    fixedWidth
                />
            </div>
        )
    }

    noOptionsMessageRenderer = ({ innerProps }) => (
        <div {...innerProps} className="c-select-no-options"><Translate id="componentSelectNoResultsText" /></div>
    )

    inputRenderer = props => (
        <div className="c-select__input-wrapper">
            <components.Input {...props} />
        </div>
    )

    valueRenderer = ({ children, ...props }) => {
        const valueChildren = this.props.valueRenderer ? this.props.valueRenderer(props) : children
        return (<components.SingleValue {...props}>{valueChildren}</components.SingleValue>)
    }

    sortOptions(options) {
        const {
            sortedBy,
            disableSort,
        } = this.props

        return disableSort === true ? options : sortBy(options, sortedBy)
    }

    renderNativeOptions(sortedOptions) {
        return sortedOptions.map(this.renderNativeOption)
    }

    renderNativeOption = (option, index) => {
        const optionLabel = this.props.mobileOptionLabelRenderer(option)

        return (
            <option
                key={index}
                value={option.value}
                disabled={option.disabled}
            >
                {optionLabel}
            </option>
        )
    }

    render() {
        const {
            value,
            disabled,
            options,
            sortedBy,
            disableSort,
            disableSortImportant,
            importantOptionValues,
            onChange,
            selectClassName,
            placeholder,
            searchable,
            emptyValueLabel,
            isDisplayingFonts,
            optionLabelRenderer,
            selectImportantOptionsBy,
        } = this.props
        let importantOptions = isEmpty(options)
            ? []
            : importantOptionValues
                .map(currentValue => options.find(option => option[selectImportantOptionsBy] === currentValue))

        if (importantOptions.some(o => typeof o === 'undefined')) {
            console.warn('Select - Undefined important options, check valid values have been provided')
            importantOptions = importantOptions.filter(o => typeof o !== 'undefined')
        }

        const nonImportantOptions = differenceBy(options, importantOptions, 'value')

        const sortedImportantOptions = disableSortImportant === true
            ? importantOptions
            : sortBy(importantOptions, sortedBy)

        const sortedNonImportantOptions = disableSort === true
            ? nonImportantOptions
            : sortBy(nonImportantOptions, sortedBy)

        const lastImportantOptionValue = (last(sortedImportantOptions) || {}).value

        const optionRenderer = (option) => {
            const {
                innerProps,
                innerRef,
                isFocused,
                isDisabled,
                isSelected,
                value: currentValue, label, level,
            } = option
            return (
                <div
                    className={classnames(
                        'c-select-option', {
                            [`c-select-option-level-${level}`]: !!level,
                            '--is-disabled': isDisabled,
                            '--is-focused': isFocused,
                            '--is-selected': isSelected,
                        }
                    )}
                    {...innerProps}
                    ref={innerRef}
                >
                    {isDisplayingFonts ? (<FontSelectOption label={label} />) : optionLabelRenderer(option)}
                    {(lastImportantOptionValue !== currentValue) ? null : (
                        <div className="c-select-option-separator">
                            <hr />
                        </div>
                    )}
                </div>
            )
        }

        const emptyOption = isEmpty(emptyValueLabel) ? [] : [{
            value: '',
            label: emptyValueLabel,
        }]

        const className = classnames(
            'c-select-mobile',
            selectClassName, {
                'c-select-mobile-disabled': disabled,
            }
        )
        const collatedOptions = [
            ...emptyOption,
            ...sortedImportantOptions,
            ...sortedNonImportantOptions,
        ]
        const actualValue = collatedOptions.find(opt => opt.value === value) || null
        // && false
        return d.desktop ? (
            <ReactSelect
                options={collatedOptions}
                getOptionLabel={opt => opt.label}
                getOptionValue={opt => opt.value}
                isSearchable={searchable}
                filterOption={this.selectFilter}
                isClearable={false}
                value={actualValue}
                isDisabled={disabled}
                onChange={(newValue, { action }) => {
                    onChange(newValue.value, action, newValue)
                }}
                className={classnames(selectClassName, 'c-select-wrapper', { searchable })}
                classNamePrefix="c-select"
                placeholder={placeholder || (<Translate id="componentSelectPlaceholderDefault" />)}
                components={{
                    DropdownIndicator: this.dropdownIndicatorRenderer,
                    IndicatorSeparator: () => null,
                    Input: this.inputRenderer,
                    NoOptionsMessage: this.noOptionsMessageRenderer,
                    Option: optionRenderer,
                    SingleValue: this.valueRenderer,
                }}
            />
        ) : (
            <div
                className={className}
            >
                {
                    this.props.mobileValueRenderer ? this.props.mobileValueRenderer(value) : null
                }
                <div
                    className="c-select-mobile-handle"
                >
                    <FontAwesomeIcon
                        icon="faChevronDown"
                        fixedWidth
                    />
                </div>
                <select
                    value={value}
                    disabled={disabled}
                    onChange={event => onChange(
                        event.target.value,
                        'select',
                        options.find(o => o.value === event.target.value)
                    )}
                >
                    {this.renderNativeOptions(collatedOptions)}
                </select>
            </div>
        )
    }
}
