import { sprintf } from 'sprintf-js'

export function format(string, ...data) {
    return data.length > 0 ? sprintf(string, ...data) : string
}

export function stripChars(string, charsRegex) {
    if (!string) {
        return string
    }
    return string.replace(charsRegex, '')
}
