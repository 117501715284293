import { format } from 'utilities/string'
import { formatMarkdownString } from 'utilities/markdown'
import translations from '../../locale.json'

export type TranslationKey = keyof typeof translations
export type TranslationLanguage = 'en' | 'fr' | 'ja' | 'es' | 'de'

export function translateString(id: TranslationKey, args: (any[] | undefined), language: TranslationLanguage): any {
    const targetTranslations = translations[id]
    if (!targetTranslations) {
        return format(id, ...(args || []))
    }

    let localeString = targetTranslations[language]
    if (!localeString) {
        localeString = targetTranslations.en
    }

    return format(localeString, ...(args || []))
}

export function translateMarkdown(id: TranslationKey, args: (any[] | undefined), language: TranslationLanguage) {
    const translatedString = translateString(id, args, language)
    return formatMarkdownString(translatedString)
}
