import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ArtistAliasInput from 'components/Inputs/ArtistAliasInput'
import { userSelector } from 'selectors/users'
import Frame from 'components/Frame/Frame'
import Header from 'containers/Header/Header'
import Footer from 'containers/Footer/Footer'
import Pills from 'containers/Navs/Pills'
import Divider from 'components/Divider/Divider'
import { logoutAndRedirectTo, unauth } from 'actions/AuthActions'
import { createArtistAlias } from 'actions/ArtistAliasActions'
import {
    URL_HOME,
    URL_ONBOARDING,
    URL_ONBOARDING_TITLE,
} from 'constants/AppUrls'
import { getIdentifierByName } from 'utilities/artists'
import { EVENT_PAGE_VIEW, sendEventToDataLayer } from 'utilities/analytics'
import ArtistLookup from 'containers/ArtistLookup/ArtistLookup'
import Button from 'components/Button/Button'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { Artist } from 'models/artist'
import { addPopup } from 'actions/PopupActions'
import { getPopupDataConfirmArtistAlias } from 'utilities/popups'
import Popup from 'containers/Popup/Popup'
import ArtistAliasTranslations from 'containers/ArtistAliasTranslations/ArtistAliasTranslations'
import { isTranslatableMarket } from 'utilities/translate'

Onboarding.propTypes = {
    user: PropTypes.object.isRequired,
    marketId: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    unauthenticate: PropTypes.func.isRequired,
    createArtistAlias: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
}

function Onboarding(props) {
    const {
        unauthenticate,
        history,
        user,
        marketId,
    } = props

    const { t, tmarkdown } = useLocaleContext()

    const [artistAlias, setArtistAlias] = useState(
        null
    )

    const [artistAliasLookup, setArtistAliasLookup] = useState(null)

    const [isArtistAliasValid, setIsArtistAliasValid] = useState(false)

    const [canContinueNew, setCanContinueNew] = useState(
        false
    )

    const [canContinueExisting, setCanContinueExisting] = useState(
        false
    )

    const [isSaving, setIsSaving] = useState(
        false
    )

    const isNewArtist = user.loginCount <= 1

    useEffect(() => {
        const eventObj = {
            pagePath: URL_ONBOARDING,
            pageUrl: `${window.location.origin}${URL_ONBOARDING}`,
            pageTitle: URL_ONBOARDING_TITLE,
        }
        eventObj.userId = props.user.id
        eventObj.country = props.user.countryId
        eventObj.market = props.user.market
        sendEventToDataLayer(
            EVENT_PAGE_VIEW,
            eventObj
        )
    }, [user])

    const [musicLiveOnSpotify, setMusicLiveOnSpotify] = useState(
        null
    )

    const canContinueSaving = () => {
        if (artistAlias === null || isArtistAliasValid === false) { // If no primary artist is created
            return false
        } else if (isTranslatableMarket(marketId) && ( // If is translatable and translations are empty
            artistAlias.get('artistNameKatakana') === ''
            || artistAlias.get('artistNameOverseas') === ''
            || artistAlias.get('artistName') === ''
        )) {
            return false
        } else if (artistAlias.get('artistName') === '') { // If not translatable and artist name is empty
            return false
        }
        return true
    }

    const enableSaving = () => {
        if (canContinueSaving()) {
            setCanContinueNew(true)
            setCanContinueExisting(true)
        } else {
            setCanContinueNew(false)
            setCanContinueExisting(false)
        }
    }

    useEffect(() => {
        enableSaving()
    }, [artistAlias, isArtistAliasValid])

    const onArtistAliasValidation = (valid) => {
        setIsArtistAliasValid(valid)
    }

    const onClickLiveMusic = (href) => {
        setMusicLiveOnSpotify(href)
        setArtistAlias(null)
        setArtistAliasLookup(null)
    }

    const onChangeArtistLookup = (artist) => {
        if (!artistAlias) {
            setArtistAlias(new Artist({
                identifiers: artist.value.get('identifiers'),
                artistName: artist.value.get('artistName'),
            }))
            setArtistAliasLookup(artist)
            setIsArtistAliasValid(true)
        } else if (!Array.isArray(artist)) {
            setArtistAlias(
                artistAlias
                    .set('artistName', artist.value.get('artistName'))
                    .set('identifiers', artist.value.get('identifiers'))
            )
            setArtistAliasLookup(artist)
            setIsArtistAliasValid(true)
        } else {
            setArtistAliasLookup(null)
            setIsArtistAliasValid(false)
        }
    }

    const onChangeArtistAlias = (term) => {
        if (artistAlias) {
            setArtistAlias(artistAlias.set('artistName', term))
        } else {
            setArtistAlias(new Artist({ artistName: term }))
        }
    }

    const onChangeArtistAliasKatakana = (term) => {
        if (artistAlias) {
            setArtistAlias(artistAlias.set('artistNameKatakana', term))
        } else {
            setArtistAlias(new Artist({ artistNameKatakana: term }))
        }
    }

    const onChangeArtistAliasOverseas = (term) => {
        if (artistAlias) {
            setArtistAlias(artistAlias.set('artistNameOverseas', term))
        } else {
            setArtistAlias(new Artist({ artistNameOverseas: term }))
        }
    }

    const onAcceptArtistAliasConfirm = () => {
        setIsSaving(true)
        const spotifyIdentifer = getIdentifierByName(artistAlias.get('identifiers'), 'spotify')
        props.createArtistAlias({
            name: artistAlias.get('artistName'),
            nameKatakana: artistAlias.get('artistNameKatakana'),
            nameOverseas: artistAlias.get('artistNameOverseas'),
            spotifyId: spotifyIdentifer ? spotifyIdentifer.get('value') : null,
        })
    }

    const saveArtistAlias = () => {
        if (artistAlias) {
            props.openPopup(
                getPopupDataConfirmArtistAlias(artistAlias.get('artistName'), onAcceptArtistAliasConfirm)
            )
        }
    }

    const renderOnboardingForm = () => {
        if (musicLiveOnSpotify === null) {
            return null
        } else {
            switch (musicLiveOnSpotify) {
                case 'yes':
                    return (
                        <>
                            <ArtistLookup
                                artists={artistAliasLookup}
                                label={t('onboardingArtistName')}
                                placeholder={t('onboardingArtistNameForm')}
                                blacklistedErrorMsg={term => tmarkdown('componentFollowArtistLookupBlacklisted', term)}
                                popover={null}
                                error={null}
                                onChange={onChangeArtistLookup}
                                helpTextMsg=""
                                multi={false}
                                allowCreate={false}
                                clearable
                                onTranslationSave={() => { }}
                                artistTranslations={[]}
                                disableTranslations
                            />
                            <ArtistAliasTranslations
                                nameKatakana={artistAlias ? artistAlias.get('artistNameKatakana') : ''}
                                nameOverseas={artistAlias ? artistAlias.get('artistNameOverseas') : ''}
                                onChangeKatakana={onChangeArtistAliasKatakana}
                                onChangeOverseas={onChangeArtistAliasOverseas}
                            />
                            <Button
                                className="v-onboarding-continue-btn"
                                href="/overview"
                                disabled={!canContinueExisting || isSaving}
                                onClick={saveArtistAlias}
                            >
                                {t('onboardingArtistButton')}
                            </Button>
                        </>
                    )
                default:
                case 'no':
                    return (
                        <>
                            <ArtistAliasInput
                                value={artistAlias ? artistAlias.get('artistName') : null}
                                onValidate={onArtistAliasValidation}
                                onChange={onChangeArtistAlias}
                            />
                            <ArtistAliasTranslations
                                nameKatakana={artistAlias ? artistAlias.get('artistNameKatakana') : ''}
                                nameOverseas={artistAlias ? artistAlias.get('artistNameOverseas') : ''}
                                onChangeKatakana={onChangeArtistAliasKatakana}
                                onChangeOverseas={onChangeArtistAliasOverseas}
                            />
                            <Button
                                className="v-onboarding-continue-btn"
                                href="/overview"
                                disabled={!canContinueNew || isSaving}
                                onClick={saveArtistAlias}
                            >
                                {t('onboardingArtistButton')}
                            </Button>
                        </>
                    )
            }
        }
    }

    const liveMusicAnswers = [
        {
            href: 'yes',
            label: t('genericYes'),
            active: musicLiveOnSpotify === 'yes',
        },
        {
            href: 'no',
            label: t('genericNo'),
            active: musicLiveOnSpotify === 'no',
        },
    ]

    return (
        <div className="l-app -is-block-page">
            <Header isBlockedPage />

            <main role="main">
                <div className="v-onboarding">
                    <Frame
                        borderImageId="dune-2"
                        contentTint="light"
                    >
                        <div className="v-onboarding-content">
                            <header>
                                <h1 className="h3">
                                    {isNewArtist ? t('onboardingArtistNewTitle') : t('onboardingArtistExistingTitle')}
                                </h1>
                            </header>
                            <p>
                                {isNewArtist ? t('onboardingArtistNewSubtitle') : t('onboardingArtistExistingSubtitle')}
                            </p>
                            <hr className="c-edit-profile-separator" />
                            <div className="v-onboarding-live-music">
                                <h4>{t('onboardingArtistSpotify')}</h4>
                            </div>
                            <Pills items={liveMusicAnswers} onClick={onClickLiveMusic} mobileButtons />
                            { renderOnboardingForm() }
                            <Divider imageId="paint" />
                        </div>
                    </Frame>
                </div>
            </main>
            <Popup />
            <Footer actions={{ logout: () => logoutAndRedirectTo(URL_HOME, unauthenticate, history) }} />
        </div>
    )
}

const mapStateToProps = state => ({
    user: userSelector(state),
    marketId: state.users.user.marketId,
})

const mapDispatchToProps = {
    unauthenticate: unauth,
    createArtistAlias,
    openPopup: addPopup,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Onboarding))
