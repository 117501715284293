import React, { Component } from 'react'
import Input from './Input'

/**
 * Password Input
 *
 * Input your password
 *
 * Styleguide: components.input.password
 */
export default class PasswordInput extends Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
    }

    getValue() {
        return this.inputRef.current.getValue()
    }

    setValue(value) {
        this.inputRef.current.setValue(value)
    }

    render() {
        return <Input ref={this.inputRef} {...this.props} type="password" />
    }
}
