import { isEmpty, matchRegex, doesNotMatchRegex } from 'validations/rules'
import { slugRegex, youtubeVideoRegex } from 'constants/regularExpressions'
import { getUrlWithoutHttp } from 'utilities/url'
import t from 'utilities/translate'

const videoURLValidation = (value) => {
    if (!value || `${value}`.trim().length === 0) {
        return null
    }
    return doesNotMatchRegex(youtubeVideoRegex, t('formEditProfileYoutubeUrlValidation'))(value)
}

const videoDuplicatesValidation = (video, index, videos) => {
    const hasDuplicates = video && videos.findIndex(
        videoUrl => getUrlWithoutHttp(videoUrl) === getUrlWithoutHttp(video)
    ) !== index
    return hasDuplicates ? t('formEditProfileDuplicateVideosValidation') : null
}

const mergeVideoErrors = (...errorArrays) => errorArrays.reduce(
    (previous, current) => previous.map((error, index) => {
        if (!error) {
            return current[index]
        }
        return error
    })
)

export const validateSyncEditProfile = (values) => {
    const videos = values.videos || []
    const videoDuplicatesError = videos.map(videoURLValidation)
    const invalidVideoUrlsErrors = videos.map(videoDuplicatesValidation)

    const errors = {
        name: isEmpty(t('formEditProfileNameRequired'))(values.name),
        slug:
            isEmpty(t('formEditProfileSlugRequired'))(values.slug)
            || matchRegex(slugRegex, t('formEditProfileSlugContainsRestrictedMarks'))(values.slug),
        videos: mergeVideoErrors(videoDuplicatesError, invalidVideoUrlsErrors),
    }
    return errors
}
