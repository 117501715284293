import React from 'react'
import * as PropTypes from 'prop-types'

import t from 'utilities/translate'
import Footer from 'components/Release/Footer'
import General from 'components/Release/General'
import ArtworkContainer from 'containers/Artwork/Artwork'

const Takendown = (props) => {
    const {
        release,
    } = props

    return (
        <>
            <div className="c-release-body">
                <ArtworkContainer useGenericImage album={release.album} className="c-release-cover-art" />
                <div className="c-release-completed">
                    <General release={release} />
                </div>
            </div>
            <Footer icon="faExclamation" message={t('componentReleaseTakedownCompleted')} />
        </>
    )
}

Takendown.propTypes = {
    release: PropTypes.object.isRequired,
}

export default Takendown
