import React from 'react'
import PropTypes from 'prop-types'
import Frame from 'components/Frame/Frame'
import ClipoutText from 'components/Frame/ClipoutText'

const EditProfileInfoWidget = ({ imageId, children }) => (
    <Frame
        borderImageId={imageId}
        contentTint="light"
        contentClassName="c-edit-profile-info-widget"
    >
        <div className="c-edit-profile-info-widget-wrapper">
            <ClipoutText type="h3" backgroundImageId={imageId}>
                {children}
            </ClipoutText>
        </div>
    </Frame>
)

EditProfileInfoWidget.propTypes = {
    imageId: PropTypes.string.isRequired,
    children: PropTypes.node,
}

export default EditProfileInfoWidget
