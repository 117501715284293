// FETCH ALL

export const FETCH_SCOUTS_REQUEST = 'FETCH_SCOUTS_REQUEST'
export const FETCH_SCOUTS_SUCCESS = 'FETCH_SCOUTS_SUCCESS'
export const FETCH_SCOUTS_FAILURE = 'FETCH_SCOUTS_FAILURE'

// FETCH ONE

export const FETCH_SCOUT_REQUEST = 'FETCH_SCOUT_REQUEST'
export const FETCH_SCOUT_SUCCESS = 'FETCH_SCOUT_SUCCESS'
export const FETCH_SCOUT_FAILURE = 'FETCH_SCOUT_FAILURE'

// PUSH

export const PUSH_SCOUT_REQUEST = 'PUSH_SCOUT_REQUEST'
export const PUSH_SCOUT_SUCCESS = 'PUSH_SCOUT_SUCCESS'
export const PUSH_SCOUT_FAILURE = 'PUSH_SCOUT_FAILURE'
