import { getFormValues } from 'redux-form'
import { get, set, isNil } from 'lodash'
import moment from 'moment'

import { getTrackForms } from 'modules/common/tracks'
import { isTranslatableMarket } from 'utilities/translate'

export default (ruleMap, values, meta = {}) => Object.keys(ruleMap).reduce((messageMap, fieldName) => {
    const fieldRuleDetails = ruleMap[fieldName]
    const rules = fieldRuleDetails.rules || fieldRuleDetails

    const value = get(values, fieldName)
    let message = ''
    message = rules.reduce((previousMessage, rule) => {
        let nextMessage

        if (previousMessage) {
            nextMessage = previousMessage
        } else {
            nextMessage = rule(value, meta)
        }

        return nextMessage
    }, message)

    set(messageMap, fieldName, message)

    return messageMap
}, {})

export const required = message => value => (isNil(value) ? message : undefined)

export const isChecked = message => value => (
    value === true || value === 1 || value === '1' || value === 'on' ? undefined : message
)

export const isUnchecked = message => value => (
    value === false || value === 0 || value === '0' || value === 'off' ? undefined : message
)

export const isEmpty = message => value => (!!value && `${value}`.trim().length > 0 ? undefined : message)

export const maxLength = (max, message) => value => (value && value.length > max ? message : undefined)

export const minLength = (min, message) => value => (value && value.length < min ? message : undefined)

export const contains = (keywords, message) => (value) => {
    const pattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'ig')
    const isMatching = pattern.test(value)
    return isMatching ? message : undefined
}

export const isLessThanNumber = (minValue, message) => value => (
    parseFloat(value) < parseFloat(minValue) ? message : undefined
)

export const isLessThanOrEqualToNumber = (minValue, message) => value => (
    parseFloat(value) <= parseFloat(minValue) ? message : undefined
)

export const isGreaterThanNumber = (maxValue, message) => value => (
    parseFloat(value) > parseFloat(maxValue) ? message : undefined
)

export const isGreaterThanOrEqualToNumber = (maxValue, message) => value => (
    parseFloat(value) >= parseFloat(maxValue) ? message : undefined
)

export const isDifferentThan = (keyword, message) => (value) => {
    const isMatching = keyword.toLowerCase() === value.toLowerCase()
    return isMatching ? message : undefined
}

export const matchRegex = (regex, message) => (value) => {
    const pattern = new RegExp(regex)
    const isMatching = pattern.test(value)
    return isMatching ? message : undefined
}

export const doesNotMatchRegex = (regex, message) => (value) => {
    const pattern = new RegExp(regex)
    const isMatching = pattern.test(value || '')
    return isMatching ? undefined : message
}

export const isDateAfter = (date, message) => value => (moment(value).isAfter(date) ? undefined : message)

export const isDateSameOrAfter = (date, message) => value => (moment(value).isSameOrAfter(date) ? undefined : message)

export const validateDateOver18Years = (messageInvalidDate, messageUnder18) => (value) => {
    const hasDay = value.day && value.day.length <= 2 && !Number.isNaN(parseFloat(value.day))
    const hasMonth = value.month && value.month.length <= 2 && !Number.isNaN(parseFloat(value.month))
    const hasYear = value.year && value.year.length === 4 && !Number.isNaN(parseFloat(value.year))

    if (hasDay && hasMonth && hasYear) {
        const date = moment(`${value.year}-${value.month}-${value.day}`, ['YYYY-MM-DD'])
        if (!date.isValid()) {
            return messageInvalidDate
        }

        const validAge = moment.duration(moment().diff(date, 'years')) >= 18
        return !validAge ? messageUnder18 : undefined
    } else {
        return messageInvalidDate
    }
}

export const notMoreThanTwoTitlesAndSameVersion = message => (value, { formName, state }) => (getTrackForms(state)
    .map(formTrack => formTrack.values || {})
    .reduce((count, { title = null, recordingVersionId = -1, albumId }) => {
        const currentTrack = getFormValues(formName)(state)
        // the double equals here are to deal with string verison of the ID, if you remove it add parseInt
        const isFromTheSameAlbum = currentTrack.albumId === albumId
        const hasVersion = currentTrack.recordingVersionId
        const hasSameVersion = currentTrack.recordingVersionId === recordingVersionId
        const hasSameTitle = currentTrack.title && title && (currentTrack.title.trim() === title.trim())
        const hasNotReachTheMax = count < 2

        if (isFromTheSameAlbum && hasNotReachTheMax && hasSameTitle && hasVersion && hasSameVersion) {
            return count + 1
        }

        return count
    }, 0) > 1
    ? message
    : undefined)

const getTrackValidationData = (formName, state) => ({
    currentTrack: getFormValues(formName)(state),
    userMarketId: state.users.user.marketId,
})

const getAlbumValidationData = (formName, state) => ({
    currentAlbum: getFormValues(formName)(state),
    userMarketId: state.users.user.marketId,
})

// TRACK TITLE TRANSLATIONS
export const testHasTitleMetadataRequiredForRegion = (currentTrack, userMarketId) => {
    if (isTranslatableMarket(userMarketId)) {
        const workingTranslations = currentTrack.titleTranslations
        const savedTranslations = currentTrack.translations
        return (
            (workingTranslations && workingTranslations.length >= 2)
            || (!workingTranslations && savedTranslations && savedTranslations.length >= 2)
        )
    }

    return true
}
export const hasTitleMetadataRequiredForRegion = message => (value, { formName, state }) => {
    const { currentTrack, userMarketId } = getTrackValidationData(formName, state)
    const isValid = testHasTitleMetadataRequiredForRegion(currentTrack, userMarketId, message)
    return !isValid ? message : undefined
}

// TRACK ARTIST TRANSLATIONS
export const testHasTrackArtistNameMetadataRequiredForRegion = (currentTrack, userMarketId) => {
    if (isTranslatableMarket(userMarketId)) {
        let isValid
        if (currentTrack.artistTranslations && !Array.isArray(currentTrack.artistTranslations)) {
            const allTranslations = Object.keys(currentTrack.artistTranslations) // Per artist
            isValid = allTranslations.length === currentTrack.artists.length
                && allTranslations.map(k => Object.values(currentTrack.artistTranslations[k]).length >= 2)
                    .findIndex(v => !v) === -1
        } else {
            isValid = (currentTrack.artists || [])
                .map((artist) => {
                    const translations = artist.translations || []
                    return translations.length >= 2
                })
                .findIndex(v => !v) === -1
        }

        return isValid
    }

    return true
}
export const hasTrackArtistNameMetadataRequiredForRegion = message => (value, { formName, state }) => {
    const { currentTrack, userMarketId } = getTrackValidationData(formName, state)
    const isValid = testHasTrackArtistNameMetadataRequiredForRegion(currentTrack, userMarketId)
    return !isValid ? message : undefined
}

// ALBUM TITLE TRANSLATIONS
export const testHasAlbumTitleMetadataRequiredForRegion = (currentAlbum, userMarketId) => {
    if (isTranslatableMarket(userMarketId)) {
        const translations = currentAlbum.translations || currentAlbum.album.translations
        return translations && translations.length >= 2
    }

    return true
}
export const hasAlbumTitleMetadataRequiredForRegion = message => (value, { formName, state }) => {
    const { currentAlbum, userMarketId } = getAlbumValidationData(formName, state)
    const isValid = testHasAlbumTitleMetadataRequiredForRegion(currentAlbum, userMarketId)
    return !isValid ? message : undefined
}

// ALBUM ARTIST TRANSLATIONS
export const testHasAlbumArtistNameMetadataRequiredForRegion = (currentAlbum, userMarketId) => {
    if (isTranslatableMarket(userMarketId)) {
        let isValid
        const albumObject = currentAlbum.album

        if (albumObject.artistTranslations && !Array.isArray(albumObject.artistTranslations)) {
            const allTranslations = Object.keys(albumObject.artistTranslations)
            isValid = allTranslations.length === albumObject.artists.length
                && allTranslations.map(k => albumObject.artistTranslations[k].length >= 2).findIndex(v => !v) === -1
        } else {
            isValid = (albumObject.artists || [])
                .map((artist) => {
                    const translations = artist.translations || []
                    return translations.length >= 2
                })
                .findIndex(v => !v) === -1
        }

        return isValid
    }

    return true
}
export const hasAlbumArtistNameMetadataRequiredForRegion = message => (value, { formName, state }) => {
    const { currentAlbum, userMarketId } = getAlbumValidationData(formName, state)
    const isValid = testHasAlbumArtistNameMetadataRequiredForRegion(currentAlbum, userMarketId)
    return !isValid ? message : undefined
}
