import React from 'react'
import { Field as ReduxField } from 'redux-form'
import { PropTypes } from 'prop-types'
import EditProfileShareButton from 'components/EditProfileButtons/EditProfileShareButton'
import EditProfileViewButton from 'components/EditProfileButtons/EditProfileViewButton'
import { editProfileFields } from 'constants/EditProfileStructure'
import t from 'utilities/translate'
import { InputField, InputFieldGroup, Label } from '../EditProfileInputs/Inputs'

const debounceFunction = (() => {
    let timeout = null
    return (fn) => {
        clearTimeout(timeout)
        timeout = setTimeout(fn, 300)
    }
})()

const EditProfileArtistInfo = ({
    customSlugValidation,
    slug,
    hasPublicProfile,
    addNotification,
    openPopup,
}) => {
    const artistProfileUrl = window.env.SPINNUP_PROFILE_URL
    const url = `${artistProfileUrl}${slug}`

    return (
        <div className="c-epf-artist-info">
            <div className="c-epf-artist-name">
                <Label label={t('containerProfileSettingsLabelArtistName')} required />
                <p className="c-epf-artist-name-description">
                    {t('formEditProfileArtistNameDescription')}
                </p>
                <ReduxField
                    required
                    name={editProfileFields.baseSection.name}
                    component={InputField}
                />
            </div>
            <div className="c-epf-artist-page-url">
                <Label label={t('formEditProfileSlugLabel')} required />
                <p className="c-epf-artist-page-url-description">
                    {t('formEditProfileArtistProfileUrlDescription')}
                </p>
                <ReduxField
                    required
                    name={editProfileFields.baseSection.slug}
                    inputGroupText={artistProfileUrl}
                    autocomplete="off"
                    component={InputFieldGroup}
                    onChange={debounceFunction(customSlugValidation)}
                />
            </div>
            <div className="c-epf-artist-buttons-wrapper">
                <EditProfileViewButton url={url} hasPublicProfile={hasPublicProfile} openPopup={openPopup} />
                <EditProfileShareButton url={url} addNotification={addNotification} />
            </div>
        </div>
    )
}

EditProfileArtistInfo.propTypes = {
    customSlugValidation: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    hasPublicProfile: PropTypes.bool.isRequired,
    addNotification: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
}

export default EditProfileArtistInfo
