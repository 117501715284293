import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Truncate from 'react-truncate'
import t from 'utilities/translate'
import Button from 'components/Button/Button'
import ArtworkContainer from 'containers/Artwork/Artwork'
import Footer from 'components/Release/Footer'
import { URL_MY_RELEASES_TITLE } from 'constants/AppUrls'
import { sendEventToDataLayer } from 'utilities/analytics'

export default class Rejected extends Component {
    static propTypes = {
        release: PropTypes.object.isRequired,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        user: PropTypes.object,
    }

    static defaultProps = {
        isDisabled: false,
    }

    constructor(props) {
        super(props)

        this.state = {
            displayMore: false,
        }
    }

    handleSetDisplay(displayMore) {
        this.setState({ displayMore })
    }

    renderRejectionReason = () => {
        const {
            displayMore,
        } = this.state

        const { release } = this.props

        const rejectionReason = (release.album.comment || '')
            .split('\r\n')
            .map((commentLine, index, commentLines) => {
                const line = <span key={index}>{commentLine}</span>
                return (index === commentLines.length - 1) ? line : [line, <br key={`${index}.br`} />]
            })

        return (
            <p>
                {displayMore ? (
                    [
                        rejectionReason,
                        <a
                            className="c-release-rejected-reason-show-hide"
                            key={`read-less${release.album.id}`}
                            onClick={this.handleSetDisplay.bind(this, false)}
                        >
                            {` ${t('componentReleaseRejectedReadLess')}`}
                        </a>,
                    ]
                ) : (
                    <Truncate
                        lines={7}
                        ellipsis={(
                            <span>
                                {'... '}
                                <a
                                    className="c-release-rejected-reason-show-hide"
                                    onClick={this.handleSetDisplay.bind(this, true)}
                                >
                                    {` ${t('componentReleaseRejectedReadMore')}`}
                                </a>
                            </span>
                        )}
                    >
                        {rejectionReason}
                    </Truncate>
                )}
            </p>
        )
    }

    onEdit = () => {
        const {
            release,
            user,
        } = this.props
        const eventObj = {
            pagePath: window.location.pathname,
            pageTitle: URL_MY_RELEASES_TITLE,
            pageUrl: window.location.href,
            currencyCode: release.releaseType.price.currency,
            name: release.releaseType.name,
            id: release.releaseType.id,
            price: release.releaseType.price.amount,
        }
        if (user) {
            eventObj.userId = user.id
            eventObj.country = user.countryId
            eventObj.market = user.market
        }
        sendEventToDataLayer(
            'EditReleaseStart',
            eventObj
        )
        this.props.onEdit()
    }

    renderButton() {
        const {
            isDisabled,
        } = this.props

        if (isDisabled) {
            return null
        }

        return (
            <Button
                className="c-release-rejected-edit-link"
                onClick={this.onEdit}
                disabled={isDisabled}
            >
                {t('componentReleaseRejectedButtonResolve')}
            </Button>
        )
    }

    renderDeleteLink() {
        const {
            onDelete,
            release,
        } = this.props

        if (release.isRemoving) {
            return null
        }

        return (
            <a onClick={onDelete} className="c-release-rejected-delete-link">
                {t('componentReleaseRejectedLinkDelete')}
            </a>
        )
    }

    render() {
        const { release } = this.props

        return (
            <>
                <div className="c-release-body">
                    <ArtworkContainer album={release.album} className="c-release-cover-art" />
                    <div className="c-release-rejected">
                        <div className="c-release-rejected-heading">
                            <span>{t('componentReleaseRejectedHeader')}</span>
                        </div>

                        {this.renderRejectionReason()}
                        {this.renderButton()}
                        {this.renderDeleteLink()}
                    </div>
                </div>
                <Footer icon="faInfo" message={t('selectorAlbumStepOne')} />
            </>
        )
    }
}
