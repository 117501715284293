import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import { chartLabels } from 'utilities/date'
import t from 'utilities/translate'
import Chart from 'components/Chart/Chart'
import Spinner from 'components/Spinner/Spinner'
import TrendsIconToggle from 'components/TrendsIconToggle/TrendsIconToggle'
import ErrorOverlayMessage from 'components/ErrorOverlayMessage/ErrorOverlayMessage'

export default class TrendsChart extends Component {
    static propTypes = {
        labels: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
        toggleIcons: PropTypes.array.isRequired,
        filterOptions: PropTypes.object.isRequired,
        toggleVisibility: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isExampleData: PropTypes.bool.isRequired,
        failedFetching: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
    };

    displayLabels = (value, index) => chartLabels(this.props.filterOptions.days, value, index, this.props.labels)

    render() {
        const {
            data,
            labels,
            toggleIcons,
            toggleVisibility,
            isLoading,
            title,
            isExampleData,
            failedFetching,
        } = this.props

        const isDisabled = (failedFetching || (isExampleData && !failedFetching))

        return (
            <div className="c-trends-chart">
                <h3>{title}</h3>

                {isLoading
                    && <Spinner size="small">{t('globalLoading')}</Spinner>
                || (
                    <div>
                        <div className="c-trends-chart-filters">
                            <TrendsIconToggle
                                icons={toggleIcons}
                                toggleVisibility={toggleVisibility}
                            />
                        </div>
                        <div className="c-trends-chart-data">
                            <Chart
                                labels={labels}
                                series={data}
                                displayLabels={this.displayLabels}
                            />
                        </div>

                        <ErrorOverlayMessage
                            message={
                                isExampleData && !failedFetching
                                    ? t('trendsChartExample')
                                    : t('trendsChartUnavailable')
                            }
                            enabled={isDisabled}
                        />

                    </div>
                )}
            </div>
        )
    }
}
