import { OrderedMap } from 'immutable'

export default class Collection {
    constructor(Record, list = [], key = 'id') {
        this.Record = Record
        this.key = key
        this.map = OrderedMap({})

        this.fromArray(list)
    }

    clear() {
        this.map = OrderedMap({})

        return this
    }

    fromObject(obj) {
        const recordObj = {}

        Object.keys(obj).forEach((key) => {
            recordObj[key] = new this.Record(obj[key])
        })

        return this.merge(recordObj)
    }

    fromArray(array) {
        const obj = {}

        array.forEach((item) => {
            obj[item[this.key]] = new this.Record({
                ...this.get(item[this.key]),
                ...item,
            })
        })

        return this.merge(obj)
    }

    merge(map) {
        this.map = this.map.merge(map)

        return this
    }

    set(item) {
        this.map = this.map.set(item[this.key], new this.Record({
            ...this.get(item[this.key]),
            ...item,
        }))

        return this
    }

    setIn(path, func) {
        this.map = this.map.setIn(path, func)

        return this
    }

    add(item) {
        return this.set(item)
    }

    get(key) {
        return this.map.has(key) ? this.map.get(key).toJS() : {}
    }

    delete(key) {
        this.map = this.map.delete(key)

        return this
    }

    first() {
        const first = this.map.first()

        return first ? first.toJS() : {}
    }

    toArray() {
        return this.map.toList().toArray().map(item => item.toJS())
    }

    length() {
        return this.map.count()
    }
}
