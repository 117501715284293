import './env'
import './css/main.scss'
import './assets/scss/app.scss'
import './polyfills'
import 'isomorphic-fetch'
import React from 'react'
import Raven from 'raven-js'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import RootContainer from 'containers/Root/Root'
import Maintenance from 'layouts/Maintenance/Maintenance'
import param from 'utilities/param'
import {
    getFullLanguage, setAppLocale,
} from 'utilities/translate'
import { LocaleProvider } from 'contexts/localisation/localeProvider'
import './ae'
import store from 'store/store'

if (window.env.SENTRY_URL) Raven.config(window.env.SENTRY_URL, window.env.SENTRY_CONF).install()

const [language, country] = (param('locale') || getFullLanguage()).split('_')
// the https://spinnup.com/ should have country selection fallbacks
// until then we have to do the mapping here ...
setAppLocale(language, country)

const elementMaintenance = document.getElementById('maintenanceRoot')

const maintenanceRenderer = () => {
    ReactDOM.render(
        <React.StrictMode>
            <Router>
                <Provider store={store}>
                    <LocaleProvider>
                        <Maintenance />
                    </LocaleProvider>
                </Provider>
            </Router>
        </React.StrictMode>,
        elementMaintenance
    )
}

const rootRenderer = () => {
    ReactDOM.render(
        <React.StrictMode>
            <Router>
                <RootContainer store={store} />
            </Router>
        </React.StrictMode>,
        document.getElementById('root')
    )
}

if (elementMaintenance) {
    maintenanceRenderer()
} else {
    rootRenderer()
}
