import { connect, ConnectedProps } from 'react-redux'
import { transactionsSelector } from 'selectors/transactions'

import { fetchTransactions } from 'actions/WalletActions'

import WithdrawalHistory from 'components/WithdrawalHistory/WithdrawalHistory'
import { AppStoreState } from 'store/store-types'

const mapStateToProps = (state: AppStoreState) => ({
    isFetching: state.wallet.isFetching,
    transactions: transactionsSelector(state),
})

const mapDispatchToProps = {
    fetchTransactions,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export type WithdrawalHistoryContainerProps = ConnectedProps<typeof connector>
export default connector(WithdrawalHistory)
