import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import * as PropTypes from 'prop-types'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { SubmissionError } from 'redux-form'
import { Spinner } from 'components/index'
import { userSelector } from 'selectors/users'
import {
    createWalletAccount, fetchBalance, fetchWalletAccount, updateWalletAccount, withdraw,
} from 'actions/WalletActions'
import UpdateWalletAccountDetails, { getWalletAccountUpdateFormat, SubmitAccountFormValues }
    from 'components/EarningsUpdateWalletAccountDetails/UpdateWalletAccountDetails'
import UpdateWalletAccountTransferMethod, {
    formatHyperwalletDropInTrmObject,
} from 'components/EarningsUpdateWalletTransferMethod/UpdateWalletTransferMethod'
import Translate from 'components/Translate/translate'
import { balanceSelector } from 'selectors/wallet'
import { getCountrySelectOpts } from 'selectors/locales'
import BalanceWithdraw, { BalanceWithdrawFormState } from 'components/EarningsWalletBalanceWithdraw/BalanceWithdraw'
import { fetchLocales } from 'actions/LocaleActions'
import {
    CREATE_WALLET_ACCOUNT_FAILURE,
    UPDATE_WALLET_ACCOUNT_FAILURE,
    WITHDRAW_FAILURE,
    WITHDRAW_SUCCESS,
} from 'constants/WalletActionTypes'
import Divider from 'components/Divider/Divider'
import ViewWalletAccountDetails from 'components/EarningsViewWalletAccountDetails/ViewWalletAccountDetails'
import Alert from 'components/Alert/Alert'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import AccountBalanceWithdrawSuccess
    from 'components/EarningsAccountBalanceWithdrawSuccess/AccountBalanceWithdrawSuccess'
import { AppStoreState } from 'store/store-types'
import { WalletAccount } from 'models/walletAccount'
import { TranslationKey } from 'contexts/localisation/translateUtilities'

const localeCountrySelector = getCountrySelectOpts()
const mapStateToProps = (state: AppStoreState) => ({
    user: userSelector(state),
    balance: balanceSelector(state),
    countries: localeCountrySelector(state),
    walletAccount: state.wallet.account,
    hasFetchedWalletAccount: state.wallet.hasFetchedAccount,
    isFetchingWalletAccount: state.wallet.isFetchingAccount,
    isWithdrawing: state.wallet.isWithdrawing,
})

const mapDispatchToProps = {
    fetchWalletAccount,
    createWalletAccount,
    updateWalletAccount,
    fetchLocales,
    fetchBalance,
    withdraw,
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type ComponentProps = ConnectedProps<typeof connector> & RouteComponentProps<any>

export const AccountBalanceWithdrawalComponent: React.FC<ComponentProps> = (props) => {
    const [isExplicitlyUpdatingDetails, setIsExplicitlyUpdatingDetails] = useState(false)
    const [isSubmittingDetails, setIsSubmittingDetails] = useState(false)
    const [isExplicitlyUpdatingTransfer, setIsExplicitlyUpdatingTransfer] = useState(false)
    const [lastWithdrawResult, setLastWithdrawResult] = useState<string | null>(null)

    const {
        user,
        countries,
        balance,
        walletAccount,
        isFetchingWalletAccount,
        hasFetchedWalletAccount,
        isWithdrawing,
    } = props
    const { t } = useLocaleContext()

    useEffect(() => {
        props.fetchWalletAccount(user.id)
        if (!countries || countries.length === 0) {
            props.fetchLocales()
        }
    }, [user.id])

    const onSubmitAccountDetails = (formValues: SubmitAccountFormValues) => {
        const apiData = getWalletAccountUpdateFormat(formValues)
        setIsSubmittingDetails(true)

        let promise
        if (walletAccount.hasBeenCreated()) {
            promise = props.updateWalletAccount(apiData)
        } else {
            promise = props.createWalletAccount(apiData)
        }

        return promise.then(({ type, error }) => {
            setIsSubmittingDetails(false)

            if (type === CREATE_WALLET_ACCOUNT_FAILURE || type === UPDATE_WALLET_ACCOUNT_FAILURE) {
                if (error && error.errors) {
                    if (error.errors['address.stateProvince']) {
                        throw new SubmissionError({
                            state: t('ruleAddressRegionGenericFormat'),
                            province: t('ruleAddressRegionGenericFormat'),
                            prefecture: t('ruleAddressRegionGenericFormat'),
                            regionGeneric: t('ruleAddressRegionGenericFormat'),
                        })
                    }

                    if (error.errors['address.postalCode']) {
                        throw new SubmissionError({
                            postcode: t('ruleAddressPostcodeIsInvalidFormat'),
                            zipcode: t('ruleAddressPostcodeIsInvalidFormat'),
                        })
                    }
                } else {
                    throw new SubmissionError({
                        _error: t('earningsWithdrawAccountUpdateError'),
                    })
                }
            } else {
                setIsExplicitlyUpdatingDetails(false)
            }
        })
    }

    type SubmitTransferMethodFormValues = {
        transferMethodName: string
        token: string
        type: string
    }
    const onSubmitAccountTransferMethod = (formValues: SubmitTransferMethodFormValues) => {
        const existingMethods = walletAccount.getTransferMethods() || []
        const newMethod = formatHyperwalletDropInTrmObject(formValues)
        const apiData = {
            transferMethods: [
                newMethod,
                ...existingMethods,
            ],
        }

        props.updateWalletAccount(apiData).then(() => {
            setIsExplicitlyUpdatingTransfer(false)
        })
    }

    const onEditAccountDetails = () => {
        setIsExplicitlyUpdatingDetails(true)
    }

    const onCancelEditAccountDetails = () => {
        setIsExplicitlyUpdatingDetails(false)
    }

    const onCreateAccountTransferMethod = () => {
        setIsExplicitlyUpdatingTransfer(true)
    }

    const onCancelEditAccountTransferMethods = () => {
        setIsExplicitlyUpdatingTransfer(false)
    }

    const onWithdrawAmount = (formData: BalanceWithdrawFormState) => {
        setLastWithdrawResult(null)
        const transferMethod = walletAccount.getTransferMethods().find(trm => trm.ref === formData.withdrawMethod)
        return props.withdraw({
            amount: formData.withdrawAmount,
            password: formData.password,
            psp: {
                type: 'hyperwallet',
                params: {
                    transferToken: transferMethod?.ref,
                },
            },
        }).then(({ type, error }) => {
            let lastFetchWithdrawResult = null
            if (type === WITHDRAW_FAILURE) {
                if (error.httpStatusCode >= 500) {
                    lastFetchWithdrawResult = 'earningsWithdrawFailedWithdrawal'
                } else {
                    const allErrorFields = Object.keys(error.errors).map(f => (f === 'amount' ? 'withdrawAmount' : f))
                    const submissionError: Record<string, string> = {}
                    allErrorFields.forEach((f) => {
                        const errorKey = f[0].toUpperCase() + f.slice(1)
                        submissionError[f] = t(`earningsWithdrawInvalid${errorKey}` as TranslationKey)
                    })
                    throw new SubmissionError({
                        ...submissionError,
                        _error: t('earningsWithdrawInvalidData'),
                    })
                }
            } else if (type === WITHDRAW_SUCCESS) {
                lastFetchWithdrawResult = 'success'
                props.fetchBalance()
            }

            setLastWithdrawResult(lastFetchWithdrawResult)
        })
    }

    if (!user || !countries || countries.length === 0 || !balance
        || (!hasFetchedWalletAccount && isFetchingWalletAccount)) {
        return (<Spinner size="large" />)
    }

    if (!hasFetchedWalletAccount && !isFetchingWalletAccount) {
        return (
            <Alert type="fatal-error" colourise small>
                <Translate id="earningsWithdrawWalletLoadError" />
            </Alert>
        )
    }

    if (!walletAccount.hasBeenCreated() || isExplicitlyUpdatingDetails) {
        return (
            <UpdateWalletAccountDetails
                user={user}
                countries={countries}
                walletAccount={walletAccount}
                onSubmit={onSubmitAccountDetails}
                isSubmitting={isSubmittingDetails}
                onCancel={onCancelEditAccountDetails}
            />
        )
    } if (!walletAccount.hasTransferMethod() || isExplicitlyUpdatingTransfer) {
        return (
            <UpdateWalletAccountTransferMethod
                user={user}
                walletAccount={walletAccount}
                countries={countries}
                onFetchWalletAccount={props.fetchWalletAccount}
                onCancel={onCancelEditAccountTransferMethods}
                onEditDetails={onEditAccountDetails}
                onSubmit={onSubmitAccountTransferMethod}
            />
        )
    }
    let resultElem = null
    if (lastWithdrawResult === 'success') {
        return (
            <AccountBalanceWithdrawSuccess user={user} history={props.history} />
        )
    } if (lastWithdrawResult) {
        resultElem = (
            <Alert type="error" containerClassNames={['c-withdraw-alert', 'error']} colourise>
                <Translate id={lastWithdrawResult as TranslationKey} />
            </Alert>
        )
    }

    return (
        <>
            <BalanceWithdraw
                balance={balance}
                minWithdrawalAmount={walletAccount.getMinWithdrawalAmount()}
                availableTransferMethods={walletAccount.getTransferMethods()}
                createNewTransferMethod={onCreateAccountTransferMethod}
                isWithdrawing={isWithdrawing}
                onSubmit={onWithdrawAmount}
            />
            {resultElem}
            <Divider imageId="dune-1" />
            <ViewWalletAccountDetails
                countries={countries}
                onEdit={onEditAccountDetails}
                walletAccount={walletAccount}
            />
        </>
    )
}

AccountBalanceWithdrawalComponent.propTypes = {
    user: PropTypes.object.isRequired,
    balance: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,

    hasFetchedWalletAccount: PropTypes.bool.isRequired,
    isFetchingWalletAccount: PropTypes.bool.isRequired,
    walletAccount: PropTypes.instanceOf(WalletAccount).isRequired,
    isWithdrawing: PropTypes.bool.isRequired,

    fetchWalletAccount: PropTypes.func.isRequired,
    createWalletAccount: PropTypes.func.isRequired,
    updateWalletAccount: PropTypes.func.isRequired,
    fetchLocales: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    withdraw: PropTypes.func.isRequired,

    history: PropTypes.any.isRequired,
}

export default connector(withRouter(AccountBalanceWithdrawalComponent))
