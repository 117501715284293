import * as types from 'constants/SocialActionTypes'
import * as authTypes from 'constants/AuthActionTypes'

const initialState = {
    fans: {
        sites: {
            facebook: {
                isVisible: true,
                data: [],
            },
            twitter: {
                isVisible: true,
                data: [],
            },
            youtube: {
                isVisible: true,
                data: [],
            },
            soundcloud: {
                isVisible: true,
                data: [],
            },
            instagram: {
                isVisible: true,
                data: [],
            },
        },
        isFetching: false,
        failedFetching: false,
    },
    plays: {
        sites: {
            youtube: {
                isVisible: true,
                data: [],
            },
            soundcloud: {
                isVisible: true,
                data: [],
            },
        },
        isFetching: false,
        failedFetching: false,
    },
    filter: {
        days: 'days',
    },
}

export default function reports(state = initialState, action) {
    switch (action.type) {
        // VISIBILITY

        case types.TOGGLE_SOCIAL_TRENDS_VISIBILITY:
            return {
                ...state,
                [action.meta.metric]: {
                    ...state[action.meta.metric],
                    sites: {
                        ...state[action.meta.metric].sites,
                        [action.meta.site]: {
                            ...state[action.meta.metric].sites[action.meta.site],
                            isVisible: action.payload,
                        },
                    },
                },
            }

            // METRICS

        case types.FETCH_SOCIAL_TRENDS_SUCCESS:
            let sites

            if (action.payload.data) {
                sites = action.payload.data.reduce((existingSites, site) => ({
                    ...existingSites,
                    [site.name]: {
                        ...state[action.meta.metric].sites[site.name],
                        data: site.data.map(point => ({
                            ...point,
                            date: point.date * 1000,
                        })),
                    },
                }), state[action.meta.metric].sites)
            }

            return {
                ...state,
                [action.meta.metric]: {
                    ...state[action.meta.metric],
                    isFetching: false,
                    failedFetching: false,
                    filter: action.meta.filter,
                    sites: sites || { ...state[action.meta.metric].sites },
                },
                filter: action.meta.filter,
            }

        case types.FETCH_SOCIAL_TRENDS_REQUEST:
            return {
                ...state,
                [action.meta.metric]: {
                    ...state[action.meta.metric],
                    isFetching: true,
                    failedFetching: false,
                },
            }

        case types.FETCH_SOCIAL_TRENDS_FAILURE:
            return {
                ...state,
                [action.meta.metric]: {
                    ...state[action.meta.metric],
                    isFetching: false,
                    failedFetching: true,
                },
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
