import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import * as popupTypes from 'constants/PopupTypes'
import * as popupButtons from 'constants/PopupButtons'
import Button from 'components/Button/Button'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const Popup = ({ popupData, closePopup }) => {
    const {
        id,
        type,
        title,
        text,
        acceptButtonText,
        closeButtonText = popupButtons.close,
        showClose = true,
        onAccept,
        onClose,
        buttons,
    } = (popupData || {})
    const { t, tmarkdown } = useLocaleContext()

    if (!popupData) return null

    const popupTitle = type ? t(title) : t('componentPopupDefaultTitle')

    const textIsString = (typeof text === 'string')
    let popupText = '-- undefined --'
    if (textIsString) {
        popupText = type ? t(text) : t('componentPopupDefaultText')
    } else if (text.isMarkupText) {
        popupText = (
            <div
                className="c-popup-text-markdown"
                dangerouslySetInnerHTML={{
                    __html: tmarkdown(text.translation, ...text.params),
                }}
            />
        )
    }

    const popupClassName = {
        'c-popup-confirmation': type === popupTypes.confirmationPopup,
        'c-popup-information': type === popupTypes.informationPopup,
        'c-popup-error': type === popupTypes.errorPopup,
    }

    const preventClosing = (e) => {
        e.stopPropagation()
    }

    const handleClosePopup = () => {
        closePopup(id)
    }

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose()
        }
        handleClosePopup()
    }

    const handleAccept = () => {
        onAccept()
        handleClosePopup()
    }

    const handleCustomButtonClick = clickHandler => () => {
        clickHandler()
        handleClosePopup()
    }

    return (
        <div className="c-popup-screen-overlay" onClick={handleClosePopup}>
            <div onClick={preventClosing} className={classnames('c-popup', popupClassName)}>
                <button className="c-popup-close" onClick={handleClosePopup}>
                    <FontAwesomeIcon icon="faTimes" />
                </button>
                <h4 className="c-popup-title">{popupTitle}</h4>

                {textIsString ? (
                    <p className="c-popup-text">{popupText}</p>
                ) : popupText }

                <div className="c-popup-buttons">
                    { showClose && <Button isLink onClick={handleClose}>{t(closeButtonText)}</Button> }
                    { buttons ? buttons.map(btn => (
                        <Button
                            key={btn.text.replace(' ', '').toLowerCase()}
                            onClick={handleCustomButtonClick(btn.click)}
                        >
                            {t(btn.text)}
                        </Button>
                    )) : null}
                    {acceptButtonText ? (
                        <Button onClick={handleAccept}>{t(acceptButtonText)}</Button>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

Popup.propTypes = {
    popupData: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
            translation: PropTypes.string.isRequired,
            params: PropTypes.array,
            isMarkupText: PropTypes.bool,
        })]),
        acceptButtonText: PropTypes.string,
        closeButtonText: PropTypes.string,
        showClose: PropTypes.bool,
        onAccept: PropTypes.func,
        onClose: PropTypes.func,
        buttons: PropTypes.array,
    }),
    closePopup: PropTypes.func.isRequired,
}

export default Popup
