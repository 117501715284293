import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom'
import param from 'utilities/param'
import Button from 'components/Button/Button'
import Frame from 'components/Frame/Frame'
import Spinner from 'components/Spinner/Spinner'
import { postVerificationCheck } from 'actions/VerificationActions'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

VerifyCheck.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    successfullyVerifiedEmail: PropTypes.bool.isRequired,
    verificationCheck: PropTypes.func.isRequired,
}

function VerifyCheck(props) {
    const {
        verificationCheck,
        isLoading,
        successfullyVerifiedEmail,
        match,
        history: { push },
    } = props

    const { t } = useLocaleContext()

    useEffect(() => {
        if (param('signature')) {
            verificationCheck(match.params.userId, param('expires'), param('signature'))
        }
    }, [])

    const onVerifyContinue = () => {
        push('/start')
    }

    if (!param('signature')) {
        return (<Redirect to="/start" />)
    }

    let content
    // todo  There is a situation (at least in dev mode) where the !successfullyVerifiedEmail branch is hit prior to
    // todo  actually making the request - potentially due to MFA signed url flow changes.
    // todo  The data flow here potentially needs refactoring somewhat.
    if (!isLoading) {
        if (!successfullyVerifiedEmail) {
            content = (
                <div className="v-verify-container">
                    <header>
                        <h1 className="h3">{t('verificationCheckErrorTitle')}</h1>
                    </header>
                    <div className="v-verify-content-container">
                        <div className="v-verify-content">
                            <TranslateMarkdown
                                id="verificationCheckErrorMessageLoggedIn"
                                className="v-verify-content-body"
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            content = (
                <div className="v-verify-container">
                    <div className="v-verify-content">
                        <header>
                            <h1 className="h3">{t('verificationCheckVerifiedTitle')}</h1>
                        </header>
                        <TranslateMarkdown
                            id="verificationCheckVerifiedMessage"
                            className="v-verify-content-body"
                        />
                    </div>
                    <Button
                        className="v-verify-continue-btn"
                        onClick={onVerifyContinue}
                    >
                        {t('verificationCheckContinueButton')}
                    </Button>
                </div>
            )
        }
    } else {
        content = (
            <Spinner size="small">
                {t('globalLoading')}
            </Spinner>
        )
    }

    return (
        <div className="v-verify">
            <Frame
                borderImageId="dune-2"
                contentTint="light"
            >
                { content }
            </Frame>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    verificationCheck: (userId, expires, signature) => dispatch(postVerificationCheck(userId, expires, signature)),
})

const mapStateToProps = state => ({
    isLoading: state.users.isVerifyingEmail,
    successfullyVerifiedEmail: state.users.successfullyVerifiedEmail,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyCheck))
