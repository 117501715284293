import { createSelector } from 'reselect'
import { isEmpty } from 'lodash'
import { money, thousand } from 'utilities/number'
import t from 'utilities/translate'
import { AppStoreState } from 'store/store-types'
import { ApiSalesBreakdownRelease, ApiSalesSubBreakdown } from 'models/api/sales-overview'
import Country from 'models/country'
import { countriesSelector } from './countries'

export const salesOverviewSelector = (state: AppStoreState) => state.sales.overview
export const salesBreakdownSelector = (state: AppStoreState) => state.sales.breakdown
export const salesBreakdownTotalSelector = (state: AppStoreState) => state.sales.breakdown.total
export const salesBreakdownReleasesSelector = (state: AppStoreState) => state.sales.breakdown.releases
export const salesBreakdownUnfilteredReleasesSelector = (
    (state: AppStoreState) => state.sales.breakdownUnfilteredReleases
)
export const salesBreakdownCountriesSelector = (state: AppStoreState) => state.sales.breakdown.countries
export const salesBreakdownUnfilteredCountriesSelector = (
    (state: AppStoreState) => state.sales.breakdownUnfilteredCountries
)
export const salesBreakdownDspsSelector = (state: AppStoreState) => state.sales.breakdown.dsps
export const salesBreakdownUnfilteredDspsSelector = (state: AppStoreState) => state.sales.breakdownUnfilteredDsps
export const getSelectedOverviewYear = (state: AppStoreState) => state.sales.selectedOverviewYear
export const selectedReleaseSelector = (state: AppStoreState) => state.sales.selectedRelease
export const selectedTrackSelector = (state: AppStoreState) => state.sales.selectedTrack
export const selectedCountrySelector = (state: AppStoreState) => state.sales.selectedCountry
export const selectedDspSelector = (state: AppStoreState) => state.sales.selectedDsp

function formatIndividualSales(sales: { breakdown: ApiSalesSubBreakdown }) {
    return {
        streams: thousand(sales.breakdown.stream.quantity),
        streamRevenue: money(sales.breakdown.stream.revenue),
        downloads: thousand(sales.breakdown.download.quantity),
        downloadRevenue: money(sales.breakdown.download.revenue),
        revenue: money(Object.entries(sales.breakdown)
            .reduce((total, sale) => total + Number.parseFloat(sale[1].revenue), 0)),
    }
}

export const getMostRecentOverviewActivityYear = (state: AppStoreState) => {
    if (state.sales.overview.length) {
        return state.sales.overview.slice().sort(
            (l, r) => (l.year < r.year ? 1 : -1)
        )[0].year
    }

    return (new Date()).getFullYear()
}

export const getOverviewByYear = (year: number) => createSelector(
    salesOverviewSelector,
    overview => overview
        .filter(overviewData => overviewData.year === +year)
        .map((overviewData) => {
            const monthData = overviewData.months.map(month => ({
                monthIndex: month.monthIndex,
                revenue: month.revenue,
                dsps: month.dsps,
            }))

            return {
                year: overviewData.year,
                months: monthData,
            }
        })
)

export const getCalendarOverview = () => createSelector(
    salesOverviewSelector,
    (overview) => {
        const yrs = overview.map(overviewData => +overviewData.year)

        return {
            years: {
                min: Math.min(...yrs),
                max: Math.max(...yrs),
            },
        }
    }
)

export const getOverviewByYearAndMonth = (year: number, month: number) => createSelector(
    getOverviewByYear(year),
    (overview) => {
        if (!isEmpty(overview)) {
            return overview[0].months.filter(m => m.monthIndex === month)
        }
        return []
    }
)

export const getOverviewRevenueFor = ({ year, month }: { year: number, month: number }) => createSelector(
    getOverviewByYearAndMonth(year, month),
    (overview) => {
        if (!isEmpty(overview)) {
            return money(overview[0].revenue)
        }
        return ''
    }
)

export const getTotalRevenue = createSelector(
    salesBreakdownTotalSelector,
    (totals) => {
        if (totals !== undefined) {
            return money(totals.revenue)
        }
        return ''
    }
)

export const getOverviewDspsByYearAndMonth = (year: number, month: number) => createSelector(
    salesOverviewSelector,
    overview => overview
        .filter(overviewData => overviewData.year === year)
        .map((overviewData) => {
            const monthData = overviewData.months
                .filter(months => months.monthIndex === month)
                .map(m => ({
                    monthIndex: m.monthIndex,
                    revenue: m.revenue,
                    dsps: m.dsps,
                }))

            return {
                year: overviewData.year,
                month: monthData[0],
            }
        })
)

type FormattedProductAssetBreakdown = {
    value: string
    label: string
    level?: number
    type: 'asset' | 'product'
    oneTrackRelease?: boolean
    singleTrackProductAssetId?: string | null
}

export const allReleaseAndTrackNames = createSelector(
    salesBreakdownUnfilteredReleasesSelector,
    (sales) => {
        if (sales !== undefined && sales.length > 0) {
            return sales.reduce((
                releasesWithTracks: FormattedProductAssetBreakdown[],
                release: ApiSalesBreakdownRelease
            ) => {
                let tracks: FormattedProductAssetBreakdown[] = []
                let oneTrackRelease = false
                let singleTrackProductAssetId = null
                if (release.tracks && release.tracks.length > 1) {
                    tracks = release.tracks.map((track, index) => ({
                        value: `${release.upc}-${track.isrc}`,
                        label: `#${index + 1} - ${track.name}`,
                        type: 'asset',
                        level: 1,
                    }))
                }
                if (release.tracks && release.tracks.length <= 1) {
                    oneTrackRelease = true
                    if (release.tracks.length > 0) {
                        singleTrackProductAssetId = release.tracks[0].isrc
                    }
                }

                return [
                    ...releasesWithTracks,
                    {
                        value: release.upc,
                        label: release.name,
                        type: 'product',
                        oneTrackRelease,
                        singleTrackProductAssetId,
                    } as FormattedProductAssetBreakdown,
                    ...tracks,
                ]
            }, [] as FormattedProductAssetBreakdown[])
        }

        return []
    }

)

export const allCountries = createSelector(
    salesBreakdownUnfilteredCountriesSelector,
    countriesSelector,
    (salesCountries, countries) => {
        if (salesCountries !== undefined && salesCountries.length > 0) {
            return salesCountries.map((salesCountry) => {
                if (salesCountry.code.toUpperCase() === 'ZZ') {
                    return {
                        label: t('globalCountryUnknown'),
                        value: 'zz',
                        countryCode: 'zz',
                        country: t('globalCountryUnknown'),
                    }
                }
                const country = countries.find((c: Country) => c.countryCode === salesCountry.code.toUpperCase())
                return {
                    label: country && country.countryName,
                    value: salesCountry.code,
                    countryCode: salesCountry.code,
                    country: country && country.countryName,
                }
            })
        }
        return []
    }
)

export const allStores = createSelector(
    salesBreakdownUnfilteredDspsSelector,
    (salesBreakdownDsps) => {
        if (salesBreakdownDsps !== undefined && salesBreakdownDsps.length > 0) {
            return salesBreakdownDsps.map(dsp => ({ dsp: dsp.name, label: dsp.name, value: dsp.name }))
        }
        return []
    }
)

export const totalSalesSelector = createSelector(
    salesBreakdownTotalSelector,
    (salesTotalBreakdown) => {
        if (salesTotalBreakdown !== undefined) {
            return {
                ...formatIndividualSales(salesTotalBreakdown),
            }
        }
        return {}
    }
)

export const salesByCountrySelector = createSelector(
    salesBreakdownCountriesSelector,
    countriesSelector,
    (salesCountries, countries) => {
        if (salesCountries !== undefined && salesCountries.length > 0) {
            return salesCountries.map((salesCountry) => {
                const country = countries.find((c: Country) => c.countryCode === salesCountry.code.toUpperCase())
                return {
                    countryCode: salesCountry.code,
                    country: country ? country.countryName : t('globalCountryUnknown'),
                    ...formatIndividualSales(salesCountry),
                }
            })
        }
        return []
    }
)

export const salesByStoreSelector = createSelector(
    salesBreakdownDspsSelector,
    (salesStores) => {
        if (salesStores !== undefined && salesStores.length > 0) {
            return salesStores.map(dsp => ({
                dsp: dsp.name,
                formattedName: dsp.formattedName,
                ...formatIndividualSales(dsp),
            }))
        }
        return []
    }
)

export const salesByReleaseSelector = createSelector(
    salesBreakdownReleasesSelector,
    (releaseSales) => {
        if (releaseSales !== undefined && releaseSales.length > 0) {
            return releaseSales.map(release => ({
                upc: release.upc,
                name: release.name,
                ...formatIndividualSales(release),
            }))
        }
        return []
    }
)

export const salesByTracksSelector = createSelector(
    salesBreakdownReleasesSelector,
    selectedReleaseSelector,
    (releaseSales, upc) => {
        if (releaseSales !== undefined && releaseSales.length > 0) {
            if (upc !== undefined && upc !== '') {
                const filteredReleaseSales = releaseSales.filter(release => Number(release.upc) === Number(upc))
                if (filteredReleaseSales.length > 0) {
                    return filteredReleaseSales[0].tracks.map(track => ({
                        isrc: track.isrc,
                        name: track.name,
                        ...formatIndividualSales(track),
                    }))
                }
            }
        }
        return []
    }
)

export const salesByTrackSelector = createSelector(
    salesByTracksSelector,
    selectedTrackSelector,
    (trackSales, isrc) => {
        if (trackSales !== undefined && trackSales.length > 0) {
            if (isrc !== undefined && isrc !== '') {
                return trackSales.filter(release => release.isrc === isrc)
            }
        }
        return []
    }
)
