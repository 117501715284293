import { errorRules } from 'components/AddressForm/rules'
import JapanPrefecture from './regions/japanPrefecture.json'
import CanadaProvinces from './regions/canada.json'
import USStates from './regions/usa.json'
import AUTerritories from './regions/australia.json'

export const countryRegionFields = {
    CA: {
        fieldName: 'province',
        fieldLabel: 'formAddressRegionProvince',
        fieldPlaceholder: 'formAddressRegionProvincePlaceholder',
        validate: errorRules.province,
        options: CanadaProvinces,
        sortable: true,
    },
    US: {
        fieldName: 'state',
        fieldLabel: 'formAddressRegionState',
        fieldPlaceholder: 'formAddressRegionStatePlaceholder',
        validate: errorRules.state,
        options: USStates,
        sortable: true,
    },
    JP: {
        fieldName: 'prefecture',
        fieldLabel: 'formAddressPrefecture',
        fieldPlaceholder: 'formAddressRegionPrefecturePlaceholder',
        validate: errorRules.prefecture,
        options: JapanPrefecture,
        sortable: false,
    },
    AU: {
        fieldName: 'state',
        fieldLabel: 'formAddressRegionState',
        fieldPlaceholder: 'formAddressRegionStatePlaceholder',
        validate: errorRules.state,
        options: AUTerritories,
        sortable: true,
    },
}
