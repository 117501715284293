// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { of, EMPTY } from 'rxjs'
import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { mergeMap, filter, catchError } from 'rxjs/operators'
import { get, kebabCase } from 'lodash'
import {
    actionTypes,
    getFormValues,
    startSubmit,
    stopSubmit,
    touch,
    change,
} from 'redux-form'

import {
    updateWarnings,
    updateErrors,
    updateAsyncErrors,
} from 'modules/forms'

import {
    getErrors,
    getWarnings,
} from 'validations/artist'

import { put } from 'services/spinnup-api/artist'

const APP_PREFIX = 'spinnup'
export const KEY = 'artist'

export const ARTIST_FORM_KEY = 'artist'

export const artistFormRegex = /^artist$/
export const getArtistFormName = () => `${ARTIST_FORM_KEY}`

// ///////////
// DETERMINISTIC ACTIONS
// ///////////

// already declare in constants files, not duckerise YET !!! (๑˃̵ᴗ˂̵)و
export const SET_IS_SAVING = `${APP_PREFIX}/${KEY}/SET_IS_SAVING`

// ///////////
// ACTION CREATORS
// ///////////
export const setIsSaving = isSaving => ({
    type: SET_IS_SAVING,
    payload: isSaving,
})
// already declare in actionCreator file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و

// ///////////
// REDUCERS
// ///////////
const isSavingReducerKey = 'isSaving'

const initialState = {
    [isSavingReducerKey]: false,
}

const isSavingReducer = (
    state = initialState[isSavingReducerKey],
    {
        type,
        payload: isSaving,
    }
) => {
    switch (type) {
        case SET_IS_SAVING:
            return isSaving
        default:
            return state
    }
}
// already declare in reducer file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و
export default combineReducers({
    [isSavingReducerKey]: isSavingReducer,
})

// ///////////
// SELECTORS
// ///////////

export const getIsSaving = state => state[KEY][isSavingReducerKey]

// ///////////
// NON DETERMINISTIC ACTIONS
// ///////////
export const REQUEST_SAVE = `${APP_PREFIX}/${KEY}/REQUEST_SAVE`
export const RECEIVE_SAVE_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_SUCCESS`
export const RECEIVE_SAVE_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_FAILURE`
export const RECEIVE_PATCH_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_SUCCESS`
export const RECEIVE_PATCH_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_FAILURE`
export const SYNC_VALIDATE = `${APP_PREFIX}/${KEY}/SYNC_VALIDATE`

// ///////////
// ACTION CREATORS
// ///////////

export const requestSave = () => ({
    type: REQUEST_SAVE,
})

export const receiveSave = updatedRelease => ({
    type: RECEIVE_SAVE_SUCCESS,
    payload: updatedRelease,
})

export const receiveSaveFailure = (error, { id }) => ({
    type: RECEIVE_SAVE_FAILURE,
    payload: error,
    meta: {
        id,
    },
})

export const receivePatch = updatedRelease => ({
    type: RECEIVE_PATCH_SUCCESS,
    payload: updatedRelease,
})

export const receivePatchFailure = ({ message }, { id }, field) => ({
    type: RECEIVE_PATCH_FAILURE,
    payload: message,
    meta: {
        field,
        id,
    },
})

export const syncValidate = (formId, field) => ({
    type: SYNC_VALIDATE,
    payload: formId,
    meta: {
        field,
    },
})

// ///////////
// EPICS
// ///////////
export const blurNameEpic = (action$, store) => action$.pipe(
    ofType(actionTypes.BLUR),
    filter(({ meta: { form: formId, field } }) => artistFormRegex.exec(formId) && field === 'name'),
    mergeMap(({ meta: { form: formId } }) => {
        const state = store.value
        const { name, slug } = getFormValues(formId)(state)

        let observable = EMPTY

        if (!slug) {
            const defaultSlug = kebabCase(name.replace(/[^A-Za-z0-9\-]+/gi, '')).toLowerCase()
            observable = of(change(formId, 'slug', defaultSlug))
        }

        return observable
    })
)

export const changeEpic = action$ => action$.pipe(
    ofType(actionTypes.CHANGE),
    filter(({ meta: { form: formId } }) => artistFormRegex.exec(formId)),
    mergeMap(({ meta: { form: formId, field } }) => of(syncValidate(formId, field)))
)

export const artistSyncValidateEpic = (action$, store) => action$.pipe(
    ofType(SYNC_VALIDATE),
    filter(({ payload: formName }) => artistFormRegex.exec(formName)),
    mergeMap(({ payload: formName, meta: { field } }) => {
        const state = store.value
        const value = getFormValues(formName)(state)

        const actions = []

        if (value) {
            actions.push(updateWarnings(formName, getWarnings(value, { formName, state })))
            actions.push(updateErrors(formName, getErrors(value, { formName, state })))
        }

        if (!field) {
            const fields = Object.keys(get(state, `form.${formName}.registeredFields`, {}))
            fields.forEach((currentField) => {
                actions.push(touch(formName, currentField))
            })
        }

        return actions
    })
)

export const requestSaveToStartSubmitEpic = action$ => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(() => of(startSubmit(getArtistFormName())))
)

export const receiveSaveToStopSumitEpic = action$ => action$.pipe(
    ofType(RECEIVE_SAVE_SUCCESS, RECEIVE_SAVE_FAILURE),
    mergeMap(() => of(stopSubmit(getArtistFormName())))
)

export const requestSaveToSetIsSavingEpic = action$ => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(() => of(setIsSaving(true)))
)

export const receiveSaveToSetIsSavingEpic = action$ => action$.pipe(
    ofType(RECEIVE_SAVE_SUCCESS, RECEIVE_SAVE_FAILURE),
    mergeMap(() => of(setIsSaving(false)))
)

export const requestSaveToPutEpic = (action$, store, { ajax }) => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(() => {
        const state = store.value
        const { credentials } = state.auth
        const formName = getArtistFormName()

        const artist = getFormValues(formName)(state)

        return put(
            { artist },
            credentials,
            ajax
        ).pipe(
            mergeMap(({ response }) => of(receiveSave(response))),
            catchError(({ response: { errors } }) => of(receiveSaveFailure(errors, artist)))
        )
    })
)

export const requestSaveToValidateEpic = action$ => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(() => of(syncValidate(getArtistFormName())))
)

export const receiveSaveFailureEpic = action$ => action$.pipe(
    ofType(RECEIVE_SAVE_FAILURE),
    mergeMap(({ payload: errors }) => Object.keys(errors)
        .map(errorFieldName => updateAsyncErrors(
            getArtistFormName(), {
                [errorFieldName]: errors[errorFieldName][0],
            }
        )))
)

export const epic = combineEpics(
    blurNameEpic,
    changeEpic,
    artistSyncValidateEpic,
    requestSaveToStartSubmitEpic,
    requestSaveToPutEpic,
    receiveSaveToStopSumitEpic,
    receiveSaveFailureEpic,
    requestSaveToSetIsSavingEpic,
    receiveSaveToSetIsSavingEpic
)
