import { Record } from 'immutable'

const emptyPayment = {
    id: -1,
    releaseId: undefined,
    status: '',
    amount: -1,
    currency: undefined,
    isRenewal: -1,
    isDeferred: false,
    metadata: {},
    errors: {},
    reference: undefined,
    type: undefined,
    country: undefined,
    method: undefined,
    voucherId: -1,
    createdAt: undefined,
    voucher: {},
    release: undefined,
    rawResponse: undefined,
}

export default class Payment extends new Record(emptyPayment) {
    constructor(apiPayment) {
        const formattedMetadata = apiPayment.metadata ? apiPayment.metadata.reduce((obj, metadata) => ({
            ...obj,
            [metadata.name]: metadata.data,
        }), {}) : null

        super({
            ...apiPayment,
            createdAt: new Date(apiPayment.createdAt),
            metadata: formattedMetadata,
        })
    }
}
