import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Button from 'components/Button/Button'
import Frame from 'components/Frame/Frame'
import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { COMPOSITION_TYPE_COVER, COMPOSITION_TYPE_SAMPLE } from 'constants/enums/TrackEnums'
import './_postReleaseGuidance.scss'

const compositionTypesRequiringLegalDocs = [
    COMPOSITION_TYPE_COVER,
    COMPOSITION_TYPE_SAMPLE,
]

const PostReleaseGuidance = ({
    release,
}) => {
    const { t } = useLocaleContext()

    const [showLegalDocs, setShowLegalDocs] = useState(false)

    useEffect(() => {
        let trackCompositionTypes = []
        if (release) {
            if (release.tracks) {
                trackCompositionTypes = release.tracks.map(rt => (rt.compositionType ? rt.compositionType.id : -1))
            } else if (release.album && release.album.tracks) {
                trackCompositionTypes = release.album.tracks.map(at => at.compositionTypeId)
            }
        }

        const requiresLegalDocs = trackCompositionTypes
            .some(trackCompositionType => compositionTypesRequiringLegalDocs.includes(trackCompositionType))

        setShowLegalDocs(requiresLegalDocs)
    }, [release])

    return (
        <>
            <Frame
                borderImageId="dune-1"
                contentTint="light"
                className="c-release-guidance-frame"
                contentClassName="c-release-guidance-frame-content"
                borderFlipHorizontaly
            >
                <h3>{t('viewPaymentSubheading')}</h3>

                <div className="c-release-guidance-info">
                    { showLegalDocs
                && (
                    <TranslateMarkdown id="viewPaymentStepRequestLegalDocuments" className="c-release-legal" />
                )}

                    <TranslateMarkdown id="viewPaymentOrderCompleteTextBlock1" />
                    <TranslateMarkdown id="viewPaymentOrderCompleteTextBlock2" />
                    <TranslateMarkdown id="viewPaymentOrderCompleteTextBlock3" />
                </div>

                <Button
                    size="large"
                    className="c-payment-frame-button-next"
                    href="/releases"
                >
                    {t('viewPaymentContinueButton')}
                </Button>
            </Frame>
        </>
    )
}

PostReleaseGuidance.propTypes = {
    release: PropTypes.object.isRequired,
}

export default PostReleaseGuidance
