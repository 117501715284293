import { connect } from 'react-redux'

import PromoWidget from 'components/PromoWidget/PromoWidget'
import { fetchPosts } from 'actions/PostActions'
import { getActiveWidget } from 'selectors/posts'

const mapStateToProps = state => ({
    data: getActiveWidget(state),
})

const mapDispatchToProps = {
    fetchPosts,
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoWidget)
