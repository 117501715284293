import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import moment from 'moment'
import { Field as ReduxField } from 'redux-form'

import DatePicker from 'components/DatePicker/DatePicker'
import Field from 'components/Field/Field'
import Select from 'components/Select/Select'
import TextInput from 'components/Inputs/TextInput'
import Frame from 'components/Frame/Frame'

import ArtistLookup from 'containers/ArtistLookup/ArtistLookup'
import ReleaseDate from 'containers/ReleaseDate/ReleaseDate'
import TerritoryRestrict from 'containers/TerritoryRestrict/TerritoryRestrict'
import TrackUploadProgress from 'containers/TrackUpload/TrackUploadProgress'
import ConditionalAlert from 'containers/ConditionalAlert/ConditionalAlert'
import TranslatableInput from 'containers/TranslatableInput/TranslatableInput'

import t, { tmarkdown } from 'utilities/translate'

import { ARTIST_TYPE_PRIMARY } from 'models/artist'
import * as statuses from 'constants/enums/ReleaseStatusTypes'
import { scrollUpToTop } from 'hooks/useScrollToTopEffect'
import { importantLanguages } from 'constants/enums/LanguageEnums'
import { isPlaceholderTitle } from 'utilities/release'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { StoreDeliveryOptions } from '../StoreDeliveryOptions/StoreDeliveryOptions'

export default class ReleaseSettingsForm extends Component {
    static propTypes = {
        album: PropTypes.object.isRequired,
        genres: PropTypes.array,
        languages: PropTypes.array,
        fetchGenres: PropTypes.func,
        fetchLanguages: PropTypes.func,
        form: PropTypes.string,
        isFetchingGenres: PropTypes.bool,
        isFetchingLanguages: PropTypes.bool,
        countries: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]),
        originalCoverValue: PropTypes.bool,
        shouldExpandAdvanceSetting: PropTypes.bool,
        addTranslatedTitleToAlbum: PropTypes.func.isRequired,
        addTranslatedArtistsToAlbum: PropTypes.func.isRequired,
        validateAlbumTitleWithMetadata: PropTypes.func.isRequired,
        validateAlbumArtistNamesWithMetadata: PropTypes.func.isRequired,
        onSocialDeliveryRelease: PropTypes.func.isRequired,
        onYoutubeToggleRelease: PropTypes.func.isRequired,
        release: PropTypes.object.isRequired,
        socialOptedIn: PropTypes.bool.isRequired,
        youtubeOptedIn: PropTypes.bool.isRequired,
        onTrackStartTimeUpdate: PropTypes.func.isRequired,
        allTracks: PropTypes.array,
    }

    constructor(props) {
        super(props)
        const shouldExpandAdvanceSetting = !!props.album.originalReleaseDate || !!props.album.upc
        this.state = {
            isDisplayingAdvancedSettings: props.shouldExpandAdvanceSetting || shouldExpandAdvanceSetting,
            allTracks: props.allTracks,
        }
    }

    componentDidMount() {
        const {
            fetchGenres,
            fetchLanguages,
        } = this.props

        fetchGenres()
        fetchLanguages()
        scrollUpToTop()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.allTracks !== this.props.allTracks) {
            this.setState({
                allTracks: this.props.allTracks,
            })
        }
    }

    onReleaseTrackStartTimeUpdate = (track, time) => {
        const updatedTracks = this.props.allTracks.map((trackItem) => {
            if (trackItem.id === track.id) {
                trackItem.isUpdating = true
                return trackItem
            } else {
                return trackItem
            }
        })
        this.setState({
            allTracks: updatedTracks,
        })
        const updatedTrack = { ...track, sampleStartTime: time, isUpdating: true }
        this.setState({
            allTracks: updatedTracks,
        })
        this.props.onTrackStartTimeUpdate(updatedTrack)
    }

    toggleAdvanceSettings = () => {
        const {
            isDisplayingAdvancedSettings,
        } = this.state

        this.setState({
            isDisplayingAdvancedSettings: !isDisplayingAdvancedSettings,
        })
    }

    onTitleTranslationSave = (translations, album) => {
        const titleTranslationObjects = Object.entries(translations).reduce(
            (allTranslations, [index, titleTranslations]) => {
                const translationsByLanguageObject = Object.entries(titleTranslations).reduce(
                    (translationsByTitle, [language, languageTranslations]) => {
                        if (languageTranslations
                            && languageTranslations.value !== null
                            && languageTranslations.value !== ''
                        ) {
                            return [
                                ...translationsByTitle,
                                {
                                    property: 'name',
                                    value: languageTranslations.value,
                                    language,
                                },
                            ]
                        } else {
                            return translationsByTitle
                        }
                    }, []
                )
                return {
                    ...allTranslations,
                    [index]: translationsByLanguageObject,
                }
            }, {}
        )
        this.props.addTranslatedTitleToAlbum(titleTranslationObjects[0], album)
    }

    onArtistTranslationSave = (translations, album) => {
        const translationsByArtists = Object.entries(translations).reduce(
            (allTranslations, [index, artistTranslations]) => {
                const translationsByArtistObject = Object.entries(artistTranslations).reduce(
                    (translationsByArtist, [language, languageTranslations]) => {
                        if (languageTranslations
                            && languageTranslations.value !== null
                            && languageTranslations.value !== ''
                        ) {
                            return [
                                ...translationsByArtist,
                                {
                                    property: 'name',
                                    value: languageTranslations.value,
                                    language,
                                },
                            ]
                        } else {
                            return translationsByArtist
                        }
                    }, []
                )
                return {
                    ...allTranslations,
                    [index]: translationsByArtistObject,
                }
            }, {}
        )
        this.props.addTranslatedArtistsToAlbum(translationsByArtists, album)
    }

    renderAlbumTitle = ({
        input,
        meta: {
            error,
            touched,
        },
        album,
    }) => {
        const isSingle = this.props.album.numberOfTracks === 1
        let singleTrackTitleNotSet = false
        if (isSingle) {
            const singleTrackTitle = this.props.album.tracks[0].title
            singleTrackTitleNotSet = isPlaceholderTitle(singleTrackTitle)
            input.value = singleTrackTitleNotSet
                ? t('selectorUntitledAlbumTitle')
                : singleTrackTitle
        }
        return (
            <Field
                fieldName="title"
                label={t('containerReleasePackageFormLabelTitle')}
                error={!isSingle && touched ? t(error) : null}
                helpText={isSingle ? t('containerReleaseSettingsFormTitleDisabled') : null}
            >
                <TranslatableInput
                    source="release.title"
                    value={input.value}
                    translations={album.translations}
                    onSave={(translations) => {
                        this.onTitleTranslationSave(translations, album)
                        this.props.validateAlbumTitleWithMetadata()
                    }}
                    disabled={isSingle}
                >
                    <input
                        className="c-input"
                        type="text"
                        disabled={isSingle}
                        placeholder={t('selectorUntitledAlbumTitle')}
                        {...input}
                    />
                </TranslatableInput>
            </Field>
        )
    }

    renderArtistInput = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
        album,
    }) => (
        <ArtistLookup
            fieldName="primaryArtists"
            artists={value}
            album={album}
            label={t('containerReleaseSettingsFormLabelMainArtists')}
            placeholder={t('containerReleaseSettingsFormPlaceholderMainArtist')}
            duplicateErrorMsg={term => tmarkdown('componentArtistLookupPrimaryDuplicateError', term)}
            helpTextMsg={t('componentArtistLookupPrimaryHelpText')}
            popover={{
                title: t('releaseFlowSettingsArtistGuidelinesTitle'),
                text: tmarkdown('releaseFlowSettingsArtistGuidelinesText'),
            }}
            error={touched ? t(error) : null}
            onChange={(options) => {
                const artists = options.map(opt => ({
                    ...opt.value,
                    artistType: ARTIST_TYPE_PRIMARY,
                }))
                onChange(artists)
                onBlur()
            }}
            source="release.artistLookup"
            onTranslationSave={(translations) => {
                this.onArtistTranslationSave(translations, album)
                this.props.validateAlbumArtistNamesWithMetadata()
            }}
            artistTranslations={album.artists}
        />
    )

    renderGenreSelect = ({
        input,
        meta: {
            error,
            touched,
        },
        genres,
        isFetchingGenres,
    }) => (
        <Field
            fieldName="genre"
            label={t('containerReleaseSettingsFormLabelGenre')}
            error={touched ? t(error) : null}
            isFetching={isFetchingGenres}
        >
            <Select
                disabled={isFetchingGenres}
                options={genres.map(genre => ({
                    value: genre.id,
                    label: t(`genre_${genre.id}`),
                }))}
                placeholder={t('containerGenreSelectLabelGenre')}
                sortOrder="ASC"
                value={input.value}
                onChange={(versionId) => {
                    input.onChange(versionId)
                    input.onBlur(versionId)
                }}
            />
        </Field>
    )

    renderLanguageSelect = ({
        input,
        meta: {
            error,
            touched,
        },
        languages,
        isFetchingLanguages,
    }) => {
        const languageOpts = languages.map((language) => {
            const txLabelKey = `dspLanguage_${language.id}`
            const translatedLabel = t(txLabelKey)
            return ({
                value: language.languageCode,
                label: (translatedLabel !== txLabelKey) ? translatedLabel : language.languageName,
            })
        })

        return (
            <Field
                fieldName="language"
                label={t('containerReleaseSettingsFormLabelLanguage')}
                error={touched ? t(error) : null}
                isFetching={isFetchingLanguages}
            >
                <Select
                    disabled={isFetchingLanguages}
                    importantOptionValues={importantLanguages}
                    disableSortImportant
                    options={languageOpts}
                    placeholder={t('containerLanguageSelectLabelLanguage')}
                    searchable
                    sortOrder="ASC"
                    value={input.value}
                    onChange={(versionId) => {
                        input.onChange(versionId)
                        input.onBlur(versionId)
                    }}
                />
            </Field>
        )
    }

    renderReleaseDatePicker = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
    }) => (
        <ReleaseDate
            fieldName="releaseDate"
            label={t('containerReleaseSettingsFormLabelDate')}
            error={touched ? t(error) : null}
            value={value}
            onChange={(date) => {
                onChange(date)
                onBlur(date)
            }}
        />
    )

    renderOriginalReleaseDate = ({
        input: {
            value,
            onChange,
            onBlur,
        },
    }) => (
        <Field
            fieldName="originalReleaseDate"
            label={t('containerReleaseSettingsFormOriginalDateLabel')}
            popover={{
                title: t('containerReleaseSettingsFormOriginalDateHelpTitle'),
                text: t('containerReleaseSettingsFormOriginalDateHelpText'),
            }}
        >
            <DatePicker
                onChange={(date) => {
                    const result = date ? moment(date).toDate() : date
                    onChange(result)
                    onBlur(result)
                }}
                selected={value ? moment(value).toDate() : null}
                maxDate={moment().add(-1, 'days').toDate()}
                placeholderText={t('containerReleaseSettingsFormOriginalDatePlaceHolder')}
                showYearDropdown
                dateFormatCalendar="MMMM"
            />
        </Field>
    )

    renderUPCField = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
        disabled,
    }) => (
        <Field
            fieldName="upc"
            label={t('containerReleaseSettingsFormUPCLabel')}
            error={touched ? t(error) : null}
            popover={{
                title: t('containerReleaseSettingsFormUPCHelpTitle'),
                text: t('containerReleaseSettingsFormUPCHelpText'),
            }}
        >
            <TextInput
                placeholder={t('containerReleaseSettingsFormUPCPlaceHolder')}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        </Field>
    )

    renderTerritoryRestrict = ({
        input: {
            value,
            onChange,
            onBlur,
        },
    }) => (
        <TerritoryRestrict
            fieldName="territories"
            label={t('containerReleaseSettingsFormLabelRestrictions')}
            countries={value || []}
            onChange={(countries) => {
                onChange(countries)
                onBlur(countries)
            }}
        />
    )

    onSocialOptedIn = (userOptedIn) => {
        this.props.onSocialDeliveryRelease(this.props.release.id, userOptedIn)
    }

    onYoutubeOptedIn = (userOptedIn) => {
        this.props.onYoutubeToggleRelease(this.props.release.id, userOptedIn)
    }

    render() {
        const {
            album,
            genres,
            isFetchingGenres,
            isFetchingLanguages,
            languages,
        } = this.props

        const {
            isDisplayingAdvancedSettings,
        } = this.state

        return (
            <article className="c-release-settings-form">
                <header>
                    <h1>{t('viewReleaseSettingsHeading')}</h1>
                    <TrackUploadProgress id={album.id} />
                </header>

                <Frame contentTint="light" borderImageId="dune-2" borderFlipVerticaly>
                    <ReduxField
                        name="album.title"
                        album={album}
                        component={this.renderAlbumTitle}
                    />

                    <ReduxField
                        name="album.artists"
                        album={album}
                        component={this.renderArtistInput}
                    />

                    <ReduxField
                        name="album.genreId"
                        genres={genres}
                        isFetchingGenres={isFetchingGenres}
                        component={this.renderGenreSelect}
                    />

                    <ReduxField
                        name="album.languageId"
                        languages={languages}
                        isFetchingLanguages={isFetchingLanguages}
                        component={this.renderLanguageSelect}
                    />
                </Frame>

                <Frame contentTint="light" borderImageId="dune-2">

                    <ConditionalAlert id="christmas-delivery-alert" type="christmas">
                        <TranslateMarkdown id="containerReleaseDateChristmasDelivery" />
                    </ConditionalAlert>

                    <ConditionalAlert id="offboarding" type="fatal-error">
                        <TranslateMarkdown id="containerReleaseDateFinalDelivery" />
                    </ConditionalAlert>

                    <ReduxField
                        name="album.releaseDate"
                        component={this.renderReleaseDatePicker}
                    />

                    <ReduxField
                        name="album.countries"
                        component={this.renderTerritoryRestrict}
                    />
                </Frame>

                <Frame contentTint="light" borderImageId="dune-2">
                    <Field
                        label={t('releaseFlowDeliveryOptionsTitle')}
                        popover={{
                            title: t('releaseFlowDeliveryOptionsSocialHelpTitle'),
                            text: tmarkdown('releaseFlowDeliveryOptionsSocialHelpText'),
                        }}
                    >
                        <StoreDeliveryOptions
                            onSocialDeliveryRelease={this.onSocialOptedIn}
                            onYoutubeToggleRelease={this.onYoutubeOptedIn}
                            userOptedIn={this.props.socialOptedIn}
                            userYoutubeOptedIn={this.props.youtubeOptedIn}
                            onTrackStartTimeUpdate={this.onReleaseTrackStartTimeUpdate}
                            allTracks={this.props.allTracks}
                        />
                    </Field>
                </Frame>

                <Frame contentTint="light" borderImageId="dune-2" borderFlipVerticaly>
                    <Field
                        label={t('containerReleaseSettingsFormAdvancedLabel')}
                    >
                        <p>{t('containerReleaseSettingsFormAdvancedText')}</p>

                        {isDisplayingAdvancedSettings ? (
                            <div>
                                <ReduxField
                                    name="album.originalReleaseDate"
                                    component={this.renderOriginalReleaseDate}
                                />

                                <ReduxField
                                    name="album.upc"
                                    disabled={album.status !== statuses.RELEASE_STATUS_NOT_COMPLETED}
                                    component={this.renderUPCField}
                                />
                            </div>
                        ) : null}

                        <p className="c-release-settings-form-advanced-toggle" onClick={this.toggleAdvanceSettings}>
                            {isDisplayingAdvancedSettings
                                ? `- ${t('containerRealeaseSettingFormAdvanceSettingsHide')}`
                                : `+ ${t('containerRealeaseSettingFormAdvanceSettingsShow')}`}
                        </p>
                    </Field>
                </Frame>
            </article>
        )
    }
}
