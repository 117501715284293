import React from 'react'
import { PropTypes } from 'prop-types'
import Button from 'components/Button/Button'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import t from 'utilities/translate'

const EditProfileShareButton = ({ url, addNotification }) => {
    const handleClick = () => {
        if (navigator.share) {
            navigator.share({
                title: t('componentEditProfileShareProfileTitle'),
                text: t('componentEditProfileShareProfileMessage'),
                url,
            }).then(() => {
                addNotification('viewProfileShareProfile')
            }).catch((error) => {
                addNotification('viewProfileShareProfileError', error)
            })
        } else {
            const temporaryElement = document.createElement('textarea')
            temporaryElement.value = url
            temporaryElement.setAttribute('readonly', '')
            temporaryElement.style = { position: 'absolute', left: '-9999px' }
            document.body.appendChild(temporaryElement)
            temporaryElement.select()
            document.execCommand('copy')
            document.body.removeChild(temporaryElement)
            addNotification('viewProfileCopyUrlToClipboard')
        }
    }

    return (
        <Button className="c-edit-profile-button-share" onClick={handleClick}>
            {navigator.share ? (
                <FontAwesomeIcon className="c-edit-profile-button-icon" icon="faShareAlt" />
            ) : <FontAwesomeIcon className="c-edit-profile-button-icon" icon="faLink" />}
        </Button>
    )
}

EditProfileShareButton.propTypes = {
    url: PropTypes.string.isRequired,
    addNotification: PropTypes.func.isRequired,
}

export default EditProfileShareButton
