import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import CONSENT_SELECTORS from 'constants/consent'
import { updateUser } from 'actions/UserActions'
import { getConsentStatus } from 'utilities/consent'
import { getGaClientId } from 'utilities/analytics'

const UpdateConsent = (props) => {
    const { user } = props

    const saveUserMetadata = (body = {}) => {
        if (user) {
            const gaClientId = getGaClientId()
            if (gaClientId !== null) {
                body.gaClientId = gaClientId
            }

            if (Object.keys(body).length > 0) {
                props.updateUser(body)
            }
        }
    }

    const updateConsentStatus = () => {
        const body = {}

        const consentStatus = getConsentStatus()
        if (consentStatus !== null) {
            body.hasConsentedMarketing = consentStatus
        }

        saveUserMetadata(body)
    }

    document.body.addEventListener('click', (e) => {
        // When 'Accept All' btn from the banner or the preferences modal is clicked
        if (e.target.id === CONSENT_SELECTORS.ACCEPT_ALL_BUTTON
            || (e.target.id === CONSENT_SELECTORS.ACCEPT_ALL_MODAL_BUTTON
                && e.target.innerText.toLowerCase().includes('accept all'))) {
            saveUserMetadata({ hasConsentedMarketing: true })
        }

        // When 'Decline All' btn from the banner or from the preferences modal is clicked
        if (e.target.id === CONSENT_SELECTORS.DECLINE_ALL_BUTTON
            || (e.target.id === CONSENT_SELECTORS.DECLINE_ALL_MODAL_BUTTON && e.target.innerText !== 'Cancel')) {
            saveUserMetadata({ hasConsentedMarketing: false })
        }

        // When 'Save preferences' from the modal is clicked
        if (e.target.id === CONSENT_SELECTORS.ACCEPT_ALL_MODAL_BUTTON
            && !e.target.innerText.toLowerCase().includes('accept all')) {
            updateConsentStatus()
        }
    })

    return null
}

UpdateConsent.propTypes = {
    user: PropTypes.object,
}

const mapStateToProps = state => ({
    ...state.app,
    user: state.users.user,
})

const mapDispatchToProps = {
    updateUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateConsent)
