import * as types from 'constants/PopupActionTypes'
import { TranslationKey } from 'contexts/localisation/translateUtilities'

// todo validate this
type PopupConfig = {
    type: string
    title: string
    text?: string | {
        translation: TranslationKey
        params: any[]
        isMarkupText: boolean
    }
    textIsComponent?: boolean
    acceptButtonText?: string
    closeButtonText?: string
    showClose?: boolean
    onAccept?: () => void
    onClose?: () => void
    buttons?: any
}

export const addPopup = ({
    type,
    title,
    text,
    textIsComponent,
    acceptButtonText,
    closeButtonText,
    showClose,
    onAccept,
    onClose,
    buttons,
}: PopupConfig) => ({
    type: types.ADD_POPUP,
    payload: {
        type,
        title,
        text,
        textIsComponent,
        acceptButtonText,
        closeButtonText,
        showClose,
        onAccept,
        onClose,
        buttons,
    },
})

export const removePopup = (id: string) => ({
    type: types.REMOVE_POPUP,
    payload: {
        id,
    },
})
