import moment from 'moment'

const releaseDateRangeRestrictions = [
    {
        label: 'christmas-period',
        currDateMin: new Date('2021-11-10'),
        currDateMax: new Date('2022-01-06'),
        minCalendarDays: 16,
    },
    {
        label: 'offboarding',
        currDateMin: new Date('2022-09-20'),
        currDateMax: new Date('2030-09-20'),
        maxDate: window.env.OFFBOARDING_MAX_RELEASE_DATE,
    },
]

export const getCurrentReleaseRestrictions = () => releaseDateRangeRestrictions
    .find(r => moment().isSameOrAfter(r.currDateMin) && moment().isBefore(r.currDateMax))

export const getRestrictedMinReleaseDate = () => {
    const releaseDateRestrictions = getCurrentReleaseRestrictions()
    let minReleaseDate

    if (releaseDateRestrictions) {
        if (releaseDateRestrictions.newDateMin) {
            minReleaseDate = moment(releaseDateRestrictions.newDateMin)
        } else if (releaseDateRestrictions.minCalendarDays) {
            minReleaseDate = moment().add(releaseDateRestrictions.minCalendarDays, 'days')
        }
    }

    return minReleaseDate
}

export const getRestrictedMaxReleaseDate = () => {
    const releaseDateRestrictions = getCurrentReleaseRestrictions()
    let maxReleaseDate

    if (releaseDateRestrictions && releaseDateRestrictions.maxDate) {
        maxReleaseDate = moment(releaseDateRestrictions.maxDate)
    }

    return maxReleaseDate
}
