import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Field, Select } from 'components'

export default class LanguageSelect extends Component {
    componentDidMount() {
        if (!this.props.translationLanguages.length) {
            this.props.fetchTranslationLanguages()
        }
    }

    render() {
        const {
            label, translationLanguages, nativeTranslationLanguages, value, placeholder, error,
        } = this.props

        const selectClassName = error ? 'select-error' : ''
        const languages = this.props.showNativeLanguages ? nativeTranslationLanguages : translationLanguages
        return (
            <Field
                label={label}
                error={error || ''}
            >
                <Select
                    disabled={translationLanguages.length === 0}
                    options={languages}
                    searchable
                    selectClassName={selectClassName}
                    value={value}
                    placeholder={placeholder}
                    onChange={this.props.onChange}
                />
            </Field>
        )
    }
}

LanguageSelect.propTypes = {
    label: PropTypes.string,
    translationLanguages: PropTypes.array.isRequired,
    nativeTranslationLanguages: PropTypes.array.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    fetchTranslationLanguages: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    showNativeLanguages: PropTypes.bool,
}
