import React from 'react'
import * as PropTypes from 'prop-types'
import PopupModalBase from 'components/common/modals/base/PopupModalBase'
import { Button } from 'components/index'
import * as popupButtons from 'constants/PopupButtons'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'

const Dialog = ({
    type,
    title,
    subtitle,
    text,
    acceptButtonText,
    closeButtonText = popupButtons.close,
    showClose = true,
    onAccept,
    onClose,
    buttons,
    onCloseModal,
}) => {
    const { t } = useLocaleContext()

    const alertTitle = title ? t(title) : t('componentPopupDefaultTitle')
    const alertSubtitle = subtitle ? t(subtitle) : null

    const textIsString = (typeof text === 'string')
    let bodyText = null
    if (textIsString) {
        bodyText = text ? t(text) : t('componentPopupDefaultText')
    } else if (text && text.isMarkupText) {
        bodyText = <TranslateMarkdown id={text.translation} args={text.params} className="c-dialog-text-markdown" />
    }

    const onAcceptWrapped = () => {
        if (typeof onAccept === 'function') {
            onAccept()
        }
        onCloseModal()
    }

    const onCloseWrapped = () => {
        if (typeof onClose === 'function') {
            onClose()
        }
        onCloseModal()
    }

    const onCustomButtonWrapped = (onBtnClick) => {
        if (typeof onBtnClick === 'function') {
            onBtnClick()
        }
        onCloseModal()
    }

    return (
        <PopupModalBase
            onClose={onCloseWrapped}
            title={alertTitle}
            subTitle={alertSubtitle}
            type={type || 'default'}
            noBody={!bodyText}
        >
            { bodyText ? (
                <div className="modal-content">
                    {bodyText}
                </div>
            ) : null }

            <footer className="modal-actions">
                { showClose && <Button isLink onClick={onCloseWrapped}>{t(closeButtonText)}</Button> }
                { buttons ? buttons.map(btn => (
                    <Button
                        key={btn.text.replace(' ', '').toLowerCase()}
                        onClick={onCustomButtonWrapped(btn.click)}
                    >
                        {t(btn.text)}
                    </Button>
                )) : null}
                {acceptButtonText ? (
                    <Button onClick={onAcceptWrapped}>{t(acceptButtonText)}</Button>
                ) : null}
            </footer>
        </PopupModalBase>
    )
}

Dialog.propTypes = {
    type: PropTypes.oneOf(['default', 'confirmation', 'info', 'error']).isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        translation: PropTypes.string.isRequired,
        params: PropTypes.array,
        isMarkupText: PropTypes.bool,
    })]),
    acceptButtonText: PropTypes.string,
    closeButtonText: PropTypes.string,
    showClose: PropTypes.bool,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    buttons: PropTypes.array,

    onCloseModal: PropTypes.func.isRequired,
}

export default Dialog
