import * as types from 'constants/ProfileActionTypes'
import * as dspTypes from 'constants/DspLookupActionTypes'
import {
    hydrateArtistProfile,
    hydrateArtistFiles,
    hydrateArtistLinks,
    hydrateArtist,
} from 'utilities/hydrator'
import { filterFiles, getFilesWithProcessingImage } from 'utilities/galleryManagement'
import { orderByDate } from 'utilities/orderByDate'
import * as authTypes from 'constants/AuthActionTypes'

const initialState = {
    data: null,
    isLoaded: false,
    isSubmitting: false,
    isPublishing: false,
    loadingFailed: false,
    isProcessingLogo: false,
    logoUploadProgress: 0,
    logoError: '',
    isProcessingHero: false,
    heroUploadProgress: 0,
    heroError: '',
    uploadGalleryCounter: 0,
    removeGalleryCounter: 0,
    galleryUploadProgress: 0,
    galleryError: '',
    tourDates: [],
    isGalleryReordered: false,
    isFetchingDsp: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        // FETCH
        case types.FETCH_DRAFT_PROFILE_REQUEST:
            return {
                ...state,
                loadingFailed: false,
                isLoaded: false,
            }

        case types.FETCH_DRAFT_PROFILE_SUCCESS:
            return {
                ...state,
                data: hydrateArtistProfile(action.payload),
                isLoaded: true,
            }

        case types.FETCH_DRAFT_PROFILE_FAILURE:
            return {
                ...state,
                loadingFailed: true,
            }

        case dspTypes.EDIT_PROFILE_INITIAL_DSP_LOOKUP_REQUEST:
            return {
                ...state,
                isFetchingDsp: true,
            }

        case dspTypes.EDIT_PROFILE_INITIAL_DSP_LOOKUP_SUCCESS:
            const spotifyLinkValue = hydrateArtist(action.payload.results[0])
            return {
                ...state,
                data: state.data.set('links', hydrateArtistLinks(
                    state.data.links,
                    spotifyLinkValue
                )),
                isFetchingDsp: false,
            }

        case dspTypes.EDIT_PROFILE_INITIAL_DSP_LOOKUP_FAILURE:
            return {
                ...state,
                isFetchingDsp: false,
            }

        case types.UPDATE_DRAFT_PROFILE:
            return {
                ...state,
                isSubmitting: true,
                logoError: initialState.logoError,
                heroError: initialState.heroError,
                galleryError: initialState.galleryError,
            }

        case types.UPDATE_DRAFT_PROFILE_SUCCESS:
            return {
                ...state,
                data: hydrateArtistProfile(action.payload),
                isSubmitting: false,
            }

        case types.UPDATE_DRAFT_PROFILE_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                isPublishing: false,
            }

        // UPLOAD LOGO
        case types.UPLOAD_LOGO_REQUEST:
            return {
                ...state,
                isProcessingLogo: true,
                logoError: initialState.logoError,
            }

        case types.UPLOAD_LOGO_SUCCESS:
            return {
                ...state,
                data: state.data.set('files', hydrateArtistFiles(action.payload)),
                isProcessingLogo: false,
                logoError: initialState.logoError,
            }

        case types.UPLOAD_LOGO_FAILURE:
            return {
                ...state,
                isProcessingLogo: false,
                logoError: 'uploadEditProfileLogoFileError',
            }

        case types.UPLOAD_LOGO_PROGRESS:
            return {
                ...state,
                logoUploadProgress: action.payload,
            }

        case types.UPLOAD_LOGO_VALIDATION_ERROR:
            return {
                ...state,
                isProcessingLogo: false,
                logoError: action.payload.error,
            }

        // REMOVE LOGO
        case types.REMOVE_LOGO_REQUEST:
            return {
                ...state,
                isProcessingLogo: true,
                logoError: initialState.logoError,
            }

        case types.REMOVE_LOGO_SUCCESS:
            return {
                ...state,
                data: state.data.set(
                    'files',
                    hydrateArtistFiles({
                        ...state.data.files.toJS(),
                        logo: {},
                    })
                ),
                isProcessingLogo: false,
                logoError: initialState.logoError,
            }

        case types.REMOVE_LOGO_FAILURE:
            return {
                ...state,
                isProcessingLogo: false,
                logoError: 'removeEditProfileLogoError',
            }

        // UPLOAD HERO
        case types.UPLOAD_HERO_REQUEST:
            return {
                ...state,
                isProcessingHero: true,
                heroError: initialState.heroError,
            }

        case types.UPLOAD_HERO_SUCCESS:
            return {
                ...state,
                data: state.data.set('files', hydrateArtistFiles(action.payload)),
                isProcessingHero: false,
                heroError: initialState.heroError,
            }

        case types.UPLOAD_HERO_FAILURE:
            return {
                ...state,
                isProcessingHero: false,
                heroError: 'uploadEditProfileHeroFileError',
            }

        case types.UPLOAD_HERO_PROGRESS:
            return {
                ...state,
                heroUploadProgress: action.payload,
            }

        case types.UPLOAD_HERO_VALIDATION_ERROR:
            return {
                ...state,
                isProcessingHero: false,
                heroError: action.payload.error,
            }

        // REMOVE HERO
        case types.REMOVE_HERO_REQUEST:
            return {
                ...state,
                isProcessingHero: true,
                heroError: initialState.heroError,
            }

        case types.REMOVE_HERO_SUCCESS:
            return {
                ...state,
                data: state.data.set(
                    'files',
                    hydrateArtistFiles({
                        ...state.data.files.toJS(),
                        hero: {},
                    })
                ),
                isProcessingHero: false,
                heroError: initialState.heroError,
            }

        case types.REMOVE_HERO_FAILURE:
            return {
                ...state,
                isProcessingHero: false,
                heroError: 'removeEditProfileHeroError',
            }

        // UPLOAD GALLERY IMAGE
        case types.UPLOAD_GALLERY_REQUEST:
            return {
                ...state,
                uploadGalleryCounter: state.uploadGalleryCounter + 1,
                galleryError: initialState.galleryError,
            }

        case types.UPLOAD_GALLERY_SUCCESS:
            return {
                ...state,
                data: state.data.set('files', hydrateArtistFiles(action.payload)),
                uploadGalleryCounter: Math.max(state.uploadGalleryCounter - 1, 0),
                galleryError: initialState.galleryError,
            }

        case types.UPLOAD_GALLERY_FAILURE:
            return {
                ...state,
                uploadGalleryCounter: Math.max(state.uploadGalleryCounter - 1, 0),
                galleryError: 'uploadEditProfileGalleryFileError',
            }

        case types.UPLOAD_GALLERY_PROGRESS:
            return {
                ...state,
                galleryUploadProgress: action.payload,
            }

        case types.UPLOAD_GALLERY_VALIDATION_ERROR:
            return {
                ...state,
                galleryError: action.payload.error,
            }

        // REMOVE GALLERY IMAGE
        case types.REMOVE_GALLERY_REQUEST:
            return {
                ...state,
                data: state.data.set(
                    'files',
                    hydrateArtistFiles(getFilesWithProcessingImage(
                        state.data.files.toJS(),
                        action.meta.groupId,
                        true
                    ))
                ),
                removeGalleryCounter: state.removeGalleryCounter + 1,
                galleryError: initialState.galleryError,
            }

        case types.REMOVE_GALLERY_SUCCESS:
            return {
                ...state,
                data: state.data.set(
                    'files',
                    hydrateArtistFiles(filterFiles(
                        state.data.files.toJS(),
                        action.meta.groupId
                    ))
                ),
                removeGalleryCounter: Math.max(state.removeGalleryCounter - 1, 0),
                galleryError: initialState.galleryError,
            }

        case types.REMOVE_GALLERY_FAILURE:
            return {
                ...state,
                data: state.data.set(
                    'files',
                    hydrateArtistFiles(getFilesWithProcessingImage(
                        state.data.files.toJS(),
                        action.meta.groupId,
                        false
                    ))
                ),
                removeGalleryCounter: Math.max(state.removeGalleryCounter - 1, 0),
                galleryError: 'removeEditProfileGalleryError',
            }

        case types.REORDER_GALLERY_IMAGES:
            return {
                ...state,
                data: state.data.set(
                    'files',
                    hydrateArtistFiles({
                        ...state.data.files.toJS(),
                        gallery: action.payload.gallery,
                    })
                ),
                isGalleryReordered: true,
            }

        case types.UPDATE_GALLERY_ORDER_SUCCESS:
            return {
                ...state,
                data: state.data.set(
                    'files',
                    hydrateArtistFiles(action.payload.files)
                ),
                isGalleryReordered: false,
            }

        case types.PUBLISH_DRAFT_PROFILE:
            return {
                ...state,
                isPublishing: true,
            }

        case types.PUBLISH_DRAFT_PROFILE_SUCCESS:
            return {
                ...state,
                data: state.data
                    .set('hasPublicProfile', action.payload.artist.hasPublicProfile)
                    .set('profileVisibility', action.payload.artist.profileVisibility),
                isPublishing: false,
            }

        case types.PUBLISH_DRAFT_PROFILE_FAILURE:
            return {
                ...state,
                isPublishing: false,
            }
        case types.UPLOAD_TOUR_SUCCESS:
            const tourDatesUpload = state.data.get('tourDates')
            return {
                ...state,
                data: state.data.set(
                    'tourDates',
                    orderByDate([...tourDatesUpload, action.payload])
                ),
            }
        case types.REMOVE_TOUR_SUCCESS:
            const tourDatesRemove = state.data.get('tourDates')
            const filteredTourDates = tourDatesRemove.filter(tourDate => tourDate.id !== action.meta.tourId)
            return {
                ...state,
                data: state.data.set(
                    'tourDates',
                    orderByDate(filteredTourDates)
                ),
            }
        case types.SURVEY_VIEWED_SUCCESS:
            const surveys = state.data.set('surveys') || []
            return {
                ...state,
                data: state.data.set('surveys', [
                    ...surveys,
                    action.payload,
                ]),
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
