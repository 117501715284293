export default function validate(fields, rules, appState) {
    const messages = {}

    Object.keys(fields).forEach((field) => {
        if (!rules[field]) return
        if (!Array.isArray(rules[field])) throw new Error('Expected field rules to be an array.')

        rules[field].some((rule) => {
            if (typeof rule !== 'object') throw new Error('Expected rule to be an object.')
            if (typeof rule.test !== 'function') throw new Error('Expected rule have a test method.')
            if (typeof rule.message !== 'string') throw new Error('Expected rule have a message.')

            if (!rule.test(fields[field], fields, appState)) {
                messages[field] = rule.message

                return true
            } else {
                return false
            }
        })
    })

    return messages
}

// LEGACY

export function legacyValidate(fields, rules, appState) {
    const errors = {}

    Object.keys(fields).forEach((field) => {
        if (!rules[field]) return
        if (!Array.isArray(rules[field])) throw new Error('Expected field rules to be an array.')

        rules[field].some((rule) => {
            if (typeof rule !== 'object') throw new Error('Expected rule to be an object.')
            if (typeof rule.test !== 'function') throw new Error('Expected rule have a test method.')
            if (typeof rule.message !== 'string') throw new Error('Expected rule have a message.')

            const validationType = rule.error ? 'error' : 'warning'

            if (rule.test(fields[field], fields, appState)) {
                return false
            } else {
                errors[field] = { [validationType]: rule.message }
                return true
            }
        })
    })

    return errors
}

export function isValid(fields, rules, appState) {
    const messages = legacyValidate(fields, rules, appState)

    return !Object.keys(messages).find(field => !!messages[field].error)
}

export function displayError(errors, key) {
    return (errors[key] && errors[key].error && errors[key].message) || ''
}

export function displayWarning(errors, key) {
    return (errors[key] && !errors[key].error && errors[key].message) || ''
}
