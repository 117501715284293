import moment from 'moment'

export function formatAudioDuration(duration) {
    const audioDuration = moment.duration(duration, 'seconds')

    const durationSeconds = audioDuration.seconds().toLocaleString('en-US', { minimumIntegerDigits: 2 })
    const durationMinutes = audioDuration.minutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })
    const durationHours = audioDuration.hours()

    return (
        duration
            ? `(${durationHours ? `${durationHours}:` : ''}${durationMinutes}:${durationSeconds})`
            : ''
    )
}
