import { createSelector } from 'reselect'

import t from 'utilities/translate'
import { formatDateLocalised } from 'utilities/date'
import { AppStoreState } from 'store/store-types'
import { Transaction } from 'models/transaction'

export interface IFormattedTransaction {
    date: string
    note: string
    currency: Transaction['currency']
    amount: Transaction['amount']
    status: Transaction['status']
    psp: Transaction['psp']
    recipient: Transaction['recipient']
    type: Transaction['type']
}

export const transactionsSelector = createSelector(
    (state: AppStoreState) => state.wallet.transactions,
    transactions => transactions
        .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
        // Filter out transactions that would be rounded to 0.00
        .filter(transaction => Math.abs(parseFloat(transaction.amount)).toFixed(2) !== '0.00')
        .map(transaction => ({
            // the format should be based on the local as well
            date: formatDateLocalised(transaction.created),
            // it is not the purpose of a selector to do that !, i18n SHOULD be ONLY in the react side.
            note: t('selectorTransactionsNote', transaction.recipient),
            currency: transaction.currency,
            amount: transaction.amount,
            status: transaction.status,
            type: transaction.type,
            recipient: transaction.recipient,
            psp: transaction.psp,
        }) as IFormattedTransaction)
)
