export const filterFiles = (files, groupId) => ({
    ...files,
    gallery: files.gallery.filter(image => image.groupId !== groupId),
})

const getImageIndex = (images, groupId) => images.findIndex(image => image.groupId === groupId)

export const getFilesWithProcessingImage = (files, groupId, value) => {
    const { gallery } = files
    const index = getImageIndex(gallery, groupId)
    gallery[index].isProcessing = value
    return {
        ...files,
        gallery: [...gallery],
    }
}
