const MIN_AUDIO_DURATION = 2

export const rules = {
    numChannels: {
        test: audioBufferObj => audioBufferObj.numberOfChannels === 2,
        message: 'audioUploadErrorNumChannels',
    },
    duration: {
        test: audioBufferObj => audioBufferObj.duration >= MIN_AUDIO_DURATION,
        message: 'audioUploadErrorDurationTooShort',
    },
}

export const getErrors = audioBuffer => Object.values(rules)
    .map(rule => (!rule.test(audioBuffer) ? rule.message : null))
    .filter(result => typeof result === 'string')
