import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { TrendsToggleItem } from './TrendsToggleItem'

export default class TrendsIconToggle extends Component {
    static propTypes = {
        icons: PropTypes.array.isRequired,
        toggleVisibility: PropTypes.func.isRequired,
    };

    handleOnChange = iconName => this.props.toggleVisibility(iconName)

    render() {
        const { icons } = this.props

        return (
            <div className="c-trends-icon-toggle">
                {icons.map(icon => (
                    <TrendsToggleItem
                        key={icon.name}
                        toggleIcon={icon}
                        onChange={this.handleOnChange}
                    />
                ))}
            </div>
        )
    }
}
