import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TrackUploadContainer from 'containers/TrackUpload/TrackUpload'
import { albumSelector } from 'selectors/albums'

const UploadTracks = props => (
    <TrackUploadContainer id={props.release.id} album={props.album} isMovingTracks={props.isMovingTracks} />
)

UploadTracks.propTypes = {
    album: PropTypes.object.isRequired,
    release: PropTypes.object.isRequired,
    isMovingTracks: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, props) => ({
    album: albumSelector(state, props.release.providerId),
    isMovingTracks: state.albums.performingUpdateRequest,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadTracks)
