import React, { Component, ReactElement } from 'react'
import * as PropTypes from 'prop-types'
import t from 'utilities/translate'
import { ColumnProps } from 'components/Table/Column'
import Checkbox from '../Checkbox/Checkbox'

type TableProps = {
    rows: any[]
    checkbox?: boolean
    onCheck?: (rowIndex: number) => void
    onCheckAll?: (value: boolean) => void
    children: React.ReactNode
}

/**
 * Table
 *
 * A table for viewing data and data related stuff
 *
 * Styleguide: components.table
 */
export default class Table extends Component<TableProps> {
    static propTypes = {
        children: PropTypes.node,
        rows: PropTypes.array.isRequired,
        checkbox: PropTypes.bool,
        onCheck: PropTypes.func,
        onCheckAll: PropTypes.func,
    }

    onCheckAll = () => {
        if (this.props.onCheckAll) {
            this.props.onCheckAll(!this.isAllChecked())
        }
    }

    onCheck = (i: number) => () => {
        if (this.props.onCheck) {
            this.props.onCheck(i)
        }
    }

    isAllChecked() {
        return !this.props.rows.find(row => !row.isChecked)
    }

    render() {
        const {
            children,
            rows,
            checkbox,
        } = this.props

        return (
            <table className="c-table">
                <thead>
                    <tr>
                        {checkbox
                        && (
                            <th key="checkbox">
                                <Checkbox
                                    label={t('componentTableLabelAll')}
                                    controlled
                                    checked={this.isAllChecked()}
                                    onChange={this.onCheckAll}
                                />
                            </th>
                        )}
                        {
                            React.Children.map(
                                children,
                                (child) => {
                                    const { heading } = (child as ReactElement<ColumnProps>).props
                                    return (<th key={heading}>{heading}</th>)
                                }
                            )
                        }
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row, i) => (
                        <tr key={i}>
                            {checkbox
                                && (
                                    <td>
                                        <Checkbox controlled checked={row.isChecked} onChange={this.onCheck(i)} />
                                    </td>
                                )}
                            {
                                React.Children.map(
                                    children,
                                    (child) => {
                                        const { prop, isNumber } = (child as ReactElement<ColumnProps>).props
                                        return (
                                            <td
                                                key={prop}
                                                className={isNumber ? 'c-table-cell-number' : ''}
                                            >
                                                {row[prop]}
                                            </td>
                                        )
                                    }
                                )
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}
