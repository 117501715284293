export const slugRegex = /[^a-z0-9-_]/igm
export const youtubeVideoRegex = /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\?&"'>]{1,11})/i // eslint-disable-line
export const anyUrlRegex = new RegExp(/^(http|https):\/\/(.{2,})/i)
export const httpRegex = new RegExp(/^(http|https):\/\//i)
export const spotifyUrlRegex = new RegExp(/^((?:http(?:s)?:\/\/)?open.spotify.com\/artist\/[a-zA-Z0-9])/i)
export const spotifyProfileRegex = new RegExp(/^((?:http(?:s)?:\/\/)?open.spotify.com\/artist\/[a-zA-Z0-9]|spotify:artist:[a-zA-Z0-9])/i) // eslint-disable-line
export const domainRegex = new RegExp(/https?:\/\/(www\.)?(.*\.)?(.*)(\..*\/?)/i)
export const nonAlphanumericCharactersRegex = /[^a-z0-9]/gi
export const verificationCodeRegex = new RegExp(/^[0-9]{6}$/)
export const phoneNumberRegex = new RegExp(/^[0-9]{4,12}$/)
export const phoneNumberAllowedSpecialCharactersRegex = new RegExp(/[ ()-]/g)
