import classnames from 'classnames'
import React from 'react'
import { PropTypes } from 'prop-types'
import Checkbox from 'components/Checkbox/Checkbox'
import Field from 'components/Field/Field'
import Radio from 'components/Radio/Radio'
import CountrySelect from 'containers/CountrySelect/CountrySelect'
import LanguageSelect from 'containers/LanguageSelect/LanguageSelect'
import t from 'utilities/translate'
import FontSelect from '../../containers/FontSelect/FontSelect'

export const Label = ({ label, required }) => (
    <label className={classnames(
        'c-field-label', ({
            required,
        })
    )}
    >
        <span>{label}</span>
    </label>
)

Label.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
}

export const InputField = ({
    input,
    meta,
    label,
    placeholder,
    required,
}) => {
    const { name, error, warning } = input
    const className = classnames('c-input', {
        error: !!error,
        warning: !!warning,
    })
    return (
        <div className={classnames(
            'c-epf-input', ({
                required,
            })
        )}
        >
            <Field
                elementName={`field-${name}`}
                label={label}
                error={meta.touched && meta.error ? t(meta.error) : null}
            >
                <input className={className} placeholder={placeholder} type="text" {...input} />
            </Field>
        </div>
    )
}

InputField.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string,
        error: PropTypes.string,
        warning: PropTypes.string,
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
}

export const InputFieldGroup = ({
    input,
    meta,
    label,
    placeholder,
    required,
    inputGroupText,
}) => {
    const inputRef = React.createRef()
    const {
        name, error, warning,
    } = input
    const className = classnames('c-input c-input-group', {
        error: !!error,
        warning: !!warning,
    })
    const handleGroupClick = () => {
        inputRef.current.focus()
    }

    return (
        <div className={classnames(
            'c-epf-input-group', ({
                required,
            })
        )}
        >
            <Field
                elementName={`field-${name}`}
                label={label}
                error={meta.touched && meta.error ? t(meta.error) : null}
            >
                <div className={className} onClick={handleGroupClick} onTouchStart={handleGroupClick}>
                    <div className="c-input-group-content-wrapper">
                        <input
                            className="c-input-group-element"
                            placeholder={placeholder}
                            type="text"
                            ref={inputRef}
                            {...input}
                        />
                        <span>{inputGroupText}</span>
                    </div>
                </div>
            </Field>
        </div>
    )
}

InputFieldGroup.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string,
        error: PropTypes.string,
        warning: PropTypes.string,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    inputGroupText: PropTypes.string,
}

export const CountrySelectField = ({
    input,
    meta,
    label,
    placeholder,
}) => {
    const { name, value, onChange } = input
    return (
        <div className="c-epf-select">
            <Field
                elementName={`field-${name}`}
                label={label}
                error={meta.touched && meta.error ? t(meta.error) : null}
            >
                <CountrySelect
                    elementName="country-select"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                />
            </Field>
        </div>
    )
}

CountrySelectField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
}

export const FontSelectField = ({
    input,
    meta,
    label,
    placeholder,
}) => {
    const { name, value, onChange } = input
    return (
        <div className="c-epf-select">
            <Field
                elementName={`field-${name}`}
                label={label}
                error={meta.touched && meta.error ? t(meta.error) : null}
            >
                <FontSelect
                    elementName="font-select"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                />
            </Field>
        </div>
    )
}

FontSelectField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.object,
    }).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
}

export const LanguageSelectField = ({
    input,
    meta,
    label,
    placeholder,
    showNativeLanguages = false,
}) => {
    const { name, value, onChange } = input
    return (
        <div className="c-epf-select">
            <Field
                elementName={`field-${name}`}
                label={label}
                error={meta.touched && meta.error ? t(meta.error) : null}
            >
                <LanguageSelect
                    elementName="language-select"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    showNativeLanguages={showNativeLanguages}
                />
            </Field>
        </div>
    )
}

LanguageSelectField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.object,
    }).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    showNativeLanguages: PropTypes.bool,
}

export const RadioField = ({ input, label, children }) => {
    const { name } = input
    return (
        <div className={`c-epf-${name}-radio-wrapper`}>
            <Radio label={label} {...input} />
            {children}
        </div>
    )
}

RadioField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export const CheckboxField = ({ input, label }) => (
    <div className="c-epf-checkbox-wrapper">
        <Checkbox label={label} {...input} />
    </div>
)

CheckboxField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
