import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import Field from 'components/Field/Field'
import Input from 'components/Inputs/Input'
import Button from 'components/Button/Button'
import Alert from 'components/Alert/Alert'
import ClipoutText from 'components/Frame/ClipoutText'

import t from 'utilities/translate'

export default class ForgotForm extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired,
        forgot: PropTypes.func.isRequired,
        username: PropTypes.string,
    };

    constructor(props) {
        super(props)
        this.emailRef = React.createRef()

        this.state = { isDone: false }
    }

    onForgot = (e) => {
        e.preventDefault()
        this.setState({ isDone: false })
        this.props.forgot(
            this.emailRef.current.getValue()
        ).then((action) => {
            if (action.payload) {
                this.setState({ isDone: true })
            }
        })
    }

    render() {
        const { isLoading, errors, username } = this.props
        const { isDone } = this.state

        return (
            <form className="c-forgot-form" onSubmit={this.onForgot}>
                <h3 className="c-book-side-title-large">
                    {t('containerForgotFormTitle')}
                </h3>

                <ClipoutText
                    type="h3"
                    backgroundImageId="dune-1"
                    className="c-book-side-title-small"
                >
                    {t('containerForgotFormTitle')}
                </ClipoutText>

                {isDone && <Alert type="success" small>{t('containerForgotFormDone')}</Alert>}

                <Field
                    label={t('containerForgotFormLabelEmail')}
                    error={t(errors.email)}
                >
                    <Input
                        ref={this.emailRef}
                        type="email"
                        error={'email' in errors}
                        value={username}
                    />
                </Field>

                <Button
                    className="c-book-button c-forgot-form-submit"
                    isSubmit
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    {t('containerForgotFormButtonReset')}
                </Button>
            </form>
        )
    }
}
