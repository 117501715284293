import moment from 'moment'
import t from 'utilities/translate'
import {
    isDateAfter, isDateSameOrAfter, testHasAlbumArtistNameMetadataRequiredForRegion,
    testHasAlbumTitleMetadataRequiredForRegion,
} from 'validations/rules'

import { earliestReleaseDateDays } from 'constants/enums/ReleaseEnums'
import { getCurrentReleaseRestrictions, getRestrictedMinReleaseDate } from 'modules/releaseDate'

export const rules = {
    title: [
        {
            test: title => !!title,
            message: t('album.Title600'),
            error: true,
        },
        {
            test: title => title !== 'Untitled',
            message: t('ruleAlbumMessageNotUntitled'),
            error: true,
        },
        {
            test: title => title.length <= 255,
            message: t('album.Title602'),
            error: true,
        },
        {
            test: title => !title.match(/\s{2,}/),
            message: t('album.Title605'),
            error: true,
        },
        {
            test: (title, fields, appState) => testHasAlbumTitleMetadataRequiredForRegion(
                { album: fields },
                appState.users.user.marketId
            ),
            message: t('releaseFlowReleaseTranslationError'),
            error: true,
        },
    ],
    genreId: [
        {
            test: genreId => genreId,
            message: t('ruleAlbumMessageGenre'),
            error: true,
        },
    ],
    languageId: [
        {
            test: languageId => languageId,
            message: t('ruleAlbumMessageLanguage'),
            error: true,
        },
    ],
    artists: [
        {
            test: artists => !!artists.length,
            message: t('ruleAlbumMessageArtists'),
            error: true,
        },
        {
            test: (artists, fields, appState) => testHasAlbumArtistNameMetadataRequiredForRegion(
                { album: fields },
                appState.users.user.marketId
            ),
            message: t('releaseFlowArtistTranslationError'),
            error: true,
        },
    ],
    releaseDate: [
        {
            test: releaseDate => (releaseDate
                ? !isDateAfter(moment().subtract(1, 'd'), true)(releaseDate)
                : true),
            message: t('ruleAlbumReleaseDateInThePast'),
            error: true,
        },
        {
            test: releaseDate => (releaseDate && !getCurrentReleaseRestrictions()
                ? !isDateAfter(moment().add(earliestReleaseDateDays, 'd'), true)(releaseDate)
                : true),
            message: t('ruleAlbumReleaseDateTooSoon'),
            error: true,
        },
        {
            test: (releaseDate) => {
                const restrictedMinDate = getRestrictedMinReleaseDate()
                if (!releaseDate || !restrictedMinDate) {
                    return true
                }

                return !isDateSameOrAfter(restrictedMinDate.startOf('day'), true)(releaseDate)
            },
            message: t('ruleAlbumReleaseDateNotInRange'),
            error: true,
        },
    ],
}
