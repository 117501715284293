import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

import t from 'utilities/translate'
import { connectService, disconnectService, connectAndDisconnectService } from 'actions/ServiceActions'
import { updateUser } from 'actions/UserActions'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Select from 'components/Select/Select'
import Button from 'components/Button/Button'
import Alert from 'components/Alert/Alert'
import { addPopup } from 'actions/PopupActions'
import { confirmationPopup } from 'constants/PopupTypes'
import * as popupButtons from 'constants/PopupButtons'

const RenderServiceTitleNotConnected = ({ service }) => {
    const { tmarkdown } = useLocaleContext()
    if (service.service === 'instagram') {
        return (
            <div className="c-social-account-service-status">
                <span>{t('containerInstagramSocialAccountServiceLabel', service.title)}</span>
                <div
                    className="c-social-account-service-status-description"
                    dangerouslySetInnerHTML={{
                        __html: tmarkdown('containerInstagramSocialAccountServiceLabelDescription'),
                    }}
                />
            </div>
        )
    } else {
        return (
            <div className="c-social-account-service-status">
                <span>{t('containerSocialAccountServiceLabel', service.title)}</span>
            </div>
        )
    }
}

class SocialAccountContainer extends Component {
    static propTypes = {
        invalidService: PropTypes.any,
        isSessionActive: PropTypes.bool.isRequired,
        isUpdatingUser: PropTypes.bool.isRequired,
        service: PropTypes.object.isRequired,
        page: PropTypes.object,
        connectService: PropTypes.func.isRequired,
        disconnectService: PropTypes.func.isRequired,
        connectAndDisconnectService: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        user: PropTypes.object,
        addPopup: PropTypes.func,
        isFacebookConnected: PropTypes.bool,
        isInstagramConnected: PropTypes.bool,
    }

    onConnect = () => {
        this.props.connectService(
            this.props.service.service,
            this.props.user
        )
    }

    onDisconnect = () => {
        const popUpTitleLocaleString = 'componentPopUpDisconnectFacebookInstagramTitle'
        const popUpTextLocaleString = 'componentPopUpDisconnectFacebookInstagramText'
        if (this.props.service.service === 'facebook' && this.props.isInstagramConnected) {
            this.props.addPopup({
                type: confirmationPopup,
                title: popUpTitleLocaleString,
                text: {
                    translation: popUpTextLocaleString,
                    params: ['Facebook', 'Instagram'],
                    isMarkupText: true,
                },
                acceptButtonText: popupButtons.confirm,
                closeButtonText: popupButtons.cancel,
                onAccept: this.disconnectService,
            })
        } else if (this.props.service.service === 'instagram' && this.props.isFacebookConnected) {
            this.props.addPopup({
                type: confirmationPopup,
                title: popUpTitleLocaleString,
                text: {
                    translation: popUpTextLocaleString,
                    params: ['Instagram', 'Facebook'],
                    isMarkupText: true,
                },
                acceptButtonText: popupButtons.confirm,
                closeButtonText: popupButtons.cancel,
                onAccept: this.disconnectService,
            })
        } else {
            this.disconnectService()
        }
    }

    onConnectAndDisconnectService = () => {
        this.props.connectAndDisconnectService(
            this.props.service.service
        )
    }

    onChangePage = (id) => {
        const { service } = this.props.service
        const page = this.props.service.pages.find(p => p.value === id)

        page.getUrl().then(url => this.props.updateUser({
            [service]: {
                id,
                data: {
                    url,
                },
            },
        }))
    }

    getPageUrl() {
        const { page } = this.props

        return page && page.data && page.data.url
    }

    disconnectService = () => {
        this.props.disconnectService(
            this.props.service.service
        )
    }

    render() {
        const {
            isSessionActive, service, page, invalidService, isUpdatingUser,
        } = this.props
        const pageUrl = this.getPageUrl()

        const isAlreadyInUse = invalidService === service.service
        const isConnected = !isAlreadyInUse && service.valid

        return (
            <div className="c-social-account">
                <div className="c-social-account-details">

                    <div className="c-social-account-service">
                        <div className="c-social-account-service-icon">
                            <FontAwesomeIcon icon={service.icon} fixedWidth size="3x" />
                        </div>

                        {isConnected
                            ? (
                                <div className="c-social-account-service-status">
                                    <span>{t('containerSocialAccountUsernameLabel')}</span>
                                    <span className="c-social-account-service-username">{service.username}</span>
                                </div>
                            )
                            : (<RenderServiceTitleNotConnected service={service} />)}
                    </div>

                    <div className="c-social-account-service-actions">
                        <div className="c-social-account-btn">
                            {isConnected
                                && (
                                    <Button onClick={isSessionActive
                                        ? this.onDisconnect
                                        : this.onConnectAndDisconnectService}
                                    >
                                        {t('containerSocialAccountButtonDisconnect')}
                                    </Button>
                                )
                            || <Button onClick={this.onConnect}>{t('containerSocialAccountButtonConnect')}</Button>}
                        </div>
                    </div>

                    <div className="c-social-account-pages">
                        {(isConnected && service.pages.length > 0)
                            && (
                                <Select
                                    ref="page"
                                    options={service.pages}
                                    value={page ? page.identifier : null}
                                    onChange={this.onChangePage}
                                    disabled={isUpdatingUser}
                                />
                            )
                        || (isConnected && pageUrl)
                            && <a href={pageUrl} target="_blank" rel="noopener noreferrer">{pageUrl}</a>}
                    </div>
                </div>
                {!isAlreadyInUse && !isConnected && service.partnerID && (
                    <div>
                        <Alert type="error">{t('containerSocialAccountReconnect')}</Alert>
                    </div>
                )}

                {isAlreadyInUse
                    && (
                        <div>
                            <Alert type="error">
                                {t('containerSocialAccountServiceError', invalidService.partnerID)}
                            </Alert>
                        </div>
                    )}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    isSessionActive: state.services.isSessionActive,
    invalidService: state.services.invalidService,
    invalidServiceUserID: state.services.invalidService.partnerID,
    page: state.users.user.providers[props.service.service],
    isUpdatingUser: state.users.isUpdatingUser,
    user: state.users.user,
    isFacebookConnected: state.services.services.filter(
        service => service.service === 'facebook' && service.valid === true
    ).size > 0,
    isInstagramConnected: state.services.services.filter(
        service => service.service === 'instagram' && service.valid === true
    ).size > 0,
})

const mapDispatchToProps = {
    connectService,
    disconnectService,
    connectAndDisconnectService,
    updateUser,
    addPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccountContainer)
