import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Spinner from 'components/Spinner/Spinner'

/**
 * Button
 *
 * Various buttons
 *
 * Styleguide: components.button
 */

export default class Button extends Component {
    static propTypes = {
        /** Button content */
        children: PropTypes.node,

        /** Disable the button */
        disabled: PropTypes.bool,

        /** Use <Link> instead of <button> */
        href: PropTypes.string,

        /** Open link in new window */
        blank: PropTypes.bool,

        /** Button click handler */
        onClick: PropTypes.func,

        className: PropTypes.string,

        isActive: PropTypes.bool,

        isSubmit: PropTypes.bool,

        isLoading: PropTypes.bool,

        isLink: PropTypes.bool,

        isIcon: PropTypes.bool,

        name: PropTypes.string,
    };

    render() {
        const {
            blank,
            children,
            className,
            name,
            disabled,
            href,
            isActive,
            isSubmit,
            isLoading,
            onClick,
            isLink,
            isIcon,
        } = this.props

        const buttonClassName = classnames(
            'c-button',
            className,
            {
                'c-button-link': isLink,
                'c-button-icon': isIcon,
                disabled,
                active: isActive,
                loading: isLoading,
            }
        )

        const isExternalTargetLink = href && href.toLowerCase().substr(0, 4) === 'http'

        if (href) {
            const linkTag = isExternalTargetLink
                ? (
                    <a
                        onClick={onClick}
                        className={buttonClassName}
                        href={href}
                        target={blank ? '_blank' : ''}
                        rel="noopener noreferrer"
                    >
                        {isLoading ? <Spinner size="auto" /> : children}
                    </a>
                )
                : (
                    <Link onClick={onClick} className={buttonClassName} to={href} target={blank ? '_blank' : ''}>
                        {isLoading ? <Spinner size="auto" /> : children}
                    </Link>
                )
            return disabled
                ? (
                    <span className={buttonClassName}>
                        {isLoading ? <Spinner size="auto" /> : children}
                    </span>
                )
                : linkTag
        }

        return (
            <button
                name={name}
                className={buttonClassName}
                onClick={onClick}
                disabled={disabled}
                type={isSubmit ? 'submit' : 'button'}
            >
                {isLoading ? <Spinner size="auto" /> : children}
            </button>
        )
    }
}
