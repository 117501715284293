import { createSelector } from 'reselect'
import * as ROLES from 'constants/enums/Roles'
import { AppStoreState } from 'store/store-types'

export const userSelector = createSelector(
    (state: AppStoreState) => state.users.user,
    user => user && ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        url: user.artist && user.artist.slug && `/${user.artist.slug}`,
    })
)

export const pushesQuantitySelector = createSelector(
    userSelector,
    user => user.artist.pushesQuantity
)

export const hasEnabledScoutFeedback = createSelector(
    userSelector,
    user => user.flags.comments
)

export const hasCompleteArtistProfile = createSelector(
    userSelector,
    user => (user.artist ? !!user.artist.name : false)
)

export const artistSelector = createSelector(
    userSelector,
    user => user.artist
)

export const artistCanCreateRelease = createSelector(
    userSelector,
    user => user.roles.includes(ROLES.ROLE_ARTIST_RELEASE_CREATE)
)

export const artistEnhancedSelector = createSelector(
    userSelector,
    user => user && user.roles.includes(ROLES.ROLE_ARTIST_ENHANCED)
)

export const userMarketId = createSelector(
    userSelector,
    user => user.marketId
)

export const isFetchingUser = (state: AppStoreState) => state.users.isFetching
