import React from 'react'
import { Field as ReduxField } from 'redux-form'
import { Label, RadioField } from 'components/EditProfileInputs/Inputs'
import { editProfileFields } from 'constants/EditProfileStructure'
import t from 'utilities/translate'
import privacy from 'constants/PrivacyTypes'

const { baseSection: { profileVisibility } } = editProfileFields

const privacyLabel = (label, description) => (
    <span className="label-wrapper">
        <strong>{t(label)}</strong>
        {t(description)}
    </span>
)

const EditProfileSharing = () => (
    <div className="c-epf-sharing-settings">
        <Label label={t('formEditProfileSharingSettingsLabel')} required />
        <p className="c-epf-sharing-settings-description">
            {t('formEditProfileSharingSettingsDescription')}
        </p>
        <div className="c-epf-profileVisibility">
            <ReduxField
                type="radio"
                name={profileVisibility}
                value={privacy.private}
                label={privacyLabel(
                    'formEditProfilePrivacyPrivateLabel',
                    'formEditProfilePrivacyPrivateDescription'
                )}
                component={RadioField}
            />
            <ReduxField
                type="radio"
                name={profileVisibility}
                value={privacy.public}
                label={privacyLabel(
                    'formEditProfilePrivacyPublicLabel',
                    'formEditProfilePrivacyPublicDescription'
                )}
                component={RadioField}
            />
            <ReduxField
                type="radio"
                name={profileVisibility}
                value={privacy.internal}
                label={privacyLabel(
                    'formEditProfilePrivacyInternalLabel',
                    'formEditProfilePrivacyInternalDescription'
                )}
                component={RadioField}
            />
        </div>
    </div>
)

export default EditProfileSharing
