export function formatArtists(artists) {
    const artistNames = artists.map(artist => artist.artistName)

    return artistNames.length > 1
        ? `${artistNames.slice(0, -1).join(', ')} & ${artistNames[artistNames.length - 1]}`
        : artistNames[0]
}

export function getIdentifierByName(identifiers, name) {
    const filtered = identifiers.filter(
        value => value.get('name') === name
    )

    if (filtered) {
        return filtered[0]
    }
    return null
}
