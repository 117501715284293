// FETCH
export const FETCH_SALES_OVERVIEW_REQUEST = 'FETCH_SALES_OVERVIEW_REQUEST'
export const FETCH_SALES_OVERVIEW_SUCCESS = 'FETCH_SALES_OVERVIEW_SUCCESS'
export const FETCH_SALES_OVERVIEW_FAILURE = 'FETCH_SALES_OVERVIEW_FAILURE'
export const FETCH_SALES_BREAKDOWN_REQUEST = 'FETCH_SALES_BREAKDOWN_REQUEST'
export const FETCH_SALES_BREAKDOWN_SUCCESS = 'FETCH_SALES_BREAKDOWN_SUCCESS'
export const FETCH_SALES_BREAKDOWN_FAILURE = 'FETCH_SALES_BREAKDOWN_FAILURE'

// SELECT
export const SELECT_OVERVIEW_YEAR = 'SELECT_OVERVIEW_YEAR'
