import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TrackListContainer from 'containers/TrackList/TrackList'
import * as statuses from 'constants/enums/ReleaseStatusTypes'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { Button } from '..'
import Icon from '../Icon/Icon'

const General = ({ release: { album }, onDownloadFiles }) => {
    // todo cannot find where album.releases is populated???
    const showReleases = album.releases.filter(releaseFilter => ['1', '28'].indexOf(releaseFilter.outletId) !== -1)
    const { t } = useLocaleContext()

    const showWhatHappensNextButton = ![
        statuses.RELEASE_STATUS_TAKENDOWN,
        statuses.RELEASE_STATUS_DELIVERED,
    ].includes(album.status)

    return (
        <div className="c-release-general">
            {album.artist
                && (
                    <p>
                        {t('componentReleaseGeneralBy')}:&nbsp;
                        <span className="c-release-general-artist">{album.artist}</span>
                    </p>
                )}
            {album.upc
                && (
                    <p>
                        {t('componentReleaseGeneralUPC')}:&nbsp;
                        <span className="c-release-general-upc">{album.upc}</span>
                    </p>
                )}
            <p>
                {t('componentReleaseGeneralReleaseDate')}:&nbsp;
                <span className="c-release-general-releasedate">
                    {
                        album.releaseDate
                            ? album.formattedReleaseDate
                            : t('componentReleaseReleaseDateEstimate')
                    }
                </span>
            </p>
            {showReleases.length > 0
                && (
                    <span>
                        <span>{t('componentReleaseReleaseFooterLinks')}</span>
                        {showReleases.map(showRelease => (
                            <Link key={showRelease.outletId} to={showRelease.uris[0]}>
                                <Icon
                                    id={showRelease.outletId === '28' ? 'spotify' : 'apple-music'}
                                    size={showRelease.outletId === '28' ? 25 : 0}
                                    width={80}
                                    height={20}
                                />
                            </Link>
                        ))}
                    </span>
                )}
            {
                onDownloadFiles ? (
                    <p>
                        <Button isLink onClick={onDownloadFiles} className="download-files">
                            <FontAwesomeIcon icon="faDownload" />
                            {t('componentReleaseDownloadFiles')}
                        </Button>
                    </p>
                ) : null
            }

            <TrackListContainer id={album.id} maxVisibleTracks={2} withTrackPosition />

            {showWhatHappensNextButton && (
                <Button href={t('componentReleaseWhatHappensNextBtnLink')} blank>
                    {t('componentReleaseWhatHappensNextBtn')}
                </Button>
            )}
        </div>
    )
}

General.propTypes = {
    release: PropTypes.object,
    onDownloadFiles: PropTypes.func,
}

export default General
