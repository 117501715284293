import 'rxjs'
import { decamelizeKeys } from 'humps'

const API_URI = window.env.SPINNUP_API

export const patchRelease = ({ release, field }, credentials, ajax) => {
    const setting = {
        method: 'PUT',
        url: `${API_URI}releases/${release.id}?part=content`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${credentials.accessToken}`,
        },
        body: JSON.stringify(decamelizeKeys({
            [field]: release[field],
        })),
    }

    return ajax(setting)
}
