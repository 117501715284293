import { createSelector } from 'reselect'
import t from 'utilities/translate'
import { formatDateUTC } from 'utilities/date'

import { isPlaceholderTitle } from 'utilities/release'
import Album from 'models/album'
import { AppStoreState } from 'store/store-types'
import { Artist } from 'models/artist'
import { Genre } from 'models/genre'
import { Language } from 'models/language'
import { FormattedAlbum } from 'models/formattedAlbum'
import { getLanguages } from './languages'
import { getGenres } from './genres'

function formatArtist(artists: Artist[]) {
    const artistNames = artists.map(artist => artist.artistName)

    return artistNames.length > 1
        ? `${artistNames.slice(0, -1).join(', ')} & ${artistNames[artistNames.length - 1]}`
        : artistNames[0]
}

function formatAlbumTitle(album: Album) {
    const isSingleTrack = album.numberOfTracks === 1
    if (isSingleTrack) {
        const track = album.tracks[0]
        return !isPlaceholderTitle(track.title) ? track.title : ''
    }
    return album.title
}

function formatReleaseDate(date?: string | Date, format = 'LL') {
    return date ? formatDateUTC(date, format) : 'ASAP'
}

function formatTerritory(album: Album) {
    const countriesAllowed = album.countries.filter(c => c.allowed).map(c => c.countryName)
    const countriesDisallowed = album.countries.filter(c => !c.allowed).map(c => c.countryName)

    if (countriesDisallowed.length === 0) {
        return t('selectorAlbumsTerritoryAll')
    } if (countriesAllowed.length >= countriesDisallowed.length) {
        // Exclusion
        const countryNames = countriesDisallowed.join(', ')
        return `${t('selectorAlbumsTerritoryExcept')} ${countryNames}`
    }
    // Only
    const countryNames = countriesAllowed.join(', ')
    return `${t('selectorAlbumsTerritoryOnly')} ${countryNames}`
}

function getCopyrightsYear(album: Album) {
    let date

    if (album.originalReleaseDate) {
        date = album.originalReleaseDate
    } else if (album.releaseDate) {
        date = album.releaseDate
    } else {
        date = new Date()
    }

    return formatReleaseDate(date, 'Y')
}

export function formatAlbum(album: Album, genres: Genre[] = [], languages: Language[] = []) {
    const { artists } = album

    return new FormattedAlbum({
        ...album,
        artists,
        countries: album.countries.map(c => ({ ...c })),
        artist: formatArtist(artists),
        title: formatAlbumTitle(album),
        copyrightsYear: getCopyrightsYear(album),
        formattedOriginalReleaseDate: formatReleaseDate(album.originalReleaseDate),
        formattedReleaseDate: formatReleaseDate(album.releaseDate),
        formattedTerritory: formatTerritory(album),
        isSingle: album.numberOfTracks === 1,
        isRejected: album.status === 'Rejected',
        genre: genres.find(({ id }) => id === album.genreId)?.name,
        genreTranslationKey: `genre_${genres.find(({ id }) => id === album.genreId)?.id}`,
        language: languages.find(({ id }) => id === album.languageId)?.languageName,
    })
}

export const albumsSelector = createSelector(
    (state: AppStoreState) => state.albums.albums,
    (state: AppStoreState) => getGenres(state),
    (state: AppStoreState) => getLanguages(state),
    (albums, genres, languages) => Object.values(albums).map(album => formatAlbum(album, genres, languages))
)

export const albumSelector = createSelector(
    albumsSelector,
    (state: AppStoreState, albumId: string) => albumId,
    (albums, albumId) => albums.find(album => album.id === albumId)
)

export const makeAlbumSelector = (id: string) => createSelector(
    albumsSelector,
    albums => albums.find(album => album.id === id)
)
