import React from 'react'
import Book from 'components/Book/Book'
import ReasonsHeader from 'components/ReasonsHeader/ReasonsHeader'
import LoginForm from 'containers/LoginForm/LoginForm'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const Login = () => {
    const { t } = useLocaleContext()
    return (
        <div>
            <ReasonsHeader
                title={t('viewLoginHeaderTitle')}
                items={[
                    t('viewLoginHeaderItemOne'),
                    t('viewLoginHeaderItemTwo'),
                    t('viewLoginHeaderItemThree'),
                ]}
            />
            <Book
                coverOnThe="left"
                pageRenderer={() => (
                    <LoginForm />
                )}
                coverRenderer={() => (
                    <div className="v-login-form-link">
                        <h3 className="light">
                            {t('viewLoginCoverSideHeading')}
                        </h3>
                    </div>
                )}
            />
        </div>
    )
}

export default Login
