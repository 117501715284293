import * as types from 'constants/ResetActionTypes'
import { CLIENT_CALL_API } from 'middleware/api'
import { callSignedApiUrlPost } from 'actions/SignedUrlActions'

// FORGOT

function postForgot(body) {
    return {
        [CLIENT_CALL_API]: {
            types: [
                types.FORGOT_REQUEST,
                types.FORGOT_SUCCESS,
                types.FORGOT_FAILURE,
            ],
            payload: {
                method: 'post',
                endpoint: 'passwords/request',
                body,
            },
        },
    }
}

export function forgot(email) {
    return dispatch => dispatch(postForgot({ email }))
}

// RESET

function postReset(body) {
    return {
        [CLIENT_CALL_API]: {
            types: [
                types.RESET_REQUEST,
                types.RESET_SUCCESS,
                types.RESET_FAILURE,
            ],
            payload: {
                method: 'post',
                endpoint: 'passwords/reset',
                body,
            },
        },
    }
}

export function reset(body) {
    return dispatch => dispatch(postReset(body))
}

export const resetSigned = (url, body) => callSignedApiUrlPost([
    types.RESET_REQUEST,
    types.RESET_SUCCESS,
    types.RESET_FAILURE,
], url, body)
