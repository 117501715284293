import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Divider from 'components/Divider/Divider'
import ScrollableContent from 'components/ScrollableContent/ScrollableContent'
import t from 'utilities/translate'

export default class ExpandablePanel extends Component {
    static propTypes = {
        open: PropTypes.bool,
        divider: PropTypes.bool,
        header: PropTypes.string.isRequired,
        className: PropTypes.string,
        children: PropTypes.node,
    }

    static defaultProps = {
        open: false,
        divider: false,
    };

    constructor(props) {
        super(props)

        const { open } = this.props

        this.state = {
            isOpen: !!open,
        }
    }

    toggleExpander = () => {
        this.setState(prevstate => ({ isOpen: !prevstate.isOpen }))
    }

    render() {
        const classes = [this.props.className, 'c-expandable-panel']
        return (
            <div className="c-expandable-panel-wrapper">
                {this.props.divider ? (<Divider imageId="clear" />) : null}

                <div className={classes.join(' ')}>

                    <div className="c-expandable-panel-control">
                        {this.props.header ? (<h2>{this.props.header}</h2>) : null}
                        <a className="c-expandable-panel-toggle" onClick={this.toggleExpander}>
                            {this.state.isOpen ? t('viewSalesPanelHide') : t('viewSalesPanelShow')}
                        </a>
                    </div>

                    <ScrollableContent>
                        <div className="c-expandable-panel-content">
                            {this.state.isOpen ? this.props.children : null}
                        </div>
                    </ScrollableContent>

                </div>
            </div>
        )
    }
}
