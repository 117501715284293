import { anyUrlRegex, httpRegex } from 'constants/regularExpressions'
import URLS_MAP from 'constants/AppUrls'

export const getUrlWithHttp = url => (!anyUrlRegex.test(url) ? `http://${url}` : url)

export const getUrlWithoutHttp = url => (anyUrlRegex.test(url) ? url.replace(httpRegex, '') : url)

export const getPathTitle = (path) => {
    const path2 = Object.keys(URLS_MAP).find((key) => {
        const regex = new RegExp(key)
        if (regex.test(path)) {
            return key
        }
        return false
    })

    return URLS_MAP[path2]
}

export const stripQueryStrings = url => url.split('?')[0].split('#')[0]
