import t from 'utilities/translate'
import {
    testHasTitleMetadataRequiredForRegion,
    testHasTrackArtistNameMetadataRequiredForRegion,
} from 'validations/rules'

export const errors = {
    title: [
        {
            test: title => title,
            message: t('ruleTrackMessageName'),
        },
        {
            test: title => title.length <= 255,
            message: t('track.Title602'),
            error: true,
        },
        {
            test: title => !title.match(/\s{2,}/),
            message: t('track.Title605'),
            error: true,
        },
        {
            test: (title, fields) => !fields.tracks || !fields.tracks.find(track => track.id !== fields.id
                && track.recordingVersionId === fields.recordingVersionId
                && track.title === title),
            message: t('ruleTrackMessageDuplicate'),
        },
        {
            test: (title, fields, appState) => testHasTitleMetadataRequiredForRegion(
                fields,
                appState.users.user.marketId
            ),
            message: t('releaseFlowTrackTranslationError'),
            error: true,
        },
    ],
    artists: [
        {
            test: (artists, fields, appState) => testHasTrackArtistNameMetadataRequiredForRegion(
                fields,
                appState.users.user.marketId
            ),
            message: t('releaseFlowArtistTranslationError'),
            error: true,
        },
    ],
    recordingVersionId: [
        {
            test: recordingVersionId => recordingVersionId,
            message: t('ruleTrackMessageNoRecordingVersion'),
        },
    ],
    hasAudio: [
        {
            test: hasAudio => hasAudio,
            message: t('ruleTrackMessageNoAudioUrl'),
        },
    ],
    isUploading: [
        {
            test: isUploading => !isUploading,
            message: '',
        },
    ],
    publishers: [
        {
            test: publishers => publishers.length,
            message: t('ruleTrackAtLeastOnePublishers'),
        },
    ],
}

export const warnings = {
    title: [
        {
            test: title => !/\b(feat|featuring|ft|with|vs|mit|med)\b/ig.test(title),
            message: t('ruleTrackMessageArtistInTitle'),
        },
        {
            test: title => !/\b(ep|single|album)\b/ig.test(title),
            message: t('ruleTrackMessageProductInTitle'),
        },
        {
            test: title => !/\b(wav|flac|mp3|acc)\b/ig.test(title),
            message: t('ruleTrackMessageFileTypeInTitle'),
        },
        {
            test: title => !/\b(cover)\b/ig.test(title),
            message: t('ruleTrackMessageCoverInTitle'),
        },
        {
            test: title => !/\b(original)\b/ig.test(title),
            message: t('ruleTrackMessageOriginalInTitle'),
        },
        {
            test: title => !/\b(clean)\b/ig.test(title),
            message: t('ruleTrackMessageCleanInTitle'),
        },
        {
            test: title => !/\b(explicit)\b/ig.test(title),
            message: t('ruleTrackMessageExplicitInTitle'),
        },
        {
            test: title => !/\b(radio)\b/ig.test(title),
            message: t('ruleTrackMessageRadioInTitle'),
        },
        {
            test: title => !/\b(instrumental)\b/ig.test(title),
            message: t('ruleTrackMessageInstrumentalInTitle'),
        },
        {
            test: title => !/\b(acoustic|akustisk|akustisch)\b/ig.test(title),
            message: t('ruleTrackMessageAccousticInTitle'),
        },
        {
            test: title => !/\b(limited edition)\b/ig.test(title),
            message: t('ruleTrackMessageLimitedEditionInTitle'),
        },
        {
            test: title => !/\b(exclusive version)\b/ig.test(title),
            message: t('ruleTrackMessageExclusiveVersionInTitle'),
        },
    ],
}
