import * as types from 'constants/ArtistAliasActionTypes'
import { CALL_API } from 'middleware/api'

// CREATE
function postArtistAlias(artistId, body) {
    return {
        [CALL_API]: {
            types: [
                types.CREATE_ARTIST_ALIAS_REQUEST,
                types.CREATE_ARTIST_ALIAS_SUCCESS,
                types.CREATE_ARTIST_ALIAS_FAILURE,
            ],
            payload: {
                method: 'post',
                endpoint: `artists/${artistId}/alias`,
                body,
            },
        },
    }
}

export function createArtistAlias(body) {
    return (dispatch, getState) => {
        const { id } = getState().users.user.artist

        return dispatch(postArtistAlias(id, body))
    }
}

// UPDATE

function putArtistAlias(artistId, body) {
    return {
        [CALL_API]: {
            types: [
                types.UPDATE_ARTIST_ALIAS_REQUEST,
                types.UPDATE_ARTIST_ALIAS_SUCCESS,
                types.UPDATE_ARTIST_ALIAS_FAILURE,
            ],
            payload: {
                method: 'put',
                endpoint: `artists/${artistId}/alias`,
                body,
            },
        },
    }
}

export function updateArtistAlias(body) {
    return (dispatch, getState) => {
        const { id } = getState().users.user.artist

        return dispatch(putArtistAlias(id, body))
    }
}
