import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    orderBy,
    omitBy,
    isUndefined,
    isNull,
} from 'lodash'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Table from 'components/Table/Table'
import ExpandablePanel from 'components/ExpandablePanel/ExpandablePanel'
import Column from 'components/Table/Column'
import Select from 'components/Select/Select'
import Alert from 'components/Alert/Alert'
import Spinner from 'components/Spinner/Spinner'
import t, { tmarkdown } from 'utilities/translate'
import Button from 'components/Button/Button'
import Divider from 'components/Divider/Divider'
import Timebar from 'components/Timebar/Timebar'
import Tooltip from 'components/Tooltip/Tooltip'
import DisplayBalance from 'components/Balance/DisplayBalance'
import ErrorOverlayMessage from 'components/ErrorOverlayMessage/ErrorOverlayMessage'
import { SalesReportDetailedContainerProps } from 'containers/SalesReportDetailed/SalesReportDetailed'

type SalesReportDetailedState = {
    selectedRelease?: string
    selectedTrack?: string
    selectedCountry?: string
    selectedDsp?: string
}

export default class SalesReportDetailed
    extends Component<SalesReportDetailedContainerProps, SalesReportDetailedState> {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        failedFetchingBreakdown: PropTypes.bool.isRequired,
        failedFetchingOverview: PropTypes.bool.isRequired,
        totalSales: PropTypes.object.isRequired,
        overviewRevenue: PropTypes.string.isRequired,
        totalRevenue: PropTypes.string.isRequired,
        overviewDspYrMonth: PropTypes.array,
        salesByRelease: PropTypes.array.isRequired,
        salesByCountry: PropTypes.array.isRequired,
        salesByStore: PropTypes.array.isRequired,
        salesByTracks: PropTypes.array.isRequired,
        salesByTrack: PropTypes.array.isRequired,
        fetchCountries: PropTypes.func.isRequired,
        fetchSalesBreakdown: PropTypes.func.isRequired,
        allReleaseAndTrackNames: PropTypes.array,
        allCountries: PropTypes.array,
        allStores: PropTypes.array,
        selectedDetailedYear: PropTypes.number,
        selectedDetailedMonth: PropTypes.number,
        getSalesOverview: PropTypes.func.isRequired,
        isAllTimeReport: PropTypes.bool.isRequired,
        balanceErrors: PropTypes.bool.isRequired,
    }

    constructor(props: SalesReportDetailedContainerProps) {
        super(props)

        this.state = {
            selectedCountry: undefined,
            selectedRelease: undefined,
            selectedTrack: undefined,
            selectedDsp: undefined,
        }
    }

    componentDidMount() {
        this.fetchAll()
    }

    fetchAll = () => {
        this.props.fetchCountries()
        this.props.getSalesOverview()
        this.fetchBreakdown()
    }

    getFilterOptions = () => {
        const { selectedDetailedMonth, selectedDetailedYear } = this.props

        const options = {
            year: selectedDetailedYear,
            month: selectedDetailedMonth,
            upc: this.state.selectedRelease,
            isrc: this.state.selectedTrack,
            country: this.state.selectedCountry,
            dsp: this.state.selectedDsp,
        }
        return omitBy(omitBy(options, isUndefined), isNull)
    }

    onChangeRelease = (selectedRelease: string) => {
        if (selectedRelease.toString().includes('-')) {
            const releaseAndIsrc = selectedRelease.split('-')
            this.setState({
                selectedRelease: releaseAndIsrc[0],
                selectedTrack: releaseAndIsrc[1],
            }, this.fetchBreakdown)
        } else if (selectedRelease !== '') {
            this.setState({
                selectedRelease,
                selectedTrack: undefined,
            },
            this.fetchBreakdown)
        } else {
            this.setState({
                selectedRelease: undefined,
                selectedTrack: undefined,
            },
            this.fetchBreakdown)
        }
    }

    onChangeCountry = (selectedCountry?: string) => {
        this.setState({ selectedCountry }, this.fetchBreakdown)
    }

    onChangeDsp = (selectedDsp?: string) => {
        this.setState({ selectedDsp }, this.fetchBreakdown)
    }

    fetchBreakdown = () => {
        this.props.fetchSalesBreakdown(this.getFilterOptions())
    }

    renderSectionFilter = () => {
        const {
            allCountries,
            allStores,
            allReleaseAndTrackNames,
            isLoading,
            failedFetchingBreakdown,
        } = this.props

        const {
            selectedCountry,
            selectedDsp,
            selectedRelease,
            selectedTrack,
        } = this.state

        let selectedReleaseValue = selectedRelease
        if (selectedTrack !== undefined) {
            selectedReleaseValue = `${selectedRelease}-${selectedTrack}`
        }

        return !failedFetchingBreakdown ? (
            <section className="c-sales-report-section">
                <header>
                    <h2>{t('viewSalesPanelTitleSale')}</h2>
                </header>

                <div className="c-sales-report-filters">
                    <Select
                        options={allReleaseAndTrackNames}
                        value={selectedReleaseValue || ''}
                        onChange={this.onChangeRelease}
                        disableSort
                        disabled={allReleaseAndTrackNames.length < 1 || isLoading}
                        emptyValueLabel={t('viewSalesPanelSelectReleases')}
                    />

                    <Select
                        options={allCountries}
                        value={selectedCountry || ''}
                        onChange={this.onChangeCountry}
                        disabled={allCountries.length < 1 || isLoading}
                        emptyValueLabel={t('viewSalesPanelSelectCountries')}
                    />

                    <Select
                        options={allStores}
                        value={selectedDsp || ''}
                        onChange={this.onChangeDsp}
                        disabled={allStores.length < 1 || isLoading}
                        emptyValueLabel={t('viewSalesPanelSelectStores')}
                    />
                </div>
            </section>
        ) : null
    }

    renderSectionTotals = () => {
        const {
            totalSales,
            failedFetchingOverview,
        } = this.props

        return (
            <div className="c-sales-report-section-group">

                <ExpandablePanel header={t('componentSalesReportTotalsHeading')} open>

                    <Table rows={[totalSales]}>
                        <Column
                            prop="revenue"
                            heading={t('viewSalesTotalHeadingTotal')}
                            isNumber
                        />
                        <Column
                            prop="streams"
                            heading={t('viewSalesTotalHeadingStreams')}
                            isNumber
                        />
                        <Column
                            prop="streamRevenue"
                            heading={t('viewSalesTotalHeadingStreamEarnings')}
                            isNumber
                        />
                        <Column
                            prop="downloads"
                            heading={t('viewSalesTotalHeadingStreamDownloads')}
                            isNumber
                        />
                        <Column
                            prop="downloadRevenue"
                            heading={t('viewSalesTotalHeadingStreamDownloadEarnings')}
                            isNumber
                        />
                    </Table>

                    <ErrorOverlayMessage
                        message={t('componentSalesReportOverviewDataFail')}
                        enabled={failedFetchingOverview}
                    />

                </ExpandablePanel>
            </div>
        )
    }

    renderSalesByCountryDetail =
    (salesByCountry: SalesReportDetailedContainerProps['salesByCountry']) => salesByCountry.length > 0 && (
        <ExpandablePanel header={t('componentSalesReportHeadingCountries')}>

            <Table rows={orderBy(salesByCountry, ['revenue'], 'desc')}>
                <Column
                    prop="country"
                    heading={t('viewSalesTotalHeadingCountry')}
                    grow={0.8}
                />
                <Column
                    prop="streams"
                    heading={t('viewSalesTableHeadingStreams')}
                    grow={0.7}
                    align="right"
                    isNumber
                />
                <Column
                    prop="streamRevenue"
                    heading={t('viewSalesTableHeadingStreamEarnings')}
                    grow={1.3}
                    align="right"
                    isNumber
                />
                <Column
                    prop="downloads"
                    heading={t('viewSalesTableHeadingDownloads')}
                    grow={1}
                    align="right"
                    isNumber
                />
                <Column
                    prop="downloadRevenue"
                    heading={t('viewSalesTableHeadingDownloadEarnings')}
                    grow={1.4}
                    align="right"
                    isNumber
                />
                <Column
                    prop="revenue"
                    heading={t('viewSalesTableHeadingTotal')}
                    grow={1.1}
                    align="right"
                    strong
                    isNumber
                />
            </Table>
        </ExpandablePanel>
    )

    renderSalesByStoreDetail =
    (salesByStore: SalesReportDetailedContainerProps['salesByStore']) => salesByStore.length > 0 && (
        <ExpandablePanel header={t('componentSalesReportHeadingStores')}>

            <Table rows={orderBy(salesByStore, ['revenue'], 'desc')}>
                <Column
                    prop="formattedName"
                    heading={t('viewSalesTableHeadingStore')}
                    grow={0.8}
                />
                <Column
                    prop="streams"
                    heading={t('viewSalesTableHeadingStreams')}
                    grow={0.7}
                    align="right"
                    isNumber
                />
                <Column
                    prop="streamRevenue"
                    heading={t('viewSalesTableHeadingStreamEarnings')}
                    grow={1.3}
                    align="right"
                    isNumber
                />
                <Column
                    prop="downloads"
                    heading={t('viewSalesTableHeadingDownloads')}
                    grow={1}
                    align="right"
                    isNumber
                />
                <Column
                    prop="downloadRevenue"
                    heading={t('viewSalesTableHeadingDownloadEarnings')}
                    grow={1.4}
                    align="right"
                    isNumber
                />
                <Column
                    prop="revenue"
                    heading={t('viewSalesTableHeadingTotal')}
                    grow={1.1}
                    align="right"
                    strong
                    isNumber
                />
            </Table>
        </ExpandablePanel>
    )

    renderSectionDetailed = () => {
        const {
            salesByRelease,
            salesByCountry,
            salesByStore,
            salesByTracks,
            salesByTrack,
            failedFetchingBreakdown,
        } = this.props

        const { selectedRelease, selectedTrack } = this.state

        if (!salesByRelease.length && !salesByCountry.length && !salesByStore.length) {
            return null
        }

        return (
            <div className="c-sales-report-section-group">

                {selectedRelease && selectedTrack
                    ? (
                        salesByTrack.length > 0 ? (
                            <ExpandablePanel header={t('viewSalesPanelTitleDetails')}>

                                <Table rows={orderBy(salesByTrack, 'revenue', 'desc')}>
                                    <Column
                                        prop="name"
                                        heading={t('viewSalesTableHeadingTrack')}
                                        grow={0.8}
                                    />
                                    <Column
                                        prop="streams"
                                        heading={t('viewSalesTableHeadingStreams')}
                                        grow={0.7}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="streamRevenue"
                                        heading={t('viewSalesTableHeadingStreamEarnings')}
                                        grow={1.3}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="downloads"
                                        heading={t('viewSalesTableHeadingDownloads')}
                                        grow={1}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="downloadRevenue"
                                        heading={t('viewSalesTableHeadingDownloadEarnings')}
                                        grow={1.4}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="revenue"
                                        heading={t('viewSalesTableHeadingTotal')}
                                        grow={1.1}
                                        align="right"
                                        strong
                                        isNumber
                                    />
                                </Table>

                            </ExpandablePanel>
                        ) : null
                    )
                    : selectedRelease
                        ? (salesByTracks.length > 0 ? (
                            <ExpandablePanel header={t('viewSalesPanelTitleDetails')}>

                                <Table rows={orderBy(salesByTracks, 'revenue', 'desc')}>
                                    <Column
                                        prop="name"
                                        heading={t('viewSalesTableHeadingTrack')}
                                        grow={0.8}
                                    />
                                    <Column
                                        prop="streams"
                                        heading={t('viewSalesTableHeadingStreams')}
                                        grow={0.7}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="streamRevenue"
                                        heading={t('viewSalesTableHeadingStreamEarnings')}
                                        grow={1.3}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="downloads"
                                        heading={t('viewSalesTableHeadingDownloads')}
                                        grow={1}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="downloadRevenue"
                                        heading={t('viewSalesTableHeadingDownloadEarnings')}
                                        grow={1.4}
                                        align="right"
                                        isNumber
                                    />
                                    <Column
                                        prop="revenue"
                                        heading={t('viewSalesTableHeadingTotal')}
                                        grow={1.1}
                                        align="right"
                                        strong
                                        isNumber
                                    />
                                </Table>

                            </ExpandablePanel>
                        ) : null
                        )
                        : (
                            salesByRelease.length > 0 ? (
                                <ExpandablePanel header={t('viewSalesPanelTitleDetails')}>

                                    <Table rows={orderBy(salesByRelease, 'revenue', 'desc')}>
                                        <Column
                                            prop="name"
                                            heading={t('viewSalesTableHeadingRelease')}
                                            grow={0.8}
                                        />
                                        <Column
                                            prop="streams"
                                            heading={t('viewSalesTableHeadingStreams')}
                                            grow={0.7}
                                            align="right"
                                            isNumber
                                        />
                                        <Column
                                            prop="streamRevenue"
                                            heading={t('viewSalesTableHeadingStreamEarnings')}
                                            grow={1.3}
                                            align="right"
                                            isNumber
                                        />
                                        <Column
                                            prop="downloads"
                                            heading={t('viewSalesTableHeadingDownloads')}
                                            grow={1}
                                            align="right"
                                            isNumber
                                        />
                                        <Column
                                            prop="downloadRevenue"
                                            heading={t('viewSalesTableHeadingDownloadEarnings')}
                                            grow={1.4}
                                            align="right"
                                            isNumber
                                        />
                                        <Column
                                            prop="revenue"
                                            heading={t('viewSalesTableHeadingTotal')}
                                            grow={1.1}
                                            align="right"
                                            strong
                                            isNumber
                                        />
                                    </Table>

                                </ExpandablePanel>
                            ) : null
                        )}

                { this.renderSalesByStoreDetail(salesByStore) }
                { this.renderSalesByCountryDetail(salesByCountry) }

                <ErrorOverlayMessage
                    message={t('componentSalesReportOverviewDataFail')}
                    enabled={failedFetchingBreakdown}
                />

            </div>
        )
    }

    renderSales = () => (
        <section className="c-sales-report-section">
            {this.renderSectionTotals()}
            {this.renderSectionDetailed()}
        </section>
    )

    renderAlert = () => (
        <Alert type="info">
            <div dangerouslySetInnerHTML={{ __html: tmarkdown('viewSalesAlertText') }} />
        </Alert>
    )

    renderTotals = () => {
        const {
            isLoading,
            overviewRevenue,
            totalRevenue,
            selectedDetailedYear,
            selectedDetailedMonth,
            isAllTimeReport,
            balanceErrors,
        } = this.props

        const reportTitle = isAllTimeReport ? (
            <Tooltip text={t('viewSalesTotalRevenueHelpText')}>
                {t('viewSalesTotalRevenue')}
            </Tooltip>
        ) : (
            <Tooltip text={t('viewSalesTotalRevenueRangeHelpText')}>
                {t('viewSalesTotalRevenueRange',
                    t(`globalMonth${selectedDetailedMonth + 1}`),
                    selectedDetailedYear)}
            </Tooltip>
        )

        const revenueBlock = isLoading ? (<Spinner />) : isAllTimeReport ? (
            <DisplayBalance balance={totalRevenue} balanceErrors={balanceErrors} />
        ) : (
            <DisplayBalance balance={overviewRevenue} balanceErrors={balanceErrors} />
        )

        return (
            <div className="c-sales-report-totals">

                <h2>{ reportTitle }</h2>

                <div className="c-sales-report-totals-wrapper">

                    { revenueBlock }

                    <div className="c-sales-report-change-month">
                        <Button href="/balance/reports/overview">
                            <FontAwesomeIcon icon="faTh" />
                            {t('viewSalesChangeMonthButton')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {
            isLoading,
            failedFetchingOverview,
            overviewDspYrMonth,
            selectedDetailedYear,
            selectedDetailedMonth,
            isAllTimeReport,
        } = this.props

        const monthTxt = t(`globalMonth${selectedDetailedMonth + 1}`)
        const title = t('viewSalesTimelineTitleText', `${monthTxt} ${selectedDetailedYear}`)

        const hideTimebar = isAllTimeReport || (overviewDspYrMonth.length === 0)

        return (
            <div className="c-sales-report">

                { this.renderAlert() }

                { this.renderTotals() }

                <Divider imageId="spiral-2" />

                {
                    hideTimebar || (
                        <div>
                            <Timebar
                                title={title}
                                help={{
                                    text: t('viewSalesTimelineHelpText'),
                                }}
                                data={overviewDspYrMonth}
                                reload={this.fetchAll}
                            >
                                <ErrorOverlayMessage
                                    message={t('componentSalesReportOverviewDataFail')}
                                    enabled={failedFetchingOverview}
                                />
                            </Timebar>
                            <Divider imageId="dune-4" />
                        </div>
                    )
                }

                {this.renderSectionFilter()}

                {isLoading
                    ? <Spinner>{t('globalLoading')}</Spinner>
                    : this.renderSales()}
            </div>
        )
    }
}
