export const URL_HOME = '/'
export const URL_LOGIN = '/start'
export const URL_VERIFY_MAIL = '/verify/request'
export const URL_VERIFY_MAIL_BLOCKED = '/verify/request'
export const URL_ONBOARDING = '/onboarding'
export const URL_OVERVIEW = '/overview'
export const URL_MY_RELEASES = '/releases/all'
export const URL_UNCOMPLETED_RELEASES = '/releases/uncomplete'
export const URL_RELEASES_PROGRESS = '/releases/progress'
export const URL_LIVE_RELEASES = '/releases/live'
export const URL_TAKEDOWN_RELEASES = '/releases/takedown'
export const URL_STATS_RELEASES = '/trends/tracks'
export const URL_STATS_FANBASE = '/trends/social'
export const URL_WITHDRAW_EARNINGS = '/balance/withdraw'
export const URL_SALES_OVERVIEW = '/balance/reports/overview'
export const URL_SALES_DETAILED = '/balance/reports/detailed'
export const URL_SALES_MONTHLY = '/balance/reports/detailed'
export const URL_WITHDRAW_HISTORY = '/balance/history'
export const URL_PROFILE = '/profile'
export const URL_SETTINGS = '/settings'
export const URL_CREATE = '/create'
export const URL_PAY = '/pay'
export const URL_PAYMENT = '/payment'
export const URL_PAY_RELEASE = id => `/pay/${id}`
export const URL_EDIT_RELEASE_TRACKS = id => `/edit/${id}/tracks`
export const URL_EDIT_RELEASE_SETTINGS = id => `/edit/${id}/settings`
export const URL_EDIT_RELEASE_PACKAGE = id => `/edit/${id}/package`
export const URL_EDIT_RELEASE_REVIEW = id => `/edit/${id}/review`

export const URL_REGISTER_REGEX = '^\/start\/register$'
export const URL_LOGIN_REGEX = '^\/start$'
export const URL_VERIFY_MAIL_REGEX = '^\/verify\/request$'
export const URL_VERIFY_MAIL_BLOCKED_REGEX = '^\/verify\/request$'
export const URL_OVERVIEW_REGEX = '^\/overview$'
export const URL_MY_RELEASES_REGEX = '^\/releases\/all$'
export const URL_UNCOMPLETED_RELEASES_REGEX = '^\/releases\/uncomplete$'
export const URL_RELEASES_PROGRESS_REGEX = '^\/releases\/progress$'
export const URL_LIVE_RELEASES_REGEX = '^\/releases\/live$'
export const URL_TAKEDOWN_RELEASES_REGEX = '^\/releases\/takedown$'
export const URL_STATS_RELEASES_REGEX = '^\/trends\/tracks$'
export const URL_STATS_FANBASE_REGEX = '^\/trends\/social$'
export const URL_WITHDRAW_EARNINGS_REGEX = '^\/balance\/withdraw$'
export const URL_SALES_OVERVIEW_REGEX = '^\/balance\/reports\/overview$'
export const URL_SALES_DETAILED_REGEX = '^\/balance\/reports\/detailed$'
export const URL_SALES_MONTHLY_REGEX = '^\/balance\/reports\/detailed\/[0-9]*\/[0-9]*$'
export const URL_WITHDRAW_HISTORY_REGEX = '^\/balance\/history$'
export const URL_PROFILE_REGEX = '^\/profile$'
export const URL_SETTINGS_REGEX = '^\/settings$'
export const URL_CREATE_REGEX = '^\/create$'
export const URL_PAY_RELEASE_REGEX = '^\/pay\/[0-9]*$'
export const URL_EDIT_RELEASE_TRACKS_REGEX = '^\/edit\/[0-9]*\/tracks$'
export const URL_EDIT_RELEASE_SETTINGS_REGEX = '^\/edit\/[0-9]*\/settings$'
export const URL_EDIT_RELEASE_PACKAGE_REGEX = '^\/edit\/[0-9]*\/package$'
export const URL_EDIT_RELEASE_REVIEW_REGEX = '^\/edit\/[0-9]*\/review$'
export const URL_PAY_REGEX = '^\/pay$'
export const URL_PAYMENT_REGEX = '^\/payment$'

export const URL_REGISTER_TITLE = 'Sign up'
export const URL_LOGIN_TITLE = 'Login'
export const URL_VERIFY_MAIL_TITLE = 'Verify email prompt'
export const URL_VERIFY_MAIL_BLOCKED_TITLE = 'Verify email blocking screen'
export const URL_ONBOARDING_TITLE = 'Onboarding'
export const URL_OVERVIEW_TITLE = 'Overview'
export const URL_MY_RELEASES_TITLE = 'My releases'
export const URL_UNCOMPLETED_RELEASES_TITLE = 'Uncompleted releases'
export const URL_RELEASES_PROGRESS_TITLE = 'In progress releases'
export const URL_LIVE_RELEASES_TITLE = 'Live in stores releases'
export const URL_TAKEDOWN_RELEASES_TITLE = 'Taken down releases'
export const URL_STATS_RELEASES_TITLE = 'Stats releases'
export const URL_STATS_FANBASE_TITLE = 'Stats fanbase'
export const URL_WITHDRAW_EARNINGS_TITLE = 'Withdraw earnings'
export const URL_SALES_OVERVIEW_TITLE = 'Sales reports overview'
export const URL_SALES_DETAILED_TITLE = 'Sales reports total earnings'
export const URL_SALES_MONTHLY_TITLE = 'Sales reports monthly earnings'
export const URL_WITHDRAW_HISTORY_TITLE = 'Withdrawal history'
export const URL_PROFILE_TITLE = 'Artist site'
export const URL_SETTINGS_TITLE = 'Settings'
export const URL_CREATE_TITLE = 'Create release'
export const URL_EDIT_RELEASE_TRACKS_TITLE = 'Release creation - Tracks'
export const URL_EDIT_RELEASE_SETTINGS_TITLE = 'Release creation - Settings'
export const URL_EDIT_RELEASE_PACKAGE_TITLE = 'Release creation - Cover art'
export const URL_EDIT_RELEASE_REVIEW_TITLE = 'Release creation - Review'
export const URL_PAY_TITLE = 'Release creation - PaymentConfirmation'
export const URL_PAYMENT_TITLE = 'Release creation - Order complete'
export const URL_EARNINGS_ACCOUNT_CREATE_TITLE = 'Create Hyperwallet Account'
export const URL_EARNINGS_ACCOUNT_EDIT_TITLE = 'Edit Hyperwallet Account'
export const URL_EARNINGS_ACCOUNT_CREATE_TRM_TITLE = 'Create withdrawal method'
export const URL_EARNINGS_WITHDRAW_SUCCESS_TITLE = 'Withdrawal complete'

const URLS_MAP = {
    [URL_REGISTER_REGEX]: URL_REGISTER_TITLE,
    [URL_MY_RELEASES_REGEX]: URL_MY_RELEASES_TITLE,
    [URL_UNCOMPLETED_RELEASES_REGEX]: URL_UNCOMPLETED_RELEASES_TITLE,
    [URL_RELEASES_PROGRESS_REGEX]: URL_RELEASES_PROGRESS_TITLE,
    [URL_OVERVIEW_REGEX]: URL_OVERVIEW_TITLE,
    [URL_LIVE_RELEASES_REGEX]: URL_LIVE_RELEASES_TITLE,
    [URL_TAKEDOWN_RELEASES_REGEX]: URL_TAKEDOWN_RELEASES_TITLE,
    [URL_STATS_RELEASES_REGEX]: URL_STATS_RELEASES_TITLE,
    [URL_STATS_FANBASE_REGEX]: URL_STATS_FANBASE_TITLE,
    [URL_WITHDRAW_EARNINGS_REGEX]: URL_WITHDRAW_EARNINGS_TITLE,
    [URL_SALES_OVERVIEW_REGEX]: URL_SALES_OVERVIEW_TITLE,
    [URL_SALES_DETAILED_REGEX]: URL_SALES_DETAILED_TITLE,
    [URL_SALES_MONTHLY_REGEX]: URL_SALES_MONTHLY_TITLE,
    [URL_WITHDRAW_HISTORY_REGEX]: URL_WITHDRAW_HISTORY_TITLE,
    [URL_PROFILE_REGEX]: URL_PROFILE_TITLE,
    [URL_SETTINGS_REGEX]: URL_SETTINGS_TITLE,
    [URL_CREATE_REGEX]: URL_CREATE_TITLE,
    [URL_PAY_RELEASE_REGEX]: URL_PAY_TITLE,
    [URL_EDIT_RELEASE_TRACKS_REGEX]: URL_EDIT_RELEASE_TRACKS_TITLE,
    [URL_EDIT_RELEASE_SETTINGS_REGEX]: URL_EDIT_RELEASE_SETTINGS_TITLE,
    [URL_EDIT_RELEASE_PACKAGE_REGEX]: URL_EDIT_RELEASE_PACKAGE_TITLE,
    [URL_EDIT_RELEASE_REVIEW_REGEX]: URL_EDIT_RELEASE_REVIEW_TITLE,
    [URL_PAY_REGEX]: URL_PAY_TITLE,
    [URL_PAYMENT_REGEX]: URL_PAYMENT_TITLE,
}

export default URLS_MAP
