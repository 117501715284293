import React from 'react'
import { PropTypes } from 'prop-types'
import Button from 'components/Button/Button'
import t from 'utilities/translate'
import privacyTypes from 'constants/PrivacyTypes'
import {
    getPopupDataEditProfileSavePrivate,
    getPopupDataEditProfileSavePublic,
    getPopupDataEditProfileSaveInternal,
    getPopupDataEditProfileUnpublish,
    getPopupDataEditProfilePublishPrivate,
    getPopupDataEditProfilePublishPublic,
    getPopupDataEditProfilePublishInternal,
} from 'utilities/popups'
import { formatEditProfileErrors } from 'validations/async'
import { URL_PROFILE, URL_PROFILE_TITLE } from 'constants/AppUrls'
import { sendEventToDataLayer } from 'utilities/analytics'

const EditFormButtons = ({
    saveDraft,
    publishDraft,
    showPreview,
    isSubmitting,
    isPublishing,
    isTokenLoading,
    isProcessingFiles,
    isFetchingDsp,
    openPopup,
    initialPrivacy,
    actualPrivacy,
    hasPublicProfile,
    form,
    setValidationError,
    isFormValid,
    updateSavePreviewState,
    isSaveAndPreview,
    scrollOnError,
    user,
    profileSection,
}) => {
    const areButtonsDisabled = !isFormValid || isSubmitting || isPublishing || isTokenLoading
        || isProcessingFiles || isFetchingDsp

    const sendDataLayer = (eventType) => {
        const eventObj = {
            pagePath: URL_PROFILE,
            pageTitle: URL_PROFILE_TITLE,
            pageUrl: `${window.location.origin}${URL_PROFILE}`,
            profileSection,
        }
        if (user) {
            eventObj.userId = user.id
            eventObj.country = user.countryId
            eventObj.market = user.market
        }
        sendEventToDataLayer(
            eventType,
            eventObj
        )
    }

    const handleErrors = ({ message, errors = {} }) => {
        setValidationError(form, {
            ...formatEditProfileErrors(message, errors),
        })
        scrollOnError(errors)
    }

    const handleSaveDraft = (onSuccess = () => { }) => {
        saveDraft().then(({ error }) => {
            if (error) {
                handleErrors(error)
            } else {
                onSuccess()
            }
        })
    }

    const handlePublishDraft = () => {
        sendDataLayer('publishProfile')
        publishDraft().then(({ error }) => {
            if (error) {
                handleErrors(error)
            }
        })
    }

    const handlePrivateCase = () => {
        if (hasPublicProfile) {
            const unpublishData = getPopupDataEditProfileUnpublish(handleSaveDraft)
            openPopup(unpublishData)
        } else {
            const privateSaveData = getPopupDataEditProfileSavePrivate()
            handleSaveDraft(() => {
                openPopup(privateSaveData)
            })
        }
    }

    const handleSaveClick = () => {
        const isPrivate = initialPrivacy === privacyTypes.private
        switch (actualPrivacy) {
            case privacyTypes.private:
                if (initialPrivacy !== privacyTypes.private) {
                    handlePrivateCase()
                } else {
                    handleSaveDraft()
                }
                break

            case privacyTypes.public:
                if (initialPrivacy !== privacyTypes.public) {
                    const publicSaveData = getPopupDataEditProfileSavePublic(isPrivate)
                    handleSaveDraft(() => {
                        openPopup(publicSaveData)
                    })
                } else {
                    handleSaveDraft()
                }
                break

            case privacyTypes.internal:
                if (initialPrivacy !== privacyTypes.internal) {
                    const internalSaveData = getPopupDataEditProfileSaveInternal(isPrivate)
                    handleSaveDraft(() => {
                        openPopup(internalSaveData)
                    })
                } else {
                    handleSaveDraft()
                }
                break

            default:
                handleSaveDraft()
        }
        sendDataLayer('saveProfile')
    }

    const handleSaveAndPreview = () => {
        updateSavePreviewState(true)
        saveDraft().then(({ error }) => {
            if (error) {
                handleErrors(error)
            } else {
                showPreview()
            }
        })
    }

    const handlePublishClick = () => {
        switch (actualPrivacy) {
            case privacyTypes.private:
                if (initialPrivacy !== privacyTypes.private) {
                    const privatePublishData = getPopupDataEditProfilePublishPrivate(handlePublishDraft)
                    openPopup(privatePublishData)
                } else {
                    const publicPublishData = getPopupDataEditProfilePublishPublic(handlePublishDraft)
                    openPopup(publicPublishData)
                }
                break

            case privacyTypes.public:
                if (initialPrivacy !== privacyTypes.public) {
                    const publicPublishData = getPopupDataEditProfilePublishPublic(handlePublishDraft)
                    openPopup(publicPublishData)
                } else {
                    handlePublishDraft()
                }
                break

            case privacyTypes.internal:
                if (initialPrivacy !== privacyTypes.internal) {
                    const internalPublishData = getPopupDataEditProfilePublishInternal(handlePublishDraft)
                    openPopup(internalPublishData)
                } else {
                    handlePublishDraft()
                }
                break

            default:
                handlePublishDraft()
        }
    }

    return (
        <div className="c-edit-profile-buttons">
            <div className="c-edit-profile-buttons-wrapper">
                <Button
                    isLoading={isSubmitting && !isPublishing && !isSaveAndPreview}
                    disabled={areButtonsDisabled}
                    onClick={handleSaveClick}
                >
                    {t('viewEditProfileFormSave')}
                </Button>
                <Button
                    isLoading={isTokenLoading || isSaveAndPreview}
                    disabled={areButtonsDisabled}
                    className="c-edit-profile-preview-button"
                    onClick={handleSaveAndPreview}
                >
                    {t('viewEditProfileFormPreview')}
                </Button>
            </div>
            <Button
                isLoading={isPublishing}
                onClick={handlePublishClick}
            >
                {t('viewEditProfileFormPublish')}
            </Button>
        </div>
    )
}

EditFormButtons.propTypes = {
    saveDraft: PropTypes.func.isRequired,
    publishDraft: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isPublishing: PropTypes.bool.isRequired,
    isTokenLoading: PropTypes.bool.isRequired,
    isProcessingFiles: PropTypes.bool.isRequired,
    isFetchingDsp: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
    initialPrivacy: PropTypes.string,
    actualPrivacy: PropTypes.string,
    hasPublicProfile: PropTypes.bool,
    form: PropTypes.string.isRequired,
    setValidationError: PropTypes.func.isRequired,
    isFormValid: PropTypes.bool.isRequired,
    updateSavePreviewState: PropTypes.func.isRequired,
    isSaveAndPreview: PropTypes.bool,
    scrollOnError: PropTypes.func.isRequired,
    user: PropTypes.object,
    profileSection: PropTypes.string,
}

export default EditFormButtons
