import { Map, Record } from 'immutable'
import { isEmpty } from 'lodash'
import { FETCH_METRICS_SUCCESS } from 'constants/ReportActionTypes'
import * as types from 'constants/ServiceActionTypes'

const Service = new Record({
    stub: '',
    service: undefined,
    title: undefined,
    userID: undefined,
    partnerID: undefined,
    username: undefined,
    token: undefined,
    fans: 0,
    plays: 0,
    pages: [],
    fill: '',
    shrink: 45,
    valid: false,
    isSocial: undefined,
    icon: undefined,
})

const initialState = {
    isReady: false,
    isSessionActive: false,
    isFetching: false,
    isRemoving: false,
    isAdding: false,
    errors: {},
    invalidService: {},
    services: new Map({
        facebook: new Service({
            stub: 'facebook.com/',
            service: 'facebook',
            title: 'Facebook',
            fill: '#38559d',
            isSocial: true,
            icon: 'faFacebookSquare',
        }),
        twitter: new Service({
            stub: 'twitter.com/',
            service: 'twitter',
            title: 'Twitter',
            fill: '#53a8f0',
            isSocial: true,
            icon: 'faTwitter',
        }),
        soundcloud: new Service({
            stub: 'soundcloud.com/',
            service: 'soundcloud',
            title: 'Soundcloud',
            fill: '#ff5600',
            shrink: 70,
            isSocial: true,
            icon: 'faSoundcloud',
        }),
        youtube: new Service({
            stub: 'youtube.com/',
            service: 'youtube',
            title: 'YouTube',
            fill: '#e50d24',
            shrink: 60,
            isSocial: true,
            icon: 'faYoutube',
        }),
        spotify: new Service({
            stub: 'spotify.com/',
            service: 'spotify',
            title: 'Spotify',
            fill: '#65d26e',
            shrink: 60,
            isSocial: false,
            icon: 'faSpotify',
        }),
        instagram: new Service({
            stub: 'instagram.com/',
            service: 'instagram',
            title: 'Instagram',
            fill: '#000000',
            isSocial: true,
            icon: 'faInstagram',
        }),
    }),
}

export default function services(state = initialState, action) {
    switch (action.type) {
        // AE

        case 'AE_READY':
            return {
                ...state,
                isReady: true,
                isSessionActive: !isEmpty(window.aeJS.user),
            }

        case 'AE_USER':
        case 'AE_LOGIN':
            return {
                ...state,
                isSessionActive: !isEmpty(window.aeJS.user),
            }

        case 'AE_INVALID_SERVICE':
            return {
                ...state,
                invalidService: action.payload,
            }

        case 'AE_FACEBOOK_PAGES':
            return {
                ...state,
                services: state.services.setIn(['facebook', 'pages'], action.payload),
            }

        case 'AE_INSTAGRAM_PAGES':
            return {
                ...state,
                services: state.services.setIn(['instagram', 'valid'],
                    action.payload.length > 0)
                    .setIn(['instagram', 'pages'], action.payload),
            }

        case types.FETCH_SERVICES_REQUEST:
            return {
                ...state,
                isFetching: true,
                errors: {},
            }

        case types.FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                invalidService: !action.payload.services
                    .find(s => s.service === state.invalidService.service) ? state.invalidService : {},
                services: state.services.merge(new Map({
                    ...action.payload.services.reduce((obj, service) => ({
                        ...obj,
                        [service.service]: new Service({
                            ...(
                                state.services.get(service.service).toJS
                                    ? state.services.get(service.service).toJS()
                                    : state.services.get(service.service)
                            ),
                            ...service,
                        }),
                    }), {}),
                })),
            }

        case types.FETCH_SERVICES_FAILURE:
            return {
                ...state,
                isFetching: false,
                services: initialState.services,
            }

            // METRICS

        case FETCH_METRICS_SUCCESS:
            return {
                ...state,
                services: state.services.map(service => service.set(
                    action.meta.stat,
                    action.payload.response.data[service.service].length > 0
                        ? action.payload.response.data[service.service].pop().quantity
                        : 0
                )),
            }

            // DEFAULT

        default:
            return state
    }
}
