import React from 'react'
import PropTypes from 'prop-types'

const FontSelectOption = ({ label }) => (
    <div style={{ fontFamily: label }}>
        {label}
    </div>
)

FontSelectOption.propTypes = {
    label: PropTypes.string,
}

export default FontSelectOption
