import * as types from 'constants/VerificationActionTypes'
import { removeMessage } from 'actions/MessageActions'
import * as messageTypes from 'constants/MessageTypes'
import { CLIENT_CALL_API } from 'middleware/api'

function verificationCheck(userId, expires, signature) {
    return {
        [CLIENT_CALL_API]: {
            types: [
                types.VERIFICATION_CHECK_REQUEST,
                types.VERIFICATION_CHECK_SUCCESS,
                types.VERIFICATION_CHECK_FAILURE,
            ],
            payload: {
                method: 'GET',
                endpoint: `verify/${userId}/check`,
                query: {
                    expires,
                    signature,
                },
            },
        },
    }
}

export function postVerificationCheck(userId, expires, signature) {
    return dispatch => dispatch(verificationCheck(userId, expires, signature))
        .then((action) => {
            if (action.type === types.VERIFICATION_CHECK_SUCCESS) {
                dispatch(removeMessage(messageTypes.VERIFY_EMAIL_MESSAGE))
            }
        })
}
