import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/ja'
import { setZendeskWidgetLanguage } from 'hoc/Zendesk/language'
import { MARKET_JAPAN } from 'constants/UserMarkets'
import translationsFile from '../locale.json'
import { format } from './string'
import { formatMarkdownString } from './markdown'

type LanguageBlock = {
    [locale: string]: string
}
type LocaleFile = {
    [translationKey: string]: LanguageBlock
}

type Locale = {
    language: string,
    country: string,
    market: string
}

const translations = translationsFile as LocaleFile

export function setFullLanguage(lang: string = 'en_US'): void {
    if (lang !== 'en_US') {
        localStorage.setItem('langFull', lang)
    } else {
        localStorage.setItem('langFull', lang)
    }
}

export function getLanguage() {
    return localStorage.getItem('lang') || 'en'
}

export function getFullLanguage(): string {
    return localStorage.getItem('langFull') || 'en_US'
}

export function getCountryCode(locales: Locale[]): string {
    const fullLanguage = getFullLanguage()
    const pattern = new RegExp(/_([a-zA-Z].*)$/g)
    const matches = pattern.exec(fullLanguage)

    if (matches && matches.length > 0) {
        if (locales !== undefined && matches[1] !== undefined) {
            const countryCode = matches[1]
            const matchedCountry = locales.find(l => l.country === countryCode)
            if (matchedCountry !== undefined) {
                return countryCode
            }
        }
    }

    const language = getLanguage()
    switch (language) {
        case 'fr':
            return 'FR'
        case 'de':
            return 'DE'
        case 'ja':
            return 'JA'
        case 'en':
        default: // Need to check with Mark whether this should unknown or US
            return 'US'
    }
}

export function getCountryMarket(locales: Locale[]): string {
    const countryCode = getCountryCode(locales)
    const language = getLanguage()
    const matchedLocale = locales.find(l => l.language === language && l.country === countryCode)

    if (matchedLocale !== undefined) {
        return matchedLocale.market
    }

    switch (language) {
        case 'fr':
            return 'France'
        case 'de':
            return 'Deutschland'
        case 'es':
            return 'International ES'
        case 'ja':
            return 'Japan'
        case 'en':
        default: // Need to check with Mark whether this should unknown or USA
            return 'USA & Canada'
    }
}

function setLanguage(lang: string = 'en', countryCode: string = 'us'): void {
    if (lang !== 'en') {
        localStorage.setItem('lang', lang)
    } else {
        localStorage.removeItem('lang')
    }

    // Moment.locale will fallback to lang if the specific dialect isn't found. So, for example at time of writing,
    // moment will find en-gb, but for en-us will fallback to en. Other (currently) supported languages, fr/es/de/ja
    // all only have the base language imported above so will all also fallback as e.g. fr-fr doesn't exist.
    moment.locale([`${lang}-${countryCode.toLowerCase()}`, lang])
    setZendeskWidgetLanguage(lang)
}

export function setAppLocale(languageCode: string, countryCode: string): void {
    setLanguage(languageCode, countryCode)
    setFullLanguage(`${languageCode}_${countryCode}`)
}

function t(string: string, ...data: any[]): string {
    if (!translations[string]) {
        return format(string, ...data)
    }

    const lang = getLanguage()
    if (!translations[string][lang]) return format(translations[string].en, ...data)

    return format(translations[string][lang], ...data)
}

export function tmarkdown(string: string, ...data: any[]) {
    const translatedString = t(string, ...data)
    return formatMarkdownString(translatedString)
}

export function isTranslatableMarket(marketId: number) {
    return (marketId === MARKET_JAPAN)
}

export default t
