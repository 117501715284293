import React, { useState } from 'react'

import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { userSelector } from 'selectors/users'
import { doLookup } from 'actions/DspLookupActions'
import Field from 'components/Field/Field'
import SearchInput from 'components/Inputs/SearchInput'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

ArtistAliasInput.propTypes = {
    user: PropTypes.object,
    value: PropTypes.string,
    actionLookup: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    onChange: PropTypes.func,
}

function ArtistAliasInput(props) {
    const {
        user,
        actionLookup,
        value,
        onValidate,
        onChange,
    } = props

    const [error, setError] = useState(
        null
    )

    const [isLoading, setIsLoading] = useState(
        false
    )

    const { t } = useLocaleContext()

    const debounceArtistLookup = debounce((term) => {
        setIsLoading(true)
        onChange(term)
        actionLookup(term, user.market).then((action) => {
            if (action.payload.blacklisted) {
                setError(t('componentFollowArtistLookupBlacklisted', term))
                onValidate(false, error)
            } else {
                setError(null)
                onValidate(true, null)
            }
            setIsLoading(false)
        })
    }, 1000)

    const onChangeArtistNameInput = (event) => {
        if (event.target.value) {
            debounceArtistLookup(event.target.value)
        } else {
            onChange(event.target.value)
        }
    }

    return (
        <Field
            fieldName="artistName"
            label={t('onboardingArtistName')}
            helpText={isLoading ? t('onboardingArtistNameHelpLoading') : t('onboardingArtistCopy')}
            error={isLoading ? null : error}
        >
            <SearchInput
                value={value}
                placeholder={t('onboardingArtistNameForm')}
                onChange={onChangeArtistNameInput}
                disabled={isLoading}
            />
        </Field>
    )
}

const mapStateToProps = state => ({
    user: userSelector(state),
})

const mapDispatchToProps = dispatch => ({
    actionLookup: (term, market) => dispatch(doLookup('artist', 'spotify', term, market)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArtistAliasInput)
