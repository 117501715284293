import { createSelector } from 'reselect'

export const draftProfileSelector = createSelector(
    state => state.draftProfile,
    draftProfile => draftProfile
)

export const draftProfileDataSelector = createSelector(
    draftProfileSelector,
    draftProfile => draftProfile && draftProfile.data
)

export const draftProfileLinksAsObjectSelector = createSelector(
    draftProfileSelector,
    draftProfile => draftProfile && draftProfile.data && draftProfile.data.links.reduce((accumulator, linkData) => ({
        ...accumulator,
        [linkData.key]: linkData.value,
    }), {})
)

export const isLoadedSelector = createSelector(
    draftProfileSelector,
    draftProfile => draftProfile && draftProfile.isLoaded
)
export const loadingFailedSelector = createSelector(
    draftProfileSelector,
    draftProfile => draftProfile && draftProfile.loadingFailed
)

export const draftProfileFilesSelector = createSelector(
    draftProfileDataSelector,
    draftProfileData => draftProfileData.files
)

export const gallerySelector = createSelector(
    draftProfileDataSelector,
    draftProfileData => draftProfileData && draftProfileData.files && draftProfileData.files.gallery
)

export const isGalleryReorderedSelector = createSelector(
    draftProfileSelector,
    draftProfile => draftProfile && draftProfile.isGalleryReordered
)
