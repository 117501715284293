export function round(val) {
    return parseFloat(Math.round(val * 100) / 100).toFixed(2)
}

export function thousand(val) {
    return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export function money(val) {
    return `€${thousand(round(val))}`
}
