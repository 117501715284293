export const mfa = 'authentication'
export const enrolment = 'set'
export const reset = 'reset'
export const confirmPhoneNumber = 'confirm-phone-number'
export const confirmEmail = 'confirm-email'
export const enterPhoneNumber = 'enter-phone-number'
export const verifyCode = 'verify-code'
export const complete = 'complete'

export const authViews = {
    viewOAuthToken: '/overview',
    viewEmailVerify: '/verify/block',
    viewMFAEnrol: '/mfa/enrol',
    viewMFAActivate: '/mfa/activate',
    viewMFAValidate: '/mfa/verify',
    viewPasswordResetRequired: '/password/required',
}

export const authApiLinks = {
    apiMFAEnrol: 'apiMFAEnrol',
    apiMFAValidate: 'apiMFAValidate',
    apiMFAResend: 'apiMFAResend',
    apiEmailResend: 'apiEmailResend',
}

export const authViewNames = {
    viewOAuthToken: 'viewOAuthToken',
    viewEmailVerify: 'viewEmailVerify',
    viewMFAEnrol: 'viewMFAEnrol',
    viewMFAActivate: 'viewMFAActivate',
    viewMFAValidate: 'viewMFAValidate',
    viewPasswordResetRequired: 'viewPasswordResetRequired',
}
