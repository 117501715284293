// eslint-disable-next-line max-len
import { WindowEnv } from './react-app-env'

// eslint-disable-next-line max-len
const adyenOriginKey = 'pub.v2.7514604686578430.aHR0cDovL2xvY2FsaG9zdDozMDAw.K7HQ6BnP5E0NDrxr8uomGmYapktIzFCRci0piNrB4O8'
const adyenDropinLibraryUrl = 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.5.0/adyen.js'

const env: WindowEnv = {
    SPINNUP_API: process.env.REACT_APP_SPINNUP_API,
    SPINNUP_ARTIST_SITE_CMS_ENABLED: process.env.REACT_APP_SPINNUP_ARTIST_SITE_CMS_ENABLED,
    SPINNUP_API_ID: '2',
    SPINNUP_API_SECRET: 'EzPrZgFDS6nIsqDcSrccNRAzJnGgWEqidyx8qj4P',
    GOOGLE_RECAPTCHA_SITE_KEY: process.env.REACT_APP_SPINNUP_RECAPTCHA_SITE_KEY,
    ZENDESK_WEBWIDGET_KEY: '99c0f3a9-986e-46a5-93ae-8e31713af265',
    SPINNUP_PROFILE_URL: 'http://localhost:3003/',
    SPINNUP_EMAIL_VERIFICATION_TIMEOUT: process.env.REACT_APP_SPINNUP_EMAIL_VERIFICATION_TIMEOUT,
    SPINNUP_RELEASE_SOCIAL_ELIGIBLE_AFTER: '2019-04-01',
    SPINNUP_IS_PRODUCTION: false,
    AE_PRODUCTION_APP_URL: 'https://umg.theappreciationengine.com/framework/js/427',
    AE_TEST_APP_URL: 'https://umg-dev.theappreciationengine.com/framework/js/438',
    COVER_ART_MIN_SIZE: process.env.REACT_APP_COVER_ART_MIN_SIZE,
    COVER_ART_MAX_SIZE: process.env.REACT_APP_COVER_ART_MAX_SIZE,
    ADYEN: {
        ENVIRONMENT: 'test', // "live"
        ORIGIN_KEY: adyenOriginKey,
        DROPIN_LIB_URL: adyenDropinLibraryUrl,
    },
    HYPERWALLET_WIDGET_URL: 'https://uat-api.paylution.com',
    ARTIST_ONBOARDING: process.env.REACT_APP_ENABLE_1A1A,
    ARTIST_SETTINGS_INFORMATION: process.env.REACT_APP_ENABLE_1A1A,
    ENABLE_SUBSCRIPTION_MANAGEMENT: process.env.REACT_APP_ENABLE_SUBSCRIPTIONS,
    ENABLE_AYCE: process.env.REACT_APP_ENABLE_AYCE,
    ENABLE_INVITE_ONLY: process.env.REACT_APP_ENABLE_INVITE_ONLY,
    OFFBOARDING_MAX_RELEASE_DATE: process.env.REACT_APP_OFFBOARDING_MAX_RELEASE_DATE,
}

export default env
