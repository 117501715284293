import { connect } from 'react-redux'
import React, { Component } from 'react'

import { setWith, clone } from 'lodash'
import * as PropTypes from 'prop-types'
import TranslatableModal from 'components/TranslatableModal/TranslatableModal'
import t from 'utilities/translate'
import { translationLanguages } from 'constants/enums/TranslationLanguageEnums'

import {
    getModalId,
    hideModal,
} from 'modules/modal'

class ArtistTranslationModalManager extends Component {
    static propTypes = {
        modalId: PropTypes.string,
        data: PropTypes.object,
        onTranslationSave: PropTypes.func,
        onClose: PropTypes.func.isRequired,
        artistTranslations: PropTypes.array,
    }

    initialTranslationValues = () => {
        const { data, artistTranslations } = this.props
        // Set the structure for the artist translations with no values
        const unsetTranslations = data.value.reduce((allTranslations, currentValue, index) => {
            const transObject = translationLanguages.reduce((translationObject, translationLanguage) => (
                {
                    ...translationObject,
                    [translationLanguage]: { id: null, artistId: null, value: null },
                }
            ), {})

            return {
                ...allTranslations,
                [index]: transObject,
            }
        }, [])
        const populatedTranslations = clone(unsetTranslations)

        if (artistTranslations && artistTranslations.length > 0) {
            artistTranslations.forEach((artist, artistIndex) => {
                // Set the artist ID on the translation language
                translationLanguages.forEach((language) => {
                    setWith(populatedTranslations, `${artistIndex}.${language}.artistId`, artist.id, clone)
                })
                // If there are translations set, set them on the translation language
                if (artist.translations && artist.translations.length > 0) {
                    artist.translations.forEach((translation) => {
                        setWith(
                            populatedTranslations,
                            `${artistIndex}.${translation.language}.value`,
                            translation.value,
                            clone
                        )
                    })
                }
            })
            return populatedTranslations
        }
        // UnsetTranslations is an array where the Translatable modal expects an object.
        // It doesn't seem to be able to be non-zero in length, and avoids intermittent propType errors?
        return unsetTranslations.length === 0 ? {} : unsetTranslations
    }

    render() {
        const {
            modalId,
            data,

            onClose,
            onTranslationSave,
        } = this.props

        let component = null

        switch (modalId) {
            case 'translatable-track-artist-lookup': {
                component = (
                    <TranslatableModal
                        modalId={modalId}
                        title={t('translationModalHeaderTrackArtistLookup')}
                        value={data.value}
                        onClose={onClose}
                        fieldName="artistTranslations"
                        headerTooltipTitle={t('translationModalFeatArtistToolTipText')}
                        headerTooltipText={t('translationModalFeatArtistToolTipBullet')}
                        onSave={onTranslationSave}
                        initialTranslationValues={this.initialTranslationValues()}
                    />
                )
                break
            }
            case 'translatable-release-artist-lookup': {
                component = (
                    <TranslatableModal
                        modalId={modalId}
                        title={t('translationModalHeaderReleaseArtistLookup')}
                        value={data.value}
                        onClose={onClose}
                        fieldName="artistTranslations"
                        headerTooltipTitle={t('translationModalArtistToolTipText')}
                        headerTooltipText={t('translationModalArtistToolTipBullet')}
                        onSave={onTranslationSave}
                        initialTranslationValues={this.initialTranslationValues()}
                    />
                )
                break
            }
            default:
                break
        }

        return component
    }
}

const mapStateToProps = state => ({
    modalId: getModalId(state),
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArtistTranslationModalManager)
