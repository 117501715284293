import * as types from 'constants/TrendActionTypes'
import { CALL_API } from 'middleware/api'

function getTrends(artistId, filterOptions) {
    const defaultParams = {
        days: '7',
        saleType: 'stream',
    }

    const queryParams = Object.entries(filterOptions).reduce((map, [key, value]) => {
        if (value !== null) {
            return { ...map, [key]: value }
        } else {
            return map
        }
    }, defaultParams)

    return {
        [CALL_API]: {
            types: [
                types.FETCH_TRENDS_REQUEST,
                types.FETCH_TRENDS_SUCCESS,
                types.FETCH_TRENDS_FAILURE,
            ],
            payload: {
                endpoint: `sales-trends/artist/${artistId}/dsp/${filterOptions.saleType}`,
                query: queryParams,
            },
            meta: {
                artistId,
                filter: filterOptions,
            },
        },
    }
}

function getTerritoryTrends(artistId, filterOptions) {
    const defaultParams = {
        days: '7',
        saleType: 'stream',
    }

    const queryParams = Object.entries(filterOptions).reduce((map, [key, value]) => {
        if (value !== null) {
            return { ...map, [key]: value }
        } else {
            return map
        }
    }, defaultParams)

    return {
        [CALL_API]: {
            types: [
                types.FETCH_TRENDS_TERRITORYS_REQUEST,
                types.FETCH_TRENDS_TERRITORYS_SUCCESS,
                types.FETCH_TRENDS_TERRITORYS_FAILURE,
            ],
            payload: {
                endpoint: `sales-trends/artist/${artistId}/territory/${filterOptions.saleType}`,
                query: queryParams,
            },
            meta: {
                artistId,
                filter: filterOptions,
            },
        },
    }
}

export function fetchTrends(artistId, filterOptions) {
    return (dispatch) => {
        dispatch(getTrends(artistId, filterOptions))
        dispatch(getTerritoryTrends(artistId, filterOptions))
    }
}

export function fetchTerritoryTrends(artistId, filterOptions) {
    return dispatch => dispatch(getTerritoryTrends(artistId, filterOptions))
}

function getOverviewTrends(artistId, filterOptions) {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_OVERVIEW_TRENDS_REQUEST,
                types.FETCH_OVERVIEW_TRENDS_SUCCESS,
                types.FETCH_OVERVIEW_TRENDS_FAILURE,
            ],
            payload: {
                endpoint: `sales-trends/artist/${artistId}/dsp/${filterOptions.saleType}`,
                query: filterOptions,
            },
            meta: {
                artistId,
            },
        },
    }
}

export function fetchOverviewTrends(artistId, filterOptions) {
    return dispatch => dispatch(getOverviewTrends(artistId, filterOptions))
}

export function toggleVisibility(dsp) {
    return (dispatch, getState) => {
        const { isVisible } = getState().trends.dsps[dsp]
        return dispatch({
            type: types.TRENDS_TOGGLE_VISIBILITY,
            payload: !isVisible,
            meta: { dsp },
        })
    }
}

export function toggleOverviewVisibility(dsp) {
    return (dispatch, getState) => {
        const { isVisible } = getState().trends.overview.dsps[dsp]
        return dispatch({
            type: types.TRENDS_TOGGLE_OVERVIEW_VISIBILITY,
            payload: !isVisible,
            meta: { dsp },
        })
    }
}
