import React from 'react'
import * as PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import GalleryUpload from 'containers/EditProfileUpload/GalleryUpload'
import GalleryImage from 'components/EditProfileGallery/GalleryImage'
import { maxGalleryLength } from 'constants/GalleryLength'
import t from 'utilities/translate'

const EditProfileGallery = ({
    gallery,
    removeGalleryImage,
    reorderGalleryImages,
    isProcessingForm,
    error,
    openPopup,
}) => {
    const { length } = gallery
    const onMove = (moveIndex, moveTargetIndex) => {
        if (moveTargetIndex >= 0 && moveTargetIndex < length && !isProcessingForm) {
            const moveImage = gallery.splice(moveIndex, 1)[0]
            gallery.splice(moveTargetIndex, 0, moveImage)
            reorderGalleryImages(gallery.map((image, index) => ({
                ...image,
                position: index,
            })))
        }
    }

    return (
        <div className="c-epf-gallery">
            <p className="c-epf-gallery-description">{t('formEditProfileGalleryDescription')}</p>
            <DndProvider backend={HTML5Backend}>
                <div className="c-epf-gallery-wrapper">
                    {length < maxGalleryLength ? <GalleryUpload /> : null}
                    {gallery.map((image, index) => (
                        <GalleryImage
                            key={image.groupId}
                            index={index}
                            length={length}
                            image={image}
                            disableActions={isProcessingForm}
                            removeGalleryImage={removeGalleryImage}
                            openPopup={openPopup}
                            onMove={onMove}
                        />
                    ))}
                </div>
            </DndProvider>
            {error ? <p className="c-epf-gallery-errors">{t(error)}</p> : null}
        </div>
    )
}

EditProfileGallery.propTypes = {
    gallery: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
        isProcessing: PropTypes.bool,
    })),
    isProcessingForm: PropTypes.bool.isRequired,
    removeGalleryImage: PropTypes.func.isRequired,
    reorderGalleryImages: PropTypes.func.isRequired,
    error: PropTypes.string,
    openPopup: PropTypes.func.isRequired,
}

export default EditProfileGallery
