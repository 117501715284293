import React, { useState } from 'react'

import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { sendVerification } from 'actions/EmailVerificationActions'
import Button from 'components/Button/Button'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

VerificationResendButton.propTypes = {
    signedUrl: PropTypes.string.isRequired,
    sendVerification: PropTypes.func.isRequired,
}

function VerificationResendButton(props) {
    const [showSendConfirmation, setSendConfirmation] = useState(false)

    const { t } = useLocaleContext()

    const onClick = () => {
        setSendConfirmation(true)
        props.sendVerification(props.signedUrl).then(() => {
            setTimeout(() => {
                setSendConfirmation(false)
            }, 30000)
        })
    }

    return (
        showSendConfirmation
            ? (
                <span
                    className="c-ver-resend-confirmation"
                >
                    {t('verificationResendMailConfirmation')}
                </span>
            )
            : (
                <Button
                    isLink
                    onClick={onClick}
                >
                    {t('verificationResendMailButton')}
                </Button>
            )
    )
}

const mapDispatchToProps = {
    sendVerification,
}

export default connect(false, mapDispatchToProps)(VerificationResendButton)
