import createId from 'utilities/createId'
import Country from 'models/country'
import { Artist } from 'models/artist'
import { ReleaseStatus } from 'constants/enums/ReleaseStatusTypes'
import { ApiDspTranslation } from 'models/api/dsp-translation'
import Track from 'models/track'

export interface IAlbum {
    id: string
    upc: string | null
    completionLevel: number
    status?: ReleaseStatus
    releaseDate?: string
    createdDate?: string
    lastUpdatedDate?: string
    genreId?: string
    languageId?: string
    tracksOrder: string[]
    tracks: Track[]
    numberOfTracks: number
    coverArt?: undefined // todo
    coverArtUrl?: string
    coverArtUserConfirmed: boolean
    coverArtId: string
    title: string
    comment: string
    artists: Artist[]
    countries: Country[]
    errors: Record<string, any> // todo
    isUploading: boolean
    uploadErrors: Record<string, any> // todo
    uploadProgress: number
    settingsErrorMessages: Record<string, { error: string }>
    packageErrorMessages: Record<string, { error: string }>
    isFetchingReleases: boolean
    releases: any[] // todo
    originalReleaseDate?: string
    translations: ApiDspTranslation[]
}

export default class Album implements IAlbum {
    id: string = ''

    upc: string | null = null

    completionLevel: number = -1

    status?: ReleaseStatus

    releaseDate?: string

    createdDate?: string

    lastUpdatedDate?: string

    genreId?: string

    languageId?: string

    tracksOrder: string[] = []

    tracks: Track[] = []

    numberOfTracks: number = 0

    coverArt?: undefined // todo

    coverArtUrl?: string

    coverArtUserConfirmed: boolean = false

    coverArtId: string = createId()

    title: string = 'My untitled album'

    comment: string = ''

    artists: Artist[] = []

    countries: Country[] = []

    errors: Record<string, any> = {} // todo

    isUploading: boolean = false

    uploadErrors: any[] = [] // todo

    uploadProgress: number = 0

    settingsErrorMessages: Record<string, { error: string }> = {}

    packageErrorMessages: Record<string, { error: string }> = {}

    isFetchingReleases: boolean = false

    releases: any[] = [] // todo used in Release/General.js, but can't work out in what context it's populated?

    originalReleaseDate?: string

    translations: ApiDspTranslation[] = []

    // Input data provideded in utilities/hydrator.js, converted from ApiDspProduct
    constructor(base?: Partial<IAlbum>) {
        if (base) {
            Object.assign(this as Album, base)
        }
    }

    clone(props?: Partial<Album>) {
        const a = new Album()
        Object.assign(a, this as Album, props ?? {})
        return a
    }
}
