import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Field from 'components/Field/Field'
import t from 'utilities/translate'

// todo replace with ReduxFormTextField ???
const MfaVerifyCodeInput = ({
    input,
    meta,
    label,
    placeholder,
    disabled,
}) => {
    const { name, error } = input
    const className = classnames('c-input', {
        error: !!error,
    })
    return (
        <Field
            elementName={`field-${name}`}
            label={label}
            error={meta.touched && meta.error ? t(meta.error) : null}
        >
            <input
                className={className}
                placeholder={placeholder}
                type="text"
                disabled={disabled}
                {...input}
            />
        </Field>
    )
}

MfaVerifyCodeInput.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        name: PropTypes.string,
        error: PropTypes.string,
        touched: PropTypes.bool,
    }).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
}

export default MfaVerifyCodeInput
