const scriptExist = src => !!document.querySelector(`script[src="${src}"]`)

export default function (src, onLoad) {
    if (scriptExist(src)) return

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.charset = 'utf-8'
    script.async = true
    script.onload = onLoad
    script.src = src
    document.body.appendChild(script)
}
