import React from 'react'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import CrmSubscriptions from 'containers/CrmSubscriptions/CrmSubscriptions'
import Button from 'components/Button/Button'
import t from 'utilities/translate'

const ManagePreferences = (props) => {
    const { match: { params } } = props

    return (
        <div className="v-manage-preferences">
            <CrmSubscriptions crmSecret={params.secret} />

            <div className="v-manage-preferences-btns">
                <Button href="/">
                    {t('viewManagePreferencesBackToSite')}
                </Button>
            </div>
        </div>
    )
}

ManagePreferences.propTypes = {
    match: PropTypes.object.isRequired,
}

export default withRouter(ManagePreferences)
