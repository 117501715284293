import React from 'react'
import { Field as ReduxField } from 'redux-form'
import { spotifyProfileFieldName } from 'constants/EditProfileStructure'
import t from 'utilities/translate'
import FollowArtistLookup from './FollowArtistLookup'

const FollowInputs = () => (
    <div>
        <p>{t('formEditProfileFollowLinksDescription')}</p>
        <ReduxField
            component={FollowArtistLookup}
            // Clearing artist lookup will turn value into null,
            // We want to keep it consistent - return undefined when falsy
            format={value => value || undefined}
            name={spotifyProfileFieldName}
        />
    </div>
)

export default FollowInputs
