import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeTracksSelector } from 'selectors/tracks'
import TrackList from 'components/Track/TrackList'
import Track from 'containers/Track/Track'

import t from 'utilities/translate'

class TrackListContainer extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        tracks: PropTypes.array.isRequired,
        withAudioPlayer: PropTypes.bool,
        withArtistSpotifyLink: PropTypes.bool,
    };

    render() {
        const {
            tracks,
            withAudioPlayer,
            withArtistSpotifyLink,
        } = this.props

        if (tracks.length === 0) {
            return false
        }

        const headings = [t('containerTrackListHeadingTrack')]
        const withIsrc = tracks.some(track => track.isrc)

        if (withIsrc) {
            headings.push(t('containerTrackListHeadingIsrc'))
        }

        return (
            <TrackList
                {...this.props}
                headings={headings}
            >
                {tracks.map(track => (
                    <Track
                        track={track}
                        key={track.id}
                        title={track.formattedTitle}
                        isrc={track.isrc}
                        tag={track.explicitString}
                        withIsrc={withIsrc}
                        withAudioPlayer={withAudioPlayer}
                        withArtistSpotifyLink={withArtistSpotifyLink}
                    />
                ))}
            </TrackList>
        )
    }
}

const mapStateToProps = (firstState, firstProps) => {
    const tracksSelector = makeTracksSelector(firstProps.id)

    return (state, props) => ({
        tracks: tracksSelector(state, props),
    })
}

export default connect(mapStateToProps)(TrackListContainer)
