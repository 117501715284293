import React from 'react'
import * as PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import t from 'utilities/translate'

const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'link',
    'list',
]

const quillModules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ],
}

const BioInput = ({ biography, handleEditorChange }) => (
    <div>
        <p>{t('formEditProfileBiographyDescription')}</p>
        <p className="c-ep-bio-editor-title">{t('componentEditProfileBioEditorTitle')}</p>
        <ReactQuill
            className="c-ep-bio-editor"
            modules={quillModules}
            value={biography}
            onChange={handleEditorChange}
            formats={quillFormats}
        />
    </div>
)

BioInput.propTypes = {
    biography: PropTypes.string,
    handleEditorChange: PropTypes.func.isRequired,
}

export default BioInput
