import React, { useEffect, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

export const MouseOverTriggerContainer = ({
    onTrigger, timeout, children, className,
}) => {
    const isMouseOverRef = useRef(false)
    const currTimeoutRef = useRef(null)
    const [isTriggered, setIsTriggered] = useState(false)

    const onMouseEnter = () => {
        isMouseOverRef.current = true

        currTimeoutRef.current = setTimeout(() => {
            if (isMouseOverRef.current) {
                setIsTriggered(true)
                onTrigger(true)
            }
            currTimeoutRef.current = null
        }, timeout || 0)
    }

    const onMouseLeave = () => {
        isMouseOverRef.current = false
        if (isTriggered) {
            onTrigger(false)
            setIsTriggered(false)
        }
    }

    useEffect(() => () => {
        if (currTimeoutRef.current) {
            clearTimeout(currTimeoutRef.current)
        }
    }, [])

    return (
        <div
            className={classnames('c-mouse-trigger-container', className)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            { children }
        </div>
    )
}

MouseOverTriggerContainer.propTypes = {
    onTrigger: PropTypes.func.isRequired,
    timeout: PropTypes.number,
    children: PropTypes.node.isRequired,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]),
}
