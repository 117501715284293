import { connect } from 'react-redux'
import { userSelector, userMarketId } from 'selectors/users'
import { updateArtistAlias } from 'actions/ArtistAliasActions'
import { doLookup } from 'actions/DspLookupActions'
import ArtistInformation from 'components/ArtistInformation/ArtistInformation'

const mapStateToProps = (state, props) => ({
    user: userSelector(state, props),
    isUpdating: state.users.isUpdatingUser,
    market: userMarketId(state),
    errors: state.users.errors,
})

const mapDispatchToProps = dispatch => ({
    updateArtistAlias: body => dispatch(updateArtistAlias(body)),
    doLookup: (term, market) => dispatch(doLookup('artist', 'spotify', term, market)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArtistInformation)
