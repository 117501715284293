import { confirmationPopup, informationPopup, errorPopup } from 'constants/PopupTypes'
import * as popupButtons from 'constants/PopupButtons'

const editProfileTranslationKey = 'componentPopupEditProfile'

const getPopupDataEditProfileSaveCommon = () => ({
    type: informationPopup,
    closeButtonText: popupButtons.ok,
})

export const getPopupDataEditProfileSavePrivate = () => ({
    title: `${editProfileTranslationKey}SavePrivateTitle`,
    text: `${editProfileTranslationKey}SavePrivateText`,
    ...getPopupDataEditProfileSaveCommon(),
})

export const getPopupDataEditProfileSavePublic = isPrivate => ({
    title: `${editProfileTranslationKey}SavePublicTitle`,
    text: isPrivate
        ? `${editProfileTranslationKey}SavePublicTextFromPrivate`
        : `${editProfileTranslationKey}SavePublicTextFromInternal`,
    ...getPopupDataEditProfileSaveCommon(),
})

export const getPopupDataEditProfileSaveInternal = isPrivate => ({
    title: `${editProfileTranslationKey}SaveInternalTitle`,
    text: isPrivate
        ? `${editProfileTranslationKey}SaveInternalTextFromPrivate`
        : `${editProfileTranslationKey}SaveInternalTextFromPublic`,
    ...getPopupDataEditProfileSaveCommon(),
})

export const getPopupDataEditProfileUnpublish = onAccept => ({
    type: confirmationPopup,
    title: `${editProfileTranslationKey}UnpublishTitle`,
    text: `${editProfileTranslationKey}UnpublishText`,
    acceptButtonText: popupButtons.unpublish,
    closeButtonText: popupButtons.cancel,
    onAccept,
})

const getPopupDataEditProfilePublishCommon = onAccept => ({
    type: confirmationPopup,
    acceptButtonText: popupButtons.publish,
    closeButtonText: popupButtons.cancel,
    onAccept,
})

export const getPopupDataEditProfilePublishPrivate = onAccept => ({
    title: `${editProfileTranslationKey}PublishPrivateTitle`,
    text: `${editProfileTranslationKey}PublishPrivateText`,
    ...getPopupDataEditProfilePublishCommon(onAccept),
})

export const getPopupDataEditProfilePublishPublic = onAccept => ({
    title: `${editProfileTranslationKey}PublishPublicTitle`,
    text: `${editProfileTranslationKey}PublishPublicText`,
    ...getPopupDataEditProfilePublishCommon(onAccept),
})

export const getPopupDataEditProfilePublishInternal = onAccept => ({
    title: `${editProfileTranslationKey}PublishInternalTitle`,
    text: `${editProfileTranslationKey}PublishInternalText`,
    ...getPopupDataEditProfilePublishCommon(onAccept),
})

const getPopupDataEditProfileRemoveImageCommon = onAccept => ({
    type: confirmationPopup,
    acceptButtonText: popupButtons.remove,
    closeButtonText: popupButtons.cancel,
    onAccept,
})

export const getPopupDataEditProfileRemoveLogo = onAccept => ({
    title: `${editProfileTranslationKey}LogoRemoveTitle`,
    text: `${editProfileTranslationKey}LogoRemoveText`,
    ...getPopupDataEditProfileRemoveImageCommon(onAccept),
})

export const getPopupDataEditProfileRemoveHero = onAccept => ({
    title: `${editProfileTranslationKey}HeroRemoveTitle`,
    text: `${editProfileTranslationKey}HeroRemoveText`,
    ...getPopupDataEditProfileRemoveImageCommon(onAccept),
})

export const getPopupDataEditProfileRemoveGallery = onAccept => ({
    title: `${editProfileTranslationKey}GalleryRemoveTitle`,
    text: `${editProfileTranslationKey}GalleryRemoveText`,
    ...getPopupDataEditProfileRemoveImageCommon(onAccept),
})

export const getPopupDataEditProfileGalleryNoSpace = onAccept => ({
    type: confirmationPopup,
    title: `${editProfileTranslationKey}GalleryNoSpaceTitle`,
    text: `${editProfileTranslationKey}GalleryNoSpaceText`,
    acceptButtonText: popupButtons.continueUploading,
    closeButtonText: popupButtons.cancel,
    onAccept,
})

export const getPopupDataEditProfileGalleryFull = () => ({
    type: informationPopup,
    title: `${editProfileTranslationKey}GalleryFullTitle`,
    text: `${editProfileTranslationKey}GalleryFullText`,
    closeButtonText: popupButtons.ok,
})

export const getPopupDataConfirmCopyPublisher = onAccept => ({
    type: confirmationPopup,
    title: 'modalCopyPublisherTitle',
    text: 'modalCopyPublisherText',
    acceptButtonText: popupButtons.ok,
    closeButtonText: popupButtons.cancel,
    onAccept,
})

export const getPopupDataDeletePublisher = onAccept => ({
    type: confirmationPopup,
    title: 'containerTrackRemovePublisherAlertConfirm',
    acceptButtonText: popupButtons.remove,
    closeButtonText: popupButtons.cancel,
    onAccept,
})

export const getPopupDataWarnEmptyProductTracks = (releaseTypeName, releaseTypeMax, numUsed, onContinue, onReEdit) => ({
    type: informationPopup,
    title: 'modalEmptyProductTracksTitle',
    text: {
        translation: 'modalEmptyProductTracksText',
        params: [(releaseTypeMax - numUsed), releaseTypeName, releaseTypeMax],
        isMarkupText: true,
    },
    acceptButtonText: popupButtons.continueFlow,
    onAccept: onContinue,
    showClose: false,
    buttons: [
        {
            text: 'modalEmptyProductTracksEditReleaseBtn',
            click: onReEdit,
        },
    ],
})

export const getPopupDataEditProfileViewPrivateProfile = () => ({
    type: errorPopup,
    title: `${editProfileTranslationKey}ViewPrivateProfileTitle`,
    text: `${editProfileTranslationKey}ViewPrivateProfileText`,
    closeButtonText: popupButtons.ok,
})

export const getPopupDataEditProfileCloseCropPopup = onAccept => ({
    type: confirmationPopup,
    title: `${editProfileTranslationKey}CloseCropPopupTitle`,
    text: `${editProfileTranslationKey}CloseCropPopupText`,
    acceptButtonText: popupButtons.confirm,
    closeButtonText: popupButtons.cancel,
    onAccept,
})

export const getPopupDataConfirmArtistAlias = (artistName, onAccept) => ({
    type: confirmationPopup,
    title: 'onboardingArtistModalTitle',
    text: {
        translation: 'onboardingArtistModalSubtitle',
        params: [artistName],
        isMarkupText: true,
    },
    acceptButtonText: 'onboardingArtistModalConfirm',
    closeButtonText: 'onboardingArtistModalCancel',
    onAccept,
})
