import { REHYDRATE } from 'redux-persist/constants'
import * as types from 'constants/GenreActionTypes'
import { AnyAction } from 'redux'
import { Genre } from 'models/genre'

type GenreState = {
    isFetching: boolean
    genres: Genre[]
    fetchedAt: number | Date
    error?: any
}

const initialState: GenreState = {
    isFetching: false,
    genres: [],
    fetchedAt: 0,
}

export default function genres(state = initialState, action: AnyAction): GenreState {
    switch (action.type) {
        case types.FETCH_GENRES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_GENRES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                genres: action.payload,
                fetchedAt: new Date(),
            }

        case types.FETCH_GENRES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            }

        case REHYDRATE:
            if (action.payload.genres) {
                return {
                    ...action.payload.genres,
                    genres: action.payload.genres.genres,
                }
            }
            return state

        default:
            return state
    }
}
