import { CALL_API } from 'middleware/api'
import { authViews } from 'constants/MfaRoutes'

export const createCallSignedApiUrlAction = (actionTypes, signedUrl, body, method = 'post') => ({
    [CALL_API]: {
        types: actionTypes,
        payload: {
            endpoint: signedUrl,
            method,
            body,
        },
    },
})

export const callSignedApiUrlPost = (actionTypes, signedUrl, body) => dispatch => dispatch(
    createCallSignedApiUrlAction(actionTypes, signedUrl, body, 'post')
)

export const callSignedApiUrlGet = (actionTypes, signedUrl) => dispatch => dispatch(
    createCallSignedApiUrlAction(actionTypes, signedUrl, undefined, 'get')
)

export const onPreAuthApiResponse = (action, goToRoute, routeState = {}) => {
    const { links, view, viewData } = action.meta

    if (view) {
        if (!links) {
            console.warn('Pre-auth - View given without associated api links')
        }

        if (!authViews[view]) {
            throw new Error(`Pre-auth - Invalid view found in links header ${view}`)
        } else {
            goToRoute(authViews[view], {
                ...(links || {}),
                ...(viewData || {}),
                ...routeState,
            })
        }
    } else {
        throw new Error('Pre-auth - API response with no view found')
    }
}
