import { connect } from 'react-redux'

import MessageBanners from 'components/MessageBanners/MessageBanners'
import { filterVisibleMessages } from 'selectors/messages'

const mapStateToProps = (state) => {
    const messages = filterVisibleMessages(state)
    return {
        messages,
    }
}

export default connect(mapStateToProps, null)(MessageBanners)
