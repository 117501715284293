import * as types from 'constants/SaleActionTypes'
import { AppStoreDispatch } from 'store/store-types'
import { callApi } from 'actions/ApiActions'
import { AnyAction } from 'redux'

export function getSalesOverview() {
    return callApi(
        [
            types.FETCH_SALES_OVERVIEW_REQUEST,
            types.FETCH_SALES_OVERVIEW_SUCCESS,
            types.FETCH_SALES_OVERVIEW_FAILURE,
        ], {
            method: 'get',
            endpoint: 'financial/sales/overview',
        }
    )
}

export function fetchSalesBreakdown(filterOptions: any) {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.FETCH_SALES_BREAKDOWN_REQUEST,
            types.FETCH_SALES_BREAKDOWN_SUCCESS,
            types.FETCH_SALES_BREAKDOWN_FAILURE,
        ], {
            method: 'get',
            endpoint: 'financial/sales/breakdown',
            query: filterOptions,
        }, {
            filter: filterOptions,
        }
    ))
}

export function selectOverviewYear(year: number): AnyAction {
    return {
        type: types.SELECT_OVERVIEW_YEAR,
        payload: year,
    }
}
