import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

const Background = ({
    children,
    imageId,
    flipVerticaly,
    flipHorizontaly,
}) => {
    const classname = classnames(
        'c-frame-background',
        `background-${imageId}`,
        {
            'vertical-flit': flipVerticaly,
            'horizontal-flit': flipHorizontaly,
        }
    )

    return (
        <div className={classname}>
            {children}
        </div>
    )
}

Background.propTypes = {
    children: PropTypes.node.isRequired,
    flipVerticaly: PropTypes.bool,
    flipHorizontaly: PropTypes.bool,
    imageId: PropTypes.oneOf([
        'clear',
        'dune-1',
        'dune-2',
        'dune-3',
        'dune-4',
        'dune-5',
        'dune-6',
        'paint',
        'sun-1',
        'distortion-1',
        'equalizer-1',
        'spiral-1',
        'sun-2',
        'distortion-2',
        'equalizer-2',
        'spiral-2',
        'water-1',
        'distortion-3',
        'equalizer-3',
        'sreen-noise',
        'water-2',
        'sky-1',
    ]),
}

Background.defaultProps = {
    imageId: 'clear',
}

export default Background
