export type ReleaseStatus = 'Not complete' | 'Processing' | 'Non-compliant' | 'Failed submission' | 'Ready for QC'
| 'Rejected' | 'Approved' | 'Delivered' | 'Taken down' | 'Paused' | 'Deleted'

export const RELEASE_STATUS_NOT_COMPLETED: ReleaseStatus = 'Not complete'
export const RELEASE_STATUS_PROCESSING: ReleaseStatus = 'Processing'
export const RELEASE_STATUS_NON_COMPLIANT: ReleaseStatus = 'Non-compliant'
export const RELEASE_STATUS_FAILED_SUBMISSION: ReleaseStatus = 'Failed submission'
export const RELEASE_STATUS_READY_QC: ReleaseStatus = 'Ready for QC'
export const RELEASE_STATUS_REJECTED: ReleaseStatus = 'Rejected'
export const RELEASE_STATUS_APPROVED: ReleaseStatus = 'Approved'
export const RELEASE_STATUS_DELIVERED: ReleaseStatus = 'Delivered'
export const RELEASE_STATUS_TAKENDOWN: ReleaseStatus = 'Taken down'
export const RELEASE_STATUS_PAUSED: ReleaseStatus = 'Paused'
export const RELEASE_STATUS_DELETED: ReleaseStatus = 'Deleted'
