import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import Dropzone from 'components/Dropzone/Dropzone'
import Icon from 'components/Icon/Icon'
import Spinner from 'components/Spinner/Spinner'

export default class ImageDropzone extends Component {
    static propTypes = {
        onDrop: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        progress: PropTypes.number,
        children: PropTypes.node,
        hasError: PropTypes.bool,
        hasExternalError: PropTypes.bool,
        isEmpty: PropTypes.bool,
        background: PropTypes.object,
    };

    onUpload = (files) => {
        this.props.onDrop(files[0])
    }

    render() {
        const {
            isLoading,
            progress,
            children,
            hasError,
            hasExternalError,
            background,
            isEmpty,
        } = this.props

        return (
            <Dropzone
                {...this.props}
                uploading={isLoading}
                error={hasError}
                onDrop={this.onUpload}
                className={classnames(
                    'c-dropzone-image', {
                        'c-dropzone-image-empty': isEmpty,
                        'c-dropzone-has-external-error': hasExternalError,
                    }
                )}
                fileTypes=".jpg,.jpeg,.png"
            >
                {React.isValidElement(background) ? (
                    React.cloneElement(background, {
                        ...background.props,
                        className: classnames(
                            (background.props || {}).className,
                            'c-dropzone-image-bg-wrapper'
                        ),
                    })
                ) : (
                    null
                )}

                <div className="c-dropzone-image-body">
                    {isLoading ? (
                        <div className="c-dropzone-spinner-wrapper">
                            <Spinner
                                className="c-dropzone-spinner"
                                size="medium"
                                percentage={progress}
                            />
                        </div>
                    ) : (
                        <Icon
                            id="upload_icon"
                            className="c-dropzone-icon"
                        />
                    )}

                    {children}
                </div>
            </Dropzone>
        )
    }
}
