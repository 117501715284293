import React from 'react'
import * as PropTypes from 'prop-types'
import Translate from 'components/Translate/translate'
import { Button } from 'components/index'
import { Address } from 'components/AddressForm/ConfirmedAddress'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { getMarketIdFromCountryCode } from 'constants/enums/MarketEnums'

const ViewWalletAccountDetails = ({
    walletAccount,
    countries,
    onEdit,
}) => {
    const address = walletAccount.getAddress()
    const { getLocaleFormOrder } = useLocaleContext()
    const ordering = getLocaleFormOrder('firstNameLastName', getMarketIdFromCountryCode(address.country))
    const reverseNameOrder = ordering.indexOf('firstName') !== 0
    const firstName = <span>{walletAccount.getFirstName()}</span>
    const lastName = <span>{walletAccount.getLastName()}</span>

    return (
        <>
            <h4><Translate id="earningsWithdrawHeaderViewAccountDetails" /></h4>
            <div className="c-wallet-account-details">
                <div className="existing">
                    <span className="name">
                        {reverseNameOrder ? lastName : firstName} {reverseNameOrder ? firstName : lastName}
                    </span>
                    <Address
                        address={address}
                        countries={countries}
                        classNames="small-margin"
                    />
                </div>

                <Button onClick={onEdit}><Translate id="formButtonEdit" /></Button>
            </div>
        </>
    )
}

ViewWalletAccountDetails.propTypes = {
    walletAccount: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
}

export default ViewWalletAccountDetails
