import { MonthOverview } from 'models/sales-overview-month'

export class SalesOverview {
    year: number
    months: MonthOverview[] = []

    constructor(year: number, months: MonthOverview[]) {
        this.year = year
        this.months = months
    }
}
