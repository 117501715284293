import * as types from 'constants/AlbumActionTypes'
import { getRestrictedMinReleaseDate } from 'modules/releaseDate'
import { VALIDATE } from 'middleware/validator'
import {
    abortChunkedUploadApi,
    callApi,
    callApiThunk,
    downloadApiThunk,
    uploadChunkedApiThunk,
} from 'actions/ApiActions'
import { AnyAction } from 'redux'
import { rules as settingsRules } from '../rules/settings'
import { rules as packageRules } from '../rules/package'

// FETCH
export const getAlbums = () => callApi(
    [
        types.FETCH_ALBUMS_REQUEST,
        types.FETCH_ALBUMS_SUCCESS,
        types.FETCH_ALBUMS_FAILURE,
    ], {
        endpoint: 'dsp/product',
        method: 'get',
    }
)

export const getAlbum = (id: string | number) => callApi(
    [
        types.FETCH_ALBUM_REQUEST,
        types.FETCH_ALBUM_SUCCESS,
        types.FETCH_ALBUM_FAILURE,
    ], {
        endpoint: `dsp/product/${id}`,
        method: 'get',
    }
)

export const getAlbumArtwork = (id: string | number) => callApiThunk(
    [
        types.FETCH_ALBUM_ARTWORK_REQUEST,
        types.FETCH_ALBUM_ARTWORK_SUCCESS,
        types.FETCH_ALBUM_ARTWORK_FAILURE,
    ], {
        endpoint: `dsp/product/${id}/artwork`,
        method: 'get',
    }, {
        id,
    }
)

export const downloadAlbumArtwork = (id: string | number) => downloadApiThunk(
    [
        types.DOWNLOAD_ALBUM_ARTWORK_REQUEST,
        types.DOWNLOAD_ALBUM_ARTWORK_SUCCESS,
        types.DOWNLOAD_ALBUM_ARTWORK_FAILURE,
    ], {
        endpoint: `dsp/product/${id}/artworkRaw`,
        method: 'get',
    }, {
        id,
    }
)

export const postAlbum = (userLanguage: string) => callApi(
    [
        types.CREATE_ALBUM_REQUEST,
        types.CREATE_ALBUM_SUCCESS,
        types.CREATE_ALBUM_FAILURE,
    ],
    {
        method: 'post',
        endpoint: 'dsp/product',
        body: {
            languageId: userLanguage ? userLanguage.toUpperCase() : 'EN',
            releaseDate: getRestrictedMinReleaseDate()?.format(),
        },
    }
)

// UPDATE
export function updateAlbum(id: string | number, data: any): AnyAction {
    return {
        type: types.UPDATE_ALBUM,
        payload: {
            id,
            data,
        },
    }
}

export const removeAlbum = (id: string | number) => callApiThunk(
    [
        types.REMOVE_ALBUM_REQUEST,
        types.REMOVE_ALBUM_SUCCESS,
        types.REMOVE_ALBUM_FAILURE,
    ], {
        method: 'delete',
        endpoint: `dsp/product/${id}`,
    }, {
        id,
    }
)

// VALIDATE
export function validateSettings(id: string | number, fields: any[]) {
    return {
        [VALIDATE]: {
            types: [
                types.VALIDATE_SETTINGS_SUCCESS,
                types.VALIDATE_SETTINGS_FAILURE,
            ],
            payload: {
                fields,
                rules: settingsRules,
            },
            meta: {
                id,
            },
        },
    }
}

// todo  this action type is - like CALL_API - odd because it has no type and is transformed in middleware, this
// todo  needs sorting to make it more redux-like.
export function validatePackage(
    id: string | number,
    fields: any[],
    fieldsToValidate: (keyof typeof packageRules)[] | null = null
) {
    const rules = (fieldsToValidate === null)
        ? packageRules
        : fieldsToValidate.reduce((map, field) => (
            {
                ...map,
                [field]: packageRules[field],
            }
        ), {})

    return {
        [VALIDATE]: {
            types: [
                types.VALIDATE_PACKAGE_SUCCESS,
                types.VALIDATE_PACKAGE_FAILURE,
            ],
            payload: {
                fields,
                rules,
            },
            meta: {
                id,
                fieldsToValidate,
            },
        },
    }
}

export const uploadCover = (id: string | number, file: any) => uploadChunkedApiThunk(
    [
        types.UPLOAD_COVER_REQUEST,
        types.UPLOAD_COVER_SUCCESS,
        types.UPLOAD_COVER_FAILURE,
        types.UPLOAD_COVER_PROGRESS,
    ], {
        endpoint: `dsp/product/${id}/artwork`,
        file,
    }, {
        id,
        uploadId: `product-artwork-${id}`,
    }
)

export const abortCover = (id: string | number) => abortChunkedUploadApi(
    types.UPLOAD_COVER_ABORT,
    { abort: true },
    { id, uploadId: `product-artwork-${id}` }
)

export function removeCover(id: string | number): AnyAction {
    return {
        type: types.REMOVE_COVER,
        meta: { id },
    }
}

export function coverValidationError(id: string | number, error: any): AnyAction {
    return {
        error,
        type: types.UPLOAD_COVER_FAILURE,
        meta: { id },
    }
}

export function confirmCover(id: string | number): AnyAction {
    return {
        type: types.CONFIRM_COVER,
        meta: { id },
    }
}

export function unconfirmCover(id: string | number): AnyAction {
    return {
        type: types.UNCONFIRM_COVER,
        meta: { id },
    }
}
