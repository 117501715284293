import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import EditProfileDropzone from 'components/Dropzone/EditProfileDropzone'
import { logoMaxSize } from 'constants/FileSizes'
import { imageTypesExtended } from 'constants/FileTypes'
import { getPopupDataEditProfileRemoveLogo } from 'utilities/popups'
import t, { tmarkdown } from 'utilities/translate'
import { isCroppingSupported } from 'constants/browserCompatybility'

const logoRequirements = {
    minWidth: 300,
    minHeight: 50,
}

const LogoUpload = ({
    isProcessing,
    isProcessingForm,
    error,
    logo,
    removeLogoImage,
    uploadLogoImage,
    uploadLogoValidationError,
    openPopup,
    addImagesToCrop,
}) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    useEffect(() => {
        setIsImageLoaded(false)
    }, [isProcessing])

    const validateFile = (file) => {
        const maxFileSize = logoMaxSize
        const { jpg, jpeg, png } = imageTypesExtended
        const acceptImgMimes = [jpg, jpeg, png]
        if (file.size > maxFileSize) {
            uploadLogoValidationError('uploadEditProfileLogoFileMaxSize')
            return false
        } else if (acceptImgMimes.indexOf(file.type) < 0) {
            uploadLogoValidationError('uploadEditProfileLogoFileType')
            return false
        }
        return true
    }

    const prepareFilesToCrop = (files) => {
        const [file] = files
        if (validateFile(file)) {
            if (isCroppingSupported) {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(file)
                fileReader.onloadend = event => (
                    addImagesToCrop([{
                        src: event.target.result,
                        callback: uploadLogoImage,
                        requirements: logoRequirements,
                        validateSize: true,
                        sizeErrorMessage: 'uploadEditProfileLogoImageMinSize',
                    }])
                )
            } else {
                uploadLogoImage(file)
            }
        }
    }

    const handleRemove = (groupId) => {
        const onAccept = () => {
            removeLogoImage(groupId)
            setIsImageLoaded(false)
        }
        openPopup(getPopupDataEditProfileRemoveLogo(onAccept))
    }

    const isHeroValid = logo && logo.url
    const isLoading = isProcessing || (isHeroValid && !isImageLoaded)
    const imageGroupId = (isHeroValid && logo.groupId) || ''
    const hasError = !!error

    return (
        <div className="c-epu logo">
            <div className="c-epu-dropzone-wrapper logo">
                <EditProfileDropzone
                    uploadImage={prepareFilesToCrop}
                    removeImage={handleRemove}
                    isProcessing={isProcessing}
                    isLoaded={isImageLoaded}
                    isLoading={isLoading}
                    hasError={hasError}
                    hideUploadButton
                    disableDropzone={isProcessingForm}
                    imageGroupId={imageGroupId}
                    background={
                        (isHeroValid && !isProcessing) ? (
                            <div className={classnames({ 'c-epu-cover-loading': !isImageLoaded })}>
                                <img className="c-epu-cover-extend" src={logo.url} alt="" />
                                <img
                                    onLoad={() => { setIsImageLoaded(true) }}
                                    className="c-epu-cover"
                                    src={logo.url}
                                    alt=""
                                />
                            </div>
                        ) : null
                    }
                />
            </div>
            <div
                className="c-epu-description logo"
                dangerouslySetInnerHTML={{
                    __html: tmarkdown('uploadEditProfileLogoDescription'),
                }}
            />
            {hasError ? <p className="c-epu-errors logo">{t(error)}</p> : null}
        </div>
    )
}

LogoUpload.propTypes = {
    logo: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
        groupId: PropTypes.string,
    }),
    isProcessing: PropTypes.bool.isRequired,
    isProcessingForm: PropTypes.bool.isRequired,
    error: PropTypes.string,
    uploadLogoImage: PropTypes.func.isRequired,
    uploadLogoValidationError: PropTypes.func.isRequired,
    removeLogoImage: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    addImagesToCrop: PropTypes.func,
}

export default LogoUpload
