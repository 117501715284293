import { connect } from 'react-redux'
import { uploadHero, uploadHeroValidationError, removeHero } from 'actions/ProfileActions'
import { addPopup } from 'actions/PopupActions'
import HeroUpload from 'components/EditProfileUpload/HeroUpload'
import { addImagesToCrop } from 'actions/EditImages'

const mapStateToProps = state => ({
    hero: state.draftProfile.data.files.hero,
    isProcessing: state.draftProfile.isProcessingHero,
    isProcessingForm: state.draftProfile.isSubmitting || state.draftProfile.isPublishing,
    error: state.draftProfile.heroError,
})

const mapDispatchToProps = {
    uploadHeroImage: uploadHero,
    removeHeroImage: removeHero,
    openPopup: addPopup,
    addImagesToCrop,
    uploadHeroValidationError,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeroUpload)
