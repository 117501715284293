import { connect } from 'react-redux'

import {
    fetchMailingLists,
    fetchUserSubscriptions,
    subscribeToMailingLists,
    unsubscribeFromMailingList,
    unsubscribeFromAll,
} from 'actions/CrmActions'

import {
    isFetchingUserSubscriptions,
    isFetchingMailingLists,
    isUpdating,
    hasFailedToFetch,
    getUserSubscriptions,
} from 'selectors/crmPreferences'

import CrmSubscriptions from 'components/CrmSubscriptions/CrmSubscriptions'

const mapStateToProps = state => ({
    userSubscriptions: getUserSubscriptions(state),
    isFetchingData: isFetchingUserSubscriptions(state) || isFetchingMailingLists(state),
    isUpdating: isUpdating(state),
    failedToFetch: hasFailedToFetch(state),
})

const mapDispatchToProps = (dispatch, ownProps) => {
    const { crmSecret } = ownProps

    return {
        getUserSubscriptions: () => {
            dispatch(fetchMailingLists(crmSecret))
                .then(dispatch(fetchUserSubscriptions(crmSecret)))
        },
        subscribeToMailingLists: mailingListIds => dispatch(subscribeToMailingLists(mailingListIds, crmSecret)),
        unsubscribeFromMailingLists: mailingListIds => (
            Promise.all(mailingListIds.map(id => dispatch(unsubscribeFromMailingList(id, crmSecret))))
        ),
        unsubscribeFromAll: () => dispatch(unsubscribeFromAll(crmSecret)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmSubscriptions)
