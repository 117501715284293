import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import moment from 'moment'
import Spinner from 'components/Spinner/Spinner'
import Tooltip from 'components/Tooltip/Tooltip'
import TimebarConfig, { units, SCOPE, GRAIN } from './TimebarConfig'
import TimebarAxis from './TimebarAxis'
import TimebarChannel from './TimebarChannel'

class Timebar extends Component {
    timebarElRef = null

    static propTypes = {
        help: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        children: PropTypes.node,
    }

    constructor(props) {
        super(props)
        this.state = {
            axis: null,
            isScrollable: false,
            scope: null,
            config: TimebarConfig,
        }

        const dayAxis = this.getDaysAxis()
        const scope = this.getScopeFromDays(dayAxis)
        this.changeScope(scope, dayAxis, true)
    }

    componentDidMount() {
        window.addEventListener('resize', this.recalculateIsScrollable)
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            const dayAxis = this.getDaysAxis()
            const scope = this.getScopeFromDays(dayAxis)
            this.changeScope(scope, dayAxis)

            this.recalculateIsScrollable()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.recalculateIsScrollable)
    }

    timebarGetChannels = () => {
        const { data } = this.props
        return (data.length && data[0].month)
            ? data[0].month.dsps.map(channel => ({
                name: channel.name,
                dates: channel.dates.map(d => ({
                    start: new Date(d.start).getTime(),
                    end: new Date(d.end).getTime(),
                })),
            }))
            : []
    }

    changeScope = (newScope, dayAxis, initialSet = false) => {
        // Add baseline date for first axis point
        // We do this because a range can start after the "natural" start for an axis point
        // - example:  weeks start on Sunday, but THAT first sunday wont necessarily be in the
        // -           range meaning we'll miss the first axis point from the time bar
        const baseAxisDateForScope = newScope.rebaseDate(dayAxis[0])

        if (dayAxis.findIndex(a => a === baseAxisDateForScope) === -1) {
            dayAxis.unshift(Number(baseAxisDateForScope))
        }

        // de-duplicate and filter
        const baseAxis = [...new Set(dayAxis)].filter((d, i) => newScope.filter(d, i, dayAxis.length))

        const axis = this.applyAxisConfiguration(baseAxis, newScope)
        if (initialSet) {
            this.state.axis = axis
            this.state.scope = newScope
        } else {
            this.setState({
                axis,
                scope: newScope,
            })
        }
    }

    timebarCalcRange = () => {
        const channels = this.timebarGetChannels()

        return (channels.length > 0) ? {
            start: Math.min(
                ...channels
                    .map(c => c.dates)
                    .map(ds => ds.map(d => moment(d.start).utc().startOf('day').format('x')))
                    .flat()
            ),
            end: Math.max(
                ...channels
                    .map(c => c.dates)
                    .map(ds => ds.map(d => moment(d.end).utc().endOf('day').format('x')))
                    .flat()
            ),
        } : {
            start: moment().utc()
                .startOf('week').startOf('day')
                .format('x'),
            end: moment().utc()
                .endOf('week').endOf('day')
                .format('x'),
        }
    }

    /**
     * Supplied base axis is ready for consumption based on data
     * - the following configs are [OPTIONALLY] applied for aesthetic reasons
     * - NOTE:: Axis config is applied AFTER Grain Tolerance settings..
     * -        so you will be adding to the day / Week / Month auto scaling of the timebar
     */
    applyAxisConfiguration = (base, scope) => {
        const { config } = this.state
        const repeat = (cb, n) => [...Array(n).keys()].forEach(() => cb()) // utility

        if (config.RANGE_PAD_LEFT > 0) {
            repeat(() => {
                const startOfPrevAxisPoint = scope.prev(parseInt(base[0], 10))
                base.unshift(parseInt(startOfPrevAxisPoint, 10))
            }, config.RANGE_PAD_LEFT)
        }

        if (config.RANGE_PAD_RIGHT > 0) {
            repeat(() => {
                const startOfNextAxisPoint = scope.next(parseInt(base[base.length - 1], 10))
                base.push(parseInt(startOfNextAxisPoint, 10))
            }, config.RANGE_PAD_RIGHT)
        }

        return base
    }

    setTimeBarElRef = (el) => {
        this.timebarElRef = el
    }

    recalculateIsScrollable = () => this.setState({
        isScrollable: this.timebarElRef.scrollWidth > this.timebarElRef.clientWidth,
    })

    getDaysAxis = () => {
        const range = this.timebarCalcRange()
        const durationInDays = Math.ceil(Math.abs(moment.duration(range.end - range.start).asDays()))

        return [...Array(durationInDays).keys()]
            .map(fromStart => Number(range.start) + (Number(fromStart) * units.ts_day))
    }

    getScopeFromDays = (days) => {
        if (GRAIN.is('years', days.length)) {
            return SCOPE.YEARS
        } else if (GRAIN.is('months', days.length)) {
            return SCOPE.MONTHS
        } else if (GRAIN.is('weeks', days.length)) {
            return SCOPE.WEEKS
        } else if (GRAIN.is('days', days.length)) {
            return SCOPE.DAYS
        } else {
            return SCOPE.MONTHS
        }
    }

    render() {
        const {
            title,
            help,
            children,
        } = this.props

        const {
            axis,
            isScrollable,
            scope,
        } = this.state

        const channels = this.timebarGetChannels()

        const renderScrollable = jsx => (isScrollable ? <div className="c-timebar-scroll-shadow">{jsx}</div> : jsx)

        const classes = ['c-timebar']
        if (isScrollable) {
            classes.push('c-timebar-scroll')
        }

        return (
            <div className="c-timebar-view">

                <h2>
                    <Tooltip text={help.text}>
                        {title}
                    </Tooltip>
                </h2>

                {renderScrollable(
                    <div ref={this.setTimeBarElRef} className={classes.join(' ')}>

                        <TimebarAxis axis={axis} format={scope.axisFormat} />

                        <div className="c-timebar-channels">
                            {
                                channels ? channels.map(c => (
                                    <TimebarChannel
                                        scope={scope}
                                        title={c.name}
                                        axis={axis}
                                        channel={c}
                                        key={c.name}
                                    />
                                )) : <Spinner />
                            }
                        </div>
                    </div>
                )}

                { children }

            </div>
        )
    }
}

export default Timebar
