import moment from 'moment'

/**
 * General Settings
 */
const TimebarConfig = {
    RANGE_PAD_LEFT: 1,
    RANGE_PAD_RIGHT: 1,
    DEBUG_TS_FORMAT: 'DD MMM YY H:m:s',
    MANY_AXIS_POINT_THRESHOLD: 10,
    AXIS_LABEL_SKIPS: 2,
    ANALOG_SEGMENT_WIDTHS: true,
}

/**
 * Utility for readable timestamp and day (timebar base units) to other timeframes
 */
export const units = {
    ts_day: (1000 * 60 * 60 * 24),
    days: n => n,
    weeks: n => n * 7,
    months: n => n * (365 / 12),
    years: n => n * 365,
}

/**
 * Definition for how the axis will autoscale
 *      weeks: [minDays, maxDays],
 *      months: [minDays, maxDays],
 *      days: [minDays, maxDays],
 *      is: ([days|weeks|months...], numberOfDays) :: Boolean
 */
export const GRAIN = {
    days: [1, 13],
    weeks: [units.weeks(2), units.weeks(11)],
    months: [units.months(3), units.months(14)],
    years: [units.years(1), units.years(99999999)],
    is: (grain, n) => {
        const r = GRAIN[grain.toLowerCase()]
        const isAfterStart = n >= r[0]
        const isBeforeEnd = n <= r[1]
        return isAfterStart && isBeforeEnd
    },
}

/**
 * View configs for given Axis unit sizes
 *      type: a named label
 *      numDays: how many days (roughly) in a unit
 *      axisFormat: Print format for the Axis
 *      labelFormat: Print format for the run labels
 *      rebaseDate: Given any timestamp return the timestamp for the corresponding prior axis units start date
 *      filter: Filter an array by axis start dates
 */
export const SCOPE = {
    DAYS: {
        type: 'DAYS',
        numDays: Math.floor(units.days(1)),
        axisFormat: () => 'DD MMM',
        labelFormat: 'DD MMM',
        rebaseDate: d => d,
        prev: d => moment(d, 'x').utc()
            .subtract(1, 'day')
            .valueOf(),
        next: d => moment(d, 'x').utc()
            .add(1, 'day')
            .valueOf(),
        filter: () => true,
    },
    WEEKS: {
        type: 'WEEKS',
        numDays: Math.floor(units.weeks(1)),
        axisFormat: () => 'DD MMM',
        labelFormat: 'DD MMM',
        rebaseDate: d => moment(d)
            .valueOf(),
        prev: d => moment(d, 'x').utc()
            .subtract(1, 'week')
            .valueOf(),
        next: d => moment(d, 'x').utc()
            .add(1, 'week')
            .valueOf(),
        filter: ms => (moment(ms).utc().format('d') === '0'),
    },
    MONTHS: {
        type: 'MONTHS',
        numDays: Math.floor(units.months(1) + 4), // add 4 to encapsulate 28 and 31 day months (we'll be rebasing)
        axisFormat: () => 'MMM',
        labelFormat: 'DD MMM',
        rebaseDate: d => moment(d, 'x')
            .valueOf(),
        prev: d => moment(d, 'x').utc()
            .subtract(1, 'month')
            .valueOf(),
        next: d => moment(d, 'x').utc()
            .add(1, 'month')
            .valueOf(),
        filter: ms => (moment(ms, 'x').utc().format('DD') === '01'),
    },
    YEARS: {
        type: 'YEARS',
        numDays: Math.floor(units.years(1)),
        axisFormat: () => 'YYYY',
        labelFormat: 'DD MMM YY', // 15 Nov 2017
        rebaseDate: d => moment(d)
            .valueOf(),
        prev: d => moment(d, 'x').utc()
            .subtract(1, 'year')
            .valueOf(),
        next: d => moment(d, 'x').utc()
            .add(1, 'year')
            .valueOf(),
        filter: ms => (moment(ms, 'x').utc().format('DD MM') === '01 01'),
    },
}

export default TimebarConfig
