import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { unsubscribeFromAll } from 'actions/CrmActions'
import { isUpdating, hasFailedToUpdate } from 'selectors/crmPreferences'

import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'
import t, { tmarkdown } from 'utilities/translate'

const UnsubscribeFromAll = (props) => {
    const {
        unsubscribeUserFromAll,
        isUnsubscribing,
        failedToUnsubscribe,
    } = props

    useEffect(() => {
        unsubscribeUserFromAll()
    }, [])

    return (
        <div className="v-unsubscribe-from-all">
            {isUnsubscribing && <Spinner />}

            {!isUnsubscribing && (
                <>
                    {failedToUnsubscribe
                        ? (
                            <div
                                className="v-unsubscribe-from-all-error"
                                dangerouslySetInnerHTML={{ __html: tmarkdown('viewUnsubscribeFromAllFailed') }}
                            />
                        )
                        : (
                            <>
                                <h1>{t('viewUnsubscribeFromAllHeading')}</h1>
                                <div
                                    className="v-unsubscribe-from-all-text"
                                    dangerouslySetInnerHTML={{ __html: tmarkdown('viewUnsubscribeFromAllText') }}
                                />
                            </>
                        )}
                </>
            )}

            <div className="v-unsubscribe-from-all-btns">
                <Button href="/">
                    {t('viewUnsubscribeFromAllBackToSite')}
                </Button>
            </div>
        </div>
    )
}

UnsubscribeFromAll.propTypes = {
    unsubscribeUserFromAll: PropTypes.func.isRequired,
    isUnsubscribing: PropTypes.bool.isRequired,
    failedToUnsubscribe: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isUnsubscribing: isUpdating(state),
    failedToUnsubscribe: hasFailedToUpdate(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    unsubscribeUserFromAll: () => dispatch(unsubscribeFromAll(ownProps.match.params.secret)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnsubscribeFromAll))
