import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormSyncErrors, getFormAsyncErrors } from 'redux-form'
import {
    isEmpty, omitBy, isUndefined, extend,
} from 'lodash'
import classnames from 'classnames'

import { fetchUserStats } from 'actions/UserStatistics'
import { hasCompleteArtistProfile, artistEnhancedSelector } from 'selectors/users'
import { userStatisticsSelector } from 'selectors/userStatistics'
import { withLocalisation } from 'contexts/localisation/localeProvider'
import scrollIntoView from 'utilities/scroll'
import { scrollUpToTop } from 'hooks/useScrollToTopEffect'
import {
    getIsSaving,
    requestSave,
    syncValidate,
    getArtistFormName,
} from 'modules/artist'

import Button from 'components/Button/Button'
import ConditionalAlert from 'containers/ConditionalAlert/ConditionalAlert'
import CreateReleaseBanner from 'containers/CreateReleaseBanner/CreateReleaseBanner'
import PromoWidget from 'containers/PromoWidget/PromoWidget'
import TrendsStats from 'containers/Trends/TrendsStats'
import PartnersWidget from 'containers/PartnersWidget/PartnersWidget'
import OverviewWidgetPanel from 'containers/OverviewWidgetPanel/OverviewWidgetPanel'
import OverviewProfile from 'containers/OverviewProfile/OverviewProfile'
import { Redirect } from 'react-router-dom'

class Overview extends Component {
    static propTypes = {
        hasCompleteArtistProfile: PropTypes.bool.isRequired,
        hasLiveReleases: PropTypes.bool.isRequired,
        hasErrors: PropTypes.bool.isRequired,
        isSaving: PropTypes.bool,
        fetchUserStats: PropTypes.func.isRequired,
        userStatistics: PropTypes.object.isRequired,
        isUserStatsLoading: PropTypes.bool.isRequired,
        locale: PropTypes.object.isRequired,
        artistIsEnhanced: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props)

        this.state = {
            isEditingProfile: !this.props.hasCompleteArtistProfile,
        }
    }

    componentDidMount() {
        this.props.fetchUserStats()
        scrollUpToTop()
    }

    // todo see if this can be flattened into did update, will require testing
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillUpdate({ isSaving: willBeSaving, hasErrors: willHaveErrors }) {
        const {
            isSaving,
        } = this.props

        // the request response is done (maybe with errors)
        if (isSaving === true && willBeSaving === false) {
            if (!willHaveErrors) {
                // eslint-disable-next-line react/no-will-update-set-state
                this.setState({ isEditingProfile: false })
            }
        }
    }

    componentDidUpdate(previousProps, { isEditingProfile: wasEditingProfile }) {
        const { isEditingProfile } = this.state

        if (wasEditingProfile !== isEditingProfile) {
            scrollIntoView('elementProfile')
        }
    }

    render() {
        const {
            hasLiveReleases,
            userStatistics,
            isUserStatsLoading,
            locale: { t, tmarkdown },
            artistIsEnhanced,
        } = this.props

        const className = classnames(
            'v-overview'
        )

        if (!artistIsEnhanced) {
            return (<Redirect to="/balance" />)
        }

        return (
            <div className={className}>
                <OverviewWidgetPanel
                    userReleaseStatistics={userStatistics}
                    isUserStatsLoading={isUserStatsLoading}
                    artistIsEnhanced={artistIsEnhanced}
                />

                <ConditionalAlert id="christmas-delivery-alert" type="christmas">
                    <div dangerouslySetInnerHTML={{ __html: tmarkdown('containerReleaseDateChristmasDelivery') }} />
                </ConditionalAlert>

                <PromoWidget />

                {window.env.SPINNUP_ARTIST_SITE_CMS_ENABLED && artistIsEnhanced
                    ? (
                        <OverviewProfile />
                    )
                    : null}

                <CreateReleaseBanner />

                {
                    hasLiveReleases && artistIsEnhanced ? (
                        <div className="stats">
                            <h1 className="h3">{t('viewOverviewSectionStatsTitle')}</h1>

                            <div className="viewMore">
                                <p>{t('viewOverviewSectionStatsText')}</p>

                                <div className="cta">
                                    <Button href="/trends">
                                        {t('viewOverviewViewStats')}
                                    </Button>
                                </div>
                            </div>

                            <TrendsStats />
                        </div>
                    ) : null
                }

                <PartnersWidget />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const syncErrors = getFormSyncErrors(getArtistFormName())(state)
    const asyncErrors = getFormAsyncErrors(getArtistFormName())(state)
    const errors = omitBy(extend({}, syncErrors, asyncErrors), isUndefined)
    const userStatistics = userStatisticsSelector(state)

    return {
        hasCompleteArtistProfile: hasCompleteArtistProfile(state),
        hasLiveReleases: userStatistics.totalLive ? userStatistics.totalLive > 0 : false,
        isSaving: getIsSaving(state),
        hasErrors: !isEmpty(errors),
        userStatistics,
        isUserStatsLoading: state.userStatistics.isFetching,
        artistIsEnhanced: artistEnhancedSelector(state),
    }
}

const mapDispatchToProps = dispatch => ({
    fetchUserStats: () => dispatch(fetchUserStats()),
    saveProfile: () => {
        dispatch(syncValidate(getArtistFormName()))
        dispatch(requestSave())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withLocalisation(Overview))
