// @ts-nocheck
import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { Provider, connect } from 'react-redux'
import { persistStore } from 'redux-persist'
import SplashScreen from 'components/SplashScreen/SplashScreen'
import { LocaleProvider } from 'contexts/localisation/localeProvider'
import { AppStoreState } from 'store/store-types'
import LocalRouter from '../../routes'

type RootContainerPropTypes = {
    store: any
    isLoaded: boolean
    isAuthenticated: boolean
}

const RootContainer = ({ store, isLoaded, isAuthenticated }: RootContainerPropTypes) => {
    useEffect(() => {
        persistStore(store, {
            whitelist: ['auth'],
        })
    }, [])

    return !isLoaded ? <SplashScreen /> : (
        <Provider store={store}>
            <LocaleProvider>
                <LocalRouter isAuthenticated={isAuthenticated} />
            </LocaleProvider>
        </Provider>
    )
}

RootContainer.propTypes = {
    store: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
}

const mapStateToProps = (state: AppStoreState) => ({
    ...state.app,
    isAuthenticated: state.auth.isAuthenticated || false,
})

export default connect(mapStateToProps)(RootContainer)
