import React, {
    forwardRef,
    useState,
    useRef,
    useImperativeHandle,
} from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import { DragSource, DropTarget } from 'react-dnd'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Spinner from 'components/Spinner/Spinner'
import { getPopupDataEditProfileRemoveGallery } from 'utilities/popups'
import DraggableItemTypes from '../../constants/DraggableItemTypes'

const GalleryImage = forwardRef(
    ({
        index,
        length,
        image,
        disableActions,
        removeGalleryImage,
        openPopup,
        onMove,
        isDragging,
        connectDragSource,
        connectDropTarget,
    },
    ref) => {
        const { url, groupId, isProcessing } = image
        const [isLoading, setIsLoading] = useState(true)
        const elementRef = useRef(null)
        connectDragSource(elementRef)
        connectDropTarget(elementRef)
        useImperativeHandle(ref, () => ({
            getNode: () => elementRef.current,
        }))

        const handleMoveLeft = () => {
            onMove(index, index - 1)
        }

        const handleMoveRight = () => {
            onMove(index, index + 1)
        }

        const handleImageLoaded = () => {
            setIsLoading(false)
        }

        const handleRemove = () => {
            const onAccept = () => {
                removeGalleryImage(groupId)
            }
            openPopup(getPopupDataEditProfileRemoveGallery(onAccept))
        }

        return (
            <div
                className={classnames('c-epf-gallery-image-wrapper', { isDragging })}
                ref={!disableActions ? elementRef : null}
            >
                {disableActions ? <div className="c-epu-disabled-overlay" /> : null}
                {isLoading || isProcessing ? (
                    <div className="c-gallery-image-spinner-wrapper">
                        <Spinner size="medium" />
                    </div>
                ) : (
                    <div className="c-gallery-image-menu-wrapper">
                        <div className="c-gallery-image-menu">
                            {index !== 0 ? (
                                <div className="c-gallery-image-order" onClick={handleMoveLeft}>
                                    <FontAwesomeIcon icon="faAngleLeft" />
                                </div>
                            ) : (
                                <div className="c-gallery-image-order placeholder" />
                            )}
                            <div
                                className="c-gallery-image-remove"
                                onClick={!disableActions ? handleRemove : null}
                            >
                                <FontAwesomeIcon icon="faTrashAlt" />
                            </div>
                            {index !== length - 1 ? (
                                <div className="c-gallery-image-order" onClick={handleMoveRight}>
                                    <FontAwesomeIcon icon="faAngleRight" />
                                </div>
                            ) : (
                                <div className="c-gallery-image-order placeholder" />
                            )}
                        </div>
                    </div>

                )}
                <img
                    className={classnames('c-epf-gallery-image', { loading: isLoading })}
                    src={url}
                    alt=""
                    onLoad={handleImageLoaded}
                />
            </div>
        )
    }
)

GalleryImage.propTypes = {
    index: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
        isProcessing: PropTypes.bool,
    }),
    disableActions: PropTypes.bool.isRequired,
    removeGalleryImage: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
}

export default DropTarget(
    DraggableItemTypes.galleryFiles,
    {
        hover(props, monitor, component) {
            const node = component && component.getNode()
            if (!node) {
                return null
            }
            const { index: dragIndex } = monitor.getItem()
            const { index: hoverIndex, onMove } = props
            if (dragIndex === hoverIndex) {
                return null
            }

            // Get middle of the hovered element
            const hoverBoundingRect = node.getBoundingClientRect()
            const hoverElementMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
            const hoverElementMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine current position
            const mousePosition = monitor.getClientOffset()
            const dragPositionX = mousePosition.x - hoverBoundingRect.left
            const dragPositionY = mousePosition.y - hoverBoundingRect.top

            // Perform move only when the user crossed half of the hovered element
            if ((
                dragIndex < hoverIndex && (dragPositionY > hoverElementMiddleY || dragPositionX > hoverElementMiddleX)
            ) || (
                dragIndex > hoverIndex && (dragPositionY < hoverElementMiddleY || dragPositionX < hoverElementMiddleX)
            )) {
                onMove(dragIndex, hoverIndex)
                monitor.getItem().index = hoverIndex
            }
            return null
        },
    },
    connect => ({ connectDropTarget: connect.dropTarget() })
)(
    DragSource(
        DraggableItemTypes.galleryFiles,
        {
            beginDrag: ({ image: { groupId }, index }) => ({
                id: groupId,
                index,
            }),
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        })
    )(GalleryImage)
)
