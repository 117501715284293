import { ApiTransaction, Psp } from 'models/api/transaction'

export class Transaction implements ApiTransaction {
    amount: string = ''
    created: string = ''
    currency: string = ''
    id: number = -1
    psp: Psp = { type: 'paypal', params: { email: '' } }
    recipient: string | null = null
    status: 'FAILED' | 'FINALIZED' | 'PENDING' | 'PROCESSING' = 'PROCESSING'
    type: string = ''

    constructor(apiTransaction: ApiTransaction) {
        Object.assign(this as Transaction, apiTransaction)
    }
}
