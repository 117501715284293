import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import t from 'utilities/translate'
import Spinner from 'components/Spinner/Spinner'
import Button from 'components/Button/Button'
import TotalsList from 'components/TotalsList/TotalsList'
import ErrorOverlayMessage from 'components/ErrorOverlayMessage/ErrorOverlayMessage'

export default class TrendCountryTotals extends Component {
    static propTypes = {
        artistId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        saleType: PropTypes.string.isRequired,
        hasReleases: PropTypes.bool.isRequired,
        fetchTerritoryTrends: PropTypes.func.isRequired,
        filterOptions: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        failedFetching: PropTypes.bool.isRequired,
        dspTerritoryTotals: PropTypes.array.isRequired,
        dspTerritoryTotalRows: PropTypes.number.isRequired,
    };

    handleLoadMore = () => {
        this.props.filterOptions.page += 1
        const options = {
            ...this.props.filterOptions,
            page: this.props.filterOptions.page,
        }
        this.props.fetchTerritoryTrends(this.props.artistId, options)
    }

    render() {
        const {
            dspTerritoryTotals,
            isLoading,
            saleType,
            failedFetching,
            dspTerritoryTotalRows,
            hasReleases,
        } = this.props

        const recordsAllLoaded = dspTerritoryTotalRows <= dspTerritoryTotals.length
        const title = saleType === 'stream' ? t('totalStreamsCountry') : t('totalDownloadsCountry')
        const isDisabled = (failedFetching || (!hasReleases && !failedFetching))

        return (
            <div className="c-trends-country-totals">
                <h1>{title}</h1>
                {isLoading
                    && <Spinner size="small">{t('globalLoading')}</Spinner>
                || dspTerritoryTotals.length > 0
                    && (
                        <div>
                            <TotalsList data={dspTerritoryTotals} displayTotalRow={false} imageId="dune-2" />

                            <ErrorOverlayMessage
                                message={
                                    !hasReleases && !failedFetching
                                        ? t('trendsTotalExample')
                                        : t('trendsTotalCountriesUnavailable')
                                }
                                enabled={isDisabled}
                            />

                            {!recordsAllLoaded
                            && (
                                <div className="c-trends-country-totals-load-more">
                                    <Button onClick={this.handleLoadMore}>{t('loadMoreTrends')}</Button>
                                </div>
                            )}
                        </div>
                    )}
            </div>
        )
    }
}
