import { ApiSalesBreakdown, ApiSalesSubBreakdown } from 'models/api/sales-overview'

const defaultBreakdown: ApiSalesSubBreakdown = {
    download: {
        revenue: '',
        quantity: -1,
    },
    stream: {
        revenue: '',
        quantity: -1,
    },
}

export class SalesBreakdown implements ApiSalesBreakdown {
    countries: ApiSalesBreakdown['countries'] = []
    dsps: ApiSalesBreakdown['dsps'] = []
    releases: ApiSalesBreakdown['releases'] = []
    total: ApiSalesBreakdown['total'] = { revenue: '', breakdown: defaultBreakdown }

    constructor(apiBreakdown: Partial<ApiSalesBreakdown>) {
        Object.assign(this as SalesBreakdown, apiBreakdown)
    }
}
