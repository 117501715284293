import React, { useState } from 'react'
import { Field as ReduxField } from 'redux-form'
import PropTypes from 'prop-types'
import moment from 'moment'
import { currentDate } from 'utilities/currentDate'
import { InputField, Label } from 'components/EditProfileInputs/Inputs'
import DatePicker from 'components/DatePicker/DatePicker'
import Field from 'components/Field/Field'
import EditProfileLocation from 'components/EditProfileLocation/EditProfileLocation'
import t from 'utilities/translate'
import Button from 'components/Button/Button'
import { UPLOAD_TOUR_SUCCESS, UPLOAD_TOUR_FAILURE } from 'constants/ProfileActionTypes'
import { getUrlWithHttp } from 'utilities/url'

export const formatEditProfileTourDatesErrors = (message, errors) => Object.keys(errors).reduce((acc, key) => {
    const getMessage = Array.isArray(errors[key]) ? errors[key][0] : errors[key]
    const errorMessage = t(`${getMessage}`) || t(`${message}`)
    return {
        ...acc,
        [key]: errorMessage,
    }
}, {})

const EditProfileTourDatesForm = ({
    handleSubmit, addTour, valid, reset, isAddingNewTourDate, form: formName, setValidationError,
}) => {
    const [date, setDate] = useState(currentDate())
    const [dateError, setDateError] = useState(null)
    const onDateChange = (newDate) => {
        const dateToSet = newDate || currentDate()
        return setDate(dateToSet)
    }
    const handleClick = (data) => {
        const { ticketsUrl } = data
        addTour({
            ...data,
            date: moment(date).format('YYYY-MM-DD'),
            ticketsUrl: ticketsUrl && getUrlWithHttp(ticketsUrl),
        }).then(({ type, error }) => {
            if (type === UPLOAD_TOUR_SUCCESS) {
                reset(formName)
                setDate(currentDate())
            }
            if (type === UPLOAD_TOUR_FAILURE || error) {
                const { message, errors, date: dateErr } = error
                if (dateErr) {
                    setDateError(t(dateErr.toString()))
                }
                setValidationError(formName, {
                    ...formatEditProfileTourDatesErrors(message, errors),
                })
            }
        })
    }
    const isSubmitDisabled = !valid || isAddingNewTourDate
    return (
        <div className="c-epf-tour-dates-form">
            <ReduxField
                name="venue"
                label={t('formEditProfileFormAddVenueLabel')}
                placeholder={t('formEditProfileFormAddVenuePlaceholder')}
                component={InputField}
            />
            <EditProfileLocation />
            <ReduxField
                name="ticketsUrl"
                label={t('formEditProfileFormTicketSupplierUrlLabel')}
                placeholder={t('formEditProfileFormTicketSupplierUrlPlaceholder')}
                component={InputField}
            />
            <Label label={t('formEditProfileAddDateLabel')} />
            <Field className="c-datePicker-wrapper" error={dateError}>
                <DatePicker
                    onChange={onDateChange}
                    selected={date}
                    popperPlacement="top-start"
                    popperModifiers={{
                        flip: {
                            behavior: ['top-start'],
                        },
                        preventOverflow: {
                            enabled: false,
                        },
                        hide: {
                            enabled: false,
                        },
                    }}
                />
            </Field>
            <Button
                isLoading={isAddingNewTourDate}
                disabled={isSubmitDisabled}
                className="c-epf-tour-dates-button"
                onClick={handleSubmit(handleClick)}
            >
                {t('formEditProfileFormAddTourButtonText')}
            </Button>
        </div>
    )
}

EditProfileTourDatesForm.propTypes = {
    handleSubmit: PropTypes.func,
    addTour: PropTypes.func,
    valid: PropTypes.bool,
    reset: PropTypes.func,
    isAddingNewTourDate: PropTypes.bool,
    form: PropTypes.string,
    setValidationError: PropTypes.func.isRequired,
}

export default EditProfileTourDatesForm
