import { connect, ConnectedProps } from 'react-redux'

import { withdraw } from 'actions/WalletActions'

import WithdrawForm from 'components/WithdrawForm/WithdrawForm'
import { AppStoreState } from 'store/store-types'

const mapStateToProps = (state: AppStoreState) => ({
    isLoading: state.wallet.isWithdrawing,
    errors: state.wallet.errors,
})

const mapDispatchToProps = {
    withdraw,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export type WithdrawFormContainerProps = ConnectedProps<typeof connector>
export default connector(WithdrawForm)
