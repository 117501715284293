interface IRecordContract {
    id: number
    name: string
    contactEmail: string
    royaltyPercentage: number
}

export default class RecordContract implements IRecordContract {
    id: number = -1

    name: string = ''

    contactEmail: string = ''

    royaltyPercentage: number = -1

    constructor({
        id, name, contactEmail, royaltyPercentage,
    }: IRecordContract) {
        this.id = id
        this.name = name
        this.contactEmail = contactEmail
        this.royaltyPercentage = royaltyPercentage
    }
}
