import * as types from 'constants/LocaleActionTypes'
import { CLIENT_CALL_API } from 'middleware/api'

// FETCH

function getLocales() {
    return {
        [CLIENT_CALL_API]: {
            types: [
                types.FETCH_LOCALES_REQUEST,
                types.FETCH_LOCALES_SUCCESS,
                types.FETCH_LOCALES_FAILURE,
            ],
            payload: {
                endpoint: 'locales',
                query: {
                    part: 'links',
                    paginate: '0:500',
                },
            },
        },
    }
}

export function fetchLocales() {
    return (dispatch, getState) => getState().locales.locales.length === 0 && dispatch(getLocales())
}
