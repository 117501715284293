import { connect } from 'react-redux'
import { fetchTranslationLanguages } from 'actions/TranslationLanguageActions'
import {
    getTranslationLanguagesSelectOptions,
    getNativeTranslationLanguagesSelectOptions,
} from 'selectors/translationLanguages'
import LanguageSelect from 'components/LanguageSelect/LanguageSelect'

const mapStateToProps = state => ({
    translationLanguages: getTranslationLanguagesSelectOptions(state),
    nativeTranslationLanguages: getNativeTranslationLanguagesSelectOptions(state),
})

const mapDispatchToProps = {
    fetchTranslationLanguages,
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect)
