import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PopupModalBase from 'components/common/modals/base/PopupModalBase'
import { Button } from 'components/index'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Spinner from 'components/Spinner/Spinner'
import { releaseSelector } from 'selectors/releases'
import './_downloadReleaseFilesModal.scss'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

export const DownloadReleaseFilesModalComponent = ({
    release,
    onCloseModal,
}) => {
    const { t } = useLocaleContext()
    const [formattedTracks, setFormattedTracks] = useState([])

    useEffect(() => {
        if (release.tracks && release.tracks.length > 0) {
            const tracks = release.tracks || []
            const dspTracks = (release.album && release.album.tracks) || []

            const formatted = tracks.map((tr) => {
                const dspTrack = dspTracks.find(dspt => dspt.id === tr.providerId)
                return {
                    internalId: tr.id,
                    providerId: dspTrack.id,
                    title: dspTrack.title,
                    url: tr.url,
                }
            })

            setFormattedTracks(formatted)
        }
    }, [release.tracks])

    return (
        <PopupModalBase
            onClose={onCloseModal}
            size="small"
            title={t('modalReleaseDownloadFilesHeader')}
        >
            <div className="modal-content">
                <div className="artwork-download">
                    <a href={release.artworkUrl}>
                        <FontAwesomeIcon icon="faFileImage" />
                        {t('modalReleaseDownloadFilesCoverArtLabel')}
                    </a>
                </div>
                {
                    !release.tracks ? (
                        <Spinner />
                    ) : (
                        formattedTracks.map(track => (
                            <div key={track.internalId} className="track-download">
                                <a href={track.url}>
                                    <FontAwesomeIcon icon="faFileAudio" />
                                    {track.title}
                                </a>
                            </div>
                        ))
                    )
                }
            </div>

            <footer className="modal-actions">
                <Button
                    onClick={() => {
                        onCloseModal()
                    }}
                >{t('componentPopupButtonCancel')}
                </Button>
            </footer>
        </PopupModalBase>
    )
}

DownloadReleaseFilesModalComponent.propTypes = {
    release: PropTypes.object.isRequired,
    onCloseModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
    release: releaseSelector(state, ownProps.releaseId),
})

export default connect(mapStateToProps)(DownloadReleaseFilesModalComponent)
