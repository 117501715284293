import * as types from 'constants/EditImageActionTypes'

export const initialState = []

const generateId = () => `_${Math.random().toString(36).substr(2, 9)}`

export default (state = initialState, action) => {
    switch (action.type) {
        // FETCH
        case types.EDIT_IMAGES_ADD_IMAGES:
            const newImages = action.payload.map(image => ({ ...image, id: generateId() }))
            return [...state, ...newImages]
        case types.EDIT_IMAGES_REMOVE_IMAGE:
            const { payload } = action
            const filteredState = state.filter(img => img.id !== payload)
            return filteredState
        case types.EDIT_IMAGES_CLEAR_IMAGES_STATE:
            return []
        default:
            return state
    }
}
