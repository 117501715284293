import { Record } from 'immutable'
import * as types from 'constants/TrackVersionActionTypes'

const Version = new Record({
    id: undefined,
    createdDate: undefined,
    lastUpdatedDate: undefined,
    name: '',
})

const initialState = {
    isFetching: false,
    versions: [],
}

export default function versions(state = initialState, action) {
    switch (action.type) {
        // FETCH

        case types.FETCH_TRACK_VERSIONS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_TRACK_VERSIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                versions: action.payload.map(version => new Version(version)),
            }

        case types.FETCH_TRACK_VERSIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
            }

            // DEFAULT

        default:
            return state
    }
}
