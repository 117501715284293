import { createSelector } from 'reselect'
import * as statuses from 'constants/enums/ReleaseStatusTypes'
import { releaseTypesSelector } from 'selectors/releaseTypes'
import FormattedRelease from 'models/formattedRelease'
import { albumsSelector } from 'selectors/albums'
import { AppStoreState } from 'store/store-types'
import Release, { IRelease } from 'models/release'
import Album from 'models/album'
import { ApiReleaseType } from 'models/api/release-type'

function formatRelease(release: Release, album?: Album, releaseType?: ApiReleaseType) {
    const formattedRelease: IRelease = { ...release, album }

    if (!formattedRelease.releaseType && releaseType) {
        formattedRelease.releaseType = releaseType
    }

    return new FormattedRelease(formattedRelease)
}

export function filterReleases(releases: FormattedRelease[], filter: string) {
    if (!filter) return releases

    return releases.filter((release) => {
        const productStatus = release.album?.status || statuses.RELEASE_STATUS_NOT_COMPLETED
        const isProgress = release.process.step === 2 || release.process.step === 3
        const isComplete = [
            statuses.RELEASE_STATUS_READY_QC,
            statuses.RELEASE_STATUS_DELIVERED,
        ].indexOf(productStatus) !== -1
        const isLive = [statuses.RELEASE_STATUS_DELIVERED].indexOf(productStatus) !== -1

        switch (filter) {
            case 'uncomplete':
                return release.process.step === 1
            case 'progress':
                return isProgress
            case 'complete':
                return isComplete
            case 'live':
                return isLive
            case 'takedown':
                return [statuses.RELEASE_STATUS_TAKENDOWN].indexOf(productStatus) !== -1
            case 'live-takedown':
                return [statuses.RELEASE_STATUS_DELIVERED, statuses.RELEASE_STATUS_TAKENDOWN]
                    .indexOf(productStatus) !== -1
            case 'spotify-active':
                return !!release.spotifyUri && [statuses.RELEASE_STATUS_DELIVERED]
                    .indexOf(productStatus) !== -1
            case 'competition-valid':
                return (isProgress || isComplete) && !release.hasRecordContract
            default:
                return true
        }
    })
}

export const releasesSelector = createSelector(
    albumsSelector,
    (state: AppStoreState) => state.releases.releases,
    releaseTypesSelector,
    (albums, releases, releaseTypes) => Object.values(releases)
        .filter(release => !!albums.find(album => album.id === release.providerId))
        .map(release => formatRelease(
            release,
            albums.find(album => album.id === release.providerId),
            releaseTypes.find((releaseType: ApiReleaseType) => releaseType.id === release.releaseTypeId)
        ))
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
)

export const filteredReleasesSelector = createSelector(
    releasesSelector,
    (state: AppStoreState, filter: string) => filter,
    (releases, filter) => filterReleases(releases, filter)
)

export const releaseSelector = createSelector(
    releasesSelector,
    (state: AppStoreState, releaseId: string) => releaseId,
    (releases, selectedId) => releases.find(release => release.id === parseInt(selectedId, 10))
)

export const releaseSocialOptedIn = (id: string) => createSelector(
    releasesSelector,
    (releases) => {
        const rel = releases.find(release => release.id === parseInt(id, 10))
        return !!rel?.socialOptedinAt
    }
)

export const releaseYoutubeOptedIn = (id: string) => createSelector(
    releasesSelector,
    (releases) => {
        const rel = releases.find(release => release.id === parseInt(id, 10))
        return !!rel?.youtubeOptedinAt
    }
)

export const makeReleaseSelector = (id: string) => createSelector(
    releasesSelector,
    releases => releases.find(release => release.id === parseInt(id, 10))
)
