import * as PropTypes from 'prop-types'
import React, { MouseEventHandler } from 'react'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { translateMarkdown, TranslationKey } from 'contexts/localisation/translateUtilities'

type TranslateMarkdownProps = {
    id: TranslationKey
    args?: any[]
    useReactRouter?: boolean
    history?: any
    useSpan?: boolean
    className?: string
}

const TranslateMarkdown: React.FC<TranslateMarkdownProps> = ({
    id, args, useReactRouter, history, useSpan, className,
}) => {
    const { localeConfig } = useLocaleContext()

    if (useReactRouter && !history) {
        console.error('TranslateMarkdown - Attempting to use react router without supplied history object')
    }

    const interceptClick: MouseEventHandler<HTMLElement> = (event) => {
        if (event.target && (event.target as HTMLElement).tagName === 'A' && useReactRouter && history) {
            event.preventDefault()
            const aTag = event.target as HTMLElement
            const href = Array.prototype.find.call(aTag.attributes, attrib => attrib.name === 'href')
            history.push(href ? href.value : '/')
        }
    }

    if (useSpan) {
        return (
            <span
                className={className}
                dangerouslySetInnerHTML={{
                    __html: translateMarkdown(id, args, localeConfig.getLanguage()),
                }}
                onClick={interceptClick}
            />
        )
    }
    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: translateMarkdown(id, args, localeConfig.getLanguage()),
            }}
            onClick={interceptClick}
        />
    )
}

TranslateMarkdown.propTypes = {
    id: PropTypes.any.isRequired,
    args: PropTypes.array,
    useReactRouter: PropTypes.bool,
    history: PropTypes.object,
    useSpan: PropTypes.bool,
}

export default TranslateMarkdown
