import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { urlsSelector } from 'selectors/urls'

import { getAlbumReleaseFormName } from 'modules/release'

import {
    uploadCover,
    coverValidationError,
    unconfirmCover,
    confirmCover,
    validatePackage,
} from 'actions/AlbumActions'

import ReleasePackageForm from 'components/ReleasePackageForm/ReleasePackageForm'

const ReleasePackageReduxForm = reduxForm({
    form: getAlbumReleaseFormName(),
    enableReinitialize: true,
    destroyOnUnmount: false,
    shouldValidate: () => false,
    shouldError: () => false,
})(ReleasePackageForm)

const ReleasePackageContainer = connect(
    (state, ownProps) => ({
        urls: urlsSelector(state, ownProps),
        isUploadingArtwork: state.albums.albums[ownProps.album.id].isUploading,
        uploadProgress: state.albums.albums[ownProps.album.id].uploadProgress,

        // redux-form
        initialValues: {
            album: {
                id: ownProps.album.id,
                coverArtUrl: ownProps.album.coverArtUrl,
                coverArtUserConfirmed: ownProps.album.coverArtUserConfirmed,
            },
        },
    }),
    dispatch => ({
        uploadCover: (id, file) => dispatch(uploadCover(id, file)),
        coverValidationError: (id, error) => dispatch(coverValidationError(id, error)),
        confirmCover: (id, fields) => {
            dispatch(confirmCover(id))
            // Fields doesn't quite represent the full cycle from action -> reducer -> component, so simulate
            // the result of the action
            dispatch(validatePackage(id, {
                ...fields,
                coverArtUserConfirmed: true,
            }))
        },
        unconfirmCover: (id, fields) => {
            dispatch(unconfirmCover(id))
            // Fields doesn't quite represent the full cycle from action -> reducer -> component, so simulate
            // the result of the action
            dispatch(validatePackage(id, {
                ...fields,
                coverArtUserConfirmed: false,
            }))
        },
    })
)(ReleasePackageReduxForm)

export default ReleasePackageContainer
