import { createSelector } from 'reselect'

export const getDisconnectedServicesSelector = createSelector(
    state => state.services.services,
    services => services.toArray().filter(service => !service.valid && typeof service.partnerID !== 'undefined')
)

export const getConnectedServicesSelector = createSelector(
    state => state.services.services,
    services => services.toArray().filter(service => service.valid === true)
)

export const SocialServicesSelector = createSelector(
    state => state.services.services,
    services => services.toArray().filter(service => service.isSocial === true)
)
