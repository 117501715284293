import { REHYDRATE } from 'redux-persist/constants'
import * as types from 'constants/LanguageActionTypes'
import { AnyAction } from 'redux'
import { ApiLanguage } from 'models/api/language'
import { Language } from 'models/language'

type LanguageState = {
    isFetching: boolean
    languages: Language[]
    fetchedAt: number | Date
    error?: any
}

const initialState: LanguageState = {
    isFetching: false,
    languages: [],
    fetchedAt: 0,
}

export default function languages(state = initialState, action: AnyAction): LanguageState {
    switch (action.type) {
        case types.FETCH_LANGUAGES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_LANGUAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                languages: action.payload.map((language: ApiLanguage) => ({
                    id: language.id,
                    languageCode: language.id,
                    languageName: language.name,
                })),
                fetchedAt: new Date(),
            }

        case types.FETCH_LANGUAGES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            }

        case REHYDRATE:
            if (action.payload.languages) {
                return {
                    ...action.payload.languages,
                    languages: action.payload.languages.languages,
                }
            }
            return state

        default:
            return state
    }
}
