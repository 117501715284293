import * as types from 'constants/CrmActionTypes'
import { CALL_API } from 'middleware/api'

const getAuthorizationHeader = (secret, type = 'CrmSecret') => {
    if (!secret) {
        return {}
    }

    return {
        headers: {
            Authorization: `${type} ${secret}`,
        },
    }
}

export function fetchMailingLists(crmSecret) {
    return {
        [CALL_API]: {
            types: [
                types.CRM_FETCH_MAILINGLISTS_REQUEST,
                types.CRM_FETCH_MAILINGLISTS_SUCCESS,
                types.CRM_FETCH_MAILINGLISTS_FAILURE,
            ],
            payload: {
                method: 'get',
                endpoint: '/crm/mailinglists',
                ...getAuthorizationHeader(crmSecret),
            },

        },
    }
}

export function fetchUserSubscriptions(crmSecret) {
    return {
        [CALL_API]: {
            types: [
                types.CRM_FETCH_USER_SUBSCRIPTIONS_REQUEST,
                types.CRM_FETCH_USER_SUBSCRIPTIONS_SUCCESS,
                types.CRM_FETCH_USER_SUBSCRIPTIONS_FAILURE,
            ],
            payload: {
                method: 'get',
                endpoint: '/crm/subscriptions',
                ...getAuthorizationHeader(crmSecret),
            },
        },
    }
}

export function subscribeToMailingLists(mailingListIds, crmSecret) {
    return {
        [CALL_API]: {
            types: [
                types.CRM_SUBSCRIBE_REQUEST,
                types.CRM_SUBSCRIBE_SUCCESS,
                types.CRM_SUBSCRIBE_FAILURE,
            ],
            payload: {
                method: 'post',
                endpoint: '/crm/subscriptions',
                body: {
                    mailingListIds,
                },
                ...getAuthorizationHeader(crmSecret),
            },
            meta: {
                ids: mailingListIds,
            },
        },
    }
}

export function unsubscribeFromMailingList(mailingListId, crmSecret) {
    return {
        [CALL_API]: {
            types: [
                types.CRM_UNSUBSCRIBE_REQUEST,
                types.CRM_UNSUBSCRIBE_SUCCESS,
                types.CRM_UNSUBSCRIBE_FAILURE,
            ],
            payload: {
                method: 'delete',
                endpoint: `/crm/subscriptions/${mailingListId}`,
                ...getAuthorizationHeader(crmSecret),
            },
            meta: {
                id: mailingListId,
            },
        },
    }
}

export function unsubscribeFromAll(crmSecret) {
    return {
        [CALL_API]: {
            types: [
                types.CRM_UNSUBSCRIBE_ALL_REQUEST,
                types.CRM_UNSUBSCRIBE_ALL_SUCCESS,
                types.CRM_UNSUBSCRIBE_ALL_FAILURE,
            ],
            payload: {
                method: 'delete',
                endpoint: '/crm/subscriptions',
                ...getAuthorizationHeader(crmSecret),
            },
        },
    }
}

export function completeDoiProcess(doiSecret) {
    return {
        [CALL_API]: {
            types: [
                types.CRM_COMPLETE_DOI_REQUEST,
                types.CRM_COMPLETE_DOI_SUCCESS,
                types.CRM_COMPLETE_DOI_FAILURE,
            ],
            payload: {
                method: 'post',
                endpoint: '/crm/subscriptions/doubleoptin',
                body: {
                    secret: doiSecret,
                },
                ...getAuthorizationHeader(doiSecret, 'DoiSecret'),
            },
        },
    }
}
