const location = {
    countryId: 'countryId',
    city: 'city',
}

const baseSection = 'baseSection'
const storeProfiles = 'storeProfiles'
const music = 'music'
const socialMedia = 'socialMedia'
const videos = 'videos'
const gallery = 'gallery'
const tourDates = 'tourDates'
const about = 'about'

export const editProfileSections = {
    baseSection,
    storeProfiles,
    music,
    socialMedia,
    videos,
    gallery,
    tourDates,
    about,
}

export const spotifyProfileFieldName = 'spotifyProfile'

export const editProfileFields = {
    [baseSection]: {
        profileVisibility: 'profileVisibility',
        name: 'name',
        slug: 'slug',
        logo: 'logo',
        ...location,
        hero: 'hero',
        heroPlacement: 'heroPlacement',
        bgColorId: 'bgColorId',
        fontId: 'fontId',
        languageId: 'languageId',
    },
    [storeProfiles]: {
        spotifyProfile: spotifyProfileFieldName,
    },
    [music]: {
        releases: 'releases',
    },
    [socialMedia]: {
        facebookSocial: 'facebookSocial',
        instagramSocial: 'instagramSocial',
        twitterSocial: 'twitterSocial',
        soundcloudSocial: 'soundcloudSocial',
        youtubeSocial: 'youtubeSocial',
        spotifySocial: 'spotifySocial',
        appleMusicSocial: 'appleMusicSocial',
        otherSocial: 'otherSocial',
    },
    [videos]: {
        videos: 'videos',
    },
    [gallery]: {
        gallery: 'gallery',
    },
    [tourDates]: {
        venue: 'venue',
        ...location,
        ticketsUrl: 'ticketsUrl',
        date: 'date',
    },
    [about]: {
        biography: 'biography',
    },
}
