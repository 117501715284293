import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Element } from 'react-scroll'
import Spinner from 'components/Spinner/Spinner'
import { SocialServicesSelector } from 'selectors/services'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import SocialAccountContainer from './SocialAccount'

const SocialAccountsContainer = (props) => {
    const { isFetching, services } = props
    const { t } = useLocaleContext()

    return (
        <div className="c-social-accounts">
            <Element name="social-accounts" />

            <header>
                <h1>{t('containerSocialAccoutsPanelTitle')}</h1>
            </header>

            <p className="text-small">{t('containerSocialAccountsText')}</p>

            { isFetching
                ? (
                    <div>
                        <Spinner size="medium" className="c-social-accounts-saving">{t('globalLoading')}</Spinner>
                    </div>
                )
                : (
                    <div>
                        {services.map(service => (
                            <SocialAccountContainer
                                key={service.service}
                                service={service}
                            />
                        ))}
                    </div>
                )}

        </div>
    )
}

SocialAccountsContainer.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    services: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    isFetching: state.services.isFetching,
    fetchedAt: state.services.fetchedAt,
    services: SocialServicesSelector(state),
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccountsContainer)
