import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import classnames from 'classnames'

/**
 * Inputs
 *
 * Various form elements
 *
 * Styleguide: components.input
 */
export default class Input extends Component {
    static propTypes = {
        /** Type of input */
        type: PropTypes.string.isRequired,

        /** Defaulted value */
        value: PropTypes.string,

        /** Placeholder text */
        placeholder: PropTypes.string,

        /** Disable the input */
        disabled: PropTypes.bool,

        /** Focus the input */
        autoFocus: PropTypes.bool,

        autoComplete: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
        ]),

        /** Make it look like an error */
        error: PropTypes.bool,

        /** Make it look like an warning */
        warning: PropTypes.bool,

        /** Disable editing */
        isReadOnly: PropTypes.bool,

        /** Change handler */
        onChange: PropTypes.func,

        /** Blur handler */
        onBlur: PropTypes.func,

        /** Focus handler */
        onFocus: PropTypes.func,

        /** Handler when pressing ENTER in input */
        onEnter: PropTypes.func,

        className: PropTypes.string,
    };

    constructor(props) {
        super(props)
        this.inputRef = React.createRef()

        this.state = {
            disabled: props.disabled,
        }
    }

    componentDidUpdate() {
        if (this.props.autoFocus) {
            this.inputRef.current.focus()
        }
    }

    onKeyUp() {
        if (!this.props.onEnter) return () => {}

        return (e) => {
            e.preventDefault()

            const keyCode = e.keyCode || e.which

            if (keyCode === 13) {
                return this.props.onEnter(e)
            } else {
                return null
            }
        }
    }

    getValue() {
        return this.inputRef.current.value
    }

    setValue(value) {
        this.inputRef.current.value = value
    }

    setDisabled(disabled) {
        this.setState({ disabled })
    }

    select() {
        return this.inputRef.current.select()
    }

    render() {
        const {
            placeholder,
            type,
            value,
            autoFocus,
            autoComplete,
            onChange,
            onFocus,
            onBlur,
            isReadOnly,
            className,
        } = this.props

        const inputClassName = classnames(
            'c-input',
            className,
            {
                error: !!this.props.error,
                warning: !!this.props.warning,
            }
        )

        return (
            <input
                ref={this.inputRef}
                type={type}
                placeholder={placeholder}
                className={inputClassName}
                defaultValue={value || ''}
                disabled={this.state.disabled}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyUp={this.onKeyUp()}
                readOnly={isReadOnly}
            />
        )
    }
}
