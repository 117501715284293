import { camelizeKeys } from 'humps'
import Raven from 'raven-js'
import { isEmpty } from 'lodash'
import { fetchServices, setDefaultSocialPage } from 'actions/ServiceActions'
import {
    warnFetchError, getInstagramPages, getFacebookPages, getFacebookPermissions,
} from 'utilities/services'
import { FACEBOOK_GRAPH_URL } from 'constants/Services'
import { sendEventToDataLayer } from 'utilities/analytics'
import { URL_SETTINGS, URL_SETTINGS_TITLE } from 'constants/AppUrls'
import store from 'store/store'
import { GADataLayerEvent } from 'models/GADataLayerEvent'
import { AppStoreState } from 'store/store-types'

const checkFbAndInstaPages = (aeUser: any) => {
    const isUserEmpty = isEmpty(aeUser)
    const facebookService = !isUserEmpty && aeUser.services.length
        ? aeUser.services.find((service: any) => service.Service === 'facebook') : null

    if (facebookService) {
        const { UserID, Token } = facebookService

        getFacebookPermissions(UserID, Token)
            .then((json) => {
                if (json.data.length < 1) {
                    return Promise.reject()
                }

                const authenticatedInstagram = json.data.filter(
                    (permission: any) => permission.permission === 'instagram_basic' && permission.status === 'granted'
                ).length > 0

                return getFacebookPages(UserID, Token)
                    .then((jsonAccounts) => {
                        if (jsonAccounts.data.length < 1) {
                            return Promise.reject()
                        }

                        if (authenticatedInstagram) {
                            getInstagramPages(jsonAccounts.data, Token)
                                .then(pages => store.dispatch({
                                    type: 'AE_INSTAGRAM_PAGES',
                                    payload: pages,
                                }))
                                .catch(warnFetchError)
                        }
                        return store.dispatch({
                            type: 'AE_FACEBOOK_PAGES',
                            payload: camelizeKeys(jsonAccounts.data).map((page: any) => ({
                                value: page.id,
                                label: page.name,
                                getUrl: () => fetch(`${FACEBOOK_GRAPH_URL}${page.id}`
                                    + `?fields=link&access_token=${Token}`)
                                    .then(response => response.json())
                                    .then(obj => obj.link)
                                    .catch(warnFetchError),
                            })),
                        })
                    })
                    .then((action) => {
                        store.dispatch(setDefaultSocialPage(action.payload))
                    })
                    .catch(() => {
                        window.alert('You have no pages connected to this account.')
                        window.aeJS.trigger.remove('facebook')
                    })
            })
            .catch(warnFetchError)
    }
}

const onReady = () => {
    store.dispatch({
        type: 'AE_READY',
    })
}

const onUser = (user: any) => {
    try {
        const invalidService = user.services
            .find((service: any) => Number(service.PartnerID) !== (store.getState() as AppStoreState).users.user.id)

        if (invalidService) {
            store.dispatch({
                type: 'AE_INVALID_SERVICE',
                payload: camelizeKeys(invalidService),
            })

            window.aeJS.trigger.remove(invalidService.Service)
        }

        store.dispatch({
            type: 'AE_USER',
        })

        const stateUser = (store.getState() as AppStoreState).users.user
        const eventObj: Partial<GADataLayerEvent> = {
            pagePath: URL_SETTINGS,
            pageTitle: URL_SETTINGS_TITLE,
            pageUrl: `${window.location.origin}${URL_SETTINGS}`,
            platform: user.services[0].Service,
        }
        if (stateUser) {
            eventObj.userId = stateUser.id
            eventObj.country = stateUser.countryId
            eventObj.market = stateUser.market

            sendEventToDataLayer(
                'socialLink',
                eventObj
            )
        }

        checkFbAndInstaPages(user)
    } catch (e) {
        Raven.captureException(e)
    }
}

const onChange = (bustCache: any) => {
    fetchServices(bustCache)(store.dispatch, store.getState)
}

window.AEJSReady = (aeJS: any) => {
    try {
        window.aeJS = aeJS

        window.aeJS.settings = {
            ...aeJS.settings,

            partner_code: 'spinnup',
            partner_id: (store.getState() as AppStoreState).users.user.id,
            extra_fields_screen: 'after',
            auth_window: false,
        }

        window.aeJS.events.onLoaded.addHandler(onReady)
        window.aeJS.events.onUser.addHandler(onUser)
        window.aeJS.events.onLogin.addHandler(onUser)
        window.aeJS.events.onLogin.addHandler(onChange)
        window.aeJS.events.onServiceRemoved.addHandler(onChange)

        onChange(true)
    } catch (e) {
        Raven.captureException(e)
    }
}
