import * as statuses from 'constants/enums/ReleaseStatusTypes'
import { formatDateLocalised } from 'utilities/date'
import { ApiReleaseBase } from 'models/api/release'
import RecordContract from 'models/recordContract'
import { ApiReleaseType } from 'models/api/release-type'
import Payment from 'models/payment'
import { IRelease } from 'models/release'
import Album from 'models/album'

export interface IFormattedRelease extends ApiReleaseBase<RecordContract, Album> {
    isTakenDown?: boolean
    canPublish?: boolean
    isOnHold?: boolean
    canPay?: boolean
    hasRecordContract?: boolean
    latestPayment?: any
    process?: { step?: number }
}

const formatExpiresAt = (releaseModel: IFormattedRelease) => {
    const expiresAt = window.env.ENABLE_SUBSCRIPTION_MANAGEMENT
        ? releaseModel.subscription && releaseModel.subscription.expiresAt
        : releaseModel.expiresAt
    return expiresAt && formatDateLocalised(expiresAt)
}

const formatTakedownAt = (releaseModel: IFormattedRelease) => (
    releaseModel.takedownAt && formatDateLocalised(releaseModel.takedownAt)
)

function isStep(step: number, formattedModel: IFormattedRelease) {
    const productStatus = formattedModel.album?.status || statuses.RELEASE_STATUS_NOT_COMPLETED
    switch (step) {
        case 1:
            // User working on release
            return (
                (
                    [statuses.RELEASE_STATUS_NOT_COMPLETED].indexOf(productStatus) !== -1
                    && !formattedModel.isProcessing
                ) || (
                    productStatus === statuses.RELEASE_STATUS_REJECTED
                    && !formattedModel.isProcessing
                )
            )

        case 2:
            // Awaiting approval
            return (
                [statuses.RELEASE_STATUS_READY_QC].indexOf(productStatus) !== -1
                || (
                    productStatus === statuses.RELEASE_STATUS_NOT_COMPLETED
                    && formattedModel.isProcessing
                ) || (
                    productStatus === statuses.RELEASE_STATUS_REJECTED && formattedModel.isProcessing
                ) || (
                    productStatus === statuses.RELEASE_STATUS_PAUSED
                )
            )

        case 3:
            // Approved and being uploaded
            return [statuses.RELEASE_STATUS_APPROVED].indexOf(productStatus) !== -1

        case 4:
            // Sent to stores
            return [statuses.RELEASE_STATUS_DELIVERED].indexOf(productStatus) !== -1

        case 5:
            return [statuses.RELEASE_STATUS_TAKENDOWN].indexOf(productStatus) !== -1

        default:
            return false
    }
}

// The current step in the submission process, used for filtering
const formatProcess: (formattedModel: IFormattedRelease) => ({ step?: number }) = (formattedModel: any) => {
    for (let i = 1; i <= 4; i++) {
        if (isStep(i, formattedModel)) {
            return {
                step: i,
            }
        }
    }

    return {}
}

// If release has a record contract, no payment required. Else if the release has been rejected and there's no
// takedown (?) OR it has pending payments
const formatCanPublish = (formattedModel: IFormattedRelease) => !!formattedModel.recordContract || (
    !formattedModel.takedownAt && formattedModel?.album?.status === statuses.RELEASE_STATUS_REJECTED
)

export default class FormattedRelease implements IFormattedRelease {
    // Core release properties
    id: number = -1

    providerId: string = ''

    releaseTypeId: number = -1

    releaseType?: ApiReleaseType = undefined

    createdAt: string = ''

    expiresAt: string | null = null

    cancelledAt: string | null = null

    takedownAt: string | null = null

    onHoldAt: string | null = null

    socialOptedinAt: string | null = null

    youtubeOptedinAt: string | null = null

    isOptingIn: boolean | null = null

    isProcessing: boolean | null = null

    isRemoving: boolean | null = null

    tracks: any

    album?: Album // Provided by selector

    artworkUrl: string | null = null

    linkfire: any

    spotifyUri: string | null = null

    errors: any

    subscription: any

    price: string = ''

    releasePrice: string = ''

    currency: string = ''

    voucher: any

    payments: Payment[] = []

    recordContract?: RecordContract

    // Formatted release properties
    hasPayments?: boolean

    hasPendingPayments?: boolean

    hasCompletedPayments?: boolean

    isTakenDown?: boolean

    canPublish?: boolean

    isOnHold?: boolean

    canPay?: boolean

    hasRecordContract?: boolean

    latestPayment?: any

    prices?: any

    process?: any

    constructor(release: IRelease) {
        const isTakenDown = release?.album?.status === statuses.RELEASE_STATUS_TAKENDOWN
        const canPay = [statuses.RELEASE_STATUS_TAKENDOWN].indexOf(
            release.album?.status || statuses.RELEASE_STATUS_TAKENDOWN
        ) === -1

        const isOnHold = !!release.onHoldAt
        const hasRecordContract = !!release.recordContract

        const expiresAt = formatExpiresAt(release)
        const takedownAt = formatTakedownAt(release)

        const formattedModel: IFormattedRelease = {
            ...release,
            isTakenDown,
            canPay,
            isOnHold,
            hasRecordContract,
            expiresAt,
            takedownAt,
        }

        formattedModel.canPublish = formatCanPublish(formattedModel)
        formattedModel.process = formatProcess(formattedModel)

        Object.assign(this as FormattedRelease, formattedModel)
    }
}
