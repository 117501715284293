import { callSignedApiUrlPost } from 'actions/SignedUrlActions'

export const types = {
    VERIFICATION_SEND_REQUEST: 'VERIFICATION_SEND_REQUEST',
    VERIFICATION_SEND_SUCCESS: 'VERIFICATION_SEND_SUCCESS',
    VERIFICATION_SEND_FAILURE: 'VERIFICATION_SEND_FAILURE',
}

export function sendVerification(signedUrl) {
    return callSignedApiUrlPost([
        types.VERIFICATION_SEND_REQUEST,
        types.VERIFICATION_SEND_SUCCESS,
        types.VERIFICATION_SEND_FAILURE,
    ],
    signedUrl,
    undefined)
}
