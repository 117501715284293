import { createSelector } from 'reselect'

export const getCountriesPrefixes = state => state.countriesPrefixes.countriesPrefixes

export const getCountryPrefix = country => createSelector(
    getCountriesPrefixes,
    countriesPrefixes => countriesPrefixes.get(country)
)

const removeUnknownCountry = ({ countryCode }) => (countryCode !== 'ZZ')

const transformCountryPrefixToOption = countryPrefix => countryPrefix && ({
    // As some countries (e.g. Canada and US) share prefixes we can't use prefix for value
    value: countryPrefix.countryCode,
    label: `+${countryPrefix.phonePrefix}`,
    countryName: countryPrefix.countryName,
    countryCode: countryPrefix.countryCode,
    phonePrefix: countryPrefix.phonePrefix,
    flag: countryPrefix.flag,
})

export const getCountriesPrefixesOptions = createSelector(
    getCountriesPrefixes,
    countriesPrefixes => (
        countriesPrefixes.map(transformCountryPrefixToOption)
            .toList()
            .toArray()
            .filter(removeUnknownCountry)
            .sort((a, b) => (a.countryName > b.countryName ? 1 : -1))
    )
)

export const getCountryPrefixOption = country => createSelector(
    getCountryPrefix(country),
    countryPrefix => transformCountryPrefixToOption(countryPrefix)
)
