import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Icon from '../Icon/Icon'

const classNames = require('classnames')

export class TrendsToggleItem extends Component {
    static propTypes = {
        toggleIcon: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    onChange = () => {
        const { toggleIcon, onChange } = this.props
        onChange(toggleIcon.name)
    }

    render() {
        const { toggleIcon } = this.props

        let toggleClassname = classNames(
            'c-trends-toggle-item'
        )

        if (toggleIcon.isVisible) {
            toggleClassname = classNames(
                'c-trends-toggle-item',
                {
                    'toggle-line-spotify': toggleIcon.name === 'spotify',
                    'toggle-line-apple-music': toggleIcon.name === 'applemusic',
                    'toggle-line-deezer': toggleIcon.name === 'deezer',
                    'toggle-line-amazon': toggleIcon.name === 'amazon',
                    'toggle-line-itunes': toggleIcon.name === 'itunes',
                    'toggle-line-facebook': toggleIcon.name === 'facebook',
                    'toggle-line-twitter': toggleIcon.name === 'twitter',
                    'toggle-line-soundcloud': toggleIcon.name === 'soundcloud',
                    'toggle-line-youtube': toggleIcon.name === 'youtube',
                    'toggle-line-instagram': toggleIcon.name === 'instagram',
                }
            )
        }

        const elementId = `trendsToggleItem_${toggleIcon.name}_${Math.random().toString().substr(2)}`

        const getDspIcon = () => (
            toggleIcon.name === 'deezer'
                ? <Icon size={55} id="icon_deezer" />
                : <FontAwesomeIcon icon={toggleIcon.iconId} fixedWidth />
        )

        return (
            <label className={toggleClassname} htmlFor={elementId}>
                <input
                    id={elementId}
                    ref={toggleIcon.name}
                    type="checkbox"
                    checked={toggleIcon.isVisible}
                    onChange={this.onChange}
                />

                <span>{ getDspIcon(toggleIcon) }</span>
            </label>
        )
    }
}
