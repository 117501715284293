import * as types from 'constants/EditImageActionTypes'

export const addImagesToCrop = imagesData => ({
    type: types.EDIT_IMAGES_ADD_IMAGES,
    payload: imagesData,
})

export const deleteImageCropPopup = id => ({
    type: types.EDIT_IMAGES_REMOVE_IMAGE,
    payload: id,
})

export const clearImageCrop = () => ({
    type: types.EDIT_IMAGES_CLEAR_IMAGES_STATE,
})
