import { CALL_API } from 'middleware/api'
import { timeSince } from '../utilities/date'
import * as types from '../constants/TranslationLanguageActionTypes'

const fetchTime = 1000 * 60 * 60 * 24

// FETCH TRANSLATION LANGUAGES

function getTranslationLanguages() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_TRANSLATION_LANGUAGES_REQUEST,
                types.FETCH_TRANSLATION_LANGUAGES_SUCCESS,
                types.FETCH_TRANSLATION_LANGUAGES_FAILURE,
            ],
            payload: {
                endpoint: 'languages',
                query: {
                    has_translations: 1,
                },
            },
        },
    }
}

export function fetchTranslationLanguages() {
    return (dispatch, getState) => (
        timeSince(getState().translationLanguages.fetchedAt) >= fetchTime) && dispatch(getTranslationLanguages())
}
