import React from 'react'
import * as PropTypes from 'prop-types'
import { BalanceState } from 'reducers/wallet'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

type FormattedBalanceProps = {
    balance: Partial<BalanceState>
    balanceErrors: boolean
}

const FormattedBalance: React.FC<FormattedBalanceProps> = (props) => {
    const { balance, balanceErrors } = props
    const { t } = useLocaleContext()

    const amount = parseFloat(balance.amount || '0')
    return (!balanceErrors)
        ? (
            <p className="c-balance-formatted-balance">
                {amount !== 0 && balance.formatted !== balance.formattedLocal
                    ? <span className="local">~{balance.formattedLocal}</span>
                    : t('componentFormattedBalanceTotal')}
                <span className="total">
                    {amount === 0 && balance.formattedLocal ? balance.formattedLocal : balance.formatted}
                </span>
            </p>
        ) : (
            <p className="c-balance-formatted-balance">
                <span className="total"> - </span>
            </p>
        )
}

FormattedBalance.propTypes = {
    balance: PropTypes.object.isRequired,
    balanceErrors: PropTypes.bool.isRequired,
}

export default FormattedBalance
