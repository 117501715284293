import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Element } from 'react-scroll'

import t, { tmarkdown } from 'utilities/translate'
import scrollIntoView from 'utilities/scroll'
import Alert from 'components/Alert/Alert'
import Field from 'components/Field/Field'
import Textarea from 'components/Textarea/Textarea'
import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'

import ReleaseSelect from 'containers/ReleaseSelect/ReleaseSelect'
import TrackSelect from 'containers/TrackSelect/TrackSelect'

export default class Push extends Component {
    static propTypes = {
        scout: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired,
        releaseFilter: PropTypes.string.isRequired,
        hasValidReleases: PropTypes.bool.isRequired,
        pushScout: PropTypes.func.isRequired,
        getRelease: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)
        this.acceptedTermsRef = React.createRef()
        this.tracksRef = React.createRef()
        this.messageRef = React.createRef()
        this.state = {
            releaseId: undefined,
            release: undefined,
            trackId: undefined,
            acceptedTermsRequired: false,
            acceptedTerms: undefined,
            isDone: false,
        }
    }

    componentDidMount() {
        const { scout } = this.props
        if (scout.tandcsUrl || scout.privacyUrl) {
            this.setState({ acceptedTermsRequired: true })
        }
    }

    componentDidUpdate() {
        if (this.state.isDone) {
            scrollIntoView('nodeSuccessMsg')
        }
    }

    onReleaseSelect = (releaseId) => {
        this.props.getRelease(releaseId).then((action) => {
            this.setState({
                releaseId: action.payload.id,
                release: action.payload,
                trackId: undefined,
            })
        })
    }

    onTrackSelect = (trackId) => {
        this.setState({ trackId })
    }

    onTermsSelect = () => {
        let acceptedTermsValue = false
        if (this.acceptedTermsRef.current) {
            acceptedTermsValue = this.acceptedTermsRef.current.getValue()
        }
        this.setState({ acceptedTerms: acceptedTermsValue })
    }

    onPush = () => {
        const {
            releaseId, trackId, acceptedTerms,
        } = this.state

        this.setState({ isDone: false })

        this.props.pushScout(
            this.props.scout.id,
            releaseId,
            trackId,
            this.messageRef.current.getValue(),
            acceptedTerms
        ).then((action) => {
            if (action.payload) {
                this.messageRef.current.setValue('')
                this.setState({
                    releaseId: undefined,
                    trackId: undefined,
                    release: undefined,
                    acceptedTerms: undefined,
                    isDone: true,
                })
            }
        })
    }

    getCheckboxLabel() {
        const { scout } = this.props
        let checkboxLabel = null

        if (scout.tandcsUrl && scout.privacyUrl) {
            checkboxLabel = tmarkdown(
                'componentCompetitionConsentPrivacyAndTCsLabel',
                scout.tandcsUrl,
                scout.privacyUrl
            )
        } else if (scout.tandcsUrl) {
            checkboxLabel = tmarkdown('componentCompetitionConsentTCsLabel', scout.tandcsUrl)
        } else if (scout.privacyUrl) {
            checkboxLabel = tmarkdown('componentCompetitionConsentPrivacyLabel', scout.privacyUrl)
        }

        return checkboxLabel
    }

    canPush() {
        const {
            releaseId, trackId, acceptedTermsRequired, acceptedTerms,
        } = this.state

        if (!acceptedTermsRequired) {
            return releaseId && trackId
        } else {
            return releaseId && trackId && acceptedTerms
        }
    }

    renderDone = () => (
        <div className="c-push-form-success">
            <Element name="nodeSuccessMsg" />
            <Alert type="success">
                <p><strong>{t('componentPushFormSuccessHeading')}</strong></p>
                <p>{t('componentPushFormSuccessBody')}</p>
            </Alert>

            <p>
                <Button href="/overview">
                    {t('componentPushFormSuccessBtn')}
                </Button>
            </p>
        </div>
    )

    renderNoLiveReleases = () => (
        <Alert type="info">
            <p><strong>{t('componentPushFormNoLiveReleasesHeading')}</strong></p>
            <p>{t('componentPushFormNoLiveReleasesBody')}</p>
            <p>
                <Link to="/releases">
                    {t('componentPushFormNoLiveReleasesLink')}
                </Link>
            </p>
        </Alert>
    )

    renderForm = () => {
        const {
            isLoading, errors, hasValidReleases, releaseFilter,
        } = this.props

        const {
            release, releaseId, trackId, acceptedTermsRequired,
        } = this.state

        const checkboxLabel = this.getCheckboxLabel()

        return (
            <div className="c-push-form-form">
                <ReleaseSelect
                    label={t('containerPushFormLabelReleaseSelect')}
                    emptyLabel={t('containerPushFormLabelReleaseSelectEmpty')}
                    releaseFilter={releaseFilter}
                    value={releaseId}
                    onChange={this.onReleaseSelect}
                    disabled={!hasValidReleases}
                />

                {!hasValidReleases && this.renderNoLiveReleases()}

                {release
                    && (
                        <TrackSelect
                            ref={this.tracksRef}
                            id={release.providerId}
                            error={errors.trackId}
                            onChange={this.onTrackSelect}
                            value={trackId}
                            label={t('containerPushFormLabelTrackSelect')}
                        />
                    )}

                {releaseId && trackId
                    && (
                        <div>
                            <Field
                                label={t('containerPushFormLabelAddMessage')}
                                error={errors.message}
                            >
                                <Textarea
                                    ref={this.messageRef}
                                    maxLength={200}
                                    placeholder={t('containerPushFormMessagePlaceholder')}
                                />
                            </Field>

                            { acceptedTermsRequired
                        && (
                            <Field
                                error={t(errors.acceptedTerms)}
                            >
                                <Checkbox
                                    label={<span dangerouslySetInnerHTML={{ __html: checkboxLabel }} />}
                                    ref={this.acceptedTermsRef}
                                    name="accepted_terms"
                                    onChange={this.onTermsSelect}
                                />
                            </Field>
                        )}
                        </div>
                    )}

                {hasValidReleases
                    && (
                        <Button disabled={!this.canPush()} isLoading={isLoading} onClick={this.onPush}>
                            {t('containerPushFormButtonPush')}
                        </Button>
                    )}
            </div>
        )
    }

    render() {
        const { isDone } = this.state

        return (
            <div className="c-push-form">
                {isDone ? this.renderDone() : this.renderForm()}
            </div>
        )
    }
}
