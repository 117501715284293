import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import t from 'utilities/translate'
import Frame from 'components/Frame/Frame'
import ClipoutText from 'components/Frame/ClipoutText'
import WidgetButton from 'components/WidgetButton/WidgetButton'
import WidgetBannerLoader from 'components/WidgetBanner/WidgetBannerLoader'

export default class ReleasesWidget extends Component {
    static propTypes = {
        isFetching: PropTypes.bool.isRequired,
        disableCreation: PropTypes.bool.isRequired,
        streamTotal: PropTypes.number.isRequired,
        spotifyReleasesCount: PropTypes.number.isRequired,
        numberOfReleases: PropTypes.number.isRequired,
        liveReleasesCount: PropTypes.number.isRequired,
    };

    render() {
        const {
            isFetching,
            disableCreation,
            numberOfReleases,
            spotifyReleasesCount,
            streamTotal,
            liveReleasesCount,
        } = this.props

        const widgetClassname = classnames(
            'c-release-widget',
            'widget-container'
        )

        return (
            <Frame
                borderImageId="water-2"
                contentTint="light"
                contentClassName="widget-content"
            >
                {isFetching
                    && <WidgetBannerLoader />}

                {!isFetching && numberOfReleases === 0
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="water-2"
                            >
                                { t('releasesWidgetTitle') }
                            </ClipoutText>
                            <WidgetButton
                                href="/create"
                                text={t('releasesWidgetButton')}
                                isDisabled={disableCreation}
                            />
                        </div>
                    )}

                {!isFetching && numberOfReleases > 0 && (liveReleasesCount === 0 || spotifyReleasesCount === 0)
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="water-2"
                            >
                                { t('releasesWidgetHasReleasesTitle') }
                            </ClipoutText>
                            <p>
                                <span className="c-release-widget-label">
                                    {t('releasesWidgetHasReleasesSubTitle')}
                                </span>
                                <span className="c-release-widget-total">{numberOfReleases}</span>
                            </p>
                            <WidgetButton href="/releases" text={t('releasesWidgetHasReleasesButton')} />
                        </div>
                    )}
                {!isFetching && numberOfReleases > 0 && liveReleasesCount > 0 && spotifyReleasesCount > 0
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="water-2"
                            >
                                { t('releasesWidgetHasSpotifyTitle') }
                            </ClipoutText>
                            <p>
                                <span className="c-release-widget-label">
                                    {t('releasesWidgetHasSpotifySubTitle')}
                                </span>
                                <span className="c-release-widget-total">{streamTotal}</span>
                            </p>
                            <WidgetButton href="/trends" text={t('releasesWidgetHasSpotifyButton')} />
                        </div>
                    )}
            </Frame>
        )
    }
}
