import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import Field from 'components/Field/Field'
import t, { tmarkdown } from 'utilities/translate'
import Button from 'components/Button/Button'
import SongwriterPublisherList from 'components/TrackForm/Fields/SongwriterPublisherList'
import SongwriterPublisherInput from 'components/TrackForm/Fields/SongwriterPublisherInput'
import Tooltip from 'components/Tooltip/Tooltip'

const SongwriterPublisherField = (
    {
        input: {
            value: publishers,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
        numForms,
        copyPublisherToSiblings,
        publisherIsGlobal,
        updateCandidatePublisher,
        track,
        showCopyPopup: onCopyPopup,
        showDeletePopup: onDeletePopup,
    }
) => {
    const [pubSongNameWarning, setPubSongNameWarning] = useState(false)
    const [newPublisher, setNewPublisher] = useState({ person: { name: '' }, publishingHouse: '' })
    const emptyInputValue = { songwriter: '', publisher: '', hasPublisher: null }
    const [inputValue, setInputValue] = useState(emptyInputValue)
    const [canSubmit, setCanSubmit] = useState(false)

    const addNewSongWriterPublisher = (publisherToAdd) => {
        onChange(
            [
                ...publishers,
                publisherToAdd,
            ]
        )
        setInputValue(emptyInputValue)
        setCanSubmit(false)
        onBlur()
    }

    const getPubSongNameWarning = (candidate) => {
        const separators = /[,_\\\/&\-;:+]/
        return separators.test(candidate.person.name.trim())
    }

    const getCanSubmit = (candidate, hasPublisher) => candidate.person.name.length > 0
        && hasPublisher !== null
        && (
            !hasPublisher || (hasPublisher && candidate.publishingHouse.length > 0)
        )

    const newPublisherCandidate = (candidate, hasPublisher) => {
        setPubSongNameWarning(getPubSongNameWarning(candidate))
        const candidateCanSubmit = getCanSubmit(candidate, hasPublisher)
        setCanSubmit(candidateCanSubmit)
        setNewPublisher(candidate)
        setInputValue({ songwriter: candidate.person.name, publisher: candidate.publishingHouse, hasPublisher })

        if (candidateCanSubmit) {
            updateCandidatePublisher(
                () => addNewSongWriterPublisher(candidate),
                track.id
            )
        } else {
            updateCandidatePublisher(
                () => {},
                track.id
            )
        }
    }

    return (
        <div className="publishers">
            <Field
                label={t('containerTrackFormPublishersLabel')}
                error={touched ? t(error) : null}
                popover={{
                    title: t('containerTrackFormPublishersHelpTitle'),
                    text: tmarkdown('containerTrackFormPublishersHelpText'),
                }}
                warning={pubSongNameWarning ? t('modalAddPublisherSongwriterNameSeparatorWarningText') : null}
            >
                <p className="helpText">{t('containerTrackFormPublishersDetails')}</p>

                <div className="c-flex-publishers-table">
                    <div className="c-fc-header">
                        <div className="c-fi-head-col-cell swname">
                            <Tooltip
                                className="c-fi-tooltip-body"
                                text={tmarkdown('modalAddPublisherSongwriterNameHelpText')}
                            >
                                {t('componentTrackFormPublisherTableName')}
                            </Tooltip>
                        </div>
                        <div className="c-fi-head-col-cell pubname">
                            <Tooltip
                                className="c-fi-tooltip-body"
                                text={tmarkdown('modalAddPublisherSongwriterPublisherNameHelpText')}
                            >
                                {t('componentTrackFormPublisherTablePublishingHouse')}
                            </Tooltip>
                        </div>
                    </div>

                    <SongwriterPublisherList
                        track={track}
                        numTracks={numForms}
                        publishers={publishers}
                        onCopyPopup={onCopyPopup}
                        onDeletePopup={onDeletePopup}
                        copyPublisherToSiblings={copyPublisherToSiblings}
                        publisherIsGlobal={publisherIsGlobal}
                        onBlur={onBlur}
                        onChange={onChange}
                    />

                    <SongwriterPublisherInput
                        value={inputValue}
                        classname="publishers-inline-input"
                        onChange={newPublisherCandidate}
                    />
                </div>

                <div>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            addNewSongWriterPublisher(newPublisher)
                        }}
                        className="publishers-add-btn"
                        disabled={!canSubmit}
                    >
                        {`+ ${t('containerTrackFormPublishersAddButtonLabel')}`}
                    </Button>
                </div>
            </Field>
        </div>
    )
}

SongwriterPublisherField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    track: PropTypes.object.isRequired,
    numForms: PropTypes.number.isRequired,
    copyPublisherToSiblings: PropTypes.func.isRequired,
    publisherIsGlobal: PropTypes.func.isRequired,
    showCopyPopup: PropTypes.func.isRequired,
    showDeletePopup: PropTypes.func.isRequired,
    updateCandidatePublisher: PropTypes.func.isRequired,
}

export default SongwriterPublisherField
