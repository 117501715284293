import { debounce } from 'lodash'
import { spotifyProfileFieldName } from 'constants/EditProfileStructure'
import {
    draftProfileDataSelector,
    draftProfileFilesSelector,
    draftProfileSelector,
    gallerySelector,
    isGalleryReorderedSelector,
} from 'selectors/draftProfile'
import { artistSelector } from 'selectors/users'
import { maxGalleryLength } from 'constants/GalleryLength'
import { clearImageCrop } from 'actions/EditImages'
import { doInitialEditProfileLookup } from 'actions/DspLookupActions'
import { getPopupDataEditProfileGalleryFull } from 'utilities/popups'
import { addPopup } from 'actions/PopupActions'
import DraggableItemTypes from 'constants/DraggableItemTypes'
import { CALL_API, UPLOAD_API } from 'middleware/api'
import * as types from '../constants/ProfileActionTypes'

const ARTIST_PARTS = 'artist,country,language,background_color,font,files,links,videos,releases,tour_dates'

// PROFILE

function putProfile(id, body) {
    return {
        [CALL_API]: {
            types: [
                types.UPDATE_PROFILE_REQUEST_LEGACY,
                types.UPDATE_PROFILE_SUCCESS_LEGACY,
                types.UPDATE_PROFILE_FAILURE_LEGACY,
            ],
            payload: {
                method: 'PUT',
                endpoint: `artists/${id}`,
                query: {
                    part: 'profile,links,files',
                },
                body,
            },
        },
    }
}

const debouncedPutProfile = debounce((body, dispatch, getState, resolve, reject) => {
    const { id } = getState().users.user.artist

    return dispatch(putProfile(id, body)).then(resolve).catch(reject)
}, 300)

export function updateProfile(body) {
    return (dispatch, getState) => new Promise(
        debouncedPutProfile.bind(null, body, dispatch, getState)
    )
}

export function uploadProfileValidationError(error) {
    return {
        type: types.UPLOAD_PROFILE_VALIDATION_ERROR,
        payload: {
            error,
        },
    }
}

// PROFILE

function postUploadProfile(id, body) {
    return {
        [UPLOAD_API]: {
            types: [
                types.UPLOAD_PROFILE_REQUEST,
                types.UPLOAD_PROFILE_SUCCESS,
                types.UPLOAD_PROFILE_FAILURE,
                types.UPLOAD_PROFILE_PROGRESS,
            ],
            payload: {
                method: 'POST',
                endpoint: `files/artist/${id}/profile`,
                body,
            },
            meta: { id },
        },
    }
}

export function uploadProfile(file) {
    return (dispatch, getState) => {
        const { id } = getState().users.user.artist
        const body = new FormData()
        body.append('file', file)

        return dispatch(postUploadProfile(id, body))
    }
}

// DRAFT PROFILE
export function fetchDraftProfile(id) {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_DRAFT_PROFILE_REQUEST,
                types.FETCH_DRAFT_PROFILE_SUCCESS,
                types.FETCH_DRAFT_PROFILE_FAILURE,
            ],
            payload: {
                method: 'GET',
                endpoint: `profile/artist/${id}`,
                query: {
                    part: ARTIST_PARTS,
                    versionType: 'draft',
                },
            },
        },
    }
}

export function getDraftProfile(id) {
    return dispatch => (
        dispatch(fetchDraftProfile(id)).then((action) => {
            if (action.type === types.FETCH_DRAFT_PROFILE_SUCCESS) {
                dispatch(fetchSpotifyProfile(action.payload))
            }
        })
    )
}

export function initUpdateDraftProfile() {
    return {
        type: types.UPDATE_DRAFT_PROFILE,
    }
}

function postUpdateDraftProfile(id, body) {
    return {
        [CALL_API]: {
            types: [
                types.UPDATE_DRAFT_PROFILE_REQUEST,
                types.UPDATE_DRAFT_PROFILE_SUCCESS,
                types.UPDATE_DRAFT_PROFILE_FAILURE,
            ],
            payload: {
                method: 'PATCH',
                endpoint: `profile/artist/${id}`,
                query: {
                    part: ARTIST_PARTS,
                },
                body,
            },
        },
    }
}

export function updateDraftProfile(id, body) {
    return (dispatch, getState) => {
        dispatch(initUpdateDraftProfile())
        const isGalleryReordered = isGalleryReorderedSelector(getState())
        const actions = [postUpdateDraftProfile(id, body)]
        if (isGalleryReordered) {
            actions.push(updateGalleryOrder(id))
        }
        return Promise.all(
            actions.map(action => dispatch(action))
        ).then(([updateDraftProfileResponse]) => {
            if (updateDraftProfileResponse.type === types.UPDATE_DRAFT_PROFILE_SUCCESS) {
                dispatch(fetchSpotifyProfile(updateDraftProfileResponse.payload))
            }
            return updateDraftProfileResponse
        })
    }
}

export function initPublishDraftProfile() {
    return {
        type: types.PUBLISH_DRAFT_PROFILE,
    }
}

export function postPublishDraftProfile(id) {
    return {
        [CALL_API]: {
            types: [
                types.PUBLISH_DRAFT_PROFILE_REQUEST,
                types.PUBLISH_DRAFT_PROFILE_SUCCESS,
                types.PUBLISH_DRAFT_PROFILE_FAILURE,
            ],
            payload: {
                method: 'POST',
                endpoint: `profile/artist/${id}/publish`,
                query: {
                    part: 'artist',
                },
            },
        },
    }
}

export function publishDraftProfile(id) {
    return dispatch => dispatch(postPublishDraftProfile(id))
}

// PUBLISH PROFILE
export function initiatePublishingProfile(id, body) {
    return (dispatch) => {
        dispatch(initPublishDraftProfile())
        return dispatch(updateDraftProfile(id, body))
            .then((action) => {
                if (action.type === types.UPDATE_DRAFT_PROFILE_SUCCESS) {
                    return dispatch(publishDraftProfile(id))
                }
                return action
            })
            .catch(() => dispatch({
                type: types.PUBLISH_DRAFT_PROFILE_FAILURE,
            }))
    }
}

// FETCH SPOTIFY PROFILE
function fetchSpotifyProfile(payload) {
    const { links = [] } = payload
    const spotifyProfile = links.find(link => link.key === spotifyProfileFieldName)
    return dispatch => spotifyProfile && dispatch(doInitialEditProfileLookup(spotifyProfile.value))
}

// UPLOAD LOGO
function postUploadLogo(id, body) {
    return {
        [UPLOAD_API]: {
            types: [
                types.UPLOAD_LOGO_REQUEST,
                types.UPLOAD_LOGO_SUCCESS,
                types.UPLOAD_LOGO_FAILURE,
                types.UPLOAD_LOGO_PROGRESS,
            ],
            payload: {
                method: 'POST',
                endpoint: `files/profile_version/${id}/logo`,
                body,
            },
            meta: { id },
        },
    }
}

export function uploadLogo(file) {
    return (dispatch, getState) => {
        const { id } = draftProfileDataSelector(getState())
        const body = new FormData()
        body.append('file', file)

        return dispatch(postUploadLogo(id, body))
    }
}

export function uploadLogoValidationError(error) {
    return {
        type: types.UPLOAD_LOGO_VALIDATION_ERROR,
        payload: {
            error,
        },
    }
}

// REMOVE LOGO
function postRemoveLogo(groupId) {
    return {
        [CALL_API]: {
            types: [
                types.REMOVE_LOGO_REQUEST,
                types.REMOVE_LOGO_SUCCESS,
                types.REMOVE_LOGO_FAILURE,
            ],
            payload: {
                method: 'DELETE',
                endpoint: `files/group/${groupId}`,
            },
            meta: { groupId },
        },
    }
}

export function removeLogo(groupId) {
    return dispatch => dispatch(postRemoveLogo(groupId))
}

// UPLOAD HERO
function postUploadHero(id, body) {
    return {
        [UPLOAD_API]: {
            types: [
                types.UPLOAD_HERO_REQUEST,
                types.UPLOAD_HERO_SUCCESS,
                types.UPLOAD_HERO_FAILURE,
                types.UPLOAD_HERO_PROGRESS,
            ],
            payload: {
                method: 'POST',
                endpoint: `files/profile_version/${id}/hero`,
                body,
            },
            meta: { id },
        },
    }
}

export function uploadHero(file) {
    return (dispatch, getState) => {
        const { id } = draftProfileDataSelector(getState())
        const body = new FormData()
        body.append('file', file)

        return dispatch(postUploadHero(id, body))
    }
}

export function uploadHeroValidationError(error) {
    return {
        type: types.UPLOAD_HERO_VALIDATION_ERROR,
        payload: {
            error,
        },
    }
}

// REMOVE HERO
function postRemoveHero(groupId) {
    return {
        [CALL_API]: {
            types: [
                types.REMOVE_HERO_REQUEST,
                types.REMOVE_HERO_SUCCESS,
                types.REMOVE_HERO_FAILURE,
            ],
            payload: {
                method: 'DELETE',
                endpoint: `files/group/${groupId}`,
            },
            meta: { groupId },
        },
    }
}

export function removeHero(groupId) {
    return dispatch => dispatch(postRemoveHero(groupId))
}

// UPLOAD GALLERY IMAGE
function postUploadGallery(id, body) {
    return {
        [UPLOAD_API]: {
            types: [
                types.UPLOAD_GALLERY_REQUEST,
                types.UPLOAD_GALLERY_SUCCESS,
                types.UPLOAD_GALLERY_FAILURE,
                types.UPLOAD_GALLERY_PROGRESS,
            ],
            payload: {
                method: 'POST',
                endpoint: `files/profile_version/${id}/gallery`,
                body,
            },
            meta: { id },
        },
    }
}

export function uploadGallery(file) {
    return (dispatch, getState) => {
        const state = getState()
        const { id } = draftProfileDataSelector(state)
        const { uploadGalleryCounter } = draftProfileSelector(state)
        const galleryLength = draftProfileFilesSelector(state).gallery.length
        if (galleryLength + uploadGalleryCounter + 1 === maxGalleryLength) {
            dispatch(clearImageCrop())
            dispatch(addPopup(getPopupDataEditProfileGalleryFull()))
        }
        const body = new FormData()
        body.append('file', file)

        return dispatch(postUploadGallery(id, body))
    }
}

export function uploadGalleryValidationError(error) {
    return {
        type: types.UPLOAD_GALLERY_VALIDATION_ERROR,
        payload: {
            error,
        },
    }
}

// REMOVE GALLERY IMAGE
function postRemoveGallery(groupId) {
    return {
        [CALL_API]: {
            types: [
                types.REMOVE_GALLERY_REQUEST,
                types.REMOVE_GALLERY_SUCCESS,
                types.REMOVE_GALLERY_FAILURE,
            ],
            payload: {
                method: 'DELETE',
                endpoint: `files/group/${groupId}`,
            },
            meta: { groupId },
        },
    }
}

export function removeGallery(groupId) {
    return dispatch => dispatch(postRemoveGallery(groupId))
}

// REORDER GALLERY IMAGES

export function reorderGalleryImages(gallery) {
    return {
        type: types.REORDER_GALLERY_IMAGES,
        payload: {
            gallery,
        },
    }
}

// UPDATE GALLERY ORDER

export function postUpdateGalleryOrder(id, body) {
    return {
        [CALL_API]: {
            types: [
                types.UPDATE_GALLERY_ORDER_REQUEST,
                types.UPDATE_GALLERY_ORDER_SUCCESS,
                types.UPDATE_GALLERY_ORDER_FAILURE,
            ],
            payload: {
                method: 'PATCH',
                endpoint: `profile/artist/${id}/order`,
                query: {
                    part: 'files',
                },
                body,
            },
        },
    }
}

export function updateGalleryOrder(id) {
    return (dispatch, getState) => {
        const gallery = gallerySelector(getState())
        const body = {
            orderables: [
                {
                    type: DraggableItemTypes.galleryFiles,
                    ids: gallery.map(({ groupId }) => groupId),
                },
            ],
        }
        return dispatch(postUpdateGalleryOrder(id, body))
    }
}

// ADD TOURS

const postAddTour = (body, id) => ({
    [CALL_API]: {
        types: [
            types.UPLOAD_TOUR_REQUEST,
            types.UPLOAD_TOUR_SUCCESS,
            types.UPLOAD_TOUR_FAILURE,
        ],
        payload: {
            method: 'POST',
            endpoint: `profile/artist/${id}/tourDates`,
            body,
        },
        meta: { id },
    },
})

export function addTour(tourData) {
    return (dispatch, getState) => {
        const { id } = artistSelector(getState())

        return dispatch(postAddTour(tourData, id))
    }
}

// REMOVE TOURS

export const postRemoveTour = (tourId, id) => ({
    [CALL_API]: {
        types: [
            types.REMOVE_TOUR_REQUEST,
            types.REMOVE_TOUR_SUCCESS,
            types.REMOVE_TOUR_FAILURE,
        ],
        payload: {
            method: 'DELETE',
            endpoint: `profile/artist/${id}/tourDates/${tourId}`,
        },
        meta: { tourId },
    },
})

export const removeTour = tourId => (dispatch, getState) => {
    const { id } = artistSelector(getState())

    return dispatch(postRemoveTour(tourId, id))
}

export const postMarkSurveyAsViewed = (id, body) => ({
    [CALL_API]: {
        types: [
            types.SURVEY_VIEWED_REQUEST,
            types.SURVEY_VIEWED_SUCCESS,
            types.SURVEY_VIEWED_FAILURE,
        ],
        payload: {
            method: 'PUT',
            endpoint: `artists/${id}/survey`,
            body,
        },
    },
})

export const markSurveyAsViewed = surveyName => (dispatch, getState) => {
    const { id } = artistSelector(getState())
    const body = {
        survey: surveyName,
        viewed: true,
    }
    return dispatch(postMarkSurveyAsViewed(id, body))
}
