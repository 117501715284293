import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
import classnames from 'classnames'
import Overlay from 'components/Overlay/Overlay'
import HamburgerMenu from 'components/HamburgerMenu/HamburgerMenu'
import Icon from 'components/Icon/Icon'
import MessageBanners from 'containers/MessageBanners/MessageBanners'
import { URL_HOME } from 'constants/AppUrls'
import UpdateConsent from 'containers/Consent/UpdateConsent'
import { logoutAndRedirectTo } from 'actions/AuthActions'

export default class Header extends Component {
    static propTypes = {
        isBlockedPage: PropTypes.bool,
        isUserLoaded: PropTypes.bool,
        isAuthenticated: PropTypes.bool,
        isMaintenanceMode: PropTypes.bool,
        isShowingModal: PropTypes.bool,
        className: PropTypes.string,
        unauth: PropTypes.func.isRequired,
        urls: PropTypes.object.isRequired,
        locale: PropTypes.object.isRequired,
        menuItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        displayMenu: PropTypes.bool,
        artistIsEnhanced: PropTypes.bool,
    }

    static defaultProps = {
        displayMenu: true,
    }

    constructor(props) {
        super(props)

        this.state = {
            isSubNavVisible: true,
            isAtTopOfPage: true,
            isMobile: !window.matchMedia('(min-width: 992px)').matches,
            isHamburgerOpen: false,
        }
    }

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)

        const scrollRoot = document.getElementById('root')
        if (scrollRoot) {
            scrollRoot.addEventListener('scroll', this.handleScroll)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)

        const scrollRoot = document.getElementById('root')
        if (scrollRoot) {
            scrollRoot.removeEventListener('scroll', this.handleScroll)
        }
    }

    getCurrentPathState() {
        const { pathname } = this.props.location
        const subNavDisabledExpr = /^\/(start|mfa|verif|subscription\/pay|edit|password)/i
        return {
            isEditingRelease: pathname.startsWith('/edit'),
            currentPathSubNavEnabled: !subNavDisabledExpr.test(pathname),
        }
    }

    toggleHamburgerMenu = () => this.setState(prevState => ({ isHamburgerOpen: !prevState.isHamburgerOpen }))

    ensureHamburgerMenuIsClosed = () => {
        if (this.state.isHamburgerOpen) {
            this.toggleHamburgerMenu()
        }
    }

    onLogOut = () => {
        logoutAndRedirectTo(URL_HOME, this.props.unauth, this.props.history)
    }

    updateDimensions = () => {
        const isMobile = !window.matchMedia('(min-width: 992px)').matches

        if (isMobile !== this.state.isMobile) {
            this.setState({
                isMobile,
            })
        }
    }

    handleScroll = () => {
        const { scrollTop } = document.getElementById('root')
        const isAtTopOfPage = (scrollTop <= 0)

        const scrollDeltaTrigger = 63

        const isScrollingUp = (this.previousScrollTop - scrollTop) > 0
        const isScrollingDown = !isScrollingUp

        const isAlreadyScrollingUp = !!this.startScrollUpTop
        const isAlreadyScrollingDown = !!this.startScrollDownTop

        if (isScrollingUp) {
            if (!isAlreadyScrollingUp) {
                this.startScrollUpTop = scrollTop
            }
            this.startScrollDownTop = scrollTop
        } else {
            if (!isAlreadyScrollingDown) {
                this.startScrollDownTop = scrollTop
            }
            this.startScrollUpTop = scrollTop
        }

        const scrollUpDelta = this.startScrollUpTop - scrollTop
        const scrollDownDelta = scrollTop - this.startScrollDownTop

        const hasScrolledUpEnoughToShowSubNav = scrollUpDelta > scrollDeltaTrigger
        const hasScrolledDownEnoughToHideSubNav = scrollDownDelta > scrollDeltaTrigger

        if ((isScrollingUp && isAlreadyScrollingUp && hasScrolledUpEnoughToShowSubNav) || isAtTopOfPage) {
            const shouldUpdateState = this.state.isSubNavVisible !== true && this.state.isAtTopOfPage !== isAtTopOfPage

            if (shouldUpdateState) {
                this.setState({ isSubNavVisible: true, isAtTopOfPage })
            }
        }

        if (isScrollingDown && isAlreadyScrollingDown && hasScrolledDownEnoughToHideSubNav && !isAtTopOfPage) {
            const shouldUpdateState = this.state.isSubNavVisible !== false && this.state.isAtTopOfPage !== isAtTopOfPage

            if (shouldUpdateState) {
                this.setState({ isSubNavVisible: false, isAtTopOfPage })
            }
        }
        this.previousScrollTop = scrollTop
    }

    render() {
        const {
            className,
            urls,
            isAuthenticated,
            menuItems: {
                primary: primaryItems,
                secondary: secondaryItems,
            },
            isMaintenanceMode,
            displayMenu,
            isBlockedPage,
            isShowingModal,
            locale,
            artistIsEnhanced,
            isUserLoaded,
        } = this.props

        const {
            isSubNavVisible,
            isAtTopOfPage,
            isHamburgerOpen,
        } = this.state
        const { isEditingRelease, currentPathSubNavEnabled } = this.getCurrentPathState()

        const { t } = locale
        const { isMobile } = this.state

        const responsivePrimaryItems = isAuthenticated
            ? primaryItems[isMobile ? 'mobile' : 'desktop']
            : primaryItems

        const responsiveSecondaryItems = isAuthenticated
            ? secondaryItems[isMobile ? 'mobile' : 'desktop'][artistIsEnhanced ? 'enhancedLinks' : 'offboardedLinks']
            : secondaryItems

        const canHaveSubNav = currentPathSubNavEnabled && !isBlockedPage && !isShowingModal
        const canHaveMessageBanners = currentPathSubNavEnabled && !isBlockedPage

        const logoId = window.env.SPINNUP_IS_PRODUCTION ? 'spinnup_logo_2018' : 'spinnup_logo_2018_test'
        return (
            <>
                <UpdateConsent />
                <header
                    className={classnames(
                        'c-header',
                        className,
                        isAuthenticated
                            ? 'c-header-logged-in'
                            : 'c-header-logged-out',
                        {
                            'with-subnav': canHaveSubNav,
                        }
                    )}
                    role="banner"
                >
                    <div className="c-header-wrapper">
                        <div className="c-header-content" onClick={this.ensureHamburgerMenuIsClosed}>
                            {isAuthenticated ? (
                                <Link to="/" className="c-header-home-link">
                                    <Icon id={logoId} />
                                </Link>
                            ) : (
                                <a href={urls.publicWebsiteHome} className="c-header-home-link">
                                    <Icon id={logoId} />
                                </a>
                            )}

                            {!isMaintenanceMode
                                && (
                                    <div className="c-header-nav">
                                        {isAuthenticated ? (
                                            <div className="c-header-nav-actions">
                                                {isEditingRelease ? (
                                                    <Link
                                                        to="/releases"
                                                    >
                                                        {t('layoutEditReleaseNavClose')}
                                                    </Link>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div className="c-header-nav-actions">
                                                <Link to="/start">
                                                    {t('componentHeaderLinkLogin')}
                                                </Link>
                                            </div>
                                        )}
                                        {!isEditingRelease && isAuthenticated ? (
                                            <HamburgerMenu
                                                open={isHamburgerOpen}
                                                onToggle={this.toggleHamburgerMenu}
                                                urls={urls}
                                                actions={{ logout: this.onLogOut }}
                                                items={responsivePrimaryItems}
                                                artistIsEnhanced={artistIsEnhanced}
                                            />
                                        ) : null}

                                    </div>
                                )}
                        </div>
                    </div>
                    {responsiveSecondaryItems && !isMaintenanceMode && displayMenu && isUserLoaded ? (
                        <nav
                            className={classnames(
                                'subnav', {
                                    'subnav-visible': isSubNavVisible && canHaveSubNav && !isShowingModal,
                                    'subnav-top': isAtTopOfPage,
                                }
                            )}
                        >
                            <ul>
                                {responsiveSecondaryItems.map(({ label, to, hrefKey }) => {
                                    const target = (typeof to !== 'undefined') ? to : urls[hrefKey]
                                    return (
                                        <li key={label.replace(' ', '_').toLowerCase()}>
                                            <NavLink
                                                to={target}
                                                activeclassname="active"
                                            >
                                                {t(label)}
                                            </NavLink>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    ) : null}

                    { isAuthenticated && canHaveMessageBanners
                        ? <MessageBanners />
                        : null }
                </header>

                <Overlay enabled={isHamburgerOpen} click={this.toggleHamburgerMenu} />

            </>
        )
    }
}
