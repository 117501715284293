import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

export default class Checkbox extends Component {
    static propTypes = {
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        children: PropTypes.node,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        controlled: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
        this.state = {
            isFocused: false,
        }
    }

    onFocus = () => {
        this.setState({ isFocused: true })
    }

    onBlur = () => {
        this.setState({ isFocused: false })
    }

    getValue() {
        return this.inputRef.current.checked
    }

    setValue(checked) {
        this.inputRef.current.checked = checked
    }

    render() {
        const {
            label,
            children,
            checked,
            disabled,
            onChange,
            controlled,
        } = this.props

        const { isFocused } = this.state

        const {
            onFocus,
            onBlur,
        } = this

        const className = classnames(
            'c-checkbox',
            {
                disabled: !!disabled,
                focused: !!isFocused,
            }
        )

        const labelAttrs = { className }
        const inputAttrs = {
            ref: this.inputRef,
            type: 'checkbox',
            disabled,
            onChange: (evt) => {
                onChange(evt, this.getValue())
            },
            onFocus,
            onBlur,
        }

        if (controlled) {
            inputAttrs.checked = checked
        } else {
            labelAttrs.onFocus = onFocus
            labelAttrs.onBlur = onBlur
            inputAttrs.defaultChecked = checked
        }

        return (
            <label className={className} {...labelAttrs}>
                <span className="c-radio-input-wrapper">
                    <input {...inputAttrs} />
                    <span className="c-radio-input" />

                    {React.isValidElement(label) ? (
                        React.cloneElement(label, {
                            ...label.props,
                            className: classnames(
                                (label.props || {}).className,
                                'c-checkbox-label'
                            ),
                        })
                    ) : (
                        <span
                            className="c-checkbox-label"
                        >
                            {label}
                        </span>
                    )}

                </span>

                {children}
            </label>
        )
    }
}
