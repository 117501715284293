import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import t, { tmarkdown } from 'utilities/translate'
import { money } from 'utilities/number'
import Alert from 'components/Alert/Alert'
import Spinner from 'components/Spinner/Spinner'

import FontAwesomeIcon from 'components/FontAwesomeIcon'
import ErrorOverlayMessage from 'components/ErrorOverlayMessage/ErrorOverlayMessage'
import { SalesReportOverviewContainerProps } from 'containers/SalesReportOverview/SalesReportOverview'

class SalesReportOverview extends Component<SalesReportOverviewContainerProps> {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,

        data: PropTypes.array,
        selectedYear: PropTypes.number.isRequired,

        getData: PropTypes.func.isRequired,
        changeYear: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        failedFetchingOverview: PropTypes.bool.isRequired,
        someOverviewDataExists: PropTypes.bool.isRequired,
        calendarDataSummary: PropTypes.object.isRequired,
    }

    componentDidMount() {
        this.props.getData()
    }

    reportDetailClickHandler = (monthIndex: number) => {
        this.props.history.push(`/balance/reports/detailed/${this.props.selectedYear}/${monthIndex}`)
    }

    renderYearNavigator(showMobile: boolean, showDesktop: boolean) {
        const {
            selectedYear,
            failedFetchingOverview,
            someOverviewDataExists,
            calendarDataSummary,
            changeYear,
        } = this.props

        const calendarHasYear = (y: number) => (y >= calendarDataSummary.years.min)
                && (y <= calendarDataSummary.years.max)
        const prevYr = selectedYear - 1
        const nextYr = selectedYear + 1

        const disabled = (failedFetchingOverview || !someOverviewDataExists)

        const getIconClasses = (yr: number) => classnames('c-sro-calendar-nav-icon', {
            'c-sro-calendar-nav-icon-disabled': disabled || !calendarHasYear(yr),
        })

        const navWrapperClasses = classnames('c-sro-calendar-nav', {
            'c-sro-calendar-nav-mobile': showMobile,
            'c-sro-calendar-nav-desktop': showDesktop,
        })

        const goToYear = (yr: number) => !disabled && calendarHasYear(yr) && changeYear(yr)

        return (
            <div className={navWrapperClasses}>
                <a onClick={() => goToYear(prevYr)}>
                    <FontAwesomeIcon
                        className={getIconClasses(prevYr)}
                        icon="faCaretSquareLeft"
                        fixedWidth
                    />
                </a>
                <span className="c-sro-calendar-nav-label">{selectedYear}</span>
                <a onClick={() => goToYear(nextYr)}>
                    <FontAwesomeIcon
                        className={getIconClasses(nextYr)}
                        icon="faCaretSquareRight"
                        fixedWidth
                    />
                </a>
            </div>
        )
    }

    renderMonthSummaries() {
        const { data } = this.props

        return [...new Array(12)].map((_, index) => {
            const overviewMonthData = data.length > 0
                ? data[0].months.find(m => m.monthIndex === index)
                : null

            const boxClasses = ['c-sro-calendar-boxes-month']

            if (!overviewMonthData) {
                boxClasses.push('c-sro-calendar-boxes-month-nodata')
            }

            return (
                <div
                    key={`month_${index}`}
                    className={boxClasses.join(' ')}
                    onClick={() => (overviewMonthData ? this.reportDetailClickHandler(index) : null)}
                >
                    <label className="c-sro-calendar-boxes-month-label">
                        {t(`globalMonth${index + 1}`)}
                    </label>

                    <span className="c-sro-calendar-boxes-month-cont">
                        {overviewMonthData ? money(overviewMonthData.revenue) : '-'}
                    </span>
                </div>
            )
        })
    }

    render() {
        const {
            isLoading,
            failedFetchingOverview,
            someOverviewDataExists,
        } = this.props

        const yearNavigator = (
            (showMobile: boolean, showDesktop: boolean) => this.renderYearNavigator(showMobile, showDesktop)
        )

        return (
            <div className="c-sro">
                <Alert type="info">
                    <div dangerouslySetInnerHTML={{ __html: tmarkdown('viewSalesAlertText') }} />
                </Alert>

                {isLoading ? <Spinner>{t('globalLoading')}</Spinner> : (
                    <div>
                        { someOverviewDataExists && !failedFetchingOverview && (
                            <div className="c-sro-title">
                                <h2>{t('componentSalesReportOverviewChooseDetail')}&nbsp;
                                    <Link to="/balance/reports/detailed">
                                        {t('componentSalesReportLinkViewAllSales')}
                                    </Link>
                                </h2>
                            </div>
                        )}

                        <div className="c-sro-calendar">

                            { yearNavigator(true, false) }

                            <div className="c-sro-calendar-boxes">
                                {this.renderMonthSummaries()}

                                <ErrorOverlayMessage
                                    message={
                                        failedFetchingOverview
                                            ? t('componentSalesReportOverviewDataFail')
                                            : t('componentSalesReportOverviewDataEmpty')
                                    }
                                    enabled={failedFetchingOverview || !someOverviewDataExists}
                                />
                            </div>

                            { yearNavigator(true, true) }

                        </div>

                    </div>
                )}

            </div>
        )
    }
}

export default SalesReportOverview
