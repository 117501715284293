import { FETCH_USERS_SUCCESS, UPDATE_USER_SUCCESS } from 'constants/UserActionTypes'
import { FETCH_LOCALES_SUCCESS } from 'constants/LocaleActionTypes'

import { getFullLanguage } from 'utilities/translate'

const initialState = {
    home: '/',
    terms: 'https://spinnup.com/terms-and-conditions/',
    privacy: 'https://spinnup.com/privacy-policy/',
    facebook: 'https://www.facebook.com/spinnupcom/',
    instagram: 'https://www.instagram.com/spinnup/',
    spotify: 'https://open.spotify.com/user/spinnup.com?si=lev5GqzCRH6dU--QMHEu8w',
    twitter: 'https://twitter.com/SpinnupCom',
    youtube: 'https://www.youtube.com/c/Spinnup',
    help: 'https://support.spinnup.com/hc',
    publicWebsiteHome: 'https://spinnup.com/',
    publicWebsiteDistribution: 'https://spinnup.com/services/',
    publicWebsitePrices: 'https://spinnup.com/pricing/',
    publicWebsiteArtists: 'https://spinnup.com/artists/',
    publicWebsiteBlog: 'https://spinnup.com/blog/',
    publicWebsiteFaq: 'https://spinnup.com/top-faqs/',
    publicWebsitePartners: 'https://spinnup.com/offers/',
    publicWebsiteGettingStarted: 'https://spinnup.com/getting-started/',
    typeFormRenewalConfirmationSurvey: 'https://spinnup.typeform.com/to/KyowEk',
    typeFormOrderConfirmationSurvey: 'https://spinnup.typeform.com/to/l8GSDz',
    typeFormReleaseTakedownSurvey: 'https://spinnup.typeform.com/to/ri6nYY',
    typeFormReleaseCancellationSurvey: 'https://spinnup.typeform.com/to/bYPMpC',
}

export default function urls(state = initialState, action) {
    switch (action.type) {
        case FETCH_LOCALES_SUCCESS:
            const currentLocale = getFullLanguage().split('_')

            const matchedLocale = action.payload
                .find(l => l.language.id === currentLocale[0]
                    && l.country.id === currentLocale[1])
            if (matchedLocale) {
                return matchedLocale
                    .country.market.links
                    .reduce((obj, link) => ({
                        ...obj,
                        [link.key]: link.value,
                    }), { ...state })
            } else {
                return state
            }

        case FETCH_USERS_SUCCESS:
            return action.payload[0].locale.country.market.links.reduce((obj, link) => ({
                ...obj,
                [link.key]: link.value,
            }), { ...state })

        case UPDATE_USER_SUCCESS:
            return action.payload.locale.country.market.links.reduce((obj, link) => ({
                ...obj,
                [link.key]: link.value,
            }), { ...state })

        default:
            return state
    }
}
