import React, { useState } from 'react'
import classNames from 'classnames'
import Spinner from 'components/Spinner/Spinner'
import previewImageDesktop from '../../assets/images/profile-preview-desktop.jpg'
import previewImageMobile from '../../assets/images/profile-preview-mobile.jpg'

const OverviewProfilePreview = () => {
    const [isDesktopPreviewLoaded, setIsDesktopPreviewLoaded] = useState(false)
    const [isMobilePreviewLoaded, setIsMobilePreviewLoaded] = useState(false)

    const handleDesktopPreviewLoaded = () => {
        setIsDesktopPreviewLoaded(true)
    }

    const handleMobilePreviewLoaded = () => {
        setIsMobilePreviewLoaded(true)
    }

    return (
        <div className="c-overview-profile-preview">
            <div className="c-overview-profile-preview-wrapper">
                <div
                    className={classNames(
                        'c-overview-profile-preview-desktop', ({
                            loaded: isDesktopPreviewLoaded,
                        })
                    )}
                >
                    <img
                        className={classNames(
                            'c-overview-profile-preview-image', ({
                                loaded: isDesktopPreviewLoaded,
                            })
                        )}
                        src={previewImageDesktop}
                        alt="Profile desktop preview"
                        onLoad={handleDesktopPreviewLoaded}
                    />
                    {!isDesktopPreviewLoaded && (
                        <div className="c-overview-profile-preview-spinner-wrapper">
                            <Spinner size="medium" />
                        </div>
                    )}
                </div>
                <div
                    className={classNames(
                        'c-overview-profile-preview-mobile', ({
                            loaded: isMobilePreviewLoaded,
                        })
                    )}
                >
                    <img
                        className={classNames(
                            'c-overview-profile-preview-image', ({
                                loaded: isMobilePreviewLoaded,
                            })
                        )}
                        src={previewImageMobile}
                        alt="Profile mobile preview"
                        onLoad={handleMobilePreviewLoaded}
                    />
                    {!isMobilePreviewLoaded && (
                        <div className="c-overview-profile-preview-spinner-wrapper">
                            <Spinner size="small" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OverviewProfilePreview
