// FETCH

export const FETCH_PROFILE_REQUEST_LEGACY = 'FETCH_PROFILE_REQUEST_LEGACY'
export const FETCH_PROFILE_SUCCESS_LEGACY = 'FETCH_PROFILE_SUCCESS_LEGACY'
export const FETCH_PROFILE_FAILURE_LEGACY = 'FETCH_PROFILE_FAILURE_LEGACY'

export const FETCH_DRAFT_PROFILE_REQUEST = 'FETCH_DRAFT_PROFILE_REQUEST'
export const FETCH_DRAFT_PROFILE_SUCCESS = 'FETCH_DRAFT_PROFILE_SUCCESS'
export const FETCH_DRAFT_PROFILE_FAILURE = 'FETCH_DRAFT_PROFILE_FAILURE'

// UPDATE

export const UPDATE_PROFILE_REQUEST_LEGACY = 'UPDATE_PROFILE_REQUEST_LEGACY'
export const UPDATE_PROFILE_SUCCESS_LEGACY = 'UPDATE_PROFILE_SUCCESS_LEGACY'
export const UPDATE_PROFILE_FAILURE_LEGACY = 'UPDATE_PROFILE_FAILURE_LEGACY'

export const UPDATE_DRAFT_PROFILE = 'UPDATE_DRAFT_PROFILE'
export const UPDATE_DRAFT_PROFILE_REQUEST = 'UPDATE_DRAFT_PROFILE_REQUEST'
export const UPDATE_DRAFT_PROFILE_SUCCESS = 'UPDATE_DRAFT_PROFILE_SUCCESS'
export const UPDATE_DRAFT_PROFILE_FAILURE = 'UPDATE_DRAFT_PROFILE_FAILURE'

export const PUBLISH_DRAFT_PROFILE = 'PUBLISH_DRAFT_PROFILE'
export const PUBLISH_DRAFT_PROFILE_REQUEST = 'PUBLISH_DRAFT_PROFILE_REQUEST'
export const PUBLISH_DRAFT_PROFILE_SUCCESS = 'PUBLISH_DRAFT_PROFILE_SUCCESS'
export const PUBLISH_DRAFT_PROFILE_FAILURE = 'PUBLISH_DRAFT_PROFILE_FAILURE'

// UPLOAD

export const UPLOAD_PROFILE_REQUEST = 'UPLOAD_PROFILE_REQUEST'
export const UPLOAD_PROFILE_SUCCESS = 'UPLOAD_PROFILE_SUCCESS'
export const UPLOAD_PROFILE_FAILURE = 'UPLOAD_PROFILE_FAILURE'
export const UPLOAD_PROFILE_PROGRESS = 'UPLOAD_PROFILE_PROGRESS'
export const UPLOAD_PROFILE_VALIDATION_ERROR = 'UPLOAD_PROFILE_VALIDATION_ERROR'

// UPLOAD LOGO

export const UPLOAD_LOGO_REQUEST = 'UPLOAD_LOGO_REQUEST'
export const UPLOAD_LOGO_SUCCESS = 'UPLOAD_LOGO_SUCCESS'
export const UPLOAD_LOGO_FAILURE = 'UPLOAD_LOGO_FAILURE'
export const UPLOAD_LOGO_PROGRESS = 'UPLOAD_LOGO_PROGRESS'
export const UPLOAD_LOGO_VALIDATION_ERROR = 'UPLOAD_LOGO_VALIDATION_ERROR'

// REMOVE LOGO

export const REMOVE_LOGO_REQUEST = 'REMOVE_LOGO_REQUEST'
export const REMOVE_LOGO_SUCCESS = 'REMOVE_LOGO_SUCCESS'
export const REMOVE_LOGO_FAILURE = 'REMOVE_LOGO_FAILURE'

// UPLOAD HERO IMAGE

export const UPLOAD_HERO_REQUEST = 'UPLOAD_HERO_REQUEST'
export const UPLOAD_HERO_SUCCESS = 'UPLOAD_HERO_SUCCESS'
export const UPLOAD_HERO_FAILURE = 'UPLOAD_HERO_FAILURE'
export const UPLOAD_HERO_PROGRESS = 'UPLOAD_HERO_PROGRESS'
export const UPLOAD_HERO_VALIDATION_ERROR = 'UPLOAD_HERO_VALIDATION_ERROR'

// REMOVE HERO IMAGE

export const REMOVE_HERO_REQUEST = 'REMOVE_HERO_REQUEST'
export const REMOVE_HERO_SUCCESS = 'REMOVE_HERO_SUCCESS'
export const REMOVE_HERO_FAILURE = 'REMOVE_HERO_FAILURE'

// UPLOAD GALLERY IMAGE

export const UPLOAD_GALLERY_REQUEST = 'UPLOAD_GALLERY_REQUEST'
export const UPLOAD_GALLERY_SUCCESS = 'UPLOAD_GALLERY_SUCCESS'
export const UPLOAD_GALLERY_FAILURE = 'UPLOAD_GALLERY_FAILURE'
export const UPLOAD_GALLERY_PROGRESS = 'UPLOAD_GALLERY_PROGRESS'
export const UPLOAD_GALLERY_VALIDATION_ERROR = 'UPLOAD_GALLERY_VALIDATION_ERROR'

// REMOVE GALLERY IMAGE

export const REMOVE_GALLERY_REQUEST = 'REMOVE_GALLERY_REQUEST'
export const REMOVE_GALLERY_SUCCESS = 'REMOVE_GALLERY_SUCCESS'
export const REMOVE_GALLERY_FAILURE = 'REMOVE_GALLERY_FAILURE'

// REORDER GALLERY IMAGES

export const REORDER_GALLERY_IMAGES = 'REORDER_GALLERY_IMAGES'

// UPDATE GALLERY ORDER

export const UPDATE_GALLERY_ORDER_REQUEST = 'UPDATE_GALLERY_ORDER_REQUEST'
export const UPDATE_GALLERY_ORDER_SUCCESS = 'UPDATE_GALLERY_ORDER_SUCCESS'
export const UPDATE_GALLERY_ORDER_FAILURE = 'UPDATE_GALLERY_ORDER_FAILURE'

// PUBLIC STATUSES

export const FETCH_STATUSES_REQUEST = 'FETCH_PUBLIC_STATUSES_REQUEST'
export const FETCH_STATUSES_SUCCESS = 'FETCH_PUBLIC_STATUSES_SUCCESS'
export const FETCH_STATUSES_FAILURE = 'FETCH_PUBLIC_STATUSES_FAILURE'

// PUBLIC ALBUMS

export const FETCH_ALBUMS_REQUEST = 'FETCH_PUBLIC_ALBUMS_REQUEST'
export const FETCH_ALBUMS_SUCCESS = 'FETCH_PUBLIC_ALBUMS_SUCCESS'
export const FETCH_ALBUMS_FAILURE = 'FETCH_PUBLIC_ALBUMS_FAILURE'

// ADD TOUR

export const UPLOAD_TOUR_REQUEST = 'UPLOAD_TOUR_REQUEST'
export const UPLOAD_TOUR_SUCCESS = 'UPLOAD_TOUR_SUCCESS'
export const UPLOAD_TOUR_FAILURE = 'UPLOAD_TOUR_FAILURE'

// REMOVE TOUR

export const REMOVE_TOUR_REQUEST = 'REMOVE_TOUR_REQUEST'
export const REMOVE_TOUR_SUCCESS = 'REMOVE_TOUR_SUCCESS'
export const REMOVE_TOUR_FAILURE = 'REMOVE_TOUR_FAILURE'

// PUT SURVEY VIEWED

export const SURVEY_VIEWED_REQUEST = 'SURVEY_VIEWED_REQUEST'
export const SURVEY_VIEWED_SUCCESS = 'SURVEY_VIEWED_SUCCESS'
export const SURVEY_VIEWED_FAILURE = 'SURVEY_VIEWED_FAILURE'
