import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import styles from './icon.module.scss'
import * as svgs from './Svgs'

/**
 * Icon
 *
 * Styleguide: components.icon
 */
export default class Icon extends Component {
    static propTypes = {
        className: PropTypes.string,
        shape: PropTypes.oneOf(['square', 'circle']),
        size: PropTypes.number,
        shrink: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        rotate: PropTypes.number,
        fill: PropTypes.string,
        background: PropTypes.string,
        src: PropTypes.string,
        id: PropTypes.string,
        onClick: PropTypes.func,
    };

    constructor(props) {
        super(props)

        this.svg = this.getSvg()
    }

    getSvg() {
        if (!svgs[this.props.id]) {
            throw new Error(`Unable to find svg icon with id ${this.props.id}`)
        }

        const viewbox = this.getViewBox()
        return (
            <svg viewBox={viewbox} style={this.getIconStyle()}>
                <use href={`${svgs[this.props.id]}#${this.props.id}`} />
            </svg>
        )
    }

    getWidth() {
        return this.props.size || this.props.width
    }

    getHeight() {
        return this.props.size || this.props.height
    }

    getViewBox() {
        const width = this.getWidth()
        const height = this.getHeight()
        if (width && height) {
            return `0 0 ${width} ${height}`
        } else {
            return null
        }
    }

    getFillColor() {
        const { shape, background, fill } = this.props

        return (shape && !background) ? '#fff' : fill
    }

    getBackgroundColor() {
        const { background, fill } = this.props

        return background || fill
    }

    getIconStyle() {
        const { rotate, shape, shrink } = this.props
        const transform = rotate && `rotate(${rotate}deg)`

        const style = {
            fill: this.getFillColor(),
        }

        return !!shape && {
            ...style,
            maxHeight: `${shrink}%`,
            maxWidth: `${shrink}%`,
            transform: `${transform} translate(50%, -35%)`,
        } || {
            ...style,
            width: `${this.getWidth()}px`,
            height: `${this.getHeight()}px`,
            transform,
        }
    }

    getShapeStyle() {
        const { shape } = this.props

        return !shape && {} || {
            backgroundColor: this.getBackgroundColor(),
            width: this.getWidth(),
            height: this.getHeight(),
        }
    }

    render() {
        const {
            shape, src, id, onClick,
        } = this.props
        const className = [
            styles.root,
            onClick ? styles.clickable : '',
            styles[shape] || '',
            this.props.className,
        ].join(' ')

        return (
            <i className={className} style={this.getShapeStyle()} onClick={onClick}>
                {id && this.getSvg()}

                {src
                    && <img src={src} alt="" />}
            </i>
        )
    }
}
