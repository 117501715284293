import { connect } from 'react-redux'

import { completeDoiProcess } from 'actions/CrmActions'
import { isUpdating, hasFailedToUpdate } from 'selectors/crmPreferences'

import CrmCompleteDoubleOptIn from 'components/CrmCompleteDoubleOptIn/CrmCompleteDoubleOptIn'

const mapStateToProps = state => ({
    isUpdating: isUpdating(state),
    failedToUpdate: hasFailedToUpdate(state),
})

const mapDispatchToProps = (dispatch, ownProps) => {
    const { doiSecret } = ownProps

    return {
        completeDoiProcess: () => dispatch(completeDoiProcess(doiSecret)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmCompleteDoubleOptIn)
