import * as types from 'constants/ProfileActionTypes'

const initialState = {
    isAddingNewTourDate: false,
    toursIdsInDeletingState: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.UPLOAD_TOUR_REQUEST:
            return {
                ...state,
                isAddingNewTourDate: true,
            }
        case types.UPLOAD_TOUR_FAILURE:
        case types.UPLOAD_TOUR_SUCCESS:
            return {
                ...state,
                isAddingNewTourDate: false,
            }
        case types.REMOVE_TOUR_REQUEST:
            return {
                ...state,
                toursIdsInDeletingState: [...state.toursIdsInDeletingState, action.meta.tourId],
            }
        case types.REMOVE_TOUR_FAILURE:
        case types.REMOVE_TOUR_SUCCESS:
            const filteredTourDatesIds = state.toursIdsInDeletingState.filter(id => id !== action.meta.tourId)
            return {
                ...state,
                toursIdsInDeletingState: filteredTourDatesIds,
            }
        default:
            return state
    }
}
