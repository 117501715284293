import { Record, OrderedMap } from 'immutable'
import * as types from 'constants/ScoutActionTypes'
import { formatErrors } from 'utilities/error'

export const Scout = new Record({
    id: undefined,
    name: undefined,
    location: undefined,
    description: undefined,
    excerpt: undefined,
    createdAt: undefined,
    isFeatured: false,
    genres: [],
    links: {},
    files: {},
    tandcsUrl: '',
    privacyUrl: '',
    isCompetitionContact: false,
})

const initialState = {
    isFetching: false,
    isPushing: false,
    scouts: new OrderedMap(),
    errors: {},
}

function transform(scout) {
    return new Scout({
        ...scout,
        links: scout.links.reduce((obj, link) => ({
            ...obj,
            [link.key]: link.value,
        }), {}),
        files: scout.files.reduce((obj, file) => ({
            ...obj,
            [file.type]: file.url,
        }), {}),
    })
}

export default function scouts(state = initialState, action) {
    switch (action.type) {
        // FETCH ALL

        case types.FETCH_SCOUTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_SCOUTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                scouts: action.payload.reduce((map, scout) => map.set(scout.id, transform(scout)), state.scouts),
            }

        case types.FETCH_SCOUTS_FAILURE:
            return {
                ...state,
                isFetching: false,
            }

            // FETCH ONE

        case types.FETCH_SCOUT_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_SCOUT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                scouts: state.scouts.set(action.payload.id, transform(action.payload)),
            }

        case types.FETCH_SCOUT_FAILURE:
            return {
                ...state,
                isFetching: false,
            }

            // PUSH

        case types.PUSH_SCOUT_REQUEST:
            return {
                ...state,
                isPushing: true,
                errors: {},
            }

        case types.PUSH_SCOUT_SUCCESS:
            return {
                ...state,
                isPushing: false,
            }

        case types.PUSH_SCOUT_FAILURE:
            return {
                ...state,
                isPushing: false,
                errors: formatErrors(action.error.errors),
            }

            // DEFAULT

        default:
            return state
    }
}
