import React from 'react'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import './_mfa.scss'
// eslint-disable-next-line import/no-named-as-default
import MfaVerificationCodeViewBase from 'views/Mfa/MfaVerificationCodeViewBase'

const MfaActivate = () => {
    const { t } = useLocaleContext()
    const componentTitle = (<h3>{t('viewMfaEnrolmentTitle')}</h3>)

    return (
        <MfaVerificationCodeViewBase
            componentTitle={componentTitle}
        />
    )
}

MfaActivate.propTypes = {
}

export default MfaActivate
