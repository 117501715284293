import React, {
    useImperativeHandle,
    useRef,
    forwardRef,
    useState,
} from 'react'
import * as PropTypes from 'prop-types'
import Recaptcha from 'react-recaptcha'
import { getLanguage } from 'utilities/translate'

const googleRecaptchaSiteKey = window.env.GOOGLE_RECAPTCHA_SITE_KEY

const InvisibleRecaptcha = forwardRef((props, ref) => {
    const { onVerify } = props
    const recaptchaRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [lastResponse, setLastResponse] = useState(null)

    useImperativeHandle(ref, () => ({
        reset: () => {
            recaptchaRef.current.reset()
        },
        execute: () => {
            recaptchaRef.current.execute()
        },
        isReady,
        lastResponse,
    }))

    const verify = (response) => {
        if (response) {
            setLastResponse(response)
            onVerify(response)
        }
    }
    const onLoad = () => {
        setIsReady(true)
    }

    return (
        <Recaptcha
            ref={recaptchaRef}
            sitekey={googleRecaptchaSiteKey}
            verifyCallback={verify}
            onloadCallback={onLoad}
            render="explicit"
            hl={getLanguage()}
            size="invisible"
            badge="inline"
        />
    )
})

InvisibleRecaptcha.propTypes = {
    onVerify: PropTypes.func.isRequired,
}

export default InvisibleRecaptcha
