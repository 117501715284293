import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import t from 'utilities/translate'
import Select from '../Select/Select'

export default class TrendsSocialFilter extends Component {
    static propTypes = {
        filterTrends: PropTypes.func.isRequired,
        filterOptions: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        failedFetching: PropTypes.bool.isRequired,
    };

    handleDaysChange = (days) => {
        this.props.filterTrends(['fans', 'plays'], days)
    }

    render() {
        const {
            filterOptions,
            isLoading,
            failedFetching,
        } = this.props

        const days = [
            { label: t('trendsFilter7Days'), value: 'week' },
            { label: t('trendsFilter30Days'), value: 'month' },
            { label: t('trendsFilterYear'), value: 'year' },
        ]

        return (
            <div>
                <Select
                    disabled={isLoading || failedFetching}
                    value={filterOptions.days}
                    ref={(daySelect) => { this.daySelect = daySelect }}
                    options={days}
                    sortedBy="disabled"
                    onChange={this.handleDaysChange}
                />
            </div>
        )
    }
}
