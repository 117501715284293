import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

//
// Containers
//
import Pills from 'containers/Navs/Pills'
import PartnersWidget from 'containers/PartnersWidget/PartnersWidget'

//
// Utilities
//
import t from 'utilities/translate'
import useScrollToTopEffect from 'hooks/useScrollToTopEffect'

import TrendsTracksContainer from 'containers/TrendsTracks/TrendsTracks'
import SocialContainer from 'containers/Social/Social'
import PropTypes from 'prop-types'

const getTrendsNavigation = () => [
    {
        href: '/trends/tracks',
        label: `${t('trendsNavTracks')}`,
    },
    {
        href: '/trends/social',
        label: `${t('trendsNavFanbase')}`,
    },
]

const Trends = ({ artistIsEnhanced }) => {
    const pills = getTrendsNavigation().map(pill => ({
        ...pill,
        value: pill.href,
    }))

    useScrollToTopEffect()

    const url = '/trends'

    if (!artistIsEnhanced) {
        return (<Redirect to="/balance" />)
    }

    return (
        <div className="c-trends">
            <header>
                <h1>{t('trendsTitle')}</h1>
                <Pills items={pills} />
            </header>

            <Switch>
                <Redirect exact from={`${url}`} to={`${url}/tracks`} />

                <Route path={`${url}/tracks`} render={() => <TrendsTracksContainer />} />
                <Route path={`${url}/social`} render={() => <SocialContainer />} />
            </Switch>

            <PartnersWidget />
        </div>
    )
}

Trends.propTypes = {
    artistIsEnhanced: PropTypes.bool.isRequired,
}

export default Trends
