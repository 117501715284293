import { connect } from 'react-redux'
import CountrySelect from 'components/CountrySelect/CountrySelect'
import t from 'utilities/translate'
import { fetchCountries } from '../../actions/CountryActions'
import { getCountrySelectOptions } from '../../selectors/countries'

const mapStateToProps = state => ({
    countries: getCountrySelectOptions(t('formEditProfileNoCountry'))(state),
})

const mapDispatchToProps = {
    fetchCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect)
