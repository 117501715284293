import * as types from 'constants/LocaleActionTypes'
import { AnyAction } from 'redux'
import { Locale } from 'models/locale'
import { ApiLocale } from 'models/api/locale'

type LocaleState = {
    isFetching: boolean
    locales: Locale[]
    error?: any
}

const initialState: LocaleState = {
    isFetching: false,
    locales: [],
}

export default function locales(state = initialState, action: AnyAction): LocaleState {
    switch (action.type) {
        // FETCH

        case types.FETCH_LOCALES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_LOCALES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                locales: action.payload.map((locale: ApiLocale) => new Locale(locale)),
            }

        case types.FETCH_LOCALES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            }

            // DEFAULT

        default:
            return state
    }
}
