import 'rxjs'
import { decamelizeKeys } from 'humps'

const API_URI = window.env.SPINNUP_API

// put is here only for a semantic purpose, indeed we are not doing a proper PUT
// but isntead we are always doing a PATCH, execpt we are providing the WHOLE album
// this is due to a FUGA limitation and the way we are handling the saves (real-time saves)
export const putAlbum = (album, credentials, ajax) => {
    const setting = {
        method: 'PATCH',
        url: `${API_URI}dsp/product/${album.id}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${credentials.accessToken}`,
        },
        body: JSON.stringify(decamelizeKeys({
            releaseDate: album.releaseDate,
            genreId: album.genreId,
            languageId: album.languageId,
            tracks: album.tracks.map(track => decamelizeKeys({
                id: track.id,
                title: track.title,
                recordingVersionId: track.recordingVersionId,
                explicitLyrics: track.explicitLyrics,
                artists: track.artists.map(artist => ({
                    id: artist.id,
                    artistType: artist.artistType,
                    artistName: artist.artistName,
                    identifiers: artist.identifiers || [],
                })),
                audioLocale: (track.audioLocale || {}).id,
                lyrics: track.lyrics,
            })),
            title: album.title,
            countries: album.countries,
            artists: album.artists.map((artist, index) => {
                artist.identifiers = artist.identifiers || []
                if (album.artistTranslations !== undefined) {
                    artist.translations = (album.artistTranslations && album.artistTranslations[index])
                        ? album.artistTranslations[index] : []
                }
                return artist
            }),
            originalReleaseDate: album.originalReleaseDate,
            translations: album.translations,
        })),
    }
    return ajax(setting)
}

export const patchAlbum = ({ album, field }, credentials, ajax) => {
    const setting = {
        method: 'PATCH',
        url: `${API_URI}dsp/product/${album.id}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${credentials.accessToken}`,
        },
        body: JSON.stringify(decamelizeKeys({
            [field]: album[field],
        })),
    }
    return ajax(setting)
}
