import React from 'react'
import * as PropTypes from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

const Footer = (props) => {
    const {
        icon,
        message,
    } = props

    return (
        <footer>
            <FontAwesomeIcon icon={icon} className="c-release-icon" />
            <span className="c-release-status-message">
                {message}
            </span>
        </footer>
    )
}

Footer.propTypes = {
    icon: PropTypes.string,
    message: PropTypes.string,
}

export default Footer
