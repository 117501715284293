import RecordContract from 'models/recordContract'
import Payment from 'models/payment'
import { ApiRelease, ApiReleaseBase } from 'models/api/release'
import { ApiReleaseType } from 'models/api/release-type'
import Album from 'models/album'

export const releaseTypeIds = {
    releaseSingleAnnual: 1,
    releaseEpAnnual: 2,
    releaseAlbumAnnual: 3,
}

export interface IRelease extends ApiReleaseBase<RecordContract, Album> {
}

export default class Release implements IRelease {
    id: number = -1

    providerId: string = ''

    releaseTypeId: number = -1

    releaseType?: ApiReleaseType = undefined

    createdAt: string = ''

    expiresAt: string | null = null

    cancelledAt: string | null = null

    takedownAt: string | null = null

    onHoldAt: string | null = null

    socialOptedinAt: string | null = null

    youtubeOptedinAt: string | null = null

    isOptingIn: boolean | null = null

    isProcessing: boolean | null = null

    isRemoving: boolean | null = null

    tracks: any

    album?: Album // This is only ever added in selectors/releases.ts

    artworkUrl: string | null = null

    linkfire: any

    spotifyUri: string | null = null

    errors: any

    price: string = ''

    releasePrice: string = ''

    currency: string = ''

    voucher: any

    payments: Payment[] = []

    recordContract?: RecordContract

    constructor(apiRelease?: Partial<ApiRelease>) {
        const recordContract = apiRelease?.recordContract && typeof apiRelease.recordContract.id !== 'undefined'
            ? new RecordContract({
                id: apiRelease.recordContract.id,
                name: apiRelease.recordContract.recordLabel.name,
                contactEmail: apiRelease.recordContract.recordLabel.contactEmail,
                royaltyPercentage: apiRelease.recordContract.royaltyPercentage,
            }) : undefined

        const payments = apiRelease?.payments ? apiRelease.payments.map((p: any) => new Payment(p)) : null

        Object.assign(this as Release, {
            ...apiRelease, recordContract, payments,
        })
    }

    clone(props?: Partial<Release>) {
        const a = new Release()
        Object.assign(a, this as Release, props ?? {})
        return a
    }
}
