import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import EmbedJS from 'embed-js'
import marked from 'marked'

import PushFormContainer from 'containers/PushForm/PushForm'

import Frame from 'components/Frame/Frame'
import ClipoutText from 'components/Frame/ClipoutText'
import Spinner from 'components/Spinner/Spinner'

export default class Competition extends Component {
    static propTypes = {
        scout: PropTypes.object,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props)
        this.descriptionRef = React.createRef()
        this.state = {
            isEmbedded: false,
        }
    }

    componentDidMount() {
        if (!this.state.isEmbedded && this.descriptionRef.current) {
            this.setState({ isEmbedded: true })

            marked.setOptions({ breaks: true })

            const node = new EmbedJS({
                input: this.descriptionRef.current,

                marked: true,
                inlineEmbed: 'all',
                inlineText: false,
                videoHeight: '360px',
                videoWidth: '100%',
                videoDetails: false,
                plugins: { marked },
            })

            node.render()
        }
    }

    renderDetails = () => {
        const { scout } = this.props

        return (
            <div>
                <Frame
                    borderImageId="dune-2"
                    contentTint="light"
                    className="c-competition-image"
                >
                    <img src={scout.files['profile-medium']} alt="" />
                </Frame>

                <section className="c-competition-details">
                    <header>
                        <ClipoutText
                            type="h1"
                            backgroundImageId="distortion-3"
                        >
                            {scout.name}
                        </ClipoutText>
                        <p>
                            {scout.location}
                        </p>
                    </header>

                    <div className="c-competition-description" ref={this.descriptionRef}>
                        {scout.description}
                    </div>

                    <PushFormContainer scout={scout} />
                </section>
            </div>
        )
    }

    render() {
        const { isLoading, scout } = this.props

        return (
            <div className="c-competition">
                {(isLoading || !scout)
                    ? <Spinner className="c-competition-spinner" />
                    : this.renderDetails()}
            </div>
        )
    }
}
