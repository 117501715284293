// FETCH ALL

export const FETCH_RELEASES_REQUEST = 'FETCH_RELEASES_REQUEST'
export const FETCH_RELEASES_SUCCESS = 'FETCH_RELEASES_SUCCESS'
export const FETCH_RELEASES_FAILURE = 'FETCH_RELEASES_FAILURE'

// FETCH ONE

export const FETCH_RELEASE_REQUEST = 'FETCH_RELEASE_REQUEST'
export const FETCH_RELEASE_SUCCESS = 'FETCH_RELEASE_SUCCESS'
export const FETCH_RELEASE_FAILURE = 'FETCH_RELEASE_FAILURE'

// CREATE

export const CREATE_RELEASE_REQUEST = 'CREATE_RELEASE_REQUEST'
export const CREATE_RELEASE_SUCCESS = 'CREATE_RELEASE_SUCCESS'
export const CREATE_RELEASE_FAILURE = 'CREATE_RELEASE_FAILURE'

// UPDATE

export const UPDATE_RELEASE_REQUEST = 'UPDATE_RELEASE_REQUEST'
export const UPDATE_RELEASE_SUCCESS = 'UPDATE_RELEASE_SUCCESS'
export const UPDATE_RELEASE_FAILURE = 'UPDATE_RELEASE_FAILURE'

// REMOVE

export const REMOVE_RELEASE_REQUEST = 'REMOVE_RELEASE_REQUEST'
export const REMOVE_RELEASE_SUCCESS = 'REMOVE_RELEASE_SUCCESS'
export const REMOVE_RELEASE_FAILURE = 'REMOVE_RELEASE_FAILURE'

// ARCHIVE

export const ARCHIVE_RELEASE_REQUEST = 'ARCHIVE_RELEASE_REQUEST'
export const ARCHIVE_RELEASE_SUCCESS = 'ARCHIVE_RELEASE_SUCCESS'
export const ARCHIVE_RELEASE_FAILURE = 'ARCHIVE_RELEASE_FAILURE'

// DELIVER SOCIAL

export const DELIVER_SOCIAL_RELEASE_REQUEST = 'DELIVER_SOCIAL_RELEASE_REQUEST'
export const DELIVER_SOCIAL_RELEASE_SUCCESS = 'DELIVER_SOCIAL_RELEASE_SUCCESS'
export const DELIVER_SOCIAL_RELEASE_FAILURE = 'DELIVER_SOCIAL_RELEASE_FAILURE'

// CANCEL

export const CANCEL_RELEASE_REQUEST = 'CANCEL_RELEASE_REQUEST'
export const CANCEL_RELEASE_SUCCESS = 'CANCEL_RELEASE_SUCCESS'
export const CANCEL_RELEASE_FAILURE = 'CANCEL_RELEASE_FAILURE'

// TAKEDOWN

export const TAKEDOWN_RELEASE_REQUEST = 'TAKEDOWN_RELEASE_REQUEST'
export const TAKEDOWN_RELEASE_SUCCESS = 'TAKEDOWN_RELEASE_SUCCESS'
export const TAKEDOWN_RELEASE_FAILURE = 'TAKEDOWN_RELEASE_FAILURE'

// PUBLISH

export const PUBLISH_RELEASE_REQUEST = 'PUBLISH_RELEASE_REQUEST'
export const PUBLISH_RELEASE_SUCCESS = 'PUBLISH_RELEASE_SUCCESS'
export const PUBLISH_RELEASE_FAILURE = 'PUBLISH_RELEASE_FAILURE'

// SELECT

export const SELECT_RELEASE = 'SELECT_RELEASE'
