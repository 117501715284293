import { WindowEnv } from './react-app-env'

let spinnupApi = null
let spinnupApiId = null
let spinnupApiSecret = null
let sentryEnviromment = null
let googleRecaptchaSiteKey = null
let spinnupProfileUrl = null
let adyenOriginKey = null
let adyenEnvironment = null
let hyperwalletWidgetUrl = 'https://uat-api.paylution.com'
let isProduction = false
const adyenDropinLibraryUrl = 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.5.0/adyen.js'
const zendeskWebWidgetKey = '99c0f3a9-986e-46a5-93ae-8e31713af265'
const spinnupArtistSiteCmsEnabled = process.env.REACT_APP_SPINNUP_ARTIST_SITE_CMS_ENABLED
const coverArtMinSize = process.env.REACT_APP_COVER_ART_MIN_SIZE
const coverArtMaxSize = process.env.REACT_APP_COVER_ART_MAX_SIZE

switch (window.location.hostname) {
    case 'artist.spinnup.com':
        spinnupApi = 'https://api.spinnup.com/'
        spinnupApiId = '5'
        spinnupApiSecret = 'mLjyUqDf3Z6FAdx6tpA3JOc0fv1XWEDcYD5S9GdS'
        sentryEnviromment = 'production'
        googleRecaptchaSiteKey = process.env.REACT_APP_SPINNUP_RECAPTCHA_SITE_KEY
        // eslint-disable-next-line max-len
        adyenOriginKey = 'pub.v2.4114479290792995.aHR0cHM6Ly9hcnRpc3Quc3Bpbm51cC5jb20.sxHvPvdwr7pCGzGFnsF8w7yLEmVErIS-1pSgFv5Qdew'
        spinnupProfileUrl = 'https://site.spinnup.com/'
        adyenEnvironment = 'live'
        hyperwalletWidgetUrl = 'https://api.paylution.com'
        isProduction = true
        break

    case 'test-artist2.spinnup.com':
        spinnupApi = 'https://test-api2.spinnup.com/'
        spinnupApiId = '2'
        spinnupApiSecret = 'EzPrZgFDS6nIsqDcSrccNRAzJnGgWEqidyx8qj4P'
        sentryEnviromment = 'development'
        googleRecaptchaSiteKey = '6LdNW7oUAAAAAKl0XaE7YcWy9LRPIBQhW1ne53wj'
        // eslint-disable-next-line max-len
        adyenOriginKey = 'pub.v2.7514604686578430.aHR0cHM6Ly90ZXN0LWFydGlzdDIuc3Bpbm51cC5jb20.eeusnxkUPR0pE1z-89UUi6PLMEKZTQ_No0TVuQtqrcc'
        spinnupProfileUrl = 'https://test-site2.spinnup.com/'
        adyenEnvironment = 'test'
        break

    case 'test-artist3.spinnup.com':
        spinnupApi = 'https://test-api3.spinnup.com/'
        spinnupApiId = '2'
        spinnupApiSecret = 'EzPrZgFDS6nIsqDcSrccNRAzJnGgWEqidyx8qj4P'
        sentryEnviromment = 'development'
        googleRecaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        // eslint-disable-next-line max-len
        adyenOriginKey = 'pub.v2.7514604686578430.aHR0cHM6Ly90ZXN0LWFydGlzdDMuc3Bpbm51cC5jb20.L87puBoLVoVAN9FVJAgBg-AkhLs29iHLE5BL0_B5wkE'
        spinnupProfileUrl = 'https://test-site3.spinnup.com/'
        adyenEnvironment = 'test'
        break

    case 'test-artist4.spinnup.com':
        spinnupApi = 'https://test-api4.spinnup.com/'
        spinnupApiId = '2'
        spinnupApiSecret = 'EzPrZgFDS6nIsqDcSrccNRAzJnGgWEqidyx8qj4P'
        sentryEnviromment = 'development'
        googleRecaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        // eslint-disable-next-line max-len
        adyenOriginKey = 'pub.v2.7514604686578430.aHR0cHM6Ly90ZXN0LWFydGlzdDQuc3Bpbm51cC5jb20.ilv8KCyphJrOXu1TwGSWAOY-LH1-1zyjYAdBZDUj_pA'
        spinnupProfileUrl = 'https://test-site4.spinnup.com/'
        adyenEnvironment = 'test'
        break

    case 'test-artist5.spinnup.com':
        spinnupApi = 'https://test-api5.spinnup.com/'
        spinnupApiId = '2'
        spinnupApiSecret = 'EzPrZgFDS6nIsqDcSrccNRAzJnGgWEqidyx8qj4P'
        sentryEnviromment = 'development'
        googleRecaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        // eslint-disable-next-line max-len
        adyenOriginKey = 'pub.v2.7514604686578430.aHR0cHM6Ly90ZXN0LWFydGlzdDUuc3Bpbm51cC5jb20.tCOqQj-N2H5BTCgmwfvrHqw7KzHHzfWW5Vc2Pi-WgFI'
        spinnupProfileUrl = 'https://test-site5.spinnup.com/'
        adyenEnvironment = 'test'
        break

    case 'test-artist6.spinnup.com':
        spinnupApi = 'https://test-api6.spinnup.com/'
        spinnupApiId = '2'
        spinnupApiSecret = 'EzPrZgFDS6nIsqDcSrccNRAzJnGgWEqidyx8qj4P'
        sentryEnviromment = 'development'
        googleRecaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        // eslint-disable-next-line max-len
        adyenOriginKey = 'pub.v2.7514604686578430.aHR0cHM6Ly90ZXN0LWFydGlzdDYuc3Bpbm51cC5jb20.m4RzD--kuXNm4YyjWS4eKsmlFszgj7gzjK0qFqib_Bs'
        spinnupProfileUrl = 'https://test-site6.spinnup.com/'
        adyenEnvironment = 'test'
        break

    case 'test-artist.spinnup.com':
    default:
        spinnupApi = 'https://test-api.spinnup.com/'
        spinnupApiId = '2'
        spinnupApiSecret = 'EzPrZgFDS6nIsqDcSrccNRAzJnGgWEqidyx8qj4P'
        sentryEnviromment = 'development'
        googleRecaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        // eslint-disable-next-line max-len
        adyenOriginKey = 'pub.v2.7514604686578430.aHR0cHM6Ly90ZXN0LWFydGlzdC5zcGlubnVwLmNvbQ.rsTXp9WaAnJ5Ps3Zt-v_iFAUCVcicaHqmWE_pkcoFr0'
        spinnupProfileUrl = 'https://test-site.spinnup.com/'
        adyenEnvironment = 'test'
        break
}

const env: WindowEnv = {
    SPINNUP_API: spinnupApi,
    SPINNUP_API_ID: spinnupApiId,
    SPINNUP_API_SECRET: spinnupApiSecret,
    GOOGLE_RECAPTCHA_SITE_KEY: googleRecaptchaSiteKey,
    ZENDESK_WEBWIDGET_KEY: zendeskWebWidgetKey,
    SENTRY_URL: 'https://2dbc3e62d3224947b6623525bf629ae5@sentry.io/149035',
    SENTRY_CONF: {
        logger: 'javascript',
        environment: sentryEnviromment,
    },
    ADYEN: {
        ENVIRONMENT: adyenEnvironment,
        ORIGIN_KEY: adyenOriginKey,
        DROPIN_LIB_URL: adyenDropinLibraryUrl,
    },
    SPINNUP_PROFILE_URL: spinnupProfileUrl,
    SPINNUP_ARTIST_SITE_CMS_ENABLED: spinnupArtistSiteCmsEnabled,
    SPINNUP_EMAIL_VERIFICATION_TIMEOUT: process.env.REACT_APP_SPINNUP_EMAIL_VERIFICATION_TIMEOUT,
    SPINNUP_RELEASE_SOCIAL_ELIGIBLE_AFTER: '2019-04-01',
    SPINNUP_IS_PRODUCTION: isProduction,
    COVER_ART_MIN_SIZE: coverArtMinSize,
    COVER_ART_MAX_SIZE: coverArtMaxSize,
    HYPERWALLET_WIDGET_URL: hyperwalletWidgetUrl,
    AE_PRODUCTION_APP_URL: 'https://umg.theappreciationengine.com/framework/js/427',
    AE_TEST_APP_URL: 'https://umg-dev.theappreciationengine.com/framework/js/438',
    ARTIST_ONBOARDING: process.env.REACT_APP_ENABLE_1A1A,
    ARTIST_SETTINGS_INFORMATION: process.env.REACT_APP_ENABLE_1A1A,
    ENABLE_SUBSCRIPTION_MANAGEMENT: process.env.REACT_APP_ENABLE_SUBSCRIPTIONS,
    ENABLE_AYCE: process.env.REACT_APP_ENABLE_AYCE,
    ENABLE_INVITE_ONLY: process.env.REACT_APP_ENABLE_INVITE_ONLY,
    OFFBOARDING_MAX_RELEASE_DATE: process.env.REACT_APP_OFFBOARDING_MAX_RELEASE_DATE,
}

export default env
