import { connect } from 'react-redux'
import {
    reduxForm,
    blur,
    change,
    touch,
} from 'redux-form'
import { getGenres, isFetchingGenres } from 'selectors/genres'
import { getLanguages, isFetchingLanguages } from 'selectors/languages'
import { countriesSelector } from 'selectors/countries'

import { fetchGenres } from 'actions/GenreActions'
import { fetchLanguages } from 'actions/LanguageActions'

import { getAlbumSettingFormName } from 'modules/common/album'
import ReleaseSettingsForm from 'components/ReleaseSettingsForm/ReleaseSettingsForm'
import { syncValidate } from 'modules/album'
import { socialDeliveryRelease, youtubeDeliveryRelease } from 'actions/ReleaseActions'
import { requestSave } from 'modules/tracks'

const ReleaseSettingReduxForm = reduxForm({
    form: getAlbumSettingFormName(),
    enableReinitialize: false,
    destroyOnUnmount: false,
    shouldValidate: () => false,
    shouldError: () => false,
})(ReleaseSettingsForm)

const mapStateToProps = (state, {
    album, release, socialOptedIn, youtubeOptedIn, allTracks,
}) => (
    {
        genres: getGenres(state),
        isFetchingGenres: isFetchingGenres(state),
        languages: getLanguages(state),
        countries: countriesSelector(state),
        isFetchingLanguages: isFetchingLanguages(state),
        // redux-form
        initialValues: {
            album: {
                title: album.title,
                id: album.id,
                artists: album.artists,
                genreId: album.genreId,
                languageId: album.languageId,
                releaseDate: album.releaseDate,
                countries: album.countries,
                originalReleaseDate: album.originalReleaseDate,
                upc: album.upc,
                translations: album.translations,
            },
            release: {
                id: release.id,
            },
        },
        socialOptedIn,
        youtubeOptedIn,
        allTracks,
    }
)

const mapDispatchToProps = dispatch => ({
    fetchGenres: () => dispatch(fetchGenres()),
    fetchLanguages: () => dispatch(fetchLanguages()),
    addTranslatedTitleToAlbum: (translations) => {
        const formName = getAlbumSettingFormName()
        dispatch(change(formName, 'album.translations', translations))
        dispatch(blur(formName, 'album.translations', translations))
        dispatch(touch(formName, 'album.title'))
        return true
    },
    addTranslatedArtistsToAlbum: (artistTranslations) => {
        const formName = getAlbumSettingFormName()
        dispatch(change(formName, 'album.artistTranslations', artistTranslations))
        dispatch(blur(formName, 'album.artistTranslations', artistTranslations))
        dispatch(touch(formName, 'album.artists'))
        return true
    },
    validateAlbumTitleWithMetadata: () => {
        dispatch(syncValidate(getAlbumSettingFormName(), 'album.title'))
    },
    validateAlbumArtistNamesWithMetadata: () => {
        dispatch(syncValidate(getAlbumSettingFormName(), 'album.artists'))
    },
    onSocialDeliveryRelease: (releaseId, optedIn) => {
        dispatch(socialDeliveryRelease(releaseId, optedIn))
    },
    onYoutubeToggleRelease: (releaseId, optedIn) => {
        dispatch(youtubeDeliveryRelease(releaseId, optedIn))
    },
    onTrackStartTimeUpdate: (updatedTrack) => {
        dispatch(requestSave(updatedTrack, 'sampleStartTime'))
    },
})

const ReleaseSettingContainer = connect(mapStateToProps, mapDispatchToProps)(ReleaseSettingReduxForm)

export default ReleaseSettingContainer
