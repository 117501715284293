import { connect } from 'react-redux'
import { orderedTracksSelector } from 'selectors/tracks'

import TrackUploadProgress from 'components/TrackUpload/TrackUploadProgress'

const mapStateToProps = (state, props) => ({
    tracks: orderedTracksSelector(state, props.id),
})

export default connect(mapStateToProps)(TrackUploadProgress)
