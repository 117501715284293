import { useRef, useEffect } from 'react'
//
// uses Ref to keep track of an updated vars previous value
// Example use case is for when comparing changed values in 'useEffect'
//
export const usePrevious = (value) => {
    const ref = useRef(value)
    useEffect(() => {
        ref.current = value
    }, [ref])
    return ref.current
}
