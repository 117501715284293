import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Button from 'components/Button/Button'

export default class WidgetBannerLoader extends Component {
    static propTypes = {
        view: PropTypes.string,
    };

    static defaultProps = {
        view: 'single',
    };

    renderMulti = () => (
        <div>
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-subtitle-left" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-subtitle-right" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-subtitle-bottom" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-multi-icon-left" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-multi-icon-middle" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-multi-icon-right" />
        </div>
    )

    renderSingle = () => (
        <div>
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-subtitle-short-left" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-subtitle-short-right" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-subtitle-bottom" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-icon-left" />
            <div className="c-widget-banner-loading-masker c-widget-banner-loading-icon-right" />
        </div>
    )

    render() {
        const {
            view,
        } = this.props

        return (
            <div className="c-widget-banner-loading-wrapper">
                <div className="c-widget-banner-loading-background">
                    <div className="c-widget-banner-loading-masker c-widget-banner-loading-title-top" />
                    <div className="c-widget-banner-loading-masker c-widget-banner-loading-title-left" />
                    <div className="c-widget-banner-loading-masker c-widget-banner-loading-title-right" />
                    <div className="c-widget-banner-loading-masker c-widget-banner-loading-title-bottom" />
                    { view === 'multi'
                        ? this.renderMulti()
                        : this.renderSingle()}
                    <div className="c-widget-banner-loading-masker c-widget-banner-loading-icon-bottom" />
                </div>
                <Button className="c-widget-banner-loading-button" disabled isLoading />
            </div>
        )
    }
}
