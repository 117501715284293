import { WindowEnv } from './react-app-env'

const env: WindowEnv = {
    SPINNUP_API: process.env.REACT_APP_SPINNUP_API,
    SPINNUP_ARTIST_SITE_CMS_ENABLED: process.env.REACT_APP_SPINNUP_ARTIST_SITE_CMS_ENABLED,
    SPINNUP_API_ID: process.env.REACT_APP_SPINNUP_API_ID,
    SPINNUP_API_SECRET: process.env.REACT_APP_SPINNUP_API_SECRET,
    GOOGLE_RECAPTCHA_SITE_KEY: process.env.REACT_APP_SPINNUP_RECAPTCHA_SITE_KEY,
    ZENDESK_WEBWIDGET_KEY: process.env.REACT_APP_ZENDESK_WEBWIDGET_KEY,
    SPINNUP_PROFILE_URL: process.env.REACT_APP_SPINNUP_PROFILE_URL,
    SPINNUP_EMAIL_VERIFICATION_TIMEOUT: process.env.REACT_APP_SPINNUP_EMAIL_VERIFICATION_TIMEOUT,
    SPINNUP_RELEASE_SOCIAL_ELIGIBLE_AFTER: process.env.REACT_APP_RELEASE_SOCIAL_ELIGIBLE_AFTER,
    SPINNUP_IS_PRODUCTION: process.env.REACT_APP_IS_PRODUCTION,
    AE_PRODUCTION_APP_URL: process.env.REACT_APP_AE_PRODUCTION_APP_URL,
    AE_TEST_APP_URL: process.env.REACT_APP_AE_TEST_APP_URL,
    COVER_ART_MIN_SIZE: process.env.REACT_APP_COVER_ART_MIN_SIZE,
    COVER_ART_MAX_SIZE: process.env.REACT_APP_COVER_ART_MAX_SIZE,
    ADYEN: {
        ENVIRONMENT: process.env.REACT_APP_ADYEN_ENVIRONMENT,
        ORIGIN_KEY: process.env.REACT_APP_ADYEN_ORIGIN_KEY,
        DROPIN_LIB_URL: process.env.REACT_APP_ADYEN_DROPIN_LIB_URL,
    },
    HYPERWALLET_WIDGET_URL: process.env.REACT_APP_HYPERWALLET_WIDGET_URL,
    ARTIST_ONBOARDING: process.env.REACT_APP_ENABLE_1A1A,
    ARTIST_SETTINGS_INFORMATION: process.env.REACT_APP_ENABLE_1A1A,
    ENABLE_SUBSCRIPTION_MANAGEMENT: process.env.REACT_APP_ENABLE_SUBSCRIPTIONS,
    ENABLE_AYCE: process.env.REACT_APP_ENABLE_AYCE,
    ENABLE_INVITE_ONLY: process.env.REACT_APP_ENABLE_INVITE_ONLY,
    OFFBOARDING_MAX_RELEASE_DATE: process.env.REACT_APP_OFFBOARDING_MAX_RELEASE_DATE,
}

export default env
