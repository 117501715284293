import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import t from 'utilities/translate'

import EditProfileForm from 'containers/EditProfile/Form'
import Spinner from 'components/Spinner/Spinner'
import { withRouter } from 'react-router-dom'

class Edit extends Component {
    componentDidMount() {
        const {
            isLoaded,
            getDraftProfile,
            artistId,
            artistIsEnhanced,
            history,
        } = this.props

        if (!artistIsEnhanced) {
            history.replace('/overview')
        }
        if (!isLoaded) {
            getDraftProfile(artistId)
        }

        window.scrollTo(0, 0)
    }

    render() {
        const { loadingFailed, isLoaded } = this.props

        if (loadingFailed) {
            return (
                <div className="c-edit-profile">
                    <div className="c-edit-profile-error">{t('formEditProfileLoadingFailed')}</div>
                </div>
            )
        }
        if (!isLoaded) {
            return (
                <div className="c-edit-profile loading">
                    <Spinner size="medium" />
                </div>
            )
        }
        return (
            <div>
                <EditProfileForm />
            </div>
        )
    }
}

Edit.propTypes = {
    getDraftProfile: PropTypes.func.isRequired,
    artistId: PropTypes.number.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    loadingFailed: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    artistIsEnhanced: PropTypes.bool.isRequired,
}

export default withRouter(Edit)
