// BALANCE

export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST'
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS'
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE'

// TRANSACTIONS

export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST'
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS'
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE'

// WITHDRAW

export const WITHDRAW_REQUEST = 'WITHDRAW_REQUEST'
export const WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS'
export const WITHDRAW_FAILURE = 'WITHDRAW_FAILURE'

// ACCOUNT
export const FETCH_WALLET_ACCOUNT_REQUEST = 'FETCH_WALLET_ACCOUNT_REQUEST'
export const FETCH_WALLET_ACCOUNT_SUCCESS = 'FETCH_WALLET_ACCOUNT_SUCCESS'
export const FETCH_WALLET_ACCOUNT_FAILURE = 'FETCH_WALLET_ACCOUNT_FAILURE'

export const CREATE_WALLET_ACCOUNT_REQUEST = 'CREATE_WALLET_ACCOUNT_REQUEST'
export const CREATE_WALLET_ACCOUNT_SUCCESS = 'CREATE_WALLET_ACCOUNT_SUCCESS'
export const CREATE_WALLET_ACCOUNT_FAILURE = 'CREATE_WALLET_ACCOUNT_FAILURE'

export const UPDATE_WALLET_ACCOUNT_REQUEST = 'UPDATE_WALLET_ACCOUNT_REQUEST'
export const UPDATE_WALLET_ACCOUNT_SUCCESS = 'UPDATE_WALLET_ACCOUNT_SUCCESS'
export const UPDATE_WALLET_ACCOUNT_FAILURE = 'UPDATE_WALLET_ACCOUNT_FAILURE'

export const FETCH_WITHDRAW_FEES_REQUEST = 'FETCH_WALLET_FEES_REQUEST'
export const FETCH_WITHDRAW_FEES_SUCCESS = 'FETCH_WALLET_FEES_SUCCESS'
export const FETCH_WITHDRAW_FEES_FAILURE = 'FETCH_WALLET_FEES_FAILURE'
