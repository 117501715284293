export const EVENT_PAGE_VIEW = 'pageviewCustomEvent'

export function event(category, action, label, value) {
    if ('ga' in window && typeof window.ga === 'function') {
        ga(() => {
            const tracker = ga.getAll()[0]
            if (tracker) {
                tracker.send('event', category, action, label, value)
            }
        })
    } else {
        console.warn(`Attempted to log analytics event with category ${category} before analytics is ready`)
    }
}

export function getGaClientId() {
    if (window.ga) {
        return window.ga.getAll()[0].get('clientId')
    }
    return null
}

export default event

export function sendEventToDataLayer(eventName, data) {
    try {
        if ('dataLayer' in window && typeof window.dataLayer === 'object') {
            let objToPush = {
                event: eventName,
            }
            objToPush = Object.assign(objToPush, data)
            window.dataLayer.push(objToPush)
        } else {
            console.warn(`Attempted to log ${eventName} with path ${data.pagePath} before dataLayer is ready`)
        }
    } catch (e) {
        console.warn(
            `Attempted to log ${eventName}t with path ${data.pagePath} but exception thrown: ${e.message}`
        )
    }
}
