import React from 'react'
import * as PropTypes from 'prop-types'

import Frame from 'components/Frame/Frame'
import ClipoutText from 'components/Frame/ClipoutText'
import WidgetButton from 'components/WidgetButton/WidgetButton'
import FormattedBalance from 'components/Balance/FormattedBalance'
import WidgetBannerLoader from 'components/WidgetBanner/WidgetBannerLoader'

import t from 'utilities/translate'

const BalanceWidget = ({
    isFetching,
    numberOfReleases,
    balance,
    urlGettingStarted,
    balanceErrors,
}) => (
    <Frame
        borderImageId="dune-3"
        contentTint="light"
        contentClassName="widget-content"
    >
        {isFetching
            && <WidgetBannerLoader />}

        {(!isFetching && numberOfReleases === 0)
            && (
                <div className="widget-container">
                    <ClipoutText
                        type="h3"
                        backgroundImageId="dune-3"
                    >
                        {t('balanceWidgetTitle')}
                    </ClipoutText>
                    <WidgetButton href={urlGettingStarted} text={t('balanceWidgetButton')} blank />
                </div>
            )}

        {(!isFetching && numberOfReleases > 0)
            && (
                <div className="widget-container">
                    <ClipoutText
                        type="h3"
                        backgroundImageId="dune-3"
                    >
                        {t('balanceWidgetTitleWithBalance')}
                    </ClipoutText>

                    <FormattedBalance balance={balance} balanceErrors={balanceErrors} />

                    <WidgetButton href="/balance" text={t('balanceWidgetButtonWithdraw')} />
                </div>
            )}
    </Frame>
)

BalanceWidget.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    numberOfReleases: PropTypes.number.isRequired,
    balance: PropTypes.object.isRequired,
    balanceErrors: PropTypes.bool.isRequired,
    urlGettingStarted: PropTypes.string.isRequired,
}

export default BalanceWidget
