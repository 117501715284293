export const CRM_FETCH_MAILINGLISTS_REQUEST = 'CRM_FETCH_MAILINGLISTS_REQUEST'
export const CRM_FETCH_MAILINGLISTS_SUCCESS = 'CRM_FETCH_MAILINGLISTS_SUCCESS'
export const CRM_FETCH_MAILINGLISTS_FAILURE = 'CRM_FETCH_MAILINGLISTS_FAILURE'

export const CRM_FETCH_USER_SUBSCRIPTIONS_REQUEST = 'CRM_FETCH_USER_SUBSCRIPTIONS_REQUEST'
export const CRM_FETCH_USER_SUBSCRIPTIONS_SUCCESS = 'CRM_FETCH_USER_SUBSCRIPTIONS_SUCCESS'
export const CRM_FETCH_USER_SUBSCRIPTIONS_FAILURE = 'CRM_FETCH_USER_SUBSCRIPTIONS_FAILURE'

export const CRM_SUBSCRIBE_REQUEST = 'CRM_SUBSCRIBE_REQUEST'
export const CRM_SUBSCRIBE_SUCCESS = 'CRM_SUBSCRIBE_SUCCESS'
export const CRM_SUBSCRIBE_FAILURE = 'CRM_SUBSCRIBE_FAILURE'

export const CRM_UNSUBSCRIBE_REQUEST = 'CRM_UNSUBSCRIBE_REQUEST'
export const CRM_UNSUBSCRIBE_SUCCESS = 'CRM_UNSUBSCRIBE_SUCCESS'
export const CRM_UNSUBSCRIBE_FAILURE = 'CRM_UNSUBSCRIBE_FAILURE'

export const CRM_UNSUBSCRIBE_ALL_REQUEST = 'CRM_UNSUBSCRIBE_ALL_REQUEST'
export const CRM_UNSUBSCRIBE_ALL_SUCCESS = 'CRM_UNSUBSCRIBE_ALL_SUCCESS'
export const CRM_UNSUBSCRIBE_ALL_FAILURE = 'CRM_UNSUBSCRIBE_ALL_FAILURE'

export const CRM_COMPLETE_DOI_REQUEST = 'CRM_COMPLETE_DOI_REQUEST'
export const CRM_COMPLETE_DOI_SUCCESS = 'CRM_COMPLETE_DOI_SUCCESS'
export const CRM_COMPLETE_DOI_FAILURE = 'CRM_COMPLETE_DOI_FAILURE'
