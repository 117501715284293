export function getConsentStatus() {
    if (!window.evidon || !window.evidon.notice || !window.evidon.notice.activeSettings) {
        return null
    }
    const evidonCookieKey = `_evidon_consent_ls_${window.evidon.notice.activeSettings.id}`
    const consentCookie = JSON.parse(localStorage.getItem(evidonCookieKey))
    if (consentCookie) {
        let consentStatus = false
        if (typeof consentCookie.categories[6] === 'object'
            && consentCookie.categories[6] !== null
            && typeof consentCookie.categories[6]['performance and analytics'] !== 'undefined') {
            consentStatus = consentCookie.categories[6]['performance and analytics']
        } else if (typeof consentCookie.categories[6] === 'boolean') {
            // eslint-disable-next-line prefer-destructuring
            consentStatus = consentCookie.categories[6]
        }

        return consentStatus
    }
    return null
}
