import { ApiCountryShort } from 'models/api/country'

export default class Country {
    id: string = ''

    countryName: string = ''

    countryCode: string = ''

    allowed: boolean = false

    constructor(apiCountry: ApiCountryShort) {
        this.id = apiCountry.id
        this.countryName = apiCountry.name
        this.countryCode = apiCountry.code
        this.allowed = apiCountry.allowed
    }
}
