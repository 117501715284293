import * as types from 'constants/ScoutActionTypes'
import { CALL_API } from 'middleware/api'

// FETCH ALL

function getScouts() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_SCOUTS_REQUEST,
                types.FETCH_SCOUTS_SUCCESS,
                types.FETCH_SCOUTS_FAILURE,
            ],
            payload: {
                endpoint: 'my/scouts',
                query: {
                    scope: 'local',
                    part: 'profile,links,files,featured',
                },
            },
        },
    }
}

export function fetchScouts() {
    return dispatch => dispatch(getScouts())
}

// FETCH ONE

function getScout(id) {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_SCOUT_REQUEST,
                types.FETCH_SCOUT_SUCCESS,
                types.FETCH_SCOUT_FAILURE,
            ],
            payload: {
                endpoint: `scouts/${id}`,
                query: {
                    part: 'profile,links,files',
                },
            },
        },
    }
}

export function fetchScout(id) {
    return dispatch => dispatch(getScout(id))
}

// PUSH

function postPush(body) {
    return {
        [CALL_API]: {
            types: [
                types.PUSH_SCOUT_REQUEST,
                types.PUSH_SCOUT_SUCCESS,
                types.PUSH_SCOUT_FAILURE,
            ],
            payload: {
                endpoint: 'pushes',
                method: 'post',
                body,
            },
            meta: body,
        },
    }
}

export function pushScout(id, releaseId, trackId, message, acceptedTerms) {
    return (dispatch, getState) => {
        const artistId = getState().users.user.artist.id

        const body = {
            scoutId: id,
            artistId,
            releaseId,
            trackId,
            message,
            acceptedTerms,
        }

        return dispatch(postPush(body))
    }
}
