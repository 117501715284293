import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import ClipoutText from 'components/Frame/ClipoutText'

const ReasonsHeader = ({
    title,
    items,
}) => (
    <div
        className={classnames(
            'c-reason-header'
        )}
    >
        <ClipoutText
            className="c-reason-header-title"
            type="h3"
            backgroundImageId="water-1"
        >
            {title}
        </ClipoutText>
        <ul
            className="c-reason-header-items"
        >
            {items.map(item => (
                <li
                    key={item}
                    className="c-reason-header-item"
                >
                    {item}
                </li>
            ))}
        </ul>
    </div>
)

ReasonsHeader.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ReasonsHeader
