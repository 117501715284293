import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import t from 'utilities/translate'
import Alert from '../Alert/Alert'

export default class SocialDisconnected extends Component {
    render() {
        return (
            <Alert type="warning">
                {t('componentSocialDisconnectedP1')}
                { ' ' }
                <Link to="settings">
                    {t('componentSocialDisconnectedP2')}
                </Link>
            </Alert>
        )
    }
}
