import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import TimebarChannelSegment from './TimebarChannelSegment'

const areSegmentBoundedDates = (boundStart, boundEnd, testStart, testEnd) => {
    const startsInBounds = (testStart >= boundStart && testStart <= boundEnd)
    const endsInBounds = (testEnd >= boundStart && testEnd <= boundEnd)
    const wrapsWholeSegment = (testStart < boundStart) && (testEnd > boundEnd)
    return startsInBounds || endsInBounds || wrapsWholeSegment
}

// used to reduce a set of subranges within a segment
// building the broadest subrange from within the set
//
const getActivitySubRange = (acc, testrange, segStart, segEnd) => {
    if (testrange.end < segStart || testrange.start > segEnd) {
        return acc
    }

    // clip start and end to segment bounds
    const testStart = testrange.start < segStart ? segStart : testrange.start
    const testEnd = testrange.end > segEnd ? segEnd : testrange.end

    return {
        start: testStart < acc.start ? testStart : acc.start,
        end: testEnd > acc.end ? testEnd : acc.end,
    }
}

const getChannelSegments = (axis, channel, scope) => {
    // Build each Segment block
    const segBlocks = [...new Array(axis.length)].map((_, axisIndex) => {
        const startSegmentTs = axis[axisIndex]
        const endSegmentTs = parseFloat(scope.next(startSegmentTs)) - 1
        const datesForSegment = channel.dates.filter(
            d => areSegmentBoundedDates(startSegmentTs, endSegmentTs, d.start, d.end)
        )

        const isFilled = datesForSegment.length > 0
        const activity = isFilled ? datesForSegment.reduce(
            (a, c) => getActivitySubRange(a, c, startSegmentTs, endSegmentTs),
            {
                start: endSegmentTs,
                end: startSegmentTs,
            } // init is backwards on purpose to min max the max min.. if you get my meaning
        ) : null
        const range = {
            first: axisIndex === 0,
            last: axisIndex === axis.length - 1,
            length: axis.length,
            sequenceNo: axisIndex + 1,
        }

        return {
            channel: channel.name,
            axisIndex,
            range,
            run: isFilled ? {
                start: activity.start,
                end: activity.end,
                length: activity.end - activity.start,
            } : null,
            segment: {
                start: startSegmentTs,
                end: endSegmentTs,
                length: (endSegmentTs - startSegmentTs) + 1,
            },
        }
    })

    segBlocks
        .forEach((s) => {
            if (s.run) {
                const prevSeg = s.axisIndex > 0 ? segBlocks[s.axisIndex - 1] : null
                const nextSeg = s.axisIndex < s.range.length - 1 ? segBlocks[s.axisIndex + 1] : null

                segBlocks[s.axisIndex].run.isStart = !!(
                    (s.run && !prevSeg)
                    || (prevSeg && (!prevSeg.run && s.run))
                )

                segBlocks[s.axisIndex].run.isEnd = !!(
                    (!nextSeg && (s.range.sequenceNo === s.range.length))
                    || (nextSeg && !nextSeg.run)
                )
            }
        })

    segBlocks
        .filter(s => s.run && s.run.isStart)
        .map((s) => {
            const nextEndIndex = segBlocks.findIndex(fSeg => (fSeg.axisIndex >= s.axisIndex) && fSeg.run.isEnd)
            s.run.endsWith = segBlocks[nextEndIndex]
            segBlocks[nextEndIndex].run.startsWith = s
            return s
        })

    return segBlocks
}

/**
 * TimebarChannel
 * ---------------
 *  - There is  some quite complex logic in here.. to help the following definitions apply
 *
 *  Channel:        The name for this whole bar in the timebar
 *  Range:          The Time period being looked at by each channel
 *  Axis:           The scale displayed for the Time Range being looked at...
 *  Segment:        Each 'block' or 'unit' of time as measured by the Axis
 *  Activity:       Any dates within a segment that need to be 'Filled' (colored in)
 *  Run:            The concept of activity spanning one or more segments in a 'Run'
 *
 *  Channel data is parsed into segment objects which should contain correct information about
 *  its relative data within the channel
 *  - any PROBLEMS inspect this first. (output from ::getChannelSegments)
 */
const TimebarChannel = ({ axis, channel, scope }) => (
    <div className="c-timebar-channels-bar">
        {
            getChannelSegments(axis, channel, scope).map(
                s => (
                    <TimebarChannelSegment
                        key={`${s.channel}_${s.axisIndex}`}
                        seg={s}
                        scope={scope}
                    />
                )
            )
        }
    </div>
)

TimebarChannel.propTypes = {
    scope: PropTypes.object.isRequired,
    channel: PropTypes.object.isRequired,
    axis: PropTypes.array.isRequired,
}

export default memo(TimebarChannel)
