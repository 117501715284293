import React from 'react'
import { connect } from 'react-redux'
import { getFullLanguage, setAppLocale } from 'utilities/translate'
import { makeLocalesSelector, SelectedLocale } from 'selectors/locales'
import { AppStoreState } from 'store/store-types'
import { TranslationLanguage } from 'contexts/localisation/translateUtilities'
import buildLocaleState, { LocaleState } from './localeState'

interface LocaleSetterState {
    setLocale: (language: TranslationLanguage, country: string) => void
}

export const LocaleContext = React.createContext<LocaleState>({} as LocaleState)
export const LocaleSetterContext = React.createContext<LocaleSetterState>({} as LocaleSetterState)

function initialState(userLanguageId: TranslationLanguage, userCountryId: string, locales: SelectedLocale[]) {
    const [storedLanguage, storedCountry] = getFullLanguage().split('_')
    return buildLocaleState(
        userLanguageId || storedLanguage,
        userCountryId || storedCountry,
        locales || []
    )
}

type LocaleProviderViewProps = {
    userLanguageId: TranslationLanguage
    userCountryId: string
    locales: SelectedLocale[]
    children?: React.ReactNode
}

const LocaleProviderView: React.FC<LocaleProviderViewProps> = ({
    userLanguageId,
    userCountryId,
    locales,
    children,
}) => {
    const [state, setLocaleState] = React.useState(initialState(userLanguageId, userCountryId, locales))
    const { localeConfig } = state
    const localeModifier = {
        setLocale: (language: TranslationLanguage, country: string) => {
            setAppLocale(language, country)
            setLocaleState(buildLocaleState(language, country, localeConfig.availableLocales))
        },
    }

    // todo   ideally this logic would be in the useEffect hook of a root level container which renders off
    // todo   user context and modify using the useLocaleContext below.
    const userLocaleChanged = !!userCountryId && !!userLanguageId
        && localeConfig.localeWithCountry !== `${userLanguageId}_${userCountryId}`
    const availableLocaledChanged = !!locales && locales.length > 0
        && localeConfig.availableLocales.length !== locales.length

    if (userLocaleChanged || availableLocaledChanged) {
        const newLanguage = userLocaleChanged ? userLanguageId : localeConfig.locale
        const newCountry = userLocaleChanged ? userCountryId : localeConfig.country
        const newLocales = availableLocaledChanged ? locales : localeConfig.availableLocales
        setLocaleState(buildLocaleState(newLanguage, newCountry, newLocales))
    }

    return (
        <LocaleContext.Provider value={state}>
            <LocaleSetterContext.Provider value={localeModifier}>
                {children}
            </LocaleSetterContext.Provider>
        </LocaleContext.Provider>
    )
}

const mapStateToProps = (state: AppStoreState) => ({
    userLanguageId: !!state.users && !!state.users.user ? state.users.user.languageId : undefined,
    userCountryId: !!state.users && !!state.users.user ? state.users.user.countryId : undefined,
    locales: makeLocalesSelector(true)(state),
})

const LocaleProvider = connect(mapStateToProps)(LocaleProviderView)

// Used inside functional components where hooks are available
function useLocaleContext() {
    return React.useContext(LocaleContext)
}

// todo   this should be used in an effect hook within a root level component that is propped off user
// todo   allowing it to set the correct app locale based on authentication context. After that, all local storage
// todo   references could be moved within the boundary of this locale provider.
// function setLocaleContext() {
//     return React.useContext(LocaleSetterContext)
// }

type PropsObject<T> = { [K in keyof T]?: any }
// Used inside class components where hooks are not available
export const withLocalisation = <T extends PropsObject<T>>(Component: React.ComponentType<T>) => (
    (props: T) => (
        <LocaleContext.Consumer>
            {
                localeContext => (
                    <LocaleSetterContext.Consumer>
                        {
                            setterContext => <Component locale={localeContext} setLocale={setterContext} {...props} />
                        }
                    </LocaleSetterContext.Consumer>
                )
            }
        </LocaleContext.Consumer>
    )
)

export { LocaleProvider, useLocaleContext }
