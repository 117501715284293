import React from 'react'
import * as PropTypes from 'prop-types'
import Button from 'components/Button/Button'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const CreateReleaseBanner = ({ display }) => {
    const { t } = useLocaleContext()
    return (!display ? null : (
        <div className="c-createReleaseBanner">
            <div className="inner">
                <Button href="/create" className="cta">
                    {t('componentCreateReleaseBannerCtaText')}
                </Button>
                <p className="bannerText">
                    {t('componentCreateReleaseBannerText')}
                </p>
            </div>
        </div>
    ))
}

CreateReleaseBanner.propTypes = {
    display: PropTypes.bool.isRequired,
}

export default CreateReleaseBanner
