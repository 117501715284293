import { connect } from 'react-redux'
import { formValueSelector, getFormSyncErrors, getFormAsyncErrors } from 'redux-form'
import { get } from 'lodash'
import { tracksOrderSelector, orderedTracksSelector } from 'selectors/tracks'
import { releaseTypeSelector } from 'selectors/releaseTypes'
import { getById as getVersionById } from 'selectors/versions'
import { getTracksFormName, getTrackForms } from 'modules/common/tracks'
import { updateAlbum } from 'actions/AlbumActions'
import {
    createTracks,
    removeTrack,
    selectTrack,
    uploadFile,
    abortFile,
} from 'actions/TrackActions'
import { fetchTrackVersions } from 'actions/TrackVersionActions'
import TrackUpload from 'components/TrackUpload/TrackUpload'
import { formatTrackTitle } from 'utilities/track'
import { queueConfirmationModal } from 'actions/ModalActions'

const mapStateToProps = (state, props) => {
    const tracks = orderedTracksSelector(state, props.album.id)
    const trackTitles = tracks.map((track) => {
        // we have to get the title from the form reducer
        // to be able to display rel time updated
        const formName = getTracksFormName(track)
        const selector = formValueSelector(formName)

        // we get the form values to display real time update
        const title = selector(state, 'title')
        const recordingVersionId = selector(state, 'recordingVersionId')
        const version = getVersionById(state, recordingVersionId)
        const artists = selector(state, 'artists')

        return {
            id: track.id,
            title: formatTrackTitle(title, artists, version),
        }
    })
    const trackHasFormError = tracks.map((track) => {
        const formName = getTracksFormName(track)
        const syncErrors = getFormSyncErrors(formName)(state) || {}
        const asyncErrors = getFormAsyncErrors(formName)(state) || {}
        const hasSyncError = Object.keys(syncErrors).some((field) => {
            const hasFieldError = syncErrors[field]
            const touched = get(state.form, `${formName}.fields.${field}.touched`)
            return hasFieldError && touched
        })
        const hasAsyncError = Object.keys(asyncErrors).some((field) => {
            const hasFieldError = asyncErrors[field]
            // no need to look for touched here, an async error comes from a field change
            return hasFieldError
        })
        return hasSyncError || hasAsyncError
    })

    return {
        tracksOrder: tracksOrderSelector(state, props.album.id),
        releaseType: releaseTypeSelector(state, props.id),
        isCreatingTracks: state.tracks.isCreatingTracks,
        tracks,
        trackTitles,
        trackHasFormError,
        allForms: getTrackForms(state),
    }
}

const mapDispatchToProps = dispatch => ({
    abortFile: trackId => dispatch(abortFile(trackId)),
    createTracks: (albumId, files) => dispatch(createTracks(albumId, files)),
    fetchVersions: () => dispatch(fetchTrackVersions()),
    removeTrack: (album, id) => dispatch(removeTrack(album, id)),
    selectTrack: trackId => dispatch(selectTrack(trackId)),
    updateAlbum: (albumId, data) => dispatch(updateAlbum(albumId, data)),
    uploadFile: (albumId, trackId, file) => dispatch(uploadFile(albumId, trackId, file)),
    showConfirmationModal: (modalId, title, body, onConfirm) => queueConfirmationModal(
        modalId, title, body, onConfirm
    )(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrackUpload)
