import 'rxjs'
import { decamelizeKeys } from 'humps'

const API_URI = window.env.SPINNUP_API

export const put = ({ artist }, credentials, ajax) => {
    const setting = {
        method: 'PUT',
        url: `${API_URI}artists/${artist.id}?part=profile,links,files`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${credentials.accessToken}`,
        },
        body: JSON.stringify(decamelizeKeys({
            ...artist,
            links: {
                website1: '',
                website2: '',
                website3: '',
                website4: '',
                website5: '',
                ...artist.links.reduce(
                    (linksAcc, { key, value }) => ({
                        ...linksAcc,
                        [key]: (value || '').trim(),
                    }),
                    {}
                ),
            },
        })),
    }

    return ajax(setting)
}
