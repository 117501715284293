import React from 'react'
import * as PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'

const Pill = ({
    href,
    label,
    active,
    onClick,
}) => {
    const className = classnames(
        'link',
        active ? 'active' : ''
    )

    if (!onClick) {
        return (
            <li className="c-navs-pill">
                <NavLink activeclassname="active" className={className} to={href}>
                    {label}
                </NavLink>
            </li>
        )
    }

    return (
        <li className="c-navs-pill">
            <span className={className} onClick={() => onClick(href)}>
                {label}
            </span>
        </li>
    )
}

Pill.propTypes = {
    href: PropTypes.string,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func,
}

export default Pill
