import { connect } from 'react-redux'
import { getDraftProfile } from 'actions/ProfileActions'
import Edit from 'components/EditProfile/Edit'
import { artistSelector, artistEnhancedSelector } from 'selectors/users'
import { isLoadedSelector, loadingFailedSelector } from 'selectors/draftProfile'

const mapStateToProps = state => ({
    isLoaded: isLoadedSelector(state),
    loadingFailed: loadingFailedSelector(state),
    artistId: artistSelector(state).id,
    artistIsEnhanced: artistEnhancedSelector(state),
})

const mapDispatchToProps = {
    getDraftProfile,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Edit)
