import Album, { IAlbum } from 'models/album'

export interface IFormattedAlbum extends IAlbum {
    artist: string
    title: string
    copyrightsYear: string
    formattedOriginalReleaseDate: string
    formattedReleaseDate: string
    formattedTerritory: string
    isSingle: boolean
    isRejected: boolean
    genre?: string,
    genreTranslationKey: string
    language?: string
}

export class FormattedAlbum extends Album implements IFormattedAlbum {
    artist: string = ''

    copyrightsYear: string = ''

    formattedOriginalReleaseDate: string = ''

    formattedReleaseDate: string = ''

    formattedTerritory: string = ''

    genre: string = ''

    genreTranslationKey: string = ''

    isRejected: boolean = false

    isSingle: boolean = false

    language: string = ''

    constructor(base?: Partial<IFormattedAlbum>) {
        super(base)
        if (base) {
            Object.assign(this as FormattedAlbum, base)
        }
    }

    clone(props?: Partial<IFormattedAlbum>) {
        const a = new FormattedAlbum()
        Object.assign(a, this as FormattedAlbum, props ?? {})
        return a
    }
}
