import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { filteredReleasesSelector } from 'selectors/releases'
import Field from 'components/Field/Field'
import Select from 'components/Select/Select'
import t from 'utilities/translate'
import styles from './releaseSelect.module.scss'

const ReleaseSelectContainer = ({
    emptyLabel,
    error,
    label,
    releaseFilter,
    fetchReleases,
    value,
    onChange,
    disabled,
}) => {
    const options = fetchReleases(releaseFilter).map(release => ({
        value: release.id,
        label: release.album.title,
    }))

    return (
        <Field
            label={label}
            error={error || ''}
            className={styles.selectField}
        >
            <Select
                value={value || ''}
                options={options}
                onChange={onChange}
                disabled={disabled || false}
                placeholder={emptyLabel || t('containerReleaseSelectEmptyLabel')}
                emptyValueLabel={emptyLabel || t('containerReleaseSelectEmptyLabel')}
            />
        </Field>
    )
}

ReleaseSelectContainer.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    label: PropTypes.string,
    emptyLabel: PropTypes.string.isRequired,
    releaseFilter: PropTypes.string,
    fetchReleases: PropTypes.func.isRequired,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

ReleaseSelectContainer.defaultProps = {
    releaseFilter: 'live',
}

const mapStateToProps = state => ({
    fetchReleases: filter => filteredReleasesSelector(state, filter),
})

export default connect(mapStateToProps)(ReleaseSelectContainer)
