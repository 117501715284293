import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Slider from 'react-slick'

import classnames from 'classnames'

import { connect } from 'react-redux'
import { fetchBalance } from 'actions/WalletActions'
import { fetchTrends } from 'actions/TrendActions'

import { fetchServices } from 'actions/ServiceActions'
import { fetchSocialTrends } from 'actions/SocialTrendsActions'
import { balanceSelector } from 'selectors/wallet'
import { urlsSelector } from 'selectors/urls'
import { makeFilterTotalSelector, TYPE_OVERVIEW } from 'selectors/trends'
import {
    siteIconsSelector,
    overviewSocialStats,
    socialTrendsFansSelector,
} from 'selectors/socialTrends'

import { getConnectedServicesSelector } from 'selectors/services'
import { artistCanCreateRelease } from 'selectors/users'

import BalanceWidget from 'components/BalanceWidget/BalanceWidget'
import ReleasesWidget from 'components/ReleasesWidget/ReleasesWidget'
import SocialWidget from 'components/SocialWidget/SocialWidget'

class OverviewWidgetPanel extends Component {
    static propTypes = {
        numberOfReleases: PropTypes.number.isRequired,

        artistIsEnhanced: PropTypes.bool.isRequired,
        artistCanCreateReleases: PropTypes.bool.isRequired,
        fetchTrends: PropTypes.func.isRequired,
        connectedServices: PropTypes.array,

        // Balance Widget
        fetchBalance: PropTypes.func.isRequired,
        isFetchingBalance: PropTypes.bool.isRequired,
        balance: PropTypes.object.isRequired,
        balanceErrors: PropTypes.bool.isRequired,
        urlGettingStarted: PropTypes.string.isRequired,

        // Releases
        filterOptions: PropTypes.object.isRequired,
        streamTotal: PropTypes.number.isRequired,
        spotifyReleasesCount: PropTypes.number.isRequired,
        liveReleasesCount: PropTypes.number.isRequired,
        artistId: PropTypes.number.isRequired,
        isFetchingReleases: PropTypes.bool.isRequired,

        // Social
        fetchServices: PropTypes.func.isRequired,
        fetchSocialTrends: PropTypes.func.isRequired,
        overviewSocialStats: PropTypes.array.isRequired,
        isFetchingSocial: PropTypes.bool,
        failedFetchingSocial: PropTypes.bool,
    };

    constructor(props) {
        super(props)

        this.state = {
            isCarrouselNeeded: false,
        }
    }

    componentDidMount() {
        this.props.fetchBalance()

        // todo  The data dependencies in these widgets need to be better encapsulated/separated into the widgets.
        // todo  The below looks to be correct, but it could be fetchTrends can also be included.
        if (this.props.artistIsEnhanced) {
            this.props.fetchServices()
            this.props.fetchSocialTrends(['fans'])
        }

        if (this.props.liveReleasesCount > 0) {
            this.props.fetchTrends(this.props.artistId, this.props.filterOptions)
        }
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    updateDimensions = () => {
        this.setState({
            isCarrouselNeeded: !window.matchMedia('(min-width: 992px)').matches,
        })
    }

    render() {
        const {
            isCarrouselNeeded,
        } = this.state

        const {
            numberOfReleases,
            artistCanCreateReleases,
            isFetchingBalance,
            balance,
            balanceErrors,
            urlGettingStarted,
            filterOptions,
            streamTotal,
            spotifyReleasesCount,
            liveReleasesCount,
            isFetchingSocial,
            failedFetchingSocial,
            artistId,
            isFetchingReleases,
            connectedServices,
        } = this.props

        return (
            <Slider
                arrows={false}
                centerMode
                centerPadding="200px"
                className={classnames(
                    'c-overview-widget-panel', {
                        'slick-slider-disabled': !isCarrouselNeeded,
                    }
                )}
                dots
                infinite={false}
                initialSlide={1}
                slidesToScroll={1}
                slidesToShow={1}
                speed={500}
                unslick={!isCarrouselNeeded}
                variableWidth
                focusOnSelect
            >
                <ReleasesWidget
                    isFetching={isFetchingReleases}
                    disableCreation={!artistCanCreateReleases}
                    filterOptions={filterOptions}
                    streamTotal={streamTotal}
                    spotifyReleasesCount={spotifyReleasesCount}
                    numberOfReleases={numberOfReleases}
                    liveReleasesCount={liveReleasesCount}
                    artistId={artistId}
                />

                {
                    this.props.artistIsEnhanced ? (
                        <SocialWidget
                            isFetching={isFetchingSocial}
                            overviewSocialStats={this.props.overviewSocialStats}
                            failedFetchingSocial={failedFetchingSocial}
                            connectedServices={connectedServices}
                        />
                    ) : null
                }

                <BalanceWidget
                    isFetching={isFetchingBalance}
                    numberOfReleases={numberOfReleases}
                    balance={balance}
                    balanceErrors={balanceErrors}
                    urlGettingStarted={urlGettingStarted}
                />
            </Slider>
        )
    }
}

const fansSocialTrendsSelector = socialTrendsFansSelector
const fansSiteIconsSelector = siteIconsSelector('fans')
const fansOverviewSocialStatsSelector = overviewSocialStats('fans')

const mapStateToProps = (state, props) => {
    const urls = urlsSelector(state)
    const filterOptions = {
        dsp: 'spotify',
        selectionType: 'dsp',
        saleType: 'stream',
        days: 7,
    }

    const streamTotal = makeFilterTotalSelector(TYPE_OVERVIEW, filterOptions.saleType)(state)
    const userStatistics = props.userReleaseStatistics

    return {
        // Shared
        numberOfReleases: userStatistics.total ? userStatistics.total : 0,

        // Balance
        isFetchingBalance: (state.releases.isFetching || state.wallet.isFetchingBalance || state.albums.isFetching),
        balance: balanceSelector(state),
        balanceErrors: state.wallet.balanceErrors,
        urlGettingStarted: urls.publicWebsiteGettingStarted,

        // Releases
        artistCanCreateReleases: artistCanCreateRelease(state),
        isFetchingReleases: (props.isUserStatsLoading || state.trends.overview.dsps.isFetching),
        spotifyReleasesCount: userStatistics.totalSpotifyLive ? userStatistics.totalSpotifyLive : 0,
        liveReleasesCount: userStatistics.totalLive ? userStatistics.totalLive : 0,
        artistId: state.users.user ? state.users.user.artist.id : null,
        streamTotal: streamTotal || 0,
        filterOptions,

        // Social
        connectedServices: getConnectedServicesSelector(state),
        fans: fansSocialTrendsSelector(state),
        fanIcons: fansSiteIconsSelector(state),
        overviewSocialStats: fansOverviewSocialStatsSelector(state),
        isFetchingSocial: (state.socialTrends.fans.isFetching || state.services.isFetching),
        failedFetchingSocial: state.socialTrends.fans.failedFetching,
    }
}

const mapDispatchToProps = {
    fetchBalance,
    fetchTrends,
    fetchServices,
    fetchSocialTrends,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewWidgetPanel)
