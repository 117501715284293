import { createSelector } from 'reselect'
import t from 'utilities/translate'
import { formatTrackTitle } from 'utilities/track'
import * as statuses from 'constants/enums/ReleaseStatusTypes'
import { makeAlbumSelector } from 'selectors/albums'
import { AppStoreState } from 'store/store-types'
import Track, { ITrack } from 'models/track'
import { Artist } from 'models/artist'

interface IFormattedTrack extends ITrack {
    formattedTitle: string
    formattedTitleNoArtists: string
    explicitString: string
    isSelected: boolean
    canEditIsrc: boolean
    isUpdating: boolean
}

export function orderTracks(tracks: IFormattedTrack[], tracksOrder: string[]) {
    return tracks
        .filter(track => tracksOrder.indexOf(track.id) !== -1)
        .sort(
            (firstTrack, secondTrack) => tracksOrder.indexOf(firstTrack.id) - tracksOrder.indexOf(secondTrack.id)
        )
}

function formatTrack(
    track: Track,
    artists: Artist[],
    selectedId: string | undefined,
    albumStatus: string | undefined
): IFormattedTrack {
    return {
        ...track,
        formattedTitle: formatTrackTitle(track.title, track.artists, track.recordingVersion),
        formattedTitleNoArtists: formatTrackTitle(track.title, [], track.recordingVersion),
        explicitString: track.explicitLyrics ? t('selectorTracksExplicit') : '',
        isSelected: selectedId === track.id,
        canEditIsrc: albumStatus === statuses.RELEASE_STATUS_NOT_COMPLETED || track.justAdded,
        isUpdating: track.isUpdating,
    }
}

export const selectedIdSelector = (state: AppStoreState) => state.tracks.selectedId

export const tracksSelector = createSelector(
    (state: AppStoreState) => state.tracks.tracks,
    selectedIdSelector,
    (state: AppStoreState) => state,
    (tracks, selectedId, state) => Object.values(tracks).map(track => formatTrack(
        track,
        track.artists,
        selectedId,
        makeAlbumSelector(track.albumId)(state)?.status
    ))
)

export const tracksOrderSelector = createSelector(
    (state: AppStoreState, releaseId: string) => state.albums.albums[releaseId].tracksOrder || [],
    tracksOrder => tracksOrder
)

export const orderedTracksSelector = createSelector(
    tracksSelector,
    tracksOrderSelector,
    (tracks, tracksOrder) => orderTracks(tracks, tracksOrder)
)

export const makeTrackSelector = (id: string) => createSelector(
    tracksSelector,
    tracks => tracks.find(track => track.id === id)
)

export const makeTracksOrderSelector = (albumId: string) => createSelector(
    (state: AppStoreState) => state.albums.albums[albumId].tracksOrder || [],
    tracksOrder => tracksOrder
)

export const makeTracksSelector = (albumId: string) => createSelector(
    tracksSelector,
    makeTracksOrderSelector(albumId),
    (tracks, tracksOrder) => orderTracks(tracks, tracksOrder)
)

export const makeAlbumTracksSelector = (targetedAlbumId: string) => createSelector(
    tracksSelector,
    tracks => tracks.filter(({ albumId }) => albumId === targetedAlbumId)
)
