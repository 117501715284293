import React from 'react'
import * as PropTypes from 'prop-types'

import Alert from 'components/Alert/Alert'

export const ConditionalAlert = (props) => {
    const { children, display } = props
    if (display) {
        return <Alert {...props}>{children}</Alert>
    } else {
        return null
    }
}

ConditionalAlert.propTypes = {
    display: PropTypes.bool.isRequired,

    // These are what's given to the Alert component
    /** Alert icon type */
    type: PropTypes.oneOf(['info', 'warning', 'error', 'christmas', 'fatal-error']),
    children: PropTypes.node,
}

export default ConditionalAlert
