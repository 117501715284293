import { connect } from 'react-redux'
import { firstEditImagesSelector } from 'selectors/editImages'
import EditProfileImageCrop from 'components/EditProfileImageCrop/EditProfileImageCrop'
import { deleteImageCropPopup } from 'actions/EditImages'
import { addPopup } from 'actions/PopupActions'

const mapStateToProps = state => ({
    ...firstEditImagesSelector(state),
})

const mapDispatchToProps = {
    deleteImageCropPopup,
    openPopup: addPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileImageCrop)
