import { REHYDRATE } from 'redux-persist/constants'
import { Record as record } from 'immutable'
import * as types from 'constants/ReleaseTypeActionTypes'
import Collection from 'utilities/collection'

const createReleaseTypesCollection = () => new Collection(record({
    id: undefined,
    name: undefined,
    description: undefined,
    translationKey: undefined,
    maxTracks: undefined,
    currency: undefined,
    currentPrice: 0.00,
    currentPriceFormatted: 0.00,
    price: {},
}), [], 'id')

const initialState = {
    isFetching: false,
    releaseTypes: createReleaseTypesCollection(),
    fetchedAt: 0,
}

export default function releaseTypes(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_RELEASE_TYPES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_RELEASE_TYPES_SUCCESS:
            const response = action.payload.map(releaseType => (
                {
                    id: releaseType.id,
                    name: releaseType.name,
                    description: releaseType.description,
                    translationKey: releaseType.translationKey,
                    maxTracks: releaseType.maxTracks,
                    currency: releaseType.price.currency,
                    currentPrice: releaseType.price.amount,
                    currentPriceFormatted: releaseType.price.amountFormatted,
                    price: {
                        id: releaseType.price.id,
                        amount: releaseType.price.amount,
                        currency: releaseType.price.currency,
                        countryId: releaseType.price.countryId,
                        releaseTypeId: releaseType.price.releaseTypeId,
                    },
                }
            ))

            return {
                ...state,
                isFetching: false,
                releaseTypes: createReleaseTypesCollection().fromArray(response),
                fetchedAt: new Date(),
            }

        case REHYDRATE:
            if (action.payload.releaseTypes) {
                return {
                    ...action.payload.releaseTypes,
                    releaseTypes: createReleaseTypesCollection()
                        .fromObject(action.payload.releaseTypes.releaseTypes.map),
                }
            }
            return state

        default:
            return state
    }
}
