import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import t from 'utilities/translate'
import Select from 'components/Select/Select'

export default class TrendsFilter extends Component {
    static propTypes = {
        artistId: PropTypes.number.isRequired,
        filterTrends: PropTypes.func.isRequired,
        filterOptions: PropTypes.object.isRequired,
        releases: PropTypes.array.isRequired,
        hasReleases: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        failedFetching: PropTypes.bool.isRequired,
    };

    getReleaseAndTrackList() {
        const { releases } = this.props

        const releaseList = releases.sort().reduce((releasesWithTracks, release) => {
            let tracks = []
            let oneTrackRelease = false
            let singleTrackProductAssetId = null
            if (release.album.tracks.length > 1) {
                tracks = release.album.tracks.map((track, index) => ({
                    value: track.id,
                    label: `#${index + 1} - ${track.title}`,
                    type: 'asset',
                    level: 1,
                }))
            }
            if (release.album.tracks.length <= 1) {
                oneTrackRelease = true
                if (release.album.tracks.length > 0) {
                    singleTrackProductAssetId = release.album.tracks[0].id
                }
            }

            return [
                ...releasesWithTracks,
                {
                    value: Number(release.id),
                    label: release.album.title,
                    type: 'releaseId',
                    oneTrackRelease,
                    singleTrackProductAssetId,
                },
                ...tracks,
            ]
        }, [])

        releaseList.unshift({
            value: '',
            label: t('trendsFilterAllReleases'),
        })

        return releaseList
    }

    handleSaleTypeChange = (saleType) => {
        const {
            artistId,
            filterTrends,
            filterOptions,
        } = this.props

        const options = {
            ...filterOptions,
            saleType,
            page: 1,
        }

        filterTrends(artistId, options)
    }

    handleDaysChange = (days) => {
        const {
            artistId,
            filterTrends,
            filterOptions,
        } = this.props

        const options = {
            ...filterOptions,
            days,
            page: 1,
        }

        filterTrends(artistId, options)
    }

    handleReleaseChange = (releaseId) => {
        const {
            artistId,
            filterTrends,
            filterOptions,
        } = this.props

        const releaseList = this.getReleaseAndTrackList()
        let productType = { releaseId: null, asset: null }
        if (releaseId !== '') {
            const selectedProduct = releaseList.find(listItem => listItem.value === releaseId)
            if (selectedProduct.type === 'releaseId') {
                productType = {
                    releaseId: selectedProduct.value,
                    asset: null,
                }
            } else {
                productType = {
                    releaseId: null,
                    asset: selectedProduct.value,
                }
            }
        }

        const options = {
            ...filterOptions,
            ...productType,
            page: 1,
        }

        filterTrends(artistId, options)
    }

    render() {
        const {
            hasReleases,
            filterOptions,
            isLoading,
            failedFetching,
        } = this.props

        const saleTypes = [
            { label: t('trendsSaleTypeDownload'), value: 'download' },
            { label: t('trendsSaleTypeStream'), value: 'stream' },
        ]

        const days = [
            { label: t('trendsFilter7Days'), value: 7 },
            { label: t('trendsFilter30Days'), value: 30 },
            { label: t('trendsFilter90Days'), value: 90 },
            { label: t('trendsFilter365Days'), value: 365 },
        ]

        let releaseSelectValue = t('trendsFilterAllReleases')

        if (filterOptions.asset !== null && filterOptions.releaseId === null) {
            releaseSelectValue = filterOptions.asset
        } else if (filterOptions.releaseId !== null) {
            releaseSelectValue = filterOptions.releaseId
        } else {
            releaseSelectValue = ''
        }

        return (
            <div className="c-trends-filter">
                <Select
                    disabled={isLoading || failedFetching || !hasReleases}
                    value={filterOptions.saleType}
                    options={saleTypes}
                    sortedBy="value"
                    sortOrder="ASC"
                    onChange={this.handleSaleTypeChange}
                    selectClassName="c-trends-filter-option"
                />
                <Select
                    disabled={isLoading || failedFetching || !hasReleases}
                    value={releaseSelectValue}
                    options={this.getReleaseAndTrackList()}
                    onChange={this.handleReleaseChange}
                    selectClassName="c-trends-filter-option"
                    disableSort
                />
                <Select
                    disabled={isLoading || failedFetching || !hasReleases}
                    value={parseInt(filterOptions.days, 10)}
                    options={days}
                    sortedBy="value"
                    sortOrder="ASC"
                    onChange={this.handleDaysChange}
                    selectClassName="c-trends-filter-option"
                />
            </div>
        )
    }
}
