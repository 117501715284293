import * as types from '../constants/PreviewActionTypes'

const initialState = {
    token: null,
    isLoaded: true,
    loadingFailed: false,
}

export default function preview(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_PREVIEW_TOKEN_REQUEST:
            return {
                ...state,
                isLoaded: false,
                loadingFailed: false,
                token: null,
            }

        case types.FETCH_PREVIEW_TOKEN_SUCCESS:
            return {
                ...state,
                isLoaded: true,
                loadingFailed: false,
                token: action.payload.token,
            }

        case types.FETCH_PREVIEW_TOKEN_FAILURE:
            return {
                ...state,
                isLoaded: true,
                loadingFailed: true,
            }

        default:
            return state
    }
}
