import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import Alert from 'components/Alert/Alert'
import { Checkbox } from 'components/index'
import Icon from 'components/Icon/Icon'
import Spinner from 'components/Spinner/Spinner'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const RenderDefault = (props) => {
    const {
        checkboxRef,
        isTermsChecked,
        onTermsCheck,
        onSocialDeliveryRelease,
        release,
    } = props
    const { t, tmarkdown } = useLocaleContext()

    const className = classnames(
        'c-release-social-delivery', {
            'c-release-is-opting-in': release.isOptingIn,
        }
    )

    return (
        <div className={className}>
            <h4>{ t('componentReleaseSocialDeliveryHeading')}</h4>
            <div dangerouslySetInnerHTML={{ __html: tmarkdown('componentReleaseSocialDeliveryTerms') }} />
            <div className="c-release-social-delivery-terms-inner">
                <div className="c-release-social-delivery-terms">
                    <div className="c-release-social-delivery-terms-control">
                        <Checkbox
                            ref={checkboxRef}
                            checked={isTermsChecked}
                            onChange={onTermsCheck}
                            label={(
                                <span>
                                    { t('containerSocialDeliveryLabelTerms')}&nbsp;
                                </span>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="c-release-social-delivery-actions">
                <RenderSocialIcons />
                <Button
                    onClick={onSocialDeliveryRelease}
                    disabled={!isTermsChecked}
                >
                    {t('containerSocialDeliveryButtonPublish')}
                </Button>
            </div>
            <RenderError release={release} />
            <RenderOptingInSpinner isReleaseOptingIn={release.isOptingIn} />
        </div>
    )
}

RenderDefault.propTypes = {
    checkboxRef: PropTypes.object.isRequired,
    isTermsChecked: PropTypes.bool.isRequired,
    onTermsCheck: PropTypes.func.isRequired,
    onSocialDeliveryRelease: PropTypes.func.isRequired,
    release: PropTypes.object.isRequired,
}

const RenderSocialIcons = () => (
    <div className="c-release-social-delivery-actions-socials">
        <FontAwesomeIcon
            id="icon-facebook"
            icon="faFacebookSquare"
            color="#4267B2"
        />
        <FontAwesomeIcon
            id="instagram-logo"
            icon="faInstagram"
            className="colourised"
        />
        <Icon
            id="tiktok"
            size={32}
            width={32}
            height={32}
        />
    </div>
)

const RenderOptingInSpinner = (props) => {
    const {
        isReleaseOptingIn,
    } = props

    if (!isReleaseOptingIn) {
        return null
    }

    return (
        <div className="c-release-opting-in">
            <Spinner
                className="c-release-opting-in-spinner"
                size="medium"
            />
        </div>
    )
}

RenderOptingInSpinner.propTypes = {
    isReleaseOptingIn: PropTypes.bool,
}

const RenderError = ({ release }) => {
    const { tmarkdown } = useLocaleContext()
    if (release.errors.socialDeliveryError) {
        return (
            <Alert type="fatal-error" colourise>
                <div dangerouslySetInnerHTML={{ __html: tmarkdown('componentReleaseSocialDeliveryError') }} />
            </Alert>
        )
    } else {
        return null
    }
}

RenderError.propTypes = {
    release: PropTypes.object.isRequired,
}

const RenderDelivered = () => {
    const { t, tmarkdown } = useLocaleContext()

    return (
        <div className="c-release-social-delivered">
            <h4>{ t('componentReleaseSocialDeliveredHeading')}</h4>
            <div dangerouslySetInnerHTML={{ __html: tmarkdown('componentReleaseSocialDelivered') }} />
            <div className="c-release-social-delivered-actions">
                <RenderSocialIcons />
            </div>
        </div>
    )
}

const SocialDelivery = (props) => {
    const [isTermsChecked, setTermsChecked] = useState(
        false
    )

    const {
        onSocialDeliveryRelease,
        release,
    } = props

    const checkboxRef = React.createRef()

    const onTermsCheck = () => {
        const checkbox = checkboxRef.current.getValue()

        setTermsChecked(!!checkbox)
    }

    return (
        release.socialOptedinAt
            ? (
                <RenderDelivered />
            )
            : (
                <RenderDefault
                    checkboxRef={checkboxRef}
                    isTermsChecked={isTermsChecked}
                    onTermsCheck={onTermsCheck}
                    onSocialDeliveryRelease={onSocialDeliveryRelease}
                    release={release}
                />
            )
    )
}

SocialDelivery.propTypes = {
    onSocialDeliveryRelease: PropTypes.func.isRequired,
    release: PropTypes.object.isRequired,
}

export default SocialDelivery
