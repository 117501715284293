import { connect } from 'react-redux'

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { setWith, clone } from 'lodash'

import TranslatableModal from 'components/TranslatableModal/TranslatableModal'
import t from 'utilities/translate'
import { translationLanguages } from 'constants/enums/TranslationLanguageEnums'
import {
    getModalId,
    hideModal,
} from 'modules/modal'

class TitleTranslationModalManager extends Component {
    static propTypes = {
        modalId: PropTypes.string,
        data: PropTypes.object,
        translations: PropTypes.array,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
    }

    initialTranslationValues = () => {
        const { data, translations } = this.props
        const unsetTranslations = data.value.reduce((allTranslations, currentValue, index) => {
            const titleTranslations = translationLanguages.reduce((translationObject, translationLanguage) => (
                {
                    ...translationObject,
                    [translationLanguage]: { id: null, value: null },
                }
            ), {})
            return {
                ...allTranslations,
                [index]: titleTranslations,
            }
        }, [])
        const populatedTranslations = clone(unsetTranslations)
        if (translations) {
            translations.forEach((translation) => {
                setWith(populatedTranslations, `0.${translation.language}.value`, translation.value, clone)
            })
        }
        // Prevent propTypes error. unsetTranslations is an array, but Translatable modal expects object...
        return translations.length > 0 ? populatedTranslations : {}
    }

    render() {
        const {
            modalId,
            data,
            onClose,
            onSave,
            translations,
        } = this.props

        let component = null

        switch (modalId) {
            case 'translatable-track-title-input': {
                component = (
                    <TranslatableModal
                        modalId={modalId}
                        title={t('translationModalHeaderTrackTitle')}
                        value={data.value}
                        fieldName="titleTranslations"
                        headerTooltipTitle={t('translationModalTrackToolTipText')}
                        headerTooltipText={t('translationModalTrackToolTipBullet')}
                        onClose={onClose}
                        onSave={onSave}
                        translations={translations}
                        initialTranslationValues={this.initialTranslationValues()}
                    />
                )
                break
            }
            case 'translatable-release-title-input': {
                component = (
                    <TranslatableModal
                        modalId={modalId}
                        title={t('translationModalHeaderReleaseTitle')}
                        value={data.value}
                        fieldName="titleTranslations"
                        headerTooltipTitle={t('translationModalReleaseToolTipText')}
                        headerTooltipText={t('translationModalReleaseToolTipBullet')}
                        onClose={onClose}
                        onSave={onSave}
                        translations={translations}
                        initialTranslationValues={this.initialTranslationValues()}
                    />
                )
                break
            }
            default:
                break
        }

        return component
    }
}

const mapStateToProps = state => ({
    modalId: getModalId(state),
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TitleTranslationModalManager)
