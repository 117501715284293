import { connect } from 'react-redux'

import { fetchOverviewTrends, toggleOverviewVisibility } from 'actions/TrendActions'
import {
    makeDspDataSelector,
    makeDspIconSelector,
    makeFilterOptionsSelector,
    makeLabelsSelector,
    TYPE_OVERVIEW,
} from 'selectors/trends'
import TrendsStats from 'components/TrendsStats/TrendsStats'

const mapStateToProps = state => ({
    artistId: state.users.user.artist.id,
    isLoading: !!state.trends.overview.dsps.isFetching,
    failedFetching: !!state.trends.overview.dsps.failedFetching,

    filterOptions: makeFilterOptionsSelector(TYPE_OVERVIEW)(state),
    data: makeDspDataSelector(TYPE_OVERVIEW)(state),
    icons: makeDspIconSelector(TYPE_OVERVIEW)(state),
    labels: makeLabelsSelector(TYPE_OVERVIEW)(state),
})

const mapDispatchToProps = {
    fetchOverviewTrends,
    toggleOverviewVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendsStats)
