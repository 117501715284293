import React from 'react'
import * as PropTypes from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

const SongwriterPublisher = ({
    publisher,
    deleteHandler,
    copyHandler,
    showCopy,
}) => (
    <div className="c-fc-songwriter-publisher">
        <div className="c-fi-songwriter">{publisher.person.name}</div>
        <div className="c-fi-publisher">{publisher.publishingHouse || '-'}</div>
        <div className="c-fi-actions">
            {
                (showCopy)
                    ? (
                        <span
                            onClick={copyHandler}
                        >
                            <FontAwesomeIcon
                                icon="faCopy"
                                className="publishers-copy-btn"
                            />
                        </span>
                    )
                    : null
            }
        </div>
        <div className="c-fi-actions">
            <span
                onClick={deleteHandler}
            >
                <FontAwesomeIcon icon="faTrash" className="publishers-remove-btn" />
            </span>
        </div>
    </div>
)

SongwriterPublisher.propTypes = {
    publisher: PropTypes.object.isRequired,
    copyHandler: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    showCopy: PropTypes.bool.isRequired,
}

export default SongwriterPublisher
