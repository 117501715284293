import { connect } from 'react-redux'

import { forgot } from 'actions/ResetActions'

import ForgotForm from 'components/ForgotForm/ForgotForm'

const mapStateToProps = state => ({
    isLoading: state.reset.isRequesting,
    errors: state.reset.errors,
})

const mapDispatchToProps = {
    forgot,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotForm)
