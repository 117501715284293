import Dialog from 'components/common/modals/Dialog'
import { confirmationPopup } from 'constants/PopupTypes'
import * as popupButtons from 'constants/PopupButtons'

const QUEUE_MODAL = 'QUEUE_MODAL'
const HIDE_MODAL = 'HIDE_MODAL'

export const modalActionTypes = {
    QUEUE_MODAL,
    HIDE_MODAL,
}

const defaultModalConfig = {
    useBackdrop: false,
    clickBackdropToClose: false,
}

export const queueModal = (
    modalId,
    modalComponent,
    modalProps,
    modalConfig = defaultModalConfig
) => ({
    type: QUEUE_MODAL,
    payload: {
        modalId,
        modalComponent,
        modalProps,
        ...Object.assign(defaultModalConfig, modalConfig),
    },
})

export const queueConfirmationModal = (
    modalId,
    title,
    text,
    onConfirm,
    onConfirmButtonText = popupButtons.ok,
    onCloseButtonText = popupButtons.cancel
) => dispatch => dispatch(queueModal(
    modalId,
    Dialog,
    {
        type: confirmationPopup,
        title,
        text,
        acceptButtonText: onConfirmButtonText,
        closeButtonText: onCloseButtonText,
        onAccept: onConfirm,
    },
    {
        useBackdrop: true,
        clickBackdropToClose: true,
    }
))

export const hideModal = modalId => ({
    type: HIDE_MODAL,
    payload: {
        modalId,
    },
})
