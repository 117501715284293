import { connect } from 'react-redux'
import { reset } from 'actions/ResetActions'

import ResetForm from 'components/ResetForm/ResetForm'

const mapStateToProps = state => ({
    isLoading: state.reset.isReseting,
    errors: state.reset.errors,
})

const mapDispatchToProps = {
    reset,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm)
