import { timeSince } from 'utilities/date'
import * as types from 'constants/ReleaseTypeActionTypes'
import { CALL_API } from 'middleware/api'

const fetchTime = 30 * 60 * 1000

// FETCH

function getReleaseTypes() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_RELEASE_TYPES_REQUEST,
                types.FETCH_RELEASE_TYPES_SUCCESS,
                types.FETCH_RELEASE_TYPES_FAILURE,
            ],
            payload: {
                endpoint: 'my/releasetypes',
                query: {
                    part: 'price',
                },
            },
        },
    }
}

export function fetchReleaseTypes(userCountryChanged = false) {
    return (dispatch, getState) => (!!userCountryChanged || timeSince(getState().releaseTypes.fetchedAt) >= fetchTime)
        && dispatch(getReleaseTypes())
}
