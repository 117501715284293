import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { Label } from 'components/EditProfileInputs/Inputs'
import Spinner from 'components/Spinner/Spinner'
import t from 'utilities/translate'

class ColorPicker extends Component {
    static propTypes = {
        solids: PropTypes.array.isRequired,
        gradients: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        loadingFailed: PropTypes.bool.isRequired,
        input: PropTypes.object.isRequired,
        fetchBackgroundColors: PropTypes.func.isRequired,
        initialGradientsVisibility: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            areGradientsVisible: this.props.initialGradientsVisibility,
        }
    }

    componentDidMount() {
        this.props.fetchBackgroundColors()
    }

    setGradientsVisible(areGradientsVisible) {
        this.setState({
            areGradientsVisible,
        })
    }

    renderColors = renderOption => (colors) => {
        const { input } = this.props
        return (
            colors
            && colors.map(color => (
                <div className="c-color-picker-option" key={color.id} onClick={() => input.onChange(color.id)}>
                    {renderOption(color)}
                    {this.renderActiveIcon(color.id)}
                </div>
            ))
        )
    }

    renderSolidOption = color => (
        <div>
            <div className="c-color-picker-option-dark" style={{ background: color.darkerHex }} />
            <div className="c-color-picker-option-light" style={{ background: color.lighterHex }} />
        </div>
    )

    renderGradientOption = color => (
        <div
            className="c-color-picker-option-gradient"
            style={{
                backgroundColor: color.lighterHex,
                backgroundImage: `linear-gradient(${color.lighterHex}, ${color.darkerHex})`,
            }}
        />
    )

    renderSolidColors = this.renderColors(this.renderSolidOption)

    renderGradientColors = this.renderColors(this.renderGradientOption)

    renderActiveIcon = colorId => colorId === +this.props.input.value && (
        <div className="c-color-picker-option-active">
            <FontAwesomeIcon icon="faCheckCircle" />
        </div>
    )

    render() {
        const { areGradientsVisible } = this.state
        const {
            solids, gradients, isLoaded, loadingFailed,
        } = this.props
        const shouldDisplaySpinner = !isLoaded && !loadingFailed

        const buttonBaseClass = 'c-color-picker-tabs-button'
        const buttonActiveClass = `${buttonBaseClass}--active`

        const gradientButtonClass = `${buttonBaseClass} ${areGradientsVisible ? buttonActiveClass : ''}`
        const solidButtonClass = `${buttonBaseClass} ${!areGradientsVisible ? buttonActiveClass : ''}`

        return (
            <div>
                <Label label={t('formEditProfileBackgroundLabel')} />
                <p>{t('formEditProfileBackgroundDescription')}</p>

                <ul className="c-color-picker-tabs">
                    <li className="c-color-picker-tabs-item">
                        <button
                            type="button"
                            onClick={() => this.setGradientsVisible(true)}
                            className={gradientButtonClass}
                        >
                            {t('componentColorPickerGradientsColours')}
                        </button>
                    </li>
                    <li className="c-color-picker-tabs-item">
                        <button
                            type="button"
                            onClick={() => this.setGradientsVisible(false)}
                            className={solidButtonClass}
                        >
                            {t('componentColorPickerSolidColours')}
                        </button>
                    </li>
                </ul>

                <div className="c-color-picker">
                    {areGradientsVisible && this.renderGradientColors(gradients)}
                    {!areGradientsVisible && this.renderSolidColors(solids)}
                    {shouldDisplaySpinner && (
                        <div className="c-color-picker-spinner">
                            <Spinner size="medium" />
                        </div>
                    )}
                    {loadingFailed && <p>{t('colorPickerLoadingFailed')}</p>}
                </div>
            </div>
        )
    }
}

export default ColorPicker
