import { debounce } from 'lodash'
import t from 'utilities/translate'
import validate from 'utilities/validate'
import * as types from 'constants/TrackActionTypes'
import { validateAudioFile } from 'utilities/audio'
import { errors, warnings } from 'rules/track'
import { getAlbum, updateAlbum } from 'actions/AlbumActions'
import { AppStoreDispatch, AppStoreState } from 'store/store-types'
import { AnyAction } from 'redux'
import Album from 'models/album'
import {
    abortChunkedUploadApi, callApi, downloadApi, uploadChunkedApi,
} from 'actions/ApiActions'
import { ITrack } from 'models/track'

export const postFile = (albumId: string, trackId: string, file: any) => uploadChunkedApi(
    [
        types.UPLOAD_FILE_REQUEST,
        types.UPLOAD_FILE_SUCCESS,
        types.UPLOAD_FILE_FAILURE,
        types.UPLOAD_FILE_PROGRESS,
    ], {
        method: 'post',
        endpoint: `dsp/product/${albumId}/track/${trackId}/audio`,
        file,
    }, {
        id: trackId,
        uploadId: `track-audio-${trackId}`,
        albumId,
    }
)

export const abortFile = (id: string) => abortChunkedUploadApi(
    types.UPLOAD_FILE_ABORT,
    { abort: true },
    {
        id,
        uploadId: `track-audio-${id}`,
    }
)

export const getTrackFile = (albumId: string, trackId: string) => downloadApi(
    [
        types.DOWNLOAD_TRACK_FILE_REQUEST,
        types.DOWNLOAD_TRACK_FILE_SUCCESS,
        types.DOWNLOAD_TRACK_FILE_FAILURE,
    ], {
        method: 'get',
        endpoint: `dsp/product/${albumId}/track/${trackId}/audioRaw`,
    }, {
        trackId,
        albumId,
    }
)

// CREATE
export const uploadFile = (albumId: string, trackId: string, file: any) => async (dispatch: AppStoreDispatch) => {
    let result
    const validationResult = await validateAudioFile(file)

    if (!validationResult.success) {
        result = dispatch({
            type: types.UPLOAD_FILE_FAILURE,
            error: [t('ERROR_INVALID_AUDIO_FILE_TYPE')],
            meta: { id: trackId },
        })
    } else {
        result = dispatch(postFile(albumId, trackId, file)).then(() => dispatch(getAlbum(albumId)))
    }

    return result
}

export const postTrack = (albumId: string, originalFilename: string) => callApi(
    [
        types.CREATE_TRACK_REQUEST,
        types.CREATE_TRACK_SUCCESS,
        types.CREATE_TRACK_FAILURE,
    ], {
        method: 'post',
        endpoint: `dsp/product/${albumId}/track`,
        body: {},
    },
    { albumId, originalFilename }
)

export const putTrack = (
    albumId: string,
    id: string,
    {
        title,
        recordingVersionId,
        explicitLyrics,
        artists,
    }: Partial<ITrack>
) => callApi(
    [
        types.UPDATE_TRACK_REQUEST,
        types.UPDATE_TRACK_SUCCESS,
        types.UPDATE_TRACK_FAILURE,
    ], {
        method: 'PUT',
        endpoint: `dsp/product/${albumId}/track/${id}`,
        body: {
            title,
            recordingVersionId,
            explicitLyrics,
            artists,
        },
    }, {
        albumId,
        id,
    }
)

export function updateTrack(id: string, body: Partial<ITrack>) {
    return (dispatch: AppStoreDispatch, getState: () => AppStoreState) => {
        const track = getState().tracks.tracks[id]
        const album = Object.values(getState().albums.albums).find(a => a.tracksOrder.indexOf(id) !== -1)
        const newBody = { ...track, ...body }

        return dispatch(putTrack(album?.id ?? '', id, newBody))
    }
}

const deleteTrack = (albumId: string, id: string) => callApi(
    [
        types.REMOVE_TRACK_REQUEST,
        types.REMOVE_TRACK_SUCCESS,
        types.REMOVE_TRACK_FAILURE,
    ], {
        method: 'delete',
        endpoint: `dsp/product/${albumId}/track/${id}`,
    }, {
        id,
        albumId,
    }
)

export function removeTrack(album: Album, id: string) {
    return (dispatch: AppStoreDispatch) => {
        if (album.tracks.length === 2) {
            // eslint-disable-next-line no-shadow
            album.tracks.forEach((track) => {
                if (track.id !== id) {
                    dispatch(updateAlbum(album.id, {
                        ...album,
                        title: track.title,
                    }))
                }
            })
        }
        dispatch(deleteTrack(album.id, id))
    }
}

export function validateTrack(id: string, body = {}) {
    return (dispatch: AppStoreDispatch, getState: () => AppStoreState) => {
        const appState = getState()
        const trackEntities = appState.tracks.tracks
        const track = trackEntities[id]

        if (track) {
            const tracks = Object.values(trackEntities).filter(currentTrack => currentTrack.albumId === track.albumId)
            const fields = { ...track, ...body, tracks }

            return dispatch({
                type: types.VALIDATE_TRACK,
                payload: {
                    errors: validate(fields, errors, appState),
                    warnings: validate(fields, warnings, appState),
                },
                meta: { id },
            })
        }
        return null
    }
}

export function validateTracks(ids: string[]) {
    return (dispatch: AppStoreDispatch) => Promise.all(ids.map(id => dispatch(validateTrack(id))))
}

const debouncedValidateAndUpdateTrack = debounce(
    (id, body, dispatch, getState: () => AppStoreState) => dispatch(updateTrack(id, body))
        .then(() => {
            const track = getState().tracks.tracks[id]
            const album = getState().albums.albums[track.albumId]
            const albumTrackIds = album.tracks.map(currentTrack => currentTrack.id)

            // not fan of returning something in an async action creator, an action should be just "fire and forget"
            // it's one of the reason I'm not fan of redux-thunk, because it encourgae this practice
            return Promise.all(
                albumTrackIds.map(
                    (currentAlbumTrackId) => {
                        // we should validate the track with the latest modification
                        // displayed on the screen (component state)
                        const currentBody = id === currentAlbumTrackId ? body : {}
                        return dispatch(validateTrack(currentAlbumTrackId, currentBody))
                    }
                )
            )
        }),
    300
)

export function validateAndUpdateTrack(id: string, body: any) {
    return debouncedValidateAndUpdateTrack.bind(null, id, body)
}

export function createTracks(albumId: string, files: any[]) {
    return (dispatch: AppStoreDispatch, getState: () => AppStoreState) => {
        dispatch({ type: types.CREATE_TRACKS })

        const successfulFiles: { trackId: string, file: any }[] = []

        const trackCreationPromise = files.reduce((promise, file) => promise
            .then(() => dispatch(postTrack(albumId, file.name))
                .then((action) => {
                    if (action.type === types.CREATE_TRACK_SUCCESS) {
                        successfulFiles.push({
                            trackId: action.payload.id,
                            file,
                        })
                    }

                    return Promise.resolve()
                })), Promise.resolve())

        trackCreationPromise
            .then(() => dispatch(getAlbum(albumId)))
            .then(() => dispatch({ type: types.CREATE_TRACKS_SUCCESS }))
            .then(() => Promise.all(
                successfulFiles.map(file => dispatch(uploadFile(albumId, file.trackId, file.file)))
            ))
            .then(() => {
                let result
                const { tracks } = getState().albums.albums[albumId]

                if (tracks) {
                    dispatch(validateTracks(tracks.map(track => track.id)))
                }

                return result
            })
    }
}

// SELECT

export function selectTrack(id: string): AnyAction {
    return {
        type: types.SELECT_TRACK,
        payload: id,
    }
}
