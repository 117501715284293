import { connect } from 'react-redux'
import { removeGallery, reorderGalleryImages } from 'actions/ProfileActions'
import { addPopup } from 'actions/PopupActions'
import EditProfileGallery from 'components/EditProfileGallery/EditProfileGallery'

const mapStateToProps = state => ({
    gallery: state.draftProfile.data.files.gallery,
    isProcessingForm: state.draftProfile.isSubmitting || state.draftProfile.isPublishing,
    error: state.draftProfile.galleryError,
})

const mapDispatchToProps = dispatch => ({
    removeGalleryImage: groupId => dispatch(removeGallery(groupId)),
    reorderGalleryImages: gallery => dispatch(reorderGalleryImages(gallery)),
    openPopup: popup => dispatch(addPopup(popup)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileGallery)
