import React from 'react'

import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import VerificationResendButton from 'components/VerificationResendButton/VerificationResendButton'
import { userSelector } from 'selectors/users'
import { addDaysToDate, formatDateLocalised } from 'utilities/date'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

VerificationMessage.propTypes = {
    user: PropTypes.object.isRequired,
    isShortMessageOnly: PropTypes.bool,
}

function VerificationMessage(props) {
    const {
        user,
        isShortMessageOnly,
    } = props

    const { t } = useLocaleContext()
    const expiryDate = formatDateLocalised(
        addDaysToDate(
            user.verificationStartedAt,
            window.env.SPINNUP_EMAIL_VERIFICATION_TIMEOUT
        ),
        'DD/MM/YYYY'
    )

    const shortTextClasses = classnames(
        'c-ver-message-text',
        {
            'd-inline-block': !isShortMessageOnly,
            'd-sm-none': !isShortMessageOnly,
        }
    )

    return (
        <div className="c-ver-message">
            {!isShortMessageOnly
                ? (
                    <p className="c-ver-message-text d-none d-sm-inline-block">
                        {t('verificationMessageInstructions', user.email, expiryDate)}
                    </p>
                )
                : null }
            <p className={shortTextClasses}>
                {t('verificationMessageInstructionsShort', expiryDate)}
            </p>
            <VerificationResendButton />
        </div>
    )
}

const mapStateToProps = state => ({
    user: userSelector(state),
})

export default connect(mapStateToProps, null)(VerificationMessage)
