// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { createSelector } from 'reselect'

export const KEY = 'menus'

// ///////////
// REDUCERS
// ///////////
const initialPrimaryWPSiteLinks = [
    {
        hrefKey: 'help',
        label: 'layoutEditReleaseNavHelp',
    },
    {
        hrefKey: 'publicWebsiteBlog',
        label: 'containerLoggedOutPrimaryMenuBlog',
    },
]

const initialLoggedInPrimaryWPSiteLinks = [
    {
        hrefKey: 'help',
        label: 'layoutEditReleaseNavHelp',
    },
]

const initialPrimaryAppLinks = (invited = true) => {
    const overviewOption = invited ? {
        to: '/overview',
        label: 'containerMainMenuNavOverview',
    } : null

    const profileOption = window.env.SPINNUP_ARTIST_SITE_CMS_ENABLED && invited
        ? {
            to: '/profile',
            label: 'containerMainMenuNavProfile',
        }
        : null

    const trendsOption = invited ? {
        to: '/trends',
        label: 'containerMainMenuNavStats',
    } : null

    const menuOptions = [
        overviewOption,
        {
            to: '/releases',
            label: 'containerMainMenuNavReleases',
        },
        trendsOption,
        {
            to: '/balance',
            label: 'containerMainMenuNavBalance',
        },
        profileOption,
        {
            to: '/settings',
            label: 'containerMainMenuNavSettings',
        },
    ]

    return menuOptions.filter(menuOption => !!menuOption)
}

const initialState = {
    menus: {
        loggedOut: {
            primary: {
                enhancedLinks: initialPrimaryWPSiteLinks,
                offboardedLinks: initialPrimaryWPSiteLinks,
                auth: [
                    {
                        to: '/start/register',
                        label: 'containerLoggedOutPrimaryMenuSignUp',
                    },
                    {
                        to: '/start',
                        label: 'containerLoggedOutPrimaryMenuLogin',
                    },
                ],
            },
            secondary: [],
        },
        loggedIn: {
            primary: {
                mobile: {
                    enhancedLinks: [
                        ...initialPrimaryAppLinks(),
                        ...initialLoggedInPrimaryWPSiteLinks,
                    ],
                    offboardedLinks: [
                        ...initialPrimaryAppLinks(false),
                        ...initialLoggedInPrimaryWPSiteLinks,
                    ],
                    auth: [
                        {
                            label: 'containerLoggedOutPrimaryMenuLogout',
                            action: 'logout',
                        },
                    ],
                },
                desktop: {
                    enhancedLinks: initialLoggedInPrimaryWPSiteLinks,
                    offboardedLinks: initialLoggedInPrimaryWPSiteLinks,
                    auth: [

                        {
                            label: 'containerLoggedOutPrimaryMenuLogout',
                            action: 'logout',
                        },
                    ],
                },
            },
            secondary: {
                mobile: [],
                desktop: {
                    enhancedLinks: initialPrimaryAppLinks(),
                    offboardedLinks: initialPrimaryAppLinks(false),
                },
            },
        },
    },
}

export default (state = initialState) => state

// ///////////
// SELECTORS
// ///////////
export const getMenus = state => state[KEY].menus

export const getMenu = ({
    isAuthenticated,
}) => createSelector(
    getMenus,
    menus => menus[isAuthenticated ? 'loggedIn' : 'loggedOut']
)
