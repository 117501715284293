import t from 'utilities/translate'
import { formatArtists } from './artists'

export function formatTrackTitle(title, artists, version) {
    const formatedTitle = [
        title || t('selectorTracksUntitledTrack'),
    ]

    if (version && version.id && parseInt(version.id, 10) !== 1) {
        formatedTitle.push(version.name)
    }

    if (artists && artists.length > 0) {
        formatedTitle.push(`feat. ${formatArtists(artists)}`)
    }

    return [
        formatedTitle[0],
        formatedTitle[1] ? `(${formatedTitle[1]})` : '',
        formatedTitle[2] ? `[${formatedTitle[2]}]` : '',
    ].join(' ')
}
