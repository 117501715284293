import moment from 'moment'
import { device as d } from 'device.js'
import { getFullLanguage } from 'utilities/translate'

export function formatDateLocalised(date, format = 'LL') {
    const locale = getFullLanguage()
    return moment(date).locale(locale).format(format)
}

export function formatDateUTC(date, format = 'LL') {
    return getDateUTC(date).format(format)
}

export function timeSince(date) {
    const t2 = (new Date()).getTime()
    const t1 = (new Date(date)).getTime()

    return t2 - t1
}

export function isExpired(expiresAt) {
    return moment(expiresAt) - moment() <= 0
}

export function isExpiredUTC(expiresAt) {
    return moment.unix(expiresAt).utc() - moment(new Date()).utc() <= 0
}

export function chartLabels(filter, value, index, labels, short = d.mobile) {
    const date = moment(value, 'DD MMMM YYYY')
    const gridEnd = (index === labels.length - 1 || index === 0)

    switch (filter) {
        case 7:
        case '7':
        case 'week':
        case 'days':
            return date.format('ddd')

        case 30:
        case '30':
        case 'month':
            if (gridEnd) {
                return short ? date.format('DD M') : date.format('DD MMM')
            }
            return index % 2 === 1 ? date.format('DD') : null

        case 90:
        case '90':
            return short ? date.format('DD M') : date.format('DD MMM')

        case 365:
        case 'year':
            return date.format('MMM')

        default:
            return short ? date.format('dd') : date.format('dddd')
    }
}

export function addDaysToDate(date, days) {
    const fromDate = new Date(moment.utc(date).toISOString(true))
    return fromDate.setDate(fromDate.getDate() + parseInt(days, 10))
}

export function removeDaysToDate(date, days) {
    const fromDate = new Date(moment.utc(date).toISOString(true))
    return fromDate.setDate(fromDate.getDate() - parseInt(days, 10))
}

export function getDateUTC(date) {
    return moment(date).utc()
}
