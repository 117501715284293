import { Record } from 'immutable'

const MailingList = new Record({
    id: null,
    providerId: null,
    identifier: null,
    subscribed: null,
    isDoubleOptInPending: null,
})

export function hydrateModel(data) {
    return new MailingList({
        id: data.id,
        providerId: data.providerId,
        identifier: data.identifier,
        isDoubleOptInPending: data.isDoiPending,
    })
}

export default MailingList
