import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import t from 'utilities/translate'

/**
 * TrackList
 *
 * A tracklist
 *
 * Styleguide: components.tracklist
 */

export default class TrackList extends Component {
    static propTypes = {
        /** Headings */
        headings: PropTypes.array,

        /** Tracks */
        children: PropTypes.node,

        /** Max visible tracks */
        maxVisibleTracks: PropTypes.number,

        withTrackPosition: PropTypes.bool,
        withAudioPlayer: PropTypes.bool,
    };

    static defaultProps = {
        withTrackPosition: false,
        maxVisibleTracks: 30,
    };

    constructor(props) {
        super(props)

        this.state = { isExpanded: false }
    }

    onExpand = () => {
        this.setState(prevState => ({ isExpanded: !prevState.isExpanded }))
    }

    render() {
        const {
            headings,
            children,
            withTrackPosition,
            withAudioPlayer,
            maxVisibleTracks,
        } = this.props

        const { isExpanded } = this.state

        const newChildren = React.Children.toArray(children)
        const visibleTracks = isExpanded && newChildren.length || maxVisibleTracks
        const hasMore = newChildren.length > maxVisibleTracks

        return (
            <div className="c-track-list">
                <table>
                    {headings && (
                        <thead>
                            <tr>
                                {withAudioPlayer && <th className="c-track-list-col-audio" aria-label="audio-player" />}
                                {withTrackPosition && <th className="c-track-list-col-pos">#</th>}

                                {headings.map((heading, index) => <th key={index}>{heading}</th>)}
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {newChildren.slice(0, visibleTracks).map((child, index) => React.cloneElement(child, {
                            position: index + 1,
                            withTrackPosition,
                        }))}
                    </tbody>
                </table>
                {hasMore
                    && (
                        <a className="c-track-list-expand-collapse" onClick={this.onExpand}>
                            {t(isExpanded ? 'componentTrackListShowLess' : 'componentTrackListShowMore')}
                        </a>
                    )}
            </div>
        )
    }
}
