import React from 'react'
import Button from 'components/Button/Button'
import OverviewProfilePreview from 'components/OverviewProfile/OverviewProfilePreview'
import t, { tmarkdown } from 'utilities/translate'

const OverviewProfileNoDraft = () => (
    <div className="c-overview-profile-wrapper">
        <div className="c-overview-profile-message">
            <h3 className="c-overview-profile-title">
                {t('componentOverviewProfileNoDraftTitle')}
            </h3>
            <div className="c-overview-profile-description">
                <div dangerouslySetInnerHTML={{ __html: tmarkdown('componentOverviewProfileNoDraftDescription') }} />
            </div>
            <div className="c-overview-profile-button-wrapper">
                <Button href="/profile" className="c-overview-profile-button">
                    {t('componentOverviewProfileNoDraftButton')}
                </Button>
            </div>
        </div>
        <OverviewProfilePreview />
    </div>
)

export default OverviewProfileNoDraft
