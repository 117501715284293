type UploadParams = {
    rootUrl: string
    endpoint: string
    body: any
    accessToken: string
    onProgress: (progress: number) => void,
    ref?: string
}

const refs: Record<string, XMLHttpRequest> = {}

export function abortUpload(ref: string) {
    if (refs[ref]) {
        refs[ref].abort()
    }
}

const func = ({
    rootUrl = window.location.origin,
    endpoint,
    body,
    accessToken,
    onProgress,
    ref,
}: UploadParams) => {
    const url = rootUrl + endpoint
    const xhr = new XMLHttpRequest()

    if (ref) {
        refs[ref] = xhr
    }

    xhr.open('post', url)
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`)

    return new Promise<any>((resolve, reject) => {
        xhr.upload.onprogress = (response) => {
            const progress = Math.round(response.loaded / response.total * 100)

            onProgress(progress)
        }

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response)
            } else {
                reject(xhr.response)
            }
        }

        xhr.onerror = () => {
            reject(xhr.response)
        }

        xhr.onabort = () => {
            reject(xhr.response)
        }

        xhr.send(body)
    })
}

export default func
