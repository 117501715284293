import React from 'react'
import { bool, func } from 'prop-types'
import classnames from 'classnames'

const Hamburger = ({
    isOpen,
    onClick,
}) => (
    <div
        className={classnames(
            'c-hamburger', {
                expanded: isOpen,
            }
        )}
        onClick={onClick}
    >
        <span />
        <span />
        <span />
        <span />
    </div>
)

Hamburger.propTypes = {
    isOpen: bool.isRequired,
    onClick: func.isRequired,
}

export default Hamburger
