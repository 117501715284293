import { OrderedMap } from 'immutable'
import * as types from 'constants/CountryActionTypes'
import { hydrateCountry } from 'utilities/hydrator'

const initialState = {
    isFetching: false,
    countries: new OrderedMap(),
    fetchedAt: 0,
}

export default function countries(state = initialState, action) {
    switch (action.type) {
        // FETCH

        case types.FETCH_COUNTRIES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                countries: state.countries.merge(
                    action.payload.reduce((map, country) => ({
                        ...map,
                        [country.id]: hydrateCountry(country),
                    }), {})
                ),
                fetchedAt: new Date(),
            }

        case types.FETCH_COUNTRIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            }

            // DEFAULT

        default:
            return state
    }
}
