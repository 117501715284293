import { getErrors } from 'rules/audio'

export async function validateAudioFile(file) {
    return new Promise((resolve) => {
        if (file.type.match(/^audio\/((x-)?wave?|(x-)?flac)$/) === null) {
            resolve({
                fileName: file.name,
                errors: ['audioUploadErrorIncorrectFileType'],
                result: file.type,
                success: false,
            })
        }

        const fileReader = new FileReader()
        fileReader.onerror = (err) => {
            resolve({
                fileName: file.name,
                errors: ['audioUploadErrorUnableToReadFile'],
                result: err,
                success: false,
            })
        }

        fileReader.onloadend = () => {
            const buffer = fileReader.result

            const audioCtx = new (window.AudioContext || window.webkitAudioContext)({
                sampleRate: 44100,
            })
            audioCtx.decodeAudioData(buffer, (result) => {
                const errors = getErrors(result)
                resolve({
                    fileName: file.name,
                    errors,
                    result: {
                        duration: result.duration,
                        length: result.length,
                        numberOfChannels: result.numberOfChannels,
                    },
                    success: errors.length === 0,
                })
                audioCtx.close()
            }, (err) => {
                resolve({
                    fileName: file.name,
                    errors: ['audioUploadErrorUnableToDecode'],
                    result: err.message || '',
                    success: false,
                })
                audioCtx.close()
            })
        }
        fileReader.readAsArrayBuffer(file)
    })
}

export async function* validateAudioFiles(fileBlobs) {
    for (let i = 0; i < fileBlobs.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        yield await validateAudioFile(fileBlobs[i])
    }
}
