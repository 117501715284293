import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { tmarkdown } from 'utilities/translate'

/**
 * Countdown
 * - takes a number and counts down to zero
 * TODO: add a handler
 */
export default class Countdown extends Component {
    static propTypes = {
        start: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        tickspeed: PropTypes.number.isRequired,
        template: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    constructor(props) {
        super(props)

        this.state = {
            remaining: parseInt(props.start, 10),
            interval: parseInt(props.tickspeed, 10),
        }

        this.intervalHandle = null
    }

    componentDidMount() {
        this.startTicking()
    }

    componentWillUnmount() {
        this.stopTicking()
    }

    startTicking = () => {
        const { interval } = this.state
        this.intervalHandle = setInterval(this.tick, interval)
    }

    stopTicking = () => {
        clearInterval(this.intervalHandle)
    }

    tick = () => {
        this.setState((prevState) => {
            const tickUnit = prevState.interval / 1000
            const newRemain = (prevState.remaining || tickUnit) - tickUnit
            return {
                remaining: (newRemain <= 0) ? 0 : newRemain,
            }
        })
        const { remaining } = this.state
        if (remaining <= 0) {
            this.stopTicking()
        }
    }

    getCounter = () => {
        const { remaining } = this.state
        return remaining
    }

    render() {
        const { template, className } = this.props
        return (
            <div
                className={className}
                dangerouslySetInnerHTML={{
                    __html: tmarkdown(template, this.getCounter()),
                }}
            />
        )
    }
}
