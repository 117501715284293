import { connect } from 'react-redux'

import Artwork from 'components/Artwork/Artwork'

const mapStateToProps = (state, ownProps) => {
    const album = ownProps.album || {}
    const useGenericImage = ownProps.useGenericImage || false

    return {
        useGenericImage,
        credentials: state.auth.credentials,
        url: album.coverArtUrl,
        spotifyUrls: (album.spotify || {}).artwork,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Artwork)
