import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'

import Spinner from 'components/Spinner/Spinner'
import ClipoutText from 'components/Frame/ClipoutText'
import t, { tmarkdown } from 'utilities/translate'

const CrmCompleteDoubleOptIn = (props) => {
    const {
        completeDoiProcess,
        isUpdating,
        failedToUpdate,
    } = props

    useEffect(() => {
        completeDoiProcess()
    }, [])

    if (isUpdating) {
        return <Spinner className="c-crm-complete-doi-spinner" />
    } else if (failedToUpdate) {
        return (
            <div
                className="c-crm-complete-doi-error"
                dangerouslySetInnerHTML={{ __html: tmarkdown('componentCrmCompleteDoubleOptInError') }}
            />
        )
    } else {
        return (
            <ClipoutText type="h1" backgroundImageId="dune-2" className="c-crm-complete-doi-header">
                {t('componentCrmCompleteDoubleOptInHeader')}
            </ClipoutText>
        )
    }
}

CrmCompleteDoubleOptIn.propTypes = {
    completeDoiProcess: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    failedToUpdate: PropTypes.bool.isRequired,
}

export default CrmCompleteDoubleOptIn
