// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { combineEpics } from 'redux-observable'
import { extend } from 'lodash'

const APP_PREFIX = 'spinnup'
export const KEY = 'modal'

// ///////////
// DETERMINISTIC ACTIONS
// ///////////

export const SHOW_MODAL = `${APP_PREFIX}/${KEY}/SHOW_MODAL`
export const HIDE_MODAL = `${APP_PREFIX}/${KEY}/HIDE_MODAL`

// ///////////
// ACTION CREATORS
// ///////////

export const showModal = id => ({
    type: SHOW_MODAL,
    payload: id,
})

export const hideModal = () => ({
    type: HIDE_MODAL,
})

// ///////////
// REDUCERS
// ///////////

const initialState = {
    id: null,
}

export default (
    state = initialState,
    {
        type,
        payload: id,
    }
) => {
    const nextState = extend(
        {},
        state
    )

    switch (type) {
        case SHOW_MODAL:
            extend(
                nextState,
                state,
                {
                    id,
                }
            )
            break
        case HIDE_MODAL:
            extend(
                nextState,
                state,
                {
                    id: null,
                }
            )
            break
        default:

            break
    }

    return nextState
}

// ///////////
// SELECTORS
// ///////////

export const getModalId = state => state[KEY].id

// ///////////
// NON DETERMINISTIC ACTIONS
// ///////////

// ///////////
// ACTION CREATORS
// ///////////

// ///////////
// EPICS
// ///////////

export const epic = combineEpics(
)
