import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import Background from 'components/Frame/Background'

const Frame = ({
    contentTint,
    borderImageId,
    borderFlipVerticaly,
    borderFlipHorizontaly,
    children,
    className,
    contentClassName,
}) => {
    const rootClassname = classnames(
        'c-frame',
        borderImageId,
        className
    )

    const contentClassname = classnames(
        'c-frame-content',
        contentTint,
        contentClassName
    )

    return (
        <div className={rootClassname}>
            <Background
                imageId={borderImageId}
                flipVerticaly={borderFlipVerticaly}
                flipHorizontaly={borderFlipHorizontaly}
            >
                <div className={contentClassname}>
                    {children}
                </div>
            </Background>
        </div>
    )
}

Frame.propTypes = {
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    contentTint: PropTypes.oneOf(['light', 'dark']),
    borderFlipVerticaly: Background.propTypes.flipVerticaly,
    borderFlipHorizontaly: Background.propTypes.flipHorizontaly,
    borderImageId: Background.propTypes.imageId,
}

Frame.defaultProps = {
    contentTint: 'dark',
    borderImageId: Background.defaultProps.imageId,
}

export default Frame
