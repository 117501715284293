import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Button from 'components/Button/Button'
import {
    getPopupDataEditProfileCloseCropPopup,
} from 'utilities/popups'
import t from 'utilities/translate'

function getCroppedImg(image, crop, fileName) {
    if (crop && (crop.height === 0 || crop.width === 0)) {
        crop = {
            ...crop, height: 100, width: 100, unit: '%',
        }
    }
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    )

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            blob.name = fileName
            resolve(blob)
        }, 'image/jpeg', 1)
    })
}
const defaultCrop = {
    unit: '%',
    width: 100,
    height: 100,
}

const EditProfileImageCrop = ({
    src,
    callback,
    id,
    deleteImageCropPopup,
    openPopup,
    initialCropState = {},
    requirements = {},
    validateSize = false,
    sizeErrorMessage,
}) => {
    const imageRef = useRef(null)
    const [crop, setCrop] = useState({ ...defaultCrop, ...initialCropState })
    const [error, setError] = useState(null)

    useEffect(() => {
        setCrop({ ...defaultCrop, ...initialCropState })
    }, [src])

    const preventClosing = (e) => {
        e.stopPropagation()
    }

    const closePopup = () => {
        deleteImageCropPopup(id)
    }

    const handleCloseClick = () => {
        if (error) {
            closePopup()
        } else {
            openPopup(getPopupDataEditProfileCloseCropPopup(closePopup))
        }
    }

    const sendPreparedImg = async () => {
        const imageBlob = await getCroppedImg(imageRef.current, crop, id)
        callback(imageBlob)
        closePopup()
    }

    const validateImageSize = (image) => {
        const { minWidth, minHeight } = requirements
        if ((minWidth && image.width < minWidth) || (minHeight && image.height < minHeight)) {
            setError(sizeErrorMessage)
        } else {
            setError(null)
        }
    }

    const onImageLoaded = (image) => {
        if (validateSize) {
            validateImageSize(image)
        }
        imageRef.current = image
    }
    return src ? (
        <div onMouseDown={handleCloseClick} className="c-epu-image-crop">
            <div onMouseDown={preventClosing} className="c-epu-image-crop-content">
                <h4 className="c-epu-image-crop-popup-title">{t('componentProfileEditProfileCropPopupTitle')}</h4>
                <div className="c-epu-image-crop-image-wrapper">
                    <ReactCrop
                        src={src}
                        crop={crop}
                        {...requirements}
                        onChange={newCrop => setCrop(newCrop)}
                        onImageLoaded={onImageLoaded}
                    />
                </div>
                {error && (<p className="c-epu-image-crop-error">{t(error)}</p>)}
                <div className="c-epu-image-crop-buttons-wrapper">
                    <Button onClick={handleCloseClick}>Cancel</Button>
                    <Button onClick={() => sendPreparedImg()} disabled={!!error}>Upload</Button>
                </div>
            </div>
        </div>
    ) : null
}

EditProfileImageCrop.propTypes = {
    src: PropTypes.string,
    callback: PropTypes.func,
    id: PropTypes.string,
    deleteImageCropPopup: PropTypes.func,
    openPopup: PropTypes.func,
    initialCropState: PropTypes.object,
    requirements: PropTypes.object,
    validateSize: PropTypes.bool,
    sizeErrorMessage: PropTypes.string,
}

export default EditProfileImageCrop
