import { debounce } from 'lodash'
import * as types from 'constants/UserActionTypes'
import * as messageTypes from 'constants/MessageTypes'
import { addMessage } from 'actions/MessageActions'
import { CALL_API } from 'middleware/api'

// FETCH

function getUsers() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_USERS_REQUEST,
                types.FETCH_USERS_SUCCESS,
                types.FETCH_USERS_FAILURE,
            ],
            payload: {
                endpoint: 'users',
                query: {
                    scope: 'mine',
                    part: 'artist,artist.aliases,locale,providers,flags,subscriptions.orders',
                },
            },
        },
    }
}

export function fetchUsers() {
    return dispatch => dispatch(getUsers())
}

// UPDATE

export function putUser(id, body) {
    return {
        [CALL_API]: {
            types: [
                types.UPDATE_USER_REQUEST,
                types.UPDATE_USER_SUCCESS,
                types.UPDATE_USER_FAILURE,
            ],
            payload: {
                method: 'put',
                endpoint: `users/${id}`,
                query: {
                    part: 'artist,artist.aliases,locale,providers,flags',
                },
                body,
            },
        },
    }
}

const debouncedPutUser = debounce((body, dispatch, getState, resolve, reject) => {
    const { id } = getState().users.user

    return dispatch(putUser(id, body)).then(resolve).catch(reject)
}, 300)

export function updateUser(body) {
    return (dispatch, getState) => new Promise(
        debouncedPutUser.bind(null, body, dispatch, getState)
    ).then((action) => {
        if (action.type === types.UPDATE_USER_SUCCESS && !action.payload.emailVerified) {
            dispatch(addMessage(messageTypes.VERIFY_EMAIL_MESSAGE))
        }
        return Promise.resolve(action)
    })
}

// PASSWORD

function postPassword(id, body) {
    return {
        [CALL_API]: {
            types: [
                types.PASSWORD_REQUEST,
                types.PASSWORD_SUCCESS,
                types.PASSWORD_FAILURE,
            ],
            payload: {
                method: 'post',
                endpoint: `users/${id}/password`,
                body,
            },
        },
    }
}

export function updatePassword(body) {
    return (dispatch, getState) => {
        const { id } = getState().users.user

        return dispatch(postPassword(id, body))
    }
}

// FLAG

function putUserFlags(id, body) {
    return {
        [CALL_API]: {
            types: [
                types.UPDATE_USER_FLAGS_REQUEST,
                types.UPDATE_USER_FLAGS_SUCCESS,
                types.UPDATE_USER_FLAGS_FAILURE,
            ],
            payload: {
                method: 'put',
                endpoint: `users/${id}`,
                query: {
                    part: 'artist,locale,providers,flags',
                },
                body,
            },
        },
    }
}

export function updateFlag(key, value) {
    return (dispatch, getState) => {
        const { user } = getState().users
        const flags = { [key]: value }

        return dispatch(putUserFlags(user.id, { flags }))
    }
}
