import { select, put, call } from 'redux-saga/effects'
import { decamelizeKeys } from 'humps'

import fetch from 'utilities/fetch'

export function* callApi(actions, data) {
    const [requestAction, failureAction, successAction] = actions
    const { credentials } = yield select(state => state.auth)

    yield put({ type: requestAction, meta: data.meta })

    const response = yield call(fetch, {
        accessToken: credentials.accessToken,
        rootUrl: window.env.SPINNUP_API,

        endpoint: data.endpoint,
        method: data.method,
        query: data.query,
        body: data.body && JSON.stringify(decamelizeKeys(data.body)),
    })

    if (typeof response.error !== 'undefined') {
        yield put({
            type: failureAction,
            error: response.error,
            meta: data.meta,
        })
    } else {
        yield put({
            type: successAction,
            meta: { ...data.meta, length: response.length },
            payload: response.payload,
        })
    }
}
