// FETCH

export const FETCH_RELEASE_TYPES_REQUEST = 'FETCH_RELEASE_TYPES_REQUEST'
export const FETCH_RELEASE_TYPES_SUCCESS = 'FETCH_RELEASE_TYPES_SUCCESS'
export const FETCH_RELEASE_TYPES_FAILURE = 'FETCH_RELEASE_TYPES_FAILURE'

// VOUCHER

export const VOUCHER_REQUEST = 'VOUCHER_REQUEST'
export const VOUCHER_SUCCESS = 'VOUCHER_SUCCESS'
export const VOUCHER_FAILURE = 'VOUCHER_FAILURE'
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER'
