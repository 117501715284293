import * as types from 'constants/UserStatisticsActionTypes'
import { CALL_API } from 'middleware/api'

// GET
function getStats() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_USER_STATISTICS_REQUEST,
                types.FETCH_USER_STATISTICS_SUCCESS,
                types.FETCH_USER_STATISTICS_FAILURE,
            ],
            payload: {
                method: 'get',
                endpoint: 'user/stats',
            },
        },
    }
}

export function fetchUserStats() {
    return dispatch => dispatch(getStats())
}
