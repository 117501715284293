import React from 'react'
import * as PropTypes from 'prop-types'
import PopupModalBase from 'components/common/modals/base/PopupModalBase'
import { Button } from 'components/index'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const ReleaseFlowErrorsModal = ({ errorCount, editReleaseHref, onCloseModal }) => {
    const { t } = useLocaleContext()

    const subTitle = errorCount ? (
        t('containerReleaseErrorsCounter', errorCount)
    ) : (
        // fallback in case the count the error count is not good
        // the count should always be positive but better avoid to display inconsitency
        t('containerReleaseErrorsDetected')
    )

    return (
        <PopupModalBase
            onClose={onCloseModal}
            title={t('containerReleaseErrorsTitle')}
            subTitle={subTitle}
            centred
            size="large"
            type="error"
        >
            <div className="modal-content">
                {t('containerReleaseErrorsText')}
            </div>

            <footer className="modal-actions">
                <Button
                    href={editReleaseHref}
                    onClick={() => {
                        onCloseModal()
                    }}
                >{t('containerReleaseErrorsEditRealeaseBtnLabel')}
                </Button>
            </footer>
        </PopupModalBase>
    )
}

ReleaseFlowErrorsModal.propTypes = {
    errorCount: PropTypes.number.isRequired,
    editReleaseHref: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
}

export default ReleaseFlowErrorsModal
