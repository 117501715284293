import { connect } from 'react-redux'
import { uploadLogo, uploadLogoValidationError, removeLogo } from 'actions/ProfileActions'
import { addPopup } from 'actions/PopupActions'
import LogoUpload from 'components/EditProfileUpload/LogoUpload'
import { addImagesToCrop } from 'actions/EditImages'

const mapStateToProps = state => ({
    logo: state.draftProfile.data.files.logo,
    isProcessing: state.draftProfile.isProcessingLogo,
    isProcessingForm: state.draftProfile.isSubmitting || state.draftProfile.isPublishing,
    error: state.draftProfile.logoError,
})

const mapDispatchToProps = {
    uploadLogoImage: uploadLogo,
    uploadLogoValidationError,
    removeLogoImage: removeLogo,
    openPopup: addPopup,
    addImagesToCrop,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogoUpload)
