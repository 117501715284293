import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

const Overlay = ({ enabled, click }) => {
    const classes = classnames(
        'c-overlay',
        { 'c-overlay-enabled': enabled }
    )
    return (
        <div className={classes} onClick={click} />
    )
}

Overlay.propTypes = {
    enabled: PropTypes.bool.isRequired,
    click: PropTypes.func.isRequired,
}

export default Overlay
