import { combineEpics } from 'redux-observable'
import { combineReducers } from 'redux'

import { epic as formEpic } from 'modules/forms'

import { epic as tracksEpic } from 'modules/tracks'

import { epic as albumEpic } from 'modules/album'

import { epic as releaseEpic } from 'modules/release'

import modalReducer, { KEY as MODAL_KEY, epic as modalEpic } from 'modules/modal'

import audioLocalesReducer, { KEY as AUDIO_LOCALES_KEY, epic as audioLocalesEpic } from 'modules/audio-locales'

import artistReducer, { KEY as ARTIST_KEY, epic as artistEpic } from 'modules/artist'

import alertsReducer, { KEY as ALERTS_KEY } from 'modules/alert'

import menusReducer, { KEY as MENUS_KEY } from 'modules/menus'

// Reducers
export default combineReducers({
    [MODAL_KEY]: modalReducer,
    [AUDIO_LOCALES_KEY]: audioLocalesReducer,
    [ALERTS_KEY]: alertsReducer,
    [ARTIST_KEY]: artistReducer,
    [MENUS_KEY]: menusReducer,
})

// Epics
export const epic = combineEpics(
    formEpic,
    tracksEpic,
    albumEpic,
    releaseEpic,
    modalEpic,
    audioLocalesEpic,
    artistEpic
)
