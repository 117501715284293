import { SelectedLocale } from 'selectors/locales'
import { MarketConfigFormLayout } from 'contexts/localisation/localeUiConfig'
import {
    translateMarkdown, translateString, TranslationKey, TranslationLanguage,
} from './translateUtilities'
import { buildLocaleConfig, LocaleConfig } from './localeConfig'

export interface LocaleState {
    t: (id: TranslationKey, ...args: any[]) => string,
    tmarkdown: (id: TranslationKey, ...args: any[]) => any,
    getLocaleFormOrder:
    (key: keyof MarketConfigFormLayout, marketId: number) => MarketConfigFormLayout[keyof MarketConfigFormLayout]
    localeConfig: LocaleConfig,
}

function buildLocaleState(
    languageCode: TranslationLanguage,
    countryCode: string,
    locales: SelectedLocale[]
): LocaleState {
    const localeConfig = buildLocaleConfig(languageCode, countryCode, locales)
    return {
        t: (id, ...args) => translateString(id, args, languageCode),
        tmarkdown: (id, ...args) => translateMarkdown(id, args, languageCode),
        getLocaleFormOrder: (key, marketId) => localeConfig.getFormOrdering(key, marketId),
        localeConfig,
    }
}

export default buildLocaleState
