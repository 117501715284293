import 'rxjs'

const API_URI = window.env.SPINNUP_API

// put is here only for a semantic purpose, indeed we are not doing a proper PUT
// but isntead we are always doing a PATCH, execpt we are providing the WHOLE album
// this is due to a FUGA limitation and the way we are handling the saves (real-time saves)
export const fetchAudioLocales = (options, credentials, ajax) => {
    const setting = {
        method: 'GET',
        url: `${API_URI}refdata/audio/locales`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${credentials.accessToken}`,
        },
    }

    return ajax(setting)
}
