import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updatePassword } from 'actions/UserActions'
import Field from 'components/Field/Field'
import PasswordInput from 'components/Inputs/PasswordInput'
import Spinner from 'components/Spinner/Spinner'
import Button from 'components/Button/Button'
import Saved from 'components/Saved/Saved'
import { withLocalisation } from 'contexts/localisation/localeProvider'

class PasswordSettingsContainer extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired,
        updatePassword: PropTypes.func.isRequired,
        locale: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props)
        this.oldPasswordRef = React.createRef()
        this.newPasswordRef = React.createRef()
        this.newPasswordConfirmationRef = React.createRef()
        this.state = { isDone: false }
    }

    onSave = () => {
        this.setState({ isDone: false })
        this.props.updatePassword({
            oldPassword: this.oldPasswordRef.current.getValue(),
            newPassword: this.newPasswordRef.current.getValue(),
            newPasswordConfirmation: this.newPasswordConfirmationRef.current.getValue(),
        }).then((action) => {
            if (action.payload) {
                this.setState({ isDone: true })
            }
        })
    }

    render() {
        const { isLoading, errors, locale } = this.props
        const { isDone } = this.state
        const { t } = locale

        return (
            <div className="c-password-settings">
                <header>
                    <h1>{t('containerPasswordSettingsPanelTitle')}</h1>
                    <div className="c-password-settings-status">
                        {isLoading
                            && (
                                <div className="c-password-settings-saving">
                                    <Spinner size="small" horizontal>{t('globalSaving')}</Spinner>
                                </div>
                            )}
                        {isDone && !isLoading && <Saved>{t('globalSaved')}</Saved>}
                    </div>
                </header>

                <Field
                    label={t('containerPasswordSettingsLabelCurrent')}
                    error={t(errors.oldPassword)}
                >
                    <PasswordInput
                        ref={this.oldPasswordRef}
                    />
                </Field>

                <Field
                    label={t('containerPasswordSettingsLabelNew')}
                    error={t(errors.newPassword)}
                    helpText={t('containerRegisterFormPasswordHint')}
                >
                    <PasswordInput
                        ref={this.newPasswordRef}
                    />
                </Field>

                <Field
                    label={t('containerPasswordSettingsLabelRepeat')}
                    error={t(errors.newPasswordConfirmation)}
                >
                    <PasswordInput
                        ref={this.newPasswordConfirmationRef}
                    />
                </Field>

                <Button onClick={this.onSave}>{t('containerPasswordSettingsButtonChange')}</Button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.users.isUpdatingPass,
    errors: state.users.passErrors,
})

const mapDispatchToProps = {
    updatePassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalisation(PasswordSettingsContainer))
