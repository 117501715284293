import { createSelector } from 'reselect'
import { formatDateLocalised } from 'utilities/date'

export const scoutsSelector = createSelector(
    state => state.scouts.scouts,
    scouts => scouts.toArray().map(scout => ({
        ...scout.toJS(),
        since: formatDateLocalised(scout.createdAt),
        genre: scout.genres.map(genre => genre.name).join(', '),
    }))
)

export const featuredScoutSelector = createSelector(
    scoutsSelector,
    scouts => scouts.filter(scout => !!scout.isFeatured)
)

export const nonFeaturedScoutsSelector = createSelector(
    scoutsSelector,
    scouts => scouts.filter(scout => !scout.isFeatured)
)

export const scoutSelector = createSelector(
    scoutsSelector,
    (state, id) => id,
    (scouts, id) => scouts.find(scout => scout.id === id)
)
