import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import t from 'utilities/translate'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

const navLinks = (linkList, actions, urls) => linkList.map((link) => {
    const {
        label,
        hrefKey,
        action,
        to,
    } = link

    const target = (typeof to !== 'undefined') ? to : urls[hrefKey]
    const key = label.replace(' ', '_').toLowerCase()

    const faIcon = ((lab) => {
        const icons = {
            faSignInAlt: ['containerLoggedOutPrimaryMenuLogin'],
            faUserPlus: ['containerLoggedOutPrimaryMenuSignUp'],
            faSignOutAlt: ['containerLoggedOutPrimaryMenuLogout'],
        }
        const iconEntry = Object.entries(icons).find(entry => entry[1].includes(lab))
        const iconName = iconEntry ? iconEntry[0] : null

        return iconName ? (
            <FontAwesomeIcon className="c-hamburger-menu-content-nav-iconlink-svg" icon={iconName} />
        ) : null
    })(label)

    const renderLink = (linkTarget, tranStr, icon = null) => {
        const txtLabel = t(tranStr)
        const clickHandler = linkAction => (e) => {
            e.preventDefault()
            linkAction()
        }
        if (typeof linkTarget === 'function') {
            return (
                <a
                    className="c-hamburger-menu-action-link"
                    onClick={clickHandler(linkTarget)}
                >
                    {icon} {txtLabel}
                </a>
            )
        } else if (linkTarget.substring(0, 4) === 'http') {
            return <a href={linkTarget} target="_blank" rel="noreferrer">{icon} {txtLabel}</a>
        } else {
            return <Link to={linkTarget}>{icon} {txtLabel}</Link>
        }
    }

    return (
        <li
            key={key}
            className={faIcon ? 'c-hamburger-menu-content-nav-iconlink' : ''}
        >
            { renderLink(action ? () => actions[action]() : target, label, faIcon) }
        </li>
    )
})

const HamburgerSideBar = (props) => {
    const {
        items,
        urls,
        actions,
        artistIsEnhanced,
    } = props

    const navAuthLinkClasses = classnames(
        'c-hamburger-menu-content-nav-links',
        'c-hamburger-menu-content-nav-divider'
    )
    const topNavLinkItems = items && navLinks(
        items[artistIsEnhanced ? 'enhancedLinks' : 'offboardedLinks'],
        actions,
        urls
    )
    const authenticationItems = items && navLinks(items.auth, actions, urls)

    return (
        <nav className="c-hamburger-menu-content-nav">
            <ul className="c-hamburger-menu-content-nav-links">
                { topNavLinkItems }
            </ul>

            <ul className={navAuthLinkClasses}>
                { authenticationItems }
            </ul>
        </nav>
    )
}

HamburgerSideBar.propTypes = {
    items: PropTypes.object.isRequired,
    urls: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    artistIsEnhanced: PropTypes.bool.isRequired,
}

export default HamburgerSideBar
