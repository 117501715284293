// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { createSelector } from 'reselect'
import moment from 'moment'

export const KEY = 'alerts'

// ///////////
// REDUCERS
// ///////////
const initialState = {
    // Example, this will only display between the 2 given dates.
    // 'example': {
    //    from: new Date('2018-04-22'),
    //    to: new Date('2018-04-28'),
    // },
    'christmas-delivery-alert': {
        from: new Date('2021-11-10'),
        to: new Date('2022-01-06'),
    },
    offboarding: {
        from: new Date('2022-09-20'),
        to: new Date('2030-09-20'),
    },
}

export default (state = initialState) => state

// ///////////
// SELECTORS
// ///////////
export const getAlerts = state => state[KEY]
export const getAlertData = id => createSelector(
    getAlerts,
    alerts => alerts[id]
)

export const shouldDisplayAlert = id => createSelector(
    getAlertData(id),
    data => moment().isBetween(data.from, data.to)
)
