import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Select, { createFilter, components } from 'react-select'
import Translate from 'components/Translate/translate'

export default class MultiSelect extends Component {
    selectFilter = createFilter({
        matchFrom: 'start',
        ignoreCase: true,
        ignoreAccents: true,
        stringify: opt => opt.label,
    })

    static propTypes = {
        options: PropTypes.array.isRequired,
        value: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    shouldComponentUpdate(nextProps) {
        const currentValues = (this.props.value || []).map(v => v.value)
        const nextValues = (nextProps.value || []).map(v => v.value)

        const areTheSame = currentValues.length === nextValues.length
            && currentValues.every((v, i) => v === nextValues[i])

        return !areTheSame
    }

    noOptionsMessageRenderer = ({ innerProps }) => (
        <div {...innerProps} className="c-select-no-options"><Translate id="componentSelectNoResultsText" /></div>
    )

    optionRenderer = props => (<components.Option {...props} className="c-select-option" />)

    render() {
        return (
            <Select
                className="c-select-wrapper c-select-wrapper-multi"
                classNamePrefix="c-select"
                placeholder={(<Translate id="componentSelectPlaceholderDefault" />)}
                isMulti
                isSearchable
                autoFocus
                isClearable={false}
                filterOption={this.selectFilter}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Option: this.optionRenderer,
                    NoOptionsMessage: this.noOptionsMessageRenderer,
                }}
                {...this.props}
            />
        )
    }
}
