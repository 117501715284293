import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import Background from 'components/Frame/Background'

const ClipoutText = ({
    type,
    textProps,
    children,
    className,
    backgroundImageId,
    isNotInFrame,
}) => {
    const rootClassname = classnames(
        'c-frame-clipout-text',
        `background-${backgroundImageId}`,
        {
            'reset-background-position': isNotInFrame,
        },
        className
    )

    return React.createElement(
        type,
        {
            ...textProps,
            className: rootClassname,
        },
        ...children
    )
}

ClipoutText.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    backgroundImageId: Background.propTypes.imageId,
    isNotInFrame: PropTypes.bool,
    textProps: PropTypes.object,
    type: PropTypes.string.isRequired,
}

ClipoutText.defaultProps = {
    backgroundImageId: Background.defaultProps.imageId,
}

export default ClipoutText
