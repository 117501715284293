import React from 'react'
import * as PropTypes from 'prop-types'

import Footer from 'components/Release/Footer'
import ArtworkContainer from 'containers/Artwork/Artwork'
import General from 'components/Release/General'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const Pending = (props) => {
    const {
        release,
    } = props
    const { t } = useLocaleContext()

    return (
        <>
            <div className="c-release-body">
                <ArtworkContainer album={release.album} className="c-release-cover-art" />
                <div className="c-release-completed">
                    <General release={release} />
                </div>
            </div>
            <Footer icon="faCheck" message={t('selectorAlbumsStepTwo')} />
        </>
    )
}

Pending.propTypes = {
    release: PropTypes.object.isRequired,
}

export default Pending
