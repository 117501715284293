import t from 'utilities/translate'
import store from 'store/store'

// This function should be used only in case if there is no other way to get those credentials from store
const getCredentialsFromStore = () => (store.getState().auth.credentials)

const rootUrl = window.env.SPINNUP_API

export const validateSlug = (slug, message) => {
    if (!slug && `${slug}`.trim().length === 0) {
        return Promise.resolve(null)
    }
    const { tokenType, accessToken } = getCredentialsFromStore()
    return fetch(`${rootUrl}/profile/slug/${slug}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${tokenType} ${accessToken}`,
        },
    })
        .then((res) => {
            const { status } = res
            if (status !== 404) {
                return ({
                    slug: message,
                })
            }
            return {}
        })
}

export const formatEditProfileErrors = (message, errors) => {
    const formattedErrors = Object.keys(errors).reduce((acc, key) => {
        const getMessage = Array.isArray(errors[key]) ? errors[key][0] : errors[key]
        const errorMessage = t(`${getMessage}`) || t(`${message}`)

        if (`${key}`.includes('links.')) {
            return acc
        }
        if (`${key}`.includes('videos')) {
            const index = `${key}`.split('.')[1]
            acc.videos[index] = errorMessage
            return {
                ...acc,
                videos: [...acc.videos],
            }
        }
        return {
            ...acc,
            [key]: errorMessage,
        }
    }, {
        videos: new Array(4),
    })
    return formattedErrors
}
