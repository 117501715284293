import { legacyValidate } from 'utilities/validate'

export const VALIDATE = Symbol('Validator')

export default store => next => (action) => {
    const validateAction = action[VALIDATE]

    if (typeof validateAction === 'undefined') {
        return next(action)
    }

    const { types, payload } = validateAction

    if (!Array.isArray(types) || types.length !== 2) throw new Error('Expected an array of three action types.')
    if (!types.every(type => typeof type === 'string')) throw new Error('Expected action types to be strings.')

    const { fields, rules } = payload

    if (typeof fields !== 'object') throw new Error('Expected first param to be an object of fields.')
    if (typeof rules !== 'object') throw new Error('Expected second param to be an object of validation rules.')

    const [successType, failureType] = types

    const actionWith = data => ({
        ...validateAction,
        ...data,
    })

    const state = store.getState()
    const messages = legacyValidate(fields, rules, state)
    const hasErrors = !!Object.keys(messages).find(key => !!messages[key].error)

    return !hasErrors
        ? next(actionWith({
            type: successType,
            payload: messages,
        }))
        : next(actionWith({
            type: failureType,
            payload: messages,
        }))
}
