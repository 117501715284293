import React from 'react'
import * as PropTypes from 'prop-types'
import EditProfileDropzone from 'components/Dropzone/EditProfileDropzone'
import { galleryMaxSize } from 'constants/FileSizes'
import { maxGalleryLength } from 'constants/GalleryLength'
import {
    getPopupDataEditProfileGalleryNoSpace,
} from 'utilities/popups'
import { isCroppingSupported } from 'constants/browserCompatybility'
import { imageTypesExtended } from '../../constants/FileTypes'

const GalleryUpload = ({
    galleryLength,
    isUploading,
    isProcessingForm,
    error,
    uploadGalleryImage,
    uploadGalleryValidationError,
    openPopup,
    addImagesToCrop,
}) => {
    const galleryEmptySlots = maxGalleryLength - galleryLength

    const validateFile = (file) => {
        const maxFileSize = galleryMaxSize
        const { jpg, jpeg, png } = imageTypesExtended
        const acceptImgMimes = [jpg, jpeg, png]
        if (file.size > maxFileSize) {
            uploadGalleryValidationError('uploadEditProfileGalleryFileMaxSize')
            return false
        }
        if (acceptImgMimes.indexOf(file.type) < 0) {
            uploadGalleryValidationError('uploadEditProfileGalleryFileType')
            return false
        }
        return true
    }

    const prepareFilesToCrop = (files) => {
        files.forEach((file) => {
            if (validateFile(file)) {
                if (isCroppingSupported) {
                    const fileReader = new FileReader()
                    fileReader.readAsDataURL(file)
                    fileReader.onloadend = event => (
                        addImagesToCrop([{
                            src: event.target.result,
                            callback: uploadGalleryImage,
                        }])
                    )
                } else {
                    uploadGalleryImage(file)
                }
            }
        })
    }

    const handleUpload = (files) => {
        if (files.length > galleryEmptySlots) {
            const onAccept = () => prepareFilesToCrop(files)
            openPopup(getPopupDataEditProfileGalleryNoSpace(onAccept))
        } else {
            prepareFilesToCrop(files)
        }
    }

    return (
        <div className="c-epu-dropzone-wrapper gallery">
            <EditProfileDropzone
                uploadImage={handleUpload}
                isProcessing={isUploading}
                isLoading={isUploading}
                hasError={!!error}
                hideUploadButton
                disableDropzone={isProcessingForm}
                allowMultipleUpload
            />
        </div>
    )
}

GalleryUpload.propTypes = {
    galleryLength: PropTypes.number.isRequired,
    isUploading: PropTypes.bool.isRequired,
    isProcessingForm: PropTypes.bool.isRequired,
    error: PropTypes.string,
    uploadGalleryImage: PropTypes.func.isRequired,
    uploadGalleryValidationError: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    addImagesToCrop: PropTypes.func,
}

export default GalleryUpload
