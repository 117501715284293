import React from 'react'
import * as PropTypes from 'prop-types'

// Some of these are used directly by Table.tsx without rendering through this component
export type ColumnProps = {
    heading: string
    prop: string
    isNumber?: boolean
    grow?: number
    align?: string
    strong?: boolean
}

const Column: React.FC<ColumnProps> = ({ heading }) => (
    <div>{heading}</div>
)

Column.propTypes = {
    heading: PropTypes.string.isRequired,
}

export default Column
