import { connect } from 'react-redux'
import { fetchBackgroundColors } from 'actions/BackgroundColorsActions'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import { solidBackgroundsSelector, gradientBackgroundsSelector } from 'selectors/backgroundColors'
import { draftProfileDataSelector } from 'selectors/draftProfile'
import * as backgroundTypes from 'constants/enums/BackgroundTypes'

const mapStateToProps = (state) => {
    const { isLoaded, loadingFailed } = state.backgroundColors
    const solids = solidBackgroundsSelector(state)
    const gradients = gradientBackgroundsSelector(state)
    const { backgroundColor } = draftProfileDataSelector(state)

    return {
        isLoaded,
        loadingFailed,
        solids,
        gradients,
        initialGradientsVisibility: backgroundColor.type !== backgroundTypes.SOLID,
    }
}

const mapDispatchToProps = {
    fetchBackgroundColors,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorPicker)
