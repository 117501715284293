import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import classnames from 'classnames'
import Input from './Input'

/**
 * Text Input
 *
 * Input a word or a sentence
 *
 * Styleguide: components.input.text
 */
export default class TextInput extends Component {
    static propTypes = {
        imitatedInputType: PropTypes.string,
        clickEdit: PropTypes.bool,
        disabled: PropTypes.bool,
        value: PropTypes.string,
    };

    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
        this.state = {
            editing: false,
        }
    }

    getValue() {
        return this.inputRef.current.getValue()
    }

    setValue(value) {
        this.inputRef.current.setValue(value)
    }

    setEditing(editing) {
        this.inputRef.current.setDisabled(!editing)
        this.setState({ editing })
    }

    render() {
        if (this.props.clickEdit) {
            const className = classnames(
                'c-input-editable',
                {
                    'c-editing': !this.props.disabled || this.state.editing,
                }
            )
            return (
                <div
                    className={className}
                >
                    <Input
                        ref={this.inputRef}
                        {...this.props}
                        type="text"
                    />
                    { (this.props.disabled && !this.state.editing)
                        && (
                            <div
                                className="imitatedInput"
                            >
                                <div
                                    className="inputValue"
                                >
                                    {this.props.imitatedInputType === 'link'
                                        ? (
                                            <a href={this.props.value} target="_blank" rel="noreferrer">
                                                {this.props.value}
                                            </a>
                                        ) : (
                                            <span>{this.props.value}</span>
                                        )}
                                </div>
                                <div
                                    className="icon"
                                    onClick={() => {
                                        this.setEditing(true)
                                    }}
                                >
                                    <FontAwesomeIcon icon="faPencilAlt" />
                                </div>
                            </div>
                        )}
                </div>
            )
        } else {
            return <Input ref={this.inputRef} {...this.props} type="text" />
        }
    }
}
