import { hydrateTrack } from 'utilities/hydrator'
import * as types from 'constants/TrackActionTypes'
import * as albumTypes from 'constants/AlbumActionTypes'
import {
    REQUEST_SAVE,
    RECEIVE_SAVE_SUCCESS,
    RECEIVE_PATCH_SUCCESS,
    RECEIVE_PATCH_FAILURE,
    RECEIVE_PATCH_START_TIME_FAILURE,
    RECEIVE_PATCH_START_TIME_SUCCESS,
} from 'modules/tracks'
import * as authTypes from 'constants/AuthActionTypes'
import Track from 'models/track'
import { ApiDspTrack } from 'models/api/dsp-track'
import { ApiDspProduct } from 'models/api/dsp-product'
import { AnyAction } from 'redux'

type TracksState = {
    isFetching: boolean
    isAdding: boolean
    isUpdating: boolean
    isRemoving: boolean
    isCreatingTracks: boolean
    tracks: Record<string, Track>
    selectedId?: string
    error?: Record<string, any>
}

const initialState: TracksState = {
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    isRemoving: false,
    isCreatingTracks: false,
    tracks: {} as Record<string, Track>,
    selectedId: undefined,
    error: {},
}

export default function tracks(state = initialState, action: AnyAction): TracksState {
    switch (action.type) {
        // FETCH
        case albumTypes.FETCH_ALBUMS_SUCCESS:
            return {
                ...state,
                tracks: action.payload.reduce((map: Record<string, Track>, product: ApiDspProduct) => ({
                    ...map,
                    ...product.tracks.reduce((trackMap: Record<string, Track>, track: ApiDspTrack) => (
                        {
                            ...trackMap,
                            [track.id]: hydrateTrack(track, product.id, state.tracks[track.id]),
                        }
                    ), {}),
                }), {}),
            }

        case albumTypes.FETCH_ALBUM_SUCCESS:
            return {
                ...state,
                tracks:
                    action.payload.tracks.reduce((map: Record<string, Track>, track: ApiDspTrack) => ({
                        ...map,
                        [track.id]: hydrateTrack(track, action.payload.id, state.tracks[track.id]),
                    }), {}),
            }

            // CREATE

        case types.CREATE_TRACKS:
            return {
                ...state,
                isCreatingTracks: true,
            }

        case types.CREATE_TRACKS_SUCCESS:
            return {
                ...state,
                isCreatingTracks: false,
            }

        case types.CREATE_TRACK_REQUEST:
            return {
                ...state,
                isAdding: true,
                error: {},
            }
        case RECEIVE_PATCH_SUCCESS:
        case RECEIVE_SAVE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                tracks: {
                    ...state.tracks,
                    [action.payload.id]: hydrateTrack(
                        action.payload,
                        action.meta.albumId,
                        state.tracks[action.payload.id]
                    ),
                },
            }
        case RECEIVE_PATCH_START_TIME_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                tracks: {
                    ...state.tracks,
                    [action.payload.id]: hydrateTrack(
                        action.payload,
                        action.meta.albumId,
                        state.tracks[action.payload.id]
                    ),
                },
            }
        case types.CREATE_TRACK_SUCCESS:
            return {
                ...state,
                isAdding: false,
                tracks: {
                    ...state.tracks,
                    [action.payload.id]: hydrateTrack(
                        {
                            ...action.payload,
                            audio: {
                                originalFilename: action.meta.originalFilename,
                            },
                        },
                        action.meta.albumId,
                        {
                            isUploading: true,
                            justAdded: true,
                        }
                    ),
                },
            }
        case types.CREATE_TRACK_FAILURE:
            return {
                ...state,
                isAdding: false,
                error: action.payload,
            }

        // UPDATE
        case REQUEST_SAVE:
        case types.UPDATE_TRACK_REQUEST:
            return {
                ...state,
                isUpdating: true,
            }

        case types.UPDATE_TRACK_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                tracks: {
                    ...state.tracks,
                    [action.payload.id]: hydrateTrack(
                        action.payload,
                        action.meta.albumId,
                        state.tracks[action.payload.id]
                    ),
                },
            }

        case RECEIVE_PATCH_FAILURE: {
            const nextState = {
                ...state,
                isUpdating: false,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        errors: action.payload.message,
                    }),
                },
            }
            // if (action.meta.field === 'artists') {
            //     return {
            //         ...nextState,
            //         albums: state.albums
            //             .setIn([action.meta.id, action.meta.field], []),
            //     }
            // }
            return nextState
        }
        case RECEIVE_PATCH_START_TIME_FAILURE: {
            return {
                ...state,
                isUpdating: false,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        startTimeError: action.payload,
                    }),
                },
            }
        }
        case types.UPDATE_TRACK_FAILURE:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        errors: action.error,
                    }),
                },
                isUpdating: false,
            }

            // UPLOAD

        case types.UPLOAD_FILE_REQUEST:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        isUploading: true,
                        uploadErrors: [],
                        hasAudio: false,
                    }),
                },
            }

        case types.UPLOAD_FILE_PROGRESS:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        uploadProgress: action.payload,
                    }),
                },
            }

        case types.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        isUploading: false,
                        hasAudio: true,
                        uploadErrors: action.payload.payload.audio,
                    }),
                },
            }

        case types.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        isUploading: false,
                        hasAudio: false,
                        uploadErrors: action.error,
                    }),
                },
            }

        case types.UPLOAD_FILE_ABORT:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        isUploading: false,
                        hasAudio: false,
                    }),
                },
            }

            // REMOVE

        case types.REMOVE_TRACK_REQUEST:
            return {
                ...state,
                isRemoving: true,
            }

        case types.REMOVE_TRACK_SUCCESS:
            const removedTrack = { ...state.tracks }
            delete removedTrack[action.meta.id]
            return {
                ...state,
                tracks: removedTrack,
                selectedId: action.meta.id === state.selectedId ? undefined : state.selectedId,
                isRemoving: false,
            }

        case types.REMOVE_TRACK_FAILURE:
            return {
                ...state,
                isRemoving: false,
            }

            // VALIDATE

        case types.VALIDATE_TRACK:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.meta.id]: state.tracks[action.meta.id].clone({
                        errors: action.payload.errors,
                        warnings: action.payload.warnings,
                    }),
                },
            }

            // SELECT

        case types.SELECT_TRACK:
            return {
                ...state,
                selectedId: action.payload,
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
