import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import {
    Field as ReduxField, InjectedFormProps,
    reduxForm,
} from 'redux-form'
import XRegExp from 'xregexp'
import { AddressFormFields } from 'components/AddressForm/AddressFormFields'
import { ReduxFormTextField } from 'components/common/forms/ReduxFormTextField'
import MarketOrderSwitch from 'components/MarketOrderSwitch/MarketOrderSwitch'
import Translate from 'components/Translate/translate'
import Button from 'components/Button/Button'
import {
    doesNotMatchRegex,
    isEmpty, required, validateDateOver18Years,
} from 'validations/rules'
import ReduxFormDateInput from 'components/common/forms/ReduxFormDateInput'
import Alert from 'components/Alert/Alert'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { EVENT_PAGE_VIEW, sendEventToDataLayer } from 'utilities/analytics'
import { URL_EARNINGS_ACCOUNT_CREATE_TITLE, URL_EARNINGS_ACCOUNT_EDIT_TITLE } from 'constants/AppUrls'
import { WalletAccount } from 'models/walletAccount'

const FORM_NAME = 'update-wallet-account-details'

type UpdateWalletAccountDetailsGivenProps = {
    user: any
    walletAccount: WalletAccount
    countries: { value: any, label: any }[]
    onCancel: () => void
    isSubmitting?: boolean
}

type FormDateObject = {
    year: string
    month: string
    day: string
}

export type SubmitAccountFormValues = {
    firstName: string
    lastName: string
    dateOfBirth: FormDateObject
    addressLine1: string
    city: string
    country: string

    state?: string
    province?: string
    prefecture?: string
    regionGeneric?: string

    zipcode?: string
    postcode?: string
}

export const parseObjectToDateString = ({ year, month, day }: FormDateObject) => {
    if ((!year || year.length === 0) && (!month || month.length === 0) && (!day || day.length === 0)) {
        return undefined
    }
    return `${year}-${month}-${day}`
}

export const formatDateStringToObject = (dateStr: string) => {
    const emptyObject = {
        year: '',
        month: '',
        day: '',
    }
    if (!dateStr || dateStr.length === 0) {
        return emptyObject
    }
    const str = dateStr.split('-')

    if (str.length !== 3) {
        return emptyObject
    }

    return {
        year: str[0],
        month: str[1],
        day: str[2],
    }
}

export const getFormDataFormat = (existingWallet: WalletAccount, user: any) => {
    const isUserCountryJapan = user.countryId === 'JP'
    const base: Partial<SubmitAccountFormValues> = {
        firstName: isUserCountryJapan ? undefined : user.firstName,
        lastName: isUserCountryJapan ? undefined : user.lastName,
        country: user.countryId,
    }

    if (existingWallet.hasBeenCreated()) {
        base.firstName = existingWallet.getFirstName()
        base.lastName = existingWallet.getLastName()
        base.dateOfBirth = formatDateStringToObject(existingWallet.getDateOfBirth())

        const address = existingWallet.getAddress()
        base.country = address.country
        base.addressLine1 = address.addressLine1
        base.city = address.city

        if (base.country === 'CA') {
            base.province = address.stateProvince
            base.postcode = address.postalCode
        } else if (base.country === 'US') {
            base.state = address.stateProvince
            base.zipcode = address.postalCode
        } else if (base.country === 'JP') {
            base.prefecture = address.stateProvince
            base.postcode = address.postalCode
        } else if (base.country === 'AU') {
            base.state = address.stateProvince
            base.postcode = address.postalCode
        } else {
            base.regionGeneric = address.stateProvince
            base.postcode = address.postalCode
        }
    }

    return base
}

export const getWalletAccountUpdateFormat = (formValues: SubmitAccountFormValues) => ({
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    dateOfBirth: parseObjectToDateString(formValues.dateOfBirth),
    address: {
        addressLine1: formValues.addressLine1,
        city: formValues.city,
        country: formValues.country,
        stateProvince: formValues.state || formValues.province
            || formValues.prefecture || formValues.regionGeneric || '',
        postalCode: formValues.zipcode || formValues.postcode,
    },
})

const requiredField = required('formInputFieldRequired')
const emptyField = isEmpty('formInputFieldRequired')
const nameRegex = XRegExp('^[\\p{L}\\-\\s\'\\.,]+$')
const nameValidation = doesNotMatchRegex(nameRegex, 'formInputLabelFirstNameError')
const nameFieldValidation = [requiredField, emptyField, nameValidation]

const dateOver18Years = validateDateOver18Years(
    'earningsWithdrawCreateDOBInvalidError',
    'earningsWithdrawCreateDOBAgeError'
)
const dateOfBirthValidation = [requiredField, dateOver18Years]

type ComponentsProps =
    UpdateWalletAccountDetailsGivenProps &
    InjectedFormProps<SubmitAccountFormValues, UpdateWalletAccountDetailsGivenProps>

const UpdateWalletAccountDetails: React.FC<ComponentsProps> = ({
    user,
    countries,
    walletAccount,
    initialize,
    change,
    invalid,
    pristine,
    error,
    handleSubmit,
    onCancel,
    isSubmitting,
}) => {
    const prePopulatedData = getFormDataFormat(walletAccount, user)

    useEffect(() => {
        initialize(prePopulatedData)

        const isEditing = walletAccount.hasBeenCreated()
        const { pathname, origin } = window.location
        const pagePath = `${pathname}/account${isEditing ? '/edit' : ''}`
        const pageUrl = `${origin}${pagePath}`
        const pageTitle = isEditing ? URL_EARNINGS_ACCOUNT_EDIT_TITLE : URL_EARNINGS_ACCOUNT_CREATE_TITLE
        const eventObj = {
            pagePath,
            pageUrl,
            pageTitle,
            userId: user.id,
            country: user.countryId,
            market: user.market,
        }

        sendEventToDataLayer(EVENT_PAGE_VIEW, eventObj)
    }, [])

    const isEditing = walletAccount.hasBeenCreated()
    const headerKey = isEditing ? 'earningsWithdrawHeaderUpdateWallet' : 'earningsWithdrawHeaderCreateWallet'
    const infoElement = isEditing ? null : (
        <Alert type="warning">
            <TranslateMarkdown id="earningsWithdrawInfoAccountCreation" />
        </Alert>
    )
    return (
        <>
            { infoElement }
            <h3 className="c-wallet-details-title">
                <span><Translate id={headerKey} /></span>
                { isEditing ? <Button onClick={onCancel}><Translate id="formButtonCancel" /></Button> : null }
            </h3>
            <form onSubmit={handleSubmit}>
                <MarketOrderSwitch
                    components={{
                        firstName: (
                            <ReduxField
                                name="firstName"
                                component={ReduxFormTextField}
                                labelId="formInputLabelFirstName"
                                validate={nameFieldValidation}
                                props={{ disabled: isSubmitting }}
                                disabled={isSubmitting}
                            />
                        ),
                        lastName: (
                            <ReduxField
                                name="lastName"
                                component={ReduxFormTextField}
                                labelId="formInputLabelLastName"
                                validate={nameFieldValidation}
                                props={{ disabled: isSubmitting }}
                                disabled={isSubmitting}
                            />

                        ),
                    }}
                    marketId={user.marketId}
                    type="firstNameLastName"
                />

                <ReduxField
                    name="dateOfBirth"
                    component={ReduxFormDateInput}
                    labelId="formInputLabelDateOfBirth"
                    marketId={user.marketId}
                    validate={dateOfBirthValidation}
                    props={{ disabled: isSubmitting }}
                    disabled={isSubmitting}
                />

                <AddressFormFields
                    reduxFormChange={change}
                    marketId={user.marketId}
                    mergeAddressLine1
                    allCountries={countries}
                    isFetchingCountries={false}
                    initialValues={prePopulatedData}
                    disabled={isSubmitting}
                    enableGenericRegion
                />

                <Button
                    isSubmit
                    isLoading={isSubmitting}
                    disabled={invalid || pristine}
                >
                    <Translate id="formButtonSave" />
                </Button>

                {
                    error ? (
                        <Alert type="error" containerClassNames={['c-withdraw-alert', 'error']} colourise>
                            { error }
                        </Alert>
                    ) : undefined
                }
            </form>
        </>
    )
}

UpdateWalletAccountDetails.propTypes = {
    user: PropTypes.object.isRequired,
    walletAccount: PropTypes.any.isRequired,
    countries: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
}

export default reduxForm<SubmitAccountFormValues, UpdateWalletAccountDetailsGivenProps>({
    form: FORM_NAME,
})(UpdateWalletAccountDetails)
