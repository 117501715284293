import React from 'react'
import * as PropTypes from 'prop-types'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import {
    camelizeKey,
    HW_TRM_TYPE_FEE_KEY_MAP,
} from 'components/EarningsWithdrawFeesDisplay/WithdrawFeesDisplay'
import {
    HW_TRANSFER_PAYPAL_ACCOUNT,
    HW_TRANSFER_WIRE_ACCOUNT,
} from 'constants/HyperWalletTransferTypes'

const TrmWithdrawFeeLabel = ({ transferMethod }) => {
    const currencyKey = camelizeKey(transferMethod.currency)
    const feeTypeKey = HW_TRM_TYPE_FEE_KEY_MAP[transferMethod.type]

    let fee
    if (transferMethod.type === HW_TRANSFER_WIRE_ACCOUNT) {
        fee = '11.50'
    } else if (transferMethod.fees[feeTypeKey]) {
        fee = transferMethod.fees[feeTypeKey][currencyKey]
    }

    // The euro symbol is translated independently for Japan so provide a different string as it's a fixed fee too
    return (
        <div className="c-trm-withdraw-fees">
            { transferMethod.type === HW_TRANSFER_PAYPAL_ACCOUNT
                ? <TranslateMarkdown id="earningsWithdrawFeesSelectedTrmPaypal" />
                : <TranslateMarkdown id="earningsWithdrawFeesSelectedTrm" args={[fee]} /> }
        </div>
    )
}

TrmWithdrawFeeLabel.propTypes = {
    transferMethod: PropTypes.object.isRequired,
}

export default TrmWithdrawFeeLabel
