import marked from 'marked'
import dompurify from 'dompurify'

function getCustomMarkdownRenderer() {
    const renderer = new marked.Renderer()

    renderer.link = function link(href, title, text) {
        if (this.options.sanitize) {
            let prot

            try {
                prot = decodeURIComponent(unescape(href))
                    .replace(/[^\w:]/g, '')
                    .toLowerCase()
            } catch (e) {
                return ''
            }

            // eslint-disable-next-line no-script-url
            if (prot.indexOf('javascript:') === 0 || prot.indexOf('vbscript:') === 0) {
                return ''
            }
        }
        let out = `<a href="${href}"`
        if (title) {
            out += ` title="${title}"`
        }

        if (href.startsWith('http://') || href.startsWith('https://')) {
            out += ' target="_blank"'

            if (href.indexOf(window.location.hostname) === -1) {
                out += ' rel="noopener noreferrer"'
            }
        }

        out += `>${text}</a>`
        return out
    }

    return renderer
}

export function formatMarkdownString(str: string) {
    const customRenderer = getCustomMarkdownRenderer()
    const parsed = marked.parse(str, { renderer: customRenderer, breaks: true })
    return dompurify.sanitize(parsed, { ADD_ATTR: ['target'] })
}
