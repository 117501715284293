import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { autoRehydrate } from 'redux-persist'
import { createLogger } from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'
import { ajax } from 'rxjs/observable/dom/ajax'

// Redux-observable
import createSagaMiddleware from 'redux-saga'
import { epic as rootEpic } from 'modules/index'

// Redux-saga
import rootSaga from '../sagas'

// Middleware
import api from '../middleware/api'
import auth from '../middleware/auth'
import validator from '../middleware/validator'

// Reducers
import reducers from '../reducers/index'

const isNodeEnvProd = process.env.NODE_ENV === 'production'

const rootReducer = combineReducers({ ...reducers })

const sagaMiddleware = createSagaMiddleware()
const epicMiddleware = createEpicMiddleware({
    dependencies: { ajax },
})
const middleware = [
    validator,
    auth,
    api,
    sagaMiddleware,
    epicMiddleware,
]

if (!isNodeEnvProd) {
    const logger = createLogger({
        collapsed: true,
        stateTransformer: (state: any) => Object.keys(state).reduce((stateMap, reducerName) => ({
            ...stateMap,
            [reducerName]: Object.keys(state[reducerName]).reduce((reducerMap, prop) => ({
                ...reducerMap,
                [prop]: !!state[reducerName][prop] && typeof state[reducerName][prop].toJS === 'function'
                    ? state[reducerName][prop].toJS()
                    : state[reducerName][prop],
            }), {}),
        }), {}),
    })
    middleware.push(logger)
}

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        // todo  ideally this wouldn't be set, but because complex class instances/immutable objects are stored in
        // todo  reducers it's necessary. Long-term plain objects would be stored in reducers, and selected into class
        // todo  instances or immutable objects.
        serializableCheck: false,
        immutableCheck: {
            ignoredPaths: ['form'], // This is necessary because nested objects in forms seem to fail invariant checks
        },
    }).concat(middleware),
    enhancers: defaultEnhancers => [autoRehydrate(), ...defaultEnhancers],
    devTools: !isNodeEnvProd,
})

epicMiddleware.run(rootEpic)
sagaMiddleware.run(rootSaga)

export default store
