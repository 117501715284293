import React from 'react'
import * as PropTypes from 'prop-types'

import * as statuses from 'constants/enums/ReleaseStatusTypes'
import Delivered from 'components/Release/Delivered'
import Takendown from 'components/Release/Takendown'
import Pending from 'components/Release/Pending'

const NonEditableRelease = (props) => {
    const {
        release,
        user,
        tab,
        artistCanCreateRelease,
        onClickPill,
        onTakedown,
        onSocialDeliveryRelease,
        onDownloadFiles,
    } = props

    switch (release.album.status) {
        case statuses.RELEASE_STATUS_TAKENDOWN:
            return (
                <Takendown release={release} />
            )
        case statuses.RELEASE_STATUS_NOT_COMPLETED:
        case statuses.RELEASE_STATUS_READY_QC:
        case statuses.RELEASE_STATUS_APPROVED:
        case statuses.RELEASE_STATUS_REJECTED:
            return (
                <Pending release={release} />
            )
        case statuses.RELEASE_STATUS_PAUSED:
            return (
                <Pending release={release} />
            )
        case statuses.RELEASE_STATUS_DELIVERED:
        default:
            return (
                <Delivered
                    release={release}
                    user={user}
                    tab={tab}
                    artistCanCreateRelease={artistCanCreateRelease}
                    onClickPill={onClickPill}
                    onTakedown={onTakedown}
                    onSocialDeliveryRelease={onSocialDeliveryRelease}
                    onDownloadFiles={onDownloadFiles}
                />
            )
    }
}

NonEditableRelease.propTypes = {
    release: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    tab: PropTypes.string,
    artistCanCreateRelease: PropTypes.bool,
    onTakedown: PropTypes.func.isRequired,
    onClickPill: PropTypes.func.isRequired,
    onSocialDeliveryRelease: PropTypes.func.isRequired,
    onDownloadFiles: PropTypes.func.isRequired,
}

export default NonEditableRelease
