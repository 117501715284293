export const enabled = true
export const bustCache = false
export const scriptUri = 'https://static.zdassets.com/ekr/snippet.js'

/**
 * These setting will override the configurations
 * set in the Admin suite (https://spinnup.zendesk.com/agent/admin/widget)
 *
 * + Additional settings are available
 *  @see    - https://developer.zendesk.com/embeddables/docs/widget/introduction#settings
 *
 *  @note   - Any settings not explicit here are subject to change externally and cannot
 *          - be relied upon to be in a default state
 */
export const settings = {
    webWidget: {
        position: {
            horizontal: 'right',
            vertical: 'bottom',
        },
        offset: {
            horizontal: '0px',
            vertical: '20px',
            mobile: {
                horizontal: '0px',
                vertical: '20px',
            },
        },
    },
}
