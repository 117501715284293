import React from 'react'
import * as PropTypes from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

/**
 * Saved
 *
 * Show when something is saved
 *
 * Styleguide: components.saved
 */

const Saved = ({ children }) => (
    <div className="c-saved">
        <FontAwesomeIcon icon="faCheck" />
        <div className="c-saved-text">{children}</div>
    </div>
)

Saved.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Saved
