import createId from 'utilities/createId'
import { hydrateProduct } from 'utilities/hydrator'
import * as types from 'constants/AlbumActionTypes'
import * as trackTypes from 'constants/TrackActionTypes'
import * as releaseTypes from 'constants/ReleaseActionTypes'
// we be removed once we will migrate this reducer to duck pattern
import {
    REQUEST_SAVE,
    RECEIVE_SAVE_SUCCESS,
    RECEIVE_SAVE_FAILURE,
    RECEIVE_PATCH_SUCCESS,
    RECEIVE_PATCH_FAILURE,
} from 'modules/album'

import * as statuses from 'constants/enums/ReleaseStatusTypes'
import * as authTypes from 'constants/AuthActionTypes'
import { AnyAction } from 'redux'
import { ApiDspProduct } from 'models/api/dsp-product'
import Album from 'models/album'
import { RELEASE_STATUS_DELETED } from 'constants/enums/ReleaseStatusTypes'

interface AlbumState {
    isFetching: boolean
    isAdding: boolean
    isUpdating: boolean
    isRemoving: boolean
    performingUpdateRequest: boolean
    isFetchingArtwork: Record<number, boolean>
    failedFetching: boolean
    albums: Record<string, Album>
}
const initialState: AlbumState = {
    isFetchingArtwork: {},
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    performingUpdateRequest: false,
    isRemoving: false,
    failedFetching: false,
    albums: {} as Record<string, Album>,
}

export default function albums(state = initialState, action: AnyAction): AlbumState {
    switch (action.type) {
        // FETCH

        case types.FETCH_ALBUMS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_ALBUMS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                albums: action.payload
                    .filter((product: ApiDspProduct) => product.state !== RELEASE_STATUS_DELETED)
                    .reduce((map: Record<number, Album>, product: ApiDspProduct) => ({
                        ...map,
                        [product.id]: hydrateProduct({
                            ...product,
                            completionLevel: 1,
                        }, state.albums[product.id]),
                    }), {}),
            }

        case types.FETCH_ALBUMS_FAILURE:
            return {
                ...state,
                isFetching: false,
                failedFetching: true,
            }

            // FETCH SINGLE

        case types.FETCH_ALBUM_SUCCESS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.payload.id]: hydrateProduct({
                        ...action.payload,
                        completionLevel: 2,
                    },
                    state.albums[action.payload.id]),
                },
            }
        case types.FETCH_ALBUM_ARTWORK_REQUEST:
            return {
                ...state,
                isFetchingArtwork: {
                    ...state.isFetchingArtwork,
                    [action.meta.id]: true,
                },
            }

        case types.FETCH_ALBUM_ARTWORK_SUCCESS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        coverArt: action.payload.data,
                    }),
                },
                isFetchingArtwork: {
                    ...state.isFetchingArtwork,
                    [action.meta.id]: false,
                },
            }

        case types.FETCH_ALBUM_ARTWORK_FAILURE:
            return {
                ...state,
                isFetchingArtwork: {
                    ...state.isFetchingArtwork,
                    [action.meta.id]: false,
                },
            }

            // CREATE

        case types.CREATE_ALBUM_SUCCESS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.payload.id]: hydrateProduct(action.payload),
                },
            }

        // UPDATE
        case REQUEST_SAVE:
            return {
                ...state,
                isUpdating: true,
                // albums: state.albums.setIn([action.payload.id, 'errors'], {}),
                albums: {
                    ...state.albums,
                    [action.payload.id]: state.albums[action.payload.id].clone({ errors: {} }),
                },
            }
        case types.UPDATE_ALBUM_REQUEST:
            return {
                ...state,
                isUpdating: true,
                performingUpdateRequest: true,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({ errors: {} }),
                },
            }
        case RECEIVE_SAVE_SUCCESS:
        case RECEIVE_PATCH_SUCCESS:
        case types.UPDATE_ALBUM_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                performingUpdateRequest: action.type === types.UPDATE_ALBUM_SUCCESS
                    ? false : state.performingUpdateRequest,
                albums: {
                    ...state.albums,
                    [action.payload.id]: hydrateProduct(action.payload, state.albums[action.payload.id]),
                },
            }
        case RECEIVE_SAVE_FAILURE:
        case RECEIVE_PATCH_FAILURE:
            const nextState = {
                ...state,
                isUpdating: false,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        errors: action.payload.message,
                    }),
                },
            }

            if (action.meta.field === 'artists') {
                return {
                    ...nextState,
                    albums: {
                        ...state.albums,
                        [action.meta.id]: state.albums[action.meta.id].clone({
                            [action.meta.field]: [],
                        }),
                    },
                }
            }
            return nextState

        case types.UPDATE_ALBUM_FAILURE:
            return {
                ...state,
                isUpdating: false,
                performingUpdateRequest: false,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({ errors: action.error }),
                },
            }

            // REMOVE

        case types.REMOVE_ALBUM_SUCCESS:
            const updatedAlbums = { ...state.albums }
            delete updatedAlbums[action.meta.id]

            return {
                ...state,
                albums: updatedAlbums,
            }

        // TAKEDOWN
        case releaseTypes.TAKEDOWN_RELEASE_SUCCESS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.albumId]: state.albums[action.meta.albumId].clone({
                        status: statuses.RELEASE_STATUS_TAKENDOWN,
                    }),
                },
            }

            // UPLOAD

        case types.UPLOAD_COVER_REQUEST:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        isUploading: true,
                        uploadErrors: [],
                        coverArtUserConfirmed: false,
                        packageErrorMessages: {},
                    }),
                },
            }

        case types.UPLOAD_COVER_SUCCESS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        isUploading: false,
                        coverArtUrl: action.payload.meta.url,
                    }),
                },
            }

        case types.UPLOAD_COVER_UPDATED:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        isUploading: false,
                        coverArtId: createId(),
                    }),
                },
            }

        case types.UPLOAD_COVER_FAILURE:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        isUploading: false,
                        uploadErrors: action.error,
                    }),
                },
            }

        case types.UPLOAD_COVER_PROGRESS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        uploadProgress: action.payload,
                    }),
                },
            }

        case types.UPLOAD_COVER_ABORT:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        isUploading: false,
                    }),
                },
            }

        case types.REMOVE_COVER:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        coverArtUrl: undefined,
                        coverArt: undefined,
                        coverArtUserConfirmed: false,
                    }),
                },
            }

        case types.CONFIRM_COVER:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        coverArtUserConfirmed: true,
                    }),
                },
            }

        case types.UNCONFIRM_COVER:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        coverArtUserConfirmed: false,
                    }),
                },
            }

            // VALIDATE

        case types.VALIDATE_SETTINGS_SUCCESS:
        case types.VALIDATE_SETTINGS_FAILURE:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        settingsErrorMessages: {
                            ...state.albums[action.meta.id].settingsErrorMessages,
                            ...action.payload,
                        },
                    }),
                },
            }

        case types.VALIDATE_PACKAGE_SUCCESS:
        case types.VALIDATE_PACKAGE_FAILURE:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.meta.id]: state.albums[action.meta.id].clone({
                        packageErrorMessages: action.payload,
                    }),
                },
            }

            // DEFAULT

        case trackTypes.REMOVE_TRACK_SUCCESS: {
            const currentAlbums = state.albums
            const originalAlbum = currentAlbums[action.meta.albumId]
            const updatedAlbumsTrackRemoved = {
                ...currentAlbums,
                [action.meta.albumId]: originalAlbum.clone({
                    tracksOrder: originalAlbum.tracksOrder.filter(t => t !== action.meta.id),
                    tracks: originalAlbum.tracks.filter((t: any) => t.id !== action.meta.id),
                    numberOfTracks: originalAlbum.numberOfTracks - 1,
                }),
            }

            return {
                ...state,
                albums: updatedAlbumsTrackRemoved,
            }
        }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
