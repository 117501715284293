import React from 'react'
import * as PropTypes from 'prop-types'
import { TOAST_LIFESPAN, TOAST_TYPES } from 'constants/ToastNotificationsConstants'

class Toast extends React.PureComponent {
    componentDidMount() {
        setTimeout(() => {
            this.props.remove()
        }, TOAST_LIFESPAN)
    }

    render() {
        const { type, content } = this.props

        const toastBaseClass = 'c-toasts-toast'
        const toastClass = `${toastBaseClass} ${type === TOAST_TYPES.error ? 'c-toasts-error' : 'c-toasts-success'}`

        return (
            <div className={toastClass}>
                <span className="c-toast-content">{content}</span>
            </div>
        )
    }
}

Toast.propTypes = {
    remove: PropTypes.func.isRequired,
    content: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TOAST_TYPES)),
}

export default Toast
