import { REHYDRATE } from 'redux-persist/constants'
import * as types from 'constants/WalletActionTypes'
import { formatErrors } from 'utilities/error'
import { WalletAccount } from 'models/walletAccount'
import * as authTypes from 'constants/AuthActionTypes'
import { Transaction } from 'models/transaction'
import { AnyAction } from 'redux'
import { ApiTransaction } from 'models/api/transaction'

type WithdrawFeesState = {
    isLoading: boolean
    isLoaded: boolean
    error?: any
    fees: {
        [countryCode: string]: {
            [trmKey: string]: {
                [currencyCode: string]: string
            }
        }
    }
}

export type BalanceState = {
    amount: string
    formatted: string
    formattedLocal: string
}

type WalletState = {
    isFetching: boolean
    isWithdrawing: boolean
    transactions: Transaction[]
    errors: any

    isFetchingBalance: boolean
    balance: BalanceState
    balanceErrors: boolean

    account: WalletAccount
    accountAuthToken: string
    accountError: boolean
    isFetchingAccount: boolean
    hasFetchedAccount: boolean

    withdrawFees: WithdrawFeesState
}

const initialState: WalletState = {
    isFetching: false,
    isWithdrawing: false,
    transactions: [],
    errors: {},

    isFetchingBalance: false,
    balance: {
        amount: '',
        formatted: '',
        formattedLocal: '',
    },
    balanceErrors: false,

    account: new WalletAccount({}),
    accountAuthToken: '',
    accountError: false,
    isFetchingAccount: false,
    hasFetchedAccount: false,

    withdrawFees: {
        isLoading: false,
        isLoaded: false,
        fees: {},
    },
}

export default function wallet(state = initialState, action: AnyAction): WalletState {
    switch (action.type) {
        // BALANCE

        case types.FETCH_BALANCE_REQUEST:
            return {
                ...state,
                isFetchingBalance: true,
                balanceErrors: false,
            }

        case types.FETCH_BALANCE_SUCCESS:
            return {
                ...state,
                isFetchingBalance: false,
                balance: action.payload,
            }

        case types.FETCH_BALANCE_FAILURE:
            return {
                ...state,
                isFetchingBalance: false,
                balanceErrors: true,
            }

            // TRANSACTIONS

        case types.FETCH_TRANSACTIONS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                transactions: action.payload.map((t: ApiTransaction) => new Transaction(t)),
            }

        case types.FETCH_TRANSACTIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
            }

            // WITHDRAW

        case types.WITHDRAW_REQUEST:
            return {
                ...state,
                isWithdrawing: true,
            }

        case types.WITHDRAW_SUCCESS:
            return {
                ...state,
                isWithdrawing: false,
                balance: action.payload,
            }

        case types.WITHDRAW_FAILURE:
            return {
                ...state,
                isWithdrawing: false,
                errors: formatErrors(action.error.errors),
            }

        case types.FETCH_WALLET_ACCOUNT_REQUEST:
            return {
                ...state,
                isFetchingAccount: true,
            }

        case types.FETCH_WALLET_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetchingAccount: false,
                hasFetchedAccount: true,
                account: new WalletAccount(action.payload),
                accountError: false,
            }

        case types.FETCH_WALLET_ACCOUNT_FAILURE:
            return {
                ...state,
                isFetchingAccount: false,
                hasFetchedAccount: action.error.httpStatusCode === 404,
                accountError: action.error.httpStatusCode !== 404 && action.error,
            }

        case types.CREATE_WALLET_ACCOUNT_REQUEST:
        case types.UPDATE_WALLET_ACCOUNT_REQUEST:
            return {
                ...state,
                isFetchingAccount: true,
            }

        case types.CREATE_WALLET_ACCOUNT_SUCCESS:
        case types.UPDATE_WALLET_ACCOUNT_SUCCESS:
            return {
                ...state,
                isFetchingAccount: false,
                hasFetchedAccount: true,
                account: new WalletAccount(action.payload),
            }

        case types.CREATE_WALLET_ACCOUNT_FAILURE:
        case types.UPDATE_WALLET_ACCOUNT_FAILURE:
            return {
                ...state,
                isFetchingAccount: false,
            }

        case types.FETCH_WITHDRAW_FEES_REQUEST:
            return {
                ...state,
                withdrawFees: {
                    isLoading: true,
                    isLoaded: false,
                    fees: {},
                },
            }

        case types.FETCH_WITHDRAW_FEES_SUCCESS:
            return {
                ...state,
                withdrawFees: {
                    isLoading: false,
                    isLoaded: true,
                    fees: {
                        ...action.payload,
                    },
                },
            }

        case types.FETCH_WITHDRAW_FEES_FAILURE:
            return {
                ...state,
                withdrawFees: {
                    isLoading: false,
                    isLoaded: false,
                    error: action.payload,
                    fees: {},
                },
            }

        // REHYDRATE
        case REHYDRATE:
            if (action.payload.wallet) {
                return {
                    ...action.payload.wallet,
                    transactions: action.payload.wallet.transactions.slice(),
                }
            }
            return state

        case authTypes.UNAUTH:
            return {
                ...initialState,
                transactions: [],
            }

        default:
            return state
    }
}
