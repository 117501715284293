import * as types from 'constants/DspLookupActionTypes'

const initialState = {
    isLookingUp: false,
    failedFetching: false,
}

export default function dspLookup(state = initialState, action) {
    switch (action.type) {
        case types.DSP_LOOKUP_REQUEST:
            return {
                ...state,
                isLookingUp: true,
                failedFetching: false,
            }

        case types.DSP_LOOKUP_SUCCESS:
            return {
                ...state,
                isLookingUp: false,
                failedFetching: false,
            }

        case types.DSP_LOOKUP_FAILURE:
            return {
                ...state,
                isLookingUp: false,
                failedFetching: true,
            }

        default:
            return state
    }
}
