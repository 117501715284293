import { createSelector } from 'reselect'
import t from 'utilities/translate'

export const translationLanguagesSelector = state => state.translationLanguages.translationLanguages

export const getTranslationLanguagesSelectOptions = createSelector(
    translationLanguagesSelector,
    translationLanguages => (
        translationLanguages.toArray().map(language => ({
            value: language.id,
            label: language.name,
        }))
    )
)

export const getNativeTranslationLanguagesSelectOptions = createSelector(
    translationLanguagesSelector,
    translationLanguages => (
        translationLanguages.toArray().map(language => ({
            value: language.id,
            label: t(`formEditProfileLanguageOption_${language.id}`),
        }))
    )
)
