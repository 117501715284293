import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import nl2br from 'utilities/nl2br'

export default class Popover extends Component {
    static propTypes = {
        title: PropTypes.string,
        text: PropTypes.string,
        children: PropTypes.node,
        show: PropTypes.bool,
        triggerByProp: PropTypes.bool,
        fixBodyToBottom: PropTypes.bool,
        textOnly: PropTypes.bool,
        onToggle: PropTypes.func,
        className: PropTypes.string,
        rightArrow: PropTypes.bool,
    };

    render() {
        const {
            title,
            text,
            children,
            show,
            triggerByProp,
            fixBodyToBottom,
            textOnly,
            onToggle,
            className,
            rightArrow,
        } = this.props

        const popoverClassName = classnames(
            'c-popover', {
                show,
                'prop-trigger': triggerByProp,
                'fix-body-to-bottom': fixBodyToBottom,
            }
        )

        const popoverBodyClassName = classnames(
            className,
            'c-popover-body', {
                'right-arrow': rightArrow,
                'text-only': textOnly,
            }
        )

        return (
            <div className={popoverClassName}>
                {children}
                <div className={popoverBodyClassName}>
                    <span className="c-popover-close" onClick={onToggle}>
                        <FontAwesomeIcon icon="faTimes" />
                    </span>
                    <p className="c-popover-title">{title}</p>
                    <p className="c-popover-text" dangerouslySetInnerHTML={{ __html: nl2br(text) }} />
                </div>
            </div>
        )
    }
}
