import { ApiDspArtist } from 'models/api/dsp-product'
import { IArtistIdentifier } from 'models/artistIdentifier'
import { ApiDspTranslation } from 'models/api/dsp-translation'

export type ArtistType = 'primary' | 'featured'
export const ARTIST_TYPE_PRIMARY: ArtistType = 'primary'
export const ARTIST_TYPE_FEATURED: ArtistType = 'featured'

export class Artist {
    id: string = ''

    artistType: ArtistType = ARTIST_TYPE_PRIMARY

    artistName: string = ''

    artistNameKatakana: string = ''

    artistNameOverseas: string = ''

    parentId?: string

    followers: any[] = []// todo

    image?: any // todo

    identifiers: IArtistIdentifier[] = []

    translations: ApiDspTranslation[] = []

    constructor(apiArtist: ApiDspArtist) {
        Object.assign(this as Artist, apiArtist)
    }
}
