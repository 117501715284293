import * as types from 'constants/PreviewActionTypes'
import { previewSelector } from 'selectors/preview'
import { CALL_API } from 'middleware/api'

export function fetchPreviewToken(id) {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_PREVIEW_TOKEN_REQUEST,
                types.FETCH_PREVIEW_TOKEN_SUCCESS,
                types.FETCH_PREVIEW_TOKEN_FAILURE,
            ],
            payload: {
                method: 'post',
                endpoint: `profile/artist/${id}/preview`,
            },
        },
    }
}

export function openPreview(id) {
    return async (dispatch, getState) => {
        await dispatch(fetchPreviewToken(id))
        const preview = previewSelector(getState())

        if (preview.token) {
            const url = `${window.env.SPINNUP_PROFILE_URL}preview/${preview.token}`
            window.open(url, 'profile-preview')
        }
    }
}
