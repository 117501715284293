import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { filteredReleasesSelector } from 'selectors/releases'
import t, { tmarkdown } from 'utilities/translate'
import { fetchTrends, toggleVisibility, fetchTerritoryTrends } from 'actions/TrendActions'

import Alert from 'components/Alert/Alert'
import Divider from 'components/Divider/Divider'
import TrendsChart from 'components/TrendsChart/TrendsChart'
import TrendCountryTotals from 'components/TrendCountryTotals/TrendCountryTotals'
import TrendDspTotals from 'components/TrendDspTotals/TrendDspTotals'
import TrendsFilter from 'components/TrendsFilter/TrendsFilter'

import {
    makeLabelsSelector,
    makeDspTotalsSelector,
    makeDspDataSelector,
    makeDspIconSelector,
    exampleDspData,
    makeFilterOptionsSelector,
    trendsCountryExampleDataSelector,
    trendsTerritoryTotalsSelector,
    TYPE_MAIN,
} from 'selectors/trends'

class TrendsTracksContainer extends Component {
    static propTypes = {
        children: PropTypes.node,

        artistId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        fetchTrends: PropTypes.func.isRequired,
        toggleVisibility: PropTypes.func.isRequired,
        dspTotals: PropTypes.array.isRequired,
        dspData: PropTypes.array.isRequired,
        dspIcons: PropTypes.array.isRequired,
        dspTerritoryTotals: PropTypes.object.isRequired,
        exampleData: PropTypes.object.isRequired,
        territoryExampleData: PropTypes.object.isRequired,
        filterOptions: PropTypes.object.isRequired,
        isDspLoading: PropTypes.bool.isRequired,
        isTerritoryLoading: PropTypes.bool.isRequired,
        releases: PropTypes.array.isRequired,
        fetchTerritoryTrends: PropTypes.func.isRequired,
        labels: PropTypes.array.isRequired,

        failedFetchingDsps: PropTypes.bool.isRequired,
        failedFetchingTerritories: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        this.props.fetchTrends(this.props.artistId, this.props.filterOptions)
        this.props.fetchTerritoryTrends(this.props.artistId, this.props.filterOptions)
    }

    getComponentTitle = (filterOptions) => {
        const titles = {
            stream: {
                365: 'totalMonthlyStreamsTitle',
                90: 'totalWeeklyStreamsTitle',
                30: 'totalDailyStreamsTitle',
                7: 'totalDailyStreamsTitle',
            },
            download: {
                365: 'totalMonthlyDownloadsTitle',
                90: 'totalWeeklyDownloadsTitle',
                30: 'totalDailyDownloadsTitle',
                7: 'totalDailyDownloadsTitle',
            },
        }
        return t(titles[filterOptions.saleType][filterOptions.days])
    }

    render() {
        const {
            dspTotals,
            dspData,
            labels,
            dspIcons,
            exampleData,
            artistId,
            filterOptions,
            isDspLoading,
            isTerritoryLoading,
            releases,
            failedFetchingDsps,
            failedFetchingTerritories,
            dspTerritoryTotals,
            territoryExampleData,
            children,
        } = this.props

        let { chartData } = exampleData
        let chartLabels = exampleData.labels
        let { totals } = exampleData
        let territoryTotals = territoryExampleData
        const hasReleases = releases.length > 0

        if (hasReleases) {
            chartData = dspData
            chartLabels = labels
            totals = dspTotals
            territoryTotals = dspTerritoryTotals
        }

        if (failedFetchingDsps) {
            chartData = []
            totals = dspTotals.map(dspTotal => ({ displayName: dspTotal.displayName, total: 0 }))
        }

        if (failedFetchingTerritories || territoryTotals.data.length === 0) {
            territoryTotals.data = territoryExampleData.data.map(item => ({
                ...item,
                total: 0,
                percentageChange: 0,
            }))
        }

        return (
            <div>
                <Alert type="info">
                    {hasReleases
                        && (
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: tmarkdown('viewTrendsAlertText') }} />
                            </div>
                        )
                    || (
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: tmarkdown('viewTrendsAlertTextNoReleases') }} />
                        </div>
                    )}
                </Alert>

                <TrendsFilter
                    filterTrends={this.props.fetchTrends}
                    filterOptions={filterOptions}
                    artistId={artistId}
                    isLoading={isDspLoading}
                    releases={releases}
                    hasReleases={hasReleases}
                    failedFetching={failedFetchingDsps && failedFetchingTerritories}
                />

                <Divider imageId="dune-2" />

                <TrendDspTotals
                    dspTotals={totals}
                    isLoading={isDspLoading}
                    saleType={filterOptions.saleType}
                    hasReleases={hasReleases}
                    failedFetching={failedFetchingDsps}
                />

                <Divider imageId="spiral-2" />

                <TrendsChart
                    data={chartData}
                    labels={chartLabels}
                    toggleIcons={dspIcons}
                    filterOptions={filterOptions}
                    toggleVisibility={this.props.toggleVisibility}
                    isLoading={isDspLoading}
                    isExampleData={!hasReleases}
                    failedFetching={failedFetchingDsps}
                    title={this.getComponentTitle(filterOptions)}
                />

                <Divider imageId="paint" />

                <TrendCountryTotals
                    dspTerritoryTotals={territoryTotals.data}
                    dspTerritoryTotalRows={territoryTotals.totalRows}
                    isLoading={isTerritoryLoading}
                    saleType={filterOptions.saleType}
                    failedFetching={failedFetchingTerritories}
                    filterOptions={filterOptions}
                    artistId={artistId}
                    fetchTerritoryTrends={this.props.fetchTerritoryTrends}
                    exampleData={territoryExampleData}
                    hasReleases={hasReleases}
                />
                {children}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    artistId: state.users.user.artist.id,
    dspTotals: makeDspTotalsSelector(TYPE_MAIN)(state),
    dspData: makeDspDataSelector(TYPE_MAIN)(state),
    labels: makeLabelsSelector(TYPE_MAIN)(state),
    dspIcons: makeDspIconSelector(TYPE_MAIN)(state),
    exampleData: exampleDspData(state),
    filterOptions: makeFilterOptionsSelector(TYPE_MAIN)(state),
    isDspLoading: state.trends.dsps.isFetching,
    isTerritoryLoading: state.trends.territories.isFetching,
    releases: filteredReleasesSelector(state, 'live-takedown'),
    dspTerritoryTotals: trendsTerritoryTotalsSelector(state),
    failedFetchingDsps: state.trends.dsps.failedFetching,
    failedFetchingTerritories: state.trends.territories.failedFetching,
    territoryExampleData: trendsCountryExampleDataSelector(),
})

const mapDispatchToProps = {
    fetchTrends, toggleVisibility, fetchTerritoryTrends,
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendsTracksContainer)
