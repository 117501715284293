import React from 'react'
import PropTypes from 'prop-types'
import ArtistLookup from 'containers/ArtistLookup/ArtistLookup'
import t, { tmarkdown } from 'utilities/translate'

const FollowArtistLookup = ({
    input,
    meta,
}) => {
    const { value, onChange } = input
    const { error, touched } = meta

    return (
        <ArtistLookup
            artists={value}
            label={t('componentFollowArtistLookupLabel')}
            placeholder={t('componentFollowArtistLookupPlaceholder')}
            blacklistedErrorMsg={term => tmarkdown('componentFollowArtistLookupBlacklisted', term)}
            popover={{
                title: t('containerReleaseSettingsArtistInputHelpTitle'),
                text: tmarkdown('containerReleaseSettingsArtistInputHelpText'),
            }}
            error={touched ? t(error) : null}
            onChange={onChange}
            helpTextMsg=""
            multi={false}
            allowCreate={false}
            clearable
            onTranslationSave={() => { }}
            artistTranslations={[]}
            disableTranslations
        />
    )
}

FollowArtistLookup.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.shape({
            value: PropTypes.object,
            label: PropTypes.string,
        }),
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool,
    }).isRequired,
}

export default FollowArtistLookup
