import { debounce } from 'lodash'
import moment from 'moment'
import * as types from 'constants/ReleaseActionTypes'
import * as albumTypes from 'constants/AlbumActionTypes'
import { URL_EDIT_RELEASE_REVIEW, URL_EDIT_RELEASE_REVIEW_TITLE } from 'constants/AppUrls'
import { sendEventToDataLayer } from 'utilities/analytics'
import {
    postAlbum, getAlbums, getAlbum,
} from 'actions/AlbumActions'
import { callApi, callApiThunk } from 'actions/ApiActions'
import { AppStoreDispatch } from 'store/store-types'
import { AnyAction } from 'redux'
import { GADataLayerEvent } from 'models/GADataLayerEvent'
import Release from 'models/release'

const getReleases = () => callApi(
    [
        types.FETCH_RELEASES_REQUEST,
        types.FETCH_RELEASES_SUCCESS,
        types.FETCH_RELEASES_FAILURE,
    ], {
        endpoint: 'releases',
        method: 'get',
        query: {
            scope: 'mine',
            part: 'price,payments,linkfire,recordContract,subscription.orders,subscription.type',
            paginate: '0:500',
        },
    }
)

export function fetchReleases() {
    return (dispatch: AppStoreDispatch) => Promise.all([
        dispatch(getAlbums()),
        dispatch(getReleases()),
    ])
}

export const getRelease = (id: number) => callApi(
    [
        types.FETCH_RELEASE_REQUEST,
        types.FETCH_RELEASE_SUCCESS,
        types.FETCH_RELEASE_FAILURE,
    ], {
        endpoint: `releases/${id}`,
        method: 'get',
        query: {
            scope: 'mine',
            part: 'releaseType,price,payments,spotify,recordContract,tracks,subscription.orders,subscription.type',
        },
    }
)

export const fetchRelease = (id: number) => (dispatch: AppStoreDispatch) => dispatch(getRelease(id))
    .then(response => response && response.payload && dispatch(getAlbum(response.payload.providerId)))

// CREATE
export const postRelease = (body: any) => callApi(
    [
        types.CREATE_RELEASE_REQUEST,
        types.CREATE_RELEASE_SUCCESS,
        types.CREATE_RELEASE_FAILURE,
    ], {
        method: 'post',
        endpoint: 'releases',
        query: {
            part: 'releaseType',
        },
        body,
    }
)

export function createRelease(releaseTypeId: number, userLanguage: string) {
    return (dispatch: AppStoreDispatch) => dispatch(postAlbum(userLanguage))
        .then((action) => {
            if (action.type === albumTypes.CREATE_ALBUM_SUCCESS) {
                return dispatch(postRelease({
                    providerId: action.payload.id,
                    releaseTypeId,
                }))
            }
            return action
        })
        .catch(() => dispatch({
            type: types.CREATE_RELEASE_FAILURE,
        }))
}

// UPDATE
const patchRelease = (id: number, body: any) => callApi(
    [
        types.UPDATE_RELEASE_REQUEST,
        types.UPDATE_RELEASE_SUCCESS,
        types.UPDATE_RELEASE_FAILURE,
    ], {
        method: 'PATCH', // This needs to be upper case
        endpoint: `releases/${id}`,
        body,
        query: {
            part: 'releaseType',
        },
    }
)

const debouncedUpdateRelease = debounce(
    (id: number, body: any, dispatch: AppStoreDispatch) => dispatch(patchRelease(id, body)),
    300,
    {
        leading: true,
    }
)

export function updateRelease(id: number, body: any) {
    return debouncedUpdateRelease.bind(null, id, body)
}

// REMOVE
export const removeRelease = (releaseId: number) => callApiThunk(
    [
        types.REMOVE_RELEASE_REQUEST,
        types.REMOVE_RELEASE_SUCCESS,
        types.REMOVE_RELEASE_FAILURE,
    ], {
        method: 'delete',
        endpoint: `releases/${releaseId}`,
    }, {
        id: releaseId,
    }
)

// Archive
export const archiveRelease = (id: number) => callApiThunk(
    [
        types.ARCHIVE_RELEASE_REQUEST,
        types.ARCHIVE_RELEASE_SUCCESS,
        types.ARCHIVE_RELEASE_FAILURE,
    ], {
        method: 'post',
        endpoint: `releases/${id}/archive`,
    }, {
        id,
    }
)

// Social Delivery
const socialDeliveryReleaseAction = (id: number, body: any) => callApi(
    [
        types.DELIVER_SOCIAL_RELEASE_REQUEST,
        types.DELIVER_SOCIAL_RELEASE_SUCCESS,
        types.DELIVER_SOCIAL_RELEASE_FAILURE,
    ], {
        method: 'post',
        endpoint: `releases/${id}/socialDelivery`,
        body,
    }, {
        id,
    }
)

export function socialDeliveryRelease(releaseId: number, optedIn: boolean) {
    const body = {
        socialOptedinAt: optedIn ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
    }
    return (dispatch: AppStoreDispatch) => dispatch(socialDeliveryReleaseAction(releaseId, body))
        .then(() => {
            dispatch(fetchRelease(releaseId))
        })
}

export function youtubeDeliveryRelease(releaseId: number, optedIn: boolean) {
    const body = {
        youtubeOptedinAt: optedIn ? moment.utc().format('YYYY-MM-DD HH:mm:ss') : null,
    }
    return (dispatch: AppStoreDispatch) => (
        dispatch(socialDeliveryReleaseAction(releaseId, body))
            .then(() => dispatch(fetchRelease(releaseId)))
    )
}

// CANCEL
export const cancelRelease = (id: number) => callApiThunk(
    [
        types.CANCEL_RELEASE_REQUEST,
        types.CANCEL_RELEASE_SUCCESS,
        types.CANCEL_RELEASE_FAILURE,
    ], {
        method: 'post',
        endpoint: `releases/${id}/cancel`,
    }, {
        id,
    }
)

// TAKEDOWN
export const takedownRelease = (id: number, albumId: number) => callApiThunk(
    [
        types.TAKEDOWN_RELEASE_REQUEST,
        types.TAKEDOWN_RELEASE_SUCCESS,
        types.TAKEDOWN_RELEASE_FAILURE,
    ], {
        method: 'post',
        endpoint: `releases/${id}/takedown`,
    }, {
        id,
        albumId,
    }
)

// PUBLISH
const postPublishRelease = (id: number) => callApi(
    [
        types.PUBLISH_RELEASE_REQUEST,
        types.PUBLISH_RELEASE_SUCCESS,
        types.PUBLISH_RELEASE_FAILURE,
    ], {
        method: 'post',
        endpoint: `releases/${id}/submission`,
    }, {
        id,
    }
)

export function publishRelease(release: Release, user: any) {
    return (dispatch: AppStoreDispatch) => dispatch(postPublishRelease(release.id))
        .then((action) => {
            if (action.type === types.PUBLISH_RELEASE_SUCCESS) {
                const pagePath = URL_EDIT_RELEASE_REVIEW(release.id)
                const eventObj: Partial<GADataLayerEvent> = {
                    pagePath,
                    pageTitle: URL_EDIT_RELEASE_REVIEW_TITLE,
                    pageUrl: `${window.location.host}${pagePath}`,
                    currencyCode: release.releaseType?.price.currency,
                    name: release.releaseType?.name,
                    id: String(release.releaseType?.id),
                    price: release.releaseType?.price.amount || '',
                }
                if (user) {
                    eventObj.userId = user.id
                    eventObj.country = user.countryId
                    eventObj.market = user.market
                }
                sendEventToDataLayer(
                    'EditReleaseComplete',
                    eventObj
                )
            }

            return action
        })
}

// SELECT

export function selectRelease(id: number): AnyAction {
    return {
        type: types.SELECT_RELEASE,
        payload: id,
    }
}
