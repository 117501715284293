import {
    REMOVE_NOTIFICATION,
    ADD_NOTIFICATION,
} from 'constants/NotificationsActionTypes'
import {
    PUBLISH_DRAFT_PROFILE_SUCCESS,
    PUBLISH_DRAFT_PROFILE_FAILURE,
    UPDATE_DRAFT_PROFILE_SUCCESS,
    UPDATE_DRAFT_PROFILE_FAILURE,
    UPDATE_GALLERY_ORDER_FAILURE,
    UPLOAD_TOUR_SUCCESS,
    UPLOAD_TOUR_FAILURE,
} from 'constants/ProfileActionTypes'
import t from 'utilities/translate'
import { createSuccessToast, createErrorToast } from '../utilities/createToast'

export const initialState = []

const notifications = (state = initialState, action) => {
    let removeIndex
    switch (action.type) {
        case PUBLISH_DRAFT_PROFILE_SUCCESS: {
            return [...state, createSuccessToast(t('viewProfilePublishSuccess'))]
        }
        case PUBLISH_DRAFT_PROFILE_FAILURE: {
            return [...state, createErrorToast(t('viewProfilePublishFailure'))]
        }
        case UPDATE_DRAFT_PROFILE_SUCCESS: {
            return [...state, createSuccessToast(t('viewProfileUpdateSuccess'))]
        }
        case UPDATE_DRAFT_PROFILE_FAILURE: {
            return [...state, createErrorToast(t('viewProfileUpdateFailure'))]
        }
        case UPDATE_GALLERY_ORDER_FAILURE: {
            return [...state, createErrorToast(t('updateGalleryOrderFailure'))]
        }
        case UPLOAD_TOUR_SUCCESS: {
            return [...state, createSuccessToast(t('tourDatesUploadSuccess'))]
        }
        case UPLOAD_TOUR_FAILURE: {
            return [...state, createErrorToast(t('tourDatesUploadFailure'))]
        }
        case ADD_NOTIFICATION: {
            return [...state, action.payload.error ? (createErrorToast(t(action.payload.message))
            ) : createSuccessToast(t(action.payload.message))]
        }
        case REMOVE_NOTIFICATION:
            removeIndex = state.findIndex(msg => msg.id === action.payload)
            return [...state.slice(0, removeIndex), ...state.slice(removeIndex + 1)]
        default:
            return state
    }
}

export default notifications
