import React from 'react'
import { Field as ReduxField } from 'redux-form'
import t from 'utilities/translate'
import { InputField } from '../EditProfileInputs/Inputs'

const renderInputField = (_, index) => (
    <ReduxField
        key={index}
        name={`videos[${index}]`}
        label={t('formEditProfileFormVideoLabel')}
        placeholder={t('formEditProfileFormVideoPlaceholder')}
        component={InputField}
    />
)

const EditProfileVideos = () => (
    <div className="c-epf-videos">
        <p className="c-epf-videos-description">{t('formEditProfileFormVideoDescription')}</p>
        {[...new Array(4)].map(renderInputField)}
    </div>
)

export default EditProfileVideos
