import React from 'react'
import * as PropTypes from 'prop-types'
import { Field as ReduxField, FieldArray } from 'redux-form'
import { CheckboxField } from 'components/EditProfileInputs/Inputs'
import t from 'utilities/translate'

const renderRelease = (releaseIndex, index, { title, releaseType }) => (
    <li key={index} className="c-epf-releases-release">
        <ReduxField
            type="checkbox"
            name={`${releaseIndex}.checked`}
            label={`${title} (${releaseType})`}
            component={CheckboxField}
        />
    </li>
)

const renderReleasesList = ({ fields }) => (
    <ul className="c-epf-releases-list">
        {fields.map((field, index) => renderRelease(field, index, fields.getAll()[index]))}
    </ul>
)

renderReleasesList.propTypes = {
    fields: PropTypes.object.isRequired,
}

const EditProfileReleases = () => (
    <div className="c-epf-releases">
        <p className="c-epf-releases-description">
            {t('formEditProfileFormReleasesDescription')}
        </p>
        <div className="c-epf-releases-wrapper">
            <p className="c-epf-releases-label">
                {t('formEditProfileFormReleasesLabel')}
            </p>
            <FieldArray name="releases" component={renderReleasesList} />
        </div>

    </div>
)

export default EditProfileReleases
