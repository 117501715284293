import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Frame from 'components/Frame/Frame'
import partnersImg from '../../assets/images/partners.png'

export default class PartnersWidget extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        locale: PropTypes.object.isRequired,
    }

    handleClick = () => {
        const { url } = this.props

        if (window.matchMedia('(max-width: 992px)').matches) {
            window.open(url)
        }
    }

    render() {
        const { url, locale } = this.props
        const { t } = locale
        return (
            <section className="c-partners-widget" onClick={this.handleClick}>
                <Frame borderImageId="dune-4" contentTint="light">
                    <img className="c-partners-widget-img" src={partnersImg} alt="" />

                    <div className="c-partners-widget-content">
                        <header>
                            <h1 className="h3">{t('componentPartnersWidgetHeading')}</h1>
                        </header>

                        <p>{t('componentPartnersWidgetBody')}</p>

                        <p>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                                {t('componentPartnersWidgetLink')}
                            </a>
                        </p>
                    </div>
                </Frame>
            </section>
        )
    }
}
