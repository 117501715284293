import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import classnames from 'classnames'

export default class Toggle extends Component {
    static propTypes = {
        value: PropTypes.any,
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
    }

    render() {
        const { value, options } = this.props

        return (
            <ul className="c-toggle">
                {options.map((option, index) => {
                    const className = classnames('c-toggle-item', {
                        active: value === option.value,
                    })

                    return (
                        <li key={index} className={className} onClick={option.onClick}>
                            <span className="c-toggle-label">{option.label}</span>
                        </li>
                    )
                })}
            </ul>
        )
    }
}
