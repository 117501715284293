import React from 'react'
import { PropTypes } from 'prop-types'
import Button from 'components/Button/Button'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { getPopupDataEditProfileViewPrivateProfile } from 'utilities/popups'

const EditProfileViewButton = ({
    url,
    hasPublicProfile,
    openPopup,
}) => {
    const handleClick = () => {
        if (hasPublicProfile) {
            window.open(url, 'profile')
        } else {
            const popupData = getPopupDataEditProfileViewPrivateProfile()
            openPopup(popupData)
        }
    }

    return (
        <Button className="c-edit-profile-button-view" onClick={handleClick}>
            <FontAwesomeIcon className="c-edit-profile-button-icon" icon="faEye" />
        </Button>
    )
}

EditProfileViewButton.propTypes = {
    url: PropTypes.string.isRequired,
    hasPublicProfile: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
}

export default EditProfileViewButton
