import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Spinner from 'components/Spinner/Spinner'
import genericImage from '../../assets/images/generic-cover.png'

export default class Artwork extends Component {
    static propTypes = {
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        className: PropTypes.string,
        fromSpotify: PropTypes.bool,

        url: PropTypes.string,
        spotifyUrls: PropTypes.object,
        credentials: PropTypes.object,
        useGenericImage: PropTypes.bool,
    }

    static defaultProps = {
        fromSpotify: false,
        useGenericImage: false,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            artworkUrl: null,
        }
    }

    componentDidMount() {
        this.setState({ artworkUrl: this.getSrc() })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.artworkUrl !== this.state.artworkUrl) {
            this.setState({
                isLoading: true,
                artworkUrl: this.getSrc(),
            })
        }
    }

    getWidth = () => {
        const { size } = this.props

        switch (size) {
            case 'small':
                return 130

            case 'medium':
                return 380

            case 'large':
                return 600

            default:
                return null
        }
    }

    getSrc = () => {
        const {
            credentials,
            fromSpotify,
            size,

            url,
            spotifyUrls,
        } = this.props

        let src

        if (fromSpotify) {
            switch (size) {
                // Spotify small is too small for us, so use medium
                case 'small':
                case 'medium':
                    src = spotifyUrls.medium
                    break

                case 'large':
                    src = spotifyUrls.large
                    break
                default:
                    break
            }
        } else if (url) {
            src = `${url}&b64=0&access_token=${credentials.accessToken}`
        }

        return src
    }

    handleImageLoading = (error) => {
        this.setState({
            isLoading: false,
            hasFetchingError: !!error,
        })
    }

    render() {
        const { className, useGenericImage } = this.props
        const { isLoading, hasFetchingError } = this.state

        const src = this.getSrc()

        const artworkClassName = classnames(
            'c-artwork',
            className,
            {
                'c-artwork-placeholder': src === undefined,
            }
        )

        const imageClassName = classnames(
            className, {
                'c-artwork-loading': isLoading,
                'c-artwork-failed-fetch': hasFetchingError,
            }
        )

        return (
            <div className={artworkClassName}>
                <img
                    className={imageClassName}
                    src={useGenericImage === true ? genericImage : src}
                    width={this.getWidth()}
                    onLoad={() => {
                        this.handleImageLoading()
                    }}
                    onError={(error) => {
                        this.handleImageLoading(error)
                    }}
                    alt=""
                />

                {(src === undefined)
                    && (
                        <div className="c-artwork-no-image">
                            <FontAwesomeIcon icon="faImage" size="6x" />
                        </div>
                    )
                || (isLoading && <Spinner size="medium" />)}
            </div>

        )
    }
}
