import * as PropTypes from 'prop-types'

const ArtistProfile = (props) => {
    const { match: { params } } = props

    window.location.href = `${window.env.SPINNUP_PROFILE_URL}${params.slug}`
    return null
}

ArtistProfile.propTypes = {
    match: PropTypes.object.isRequired,
}

export default ArtistProfile
