import React from 'react'
import PropTypes from 'prop-types'

const PhoneNumberInput = ({
    input,
    placeholder,
    className,
    disabled,
}) => (
    <input
        {...input}
        className={className}
        placeholder={placeholder}
        type="tel"
        disabled={disabled}
    />
)

PhoneNumberInput.propTypes = {
    input: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

export default PhoneNumberInput
