import React, { Component } from 'react'

import * as PropTypes from 'prop-types'
import t, { tmarkdown } from 'utilities/translate'
import Button from 'components/Button/Button'
import Input from 'components/Inputs/Input'
import { URL_MY_RELEASES_TITLE } from 'constants/AppUrls'
import { sendEventToDataLayer } from 'utilities/analytics'

export default class Promote extends Component {
    static propTypes = {
        linkfireUrl: PropTypes.string,
        user: PropTypes.object,
        releaseDateReached: PropTypes.bool,
    };

    copyLinkfireLink = () => {
        const {
            user,
        } = this.props

        const eventObj = {
            pagePath: window.location.pathname,
            pageTitle: URL_MY_RELEASES_TITLE,
            pageUrl: window.location.href,
        }
        if (user) {
            eventObj.userId = user.id
            eventObj.country = user.countryId
            eventObj.market = user.market
        }
        sendEventToDataLayer(
            'copyLinkfireURL',
            eventObj
        )
        this.linkfireLink.select()
        document.execCommand('copy')
    }

    render() {
        const {
            linkfireUrl,
            releaseDateReached,
        } = this.props

        const promoteText = releaseDateReached
            ? 'componentReleasePromoteAboutDetails'
            : 'componentReleasePromotePreAboutDetails'

        return (
            <div className="c-release-promote">
                <h4>{t('componentReleasePromoteHeading')}</h4>
                <p dangerouslySetInnerHTML={{ __html: tmarkdown(promoteText) }} />
                <Input
                    type="input"
                    inputClassName="c-release-promote-input"
                    bordered
                    ref={(input) => { this.linkfireLink = input }}
                    value={linkfireUrl}
                    placeholder={t('componentReleaseLinkfirePaceholderText')}
                    isReadOnly
                />
                <div className="c-release-promote-actions">
                    <Button onClick={this.copyLinkfireLink} disabled={!linkfireUrl}>
                        {t('componentReleaseCopyButton')}
                    </Button>
                    <Button href={linkfireUrl} blank disabled={!linkfireUrl}>
                        {t('componentReleaseOpenPlayerButton')}
                    </Button>
                </div>
            </div>
        )
    }
}
