import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { ReactTypeformEmbed } from 'react-typeform-embed'

import ReleaseList from 'components/Release/ReleaseList'
import DownloadReleaseFilesModal from 'components/common/modals/DownloadReleaseFilesModal'
import t from 'utilities/translate'

import {
    removeRelease, takedownRelease, archiveRelease, socialDeliveryRelease, fetchRelease, youtubeDeliveryRelease,
} from 'actions/ReleaseActions'
import { urlsSelector } from 'selectors/urls'
import { filteredReleasesSelector } from 'selectors/releases'
import { userMarketId } from 'selectors/users'
// import { canShowSurvey } from 'utilities/surveys'
import { URL_TAKEDOWN_RELEASES_TITLE } from 'constants/AppUrls'
import { sendEventToDataLayer } from 'utilities/analytics'
import { RELEASE_STATUS_DELIVERED } from 'constants/enums/ReleaseStatusTypes'
import { queueConfirmationModal, queueModal } from 'actions/ModalActions'
import * as popupButtons from 'constants/PopupButtons'
import ReleaseContainer from './Release'

export class ReleasesContainerComponent extends Component {
    static propTypes = {
        releases: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
        removeRelease: PropTypes.func.isRequired,
        archiveRelease: PropTypes.func.isRequired,
        socialDeliveryRelease: PropTypes.func.isRequired,
        youtubeDeliveryRelease: PropTypes.func.isRequired,
        takedownRelease: PropTypes.func.isRequired,
        // urls: PropTypes.object.isRequired,
        market: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired,
        queueModal: PropTypes.func.isRequired,
        queueConfirmationModal: PropTypes.func.isRequired,
        fetchRelease: PropTypes.func.isRequired,
    };

    onTakedown = (releaseId, albumId, title) => {
        const {
            // market,
            releases,
            user,
        } = this.props

        this.props.queueConfirmationModal(
            'confirm-takedown-release',
            t('containerReleaseAlertTakedownTitle', title),
            t('containerReleaseAlertTakedown', title),
            () => {
                // const showSurvey = canShowSurvey(market)
                // if (showSurvey) {
                //     this.takedownSurvey.typeform.open()
                // }

                const releaseCount = releases.filter(
                    release => release.album.status === RELEASE_STATUS_DELIVERED
                ).length
                const eventObj = {
                    pagePath: window.location.pathname,
                    pageTitle: URL_TAKEDOWN_RELEASES_TITLE,
                    pageUrl: window.location.href,
                    releaseID: releaseId,
                    releaseCount,
                }
                if (user) {
                    eventObj.userId = user.id
                    eventObj.country = user.countryId
                    eventObj.market = user.market
                }
                sendEventToDataLayer(
                    'releaseTakedown',
                    eventObj
                )

                this.props.takedownRelease(releaseId, albumId)
            },
            popupButtons.confirm
        )
    }

    onDelete = (releaseId, title, expiresAt, status) => {
        this.props.queueConfirmationModal(
            'confirm-delete-release',
            (!expiresAt && t('containerReleaseAlertDeleteHasPayments', title))
            || t('containerReleaseAlertDelete', title),
            null,
            () => {
                if (status === 'Rejected') {
                    this.props.archiveRelease(releaseId)
                } else {
                    this.props.removeRelease(releaseId)
                }
            }
        )
    }

    onEdit = (releaseId) => {
        this.props.history.push(`/edit/${releaseId}/tracks`)
    }

    onSocialDeliveryRelease = (releaseId, socialOptedinAt) => {
        this.props.socialDeliveryRelease(releaseId, { socialOptedinAt })
    }

    onYoutubeDeliveryRelease = (releaseId, youtubeOptedinAt) => {
        this.props.youtubeDeliveryRelease(releaseId, { youtubeOptedinAt })
    }

    onDownloadReleaseFiles = (release) => {
        if (!release.tracks) {
            this.props.fetchRelease(release.id)
        }

        const modalProps = {
            releaseId: release.id,
        }
        const modalConfig = {
            useBackdrop: true,
            clickBackdropToClose: false,
        }

        this.props.queueModal('release-download-files', DownloadReleaseFilesModal, modalProps, modalConfig)
    }

    render() {
        const {
            releases,
            // urls
        } = this.props

        return (
            <div>
                <ReleaseList>
                    {releases.map(release => (
                        <ReleaseContainer
                            key={release.id}
                            release={release}
                            onTakedown={this.onTakedown}
                            onEdit={this.onEdit}
                            onDelete={this.onDelete}
                            onEditDescription={this.onEditDescription}
                            onSocialDeliveryRelease={this.onSocialDeliveryRelease}
                            onYoutubeToggleRelease={this.onYoutubeDeliveryRelease}
                            onDownloadFiles={this.onDownloadReleaseFiles}
                        />
                    ))}
                </ReleaseList>

                <div className="c-releases-survey-form">
                    {/* <ReactTypeformEmbed */}
                    {/*    url={urls.typeFormReleaseTakedownSurvey} */}
                    {/*    popup */}
                    {/*    autoOpen={false} */}
                    {/*    mode="popup" */}
                    {/*    style={{ top: 1 }} */}
                    {/*    ref={((tf) => { this.takedownSurvey = tf })} */}
                    {/* /> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    releases: filteredReleasesSelector(state, props.filter),
    user: state.users.user,
    urls: urlsSelector(state),
    market: userMarketId(state),
})

const mapDispatchToProps = {
    removeRelease,
    archiveRelease,
    socialDeliveryRelease,
    youtubeDeliveryRelease,
    takedownRelease,
    queueModal,
    queueConfirmationModal,
    fetchRelease,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleasesContainerComponent))
