import { cloneDeep } from 'lodash'
import * as types from 'constants/TrendActionTypes'
import * as authTypes from 'constants/AuthActionTypes'

const dsps = {
    spotify: {
        name: 'spotify',
        displayName: 'Spotify',
        saleType: 'stream',
        data: [],
        isVisible: true,
    },
    applemusic: {
        name: 'applemusic',
        displayName: 'Apple Music',
        saleType: 'both',
        data: [],
        isVisible: true,
    },
    deezer: {
        name: 'deezer',
        displayName: 'Deezer',
        saleType: 'stream',
        data: [],
        isVisible: true,
    },
    amazon: {
        name: 'amazon',
        displayName: 'Amazon Music',
        saleType: 'both',
        data: [],
        isVisible: true,
    },
}

const initialState = {
    dsps: {
        ...cloneDeep(dsps),
        isFetching: false,
        failedFetching: false,
    },
    territories: {
        data: [],
        totalRows: 0,
        isFetching: false,
        failedFetching: false,
    },
    overview: {
        dsps: {
            ...cloneDeep(dsps),
            isFetching: false,
            failedFetching: false,
        },
        filter: {
            days: '7',
            selectionType: 'dsp',
            saleType: 'stream',
            page: 1,
        },
    },
    filter: {
        days: '7',
        selectionType: 'dsp',
        saleType: 'stream',
        releaseId: null,
        asset: null,
        page: 1,
    },
}

export default function trends(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_TRENDS_SUCCESS:
            const trendsData = action.payload.reduce((dspData, dsp) => {
                const { name, data, total } = dsp
                return {
                    ...dspData,
                    [name]: {
                        ...state.dsps[name],
                        name,
                        data,
                        total,
                    },
                }
            }, initialState.dsps)

            return {
                ...state,
                dsps: {
                    ...trendsData,
                    isFetching: false,
                },
            }

        case types.FETCH_TRENDS_REQUEST:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.meta.filter,
                },
                dsps: {
                    ...state.dsps,
                    isFetching: true,
                    failedFetching: false,
                },
            }

        case types.FETCH_OVERVIEW_TRENDS_SUCCESS:
            const trendsOverviewData = action.payload.reduce((dspData, dsp) => {
                const { name, data, total } = dsp
                return {
                    ...dspData,
                    [name]: {
                        ...state.dsps[name],
                        name,
                        data,
                        total,
                    },
                }
            }, initialState.dsps)

            return {
                ...state,
                overview: {
                    ...state.overview,
                    dsps: {
                        ...trendsOverviewData,
                        isFetching: false,
                        failedFetching: false,
                    },
                },
            }

        case types.FETCH_OVERVIEW_TRENDS_REQUEST:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    dsps: {
                        ...state.overview.dsps,
                        isFetching: true,
                        failedFetching: false,
                    },
                },
            }

        case types.FETCH_OVERVIEW_TRENDS_FAILURE:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    dsps: {
                        ...state.overview.dsps,
                        isFetching: false,
                        failedFetching: true,
                    },
                },
            }

        // VISIBILITY
        case types.TRENDS_TOGGLE_VISIBILITY:
            return {
                ...state,
                dsps: {
                    ...state.dsps,
                    [action.meta.dsp]: {
                        ...state.dsps[action.meta.dsp],
                        isVisible: action.payload,
                    },
                },
            }

        case types.TRENDS_TOGGLE_OVERVIEW_VISIBILITY:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    dsps: {
                        ...state.overview.dsps,
                        [action.meta.dsp]: {
                            ...state.overview.dsps[action.meta.dsp],
                            isVisible: action.payload,
                        },
                    },
                },
            }

        case types.FETCH_TRENDS_FAILURE:
            return {
                ...state,
                dsps: {
                    ...state.dsps,
                    isFetching: false,
                    failedFetching: true,
                },
            }

        case types.FETCH_TRENDS_TERRITORYS_REQUEST:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.meta.filter,
                },
                territories: {
                    ...state.territories,
                    isFetching: true,
                    failedFetching: false,
                },
            }

        case types.FETCH_TRENDS_TERRITORYS_SUCCESS:
            const trendsTerritoryData = Object.entries(action.payload.data).reduce((territoryData, ...territory) => {
                const [[, data]] = territory
                const {
                    countryCode, name, total, percentageChange,
                } = data
                return [
                    ...territoryData,
                    {
                        countryCode,
                        name,
                        total,
                        percentageChange,
                    },
                ]
            }, [])

            return {
                ...state,
                territories: {
                    data: trendsTerritoryData,
                    totalRows: action.payload.meta.pagination.total,
                    isFetching: false,
                    failedFetching: false,
                },
            }

        case types.FETCH_TRENDS_TERRITORYS_FAILURE:
            return {
                ...state,
                territories: {
                    ...state.territories,
                    isFetching: false,
                    failedFetching: true,
                },
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
