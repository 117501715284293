// CREATE

export const CREATE_TRACKS = 'CREATE_TRACKS'
export const CREATE_TRACKS_SUCCESS = 'CREATE_TRACKS_SUCCESS'

export const CREATE_TRACK_REQUEST = 'CREATE_TRACK_REQUEST'
export const CREATE_TRACK_SUCCESS = 'CREATE_TRACK_SUCCESS'
export const CREATE_TRACK_FAILURE = 'CREATE_TRACK_FAILURE'

// UPDATE

export const UPDATE_TRACK_REQUEST = 'UPDATE_TRACK_REQUEST'
export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS'
export const UPDATE_TRACK_FAILURE = 'UPDATE_TRACK_FAILURE'

// REMOVE

export const REMOVE_TRACK_REQUEST = 'REMOVE_TRACK_REQUEST'
export const REMOVE_TRACK_SUCCESS = 'REMOVE_TRACK_SUCCESS'
export const REMOVE_TRACK_FAILURE = 'REMOVE_TRACK_FAILURE'

// UPLOAD

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS'
export const UPLOAD_FILE_ABORT = 'UPLOAD_FILE_ABORT'

// DOWNLOAD
export const DOWNLOAD_TRACK_FILE_REQUEST = 'DOWNLOAD_TRACK_FILE_REQUEST'
export const DOWNLOAD_TRACK_FILE_SUCCESS = 'DOWNLOAD_TRACK_FILE_SUCCESS'
export const DOWNLOAD_TRACK_FILE_FAILURE = 'DOWNLOAD_TRACK_FILE_FAILURE'

// VALIDATE

export const VALIDATE_TRACK = 'VALIDATE_TRACK'
export const VALIDATE_TRACK_SUCCESS = 'VALIDATE_TRACK_SUCCESS'
export const VALIDATE_TRACK_FAILURE = 'VALIDATE_TRACK_FAILURE'

// SELECT

export const SELECT_TRACK = 'SELECT_TRACK'
