import { connect } from 'react-redux'

import { doLookup } from 'actions/DspLookupActions'

import ArtistLookup from 'components/ArtistLookup/ArtistLookup'
import { userMarketId } from 'selectors/users'

const mapStateToProps = state => ({
    failedFetching: state.dspLookup.failedFetching,
    market: userMarketId(state),
    user: state.users.user,
})

const mapDispatchToProps = dispatch => ({
    doLookup: (term, market) => dispatch(doLookup('artist', 'spotify', term, market)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArtistLookup)
