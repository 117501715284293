import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import Field from 'components/Field/Field'
import Input from 'components/Inputs/Input'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { isTranslatableMarket } from 'utilities/translate'

const ArtistAliasTranslations = (props) => {
    const {
        onChangeKatakana,
        onChangeOverseas,
        marketId,
        nameKatakana,
        nameOverseas,
    } = props

    const { t, tmarkdown } = useLocaleContext()

    const updateArtistKatakana = (e) => {
        onChangeKatakana(e.target.value)
    }

    const updateArtistOverseas = (e) => {
        onChangeOverseas(e.target.value)
    }

    const nameKatakanaRef = React.createRef()
    const nameOverseasRef = React.createRef()

    useEffect(() => {
        if (nameKatakanaRef.current) {
            nameKatakanaRef.current.setValue(nameKatakana)
        }
    }, [nameKatakana])

    useEffect(() => {
        if (nameOverseasRef.current) {
            nameOverseasRef.current.setValue(nameOverseas)
        }
    }, [nameOverseas])

    if (isTranslatableMarket(marketId)) {
        return (
            <>
                <span
                    className="c-translatible-artist-name-tooltip"
                    dangerouslySetInnerHTML={{
                        __html: tmarkdown('translationModalFeatArtistToolTipText'),
                    }}
                />
                <span
                    className="c-translatible-artist-name-tooltip-bullet"
                    dangerouslySetInnerHTML={{
                        __html: tmarkdown('translationModalArtistToolTipBullet'),
                    }}
                />
                <Field
                    label={t('onboardingArtistNameKatakana')}
                    key="artist-katakana"
                >
                    <Input
                        className="c-input"
                        type="text"
                        name="artist-katakana"
                        onChange={updateArtistKatakana}
                        value={nameKatakana}
                        ref={nameKatakanaRef}
                    />
                </Field>
                <Field
                    label={t('onboardingArtistNameOverseas')}
                    key="artist-overseas"
                >
                    <Input
                        className="c-input"
                        type="text"
                        name="artist-overseas"
                        onChange={updateArtistOverseas}
                        value={nameOverseas}
                        ref={nameOverseasRef}
                    />
                </Field>
            </>
        )
    } else {
        return null
    }
}

ArtistAliasTranslations.propTypes = {
    nameKatakana: PropTypes.string,
    nameOverseas: PropTypes.string,
    onChangeKatakana: PropTypes.func.isRequired,
    onChangeOverseas: PropTypes.func.isRequired,
    marketId: PropTypes.number.isRequired,
}

export default ArtistAliasTranslations
