import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Button from 'components/Button/Button'
import StepList from 'components/Release/StepList'
import t from 'utilities/translate'
import ArtworkContainer from 'containers/Artwork/Artwork'
import Footer from 'components/Release/Footer'

export default class NotCompleted extends Component {
    static propTypes = {
        release: PropTypes.object.isRequired,
        steps: PropTypes.array.isRequired,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
    }

    static defaultProps = {
        isDisabled: false,
    }

    renderButton() {
        const {
            release,
            isDisabled,
            onEdit,
        } = this.props

        const allowEditing = !release.isRemoving && !isDisabled

        if (allowEditing) {
            return (
                <Button onClick={onEdit}>
                    {t('componentReleaseNotCompletedButtonContinue')}
                </Button>
            )
        }
        return null
    }

    render() {
        const {
            release,
            steps,
            onDelete,
        } = this.props

        const footerMessage = 'selectorAlbumStepOne'

        return (
            <>
                <div className="c-release-body">
                    <ArtworkContainer album={release.album} className="c-release-cover-art" />
                    <div className="c-release-not-completed">
                        <span className="c-release-not-completed-heading">
                            {t('componentReleaseNotCompletedHeading')}
                        </span>
                        <StepList steps={steps} />

                        {steps.find(step => step.key === 'review' && !step.completed)
                        && (
                            <div className="c-release-not-completed-actions">
                                {this.renderButton()}

                                {release.isRemoving ? null : (
                                    <a
                                        onClick={onDelete}
                                        className="c-release-not-completed-delete-link"
                                    >{t('componentReleaseNotCompletedLinkDelete')}
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <Footer icon="faInfo" message={t(footerMessage)} />
            </>
        )
    }
}
