import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import removeAccents from 'remove-accents'
import { components } from 'react-select'
import Async from 'react-select/async'
import AsyncCreatable from 'react-select/async-creatable'
import { debounce } from 'lodash'
import classnames from 'classnames'
import { Artist } from 'models/artist'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Field from 'components/Field/Field'
import Spinner from 'components/Spinner/Spinner'
import t, { tmarkdown } from 'utilities/translate'
import { hydrateArtist, hydrateTrackArtistTranslations } from 'utilities/hydrator'
import { DSP_LOOKUP_TYPE_ARTIST_LINK } from 'constants/DspLookupTypes'
import TranslatableArtistLookup from 'containers/TranslatableArtistLookup/TranslatableArtistLookup'
import Popover from 'components/Popover/Popover'
import { MouseOverTriggerContainer } from 'components/MouseOverTriggerContainer/MouseOverTriggerContainer'
import { getSpotifyIdFromUrl } from 'utilities/services'
import { defaultStringify, trimString } from 'utilities/react-select'

export default class ArtistLookup extends Component {
    debouncedFetch = debounce((term, callback) => {
        this.props.doLookup(term, this.props.market).then((action) => {
            if (this.state.searchTerm !== term) {
                // This is an old debounce request that we can ignore
                return
            }

            let options = []
            let isBlacklisted = false
            let blacklistedArtistName = null
            let isInvalidLink = false
            let searchLookupType = null
            let isArtistLinkDuplicate = false
            let artistLinkName = null

            if (action.payload) {
                const {
                    results,
                    blacklisted,
                    blacklistedName,
                    invalidLink,
                    lookupType,
                } = action.payload

                blacklistedArtistName = blacklistedName
                isInvalidLink = invalidLink
                searchLookupType = lookupType
                isBlacklisted = blacklisted

                if (this.props.multi && searchLookupType === DSP_LOOKUP_TYPE_ARTIST_LINK && results[0]) {
                    artistLinkName = removeAccents(results[0].name).toLowerCase()
                    isArtistLinkDuplicate = this.state.artists.find(
                        a => removeAccents(a.label).toLowerCase() === artistLinkName
                    )
                }
                options = results.map(item => ({
                    value: hydrateArtist(item),
                    label: item.name,
                }))
            }

            this.setState({
                isLookingUp: false,
                isSearchTermBlacklisted: isBlacklisted,
                blacklistedName: blacklistedArtistName,
                invalidLink: isInvalidLink,
                lookupType: searchLookupType,
                isArtistLinkDuplicate: !!isArtistLinkDuplicate,
                artistLinkName,
            }, () => {
                callback(options)
            })
        })
    }, 300)

    static propTypes = {
        doLookup: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        multi: PropTypes.bool,
        allowCreate: PropTypes.bool,
        clearable: PropTypes.bool,
        user: PropTypes.object,
        album: PropTypes.object,

        artists: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]),

        // Form stuff
        fieldName: PropTypes.string,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        duplicateErrorMsg: PropTypes.func,
        helpTextMsg: PropTypes.string,
        popover: PropTypes.object,
        error: PropTypes.string,
        failedFetching: PropTypes.bool.isRequired,
        failedFetchingErrorMsg: PropTypes.string,
        blacklistedErrorMsg: PropTypes.func,
        failedFetchingInvalidUriMsg: PropTypes.string,
        market: PropTypes.number.isRequired,
        source: PropTypes.string,
        onTranslationSave: PropTypes.func,
        artistTranslations: PropTypes.array.isRequired,
        disableTranslations: PropTypes.bool,
    }

    static defaultProps = {
        failedFetchingErrorMsg: tmarkdown('componentArtistLookupError'),
        blacklistedErrorMsg: term => tmarkdown('componentArtistLookupBlacklisted', term),
        duplicateErrorMsg: term => tmarkdown('componentArtistLookupDuplicateError', term),
        failedFetchingInvalidUriMsg: tmarkdown('componentArtistLookupInvalidUri'),
        helpTextMsg: t('componentArtistLookupHelptext'),
        multi: true,
        allowCreate: true,
        clearable: false,
    }

    constructor(props) {
        super(props)

        const userAliases = this.props.user && this.props.user.artist && this.props.user.artist.aliases
        const { album } = this.props
        this.state = {
            isFocused: false,
            isLookingUp: false,
            isSearchTermBlacklisted: false,
            blacklistedName: null,
            invalidLink: false,
            lookupType: null,
            searchTerm: null,
            isArtistLinkDuplicate: false,
            artistLinkName: null,
            artists: props.multi && props.artists ? props.artists.map(artist => ({
                label: artist.artistName,
                value: artist,
                isFixed: (window.env.ARTIST_ONBOARDING || false) && (
                    (album && album.isRejected) || artist.artistName === (userAliases ? userAliases.name : false)
                ),
            })) : props.artists,
            artistTooltip: null,
        }
    }

    componentDidUpdate() {
        // Follow Spotify profile is saved as artist url in public site form
        // The initial value for the input will change when an artist record is fetched
        if (!this.props.multi && this.props.artists !== this.state.artists) {
            this.setState({
                artists: this.props.artists,
            })
        }
    }

    selectFilter = (option, rawInput) => {
        const { lookupType } = this.state
        const input = lookupType === 'artist_link' && getSpotifyIdFromUrl(rawInput) !== null
            ? trimString(getSpotifyIdFromUrl(rawInput)).toLowerCase()
            : trimString(rawInput).toLowerCase()
        const candidate = trimString(defaultStringify(option)).toLowerCase()

        return candidate.indexOf(input) > -1
    }

    fetchOptions = (term, callback) => {
        const searchTerm = term.trim()
        const normalisedTerm = removeAccents(searchTerm).toLowerCase()

        const defaultSetState = () => {
            this.setState({
                isLookingUp: true,
                isDuplicate: false,
                searchTerm,
            }, () => {
                this.debouncedFetch(searchTerm, callback)
            })
        }

        if (this.props.multi) {
            const artistValues = this.state.artists.map(artist => removeAccents(artist.label).toLowerCase())
            const isDuplicate = artistValues.find(artistName => artistName === normalisedTerm)

            if (isDuplicate) {
                this.setState({
                    isLookingUp: false,
                    isDuplicate: true,
                    searchTerm,
                })
            } else {
                defaultSetState()
            }
        } else {
            defaultSetState()
        }
    }

    onFocus = () => {
        this.setState({ isFocused: true })
    }

    onBlur = () => {
        this.setState({ isFocused: false })
    }

    onCreateArtist = (newOption) => {
        const artists = this.state.artists.slice() // Create a copy

        artists.push({
            value: new Artist({ artistName: newOption }),
            label: newOption,
        })

        this.onChange(artists)
    }

    onChange = (artists) => {
        let deletedIndex = -1
        const originalArtists = this.state.artists || []
        const newArtists = artists || []
        if (newArtists.length < originalArtists.length) {
            deletedIndex = originalArtists.findIndex((originalArtist) => {
                const originalName = originalArtist.value.artistName
                return !newArtists.find(newArtist => newArtist.value.artistName === originalName)
            })
        }

        this.setState({
            artists: newArtists,
        }, () => {
            this.props.onChange(newArtists, deletedIndex)

            if (deletedIndex !== -1) {
                const translationsToSave = hydrateTrackArtistTranslations(this.props.artistTranslations, deletedIndex)
                this.props.onTranslationSave(translationsToSave)
            }
        })
    }

    onInputChange = value => (value || '').trimStart().replace(/\s{2,}/g, ' ')

    /*
     * By always returning bool true we ensure that the option to
     * create a new artist is always present, even if the exact
     * wording the user has typed shows up as a result. This allows
     * the user to create an artist with the same name as one found
     * in Spotify, for example.
     */
    isOptionUnique = () => true

    showCreateNewOption = inputValue => inputValue && inputValue.length > 0

    renderMenuLoading = props => (
        <components.Menu {...props}>
            <div className="c-artist-lookup-searching">
                <Spinner horizontal size="small">
                    {t('componentArtistLookupSearching')}
                </Spinner>
            </div>
        </components.Menu>
    )

    renderError = (innerProps, errorMessage) => (
        <components.Menu {...innerProps}>
            <div className="c-artist-lookup-error">
                <FontAwesomeIcon
                    className="c-artist-lookup-error-icon"
                    icon="faExclamation"
                    size="2x"
                    fixedWidth={false}
                />
                <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
            </div>
        </components.Menu>
    )

    renderMenu = params => (
        this.state.isLookingUp
            ? this.renderMenuLoading(params)
            : this.renderResults(params)
    )

    renderNoOptionsMessage = ({ innerProps, selectProps }) => (this.props.multi ? null : (
        <div className="c-artist-lookup-noresults" {...innerProps}>
            {
                selectProps.inputValue && selectProps.inputValue.length > 0
                    ? t('componentArtistLookupHelpTextNoResults')
                    : t('componentArtistLookupSearchPromptText')
            }
        </div>
    ))

    renderMenuOption = ({
        value, innerProps, innerRef, isFocused, isDisabled,
    }) => {
        const { searchTerm } = this.state
        const isCreateOption = (typeof value === 'string')

        const classes = classnames(
            isCreateOption ? 'c-artist-lookup-create' : 'c-artist-lookup-option',
            {
                '--is-disabled': isDisabled,
                '--is-focused': isFocused,
            }
        )

        if (isCreateOption) {
            return (
                <div className={classes} {...innerProps} ref={innerRef}>
                    <FontAwesomeIcon
                        className="c-artist-lookup-option-img"
                        icon="faUserPlus"
                        size="2x"
                        fixedWidth
                    />

                    <div className="c-artist-lookup-option-details">
                        <p>{t('componentArtistLookupCreate', searchTerm)}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div
                    className={classes}
                    role="option"
                    {...innerProps}
                    ref={innerRef}
                >
                    <img className="c-artist-lookup-option-img" src={value.image} alt="" />
                    <div className="c-artist-lookup-option-details">
                        <p>{value.artistName}</p>
                        <p className="c-artist-lookup-option-meta">
                            {t('componentArtistLookupFollowers', value.followers)}
                        </p>
                    </div>
                </div>
            )
        }
    }

    onMultiValueHover = (isHovering, value) => {
        this.setState({
            artistTooltip: isHovering ? value : null,
        })
    }

    renderMultiValue = ({ data, innerProps }) => (
        <MouseOverTriggerContainer
            timeout={500}
            {...innerProps}
            onTrigger={v => this.onMultiValueHover(v, data.label)}
        >
            {data.label}
        </MouseOverTriggerContainer>
    )

    renderResults(props) {
        const {
            failedFetching,
            duplicateErrorMsg,
            failedFetchingErrorMsg,
            blacklistedErrorMsg,
            failedFetchingInvalidUriMsg,
        } = this.props

        const {
            isSearchTermBlacklisted,
            isDuplicate,
            searchTerm,
            blacklistedName,
            invalidLink,
            lookupType,
            isArtistLinkDuplicate,
            artistLinkName,
        } = this.state

        const lookupTypeClass = `c-type-${lookupType}`

        if (invalidLink) {
            return (this.renderError(props, failedFetchingInvalidUriMsg))
        }

        if (failedFetching) {
            return (this.renderError(props, failedFetchingErrorMsg))
        }

        if (isArtistLinkDuplicate) {
            return (this.renderError(props, duplicateErrorMsg(artistLinkName)))
        }

        if (isDuplicate) {
            return (this.renderError(props, duplicateErrorMsg(searchTerm)))
        }

        if (isSearchTermBlacklisted) {
            const param = lookupType === DSP_LOOKUP_TYPE_ARTIST_LINK ? blacklistedName : searchTerm
            return (this.renderError(props, blacklistedErrorMsg(param)))
        }

        return (
            <components.Menu {...props}>
                <div className={classnames(lookupTypeClass, 'c-artist-lookup-option-container')}>
                    { props.children }
                </div>
            </components.Menu>
        )
    }

    render() {
        const {
            label,
            fieldName = 'artistLookup',
            placeholder,
            popover,
            error,
            helpTextMsg,
            multi,
            allowCreate,
            clearable,
            source,
            onTranslationSave,
            artistTranslations,
            disableTranslations,
        } = this.props

        const { isFocused, isLookingUp, artists } = this.state
        const SelectType = allowCreate ? AsyncCreatable : Async
        const styles = {
            multiValue: (base, state) => (state.data.isFixed ? { ...base, opacity: '50%' } : base),
            multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
        }

        return (
            <Field
                fieldName={fieldName}
                label={label}
                helpText={helpTextMsg}
                error={error}
                popover={popover}
            >
                <Popover
                    text={this.state.artistTooltip || ''}
                    show={!!this.state.artistTooltip}
                    triggerByProp
                    fixBodyToBottom
                    textOnly
                    onToggle={() => this.onMultiValueHover(false, null)}
                >
                    <TranslatableArtistLookup
                        artists={artists}
                        source={source}
                        onTranslationSave={onTranslationSave}
                        artistTranslations={artistTranslations}
                        disableTranslations={disableTranslations}
                    >
                        <SelectType
                            className={classnames('c-artist-lookup', 'c-select-wrapper')}
                            classNamePrefix="c-select"
                            maxMenuHeight={300}
                            value={artists}
                            placeholder={isFocused ? null : placeholder}
                            isClearable={clearable}
                            backspaceRemovesValue={false}
                            isMulti={multi}
                            isLoading={isLookingUp}
                            defaultOptions={[]}
                            loadOptions={this.fetchOptions}
                            cacheOptions={false}
                            styles={styles}
                            filterOption={this.selectFilter}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onChange={(changedArtists) => {
                                // Leave this as an arrow function - the translatable artist component event doesn't
                                // trigger otherwise
                                this.onChange(changedArtists)
                            }}
                            onCreateOption={(newArtist) => {
                                // Leave this as an arrow function - the translatable artist component event doesn't
                                // trigger otherwise
                                this.onCreateArtist(newArtist)
                            }}
                            onInputChange={this.onInputChange}
                            isOptionUnique={this.isOptionUnique}
                            isValidNewOption={this.showCreateNewOption}
                            createOptionPosition="first"
                            components={{
                                DropdownIndicator: () => null,
                                LoadingIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Menu: this.renderMenu,
                                Option: this.renderMenuOption,
                                NoOptionsMessage: this.renderNoOptionsMessage,
                                MultiValueLabel: this.renderMultiValue,
                            }}
                        />
                    </TranslatableArtistLookup>
                </Popover>
            </Field>
        )
    }
}
