import { connect } from 'react-redux'

import Footer from 'components/Footer/Footer'

import { urlsSelector } from 'selectors/urls'
import { getMenu } from 'modules/menus'
import { getLanguage } from 'utilities/translate'
import { withLocalisation } from 'contexts/localisation/localeProvider'

const mapStateToProps = (state, props) => {
    const { isAuthenticated } = state.auth
    const { isMaintenanceMode } = props

    const menuItems = getMenu({
        isAuthenticated,
    })(state)

    return {
        isAuthenticated,
        isMaintenanceMode,
        menuItems,
        urls: urlsSelector(state),
        isGermanLocale: getLanguage() === 'de',
        artistIsEnhanced: props.artistIsEnhanced,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalisation(Footer))
