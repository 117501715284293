import React from 'react'
import * as PropTypes from 'prop-types'

/**
 * ReleaseList
 *
 * A list of releases
 *
 * Styleguide: components.releaselist
 */

const ReleaseList = ({ children }) => (
    <ul className="c-releases-list">
        {React.Children.map(children, (child, index) => (
            <li key={index}>
                {child}
            </li>
        ))}
    </ul>
)

ReleaseList.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ReleaseList
