import {
    verificationCodeRegex,
    phoneNumberRegex,
    phoneNumberAllowedSpecialCharactersRegex,
} from 'constants/regularExpressions'
import t from 'utilities/translate'
import { stripChars } from 'utilities/string'

export const validatePhone = ({ phonePrefixCountryCode, phoneNumber, phoneNumberConfirmation }) => {
    phoneNumber = stripChars(phoneNumber, phoneNumberAllowedSpecialCharactersRegex)
    phoneNumberConfirmation = stripChars(phoneNumberConfirmation, phoneNumberAllowedSpecialCharactersRegex)

    const validateNumber = () => (phoneNumberRegex.test(phoneNumber)
        ? null : t('viewMfaValidationPhoneNumberInvalid'))
    const validateNumberConfirmation = () => (phoneNumber === phoneNumberConfirmation
        ? null : t('viewMfaValidationPhoneNumberConfirmationInvalid'))
    const phonePrefixError = phonePrefixCountryCode ? null : t('viewMfaValidationPhonePrefixRequired')
    const phoneNumberError = phoneNumber ? validateNumber() : t('viewMfaValidationPhoneNumberRequired')
    const phoneConfirmationError = phoneNumberConfirmation
        ? validateNumberConfirmation()
        : t('viewMfaValidationPhoneNumberConfirmationRequired')

    return {
        phonePrefixCountryCode: phonePrefixError,
        phoneNumber: phoneNumberError,
        phoneNumberConfirmation: phoneConfirmationError,
    }
}

export const validateVerificationCode = ({ verificationCode }) => {
    const validateCode = () => (verificationCodeRegex.test(verificationCode)
        ? null : t('viewMfaValidationVerificationCodeError'))
    const verificationCodeError = verificationCode ? validateCode()
        : t('viewMfaValidationVerificationCodeRequired')
    return {
        verificationCode: verificationCodeError,
    }
}
