import { Record } from 'immutable'

export const ArtistProfile = new Record({
    id: 1,
    profileVisibility: undefined,
    hasPublicProfile: undefined,
    name: undefined,
    languageId: undefined,
    countryId: undefined,
    city: undefined,
    biography: undefined,
    heroPlacement: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    files: {},
    backgroundColor: undefined,
    font: undefined,
    links: [],
    videos: [],
    releases: [],
    slug: undefined,
    tourDates: [],
    surveys: [],
})

export const ArtistFiles = new Record({
    logo: {},
    hero: {},
    gallery: [],
})
