import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { device as d } from 'device.js'
import { Route, Switch, withRouter } from 'react-router-dom'
import * as statuses from 'constants/enums/ReleaseStatusTypes'
import Header from 'containers/Header/Header'
import StepsContainer from 'containers/Steps/Steps'
import Footer from 'containers/Footer/Footer'
import { releaseSelector } from 'selectors/releases'
import { userSelector, artistEnhancedSelector } from 'selectors/users'
import { fetchUsers } from 'actions/UserActions'
import { fetchReleaseTypes } from 'actions/ReleaseTypeActions'
import { fetchRelease } from 'actions/ReleaseActions'
import { fetchGenres } from 'actions/GenreActions'
import { fetchLanguages } from 'actions/LanguageActions'
import { fetchCountries } from 'actions/CountryActions'
import { destroyReleaseForm } from 'modules/release'
import { requestFetch as fetchTrackLanguages } from 'modules/audio-locales'
import useScrollToTopEffect from 'hooks/useScrollToTopEffect'
import UploadTracks from 'views/UploadTracks/UploadTracks'
import ReleaseSettings from 'views/ReleaseSettings/ReleaseSettings'
import PackageRelease from 'views/PackageRelease/PackageRelease'
import ReviewRelease from 'containers/ReviewRelease/ReviewRelease'
import Popup from 'containers/Popup/Popup'
import { FETCH_USERS_FAILURE } from 'constants/UserActionTypes'
import { unauth, logoutAndRedirectTo } from 'actions/AuthActions'
import { URL_HOME } from 'constants/AppUrls'
import Spinner from 'components/Spinner/Spinner'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { releaseTypesSelector } from 'selectors/releaseTypes'

export const EditComponent = (props) => {
    const {
        release, user, releaseTypes, match: { params: { releaseId } }, artistIsEnhanced,
    } = props
    const [enableNavigation, setEnableNavigation] = useState(false)
    const [originalCoverValue] = useState(null)
    const { t } = useLocaleContext()

    const url = `/edit/${releaseId}`

    useScrollToTopEffect()

    useEffect(() => {
        props.fetchRelease(props.match.params.releaseId).then(() => {
            setEnableNavigation(true)
        })

        props.fetchUsers().then((result) => {
            if (result.type === FETCH_USERS_FAILURE && result.error.httpStatusCode === 401) {
                logoutAndRedirectTo(URL_HOME, props.unauth, props.history)
            }
        })
        props.fetchReleaseTypes()

        props.fetchGenres()
        props.fetchLanguages()
        props.fetchCountries()
        props.fetchTrackLanguages()

        if (!d.desktop) {
            props.history.push('/create')
        }
        return () => {
            props.destroyReleaseForm()
        }
    }, [])

    useEffect(() => {
        if (props.release) {
            const { isProcessing, album: { status } } = props.release

            const immutableStatuses = [
                statuses.RELEASE_STATUS_READY_QC,
                statuses.RELEASE_STATUS_APPROVED,
                statuses.RELEASE_STATUS_DELIVERED,
                statuses.RELEASE_STATUS_TAKENDOWN,
            ]

            if (isProcessing || immutableStatuses.includes(status)) {
                props.history.push('/releases')
            }
        }
    })

    const addProps = {
        originalCoverValue,
        release,
    }

    return (
        <div className="l-edit-release">
            <Header artistIsEnhanced={artistIsEnhanced} isUserLoaded={!!user} />
            {
                release && user && releaseTypes.length > 0 ? (
                    <main role="main">
                        <Popup />
                        <StepsContainer release={release} enableNavigation={enableNavigation} />
                        <Switch>
                            <Route path={`${url}/tracks`} render={() => <UploadTracks {...addProps} />} />
                            <Route path={`${url}/settings`} render={() => <ReleaseSettings {...addProps} />} />
                            <Route path={`${url}/package`} render={() => <PackageRelease {...addProps} />} />
                            <Route path={`${url}/review`} render={() => <ReviewRelease {...addProps} />} />
                        </Switch>
                        <StepsContainer release={release} enableNavigation={enableNavigation} />
                    </main>
                ) : (
                    <main role="main" className="l-edit-release-loading">
                        <Spinner size="medium">{t('globalLoading')}</Spinner>
                    </main>
                )
            }
            <Footer
                artistIsEnhanced={artistIsEnhanced}
                isUserLoaded={!!user}
                actions={{ logout: () => logoutAndRedirectTo(URL_HOME, props.unauth, props.history) }}
            />
        </div>
    )
}

EditComponent.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    release: PropTypes.object,
    releaseTypes: PropTypes.array,
    user: PropTypes.object,
    fetchUsers: PropTypes.func.isRequired,
    fetchReleaseTypes: PropTypes.func.isRequired,
    fetchRelease: PropTypes.func.isRequired,
    fetchGenres: PropTypes.func.isRequired,
    fetchLanguages: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchTrackLanguages: PropTypes.func.isRequired,
    destroyReleaseForm: PropTypes.func.isRequired,
    unauth: PropTypes.func.isRequired,
    artistIsEnhanced: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => ({
    user: userSelector(state),
    release: releaseSelector(state, ownProps.match.params.releaseId),
    releaseTypes: releaseTypesSelector(state),
    artistIsEnhanced: artistEnhancedSelector(state),
})

const mapDispatchToProps = {
    fetchUsers,
    fetchReleaseTypes,
    fetchRelease,
    destroyReleaseForm,
    fetchGenres,
    fetchLanguages,
    fetchCountries,
    fetchTrackLanguages,
    unauth,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditComponent))
