import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import { find, isEmpty } from 'lodash'

import DraggableList from 'components/Draggable/DraggableList'
import Draggable from 'components/Draggable/Draggable'
import Alert from 'components/Alert/Alert'
import Spinner from 'components/Spinner/Spinner'

import t, { tmarkdown } from 'utilities/translate'
import { scrollUpToTop } from 'hooks/useScrollToTopEffect'

import TrackUploadProgress from 'containers/TrackUpload/TrackUploadProgress'
import TrackFormContainer from 'containers/TrackForm/TrackForm'
import AudioDropzone from 'components/Dropzone/AudioDropzone'
import { isPlaceholderTitle } from 'utilities/release'

export default class TrackUpload extends Component {
    static propTypes = {
        abortFile: PropTypes.func.isRequired,
        album: PropTypes.object.isRequired,
        createTracks: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
        releaseType: PropTypes.object.isRequired,
        removeTrack: PropTypes.func.isRequired,
        selectTrack: PropTypes.func.isRequired,
        tracks: PropTypes.array.isRequired,
        tracksOrder: PropTypes.array.isRequired,
        trackTitles: PropTypes.array.isRequired,
        updateAlbum: PropTypes.func.isRequired,
        uploadFile: PropTypes.func.isRequired,
        isCreatingTracks: PropTypes.bool,
        fetchVersions: PropTypes.func,
        trackHasFormError: PropTypes.array,
        allForms: PropTypes.array.isRequired,
        isMovingTracks: PropTypes.bool.isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)
        this.state = {
            tracksOrder: props.tracksOrder,
            candidatePublishers: {},
            lastMovedIndex: -1,
        }
    }

    componentDidMount() {
        const {
            fetchVersions,
        } = this.props

        fetchVersions()

        scrollUpToTop()
    }

    componentDidUpdate(prevProps) {
        const { tracksOrder: currentTracksOrder, isMovingTracks: currIsMovingTracks } = this.props
        const { tracksOrder: prevTracksOrder, isMovingTracks: prevIsMovingTracks } = prevProps

        if (currentTracksOrder.length !== prevTracksOrder.length) {
            this.setState({
                tracksOrder: currentTracksOrder,
            })
        }

        if (currIsMovingTracks !== prevIsMovingTracks && !currIsMovingTracks) {
            this.setState({
                lastMovedIndex: -1,
            })
        }
    }

    componentWillUnmount() {
        this.syncCandidatePublishers()
    }

    onMove = (tracksOrder, lastMovedIndex) => {
        const tracks = this.props.tracks.sort((a, b) => {
            if (tracksOrder.indexOf(a.id) < tracksOrder.indexOf(b.id)) {
                return -1
            }
            if (tracksOrder.indexOf(a.id) > tracksOrder.indexOf(b.id)) {
                return 1
            }
            return 0
        })

        // not fan but the alternative is to add the action flow to update the order
        this.setState({
            tracksOrder,
            lastMovedIndex,
        }, () => {
            this.props.updateAlbum(this.props.album.id, {
                tracks,
            })
        })
    }

    onFileDrop = (files) => {
        const { length } = files
        const { releaseType } = this.props
        const filesToUpload = files.splice(0, releaseType.maxTracks)

        if (filesToUpload.length < length) {
            window.alert(t('containerTrackUploadAlertTrackLimit', filesToUpload.length, length))
        }

        this.props.createTracks(this.props.album.id, filesToUpload)
    }

    onFileReplace(id) {
        return () => {
            this.fileInputEl.value = null
            this.fileInputEl.onchange = (event) => {
                const files = event.dataTransfer ? event.dataTransfer.files : event.target.files
                this.props.uploadFile(this.props.album.id, id, files[0])
            }
            this.fileInputEl.click()
        }
    }

    syncCandidatePublishers = () => Object.entries(
        this.state.candidatePublishers
    ).forEach(
        (entry) => {
            const candidateRegFunc = entry[1]
            candidateRegFunc()
        }
    )

    onRemove = (trackId, onRemovalConfirmed) => {
        const removedTrack = this.props.tracks.find(track => track.id === trackId)

        this.props.showConfirmationModal(
            'confirm-track-removal',
            'containerTrackUploadAlertConfirm',
            null,
            () => {
                if (removedTrack.isUploading) {
                    this.props.abortFile(trackId)
                }

                this.props.removeTrack(this.props.album, trackId)
                onRemovalConfirmed()
            }
        )
    }

    onSelect = (trackId) => {
        const selectedTrack = this.props.tracks.find(track => track.isSelected)
        this.props.selectTrack(!selectedTrack || selectedTrack.id !== trackId ? trackId : undefined)
    }

    publisherIsGlobal = (publisher) => {
        const { allForms } = this.props
        return allForms
            .map(
                f => (f.values && f.values.publishers ? f.values.publishers : [])
                    .filter(p => (p.person.name === publisher.person.name
                        && p.publishingHouse === publisher.publishingHouse))
            )
            .filter(found => found.length > 0)
            .length === allForms.length
    }

    updateCandidatePublisher = (newPublisherRegFunc, trackId) => {
        this.state.candidatePublishers[trackId] = newPublisherRegFunc
    }

    hasMaxedTracks() {
        const { tracks, releaseType } = this.props

        return tracks.length >= releaseType.maxTracks
    }

    renderMaxTracks = () => {
        const { releaseType } = this.props

        return (
            <Alert type="info">
                {t('containerTrackUploadAlertTrackLimitNumber', releaseType.maxTracks)}
            </Alert>
        )
    }

    renderDropzone = () => {
        const { tracks, isCreatingTracks } = this.props

        const hasTracks = !isEmpty(tracks)

        return (
            <AudioDropzone
                uploadAudio={this.onFileDrop}
                isCreatingTracks={!!isCreatingTracks}
                className={classnames(
                    'c-track-upload-dropzone', {
                        'c-track-upload-dropzone-no-tracks': !hasTracks,
                    }
                )}
            >
                {!isCreatingTracks && !hasTracks
                    && (
                        <p className="c-dropzone-content-title">
                            {t('containerTrackUploadDropzoneTitle')}
                        </p>
                    )
                || !isCreatingTracks && hasTracks
                    && (
                        <div>
                            <p className="c-dropzone-content-title">
                                {t('containerMultipleTrackUploadDropzoneTitle')}
                            </p>
                            <p className="c-dropzone-content-text">
                                {t('containerMultipleTrackUploadDropzoneText')}
                            </p>
                        </div>
                    )}

                {isCreatingTracks || hasTracks ? (
                    null
                ) : (
                    <div>
                        <p className="c-dropzone-content-guidelines">
                            {t('containerTrackUploadDropzoneGuidelines')}
                        </p>
                        <div
                            dangerouslySetInnerHTML={{ __html: tmarkdown('containerTrackUploadDropzoneLink') }}
                            onClick={(event) => {
                                event.stopPropagation()
                            }}
                        />
                    </div>
                )}
            </AudioDropzone>
        )
    }

    render() {
        const {
            album,
            releaseType,
            tracks,
            trackTitles,
            trackHasFormError,
            allForms,
            isMovingTracks,
        } = this.props

        const { tracksOrder } = this.state

        if (!releaseType) {
            return false
        }

        // ensure empty tracks are not rendered
        const sortedTracks = tracksOrder.reduce(
            (list, trackId) => {
                const track = find(tracks, { id: trackId })
                if (!isEmpty(track)) {
                    list.push(track)
                }
                return list
            },
            []
        )

        const isLoading = (album.completionLevel < 2)

        const sortedTrackTitles = sortedTracks
            .map(({ id }) => trackTitles.find(({ id: trackTitleId }) => id === trackTitleId).title)
            .map(title => (isPlaceholderTitle(title) ? t('selectorTracksUntitledTrack') : title))

        return (
            <article className="c-track-upload">

                <header>
                    <h1>
                        {t('containerTrackUploadHeading')}
                    </h1>
                    <TrackUploadProgress id={album.id} />
                </header>

                { isLoading
                    && (
                        <Spinner
                            size="large"
                            className="c-track-upload-waiting"
                        >
                            {t('globalLoading')}
                        </Spinner>
                    )
                || (
                    <div>
                        <DraggableList
                            indexes={tracksOrder}
                            onMove={this.onMove}
                        >
                            {sortedTracks.map((track, index) => {
                                const hasUploadError = !isEmpty(track.uploadErrors)
                                return (
                                    <Draggable
                                        htmlId={`track${index}`}
                                        id={track.id}
                                        key={track.id}
                                        order={index + 1}
                                        title={sortedTrackTitles[index] || t('selectorTracksUntitledTrack')}
                                        active={track.isSelected}
                                        error={(hasUploadError || trackHasFormError[index]) && !track.isUploading}
                                        onRemove={this.onRemove}
                                        onSelect={this.onSelect}
                                        disableInteraction={isMovingTracks}
                                        isUpdatingPosition={index === this.state.lastMovedIndex}
                                    >
                                        <TrackFormContainer
                                            track={track}
                                            isActiveTrack={track.isSelected}
                                            releaseSiblingTracks={sortedTracks.filter(tr => tr.id !== track.id)}
                                            trackSelect={this.onSelect}
                                            publisherIsGlobal={this.publisherIsGlobal}
                                            updateCandidatePublisher={this.updateCandidatePublisher}
                                            allForms={allForms}
                                        />
                                    </Draggable>
                                )
                            })}
                        </DraggableList>

                        {this.hasMaxedTracks() ? this.renderMaxTracks() : this.renderDropzone()}
                    </div>
                )}
            </article>
        )
    }
}
