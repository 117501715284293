import { connect, ConnectedProps } from 'react-redux'

import { fetchBalance } from 'actions/WalletActions'
import { balanceSelector } from 'selectors/wallet'

import Balance from 'components/Balance/Balance'
import { AppStoreState } from 'store/store-types'

const mapStateToProps = (state: AppStoreState) => ({
    isFetchingBalance: state.wallet.isFetchingBalance,
    balance: balanceSelector(state),
    balanceErrors: state.wallet.balanceErrors,
})

const mapDispatchToProps = {
    fetchBalance,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export type BalanceContainerProps = ConnectedProps<typeof connector>
export default connector(Balance)
