import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

export default class Radio extends Component {
    static propTypes = {
        /** The input value */
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),

        /** The input name */
        name: PropTypes.string,

        /** The input initial state */
        checked: PropTypes.bool,

        /** The inputs change handler */
        onChange: PropTypes.func,

        /** The disabled state */
        disabled: PropTypes.bool,

        /** The label */
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

        /** is label in markdown */
        isMarkdownLabel: PropTypes.bool,

        children: PropTypes.node,
    };

    static defaultProps = {
        isMarkdownLabel: false,
    }

    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
        this.state = {
            isFocused: false,
        }
    }

    onFocus = () => {
        this.setState({ isFocused: true })
    }

    onBlur = () => {
        this.setState({ isFocused: false })
    }

    getValue() {
        return this.inputRef.current.value
    }

    setValue(value) {
        this.inputRef.current.value = value
    }

    render() {
        const {
            name,
            value,
            checked,
            disabled,
            onChange,
            label,
            isMarkdownLabel,
            children,
        } = this.props

        const { isFocused } = this.state

        const {
            onFocus,
            onBlur,
        } = this

        const boundOnChange = onChange.bind(null, value)

        const className = classnames(
            'c-radio',
            {
                disabled: !!disabled,
                focused: !!isFocused,
            }
        )

        return (
            <label className={className} onFocus={onFocus} onBlur={onBlur}>
                <span className="c-radio-input-wrapper">
                    <input
                        ref={this.inputRef}
                        type="radio"
                        name={name}
                        defaultValue={value}
                        checked={checked}
                        onChange={boundOnChange}
                        disabled={disabled}
                    />
                    <span className="c-radio-input" />
                    { isMarkdownLabel
                        ? (
                            <span
                                className="c-radio-label"
                                dangerouslySetInnerHTML={{ __html: label }}
                            />
                        ) : <span className="c-radio-label">{label}</span>}
                    {children}
                </span>
            </label>
        )
    }
}
