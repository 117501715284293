import { connect } from 'react-redux'

import { ConditionalAlert } from 'components/ConditionalAlert/ConditionalAlert'
import { shouldDisplayAlert } from 'modules/alert'

const mapStateToProps = (state, props) => {
    const shouldDisplay = shouldDisplayAlert(props.id)(state)

    return {
        display: shouldDisplay,
    }
}

export default connect(mapStateToProps)(ConditionalAlert)
