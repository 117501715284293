import * as types from 'constants/AuthActionTypes'
import { updateUser, fetchUsers } from 'actions/UserActions'
import { URL_LOGIN, URL_LOGIN_TITLE } from 'constants/AppUrls'
import { getGaClientId, sendEventToDataLayer } from 'utilities/analytics'
import { getConsentStatus } from 'utilities/consent'
import { FETCH_USERS_SUCCESS } from 'constants/UserActionTypes'
import { AUTH, VERIFY_MFA } from 'middleware/auth'
import { types as mfaTypes } from 'actions/MfaActions'

function handleAuth(username, password, captchaResponse) {
    return {
        [AUTH]: {
            username,
            password,
            captchaResponse,
        },
    }
}

function handleAuthMfaStep(mfaCode, signedUrl) {
    return {
        [VERIFY_MFA]: {
            mfaCode,
            signedUrl,
        },
    }
}

const sendUserLoginEvent = (user) => {
    const eventObj = {
        pagePath: URL_LOGIN,
        pageTitle: URL_LOGIN_TITLE,
        pageUrl: `${window.location.origin}${URL_LOGIN}`,
    }
    if (user) {
        eventObj.userId = user.id
        eventObj.country = user.locale.country.id
        eventObj.market = user.locale.country.market.name
    }
    sendEventToDataLayer(
        'userLogin',
        eventObj
    )
}

const updateUserOfflineConsent = (dispatch) => {
    const body = {}

    const consentStatus = getConsentStatus()
    const googleAnalyticsCookie = getGaClientId()

    if (consentStatus !== null) {
        body.hasConsentedMarketing = consentStatus
    }

    if (googleAnalyticsCookie !== null) {
        body.gaClientId = googleAnalyticsCookie
    }

    if (Object.keys(body).length > 0) {
        dispatch(updateUser(body))
    }
}

export function auth(username, password, captchaResponse) {
    // todo any subsequent action necessary?
    return dispatch => dispatch(handleAuth(username, password, captchaResponse))
}

export function completeAuth(signedUrl, mfaCode) {
    return dispatch => dispatch(handleAuthMfaStep(mfaCode, signedUrl))
        .then((action) => {
            if (action.type === mfaTypes.MFA_VERIFY_SUCCESS) {
                return dispatch(fetchUsers())
                    .then((fetchUsersAction) => {
                        if (fetchUsersAction.type === FETCH_USERS_SUCCESS) {
                            sendUserLoginEvent(fetchUsersAction.payload[0])
                            updateUserOfflineConsent(dispatch)
                        }
                    })
            }

            return Promise.reject(action)
        })
}

export function unauth() {
    return {
        type: types.UNAUTH,
    }
}

export function unblock() {
    return {
        type: types.BACKOFF_UNBLOCK,
    }
}

export function logoutAndRedirectTo(to, fUnAuthenticate, history) {
    fUnAuthenticate()

    if (to.startsWith('http://') || to.startsWith('https://') || !history) {
        window.location = to
    } else {
        history.push(to)
    }
}
