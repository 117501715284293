// FETCH

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

// CREATE

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

// UPDATE

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

// PASSWORD

export const PASSWORD_REQUEST = 'PASSWORD_REQUEST'
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS'
export const PASSWORD_FAILURE = 'PASSWORD_FAILURE'

// FLAGS
export const UPDATE_USER_FLAGS_REQUEST = 'UPDATE_USER_FLAGS_REQUEST'
export const UPDATE_USER_FLAGS_SUCCESS = 'UPDATE_USER_FLAGS_SUCCESS'
export const UPDATE_USER_FLAGS_FAILURE = 'UPDATE_USER_FLAGS_FAILURE'
