/*
* - like (../tracks.ts) but for cyclic dependency headaches
*/

export const KEY = 'tracks'
export const TRACKS_FORM_KEY = 'tracks'
export const TRACK_FORM_KEY = 'track'

export const tracksFormRegex = /^tracks_[\d\w]+$/
export const getTracksFormName = props => `${TRACKS_FORM_KEY}_${props.id}`
export const getTrackFormIds = (state) => {
    const formIds = Object.keys(state.form)
    return formIds.filter(formId => tracksFormRegex.exec(formId))
}

export const getTrackForms = state => getTrackFormIds(state).map(trackFormId => state.form[trackFormId])
