import { OrderedMap } from 'immutable'
import { types } from 'actions/CountryPrefixActions'
import CountryPrefix from 'models/countryPrefix'

const initialState = {
    isFetching: false,
    countriesPrefixes: new OrderedMap(),
    fetchedAt: 0,
}

export default function countriesPrefixes(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_COUNTRIES_PREFIXES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.FETCH_COUNTRIES_PREFIXES_SUCCESS:
            return {
                isFetching: false,
                countriesPrefixes: state.countriesPrefixes.merge(
                    action.payload.reduce((map, country) => ({
                        ...map,
                        [country.id]: new CountryPrefix({
                            countryCode: country.id,
                            countryName: country.name,
                            phonePrefix: country.phonePrefix,
                            flag: `flag-${country.id}`,
                        }),
                    }), {})
                ),
                fetchedAt: new Date(),
            }

        case types.FETCH_COUNTRIES_PREFIXES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            }

        default:
            return state
    }
}
