import React from 'react'
import { Field as ReduxField } from 'redux-form'
import { PropTypes } from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import t from 'utilities/translate'
import { InputField } from '../EditProfileInputs/Inputs'

const SocialMediaInput = ({ name, labelText, icon }) => (
    <div className="c-social-media-input-wrapper">
        <div className="c-social-icon-wrapper">
            <FontAwesomeIcon className="c-social-icon" icon={icon} />
        </div>
        <div className="c-social-media-input">
            <ReduxField
                autocomplete="off"
                component={InputField}
                name={name}
                label={labelText}
                placeholder={t('formEditProfileSocialPlaceholder')}
            />
        </div>
    </div>
)

SocialMediaInput.propTypes = {
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
}

export default SocialMediaInput
