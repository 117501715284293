import { ApiSalesOverviewMonth } from 'models/api/sales-overview'

export class MonthOverview {
    dsps: { name: string; dates: { start: string; end: string }[] }[] = []
    monthIndex: number = -1
    revenue: string = ''

    constructor(month: ApiSalesOverviewMonth) {
        this.monthIndex = month.month
        this.revenue = month.revenue
        this.dsps = month.dsps
    }
}
