import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import classnames from 'classnames'
import { Element } from 'react-scroll'
import Button from 'components/Button/Button'
import Frame from 'components/Frame/Frame'
import Spinner from 'components/Spinner/Spinner'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import t from 'utilities/translate'
import scrollIntoView from 'utilities/scroll'

class Draggable extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        htmlId: PropTypes.string,
        order: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,
        title: PropTypes.string.isRequired,
        active: PropTypes.bool,
        error: PropTypes.bool,
        isDragging: PropTypes.bool.isRequired,
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        disableInteraction: PropTypes.bool.isRequired,
        isUpdatingPosition: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props)

        this.state = {
            isDeleting: false,
        }
    }

    componentDidUpdate({ active: wasActive }) {
        if (this.props.active && !wasActive) {
            scrollIntoView(`draggable${this.props.id}`, { duration: 0 })
        }
    }

    onSelect = (event) => {
        event.stopPropagation()

        this.props.onSelect(this.props.id)
    }

    onRemove = (event) => {
        if (this.props.disableInteraction) {
            return
        }

        event.stopPropagation()

        this.props.onRemove(this.props.id, () => {
            this.setState({ isDeleting: true })
        })
    }

    render() {
        const {
            id,
            htmlId,
            order,
            title,
            active,
            error,
            children,
            isDragging,
            connectDragSource,
            connectDragPreview,
            connectDropTarget,
            disableInteraction,
            isUpdatingPosition,
        } = this.props

        const { isDeleting } = this.state

        const className = classnames(
            'c-draggable-inner',
            {
                active: active && !isDeleting,
                error,
                dragging: isDragging,
                deleting: isDeleting,
                'drag-disabled': disableInteraction,
                'position-updating': isUpdatingPosition,
            }
        )

        return connectDragPreview(connectDropTarget(
            <article id={htmlId} className="c-draggable">
                <Frame
                    className={className}
                    contentTint="light"
                    borderImageId="sun-1"
                    borderFlipVerticaly={order % 2 === 0}
                >
                    <Element name={`draggable${id}`} />
                    {connectDragSource(
                        <header className="header">
                            <div className="dragHandle">
                                { isUpdatingPosition ? <Spinner size="small" /> : <FontAwesomeIcon icon="faBars" /> }
                            </div>

                            <div className="title">
                                <span className="order">{order && order}.</span>
                                {title && title}
                                <br />
                                <span className="remove" onClick={this.onRemove}>
                                    {t('componentDraggableRemove')}
                                </span>
                            </div>

                            <Button onClick={this.onSelect} name="trackToggle" disabled={disableInteraction}>
                                {active ? t('componentDraggableClose') : t('componentDraggableEdit')}
                            </Button>
                        </header>
                    )}

                    <div
                        className="body"
                        draggable={false}
                    >
                        {children}
                    </div>
                </Frame>
                {isDeleting ? (
                    <Spinner className="c-draggable-delete-spinner" size="small">
                        {t('componentDraggableTrackDeleting')}
                    </Spinner>
                ) : (
                    null
                )}
            </article>
        ))
    }
}

const draggableSource = {
    beginDrag(props) {
        return {
            index: props.index,
        }
    },

    endDrag(props) {
        props.drop()
    },

    canDrag(props) {
        return !props.disableInteraction
    },
}

const draggableTarget = {
    hover(target, monitor, component) {
        const dragIndex = monitor.getItem().index
        const hoverIndex = target.index

        if (dragIndex === hoverIndex) return

        // eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientY = clientOffset.y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

        target.move(dragIndex, hoverIndex)

        monitor.getItem().index = hoverIndex
    },
}

Draggable = DragSource('DRAGGABLE', draggableSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
}))(Draggable)

Draggable = DropTarget('DRAGGABLE', draggableTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
}))(Draggable)

export default Draggable
