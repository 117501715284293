import { Record } from 'immutable'
import Collection from '../utilities/collection'
import * as types from '../constants/TranslationLanguageActionTypes'

export const getTranslationLanguagesCollection = () => new Collection(Record({
    id: undefined,
    name: '',
}))

const initialState = {
    isFetching: false,
    translationLanguages: getTranslationLanguagesCollection(),
    fetchedAt: 0,
}

export default function translationLanguages(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.FETCH_TRANSLATION_LANGUAGES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_TRANSLATION_LANGUAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                translationLanguages: getTranslationLanguagesCollection().fromArray(action.payload),
                fetchedAt: new Date(),
            }

        case types.FETCH_TRANSLATION_LANGUAGES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            }

        default:
            return state
    }
}
