import * as types from 'constants/UserStatisticsActionTypes'
import * as authTypes from 'constants/AuthActionTypes'

const initialState = {
    isFetching: false,
    failedFetching: false,
    release: {
        total: 0,
        totalLive: 0,
        totalSpotifyLive: 0,
    },
}

export default function userStatistics(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_USER_STATISTICS_REQUEST:
            return {
                ...state,
                isFetching: true,
                failedFetching: false,
            }

        case types.FETCH_USER_STATISTICS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                failedFetching: false,
                release: action.payload.userStats.release,
            }

        case types.FETCH_USER_STATISTICS_FAILURE:
            return {
                ...state,
                isFetching: false,
                failedFetching: true,
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
