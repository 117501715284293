import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'

import Field from 'components/Field/Field'
import PasswordInput from 'components/Inputs/PasswordInput'
import Button from 'components/Button/Button'
import ClipoutText from 'components/Frame/ClipoutText'
import Alert from 'components/Alert/Alert'

import t from 'utilities/translate'

class ResetForm extends Component {
    static propTypes = {
        token: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired,
        reset: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.newPasswordRef = React.createRef()
        this.newPasswordConfirmationRef = React.createRef()
    }

    onSave = (e) => {
        e.preventDefault()

        this.props.reset({
            token: this.props.token,
            newPassword: this.newPasswordRef.current.getValue(),
            newPasswordConfirmation: this.newPasswordConfirmationRef.current.getValue(),
        }).then((action) => {
            if (action.payload) {
                this.props.history.push('/start?passwordReset=1')
            }
        })
    }

    render() {
        const { isLoading, errors } = this.props

        return (
            <form
                className="c-reset-form"
                autoComplete="off"
                onSubmit={this.onSave}
            >
                <ClipoutText
                    type="h3"
                    backgroundImageId="dune-2"
                    className="c-book-side-title-small"
                >
                    {t('containerResetFormTitle')}
                </ClipoutText>
                <h3 className="c-book-side-title-large">
                    {t('containerResetFormTitle')}
                </h3>

                {errors.token
                    && (
                        <Alert type="error" small>
                            <p>{t(errors.token)}&nbsp;
                                <Link to="/start/forgot">
                                    {t('componentResetPasswordFormLink')}
                                </Link>
                            </p>
                        </Alert>
                    )}
                <Field
                    label={t('containerResetFormLabelNew')}
                    error={t(errors.newPassword)}
                    helpText={t('apiArtistRegisterPasswordValid')}
                >
                    <PasswordInput
                        ref={this.newPasswordRef}
                        autoComplete="off"
                    />
                </Field>

                <Field
                    label={t('containerResetFormLabelRepeat')}
                    error={t(errors.newPasswordConfirmation)}
                >
                    <PasswordInput
                        ref={this.newPasswordConfirmationRef}
                        autoComplete="off"
                    />
                </Field>

                <Button
                    isSubmit
                    className="c-book-button c-reset-form-submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    {t('containerResetFormButtonChange')}
                </Button>
            </form>
        )
    }
}

export default withRouter(ResetForm)
