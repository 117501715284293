// AUTH

export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

// REFRESH

export const REFRESH_REQUEST = 'REFRESH_REQUEST'
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS'
export const REFRESH_FAILURE = 'REFRESH_FAILURE'

// CLIENT

export const CLIENT_AUTH_REQUEST = 'CLIENT_AUTH_REQUEST'
export const CLIENT_AUTH_SUCCESS = 'CLIENT_AUTH_SUCCESS'
export const CLIENT_AUTH_FAILURE = 'CLIENT_AUTH_FAILURE'

// UNAUTH

export const UNAUTH = 'UNAUTH'

// UNBLOCK

export const BACKOFF_UNBLOCK = 'BACKOFF_UNBLOCK'
