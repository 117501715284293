import { createSelector } from 'reselect'
import { AppStoreState } from 'store/store-types'
import { Locale } from 'models/locale'

export interface SelectedLocale {
    value: number
    language: string
    country: string
    market: string
    marketId: number
    label: string
}

export const makeLocalesSelector = (displayLanguages = true) => createSelector(
    (state: AppStoreState) => state.locales.locales,
    (locales: Locale[]) => locales
        .map((locale) => {
            let label = `${locale.country.name} (${locale.language.name})`
            if (!displayLanguages) {
                label = `${locale.country.name}`
            }
            return <SelectedLocale>{
                value: locale.id,
                language: locale.language.id,
                country: locale.country.id,
                market: locale.country.market.name,
                marketId: locale.country.market.id,
                label,
            }
        })
        .sort((a: SelectedLocale, b: SelectedLocale) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
        })
)

export const getLocaleLanguage = (localeId: number) => createSelector(
    makeLocalesSelector(),
    (locales) => {
        const locale = locales.find(({ value }) => value === localeId)
        return locale && locale.language
    }
)

export const getCountrySelectOpts = () => (state: AppStoreState) => state.locales.locales
    .filter((l: any) => l.country.blacklisted === 0)
    .map((l: any) => ({
        value: l.country.id,
        label: l.country.name,
    }))
