import React from 'react'
import * as PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'

import Book from 'components/Book/Book'
import ReasonsHeader from 'components/ReasonsHeader/ReasonsHeader'

import ForgotForm from 'containers/ForgotForm/ForgotForm'

import t from 'utilities/translate'

const Reset = (props) => {
    const { location } = props

    const username = (location.state && location.state.username) ? location.state.username : null

    return (
        <div>
            <ReasonsHeader
                title={t('viewForgotHeaderTitle')}
                items={[
                    t('viewForgotHeaderItemOne'),
                    t('viewForgotHeaderItemTwo'),
                    t('viewForgotHeaderItemThree'),
                ]}
            />
            <Book
                coverOnThe="right"
                pageRenderer={() => (
                    <ForgotForm username={username} />
                )}
                coverRenderer={() => (
                    <div className="v-forgot-form-link">
                        <h3 className="light">
                            {t('viewForgotCoverSideHeading')}
                        </h3>

                        <Link
                            to="/start"
                        >
                            <h3>
                                {t('viewForgotCoverSideLink')}
                            </h3>
                        </Link>
                    </div>
                )}
            />
        </div>
    )
}

Reset.propTypes = {
    location: PropTypes.object,
}

export default withRouter(Reset)
