import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Dropzone from 'components/Dropzone/Dropzone'
import Icon from 'components/Icon/Icon'
import Spinner from 'components/Spinner/Spinner'
import { imageTypesDot } from '../../constants/FileTypes'

export default class EditProfileDropzone extends Component {
    static propTypes = {
        uploadImage: PropTypes.func,
        removeImage: PropTypes.func,
        isProcessing: PropTypes.bool,
        isLoaded: PropTypes.bool,
        isLoading: PropTypes.bool,
        hasError: PropTypes.bool,
        background: PropTypes.object,
        hideUploadButton: PropTypes.bool,
        disableDropzone: PropTypes.bool,
        allowMultipleUpload: PropTypes.bool,
        imageGroupId: PropTypes.string,
    };

    uploadImage = (files) => {
        this.props.uploadImage(files)
    }

    removeImage = (e) => {
        e.stopPropagation()
        this.props.removeImage(this.props.imageGroupId)
    }

    render() {
        const {
            isProcessing,
            isLoaded,
            isLoading,
            hasError,
            background,
            hideUploadButton,
            disableDropzone,
            allowMultipleUpload,
        } = this.props

        const { jpg, jpeg, png } = imageTypesDot
        const fileTypes = `${jpg},${jpeg},${png}`
        const disableUpload = disableDropzone || isLoaded

        const showSpinner = (
            <div className="c-dropzone-spinner-wrapper">
                <Spinner
                    className="c-dropzone-spinner"
                    size="medium"
                />
            </div>
        )

        const showOverlay = (
            isLoaded ? (
                <div
                    className="c-dropzone-image-body c-dropzone-remove-overlay"
                    onClick={!disableDropzone ? this.removeImage : null}
                >
                    <FontAwesomeIcon icon="faTrashAlt" />
                </div>
            ) : (
                <div className="c-dropzone-image-body">
                    <Icon id="upload_icon" className="c-dropzone-icon" />
                </div>
            )
        )

        return (
            <Dropzone
                uploading={isProcessing}
                onDrop={!disableUpload ? this.uploadImage : null}
                disableClick={disableUpload}
                className={classnames(
                    'c-dropzone-image', {
                        'c-dropzone-image-empty': !isLoaded,
                    }
                )}
                fileTypes={fileTypes}
                multiple={allowMultipleUpload}
                error={hasError}
                hideUploadButton={hideUploadButton}
            >
                {React.isValidElement(background) ? (
                    React.cloneElement(background, {
                        ...background.props,
                        className: classnames(
                            (background.props || {}).className,
                            'c-dropzone-image-bg-wrapper'
                        ),
                    })
                ) : null}

                {disableDropzone ? <div className="c-epu-disabled-overlay" /> : null}
                {isLoading ? showSpinner : showOverlay}
            </Dropzone>
        )
    }
}
