import React from 'react'
import EditProfileTourDatesList from 'containers/EditProfileTourDatesList/EditProfileTourDatesList'
import EditProfileTourDatesForm from 'containers/EditProfileTourDatesForm/EditProfileTourDatesForm'
import t from 'utilities/translate'

const EditProfileTourDates = () => (
    <>
        <p>{t('formEditProfileFormToursDescription')}</p>
        <EditProfileTourDatesList />
        <EditProfileTourDatesForm />
    </>
)

export default EditProfileTourDates
