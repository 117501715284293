import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import './_modalPopup.scss'

const PopupModalBase = ({
    title, subTitle, centred, size, type, noBody, onClose, children,
}) => {
    const classes = classnames('c-modal-popup', type, `size-${size || 'medium'}`, {
        'with-close': !!onClose,
        centred,
        'no-body': noBody,
    })
    return (
        <div className={classes}>
            <header>
                <div>
                    <h1 className="title">{ title }</h1>
                    <div className="modal-close" onClick={onClose}><FontAwesomeIcon icon="faTimes" fixedWidth /></div>
                </div>
                { subTitle ? <h2 className="sub-title">{ subTitle }</h2> : null }
            </header>
            { children }
        </div>
    )
}

PopupModalBase.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
    centred: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['default', 'confirmation', 'info', 'error']),
    noBody: PropTypes.bool,
}

export default PopupModalBase
