import { createSelector } from 'reselect'
import moment from 'moment'
import { formatDateLocalised } from 'utilities/date'

export const TYPE_MAIN = 1
export const TYPE_OVERVIEW = 2

function getDatesInRange(days) {
    const start = moment().subtract(days, 'd')
    const end = moment()

    const day = start.clone()
    const dates = []
    while (day.isBefore(end)) {
        day.add(1, 'days')
        dates.push(formatDateLocalised(day))
    }
    return dates
}

function isCorrectSaleType(dsp, expectedType) {
    return dsp.saleType === expectedType || dsp.saleType === 'both'
}

export const trendsSelector = state => state.trends
export const trendsOverviewSelector = state => state.trends.overview
export const trendsTerritorySelector = state => state.trends.territories

export const makeVisibleDspsSelector = type => createSelector(
    type === TYPE_MAIN ? trendsSelector : trendsOverviewSelector,
    trends => Object.values(trends.dsps).filter(dspData => (dspData.isVisible === true))
)

export const makeFilterOptionsSelector = type => createSelector(
    type === TYPE_MAIN ? trendsSelector : trendsOverviewSelector,
    trends => trends.filter
)

export const makeDspTotalsSelector = type => createSelector(
    type === TYPE_MAIN ? trendsSelector : trendsOverviewSelector,
    makeFilterOptionsSelector(type),
    (trends, filters) => Object.entries(trends.dsps)
        .filter(dspData => isCorrectSaleType(dspData[1], filters.saleType))
        .map((dspData) => {
            const value = dspData[1]
            const { displayName, saleType, total } = value
            return {
                name: displayName,
                total,
                saleType,
            }
        })
)

export const makeDspDataSelector = type => createSelector(
    makeVisibleDspsSelector(type),
    makeFilterOptionsSelector(type),
    (trends, filters) => {
        const dateFormat = (filters.days === 365) ? 'MMMM YYYY' : 'LL'

        return Object.entries(trends)
            .map((dspData) => {
                const value = dspData[1]
                const { name, data, isVisible } = value

                let values = []
                if (data !== undefined) {
                    values = data.map(totalsRow => ({
                        meta: formatDateLocalised(totalsRow.date, dateFormat),
                        value: totalsRow.quantity,
                    }))
                }

                return { className: name, data: values, isVisible }
            })
    }
)

export const makeLabelsSelector = type => createSelector(
    makeVisibleDspsSelector(type),
    (trends) => {
        const trendsData = Object.entries(trends)
            .reduce((dsps, dspData) => {
                const value = dspData[1]
                const { name, data } = value
                if (data && data.length > 0) {
                    return [
                        ...dsps,
                        { name, data },
                    ]
                } else {
                    return dsps
                }
            }, [])

        let dspDataDates = []

        if (trendsData.length && trendsData[0].data !== undefined && trendsData[0].data.length > 0) {
            dspDataDates = trendsData[0].data.reduce((dates, dayTotals) => [
                ...dates,
                moment(dayTotals.date),
            ], [])
        }

        return dspDataDates
    }
)

export const makeDspIconSelector = type => createSelector(
    type === TYPE_MAIN ? trendsSelector : trendsOverviewSelector,
    makeFilterOptionsSelector(type),
    (trends, filters) => {
        const dsps = [
            { name: 'spotify', icon: 'faSpotify', fillColour: '#00D95A' },
            { name: 'itunes', icon: 'faItunes', fillColour: '#e50d24' },
            { name: 'applemusic', icon: 'faApple', fillColour: '#ff5600' },
            { name: 'deezer', icon: 'deezer', fillColour: '#00B5C8' },
            { name: 'googlemusic', icon: 'faGooglePlay', fillColour: '#F9D04B' },
            { name: 'amazon', icon: 'faAmazon', fillColour: '#23527F' },
        ]

        return dsps.reduce((icons, dsp) => {
            const { name, icon, fillColour } = dsp
            const trendList = Object.values(trends.dsps)
                .find(d => d.name === name && isCorrectSaleType(d, filters.saleType))

            if (!trendList || trendList.length === 0) {
                return icons
            }

            return [
                ...icons,
                {
                    name,
                    iconId: icon,
                    isVisible: trendList.isVisible,
                    fillColour,
                },
            ]
        }, [])
    }
)

export const makeDspListSelector = type => createSelector(
    makeVisibleDspsSelector(type),
    makeFilterOptionsSelector(type),
    (trends, filters) => Object.entries(trends)
        .filter(dsp => isCorrectSaleType(dsp[1], filters.saleType))
        .reduce((dspList, dsp) => [
            ...dspList,
            dsp[1].displayName,
        ], [])
)

export const makeFilterTotalSelector = (type, saleType) => createSelector(
    makeDspTotalsSelector(type),
    dspTotals => dspTotals.reduce((total, dspTotal) => {
        if (isCorrectSaleType(dspTotal, saleType)) {
            return total + dspTotal.total
        } else {
            return total
        }
    }, 0)
)

export const trendsTerritoryTotalsSelector = createSelector(
    trendsTerritorySelector,
    (trends) => {
        let trendsData

        if (trends.data.length > 0) {
            trendsData = trends.data.map((territoryData) => {
                const sign = (Math.sign(territoryData.percentageChange))

                const percentageSign = (sign === 1) ? 'positive' : 'negative'
                let formattedPercentageChange

                if (territoryData.percentageChange !== 0) {
                    formattedPercentageChange = Number(territoryData.percentageChange.replace(/-/, '')).toFixed(1)
                }

                return {
                    ...territoryData,
                    percentageChange: formattedPercentageChange,
                    percentageIndicator: percentageSign,
                }
            })
        } else {
            trendsData = trends.data
        }

        return {
            ...trends,
            data: trendsData,
        }
    }
)

/** *****************************************************
 * Example data selectors and functions below
 ****************************************************** */
export const exampleDspData = createSelector(
    makeVisibleDspsSelector(TYPE_MAIN),
    makeDspListSelector(TYPE_MAIN),
    makeFilterOptionsSelector(TYPE_MAIN),
    (trends, dspList, filters) => {
        const dates = getDatesInRange(filters.days)
        const exampleData = getExampleData(dspList, dates)
        const exampleTotals = getExampleDataTotals(exampleData)

        return {
            chartData: exampleData,
            labels: dates,
            totals: exampleTotals,
        }
    }
)

export const trendsCountryExampleDataSelector = createSelector(
    () => ({
        data: [
            {
                countryCode: 'US',
                name: 'United States of America',
                total: 438170,
                percentageChange: 41.10,
                percentageIndicator: 'positive',
            },
            {
                countryCode: 'CA',
                name: 'Canada',
                total: 22905,
                percentageChange: 44.71,
                percentageIndicator: 'positive',
            },
            {
                countryCode: 'GB',
                name: 'United Kingdom of Great Britain',
                total: 22905,
                percentageChange: 44.39,
                percentageIndicator: 'positive',
            },
            {
                countryCode: 'AU',
                name: 'Australia',
                total: 16336,
                percentageChange: 43.85,
                percentageIndicator: 'positive',
            },
            {
                countryCode: 'DE',
                name: 'Germany',
                total: 16336,
                percentageChange: 50.15,
                percentageIndicator: 'positive',
            },
            {
                countryCode: 'PH',
                name: 'Philippines',
                total: 9250,
                percentageChange: -57.37,
                percentageIndicator: 'negative',
            },
            {
                countryCode: 'MX',
                name: 'Mexico',
                total: 7359,
                percentageChange: -49.85,
                percentageIndicator: 'negative',
            },
            {
                countryCode: 'BR',
                name: 'Brazil',
                total: 7214,
                percentageChange: 58.68,
                percentageIndicator: 'positive',
            },
            {
                countryCode: 'SE',
                name: 'Sweden',
                total: 5865,
                percentageChange: 49.50,
                percentageIndicator: 'positive',
            },
            {
                countryCode: 'NL',
                name: 'Netherlands',
                total: 5306,
                percentageChange: 44.70,
                percentageIndicator: 'positive',
            },
        ],
        totalRows: 10,
    })
)

function getExampleData(dspList, dates) {
    return dspList.reduce((dspExampleData, dspName) => {
        const dayTotals = dates.reduce((chartData, date) => [
            ...chartData,
            {
                meta: date,
                value: Math.floor(Math.random() * (1000 - 100 + 1) + 100),
            },
        ], [])
        return [...dspExampleData, {
            className: dspName.replace(/ /, '').toLowerCase(),
            data: dayTotals,
        }]
    }, [])
}

function getExampleDataTotals(dspTotals) {
    return dspTotals.map((dspData) => {
        const { className, data } = dspData
        let total = 0
        if (data !== undefined) {
            total = data.reduce((dspTotal, { value }) => dspTotal + value, 0)
        }
        return { name: className, total }
    })
}
