import * as types from 'constants/FontsActionTypes'

const initialState = {
    data: [],
    isLoaded: false,
    loadingFailed: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        // FETCH
        case types.FETCH_FONTS_REQUEST:
            return {
                ...state,
                isLoaded: false,
            }
        case types.FETCH_FONTS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isLoaded: true,
            }
        case types.FETCH_FONTS_FAILURE:
            return {
                ...state,
                loadingFailed: true,
            }
        default:
            return state
    }
}
