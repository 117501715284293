export interface IArtistIdentifier {
    name: string
    value: string | null
}

// todo this is legacy and isn't really necessary once hydrator.js has been converted
export class ArtistIdentifier implements IArtistIdentifier {
    name: string = ''

    value: string | null = null

    constructor({ name, value }: { name: string, value: string | null }) {
        this.name = name
        this.value = value
    }
}
