import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'utilities/translate'
import { albumSelector } from 'selectors/albums'
import ReleaseSettingsFormContainer from 'containers/ReleaseSettingsForm/ReleaseSettingsForm'
import { makeAlbumTracksSelector } from 'selectors/tracks'
import { releaseSocialOptedIn, releaseYoutubeOptedIn } from 'selectors/releases'

const ReleaseSettings = (
    {
        album, release, originalCoverValue, socialOptedIn, youtubeOptedIn, allTracks,
    }
) => (!album
    ? <div>{t('globalLoading')}</div>
    : (
        <ReleaseSettingsFormContainer
            album={album}
            release={release}
            originalCoverValue={originalCoverValue}
            socialOptedIn={socialOptedIn}
            youtubeOptedIn={youtubeOptedIn}
            releaseYoutubeOptedIn={releaseYoutubeOptedIn}
            allTracks={allTracks}
        />
    )
)

ReleaseSettings.propTypes = {
    album: PropTypes.object.isRequired,
    release: PropTypes.object.isRequired,
    originalCoverValue: PropTypes.bool,
    socialOptedIn: PropTypes.bool,
    youtubeOptedIn: PropTypes.bool,
    allTracks: PropTypes.array,
}
const mapStateToProps = (state, props) => ({
    album: albumSelector(state, props.release.providerId),
    socialOptedIn: releaseSocialOptedIn(props.release.id)(state, props),
    youtubeOptedIn: releaseYoutubeOptedIn(props.release.id)(state, props),
    allTracks: makeAlbumTracksSelector(props.release.providerId)(state, props),
})

export default connect(mapStateToProps)(ReleaseSettings)
