import { useLocaleContext } from 'contexts/localisation/localeProvider'
import * as PropTypes from 'prop-types'
import React from 'react'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'

const tKeyOverrides = {
    Audio: {
    },
    Track: {
    },
    Release: {
    },
    Cover: {
        title: 'componentArtCoverFormHeader',
    },
}

const GuidanceSection = ({
    sectionName,
    iconPath,
    sectionFlowPosition,
}) => {
    const copyKeyIndices = sectionName === 'Cover' ? [2, 3, 4] : [1, 2, 3, 4]
    const { t } = useLocaleContext()

    const keyPrefix = 'releaseflowGuidance'
    const headerKey = tKeyOverrides[sectionName].title || `${keyPrefix}${sectionName}`

    return (
        <div>
            <div className={`c-progress ${sectionFlowPosition}`} />
            <div className="c-content">
                <h3>{t(headerKey)}</h3>
                <img src={iconPath} alt={`${sectionName} Guidance Icon`} />
                <div className="c-guidance-copy">
                    {
                        copyKeyIndices.map((copyIndex, i) => (
                            <React.Fragment key={`${sectionName}-${copyIndex}`}>
                                <div>
                                    {t(`${keyPrefix}${sectionName}Copy${copyIndex}`)}
                                </div>
                                {
                                    (i + 1) === copyKeyIndices.length ? null : (
                                        <div className="separator" />
                                    )
                                }
                            </React.Fragment>
                        ))
                    }
                </div>

                <TranslateMarkdown id={`releaseflowGuidanceLearnMore${sectionName}`} className="c-learn-more" />
            </div>
        </div>
    )
}

GuidanceSection.propTypes = {
    sectionName: PropTypes.oneOf(['Audio', 'Track', 'Release', 'Cover']).isRequired,
    iconPath: PropTypes.string.isRequired,
    sectionFlowPosition: PropTypes.oneOf(['start', 'mid', 'end']).isRequired,
}

export default GuidanceSection
