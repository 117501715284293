import React from 'react'
import PropTypes from 'prop-types'

import { withRouter, useLocation } from 'react-router-dom'
import Book from 'components/Book/Book'
import ReasonsHeader from 'components/ReasonsHeader/ReasonsHeader'

import ResetForm from 'containers/ResetForm/ResetForm'

import Alert from 'components/Alert/Alert'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'

const Reset = (props) => {
    const location = useLocation()
    const token = location.hash.slice(1)
    const { t } = useLocaleContext()
    const { history } = props

    return (
        <div>
            <ReasonsHeader
                title={t('viewResetHeaderTitle')}
                items={[
                    t('viewResetHeaderItemOne'),
                    t('viewResetHeaderItemTwo'),
                    t('viewResetHeaderItemThree'),
                ]}
            />
            <Book
                coverOnThe="right"
                pageRenderer={() => (
                    token === '' ? (
                        <Alert type="error" small>
                            <TranslateMarkdown
                                id="componentResetPasswordNoToken"
                                args={['/start/forgot']}
                                useReactRouter
                                history={history}
                            />
                        </Alert>
                    )
                        : <ResetForm token={token} />
                )}
                coverRenderer={() => (
                    <div className="v-reset-form-link">
                        <h3 className="light">
                            {t('viewResetCoverSideHeading')}
                        </h3>
                    </div>
                )}
            />
        </div>
    )
}

Reset.propTypes = {
    history: PropTypes.object.isRequired,
}

export default withRouter(Reset)
