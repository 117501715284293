import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from 'components/Button/Button'

import t from 'utilities/translate'
/**
 * Dropzone
 *
 * Lets the user upload files
 *
 * Styleguide: components.dropzone
 */
export default class Dropzone extends Component {
    static propTypes = {
        active: PropTypes.bool,
        uploading: PropTypes.bool,
        error: PropTypes.bool,
        trackUpload: PropTypes.bool,
        onDrop: PropTypes.func,
        onDragEnter: PropTypes.func,
        onDragLeave: PropTypes.func,
        children: PropTypes.node,
        disableClick: PropTypes.bool,
        multiple: PropTypes.bool,
        fileTypes: PropTypes.string,
        className: PropTypes.string,
        hideUploadButton: PropTypes.bool,
    };

    static defaultProps = {
        active: false,
        disableClick: false,
        multiple: true,
        hideUploadButton: false,
    };

    onDragEnter = (event) => {
        if (!this.props.uploading) {
            event.preventDefault()
        }
    }

    onDragOver = (event) => {
        if (!this.props.uploading) {
            event.preventDefault()
            event.stopPropagation()
            return false
        }
        return true
    }

    onDragLeave = (event) => {
        if (!this.props.uploading) {
            event.preventDefault()
        }
    }

    onDrop = (event) => {
        if (!this.props.uploading) {
            event.preventDefault()

            const droppedFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files
            const max = this.props.multiple ? droppedFiles.length : 1
            const files = []

            for (let i = 0; i < max; i++) {
                const file = droppedFiles[i]
                files.push(file)
            }

            if (this.props.onDrop) {
                this.props.onDrop(files, event)
            }
        }
    }

    onClick = () => {
        const {
            disableClick,
            uploading,
        } = this.props

        if (!disableClick && !uploading) {
            this.open()
        }
    }

    open() {
        this.fileInputEl.value = null
        this.fileInputEl.click()
    }

    render() {
        const {
            multiple,
            error,
            children,
            fileTypes,
            uploading,
            className: customClassname,
            hideUploadButton,
        } = this.props

        const rootClassnames = classnames(
            'c-dropzone',
            customClassname, {
                'c-dropzone-is-uploading': !!uploading,
                'c-dropzone-has-error': !!error,
            }
        )

        const contentClassnames = classnames(
            'c-dropzone-content', {
                'c-dropzone-has-error': !!error,
            }
        )

        const inputProps = {
            multiple,
            accept: fileTypes,
            type: 'file',
            style: { display: 'none' },
            ref: (el) => { this.fileInputEl = el },
            onChange: this.onDrop,
        }

        const content = (
            <div className={contentClassnames}>
                {children}
            </div>
        )

        return (
            <div className="c-dropzone-wrapper">
                <div
                    className={rootClassnames}
                    onClick={this.onClick}
                    onDragEnter={this.onDragEnter}
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                    onDrop={this.onDrop}
                >
                    {content}
                    <input {...inputProps} />
                </div>

                {hideUploadButton ? null : (
                    <Button
                        className="c-dropzone-drop-button"
                        onClick={() => { this.open() }}
                    >
                        {t('componentImageDropzoneButton')}
                    </Button>
                )}
            </div>
        )
    }
}
