import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import Hamburger from 'components/Hamburger/Hamburger'
import HamburgerSideBar from 'components/HamburgerMenu/HamburgerMenuSidebar'

const HamburgerMenu = (props) => {
    const {
        open,
        onToggle,
        items,
        urls,
        actions,
        artistIsEnhanced,
    } = props

    const [isOpen, setIsOpen] = useState(open)

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    const wrapperClasses = classnames(
        'c-hamburger-menu', {
            'c-hamburger-menu-is-open': isOpen,
        }
    )

    const sideClasses = classnames(
        'c-hamburger-menu-content', {
            'c-hamburger-menu-content-opened': isOpen,
        }
    )

    const clickHamburgerHandler = (e) => {
        e.stopPropagation()
        setIsOpen(!isOpen)
        onToggle()
    }

    return (
        <div className={wrapperClasses}>
            <Hamburger
                isOpen={isOpen}
                onClick={clickHamburgerHandler}
            />
            <div className={sideClasses}>
                <HamburgerSideBar
                    items={items}
                    urls={urls}
                    actions={actions}
                    artistIsEnhanced={artistIsEnhanced}
                />
            </div>
        </div>
    )
}

HamburgerMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    urls: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    artistIsEnhanced: PropTypes.bool.isRequired,
}

export default HamburgerMenu
