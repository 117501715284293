import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import {
    Field as ReduxField, propTypes as formPropTypes, reduxForm, SubmissionError,
} from 'redux-form'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { ReduxFormTextField } from 'components/common/forms/ReduxFormTextField'
import Button from 'components/Button/Button'
import { reset, resetSigned } from 'actions/ResetActions'
import { connect } from 'react-redux'
import { RESET_FAILURE, RESET_SUCCESS } from 'constants/ResetActionTypes'
import Book from 'components/Book/Book'
import './_forcedPasswordReset.scss'
import { doesNotMatchRegex, required } from 'validations/rules'
import Alert from 'components/Alert/Alert'

const forcePasswordResetFormName = 'passwordResetForm'

const confirmationFieldMatchingValidation = (value, allValues) => {
    if (value !== allValues.newPassword) {
        return 'viewPasswordResetInputConfirmationInvalid'
    }
    return undefined
}
const passwordFieldValidationRules = [
    required('viewPasswordResetInputRequired'),
    doesNotMatchRegex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{10,64}$/, 'viewPasswordResetInputComplexity'),
]

const confirmationFieldValidationRules = [
    required('viewPasswordResetInputConfirmationRequired'),
    confirmationFieldMatchingValidation,
]

/*
 * This is the 'password reset required' component for DEV-4289. This has been made to support a signature in the
 * location hash so it can also be clicked through to, such that eventually it can replace Reset.js and become
 * the only password reset component (todo)
 */
const ForcedPasswordReset = ({
    location,
    history,
    handleSubmit,
    invalid,
    resetPassword,
    resetPasswordWithToken,
}) => {
    const {
        push,
    } = history

    const {
        state,
        hash,
    } = location

    const {
        apiPasswordReset,
    } = (state || {})

    const urlSignature = (hash || '').slice(1)

    const { t } = useLocaleContext()
    const [isResetting, setIsResetting] = useState(false)
    const [tokenError, setTokenError] = useState(undefined)

    const onResetPassword = (form) => {
        // console.log('Resetting password', form)
        setIsResetting(true)

        const resetAction = apiPasswordReset
            ? resetPassword(apiPasswordReset.url, form)
            : resetPasswordWithToken({
                ...form,
                token: urlSignature,
            })

        return resetAction.then((action) => {
            setIsResetting(false)

            if (action.type === RESET_SUCCESS) {
                push('/start')
            } else if (action.type === RESET_FAILURE) {
                // todo this is slightly speculative based on how HW forms handled api field level validation
                const errors = action.error && action.error.errors ? action.error.errors : {}
                const allErrorFields = Object.keys(errors)
                const submissionError = {}

                allErrorFields.forEach((f) => {
                    const errorKey = f[0].toUpperCase() + f.slice(1)
                    submissionError[f] = t(`viewPasswordReset${errorKey}Error`)
                })

                // At time of writing, error text finishes with "please try again" so use a separate error tracking
                // state such that the submission button doesn't disable until field change leading
                // to the user incorrectly thinking the field values affect things.
                setTokenError(errors.token ? errors.token[0] : undefined)

                throw new SubmissionError({
                    ...submissionError,
                })
            }
        })
    }

    let resetForm

    if (!apiPasswordReset && urlSignature.length === 0) {
        resetForm = () => (
            <div className="v-reset-form">
                <h3>{t('viewPasswordResetTitle')}</h3>
                <Alert type="error" small>
                    <p>{t('apiArtistResetPwdInvalidToken')}&nbsp;
                        <Link to="/start/forgot">
                            {t('componentResetPasswordFormLink')}
                        </Link>
                    </p>
                </Alert>
            </div>
        )
    } else {
        resetForm = () => (
            <div className="v-reset-form">
                <h3>{t('viewPasswordResetTitle')}</h3>
                <TranslateMarkdown id="viewPasswordResetDescription" className="v-password-reset-description" />
                <form onSubmit={handleSubmit(onResetPassword)}>
                    <ReduxField
                        required
                        isPassword
                        name="newPassword"
                        labelId="viewPasswordResetInputLabel"
                        placeholder={t('viewPasswordResetInputPlaceholder')}
                        disabled={isResetting}
                        helpText={t('viewPasswordResetInputHelpText')}
                        validate={passwordFieldValidationRules}
                        component={ReduxFormTextField}
                    />

                    <ReduxField
                        required
                        isPassword
                        name="newPasswordConfirmation"
                        labelId="viewPasswordResetInputConfirmationLabel"
                        placeholder={t('viewPasswordResetInputConfirmationPlaceholder')}
                        disabled={isResetting}
                        validate={confirmationFieldValidationRules}
                        component={ReduxFormTextField}
                    />

                    <div className="v-mfa-buttons-wrapper">
                        <Button
                            isSubmit
                            isLoading={isResetting}
                            disabled={isResetting || invalid}
                            className="v-mfa-button"
                        >
                            {t('viewPasswordResetButtonLabel')}
                        </Button>
                    </div>

                    {
                        tokenError ? (
                            <Alert type="error" colourise>{t(tokenError)}</Alert>
                        ) : undefined
                    }
                </form>
            </div>
        )
    }

    const cover = () => (
        <div className="v-reset-cover-content">
            <h3 className="light">
                {t('viewPasswordResetSubTitle')}
            </h3>
        </div>
    )

    return (
        <div className="v-password-reset">
            <Book
                pageRenderer={resetForm}
                coverRenderer={cover}
                coverOnThe="right"
            />
        </div>
    )
}

ForcedPasswordReset.propTypes = {
    ...formPropTypes,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    resetPasswordWithToken: PropTypes.func.isRequired,
}

export const ForcedPasswordResetForm = reduxForm({
    form: forcePasswordResetFormName,
})(ForcedPasswordReset)

const mapDispatchToProps = {
    resetPassword: resetSigned,
    resetPasswordWithToken: reset,
}

export default withRouter(connect(null, mapDispatchToProps)(ForcedPasswordResetForm))
