import React from 'react'
import { Field as ReduxField } from 'redux-form'
import { RadioField } from 'components/EditProfileInputs/Inputs'
import t from 'utilities/translate'

const EditProfileHeroPlacement = () => (
    <div className="c-epf-heroPlacement-wrapper">
        <div className="c-epf-heroPlacement">
            <ReduxField
                type="radio"
                name="heroPlacement"
                value="full_width"
                label={t('heroImageSizeFullWidth')}
                component={RadioField}
            >
                <div className="c-epf-heroPlacement-preview">
                    <div className="c-epf-heroPlacement-preview-nav" />
                    <div className="c-epf-heroPlacement-preview-content full-width" />
                </div>
            </ReduxField>
            <p className="c-epf-heroPlacement-description">
                {t('formEditProfileHeroPlacementDescriptionFull')}
            </p>
        </div>
        <div className="c-epf-heroPlacement">
            <ReduxField
                type="radio"
                name="heroPlacement"
                value="centered"
                label={t('heroImageSizeCentered')}
                component={RadioField}
            >
                <div className="c-epf-heroPlacement-preview">
                    <div className="c-epf-heroPlacement-preview-nav" />
                    <div className="c-epf-heroPlacement-preview-content centered" />
                </div>
            </ReduxField>
            <p className="c-epf-heroPlacement-description">
                {t('formEditProfileHeroPlacementDescriptionCenter')}
            </p>
        </div>
    </div>
)

export default EditProfileHeroPlacement
