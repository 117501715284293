import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import ArtworkContainer from 'containers/Artwork/Artwork'
import TrackListContainer from 'containers/TrackList/TrackList'

import Frame from 'components/Frame/Frame'
import ArtistNames from 'components/ArtistNames/ArtistNames'

import { scrollUpToTop } from 'hooks/useScrollToTopEffect'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { RELEASE_STATUS_REJECTED } from 'constants/enums/ReleaseStatusTypes'
import { PUBLISH_RELEASE_SUCCESS } from 'constants/ReleaseActionTypes'
import { withLocalisation } from 'contexts/localisation/localeProvider'
import {
    Checkbox, Button, Alert,
} from '..'

class ReviewReleaseContainer extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        release: PropTypes.object.isRequired,
        urls: PropTypes.object.isRequired,
        publishRelease: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        locale: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props)
        this.checkboxRef = React.createRef()
        this.voucherRef = React.createRef()
        this.state = {
            isTermsChecked: false,
        }
    }

    componentDidMount() {
        scrollUpToTop()
    }

    onTermsCheck = () => {
        const checkbox = this.checkboxRef.current.getValue()

        this.setState({ isTermsChecked: !!checkbox })
    }

    onEdit = () => {
        this.props.history.push(`/edit/${this.props.release.id}/tracks`)
    }

    onDistribute = () => {
        const {
            release,
            user,
            history,
        } = this.props

        this.props.publishRelease(
            release,
            user
        ).then((action) => {
            if (action.type === PUBLISH_RELEASE_SUCCESS && action.payload) {
                history.push(`/release/${release.id}/success`)
            }
        })
    }

    renderSectionData() {
        const { release, locale, urls } = this.props
        const { t } = locale
        const { isTermsChecked } = this.state

        return (
            <Frame contentTint="light" borderImageId="dune-2" className="c-review-release-data" borderFlipVerticaly>
                <header className="c-review-release-data-header">
                    <h2>
                        {release.album.title}
                    </h2>

                    {release.process.step === 1
                        && (
                            <Button color="gray" onClick={this.onEdit}>
                                {t('containerReviewReleaseButtonEdit')}
                            </Button>
                        )}
                </header>

                <div className="c-review-release-data-meta">
                    <ArtworkContainer album={release.album} size="medium" />

                    <ul>
                        {release.album.artist
                            && (
                                <li>
                                    <span className="c-review-release-data-meta-key">
                                        {t('containerReviewReleaseBy')}
                                    </span>
                                    <span className="c-review-release-data-meta-value">
                                        <ArtistNames artists={release.album.artists} />
                                    </span>
                                </li>
                            )}

                        {release.album.genre
                            && (
                                <li>
                                    <span className="c-review-release-data-meta-key">
                                        {t('containerReviewGenre')}
                                    </span>
                                    <span className="c-review-release-data-meta-value">
                                        {t(release.album.genreTranslationKey)}
                                    </span>
                                </li>
                            )}

                        {release.album.language
                            && (
                                <li>
                                    <span className="c-review-release-data-meta-key">
                                        {t('containerReviewLanguage')}
                                    </span>
                                    <span className="c-review-release-data-meta-value">
                                        {release.album.language}
                                    </span>
                                </li>
                            )}

                        {release.album.upc
                            && (
                                <li>
                                    <span className="c-review-release-data-meta-key">
                                        {t('containerReviewUPC')}
                                    </span>
                                    <span className="c-review-release-data-meta-value">
                                        {release.album.upc}
                                    </span>
                                </li>
                            )}

                        {(release.album.originalReleaseDate) ? (
                            <li>
                                <span className="c-review-release-data-meta-key">
                                    {t('containerReviewOriginalReleaseDate')}
                                </span>
                                <span className="c-review-release-data-meta-value">
                                    {release.album.formattedOriginalReleaseDate}
                                </span>
                            </li>
                        ) : null}

                        {release.album.formattedReleaseDate
                            && (
                                <li>
                                    <span className="c-review-release-data-meta-key">
                                        {t('containerReviewReleaseDate')}
                                    </span>
                                    <span className="c-review-release-data-meta-value">
                                        {release.album.formattedReleaseDate}
                                    </span>
                                </li>
                            )}

                        {release.album.formattedTerritory
                            && (
                                <li>
                                    <span className="c-review-release-data-meta-key">
                                        {t('containerReviewReleaseAvailable')}
                                    </span>
                                    <span className="c-review-release-data-meta-value">
                                        {release.album.formattedTerritory}
                                    </span>
                                </li>
                            )}
                    </ul>
                </div>

                <TrackListContainer id={release.album.id} withTrackPosition withAudioPlayer withArtistSpotifyLink />

                <div className="c-review-release-data-copyright">
                    <p>
                        &copy; {`${release.album.copyrightsYear} ${release.album.artist}`}
                    </p>
                    <p>
                        &#x2117; {`${release.album.copyrightsYear} ${release.album.artist}`}
                    </p>
                </div>

                <div className="c-review-release-terms-inner">
                    <div className="c-review-release-terms">
                        <div className="c-review-release-terms-control">
                            <Checkbox
                                ref={this.checkboxRef}
                                checked={isTermsChecked}
                                onChange={this.onTermsCheck}
                                label={(
                                    <TranslateMarkdown
                                        id="containerReviewReleaseLabelTerms"
                                        args={[urls.terms]}
                                        useSpan
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <Button
                        onClick={this.onDistribute}
                        disabled={!isTermsChecked}
                    >
                        { release.album.status === RELEASE_STATUS_REJECTED
                            ? t('containerReviewReleaseButtpublish')
                            : t('releaseflowReviewDistributeButton') }
                    </Button>
                </div>
            </Frame>
        )
    }

    render() {
        const enableSubscriptionManagement = window.env.ENABLE_SUBSCRIPTION_MANAGEMENT
        const { locale } = this.props
        const { t } = locale

        return (
            <div className="c-review-release">
                <header>
                    <h1>{t(enableSubscriptionManagement ? 'releaseflowReviewTitle' : 'viewReviewReleaseHeading')}</h1>

                    <Alert type="warning">
                        <p>
                            <strong>{t('containerReviewReleaseAlertFirst')}</strong>
                        </p>
                        <p>
                            {t(enableSubscriptionManagement
                                ? 'releaseflowReviewAlertCopy'
                                : 'containerReviewReleaseAlertSecond')}
                        </p>
                    </Alert>
                </header>

                {this.renderSectionData()}
            </div>
        )
    }
}

export default withRouter(withLocalisation(ReviewReleaseContainer))
