// todo this only seems to be used by components/ArtistInformation for its default constructor, is it necessary?
export class ArtistAlias {
    name: string | null = null

    nameKatakana: string | null = null

    nameOverseas: string | null = null

    spotifyId: string | null = null

    appleId: string | null = null
}
