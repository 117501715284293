import React, { useEffect, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/Inputs/Input'
import moment from 'moment'
import { debounce } from 'lodash'

export const TrackStartTimeForm = ({
    track,
    onTrackStartTimeUpdate,
    hasError,
    isDisabled,
}) => {
    const sampleStartTime = moment.duration(track.sampleStartTime, 'seconds')
    const sampleStartTimeSeconds = sampleStartTime.seconds().toLocaleString('en-US', { minimumIntegerDigits: 2 })
    const sampleStartTimeMinutes = sampleStartTime.minutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })

    const minutesInputRef = useRef(null)
    const secondsInputRef = useRef(null)

    const [seconds, setSeconds] = useState(sampleStartTimeSeconds)
    const [minutes, setMinutes] = useState(sampleStartTimeMinutes)

    useEffect(() => {
        secondsInputRef.current.setValue(seconds)
    }, [seconds])

    useEffect(() => {
        minutesInputRef.current.setValue(minutes)
    }, [minutes])

    useEffect(() => {
        secondsInputRef.current.setDisabled(isDisabled)
        minutesInputRef.current.setDisabled(isDisabled)
    }, [isDisabled])

    const updateStartTime = (startTime, updatedTrack) => {
        onTrackStartTimeUpdate(updatedTrack, startTime)
    }

    const isValidStartTime = (minutesNewValue, secondsNewValue) => {
        if (secondsNewValue > 59) {
            return false
        }

        if (minutesNewValue.toString().length > 2) {
            return false
        }
        if (secondsNewValue.toString().length > 2) {
            return false
        }
        if (Number.isNaN(minutesNewValue)) {
            return false
        }
        if (Number.isNaN(secondsNewValue)) {
            return false
        }
        return true
    }

    const debounceChangeStartTime = debounce((startTime, updatedTrack) => {
        updateStartTime(startTime, updatedTrack)
    }, 1000)

    const onChangeStartTime = (updatedTrack) => {
        // Calculate the seconds
        const minutesInputValue = Number(minutesInputRef.current.getValue())
        const secondsInputValue = Number(secondsInputRef.current.getValue())

        if (!isValidStartTime(minutesInputValue, secondsInputValue)) {
            minutesInputRef.current.setValue(minutes)
            secondsInputRef.current.setValue(seconds)
        } else {
            if (secondsInputValue < 10) {
                const formattedSeconds = moment
                    .duration(secondsInputValue, 'seconds')
                    .seconds()
                    .toLocaleString('en-US', { minimumIntegerDigits: 2 })
                setSeconds(formattedSeconds)
            } else {
                setSeconds(secondsInputValue)
            }

            if (minutesInputValue < 10) {
                const formattedMinutes = moment
                    .duration(minutesInputValue, 'minutes')
                    .minutes()
                    .toLocaleString('en-US', { minimumIntegerDigits: 2 })
                setMinutes(formattedMinutes)
            } else {
                setMinutes(minutesInputValue)
            }
            const startTimeSeconds = minutesInputValue * 60 + secondsInputValue
            debounceChangeStartTime(startTimeSeconds, updatedTrack)
        }
    }

    const onMinutesFocus = () => {
        const minutesInputValue = Number(minutesInputRef.current.getValue())
        if (minutesInputValue === 0) {
            minutesInputRef.current.setValue(null)
        }
    }

    const onSecondsFocus = () => {
        const secondsInputValue = Number(secondsInputRef.current.getValue())
        if (secondsInputValue === 0) {
            secondsInputRef.current.setValue(null)
        }
    }

    const onMinutesBlur = () => {
        const minutesInputValue = Number(minutesInputRef.current.getValue())
        if (minutesInputValue === 0) {
            minutesInputRef.current.setValue('00')
        }
    }

    const onSecondsBlur = () => {
        const secondsInputValue = Number(secondsInputRef.current.getValue())
        if (secondsInputValue === 0) {
            secondsInputRef.current.setValue('00')
        }
    }

    return (

        <div className="c-track-start">
            <Input
                className="c-track-start-time"
                type="text"
                name="track-start-time"
                value={minutes.toString()}
                onChange={event => onChangeStartTime(track, event)}
                onFocus={event => onMinutesFocus(event)}
                onBlur={event => onMinutesBlur(event)}
                error={hasError}
                ref={minutesInputRef}
            />
            <span className="c-track-start-separator">&nbsp;:&nbsp;</span>
            <Input
                className="c-track-start-time"
                type="text"
                name="track-start-time"
                value={seconds.toString()}
                onChange={event => onChangeStartTime(track, event)}
                onFocus={event => onSecondsFocus(event)}
                onBlur={event => onSecondsBlur(event)}
                error={hasError}
                ref={secondsInputRef}
            />
        </div>
    )
}

TrackStartTimeForm.propTypes = {
    track: PropTypes.object.isRequired,
    onTrackStartTimeUpdate: PropTypes.func.isRequired,
    hasError: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
}
