import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

export default class StepList extends Component {
    static propTypes = {
        steps: PropTypes.array,
    };

    render() {
        const {
            steps,
        } = this.props

        return (
            <ul className="c-release-step-list">
                {steps.map((step, index) => {
                    const className = classnames(
                        {
                            completed: !!step.completed,
                            error: !!step.error,
                        }
                    )
                    return <li key={index} className={className}>{step.label}</li>
                })}
            </ul>
        )
    }
}
