import { ApiIdName } from 'models/api/common'
import { ApiDspTranslation } from 'models/api/dsp-translation'
import { Artist } from 'models/artist'
import { ApiDspPublisher, ApiDspTrackAudio } from './api/dsp-track'

export interface ITrack {
    id: string,
    isrc: string | null
    title: string
    explicitLyrics: boolean | null,
    recordingVersion: ApiIdName<number>
    recordingVersionId: number
    hasAudio: boolean
    publishers: {
        id: number
        publishingHouse: string
        person: {
            name: string
        }
    }[]
    albumId: string
    audioLocale: boolean | ApiIdName<string>
    lyrics: string | null
    hasLyrics: boolean
    hasCustomIsrc: boolean | null
    audio?: ApiDspTrackAudio
    compositionTypeId: number
    compositionTermsAgreed: boolean | null
    translations: ApiDspTranslation[]
    sampleStartTime: number
    audioDuration: number
    trackNumber: number
    artists: Artist[] // Featured artists on the dsp track

    errors: Record<string, string>
    warnings: Record<string, any>
    uploadErrors: Record<string, any>
    isUploading: boolean
    isUpdating: boolean
    justAdded: boolean
    startTimeError?: Record<string, any>
    uploadProgress?: number
}

export default class Track implements ITrack {
    id: string = ''

    isrc: string | null = null

    albumId: string = ''

    title: string = ''

    artists: Artist[] = []

    hasAudio: boolean = false

    audio?: ApiDspTrackAudio = undefined

    audioDuration: number = -1

    audioLocale: boolean | ApiIdName<string> = false

    compositionTermsAgreed: boolean | null = null

    compositionTypeId: number = -1

    explicitLyrics: boolean | null = null

    hasCustomIsrc: boolean | null = null

    hasLyrics: boolean = false

    lyrics: string | null = null

    publishers: ApiDspPublisher[] = []

    recordingVersion: ApiIdName<number> = { id: -1, name: '' }

    recordingVersionId: number = -1

    sampleStartTime: number = 0

    trackNumber: number = -1

    translations: ApiDspTranslation[] = []

    artistTranslations?: Record<string, { [language: string]: { artistId: number, id?: number, value: string } }>

    errors: Record<string, string> = {}

    uploadErrors: Record<string, any> = {}

    warnings: Record<string, any> = {}

    justAdded: boolean = false

    isUploading: boolean = false

    isUpdating: boolean = false

    startTimeError?: Record<string, any>

    uploadProgress?: number = 0

    constructor(base?: Partial<ITrack>) {
        if (base) {
            Object.assign(this as Track, base)
        }
    }

    clone(overrideProps?: Partial<ITrack>) {
        const t = new Track()
        Object.assign(t as Track, this as Track, overrideProps)
        return t
    }
}
