import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
    isEmpty, intersectionWith, isEqual, isNil,
} from 'lodash'
import classnames from 'classnames'
import t from 'utilities/translate'
import Field from 'components/Field/Field'
import Checkbox from 'components/Checkbox/Checkbox'
import RadioGroup from 'components/Radio/RadioGroup'
import Radio from 'components/Radio/Radio'
import CountryInput from '../CountryInput/CountryInput'

const RESTRICTION_TYPE_EXCLUSION = 0 // falsy
const RESTRICTION_TYPE_INCLUSION = 1 // trusly

class TerritoryRestrictContainer extends Component {
    static isCountrySelected = (selectedCountries, country) => !isEmpty(intersectionWith(
        selectedCountries,
        [country],
        isEqual
    ))

    static propTypes = {
        label: PropTypes.string.isRequired,
        countries: PropTypes.array.isRequired,
        error: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        fieldName: PropTypes.string,
    };

    constructor(props) {
        super(props)

        this.state = {
            isIncluding: true,
            shouldDisplayControls: false,
        }
    }

    componentDidMount() {
        const {
            countries,
        } = this.props

        const numberAllowed = countries.filter(country => country.allowed).length
        const numberDisallowed = countries.length - numberAllowed

        const hasAtLeastOneRestriction = this.hasAtLeastOneRestriction()

        // by default there is no restrictions and we want a white list
        this.setState({
            isIncluding: hasAtLeastOneRestriction ? numberAllowed < numberDisallowed : true,
            shouldDisplayControls: hasAtLeastOneRestriction,
        })
    }

    // @todo because the parent component have is own state to manage countries, I could not write proper selector
    // ideally this kind of function should not be here
    getSelectedCountries = () => {
        const {
            countries,
        } = this.props

        const {
            isIncluding,
        } = this.state

        const hasAtLeastOneRestriction = this.hasAtLeastOneRestriction()

        // by default there is no restrictions so all the countries are allowed
        // but we do not want to display the tag of all the countries right at the beginning
        return hasAtLeastOneRestriction ? countries.filter(country => country.allowed === isIncluding) : []
    }

    // @todo because the parent component have is own state to manage countries, I could not write proper selector
    // ideally this kind of function should not be here
    hasAtLeastOneRestriction = () => {
        const {
            isIncluding,
        } = this.state

        return !this.props.countries.every(country => country.allowed === isIncluding)
    }

    // @todo because the parent component has own state to manage countries, I could have this logic within a reducer
    // ideally this kind of function should not be here
    toggleCountries = (forcing) => {
        const {
            countries,
            onChange,
        } = this.props

        const toggledCountries = countries.map((country) => {
            const isAllowed = !isNil(forcing) ? forcing : !country.allowed

            return {
                ...country,
                allowed: isAllowed,
            }
        })

        onChange(toggledCountries)
    }

    handleToggleRestrictionActivation = () => {
        const {
            isIncluding,
            shouldDisplayControls,
        } = this.state

        this.setState({
            // if we hide the controls, we revert isIncluding to his default state
            shouldDisplayControls: !shouldDisplayControls,
            isIncluding: !shouldDisplayControls ? true : isIncluding,
        }, () => {
            // if we hide the controls, we reset the restriction to his default state
            if (!this.state.shouldDisplayControls) {
                const {
                    countries,
                    onChange,
                } = this.props

                const resetCountries = countries.map(c => ({
                    ...c,
                    allowed: true,
                }))
                onChange(resetCountries)
            }
        })
    }

    handleToggleRestrictions = () => {
        const {
            isIncluding,
        } = this.state

        this.setState({
            isIncluding: !isIncluding,
        }, () => {
            this.toggleCountries()
        })
    }

    handleChangeSelectedCountries = (selectedCountries) => {
        const {
            countries,
            onChange,
        } = this.props

        const {
            isIncluding,
        } = this.state

        // @todo it is here we should check if we have reach more than the half of the countries
        // to swap from one type of restriction to an other (include <-> restrict)
        // but regarding the size of the list, I don't think it is a priority to implement that yet

        const toggledCountries = countries.map((country) => {
            const isSelected = TerritoryRestrictContainer.isCountrySelected(
                selectedCountries,
                {
                    value: country.countryCode,
                    label: country.countryName,
                }
            )

            // we put all the element to not selected
            let result = {
                ...country,
                allowed: !isIncluding,
            }

            if (isSelected) {
                result = {
                    ...country,
                    allowed: isIncluding,
                }
            }
            return result
        })

        onChange(toggledCountries)
    }

    render() {
        const {
            label,
            error,
            countries,
            fieldName,
        } = this.props

        const {
            shouldDisplayControls,
            isIncluding,
        } = this.state

        const hasAtLeastOneRestriction = this.hasAtLeastOneRestriction()
        let checkedValue = isIncluding ? RESTRICTION_TYPE_INCLUSION : RESTRICTION_TYPE_EXCLUSION
        // default value if no restriction defined yet
        checkedValue = hasAtLeastOneRestriction ? checkedValue : RESTRICTION_TYPE_INCLUSION

        return (
            <Field
                fieldName={fieldName}
                label={label}
                error={error || ''}
                className="c-territory-restrict"
            >
                <Field className={classnames({ 'c-territory-restrict-active': shouldDisplayControls })}>
                    <Checkbox
                        label={t('containerTerritoryRestrictLabelRestrict')}
                        checked={shouldDisplayControls}
                        onChange={this.handleToggleRestrictionActivation}
                    />
                </Field>

                {shouldDisplayControls
                    && (
                        <div>
                            <CountryInput
                                countries={countries}
                                selectedCountries={this.getSelectedCountries()}
                                onChange={this.handleChangeSelectedCountries}
                            />

                            <RadioGroup
                                name="allowed"
                                checkedValue={checkedValue}
                                onChange={this.handleToggleRestrictions}
                            >
                                <Radio
                                    value={RESTRICTION_TYPE_INCLUSION}
                                    label={t('containerTerritoryRestrictLabelRadioCountries')}
                                />
                                <Radio
                                    value={RESTRICTION_TYPE_EXCLUSION}
                                    label={t('containerTerritoryRestrictLabelRadioEverywhere')}
                                />
                            </RadioGroup>
                        </div>
                    )}
            </Field>
        )
    }
}

export default TerritoryRestrictContainer
