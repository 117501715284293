import { connect } from 'react-redux'
import { createRelease as createReleaseAction } from 'actions/ReleaseActions'
import { userSelector, artistCanCreateRelease as artistCanCreateReleaseSelector } from 'selectors/users'
import { device as d } from 'device.js'
import React, { useState } from 'react'
import Frame from 'components/Frame/Frame'
import Button from 'components/Button/Button'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import * as PropTypes from 'prop-types'
import { URL_CREATE_TITLE } from 'constants/AppUrls'
import { sendEventToDataLayer } from 'utilities/analytics'
import { withRouter } from 'react-router-dom'
import Alert from 'components/Alert/Alert'
import { releaseTypeIds } from 'models/release'
import GuidanceSection from 'routes/RouteContainers/App/ReleaseCreationGuidance/GuidanceSection'
import './_releaseCreationGuidance.scss'
import iconWorld from 'assets/images/World.png'
import iconAudio from 'assets/images/Audio.png'
import iconWav from 'assets/images/Wav.png'
import iconCover from 'assets/images/Cover.png'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'

export const ReleaseCreationGuidanceComponent = ({
    user,
    history,
    artistCanCreateRelease,
    error,
    createRelease,
}) => {
    const { t } = useLocaleContext()
    const [isCreating, setIsCreating] = useState(false)

    if (!artistCanCreateRelease) {
        history.push('/releases')
    }

    if (!d.desktop) {
        return (
            <section className="c-create-release">
                <header>
                    <h1>{t('componentCreateReleaseTitle')}</h1>
                    <p className="text-small">{t('releaseflowGuidanceSubtitle')}</p>
                </header>

                <Alert type="info">
                    {t('viewCreateReleaseNoAccess')}
                </Alert>
            </section>
        )
    }

    const onCreate = () => {
        setIsCreating(true)

        createRelease(releaseTypeIds.releaseAlbumAnnual, user.languageId)
            .then((action) => {
                if (action && action.payload && action.payload.id) {
                    const eventObj = {
                        pagePath: window.location.pathname,
                        pageTitle: URL_CREATE_TITLE,
                        pageUrl: window.location.href,
                    }

                    if (user) {
                        eventObj.userId = user.id
                        eventObj.country = user.countryId
                        eventObj.market = user.market
                    }
                    sendEventToDataLayer(
                        'ReleaseStart',
                        eventObj
                    )

                    history.push(`/edit/${action.payload.id}/tracks`)
                } else {
                    setIsCreating(false)
                }
            }, () => {
                setIsCreating(false)
            })
    }

    return (
        <section className="c-create-release">
            <header>
                <h1>{t('componentCreateReleaseTitle')}</h1>
                <TranslateMarkdown id="releaseflowGuidanceSubtitle" className="text-small" />
            </header>

            <Frame
                contentTint="light"
                borderImageId="dune-6"
            >
                <div className="c-release-guidance-container">
                    <GuidanceSection
                        sectionName="Audio"
                        iconPath={iconWav}
                        sectionFlowPosition="start"
                    />

                    <GuidanceSection
                        sectionName="Track"
                        iconPath={iconAudio}
                        sectionFlowPosition="mid"
                    />

                    <GuidanceSection
                        sectionName="Release"
                        iconPath={iconWorld}
                        sectionFlowPosition="mid"
                    />

                    <GuidanceSection
                        sectionName="Cover"
                        iconPath={iconCover}
                        sectionFlowPosition="end"
                    />
                </div>
            </Frame>

            <Button
                onClick={onCreate}
                isLoading={isCreating}
                disabled={isCreating}
            >
                {t('releaseflowGuidanceCreateButton')}
            </Button>

            {
                error && (
                    <Alert type="error">
                        {t('viewCreateReleaseAlert')}
                    </Alert>
                )
            }
        </section>
    )
}

ReleaseCreationGuidanceComponent.propTypes = {
    user: PropTypes.object.isRequired,
    error: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    artistCanCreateRelease: PropTypes.bool.isRequired,
    createRelease: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    user: userSelector(state),
    error: state.releases.error,
    artistCanCreateRelease: artistCanCreateReleaseSelector(state),
})

const mapDispatchToProps = {
    createRelease: createReleaseAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReleaseCreationGuidanceComponent))
