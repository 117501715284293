import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

export default class ArtistNames extends Component {
    static propTypes = {
        artists: PropTypes.array.isRequired,
    }

    getSpotifyUrl = (artist) => {
        const spotifyIdentifier = (artist.identifiers || [])
            .filter(identifier => identifier.name === 'spotify' && identifier.value)

        if (spotifyIdentifier.length > 0) {
            let spotifyId = spotifyIdentifier[0].value
            if (spotifyId.includes(':')) {
                [, , spotifyId] = spotifyId.split(':')
            }

            if (spotifyId) {
                return `https://open.spotify.com/artist/${spotifyId}`
            }
        }

        return null
    }

    renderArtist = (artist) => {
        let output

        const name = artist.artistName
        const spotifyUrl = this.getSpotifyUrl(artist)

        if (spotifyUrl) {
            output = (<a href={spotifyUrl} target="_blank" rel="noopener noreferrer">{name}</a>)
        } else {
            output = (<span>{name}</span>)
        }

        return output
    }

    renderSeparator = (index) => {
        const totalArtists = this.props.artists.length

        if (totalArtists === 1 || index === totalArtists - 1) {
            return ''
        } else if (index < totalArtists - 2) {
            return ', '
        } else if (index === totalArtists - 2) {
            return ' & '
        }
        return ''
    }

    render() {
        const { artists } = this.props

        return (
            <span>
                {artists.map((artist, index) => (
                    <span key={index}>
                        {this.renderArtist(artist)}
                        {this.renderSeparator(index)}
                    </span>
                ))}
            </span>
        )
    }
}
