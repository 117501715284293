import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import Field from 'components/Field/Field'
import Spinner from 'components/Spinner/Spinner'
import Saved from 'components/Saved/Saved'
import Button from 'components/Button/Button'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Icon from 'components/Icon/Icon'
import TextInput from 'components/Inputs/TextInput'
import { stripQueryStrings } from 'utilities/url'
import {
    getSpotifyUrlFromArtistId,
    getAppleUrlFromArtistId,
    isAppleMusicUrlValid,
    getSpotifyIdFromUrl,
    getAppleIdFromUrl,
} from 'utilities/services'
import { isTranslatableMarket } from 'utilities/translate'
import { ArtistAlias } from 'models/artistAlias'
import appleMusicColouredIcon from '../../svg/apple_music_icon_color.svg'

const ArtistInformation = (props) => {
    const {
        user,
        market,
        doLookup,
        updateArtistAlias,
    } = props

    const { t } = useLocaleContext()

    const [isUpdating, setIsUpdating] = useState(false)
    const [isDone, setIsDone] = useState(false)
    const [isSaveEnabled, setIsSaveEnabled] = useState(false)
    const [spotifyError, setSpotifyError] = useState(null)
    const [appleError, setAppleError] = useState(null)
    const [primaryArtist, setPrimaryArtist] = useState(new ArtistAlias())

    useEffect(() => {
        if (user) {
            setPrimaryArtist(user.artist.aliases ? user.artist.aliases : new ArtistAlias())
        }
    }, [user])

    const spotifyUrlRef = React.createRef()
    const appleUrlRef = React.createRef()

    useEffect(() => {
        if (isDone && !isUpdating) {
            if (
                !spotifyError
                && primaryArtist.spotifyId !== null
                && primaryArtist.spotifyId.length > 0
            ) {
                spotifyUrlRef.current.setEditing(false)
            }
            if (
                !appleError
                && primaryArtist.appleId !== null
                && primaryArtist.appleId.length > 0
            ) {
                appleUrlRef.current.setEditing(false)
            }
        }
    }, [isDone])

    const onSave = () => {
        setIsDone(false)
        setIsUpdating(true)
        setSpotifyError(false)
        setAppleError(false)
        const spotifyUrl = spotifyUrlRef.current.getValue()
        const appleUrl = appleUrlRef.current.getValue()
        const spotifyId = getSpotifyIdFromUrl(spotifyUrl)
        const appleId = getAppleIdFromUrl(appleUrl)

        if (appleUrl && !isAppleMusicUrlValid(appleUrl)) {
            setAppleError(t('settingsArtistInformationErrorLink'))
            setIsUpdating(false)
        } else if (spotifyUrl) {
            doLookup(spotifyUrl, market).then((action) => {
                if (action.payload.lookupType !== 'artist_link' || action.payload.results.length === 0) {
                    setSpotifyError(t('settingsArtistInformationErrorLink'))
                    setIsUpdating(false)
                } else if (action.payload.blacklisted) {
                    setSpotifyError(t('settingsArtistInformationErrorBlacklist'))
                    setIsUpdating(false)
                } else if (action.payload.results[0].name !== primaryArtist.name) {
                    setSpotifyError(t('settingsArtistInformationErrorMatch'))
                    setIsUpdating(false)
                } else {
                    updateArtistAlias({
                        spotifyId,
                        appleId,
                    }).then(() => {
                        setIsUpdating(false)
                        setIsDone(true)
                    })
                }
            })
        } else {
            updateArtistAlias({
                spotifyId,
                appleId,
            }).then(() => {
                setIsUpdating(false)
                setIsDone(true)
            })
        }
    }

    const onChangeArtistUrl = (event) => {
        setIsSaveEnabled(true)
        event.target.value = stripQueryStrings(event.target.value)
    }

    return (
        <div className="c-artist-information">
            <header>
                <h1>{t('settingsArtistInformationTitle')}</h1>
                <div className="c-artist-information-status">
                    {isUpdating
                        && (
                            <div className="c-artist-information-saving">
                                <Spinner size="small" horizontal>{t('globalSaving')}</Spinner>
                            </div>
                        )}
                    {isDone && !isUpdating && <Saved>{t('globalSaved')}</Saved>}
                </div>
            </header>
            <div className="c-artist-information-fields">
                <Field
                    label={t('onboardingArtistName')}
                >
                    <TextInput
                        disabled
                        value={primaryArtist.name}
                    />
                </Field>
                {isTranslatableMarket(market) && (
                    <Field
                        label={t('onboardingArtistNameKatakana')}
                    >
                        <TextInput
                            disabled
                            value={primaryArtist.nameKatakana}
                        />
                    </Field>
                )}
                {isTranslatableMarket(market) && (
                    <Field
                        label={t('onboardingArtistNameOverseas')}
                    >
                        <TextInput
                            disabled
                            value={primaryArtist.nameOverseas}
                        />
                    </Field>
                )}
                <h2>{t('settingsArtistInformationSubTitle')}</h2>
                <p>{t('settingsArtistInformationText')}</p>
                <div className="c-artist-information-icon-field">
                    <div className="c-artist-information-social-icon">
                        <Icon
                            id={primaryArtist.spotifyId !== null && primaryArtist.spotifyId.length > 0
                                ? 'spotify_logo_color' : 'spotify_logo'}
                            size={50}
                        />
                    </div>
                    <Field
                        error={spotifyError}
                    >
                        <TextInput
                            ref={spotifyUrlRef}
                            disabled={primaryArtist.spotifyId !== null && primaryArtist.spotifyId.length > 0}
                            value={primaryArtist.spotifyId ? getSpotifyUrlFromArtistId(primaryArtist.spotifyId) : null}
                            onChange={onChangeArtistUrl}
                            placeholder={t('settingsArtistServiceLinkPlaceholder', 'Spotify')}
                            imitatedInputType="link"
                            clickEdit
                        />
                    </Field>
                </div>
                <div className="c-artist-information-icon-field">
                    <div className="c-artist-information-social-icon">
                        {(primaryArtist.appleId === null || primaryArtist.appleId.length === 0)
                        && (
                            <Icon
                                id="apple_music_icon"
                                size={50}
                            />
                        )}
                        {primaryArtist.appleId !== null && primaryArtist.appleId.length > 0
                        && (
                            <img
                                src={appleMusicColouredIcon}
                                width="50"
                                height="50"
                                alt="apple_music_icon"
                            />
                        )}
                    </div>
                    <Field
                        error={appleError}
                    >
                        <TextInput
                            ref={appleUrlRef}
                            disabled={primaryArtist.appleId !== null && primaryArtist.appleId.length > 0}
                            value={primaryArtist.appleId ? getAppleUrlFromArtistId(primaryArtist.appleId) : null}
                            onChange={onChangeArtistUrl}
                            placeholder={t('settingsArtistServiceLinkPlaceholder', 'Apple Music')}
                            imitatedInputType="link"
                            clickEdit
                        />
                    </Field>
                </div>
            </div>

            <div className="c-artist-information-save">
                <Button onClick={onSave} disabled={isUpdating || !isSaveEnabled}>
                    {t('settingsArtistInformationButton')}
                </Button>
            </div>
        </div>
    )
}

ArtistInformation.propTypes = {
    user: PropTypes.object.isRequired,
    updateArtistAlias: PropTypes.func.isRequired,
    doLookup: PropTypes.func.isRequired,
    market: PropTypes.number.isRequired,
}

export default ArtistInformation
