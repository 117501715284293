import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { Spinner } from 'components/index'
import Translate from 'components/Translate/translate'
import { WalletAccount } from 'models/walletAccount'
import Alert from 'components/Alert/Alert'

const transferDropInId = 'TransferMethodUI'

const initializeHyperwalletWidget = (
    userAuthToken,
    userFirstName,
    userLastName,
    userAddress,
    onTransferMethodComplete,
    onTransferMethodSubmit,
    onTransferMethodError
) => (
    new Promise((resolve, reject) => {
        const hwWidgets = window.HWWidgets
        if (!hwWidgets) {
            reject('earningsWithdrawErrorLoadingHyperwallet')
        }

        // https://docs.hyperwallet.com/content/drop-in-uis/v1/transfer-method/setup
        hwWidgets.initialize((onSuccess) => {
            onSuccess(userAuthToken)
        })

        const configureRes = hwWidgets.transferMethods.configure({
            // https://docs.hyperwallet.com/content/drop-in-uis/v1/transfer-method/styling
            template: 'plain',
            skipProfileFields: false, // A value of true cannot be used with the profile object
            profile: {
                firstName: userFirstName,
                middleName: '',
                lastName: userLastName,
                addressLine1: userAddress.addressLine1,
                addressLine2: '',
                city: userAddress.city,
                postalCode: userAddress.postalCode,
                stateProvince: userAddress.stateProvince,
                country: userAddress.country,
                profileType: 'INDIVIDUAL',
            },
            transferMethodConfiguration: {
                // https://docs.hyperwallet.com/content/drop-in-uis/v1/transfer-method/configuration-options
                // profileType: 'INDIVIDUAL', // or BUSINESS. This cannot be supplied with profile -> profileType
                country: userAddress.country,
            },
            el: document.getElementById(transferDropInId),
            onSubmit() { // also takes a state arg
                onTransferMethodSubmit()
            },
            onComplete(trmObject) {
                if (!trmObject) {
                    onTransferMethodError('formHyperWalletTrmCreationFailed')
                } else {
                    onTransferMethodComplete(trmObject)
                }
            },
            onError(errors, state) {
                console.error('HyperWalletDropIn - Drop in errors', errors, state)
                onTransferMethodError('formHyperWalletTrmDropInError')
            },
        })

        // This is a callback event is supposedly called when displaying is done, but seems to leave a perceivable
        // gap between being called and the first field appearing??? The loading callback provides some sort of toggle
        // but only seems to be set to false after a sequence of fields has been completed and all dropdown data has
        // been loaded, which is not suitable for use in hiding the spinner off first load.
        configureRes.display(() => {
            resolve()
        })
    })
)

const HyperWalletTransferMethodDropIn = (props) => {
    const [dropInLoadError, setDropInLoadError] = useState(false)
    const [dropInTrmError, setDropInTrmError] = useState(null)
    const [scriptReady, setScriptReady] = useState(false)
    const [isDropInReady, setIsDropInReady] = useState(false)
    const [isTransferMethodCreating, setIsTransferMethodCreating] = useState(false)

    const { localeId, userToken } = props

    const initialiseDropIn = (authToken, userFirstName, userLastName, userAddress) => {
        initializeHyperwalletWidget(
            authToken,
            userFirstName,
            userLastName,
            userAddress,
            (trmObject) => {
                props.onTransferMethodComplete(trmObject)
                setIsTransferMethodCreating(true)
            },
            () => {
                setIsTransferMethodCreating(false)
            },
            setDropInTrmError
        )
            .then(() => {
                setIsDropInReady(true)
            }, (err) => {
                console.error('Error initialising hyperwallet drop-in', err)
                setDropInLoadError(true)
            })
    }

    useEffect(() => {
        const targetSite = window.env.HYPERWALLET_WIDGET_URL

        const dropInScript = document.createElement('script')
        dropInScript.src = `${targetSite}/rest/widgets/transfer-methods/${userToken}/${localeId}.min.js`
        dropInScript.async = true
        dropInScript.onerror = () => {
            console.error('Unable to load hyperwallet drop in script')
            setDropInLoadError(true)
        }
        dropInScript.onload = () => {
            setScriptReady(true)
        }

        document.head.appendChild(dropInScript)

        return () => {
            document.head.removeChild(dropInScript)
        }
    }, [])

    useEffect(() => {
        if (scriptReady) {
            // Pessimistically attempt refresh of the authToken as it has a 10 minute lifetime
            props.fetchWalletAccount(props.userId)
                .then(({ payload }) => {
                    const newAccount = new WalletAccount(payload)
                    initialiseDropIn(
                        newAccount.getAuth().token,
                        newAccount.getFirstName(),
                        newAccount.getLastName(),
                        newAccount.getAddress()
                    )
                })
        }
    }, [scriptReady])

    const showSpinner = !isDropInReady
    const spinnerClasses = classNames('hyperwallet-loading', { 'spinner-visible': showSpinner })
    const createSpinnerClasses = classNames(
        'hyperwallet-loading',
        'drop-in-spinner',
        { 'spinner-visible': isTransferMethodCreating }
    )
    const dropInClasses = classNames({ 'drop-in-visible': !showSpinner })

    if (dropInLoadError) {
        return (
            <Alert type="error" containerClassNames={['c-withdraw-alert', 'error']} colourise>
                <Translate id="formHyperwalletDropInFailedToLoad" />
            </Alert>
        )
    }

    const errorElem = dropInTrmError ? (
        <Alert type="error" containerClassNames={['c-withdraw-alert', 'error']} colourise>
            <Translate id={dropInTrmError} />
        </Alert>
    ) : undefined

    return (
        <>
            <div id={transferDropInId} className={dropInClasses} />
            <Spinner size="large" className={spinnerClasses} />
            <Spinner size="small" className={createSpinnerClasses} />
            { errorElem }
        </>
    )
}

HyperWalletTransferMethodDropIn.propTypes = {
    userToken: PropTypes.string.isRequired,
    localeId: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    onTransferMethodComplete: PropTypes.func.isRequired,
    fetchWalletAccount: PropTypes.func.isRequired,
}

export default HyperWalletTransferMethodDropIn
