import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WebFont from 'webfontloader'
import Select from 'components/Select/Select'
import { fetchFonts } from 'actions/FontsActions'
import { fontsDataSelector } from 'selectors/fonts'

const getStringFromSet = set => Array.from(set).join('')

const getUniqueCharacters = (words) => {
    const uniqueString = getStringFromSet(new Set(words.join('')))
    return uniqueString
}

const loadGoogleFontPreview = (fontNames) => {
    WebFont.load({
        google: {
            families: fontNames,
            text: getUniqueCharacters(fontNames),
        },
        timeout: 5000,
    })
}

class FontSelect extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fonts: PropTypes.array.isRequired,
        fetchFonts: PropTypes.func.isRequired,
    }

    componentDidMount() {
        if (!this.props.fonts.length) {
            this.props.fetchFonts().then((response) => {
                const fontNames = response.payload.data.map(font => font.name)
                loadGoogleFontPreview(fontNames)
            })
        }
    }

    render() {
        const {
            fonts, placeholder, value, onChange,
        } = this.props

        const formatedFonts = fonts.map(font => ({
            value: font.id,
            label: font.name,
        }))

        return (
            <Select
                placeholder={placeholder}
                disabled={!fonts}
                options={formatedFonts}
                value={value}
                onChange={onChange}
                isDisplayingFonts
            />
        )
    }
}

const mapStateToProps = (state) => {
    const { isLoaded, loadingFailed } = state.fonts
    const fonts = fontsDataSelector(state)
    return {
        isLoaded,
        loadingFailed,
        fonts,
    }
}

const mapDispatchToProps = {
    fetchFonts,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FontSelect)
