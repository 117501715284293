import * as types from 'constants/AuthActionTypes'
import { isExpired } from 'utilities/date'
import param from 'utilities/param'
import { createCallSignedApiUrlAction } from 'actions/SignedUrlActions'
import { types as mfaTypes } from 'actions/MfaActions'
import { CALL_API, CLIENT_CALL_API } from 'middleware/api'

let promise = false
let clientPromise = false

const clientId = window.env.SPINNUP_API_ID
const clientSecret = window.env.SPINNUP_API_SECRET

export const AUTH = Symbol('Validate user credentials to trigger MFA step')
export const VERIFY_MFA = Symbol('Verify Mfa Code')

function authenticateCredentials(action) {
    const { username, password, captchaResponse } = action
    const userId = param('user')

    const query = {}
    if (userId) {
        query.user_id = userId
    }

    return {
        [CALL_API]: {
            types: [
                types.AUTH_REQUEST,
                types.AUTH_SUCCESS,
                types.AUTH_FAILURE,
            ],
            payload: {
                endpoint: 'oauth/token',
                method: 'post',
                body: {
                    grantType: 'password',
                    clientId,
                    clientSecret,
                    username,
                    password,
                    'g-recaptcha-response': captchaResponse,
                },
                query,
            },
        },
    }
}

const verifyMfaCode = ({ signedUrl, mfaCode }) => createCallSignedApiUrlAction([
    mfaTypes.MFA_VERIFY_REQUEST,
    mfaTypes.MFA_VERIFY_SUCCESS,
    mfaTypes.MFA_VERIFY_FAILURE,
], signedUrl, {
    mfaCode,
})

function refresh(refreshToken) {
    return {
        [CALL_API]: {
            types: [
                types.REFRESH_REQUEST,
                types.REFRESH_SUCCESS,
                types.REFRESH_FAILURE,
            ],
            payload: {
                endpoint: 'oauth/token',
                method: 'post',
                body: {
                    grantType: 'refresh_token',
                    clientId,
                    clientSecret,
                    refreshToken,
                },
            },
        },
    }
}

function authenticateClient() {
    return {
        [CALL_API]: {
            types: [
                types.CLIENT_AUTH_REQUEST,
                types.CLIENT_AUTH_SUCCESS,
                types.CLIENT_AUTH_FAILURE,
            ],
            payload: {
                endpoint: 'oauth/token',
                method: 'post',
                body: {
                    grantType: 'client_credentials',
                    clientId,
                    clientSecret,
                },
            },
        },
    }
}

function nextRefresh(next, refreshToken) {
    if (promise) {
        return promise
    }
    promise = next(refresh(refreshToken)).then((action) => {
        if (action.type !== types.REFRESH_SUCCESS) {
            throw new Error('The refresh token has expired')
        }

        promise = false
    })

    return promise
}

function nextClientAuth(next) {
    if (clientPromise) {
        return clientPromise
    }

    clientPromise = next(authenticateClient()).then(() => {
        clientPromise = false
    })

    return clientPromise
}

export default store => next => (action) => {
    if (typeof action[AUTH] !== 'undefined') {
        return next(authenticateCredentials(action[AUTH]))
    } else if (typeof action[VERIFY_MFA] !== 'undefined') {
        return next(verifyMfaCode(action[VERIFY_MFA]))
    }

    const { credentials, clientCredentials, isAuthenticated } = store.getState().auth

    if (isAuthenticated) {
        const { expiresAt, refreshToken } = credentials

        if (isExpired(expiresAt)) {
            return nextRefresh(next, refreshToken)
                .then(() => next(action))
                .catch(() => {
                    next({
                        type: types.UNAUTH,
                    })
                    window.location = '/start'
                })
        }
    }

    if (typeof action[CLIENT_CALL_API] !== 'undefined') {
        const { expiresAt } = clientCredentials

        if (isExpired(expiresAt)) {
            return nextClientAuth(next)
                .then(() => next(action))
        }
    }

    return next(action)
}
