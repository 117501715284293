import { timeSince } from 'utilities/date'
import * as types from 'constants/PostActionTypes'
import { CALL_API } from 'middleware/api'

const fetchTime = 30 * 60 * 1000

// FETCH

function getPosts() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_POSTS_REQUEST,
                types.FETCH_POSTS_SUCCESS,
                types.FETCH_POSTS_FAILURE,
            ],
            payload: {
                endpoint: 'posts',
                query: {
                    scope: 'local',
                },
            },
        },
    }
}

export function fetchPosts() {
    return (dispatch, getState) => (timeSince(getState().posts.fetchedAt) >= fetchTime) && dispatch(getPosts())
}
