import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Field from 'components/Field/Field'
import MarketOrderSwitch from 'components/MarketOrderSwitch/MarketOrderSwitch'

const buildNewValue = (targetInputName, targetValue, existingValue) => {
    let newDay
    let newMonth
    let newYear

    if (targetInputName.endsWith('Day')) {
        newDay = targetValue
        newMonth = existingValue.month
        newYear = existingValue.year
    } else if (targetInputName.endsWith('Month')) {
        newDay = existingValue.day
        newMonth = targetValue
        newYear = existingValue.year
    } else {
        newDay = existingValue.day
        newMonth = existingValue.month
        newYear = targetValue
    }

    return {
        year: newYear,
        month: newMonth,
        day: newDay,
    }
}

/*
    The two approaches for this component were to either connect one field and aggregate the different connected
    inputs into a single redux field dispatch loop, or to connect each individual field to the form reducer via Field.
    The single field approach was taken because it gives encapsulated aggregate validation over all fields,
    whereas the Fields component would require form level validation
 */
const ReduxFormDateInput = ({
    input: {
        name,
        value,
        onChange,
        onBlur,
        onFocus,
    },
    meta: {
        warning,
        error,
        touched,
    },
    labelId,
    marketId,
    fieldClassName,
    disabled,
}) => {
    const { t } = useLocaleContext()
    const [hasBlurredOnce, setHasBlurredOnce] = useState(false)

    const onSubInputChange = (evt) => {
        const { target } = evt
        const newValue = buildNewValue(target.name, target.value, value)
        onChange(newValue, value)
    }

    const onSubInputBlur = (evt) => {
        const { target } = evt
        const newValue = buildNewValue(target.name, target.value, value)
        onBlur(newValue)

        setTimeout(() => {
            const newFocusedElement = document.activeElement
            const hasBlurredAllInputs = !newFocusedElement || newFocusedElement.nodeName !== 'INPUT'
                || !newFocusedElement.attributes.getNamedItem('name')
                || newFocusedElement.attributes.getNamedItem('name').value.indexOf(name) !== 0
            if (!hasBlurredOnce && hasBlurredAllInputs) {
                setHasBlurredOnce(hasBlurredAllInputs)
            }
        }, 1)
    }

    return (
        <Field
            label={t(labelId)}
            warning={touched && hasBlurredOnce ? t(warning) : null}
            error={touched && hasBlurredOnce ? t(error) : null}
            className={fieldClassName}
        >
            <div className="c-field-date-fields-wrapper">
                <MarketOrderSwitch
                    marketId={marketId}
                    type="dateOfBirth"
                    components={{
                        day: (
                            <input
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                className="c-input"
                                name={`${name}Day`}
                                placeholder={t('formInputPlaceholderDay')}
                                value={value.day || ''}
                                disabled={disabled}
                                onBlur={onSubInputBlur}
                                onFocus={onFocus}
                                onChange={onSubInputChange}
                            />
                        ),
                        month: (
                            <input
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                className="c-input"
                                name={`${name}Month`}
                                placeholder={t('formInputPlaceholderMonth')}
                                value={value.month || ''}
                                disabled={disabled}
                                onBlur={onSubInputBlur}
                                onFocus={onFocus}
                                onChange={onSubInputChange}
                            />
                        ),
                        year: (
                            <input
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                className="c-input c-date-input-double-width"
                                name={`${name}Year`}
                                placeholder={t('formInputPlaceholderYear')}
                                value={value.year || ''}
                                disabled={disabled}
                                onBlur={onSubInputBlur}
                                onFocus={onFocus}
                                onChange={onSubInputChange}
                            />
                        ),
                    }}
                />

            </div>
        </Field>
    )
}

ReduxFormDateInput.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    labelId: PropTypes.string,
    marketId: PropTypes.number,
    fieldClassName: PropTypes.string,
    disabled: PropTypes.bool,
}

export default ReduxFormDateInput
