import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import Spinner from 'components/Spinner/Spinner'
import t from 'utilities/translate'

export default class TrackUploadProgress extends Component {
    static propTypes = {
        tracks: PropTypes.array.isRequired,
    };

    render() {
        const { tracks } = this.props

        const tracksUploading = tracks.filter(track => track.isUploading)

        if (tracksUploading.length === 0) {
            return false
        }

        const totalProgress = tracksUploading.reduce((progress, track) => progress + track.uploadProgress, 0)
        const percentage = Math.round(totalProgress / tracksUploading.length)

        return (
            <Spinner className="c-track-upload-progress" size="small" percentage={percentage} horizontal>
                {t('containerTrackUploadUploadingLabel', tracksUploading.length)}
            </Spinner>
        )
    }
}
