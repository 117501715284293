import * as types from 'constants/BackgroundColorsActionTypes'

const initialState = {
    data: [],
    isLoaded: false,
    loadingFailed: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        // FETCH
        case types.FETCH_BACKGROUNDS_REQUEST:
            return {
                ...state,
                isLoaded: false,
            }
        case types.FETCH_BACKGROUNDS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isLoaded: true,
            }
        case types.FETCH_BACKGROUNDS_FAILURE:
            return {
                ...state,
                loadingFailed: true,
            }
        default:
            return state
    }
}
