import { camelizeKeys } from 'humps'
import Country from 'models/country'
import Album from 'models/album'
import { Artist } from 'models/artist'
import { ArtistProfile, ArtistFiles } from 'models/artistProfile'
import Track from 'models/track'
import Release from 'models/release'
import { orderByDate } from 'utilities/orderByDate'
import { spotifyProfileFieldName } from 'constants/EditProfileStructure'
import { translationLanguages } from 'constants/enums/TranslationLanguageEnums'
import { ArtistIdentifier } from 'models/artistIdentifier'

export function hydrateCountry(apiCountry) {
    return new Country(apiCountry)
}

export function hydrateProduct(apiProduct, existingProduct) {
    let genreId

    if (apiProduct.genres && apiProduct.genres.length) {
        genreId = apiProduct.genres[0].id
    } else {
        genreId = null
    }

    const existingProperties = existingProduct instanceof Album ? {
        settingsErrorMessages: existingProduct.settingsErrorMessages,
        packageErrorMessages: existingProduct.packageErrorMessages,
        errors: existingProduct.errors,
        coverArt: existingProduct.coverArt,
    } : {}

    return new Album({
        completionLevel: apiProduct.completionLevel,
        id: apiProduct.id,
        createdDate: apiProduct.createdAt,
        releaseDate: apiProduct.releaseAt,
        status: apiProduct.state,
        title: apiProduct.name,
        artists: (apiProduct.primaryArtists || []).map(trackArtist => hydrateArtist(trackArtist, apiProduct.id)),
        tracks: (apiProduct.tracks || []).map(track => hydrateTrack(track, apiProduct.id)),
        tracksOrder: (apiProduct.tracks || []).map(track => track.id),
        numberOfTracks: apiProduct.tracks.length,
        languageId: apiProduct.language ? apiProduct.language.id : null,
        countries: apiProduct.countries.map(country => hydrateCountry(country)),
        upc: apiProduct.upc,
        comment: apiProduct.rejectionMessage,
        coverArtUrl: (apiProduct.artwork && apiProduct.artwork.url) || null,
        coverArtUserConfirmed: false,
        originalReleaseDate: (apiProduct.originalReleaseDate === apiProduct.releaseAt)
            ? null : apiProduct.originalReleaseDate,
        genreId,
        translations: apiProduct.translations,
        ...existingProperties,
    })
}

export function hydrateArtist(apiArtist, albumId) {
    return new Artist({
        id: apiArtist.id,
        artistName: apiArtist.name,
        artistType: apiArtist.type,
        parentId: albumId,
        identifiers: hydrateArtistIdentifiers(apiArtist.identifiers),
        followers: apiArtist.followers,
        image: apiArtist.image,
        translations: apiArtist.translations,
    })
}

export function hydrateArtistIdentifiers(apiIdentifiers) {
    return (apiIdentifiers || []).map(identifier => new ArtistIdentifier({
        name: identifier.name,
        value: identifier.value,
    }))
}

// todo  It may be possible to refactor this to leverage the same logic from initialTranslationValues
// todo  in ArtistTranslationModalManager, however the dependencies and their 'freshness' (due to
// todo  the 'background' nature of the artist model updates on translation save) are difficult to follow or refactor.
// todo  This needs a re-rationalisation at some point.
export const hydrateTrackArtistTranslations = (artistsWithTranslations, deleteIndex = -1) => {
    const finalArtistTranslations = artistsWithTranslations.map((artist) => {
        const artistId = artist.id
        const translations = artist.translations || []
        const artistTranslations = {}
        translationLanguages.forEach((language) => {
            const matching = translations.find(tr => tr.language === language)
                || { value: '' }
            artistTranslations[language] = {
                artistId,
                id: null,
                value: matching.value,
            }
        })
        return artistTranslations
    })

    if (deleteIndex !== null && deleteIndex > -1) {
        finalArtistTranslations.splice(deleteIndex, 1)
    }

    return finalArtistTranslations.length > 0 ? { ...finalArtistTranslations } : { }
}

export function hydrateTrack(apiTrack, albumId, existingTrack = {}) {
    const stateProperties = {
        errors: existingTrack.errors || {},
        warnings: existingTrack.warnings || {},
        uploadErrors: existingTrack.uploadErrors || {},
        isUploading: existingTrack.isUploading || false,
        justAdded: existingTrack.justAdded || false, // at true only once created, if refetched, will be false
    }

    // it is for FE ONLY, it helps the redux-form to keep track of the yes/no buttons user decision
    let hasLyrics = !!apiTrack.explicitLyrics || !!apiTrack.lyrics || apiTrack.audioLocale

    if (apiTrack.audioLocale !== null && apiTrack.audioLocale !== false) {
        hasLyrics = true
    }
    const t = new Track({
        id: apiTrack.id,
        isrc: apiTrack.isrc,
        title: apiTrack.name,
        explicitLyrics: apiTrack.parentalAdvisory,
        artists: (apiTrack.featuredArtists || []).map(trackArtist => hydrateArtist(trackArtist, albumId)),
        recordingVersion: apiTrack.version ? apiTrack.version : null,
        recordingVersionId: apiTrack.version ? apiTrack.version.id : null,
        hasAudio: apiTrack.hasAudio,
        publishers: apiTrack.publishers,
        albumId,
        audioLocale: apiTrack.audioLocale,
        lyrics: apiTrack.lyrics,
        hasLyrics,
        hasCustomIsrc: apiTrack.hasCustomIsrc,
        audio: apiTrack.audio || existingTrack.audio,
        compositionTypeId: apiTrack.compositionTypeId,
        compositionTermsAgreed: apiTrack.compositionTermsAgreed,
        translations: apiTrack.translations,
        sampleStartTime: apiTrack.sampleStartTime,
        audioDuration: apiTrack.audioDuration,
        trackNumber: apiTrack.trackNumber,
        ...stateProperties,
    })
    t.artistTranslations = hydrateTrackArtistTranslations(t.artists)

    return t
}

export function hydrateRelease(apiRelease) {
    return new Release(apiRelease)
}

export function hydrateArtistProfile(artistData) {
    return new ArtistProfile({
        id: artistData.id,
        profileVisibility: artistData.artist.profileVisibility,
        hasPublicProfile: artistData.artist.hasPublicProfile,
        name: artistData.name,
        languageId: artistData.language.id,
        countryId: artistData.country && artistData.country.id,
        city: artistData.city,
        biography: artistData.biography,
        heroPlacement: artistData.heroPlacement,
        createdAt: artistData.createdAt,
        updatedAt: artistData.updatedAt,
        files: hydrateArtistFiles(artistData.files),
        backgroundColor: artistData.backgroundColor,
        font: artistData.font,
        links: hydrateArtistLinks(artistData.links, undefined),
        videos: artistData.videos.map(v => v.url),
        releases: artistData.releases.map(r => r.id),
        slug: artistData.artist.slug,
        tourDates: orderByDate(artistData.tourDates),
        surveys: artistData.artist.surveys,
    })
}

export const transformFiles = (files = []) => {
    const ccFiles = camelizeKeys(files)
    const fileTypes = {
        'logo-original': 'logo',
        'hero-large': 'hero',
        'gallery-small': 'gallery',
    }

    return ccFiles.reduce((accumulator, file) => {
        const type = fileTypes[file.type]
        if (type) {
            if (type === fileTypes['gallery-small']) {
                accumulator[type] = [{
                    ...file,
                    // In response from uploading files position is placed in fileOrder object
                    position: file.position || (file.fileOrder && file.fileOrder.position),
                }, ...accumulator[type]]
            } else {
                accumulator[type] = file
            }
        }
        return accumulator
    }, {
        gallery: [],
    })
}

export function hydrateArtistFiles(files) {
    const correctFiles = Array.isArray(files) ? transformFiles(files) : files
    const { logo = {}, hero = {}, gallery = [] } = correctFiles
    return new ArtistFiles({
        logo,
        hero,
        gallery: gallery.sort((a, b) => a.position - b.position),
    })
}

export function hydrateArtistLinks(links, spotifyProfile) {
    return links.map(link => (
        link.key === spotifyProfileFieldName
            ? ({
                ...link,
                value: spotifyProfile && ({
                    value: spotifyProfile,
                    label: spotifyProfile.get('artistName'),
                }),
            })
            : link
    ))
}
