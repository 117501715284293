import { connect } from 'react-redux'
import EditProfileTourDatesList from 'components/EditProfileTourDatesList/EditProfileTourDatesList'
import { draftProfileDataSelector } from 'selectors/draftProfile'
import { removeTour } from 'actions/ProfileActions'

const mapStateToProps = (state) => {
    const { tourDates } = draftProfileDataSelector(state)
    const { toursIdsInDeletingState } = state.tourDatesForm
    return {
        tourDates,
        toursIdsInDeletingState,
    }
}

const mapDispatchToProps = {
    removeTour,
}

const EditProfileTourDatesListContainer = connect(mapStateToProps, mapDispatchToProps)(EditProfileTourDatesList)

export default EditProfileTourDatesListContainer
