import { createSelector } from 'reselect'
import moment from 'moment'
import { getConnectedServicesSelector } from 'selectors/services'

export const socialTrendsSelector = state => state.socialTrends

export const socialTrendsFilterSelector = state => state.socialTrends.filter

export const getSocialTrendsSelector = metric => createSelector(
    socialTrendsSelector,
    socialTrendsFilterSelector,
    (socialTrends, filter) => ({
        filter,
        labels: getLabels(socialTrends[metric].sites, filter),
        data: Object.keys(socialTrends[metric].sites)
            .filter(key => socialTrends[metric].sites[key].isVisible !== false)
            .map(key => ({
                className: key,
                data: socialTrends[metric].sites[key].data.map(data => ({
                    meta: (filter.days === 'year') ? data.formattedMonthYear : data.formattedDate,
                    value: data.quantity,
                })),
            })),
    })
)

function getLabels(services, filter) {
    const socialServiceNames = Object.keys(services)
    // Grab the first service and check whether there is data available
    if (!services[socialServiceNames[0]].data.length) {
        // If there is no service data available, get the dates that would have been in range for the default 'week'
        // selection option to prevent the chart from displaying invalid dates
        // Dates in range: 8 days, the last date is always 2 days before today
        const labels = []
        let date = moment().subtract(10, 'days')
        for (let i = 0; i < 8; i++) {
            date = date.clone().add(1, 'days')
            labels.push(date.format('LL'))
        }
        return labels
    } else {
        // If there are services, then grab the dates listed for the first service
        return services[socialServiceNames[0]].data
            .map(item => (filter.days === 'year' ? item.formattedMonthYear : item.formattedDate))
    }
}

export const socialTrendsFansSelector = getSocialTrendsSelector('fans')
export const socialTrendsPlaysSelector = getSocialTrendsSelector('plays')

const socialTrendsSelectorsByMetric = {
    fans: socialTrendsFansSelector,
    plays: socialTrendsPlaysSelector,
}

const allSiteIcons = [
    {
        name: 'soundcloud', icon: 'faSoundcloud', fillColour: '#ff5600', metrics: ['fans', 'plays'],
    },
    {
        name: 'youtube', icon: 'faYoutube', fillColour: '#e50d24', metrics: ['fans', 'plays'],
    },
    {
        name: 'facebook', icon: 'faFacebookSquare', fillColour: '#38559d', metrics: ['fans'],
    },
    {
        name: 'twitter', icon: 'faTwitter', fillColour: '#53a8f0', metrics: ['fans'],
    },
    {
        name: 'instagram', icon: 'faInstagram', fillColour: '#000000', metrics: ['fans'],
    },
]

const siteIconToDisplayIcon = ({ name, icon, fillColour }) => ({
    name,
    iconId: icon,
    fillColour,
    isVisible: true,
})

const siteIconsByMetrics = {
    fans: allSiteIcons.filter(icon => icon.metrics.find(m => m === 'fans')).map(siteIconToDisplayIcon),
    plays: allSiteIcons.filter(icon => icon.metrics.find(m => m === 'plays')).map(siteIconToDisplayIcon),
}

export const siteIconsSelector = metric => createSelector(
    socialTrendsSelector,
    (socialTrends) => {
        const siteIcons = siteIconsByMetrics[metric]
        return siteIcons
            .filter(icon => !!socialTrends[metric].sites[icon.name])
            .map((icon) => {
                const site = socialTrends[metric].sites[icon.name]
                return {
                    ...icon,
                    isVisible: !!site && site.isVisible,
                }
            })
    }
)

export const overviewSocialStats = metric => createSelector(
    getConnectedServicesSelector,
    socialTrendsSelectorsByMetric[metric],
    siteIconsSelector(metric),
    (connectedServices, socialTrends, icons) => {
        if (connectedServices.length === 0) {
            return []
        }

        const socialStats = connectedServices.map((service) => {
            const serviceName = service.title
            const serviceIcon = icons.filter(icon => icon.name === serviceName.toLowerCase())[0]
            const serviceData = socialTrends.data.filter(fanData => fanData.className === serviceName.toLowerCase())[0]
            const serviceFans = (serviceData.data.length > 0 && serviceData.data.slice(-1)[0].value)
                ? serviceData.data.slice(-1)[0].value
                : null

            let formattedTotal
            if (serviceFans === 0 || serviceFans === null) {
                formattedTotal = 0
            } else if (serviceFans > 999) {
                formattedTotal = `${(serviceFans / 1000).toFixed(2)}k`
            } else {
                formattedTotal = serviceFans
            }

            return {
                icon: serviceIcon,
                serviceFans,
                formattedTotal,
            }
        })

        const orderedServices = socialStats
            .sort((serviceA, serviceB) => serviceA.serviceFans < serviceB.serviceFans)
            .slice(0, 2)

        return orderedServices.map(service => ({
            ...service,
            total: service.formattedTotal,
        }))
    }
)
