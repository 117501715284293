import * as types from 'constants/MessageActionTypes'

const initialState = []

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_MESSAGE:
            return [
                ...state.filter(message => message.id !== action.payload.id),
                {
                    id: action.payload.id,
                    hidden: false,
                },
            ]
        case types.REMOVE_MESSAGE:
            return state.map(
                message => (message.id === action.payload.id
                    ? { ...message, hidden: true }
                    : message)
            )
        default:
            return state
    }
}
