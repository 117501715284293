import * as types from 'constants/WalletActionTypes'
import { callApi } from 'actions/ApiActions'
import { AppStoreDispatch } from 'store/store-types'

export function fetchBalance() {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.FETCH_BALANCE_REQUEST,
            types.FETCH_BALANCE_SUCCESS,
            types.FETCH_BALANCE_FAILURE,
        ], {
            endpoint: 'wallets/balance',
        }
    ))
}

export function fetchTransactions() {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.FETCH_TRANSACTIONS_REQUEST,
            types.FETCH_TRANSACTIONS_SUCCESS,
            types.FETCH_TRANSACTIONS_FAILURE,
        ], {
            endpoint: 'wallets/transactions',
        }
    ))
}

export function withdraw(body: any) {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.WITHDRAW_REQUEST,
            types.WITHDRAW_SUCCESS,
            types.WITHDRAW_FAILURE,
        ], {
            method: 'post',
            endpoint: 'wallets/withdraw',
            body,
        }
    ))
}

export function fetchWalletAccount(userId: string) {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.FETCH_WALLET_ACCOUNT_REQUEST,
            types.FETCH_WALLET_ACCOUNT_SUCCESS,
            types.FETCH_WALLET_ACCOUNT_FAILURE,
        ], {
            endpoint: `wallets/psp/${userId}`,
        }
    ))
}

export function createWalletAccount(body: any) {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.CREATE_WALLET_ACCOUNT_REQUEST,
            types.CREATE_WALLET_ACCOUNT_SUCCESS,
            types.CREATE_WALLET_ACCOUNT_FAILURE,
        ], {
            method: 'put',
            endpoint: 'wallets/psp',
            body,
        }
    ))
}

export function updateWalletAccount(body: any) {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.UPDATE_WALLET_ACCOUNT_REQUEST,
            types.UPDATE_WALLET_ACCOUNT_SUCCESS,
            types.UPDATE_WALLET_ACCOUNT_FAILURE,
        ], {
            method: 'put',
            endpoint: 'wallets/psp',
            body,
        }
    ))
}

export function fetchWithdrawFees() {
    return (dispatch: AppStoreDispatch) => dispatch(callApi(
        [
            types.FETCH_WITHDRAW_FEES_REQUEST,
            types.FETCH_WITHDRAW_FEES_SUCCESS,
            types.FETCH_WITHDRAW_FEES_FAILURE,
        ], {
            method: 'get',
            endpoint: 'wallets/psp/fees',
        }
    ))
}
