import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import Divider from 'components/Divider/Divider'

const Book = ({
    coverOnThe,
    coverRenderer,
    pageRenderer,
    borderImageId,
    className,
}) => {
    const rootClassname = classnames(
        'c-book',
        `c-book-cover-on-the-${coverOnThe}`,
        `background-${borderImageId}`,
        className
    )

    return coverOnThe === 'right' ? (
        <div className={rootClassname}>
            <div className="c-book-page">
                {pageRenderer()}
            </div>
            <Divider imageId="dune-2" />
            <div className="c-book-cover">
                {coverRenderer()}
            </div>
        </div>
    ) : (
        <div className={rootClassname}>
            <div className="c-book-cover">
                {coverRenderer()}
            </div>
            <Divider imageId="dune-2" />
            <div className="c-book-page">
                {pageRenderer()}
            </div>
        </div>
    )
}

Book.propTypes = {
    coverRenderer: PropTypes.func.isRequired,
    pageRenderer: PropTypes.func.isRequired,
    borderImageId: PropTypes.string,
    className: PropTypes.string,
    coverOnThe: PropTypes.oneOf(['left', 'right']),
}

Book.defaultProps = {
    coverOnThe: 'right',
    borderImageId: 'dune-2',
}

export default Book
