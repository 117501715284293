import React, { useState } from 'react'
import classNames from 'classnames'
import { PropTypes } from 'prop-types'
import Button from 'components/Button/Button'
import EditProfileShareButton from 'components/EditProfileButtons/EditProfileShareButton'
import EditProfileViewButton from 'components/EditProfileButtons/EditProfileViewButton'
import Spinner from 'components/Spinner/Spinner'
import t, { tmarkdown } from 'utilities/translate'

const OverviewProfileCompleted = ({
    name,
    hero,
    url,
    openPopup,
    addNotification,
    slug,
}) => {
    const [isHeroLoaded, setIsHeroLoaded] = useState(false)
    const handleHeroLoaded = () => {
        setIsHeroLoaded(true)
    }
    const heroImage = hero
        ? (
            <>
                <img
                    className={classNames(
                        'c-overview-profile-hero-image', ({
                            loaded: isHeroLoaded,
                        })
                    )}
                    src={hero}
                    alt="Profile mobile preview"
                    onLoad={handleHeroLoaded}
                />
                {!isHeroLoaded && (
                    <div className="c-overview-profile-hero-spinner-wrapper">
                        <Spinner size="small" />
                    </div>
                )}
            </>
        )
        : null
    const artistUrl = `${window.env.SPINNUP_PROFILE_URL}${slug}`

    return (
        <div className="c-overview-profile-wrapper">
            <div className="c-overview-profile-message">
                <h3 className="c-overview-profile-title">
                    {t('componentOverviewProfileCompletedTitle')}
                </h3>
                <div className="c-overview-profile-description">
                    <div
                        dangerouslySetInnerHTML={
                            { __html: tmarkdown('componentOverviewProfileCompletedDescription', name, artistUrl) }
                        }
                    />
                </div>
                <div className="c-overview-profile-button-wrapper">
                    <Button href="/profile" className="c-overview-profile-button--full-width">
                        {t('componentOverviewProfileCompletedButtonUpdate')}
                    </Button>
                    <EditProfileViewButton url={url} hasPublicProfile openPopup={openPopup} />
                    <EditProfileShareButton url={url} addNotification={addNotification} />
                </div>
            </div>
            <div className="c-overview-profile-stats">
                <div className="c-overview-profile-hero-wrapper">
                    {heroImage}
                </div>
            </div>
        </div>
    )
}

OverviewProfileCompleted.propTypes = {
    name: PropTypes.string,
    hero: PropTypes.string,
    url: PropTypes.string.isRequired,
    openPopup: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    slug: PropTypes.string,
}

export default OverviewProfileCompleted
