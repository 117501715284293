import { timeSince } from 'utilities/date'
import * as types from 'constants/GenreActionTypes'
import { CALL_API } from 'middleware/api'

const fetchTime = 1000 * 60 * 60 * 24

// FETCH

function getGenres() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_GENRES_REQUEST,
                types.FETCH_GENRES_SUCCESS,
                types.FETCH_GENRES_FAILURE,
            ],
            payload: {
                endpoint: 'refdata/genres',
            },
        },
    }
}

export function fetchGenres() {
    return (dispatch, getState) => (timeSince(getState().genres.fetchedAt) >= fetchTime) && dispatch(getGenres())
}
