export const FETCH_TRENDS_REQUEST = 'FETCH_TRENDS_REQUEST'
export const FETCH_TRENDS_SUCCESS = 'FETCH_TRENDS_SUCCESS'
export const FETCH_TRENDS_FAILURE = 'FETCH_TRENDS_FAILURE'
export const FETCH_TRENDS_TERRITORYS_REQUEST = 'FETCH_TRENDS_TERRITORYS_REQUEST'
export const FETCH_TRENDS_TERRITORYS_SUCCESS = 'FETCH_TRENDS_TERRITORYS_SUCCESS'
export const FETCH_TRENDS_TERRITORYS_FAILURE = 'FETCH_TRENDS_TERRITORYS_FAILURE'
export const FETCH_OVERVIEW_TRENDS_REQUEST = 'FETCH_OVERVIEW_TRENDS_REQUEST'
export const FETCH_OVERVIEW_TRENDS_SUCCESS = 'FETCH_OVERVIEW_TRENDS_SUCCESS'
export const FETCH_OVERVIEW_TRENDS_FAILURE = 'FETCH_OVERVIEW_TRENDS_FAILURE'
export const TRENDS_TOGGLE_VISIBILITY = 'TRENDS_TOGGLE_VISIBILITY'
export const TRENDS_TOGGLE_OVERVIEW_VISIBILITY = 'TRENDS_TOGGLE_OVERVIEW_VISIBILITY'
