import { createSelector } from 'reselect'
import { find } from 'lodash'

export const isFetchingVersions = state => state.versions.isFetching

export const getById = (state, versionId) => find(state.versions.versions, { id: parseInt(versionId, 10) })

export const versionsSelector = createSelector(
    state => state.versions.versions,
    versions => versions.slice().sort((a) => {
        if (a.id === '2430') {
            return -1
        }

        if (a.id === '2423') {
            return -0.9
        }

        if (a.id === '2422') {
            return -0.8
        }

        return 1
    })
)
