import React from 'react'

import * as PropTypes from 'prop-types'
import MessageBanner from 'components/MessageBanner/MessageBanner'

MessageBanners.propTypes = {
    messages: PropTypes.array.isRequired,
}

function MessageBanners(props) {
    return (
        <div className="c-message-banners">
            {props.messages && props.messages.length > 0 && props.messages.map(item => (
                <MessageBanner id={item.id} key={item.id} />
            ))}
        </div>
    )
}

export default (MessageBanners)
