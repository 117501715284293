import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'utilities/translate'
import { albumSelector } from 'selectors/albums'
import { fetchRelease } from 'actions/ReleaseActions'
import ReleasePackageFormContainer from 'containers/ReleasePackageForm/ReleasePackageForm'

const PackageRelease = ({ album, release }) => (!album
    ? <div>{t('globalLoading')}</div>
    : <ReleasePackageFormContainer album={album} release={release} />
)

PackageRelease.propTypes = {
    album: PropTypes.object.isRequired,
    release: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => ({
    album: albumSelector(state, props.release.providerId),
})
const mapDispatchToProps = {
    fetchRelease,
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageRelease)
