import React, { useState } from 'react'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import * as PropTypes from 'prop-types'
import { TrackStartTimeForm } from 'components/TrackStartTimeForm/TrackStartTimeForm'
import { Checkbox } from 'components/index'
import { formatAudioDuration } from 'utilities/formatAudioDuration'
import Field from 'components/Field/Field'
import { tmarkdown } from 'utilities/translate'

export const StoreDeliveryOptions = ({
    onSocialDeliveryRelease,
    onYoutubeToggleRelease,
    userOptedIn,
    userYoutubeOptedIn,
    onTrackStartTimeUpdate,
    allTracks,
}) => {
    const { t } = useLocaleContext()
    const checkboxRef = React.createRef()
    const youtubeCheckboxRef = React.createRef()
    const [isDisplayingStartTimes, setIsDisplayingStartTimes] = useState(userOptedIn)
    const [isOptedIn, setIsOptedIn] = useState(userOptedIn)
    const [isYoutubeOptedIn, setIsYoutubeOptedIn] = useState(userYoutubeOptedIn)

    const isEligible = tracks => tracks.every(track => track.compositionTypeId === 1) && tracks.length > 0

    const toggleShowStartTimes = () => {
        setIsDisplayingStartTimes(!isDisplayingStartTimes)
    }

    const onOptInChange = () => {
        const checkbox = checkboxRef.current.getValue()
        onSocialDeliveryRelease(!!checkbox)
        setIsOptedIn(!!checkbox)
        toggleShowStartTimes()
    }

    const onYoutubeOptInChange = () => {
        const checkbox = youtubeCheckboxRef.current.getValue()
        onYoutubeToggleRelease(!!checkbox)
        setIsYoutubeOptedIn(!!checkbox)
        toggleShowStartTimes()
    }

    const trackAudioUploaded = track => track.audioDuration > 0
    const trackAudioTooShort = track => track.audioDuration < 60

    const startTimeDisabled = (track) => {
        const isUploaded = trackAudioUploaded(track)
        const isTrackAudioTooShort = trackAudioTooShort(track)
        return (!isUploaded || isTrackAudioTooShort)
    }

    const renderSocialDeliveryOptions = () => (
        <div className="c-release-social-delivery-terms-control">
            <Checkbox
                disabled={!isEligible(allTracks)}
                ref={checkboxRef}
                checked={userOptedIn}
                onChange={onOptInChange}
                label={(
                    <span>
                        { t('releaseFlowDeliveryOptionsSocialText')}&nbsp;
                    </span>
                )}
            />
            {isEligible(allTracks) ? (
                <>
                    {isOptedIn ? (
                        <p className="c-store-delivery-options-start-time-toggle" onClick={toggleShowStartTimes}>
                            {isDisplayingStartTimes
                                ? `- ${t('releaseFlowDeliveryOptionsSocialStartTitleOpen')}`
                                : `+ ${t('releaseFlowDeliveryOptionsSocialStartTitleClosed')}`}
                        </p>
                    ) : null}
                    {isOptedIn && isDisplayingStartTimes ? (
                        <>
                            <p className="c-store-delivery-options-info">
                                {t('releaseFlowDeliveryOptionsSocialStartText')}
                            </p>
                            <div className="c-store-delivery-options-track-list">
                                <div className="c-store-delivery-options-track-header">
                                    <span>{t('releaseFlowDeliveryOptionsSocialStartMins')}</span>
                                    <span>{t('releaseFlowDeliveryOptionsSocialStartSecs')}</span>
                                </div>
                                {allTracks.map((track, index) => {
                                    const title = track.title || t('selectorTracksUntitledTrack')
                                    return (
                                        <div className="c-store-delivery-options-track" key={`track-${track.id}`}>
                                            <div className="c-store-delivery-options-track-title">
                                                {index + 1}. {title} {formatAudioDuration(track.audioDuration)}
                                                {trackAudioUploaded(track) === false
                                                    ? (
                                                        <p className="msg-helptext">
                                                            {t('releaseFlowSocialUploadIncompleteError')}
                                                        </p>
                                                    )
                                                    : trackAudioTooShort(track)
                                                        ? (
                                                            <p className="msg-helptext">
                                                                {t('releaseFlowSocialMinimumError')}
                                                            </p>
                                                        )
                                                        : null}
                                                {track.startTimeError !== null
                                                    ? <p className="msg-error">{t(track.startTimeError)}</p>
                                                    : null}
                                            </div>
                                            <TrackStartTimeForm
                                                key={track.id}
                                                track={track}
                                                onTrackStartTimeUpdate={onTrackStartTimeUpdate}
                                                hasError={track.startTimeError !== null}
                                                isDisabled={startTimeDisabled(track)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    ) : null}
                </>
            ) : null }
        </div>
    )

    const renderYoutubeDeliveryOptions = () => (
        <Field
            label={t('releaseFlowDeliveryOptionsYoutubeHelpTitle')}
            popover={{
                title: t('releaseFlowDeliveryOptionsYoutubeHelpTitle'),
                text: tmarkdown('releaseFlowDeliveryOptionsYoutubeHelpText'),
            }}
        >
            <Checkbox
                disabled={!isEligible(allTracks)}
                ref={youtubeCheckboxRef}
                checked={isYoutubeOptedIn}
                onChange={onYoutubeOptInChange}
                label={(
                    <span>
                        { t('releaseFlowDeliveryOptionsYoutubeText')}&nbsp;
                    </span>
                )}
            />
        </Field>
    )

    return (
        <div className="c-release-social-delivery">
            {renderSocialDeliveryOptions()}
            {renderYoutubeDeliveryOptions()}
            {!isEligible(allTracks) ? (
                <p className="c-store-delivery-options-not-eligible">
                    {t('releaseFlowDeliveryOptionsSocialDisabled')}
                </p>
            ) : null }
        </div>
    )
}

StoreDeliveryOptions.propTypes = {
    onSocialDeliveryRelease: PropTypes.func.isRequired,
    onYoutubeToggleRelease: PropTypes.func.isRequired,
    userOptedIn: PropTypes.bool.isRequired,
    userYoutubeOptedIn: PropTypes.bool.isRequired,
    allTracks: PropTypes.array,
    onTrackStartTimeUpdate: PropTypes.func.isRequired,
}
