import { timeSince } from 'utilities/date'
import * as types from 'constants/CountryActionTypes'
import { CALL_API } from 'middleware/api'

const fetchTime = 1000 * 60 * 60 * 24

// FETCH

function getCountries() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_COUNTRIES_REQUEST,
                types.FETCH_COUNTRIES_SUCCESS,
                types.FETCH_COUNTRIES_FAILURE,
            ],
            payload: {
                endpoint: 'refdata/countries',
            },
        },
    }
}

export function fetchCountries() {
    return (dispatch, getState) => (timeSince(getState().countries.fetchedAt) >= fetchTime) && dispatch(getCountries())
}
