import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import classnames from 'classnames'
import Select from 'components/Select/Select'
import Pill from './Pill'

export default class Pills extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        size: PropTypes.string,
        items: PropTypes.array.isRequired,
        onClick: PropTypes.func,
        mobileButtons: PropTypes.bool,
    }

    handleSelectChange = (index) => {
        const item = this.props.items[index]

        if (item.onClick) {
            item.onClick(item.href)
        } else if (this.props.onClick) {
            this.props.onClick(item.href)
        } else {
            this.props.history.push(item.href)
        }
    }

    render() {
        const {
            items,
            onClick,
            size,
            mobileButtons,
        } = this.props

        const pillClassName = classnames(
            'c-navs-pills',
            {
                'size-small': size === 'small',
                'mobile-buttons': mobileButtons,
            }
        )

        const activeItemIndex = items.findIndex(item => !!item.active || item.href === window.location.pathname)

        return (
            <nav role="navigation" className={pillClassName}>
                <ul className="pills">
                    {items.map(item => (
                        <Pill
                            key={item.href}
                            onClick={onClick}
                            {...item}
                        />
                    ))}
                </ul>

                <Select
                    selectClassName="select"
                    options={items.map((item, index) => ({
                        label: item.label,
                        value: index,
                    }))}
                    value={activeItemIndex}
                    disableSort
                    onChange={this.handleSelectChange}
                />
            </nav>
        )
    }
}
