import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import Header from 'containers/Header/Header'
import Footer from 'containers/Footer/Footer'
import Login from 'views/Start/Login'
import { makeLocalesSelector } from 'selectors/locales'
import { fetchLocales } from 'actions/LocaleActions'
import { getCountryCode, getCountryMarket } from 'utilities/translate'
import Forgot from 'views/Start/Forgot'
import Reset from 'views/Start/Reset'
import useScrollToTopEffect from 'hooks/useScrollToTopEffect'
import { usePrevious } from 'hooks/usePrevious'
import VerifyCheck from 'views/Verify/VerifyCheck'
import { URL_LOGIN, URL_LOGIN_TITLE } from 'constants/AppUrls'
import { EVENT_PAGE_VIEW, sendEventToDataLayer } from 'utilities/analytics'
import VerifyBlock from 'views/Verify/VerifyBlock'
// eslint-disable-next-line import/no-named-as-default
import MfaEnrol from 'views/Mfa/MfaEnrol'
import MfaActivate from 'views/Mfa/MfaActivate'
import MfaVerify from 'views/Mfa/MfaVerify'
import ForcedPasswordReset from 'views/Start/ForcedPasswordReset'
import ForcedPasswordResetEmailBlock from 'views/Start/ForcedPasswordResetEmailBlock'
import { urlsSelector } from 'selectors/urls'

const baseUrl = '/start'

const Start = (props) => {
    const { locales = [], urls } = props
    const prevValue = usePrevious({ locales })

    useScrollToTopEffect()

    useEffect(() => {
        props.fetchLocales()
    }, [])

    useEffect(() => {
        if (prevValue.locales.length === 0 && locales.length > 0) {
            window.dataLayer.push({
                artist: {
                    country: getCountryCode(props.locales),
                    market: getCountryMarket(props.locales),
                },
            })

            const eventObj = {
                pagePath: URL_LOGIN,
                pageUrl: `${window.location.origin}${URL_LOGIN}`,
                pageTitle: URL_LOGIN_TITLE,
                country: getCountryCode(props.locales),
                market: getCountryMarket(props.locales),
            }
            sendEventToDataLayer(
                EVENT_PAGE_VIEW,
                eventObj
            )
        }
    }, [locales.length])
    return (
        <div className="l-start">
            <Header />

            <main>
                <Switch>
                    <Route exact path={`${baseUrl}`} component={Login} />

                    <Route path="/verify/block" render={() => <VerifyBlock />} />
                    <Route path="/verify/:userId/check" render={() => <VerifyCheck />} />

                    <Route path="/mfa/enrol" render={() => <MfaEnrol />} />
                    <Route path="/mfa/activate" render={() => <MfaActivate />} />
                    <Route path="/mfa/verify" render={() => <MfaVerify />} />

                    <Route path="/password/required" render={() => <ForcedPasswordResetEmailBlock />} />
                    <Route path="/password/reset" render={() => <ForcedPasswordReset />} />

                    <Route path={`${baseUrl}/forgot`} render={() => <Forgot />} />
                    { window.env.ENABLE_INVITE_ONLY === 'true' ? (
                        <Route
                            path={`${baseUrl}/xg75hyl8`}
                            component={() => {
                                window.location.href = urls.publicWebsiteHome
                                return null
                            }}
                        />
                    ) : null}
                    <Route path={`${baseUrl}/reset`} render={() => <Reset />} />

                    <Redirect to={baseUrl} />
                </Switch>
            </main>

            <Footer />
        </div>
    )
}

Start.propTypes = {
    fetchLocales: PropTypes.func.isRequired,
    locales: PropTypes.array.isRequired,
    urls: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    locales: makeLocalesSelector(false)(state),
    urls: urlsSelector(state),
})

const mapDispatchToProps = {
    fetchLocales,
}

export default connect(mapStateToProps, mapDispatchToProps)(Start)
