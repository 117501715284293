import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchScout } from 'actions/ScoutActions'
import { scoutSelector } from 'selectors/scouts'

import Alert from 'components/Alert/Alert'
import t from 'utilities/translate'
import Spinner from 'components/Spinner/Spinner'
import Competition from 'components/Competition/Competition'
import { scrollUpToTop } from 'hooks/useScrollToTopEffect'

class CompetitionView extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        fetchScout: PropTypes.func.isRequired,
        scout: PropTypes.object,
        isLoading: PropTypes.bool,
    };

    componentDidMount() {
        this.props.fetchScout(this.props.match.params.scoutId)
        scrollUpToTop()
    }

    render() {
        const { scout, isLoading } = this.props

        if (isLoading) {
            return <Spinner size="small" horizontal>{t('globalLoading')}</Spinner>
        } else if (!isLoading && scout && scout.isCompetitionContact === 1) {
            return <Competition scout={scout} isLoading={isLoading} />
        } else {
            return (
                <Alert type="error">
                    <strong>{t('viewCompetitionInvalid')}</strong>
                </Alert>
            )
        }
    }
}

const mapStateToProps = (state, props) => ({
    scout: scoutSelector(state, Number(props.match.params.scoutId)),
    isLoading: !!state.scouts.isFetching || !!state.releases.isFetching,
})

const mapDispatchToProps = {
    fetchScout,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompetitionView))
