import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import Alert from 'components/Alert/Alert'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { URL_EARNINGS_WITHDRAW_SUCCESS_TITLE } from 'constants/AppUrls'
import { EVENT_PAGE_VIEW, sendEventToDataLayer } from 'utilities/analytics'

const AccountBalanceWithdrawSuccess = ({ user, history }) => {
    useEffect(() => {
        const { pathname, origin } = window.location
        const pagePath = `${pathname}/complete`
        const pageUrl = `${origin}${pagePath}`
        const pageTitle = URL_EARNINGS_WITHDRAW_SUCCESS_TITLE
        const eventObj = {
            pagePath,
            pageUrl,
            pageTitle,
            userId: user.id,
            country: user.countryId,
            market: user.market,
        }

        sendEventToDataLayer(EVENT_PAGE_VIEW, eventObj)
    }, [])

    return (
        <Alert type="success" containerClassNames={['c-withdraw-alert', 'success']}>
            <TranslateMarkdown
                id="earningsWithdrawSuccessfulWithdrawal"
                args={['/balance/history']}
                useReactRouter
                history={history}
            />
        </Alert>
    )
}

AccountBalanceWithdrawSuccess.propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

export default AccountBalanceWithdrawSuccess
