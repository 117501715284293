import React from 'react'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import CrmCompleteDoubleOptInContainer from 'containers/CrmCompleteDoubleOptIn/CrmCompleteDoubleOptIn'
import Button from 'components/Button/Button'
import t from 'utilities/translate'

const CompleteDoubleOptIn = (props) => {
    const { match: { params } } = props

    return (
        <div className="v-crm-complete-doi">
            <CrmCompleteDoubleOptInContainer doiSecret={params.doiSecret} />

            <div className="v-crm-complete-doi-actions">
                <Button href="/overview">
                    {t('viewCrmCompleteDoubleOptInBackToSite')}
                </Button>
            </div>
        </div>
    )
}

CompleteDoubleOptIn.propTypes = {
    match: PropTypes.object.isRequired,
}

export default withRouter(CompleteDoubleOptIn)
