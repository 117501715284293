import { createSelector } from 'reselect'
import t from 'utilities/translate'

function orderReleaseTypes(releaseTypes) {
    return releaseTypes.sort((a, b) => a.maxTracks > b.maxTracks)
}

function formatReleaseType(releaseType, prevReleaseType) {
    const minTracks = prevReleaseType ? prevReleaseType.maxTracks + 1 : 1
    return {
        ...releaseType,
        numberOfTracks: `${minTracks}-${releaseType.maxTracks}`,
        formattedPrice: releaseType.currentPrice ? releaseType.currentPriceFormatted : t('selectorProductsFree'),
        isFree: !releaseType.currentPrice,
    }
}

export const releaseTypesSelector = createSelector(
    state => state.releaseTypes.releaseTypes,
    (releaseTypes) => {
        const orderedReleaseTypes = orderReleaseTypes(releaseTypes.toArray())

        return orderedReleaseTypes.map((releaseType, index) => {
            const prevReleaseType = orderedReleaseTypes[index - 1]
            return formatReleaseType(releaseType, prevReleaseType)
        })
    }
)

export const releaseTypeSelector = createSelector(
    releaseTypesSelector,
    (state, releaseId) => state.releases.releases[String(releaseId)].releaseTypeId,
    (releaseTypes, id) => releaseTypes.find(releaseType => releaseType.id === id)
)

export const makeReleaseTypeSelector = releaseId => createSelector(
    releaseTypesSelector,
    state => state.releases.releases[String(releaseId)].releaseTypeId,
    (releaseTypes, id) => releaseTypes.find(releaseType => releaseType.id === id)
)
