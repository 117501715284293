import React from 'react'
import classnames from 'classnames'
import MarketOrderSwitch from 'components/MarketOrderSwitch/MarketOrderSwitch'
import { countryRegionFields } from 'components/AddressForm/countryRegions'
import { getMarketIdFromCountryCode } from 'constants/enums/MarketEnums'

const regionCountries = Object.keys(countryRegionFields)

// There are currently two address formats: Adyen and Hyperwallet. Adyen uses postcode/zipcode/prefecture/state/region
// Hyperwallet merges fields such that we have postalCode and stateProvince condensed.
export const renderAddressFormConfirmedEditable = (
    initialValues,
    confirmedAddress,
    countryOptions,
    regionOptions,
    classNames
) => {
    const switchableComponentEntries = Object.entries(confirmedAddress).map((entry) => {
        const [k, v] = entry
        if ((['postcode', 'zipcode', 'postalCode'].indexOf(k) >= 0) && v.length > 0) {
            return ['zipPostcode', v]
        }
        if ((['prefecture', 'state', 'province', 'stateProvince'].indexOf(k) >= 0) && v.length > 0) {
            const hasCustomRegion = regionOptions && (regionCountries.indexOf(confirmedAddress.country) >= 0)
            return ['region', hasCustomRegion ? regionOptions.find(opt => opt.value === v).label : v]
        }
        if (k === 'country') {
            const countryEntry = (countryOptions || []).find(opt => opt.value === v)
            return [k, countryEntry ? countryEntry.label : v]
        }
        if (v.length === 0) {
            return [k, null]
        }
        return entry
    }).filter(e => e[1] !== null)

    const hasMergedLine1 = Object.hasOwnProperty.call(confirmedAddress, 'addressLine1')
    const type = hasMergedLine1 ? 'displayAddressMergedLine1' : 'displayAddress'
    const wrapperClasses = classnames('c-address-form-confirmed', classNames)

    return (
        <div className={wrapperClasses}>
            <MarketOrderSwitch
                marketId={getMarketIdFromCountryCode(confirmedAddress.country)}
                type={type}
                components={Object.fromEntries(
                    switchableComponentEntries.map(([k, v]) => [k, (<p key={k}>{v}</p>)])
                )}
            />
        </div>
    )
}

export const Address = ({
    address,
    countries,
    classNames,
}) => {
    const regionOptions = Object.hasOwnProperty.call(countryRegionFields, address.country)
        ? countryRegionFields[address.country].options
        : null
    return renderAddressFormConfirmedEditable({}, address, countries, regionOptions, classNames)
}
