import React from 'react'
import * as PropTypes from 'prop-types'
import moment from 'moment'
import Field from 'components/Field/Field'
import Radio from 'components/Radio/Radio'
import RadioGroup from 'components/Radio/RadioGroup'
import DatePicker from 'components/DatePicker/DatePicker'
import { getRestrictedMaxReleaseDate, getRestrictedMinReleaseDate } from 'modules/releaseDate'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const ReleaseDateComponent = (props) => {
    const {
        label,
        value,
        error,
        fieldName,
        onChange,
    } = props

    const { t, tmarkdown } = useLocaleContext()

    const date = value ? moment(value).toDate() : null
    const restrictedMinDate = getRestrictedMinReleaseDate()
    const minDate = restrictedMinDate ? restrictedMinDate.toDate() : moment().add(15, 'days').toDate()
    const restrictedMaxDate = getRestrictedMaxReleaseDate()
    const maxDate = restrictedMaxDate ? restrictedMaxDate.toDate() : moment().add(1, 'years').toDate()

    const onChangeAsap = (asap) => {
        const newAsapDate = restrictedMinDate || moment().add(15, 'days')
        onChange(asap === '1' ? newAsapDate.format() : '')
    }

    const onChangeDate = (newDate) => {
        onChange(moment(newDate).format())
    }

    return (
        <Field
            fieldName={fieldName}
            label={label}
            error={error || ''}
            className="c-release-settings-form-release-date"
        >

            <RadioGroup
                name="asap"
                checkedValue={date ? '1' : '0'}
                onChange={onChangeAsap}
                align="column"
            >
                <Radio value="0" isMarkdownLabel label={tmarkdown('containerReleaseDateLabelAsap')} />
                <Radio value="1" isMarkdownLabel label={tmarkdown('containerReleaseDateLabelSpecific')} />
            </RadioGroup>

            {date ? (
                <DatePicker
                    onChange={onChangeDate}
                    selected={date}
                    placeholderText={t('containerReleaseDatePickerPlaceholder')}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            ) : null}
        </Field>
    )
}

ReleaseDateComponent.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
}

export default ReleaseDateComponent
