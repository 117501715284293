import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import classnames from 'classnames'

export default class WidgetButton extends Component {
    static propTypes = {
        href: PropTypes.string,
        text: PropTypes.string,
        isDisabled: PropTypes.bool,
        blank: PropTypes.bool,
        anchor: PropTypes.string,
    };

    render() {
        const {
            href,
            text,
            blank,
            anchor,
            isDisabled,
        } = this.props

        const linkState = anchor ? { anchor } : null

        const buttonClassname = classnames(
            'c-widget-button',
            {
                disabled: isDisabled,
            }
        )

        return (
            <div>
                {isDisabled
                    ? (
                        <span className={buttonClassname}>{text}</span>
                    )
                    : (
                        <Link
                            className={buttonClassname}
                            to={{
                                pathname: href,
                                state: linkState,
                            }}
                            target={blank ? '_blank' : ''}
                        >
                            {text}
                        </Link>
                    )}

            </div>
        )
    }
}
