// FETCH

export const FETCH_ARTIST_ALIASES_REQUEST = 'FETCH_ARTIST_ALIASES_REQUEST'
export const FETCH_ARTIST_ALIASES_SUCCESS = 'FETCH_ARTIST_ALIASES_SUCCESS'
export const FETCH_ARTIST_ALIASES_FAILURE = 'FETCH_ARTIST_ALIASES_FAILURE'

// CREATE

export const CREATE_ARTIST_ALIAS_REQUEST = 'CREATE_ARTIST_ALIAS_REQUEST'
export const CREATE_ARTIST_ALIAS_SUCCESS = 'CREATE_ARTIST_ALIAS_SUCCESS'
export const CREATE_ARTIST_ALIAS_FAILURE = 'CREATE_ARTIST_ALIAS_FAILURE'

// UPDATE

export const UPDATE_ARTIST_ALIAS_REQUEST = 'UPDATE_ARTIST_ALIAS_REQUEST'
export const UPDATE_ARTIST_ALIAS_SUCCESS = 'UPDATE_ARTIST_ALIAS_SUCCESS'
export const UPDATE_ARTIST_ALIAS_FAILURE = 'UPDATE_ARTIST_ALIAS_FAILURE'
