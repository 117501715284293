import { decamelizeKeys } from 'humps'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import fetch from 'utilities/fetch'
import upload from 'utilities/upload'
import { REFRESH_REQUEST } from 'constants/AuthActionTypes'
import { parseLinkHeader } from '@web3-storage/parse-link-header'
import { Middleware, AnyAction, Dispatch } from 'redux'
import { AppStoreState } from 'store/store-types'

export const CALL_API = Symbol('Call API')
export const DOWNLOAD_API = Symbol('Download API')
export const UPLOAD_API = Symbol('Upload API')
export const UPLOAD_CHUNKED_API = Symbol('Upload Chunked API')
export const ABORT_UPLOAD_CHUNKED_API = Symbol('Abort Upload Chunked API')
export const CLIENT_CALL_API = Symbol('Client call API')

export type ApiPayload = {
    endpoint?: string
    method?: string
    body?: any
    query?: Record<string, string>
    headers?: HeadersInit
    maintainBodyFormat?: boolean
    file?: any
    abort?: boolean
}

export type ApiMeta = {
    [k: string]: any
}

export type ApiActionBody = {
    types: string[],
    payload?: ApiPayload
    meta?: ApiMeta
}

export type ApiAction = {
    type: 'api-action'
    [action: symbol]: ApiActionBody
}

interface ApiResultAction {
    type: string
    payload: any
    error: any
    meta: ApiMeta
}

const HEADER_NAME_LINK = 'link'
const HEADER_NAME_X_VIEW = 'x-view'
const HEADER_NAME_MFA_PHONE_NUMBER = 'x-mfa-phonenumber'
// const HEADER_NAME_MFA_REGISTERED_COUNTRY = 'x-mfa-registeredcountry'

const HEADERS_VIEW_DATA: Record<string, string> = {
    [HEADER_NAME_MFA_PHONE_NUMBER]: 'userPhone',
    // [HEADER_NAME_MFA_REGISTERED_COUNTRY]: 'userRegisteredCountry',
}

const fileUploaders: FineUploaderTraditional[] = []

function uploadApi(next: Dispatch, action: ApiActionBody, accessToken: string)
    : Promise<{ payload?: any, error?: any, length?: number, headers?: Headers }> {
    const { types, payload, meta } = action
    const { endpoint, body } = payload ?? {}

    return upload({
        endpoint: endpoint ?? 'no-endpoint',
        accessToken,
        body,
        rootUrl: window.env.SPINNUP_API ?? 'no-api',
        onProgress: (response) => {
            next({
                type: types[3],
                payload: response,
                meta,
            })
        },
    })
        .then(response => ({ payload: response && JSON.parse(response) }))
        .catch(response => ({ error: response && JSON.parse(response) }))
}

type ChunkedUploadReturn = { payload?: any, rejected?: boolean, length?: number, headers?: Headers }
function chunkedUploadApi(next: Dispatch, action: ApiActionBody, accessToken: string) {
    const { payload, meta, types } = action
    const rootUrl = window.env.SPINNUP_API ?? 'no-api'

    return fetch({
        endpoint: `${payload?.endpoint}/start`,
        method: 'get',
        rootUrl,
        accessToken,
    })
        .then(({ payload: { uuid } }) => uuid)
        .then((uuid) => {
            let numberOfChunksUploaded = 0
            const partSize = 2621440
            return new Promise<ChunkedUploadReturn>((resolve, reject) => {
                fileUploaders[meta?.uploadId] = new FineUploaderTraditional({
                    options: {
                        request: {
                            endpoint: rootUrl + payload?.endpoint,
                            inputName: 'file',
                            uuidName: 'uuid',
                            filenameParam: 'fileName',
                            totalFileSizeName: 'totalFileSize',
                            customHeaders: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                            params: {
                                maxChunkSize: partSize,
                                fileType: payload?.file?.type,
                            },
                        },
                        retry: {
                            enableAuto: true,
                        },
                        chunking: {
                            enabled: true,
                            mandatory: true,
                            partSize,
                            paramNames: {
                                chunkSize: 'chunkSize',
                                partByteOffset: 'chunkByteOffset',
                                partIndex: 'chunkIndex',
                                totalParts: 'totalChunks',
                            },
                            concurrent: {
                                enabled: true,
                            },
                            success: {
                                endpoint: rootUrl + payload?.endpoint,
                            },
                        },
                        callbacks: {
                            onUpload: (id: any) => {
                                fileUploaders[meta?.uploadId].methods.setUuid(id, uuid)
                            },
                            onUploadChunkSuccess: (id: any, chunkData: any, response: any) => {
                                if (typeof types[3] !== 'undefined') {
                                    numberOfChunksUploaded += 1

                                    const percentage = (numberOfChunksUploaded / response.totalChunks) * 100

                                    next({
                                        type: types[3],
                                        payload: percentage,
                                        meta,
                                    })
                                }
                            },
                            onComplete: (id: any, name: any, response: any) => {
                                resolve({ payload: response, length: undefined, headers: undefined })
                            },
                            onError: (id: any, name: any, reason: any) => {
                                const errorMsg = (reason === 'XHR returned response code 0')
                                    ? 'ERROR_SERVER_ERROR'
                                    : reason

                                reject({
                                    error: [errorMsg],
                                })
                            },
                            onCancel: () => {
                                reject({ rejected: true })
                            },
                        },
                    },
                })
                fileUploaders[meta?.uploadId].methods.addFiles(payload?.file)
            })
        })
        .catch((response) => {
            const error = (typeof response.error.message === 'undefined') ? response.error : ['ERROR_SERVER_ERROR']
            return Promise.reject({
                error,
            })
        })
}

function abortChunkedUploadApi(uploadId: number) {
    fileUploaders[uploadId].methods.cancelAll()
}

function callApi(action: ApiActionBody, accessToken: string, isBlobResponse = false) {
    const { payload, types } = action
    const {
        endpoint, method, query, body, headers, maintainBodyFormat,
    } = payload ?? {}
    const [requestType] = types
    // Don't set the access token for a refresh
    const isRefresh = requestType === REFRESH_REQUEST
    const isAbsoluteEndpoint = endpoint?.startsWith('http://') || endpoint?.startsWith('https://')

    return fetch({
        endpoint: endpoint ?? 'no-endpoint',
        method: method ?? 'get',
        accessToken: isRefresh ? undefined : accessToken,
        query,
        rootUrl: isAbsoluteEndpoint ? '' : (window.env.SPINNUP_API ?? 'no-api-defined'),
        body: body && JSON.stringify(maintainBodyFormat ? body : decamelizeKeys(body)),
        headers,
        isBlobResponse,
    }).then((resp) => {
        if (resp.isBlobPayload) {
            const tempLink = document.createElement('a')
            const objectUrl = URL.createObjectURL(resp.payload)
            tempLink.href = objectUrl
            tempLink.download = resp.blobFileName ?? ''
            tempLink.click()
            URL.revokeObjectURL(objectUrl)
        }
        return resp
    })
}

function getResponseLinks(headers: Headers) {
    return parseLinkHeader(headers.get(HEADER_NAME_LINK))
}

function getResponseViewData(headers: Headers) {
    const viewData: Record<string, string> = {}

    headers.forEach((val, key) => {
        const viewDataKey = HEADERS_VIEW_DATA[key]
        if (viewDataKey) {
            viewData[viewDataKey] = val
        }
    })

    return viewData
}

// I couldn't work out how to get this inferred through the redux store middleware application so this needs
// to be used directly.
export interface ApiDispatchExt {
    <T extends ApiAction>(action: T): Promise<ApiResultAction>
    <T extends AnyAction>(action: T): T
    // <T extends AnyAction>(action: T): T extends ApiAction ? Promise<ApiResultAction> : T
}
type ApiMiddleware = Middleware<ApiDispatchExt, AppStoreState>

const middleware: ApiMiddleware = store => next => (action: any) => {
    const apiAction = (action[CALL_API] || action[CLIENT_CALL_API] || action[UPLOAD_API] || action[UPLOAD_CHUNKED_API]
        || action[DOWNLOAD_API]) as ApiActionBody
    const { credentials, clientCredentials } = store.getState().auth

    if (typeof apiAction === 'undefined') {
        if (typeof action[ABORT_UPLOAD_CHUNKED_API] !== 'undefined') {
            const abortAction = action[ABORT_UPLOAD_CHUNKED_API] as ApiActionBody

            abortChunkedUploadApi(abortAction?.meta?.uploadId)
            return next({
                ...abortAction,
                type: abortAction.types[0], // Whereas the others provide req/suc/fail types, this one is singular...
            })
        }

        return next(action as AnyAction)
    }

    const { types, meta } = apiAction
    const [requestType, successType, failureType] = types

    let promise

    if (typeof action[CLIENT_CALL_API] !== 'undefined') {
        promise = callApi(apiAction, clientCredentials.accessToken)
    }

    if (typeof action[CALL_API] !== 'undefined') {
        promise = callApi(apiAction, credentials.accessToken)
    }

    if (typeof action[DOWNLOAD_API] !== 'undefined') {
        promise = callApi(apiAction, credentials.accessToken, true)
    }

    if (typeof action[UPLOAD_API] !== 'undefined') {
        promise = uploadApi(next, apiAction, credentials.accessToken)
    }

    if (typeof action[UPLOAD_CHUNKED_API] !== 'undefined') {
        promise = chunkedUploadApi(next, apiAction, credentials.accessToken)
    }

    next({ type: requestType, meta })

    return promise
        ?.then(({ payload, length, headers }) => next({
            type: successType,
            meta: {
                ...meta,
                view: headers ? headers.get(HEADER_NAME_X_VIEW) : undefined,
                links: headers ? getResponseLinks(headers) : undefined,
                viewData: headers ? getResponseViewData(headers) : undefined,
                length,
            },
            payload,
        }))
        .catch(({ error, rejected, statusCode }) => {
            if (rejected) {
                return { rejected }
            }

            if (error && error.message === 'The resource owner or authorization server denied the request.') {
                window.location.href = '/start'
                return false
            }

            return next({
                type: failureType,
                error: Object.assign(error || {}, { httpStatusCode: statusCode }),
                meta,
            })
        })
}

export default middleware
