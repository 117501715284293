import React from 'react'
import BalanceContainer from 'containers/Balance/Balance'
import AccountBalanceWithdrawal from 'components/EarningsAccountBalanceWithdrawal/AccountBalanceWithdrawal'
import { WithdrawFormContainerProps } from 'containers/WithdrawForm/WithdrawForm'

const WithdrawForm: React.FC<WithdrawFormContainerProps> = () => (
    <div className="c-withdraw-form">

        <BalanceContainer />

        <section className="c-withdraw-form">
            <AccountBalanceWithdrawal />
        </section>
    </div>
)

export default WithdrawForm
