import React from 'react'
import { PropTypes } from 'prop-types'
import Button from 'components/Button/Button'
import EditProfileViewButton from 'components/EditProfileButtons/EditProfileViewButton'
import OverviewProfilePreview from 'components/OverviewProfile/OverviewProfilePreview'
import t, { tmarkdown } from 'utilities/translate'

const OverviewProfileUncompleted = ({
    name,
    url,
    isPublishedAsPublic,
    openPopup,
}) => (
    <div className="c-overview-profile-wrapper">
        <div className="c-overview-profile-message">
            <h3 className="c-overview-profile-title">
                {t('componentOverviewProfileUncompletedTitle')}
            </h3>
            <div
                className="c-overview-profile-description"
                dangerouslySetInnerHTML={{ __html: tmarkdown('componentOverviewProfileUncompletedDescription', name) }}
            />
            <div className="c-overview-profile-button-wrapper">
                <Button href="/profile" className="c-overview-profile-button">
                    {t('componentOverviewProfileUncompletedButton')}
                </Button>
                {isPublishedAsPublic && (
                    <EditProfileViewButton url={url} hasPublicProfile openPopup={openPopup} />
                )}
            </div>
        </div>
        <OverviewProfilePreview />
    </div>
)

OverviewProfileUncompleted.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string.isRequired,
    isPublishedAsPublic: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
}

export default OverviewProfileUncompleted
