import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Field as ReduxField } from 'redux-form'
import classnames from 'classnames'

import {
    COMPOSITION_TYPE_ORIGINAL,
    COMPOSITION_TYPE_COVER,
    COMPOSITION_TYPE_SAMPLE,
    COMPOSITION_TYPE_BEAT,
} from 'constants/enums/TrackEnums'

import Spinner from 'components/Spinner/Spinner'

import { ARTIST_TYPE_FEATURED, Artist } from 'models/artist'

import Alert from 'components/Alert/Alert'
import Toggle from 'components/Toggle/Toggle'
import Field from 'components/Field/Field'
import Select from 'components/Select/Select'
import TextInput from 'components/Inputs/TextInput'
import Button from 'components/Button/Button'
import RadioGroup from 'components/Radio/RadioGroup'
import Radio from 'components/Radio/Radio'
import Textarea from 'components/Textarea/Textarea'
import Checkbox from 'components/Checkbox/Checkbox'
import ArtistLookup from 'containers/ArtistLookup/ArtistLookup'
import t, { tmarkdown } from 'utilities/translate'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { importantLanguages } from 'constants/enums/LanguageEnums'
import SongwriterPublisherField from 'components/TrackForm/Fields/SongwriterPublisherField'
import TranslatableInput from 'containers/TranslatableInput/TranslatableInput'
import { formatAudioDuration } from 'utilities/formatAudioDuration'

export default class TrackForm extends Component {
    static propTypes = {
        track: PropTypes.object.isRequired,
        releaseSiblingTracks: PropTypes.array.isRequired,
        isActiveTrack: PropTypes.bool.isRequired,
        accessToken: PropTypes.string.isRequired,
        removeTrack: PropTypes.func.isRequired,
        uploadFile: PropTypes.func.isRequired,
        versions: PropTypes.array.isRequired,
        isFetchingVersions: PropTypes.bool,
        isFetchingAudioLocales: PropTypes.bool.isRequired,
        hasCustomIsrc: PropTypes.bool,
        showCopyPopup: PropTypes.func,
        showDeleteSongwriterPopup: PropTypes.func,
        audioLocaleMap: PropTypes.object.isRequired,
        copyPublisherToSiblings: PropTypes.func.isRequired,
        getTrackById: PropTypes.func.isRequired,
        selectTrack: PropTypes.func.isRequired,
        publisherIsGlobal: PropTypes.func.isRequired,
        updateCandidatePublisher: PropTypes.func.isRequired,
        addPublisherToTrack: PropTypes.func.isRequired,
        numForms: PropTypes.number.isRequired,
        addTranslatedTitleToTrack: PropTypes.func.isRequired,
        addTranslatedArtistsToTrack: PropTypes.func.isRequired,
        validateTrackTitleWithMetadata: PropTypes.func.isRequired,
        validateArtistNamesWithMetadata: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.audioRef = React.createRef()
        this.fileInputRef = React.createRef()
        this.state = {
            hasCustomIsrc: !!props.track.hasCustomIsrc,
            isShowingFullName: false,
            hasAddedAudioListeners: false,
            isPlayingAudio: false,
            isBuffering: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActiveTrack !== prevProps.isActiveTrack) {
            // Handle the situation of toggling different tracks expanded state
            this.stopAudioPlayback()
        }

        if (!this.state.hasAddedAudioListeners && this.audioRef.current) {
            this.setState({ hasAddedAudioListeners: true }, () => {
                this.audioRef.current.addEventListener('timeupdate', this.onAudioPlayUpdate)
                this.audioRef.current.addEventListener('playing', () => this.setState({ isBuffering: false }))
                this.audioRef.current.addEventListener('waiting', () => this.setState({ isBuffering: true }))
            })
        }
    }

    onCreateArtist(name) {
        const artist = new Artist({
            artistName: name,
            artistType: ARTIST_TYPE_FEATURED,
        })

        const artists = (this.state.form.artists || [])
        artists.push(artist)

        this.save({ artists })
    }

    onHasCustomIsrcChange = (value) => {
        this.setState({
            hasCustomIsrc: value,
        })
    }

    onTitleTranslationSave = (translations, track) => {
        const titleTranslationObjects = Object.entries(translations).reduce(
            (allTranslations, [index, titleTranslations]) => {
                const translationsByLanguageObject = Object.entries(titleTranslations).reduce(
                    (translationsByTitle, [language, languageTranslations]) => {
                        if (languageTranslations
                            && languageTranslations.value !== null
                            && languageTranslations.value !== ''
                        ) {
                            return [
                                ...translationsByTitle,
                                {
                                    property: 'name',
                                    value: languageTranslations.value,
                                    language,
                                },
                            ]
                        } else {
                            return translationsByTitle
                        }
                    }, []
                )
                return {
                    ...allTranslations,
                    [index]: translationsByLanguageObject,
                }
            }, {}
        )
        this.props.addTranslatedTitleToTrack(titleTranslationObjects[0], track)
    }

    onArtistTranslationSave = (translations, track) => {
        const translationsByArtists = Object.entries(translations).reduce(
            (allTranslations, [index, artistTranslations]) => {
                const translationsByArtistObject = Object.entries(artistTranslations).reduce(
                    (translationsByArtist, [language, languageTranslations]) => {
                        if (languageTranslations
                            && languageTranslations.value !== null
                            && languageTranslations.value !== ''
                        ) {
                            return [
                                ...translationsByArtist,
                                {
                                    property: 'name',
                                    value: languageTranslations.value,
                                    language,
                                },
                            ]
                        } else {
                            return translationsByArtist
                        }
                    }, []
                )
                return {
                    ...allTranslations,
                    [index]: translationsByArtistObject,
                }
            }, {}
        )
        this.props.addTranslatedArtistsToTrack(translationsByArtists, track)
    }

    handleOverName = (isOver) => {
        this.setState({
            isShowingFullName: isOver,
        })
    }

    handleFileReplace = ({ id, albumId }) => {
        this.fileInputRef.current.value = null
        this.fileInputRef.current.onchange = (event) => {
            const files = event.dataTransfer ? event.dataTransfer.files : event.target.files

            this.stopAudioPlayback()
            this.props.uploadFile(albumId, id, files[0])
        }
        this.fileInputRef.current.click()
    }

    getAuthenticatedAudioUrl = url => `${url}&access_token=${this.props.accessToken}`

    onAudioPlayUpdate = () => {
        const progress = this.audioRef.current.currentTime / this.audioRef.current.duration

        if (progress >= 1) {
            this.stopAudioPlayback()
        }
    }

    toggleAudioPlayback = () => {
        const { isPlayingAudio } = this.state

        if (isPlayingAudio) {
            this.stopAudioPlayback()
        } else {
            this.startAudioPlayback()
        }
    }

    stopAudioPlayback = () => {
        this.setState({
            isPlayingAudio: false,
            isBuffering: false,
        }, () => {
            if (this.audioRef.current) {
                this.audioRef.current.pause()
            }
        })
    }

    startAudioPlayback = () => {
        if (this.audioRef.current) {
            this.setState({ isPlayingAudio: true }, () => {
                this.audioRef.current.play()
            })
        }
    }

    renderAudio = ({
        input,
        meta: {
            warning,
            error,
            touched,
        },
        isUploading,
        track,
        isShowingFullName,
        isPlayingAudio,
        isBuffering,
    }) => {
        const inputClassname = classnames(
            'c-track-form-audio-file-details',
            'c-input',
            {
                'c-track-form-audio-file-details-has-audio': !!input.value,
            }
        )

        return (
            <Field
                fieldName="audioFile"
                className="c-track-form-audio-file-field"
                label={t('containerTrackFormLabelTrackAudio')}
                popover={{
                    title: t('containerTrackFormAudioHelpTitle'),
                    text: t('containerTrackFormAudioHelpText'),
                }}
                warning={touched ? t(warning) : null}
                error={(touched && error && !isUploading) ? (
                    // todo There's a dom validation error that occurs because the surrounding msg-error element is a p
                    // and as such there shouldn't be descendent divs. This has been changed to a span but - because
                    // there is a markdown translation this isn't a robust fix. Ideally p.msg-error in field would
                    // be a div, but that's potentially an app wide change.
                    <span className="c-audio-file-field-error">
                        <span>
                            {`${t(error)} `}
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: tmarkdown('containerTrackUploadLinkMore') }} />
                    </span>
                ) : null}
            >
                {isUploading ? (
                    <div className="c-track-form-audio-file-uploading c-input">
                        <Spinner size="auto" />

                        <span className="c-track-form-audio-file-details-placeholder">
                            {t('containerTrackFormAudioUploading')}
                        </span>
                    </div>
                ) : (
                    <div className="input-group">
                        <div className={inputClassname} onClick={() => this.toggleAudioPlayback()}>
                            {input.value ? (
                                <div>
                                    <span className="c-track-form-audio-file-control">
                                        {isPlayingAudio
                                            ? (
                                                isBuffering ? <Spinner /> : <FontAwesomeIcon icon="faPauseCircle" />
                                            )
                                            : <FontAwesomeIcon icon="faPlayCircle" />}
                                    </span>

                                    <span
                                        className="c-track-form-audio-file-details-content"
                                        onMouseEnter={() => {
                                            this.handleOverName(true)
                                        }}
                                        onMouseLeave={() => {
                                            this.handleOverName(false)
                                        }}
                                    >
                                        <span
                                            className="c-track-form-audio-file-details-content-filename"
                                        >
                                            {`${input.value.originalFilename}`}
                                        </span>
                                        <span className="c-track-form-audio-file-details-content-duration">
                                            {formatAudioDuration(input.value.duration)}
                                        </span>
                                    </span>

                                </div>
                            ) : (
                                <span className="c-track-form-audio-file-details-placeholder">
                                    {t('containerTrackUploadFileMissingHeader')}
                                </span>
                            )}
                        </div>
                        <div className="input-group-append">
                            <Button
                                name="uploadAudio"
                                onClick={() => this.handleFileReplace(track)}
                            >
                                {input.value
                                    ? t('containerTrackUploadButton')
                                    : t('containerTrackFormButtonNoAudio')}
                            </Button>
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={this.fileInputRef}
                                accept=".wav,.flac"
                            />
                        </div>
                        {!isUploading && input.value
                            ? (
                                <div
                                    className="msg-helptext"
                                    dangerouslySetInnerHTML={{ __html: tmarkdown('componentTrackFormAudioHelpText') }}
                                />
                            ) : null}
                    </div>
                )}
                <div
                    className={classnames(
                        'c-popover', {
                            visible: isShowingFullName,
                        }
                    )}
                >
                    <div className="c-popover-body">
                        <p className="c-popover-text">
                            {input.value.originalFilename}
                        </p>
                    </div>
                </div>
            </Field>
        )
    }

    renderTitleField = ({
        input,
        meta: {
            warning,
            error,
            touched,
        },
        track,
    }) => (
        <Field
            fieldName="title"
            label={t('containerTrackFormLabelTrackTitle')}
            popover={{
                title: t('containerTrackFormTitleHelpTitle'),
                text: t('containerTrackFormTitleHelpText'),
            }}
            warning={warning && t(warning)}
            error={touched ? t(error) : null}
        >
            <TranslatableInput
                source="track.title"
                value={input.value}
                translations={track.translations}
                onSave={(translations) => {
                    this.onTitleTranslationSave(translations, track)
                    // Although the validation is triggered correctly on save, it doesn't seem to 'stick' to the form
                    // state, so call it again...
                    this.props.validateTrackTitleWithMetadata(track)
                }}
            >
                <input
                    {...input}
                    className="c-input"
                    type="text"
                    placeholder={t('selectorTracksUntitledTrack')}
                />
            </TranslatableInput>
        </Field>
    )

    renderAudioSection = ({
        input: {
            value,
            onChange,
        },
        meta: {
            touched,
            error,
        },
        audioLocaleMap,
        isFetchingAudioLocales,
    }) => (
        <div>
            <Field
                fieldName="hasLyrics"
                label={t('containerHasLyricsLabel')}
                popover={{
                    title: t('containerHasLyricsHelpTitle'),
                    text: t('containerHasLyricsHelpText'),
                }}
                error={touched ? t(error) : null}
            >
                <Toggle
                    value={value}
                    options={[
                        {
                            label: t('containerHasLyricsYes'),
                            value: true,
                            onClick: () => {
                                if (value !== true) {
                                    onChange(true)
                                }
                            },
                        },
                        {
                            label: t('containerHasLyricsNo'),
                            value: false,
                            onClick: () => {
                                if (value !== false) {
                                    onChange(false)
                                }
                            },
                        },
                    ]}
                />
            </Field>

            {value ? (
                <div>
                    <div className="trackFieldhasLyricsSection">
                        <ReduxField
                            name="audioLocale"
                            component={this.renderAudioLocale}
                            audioLocaleMap={audioLocaleMap}
                            isFetching={isFetchingAudioLocales}
                        />
                        <ReduxField
                            name="explicitLyrics"
                            component={this.renderExplicit}
                        />
                        <ReduxField
                            name="lyrics"
                            component={this.renderLyrics}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    )

    renderLyrics = ({
        input,
    }) => (
        <Field
            fieldName="lyrics"
            label={t('containerTrackFormLyricsTitle')}
            popover={{
                title: t('containerTrackFormLyricsHelpTitle'),
                text: t('containerTrackFormLyricsHelpText'),
            }}
        >
            <Textarea
                {...input}
            />
        </Field>
    )

    renderAudioLocale = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        audioLocaleMap,
        isFetching,
        meta: {
            warning,
            error,
            touched,
        },
    }) => {
        const languageOpts = Object.values(audioLocaleMap).map((language) => {
            const txLabelKey = `dspLanguage_${language.id}`
            const translatedLabel = t(txLabelKey)
            return ({
                value: language.id,
                label: (translatedLabel !== txLabelKey) ? translatedLabel : language.name,
            })
        })
        return (
            <Field
                fieldName="audioLocale"
                label={t('containerTrackFormAudioLocaleSelectLabel')}
                warning={touched ? t(warning) : null}
                error={touched ? t(error) : null}
                popover={{
                    title: t('containerTrackFormAudioLocaleSelectHelpTitle'),
                    text: t('containerTrackFormAudioLocaleSelectHelpText'),
                }}
                isFetching={isFetching}
            >
                <Select
                    disabled={isFetching}
                    importantOptionValues={importantLanguages}
                    disableSortImportant
                    options={languageOpts}
                    placeholder={t('containerTrackFormAudioLocaleSelectPlaceholder')}
                    searchable
                    sortOrder="ASC"
                    value={value.id || ''}
                    onChange={(localeIdId) => {
                        onChange(audioLocaleMap[localeIdId])
                        onBlur(audioLocaleMap[localeIdId])
                    }}
                />
            </Field>
        )
    }

    renderExplicit = ({
        input: {
            value,
            ...input
        },
        meta: {
            error,
            touched,
        },
    }) => (
        <Field
            fieldName="explicit"
            label={t('containerTrackFormLabelExplicit')}
            error={touched ? t(error) : null}
            popover={{
                title: t('containerTrackFormLabelExplicitHelpTitle'),
                text: t('containerTrackFormLabelExplicitHelpText'),
            }}
        >
            <Select
                options={[false, true].map(option => ({
                    value: option,
                    label: option
                        ? t('containerTrackFormLabelExplicitOptionYes')
                        : t('containerTrackFormLabelExplicitOptionNo'),
                }))}
                placeholder={t('containerTrackFormLabelExplicitPlaceholder')}
                value={value}
                onChange={(selectedValue) => {
                    input.onChange(selectedValue)
                    input.onBlur(selectedValue)
                }}
            />
        </Field>
    )

    renderVersionSelect = ({
        input,
        versions,
        isFetchingVersions,
        meta: {
            warning,
            error,
            touched,
        },
    }) => (
        <Field
            fieldName="version"
            label={t('containerTrackFormLabelVersionSelect')}
            warning={touched ? t(warning) : null}
            error={touched ? t(error) : null}
            popover={{
                title: t('containerTrackFormVersionHelpTitle'),
                text: t('containerTrackFormVersionHelpText'),
            }}
            isFetching={isFetchingVersions}
        >
            <Select
                disabled={isFetchingVersions}
                importantOptionValues={[1]}
                options={versions.map(version => ({
                    value: version.id,
                    label: version.name,
                }))}
                placeholder={t('containerVersionSelectPlaceholder')}
                sortOrder="ASC"
                value={input.value}
                onChange={(versionId) => {
                    input.onChange(versionId)
                    input.onBlur(versionId)
                }}
            />
        </Field>
    )

    renderArtistInput = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
        track,
    }) => (
        <ArtistLookup
            fieldName="featuredArtists"
            artists={value}
            label={t('containerTrackFormLabelArtistInput')}
            placeholder={t('containerTrackFormPlaceholderFeaturedArtist')}
            duplicateErrorMsg={term => tmarkdown('componentArtistLookupFeaturedDuplicateError', term)}
            helpTextMsg={t('componentArtistLookupFeaturedHelpText')}
            popover={{
                title: t('containerTrackFormFeaturedArtistHelpTitle'),
                text: t('containerTrackFormFeaturedArtistHelpText'),
            }}
            error={touched ? t(error) : null}
            onChange={(options) => {
                const artists = options.map(opt => ({
                    ...opt.value,
                    artistType: ARTIST_TYPE_FEATURED,
                }))
                onChange(artists)
                onBlur()
            }}
            source="track.artistLookup"
            onTranslationSave={(translations) => {
                this.onArtistTranslationSave(translations, track)
                this.props.validateArtistNamesWithMetadata(track)
            }}
            artistTranslations={track.artists}
        />
    )

    renderISRCField = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
        disabled,
        className,
        hasCustomIsrc,
    }) => (
        <div>
            <Field
                fieldName="isrc"
                label={t('containerTrackFormISRCLabel')}
                error={touched ? t(error) : null}
                popover={{
                    title: t('containerTrackFormISRCHelpTitle'),
                    text: t('containerTrackFormISRCHelpText'),
                }}
                className={className}
            >
                {disabled ? (
                    null
                ) : (
                    <RadioGroup
                        name="hasCustomIsrc"
                        checkedValue={!!(hasCustomIsrc || (touched && error))}
                        align="column"
                        className="has-custom-isrc-radio-group"
                        onChange={(selectedValue) => {
                            if (selectedValue === false) {
                                onChange('')
                                onBlur()
                            }
                            this.onHasCustomIsrcChange(selectedValue)
                        }}
                    >
                        <Radio value={false} label={t('containerTrackFormISRCNoText')} />
                        <Radio value label={t('containerTrackFormISRCYesText')} />
                    </RadioGroup>
                )}
                {hasCustomIsrc || disabled || (touched && error) ? (
                    <TextInput
                        placeholder={t('containerTrackFormISRCPlaceHolder')}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                ) : (
                    null
                )}
            </Field>
        </div>
    )

    renderCompositionType = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
        className,
    }) => (
        <div>
            <Field
                fieldName="composition"
                label={t('containerTrackFormCompositionTypeLabel')}
                error={touched ? t(error) : null}
                popover={{
                    title: t('containerTrackFormCompositionTypeHelpTitle'),
                    text: t('containerTrackFormCompositionTypeHelpText'),
                }}
                className={className}
            >
                <RadioGroup
                    name="compositionTypeId"
                    checkedValue={value}
                    align="column"
                    onChange={(selectedValue) => {
                        onChange(selectedValue)
                        onBlur(selectedValue)
                    }}
                >
                    <Radio value={COMPOSITION_TYPE_ORIGINAL} label={t('containerTrackFormCompositionTypeOriginal')} />
                    <Radio value={COMPOSITION_TYPE_COVER} label={t('containerTrackFormCompositionTypeCover')} />
                    <Radio value={COMPOSITION_TYPE_SAMPLE} label={t('containerTrackFormCompositionTypeSample')} />
                    <Radio value={COMPOSITION_TYPE_BEAT} label={t('containerTrackFormCompositionTypeBeat')} />
                </RadioGroup>

                {value === COMPOSITION_TYPE_ORIGINAL && (
                    <Alert type="warning">
                        <p><strong>{t('containerTrackFormCompositionTypeOriginalAlertTitle')}</strong></p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: tmarkdown('containerTrackFormCompositionTypeOriginalAlertText'),
                            }}
                        />
                    </Alert>
                )}

                {value === COMPOSITION_TYPE_COVER && (
                    <Alert type="warning">
                        <p><strong>{t('containerTrackFormCompositionTypeCoverAlertTitle')}</strong></p>
                        <div dangerouslySetInnerHTML={
                            { __html: tmarkdown('containerTrackFormCompositionTypeCoverAlertText') }
                        }
                        />

                        <ReduxField
                            name="compositionTermsAgreed"
                            component={this.renderCompositionTermsAgreed}
                            label={(
                                <span dangerouslySetInnerHTML={{
                                    __html: tmarkdown('componentTrackFormCompositionTypeCoverAgreeCheckbox'),
                                }}
                                />
                            )}
                        />
                    </Alert>
                )}

                {value === COMPOSITION_TYPE_SAMPLE && (
                    <Alert type="warning">
                        <p><strong>{t('containerTrackFormCompositionTypeSampleAlertTitle')}</strong></p>
                        <div dangerouslySetInnerHTML={{
                            __html: tmarkdown('containerTrackFormCompositionTypeSampleAlertText'),
                        }}
                        />

                        <ReduxField
                            name="compositionTermsAgreed"
                            component={this.renderCompositionTermsAgreed}
                            label={(
                                <span dangerouslySetInnerHTML={{
                                    __html: tmarkdown('componentTrackFormCompositionTypeSampleAgreeCheckbox'),
                                }}
                                />
                            )}
                        />
                    </Alert>
                )}

                {value === COMPOSITION_TYPE_BEAT && (
                    <Alert type="warning">
                        <p><strong>{t('containerTrackFormCompositionTypeBeatAlertTitle')}</strong></p>
                        <div dangerouslySetInnerHTML={{
                            __html: tmarkdown('containerTrackFormCompositionTypeBeatAlertText'),
                        }}
                        />

                        <ReduxField
                            name="compositionTermsAgreed"
                            component={this.renderCompositionTermsAgreed}
                            label={(
                                <span dangerouslySetInnerHTML={{
                                    __html: tmarkdown('componentTrackFormCompositionTypeBeatAgreeCheckbox'),
                                }}
                                />
                            )}
                        />
                    </Alert>
                )}
            </Field>
        </div>
    )

    renderCompositionTermsAgreed = ({
        input: {
            value,
            onChange,
            onBlur,
        },
        meta: {
            error,
            touched,
        },
        className,
        label,
    }) => (
        <Field
            fieldName="compositionTerms"
            error={touched ? t(error) : null}
            className={className}
        >
            <Checkbox
                checked={!!value}
                onChange={(selectedValue) => {
                    onChange(selectedValue)
                    onBlur(selectedValue)
                }}
                label={label}
            />
        </Field>
    )

    // This seems to prevent an error in which redux form doesn't see SongwriterPublisherField as a
    // valid functional component
    renderSongwriterField = props => (<SongwriterPublisherField {...props} />)

    render() {
        const {
            isActiveTrack,
            track,
            versions,
            isFetchingVersions,
            audioLocaleMap,
            isFetchingAudioLocales,
            showCopyPopup,
            showDeleteSongwriterPopup,
            numForms,
            copyPublisherToSiblings,
            publisherIsGlobal,
            updateCandidatePublisher,
            addTranslatedTitleToTrack,
        } = this.props

        const { audio } = track

        const {
            hasCustomIsrc,
            isShowingFullName,
            isPlayingAudio,
            isBuffering,
        } = this.state

        return (
            <form
                key={`track[${track.id}]`}
                className="c-track-form"
                onSubmit={(event) => {
                    event.preventDefault()
                }}
            >
                {(isActiveTrack && !track.isUploading && audio && audio.url)
                    ? (
                        <audio ref={this.audioRef}>
                            <source src={this.getAuthenticatedAudioUrl(audio.url)} type={audio.contentType} />
                        </audio>
                    )
                    : null}

                <ReduxField
                    name="audio"
                    component={this.renderAudio}
                    track={track}
                    isUploading={track.isUploading}
                    isShowingFullName={isShowingFullName}
                    isPlayingAudio={isPlayingAudio}
                    isBuffering={isBuffering}
                />
                <ReduxField
                    name="title"
                    component={this.renderTitleField}
                    addTranslatedTitleToTrack={addTranslatedTitleToTrack}
                    track={track}
                />
                <ReduxField
                    name="artists"
                    component={this.renderArtistInput}
                    track={track}
                />
                <ReduxField
                    name="recordingVersionId"
                    component={this.renderVersionSelect}
                    versions={versions}
                    isFetchingVersions={isFetchingVersions}
                />
                <ReduxField
                    name="hasLyrics"
                    component={this.renderAudioSection}
                    audioLocaleMap={audioLocaleMap}
                    isFetchingAudioLocales={isFetchingAudioLocales}
                />

                <ReduxField
                    name="isrc"
                    disabled={!track.canEditIsrc}
                    hasCustomIsrc={hasCustomIsrc}
                    component={this.renderISRCField}
                />

                <ReduxField
                    name="compositionTypeId"
                    component={this.renderCompositionType}
                />

                <ReduxField
                    name="publishers"
                    component={this.renderSongwriterField}
                    numForms={numForms}
                    copyPublisherToSiblings={copyPublisherToSiblings}
                    publisherIsGlobal={publisherIsGlobal}
                    updateCandidatePublisher={updateCandidatePublisher}
                    showCopyPopup={showCopyPopup}
                    showDeletePopup={showDeleteSongwriterPopup}
                    track={track}
                />
            </form>
        )
    }
}
