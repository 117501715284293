import React from 'react'
import * as PropTypes from 'prop-types'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Field from 'components/Field/Field'

export const ReduxFormTextField = ({
    input,
    meta: {
        warning,
        error,
        touched,
    },
    labelId,
    fieldClassName,
    disabled,
    isPassword,
    isNumeric,
    placeholder,
    autocomplete,
    helpText,
}) => {
    const { t } = useLocaleContext()
    // Numeric input without the browser spinner controls
    const additionalProps = isNumeric ? {
        inputMode: 'decimal',
        pattern: '[0-9\\.,]*',
    } : {}

    return (
        <Field
            label={t(labelId)}
            warning={touched ? t(warning) : null}
            error={touched ? t(error) : null}
            className={fieldClassName}
            helpText={helpText}
        >
            <input
                {...input}
                className="c-input"
                placeholder={placeholder}
                type={isPassword ? 'password' : 'text'}
                disabled={disabled}
                {...additionalProps}
                autoComplete={autocomplete}
            />
        </Field>
    )
}
ReduxFormTextField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    labelId: PropTypes.string,
    fieldClassName: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isPassword: PropTypes.bool,
    isNumeric: PropTypes.bool,
    autocomplete: PropTypes.string,
    helpText: PropTypes.string,
}
