import { useEffect } from 'react'
import { unauthAE } from 'actions/ServiceActions'
import script from 'utilities/script'

const useAppreciationEngine = (user, isAEReady) => {
    useEffect(() => {
        if (user) {
            script(window.env.AE_PRODUCTION_APP_URL)
            window.dataLayer.push({
                artist: {
                    country: user.country,
                    market: user.market,
                },
            })
        }
    }, [user])

    useEffect(() => {
        if (user && isAEReady) {
            const userID = user.id
            const services = ((window.aeJS && window.aeJS.user) && window.aeJS.user.services) || []
            const hasInvalidServices = services.find(s => Number(s.PartnerID) !== userID)

            if (hasInvalidServices) {
                unauthAE()
            }
        }
    }, [isAEReady])
}

export default useAppreciationEngine
