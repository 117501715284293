import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import EnterPhone from 'components/mfa/EnterPhone'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { SubmissionError } from 'redux-form'
import { enrolUserPhoneMfa as enrolUserPhoneMfaAction, types } from 'actions/MfaActions'
import { connect } from 'react-redux'
import { onPreAuthApiResponse } from 'actions/SignedUrlActions'
import './_mfa.scss'
import { Redirect, withRouter } from 'react-router-dom'
import { getCountriesPrefixesOptions } from 'selectors/countriesPrefixes'
import { phoneNumberAllowedSpecialCharactersRegex } from 'constants/regularExpressions'
import { stripChars } from 'utilities/string'

export const MfaEnrol = ({
    location,
    history,
    enrolUserPhoneMfa,
    countriesPrefixes,
}) => {
    const { t } = useLocaleContext()
    const {
        apiMFAEnrol,
        // userRegisteredCountry,
    } = (location.state || {})

    const [isAddingPhone, setIsAddingPhone] = useState(false)

    if (!apiMFAEnrol) {
        return (<Redirect to="/start" />)
    }

    const onEnterPhone = ({ phonePrefixCountryCode, phoneNumber }) => {
        setIsAddingPhone(true)

        const matchedCountry = countriesPrefixes.find(c => c.countryCode === phonePrefixCountryCode)

        if (!matchedCountry) {
            console.warn(`MfaEnrol - Invalid phone prefix country code ${phonePrefixCountryCode}`)
            throw new SubmissionError({
                phoneNumber: t('viewMfaValidationPhoneError'),
            })
        }

        const phoneNumberNoSpecialChars = stripChars(phoneNumber, phoneNumberAllowedSpecialCharactersRegex)
        const formattedPhoneNumber = `${phoneNumberNoSpecialChars}`

        return enrolUserPhoneMfa(apiMFAEnrol.url, formattedPhoneNumber).then((respAction) => {
            setIsAddingPhone(false)
            if (respAction.type === types.MFA_ENROL_FAILURE) {
                let submissionErrorObj

                if (respAction.error && respAction.error.errors) {
                    const allErrors = respAction.error.errors

                    // This is not strictly robust long-term as the API validation could encompass more validation
                    // rules in the future. The reason it is implemented explicitly now is to give control of the
                    // translations to the UI for consistency and not hide which key has been used.
                    if (allErrors.phoneNumber) {
                        submissionErrorObj = {
                            phoneNumber: t(allErrors.phoneNumber[0]),
                        }
                    }
                }

                // If no explicit error has been found/set then attach a generic one
                if (!submissionErrorObj) {
                    submissionErrorObj = {
                        phoneNumber: t('viewMfaValidationPhoneError'),
                    }
                }

                throw new SubmissionError(submissionErrorObj)
            } else {
                onPreAuthApiResponse(respAction, history.push, {
                    phoneNumber: formattedPhoneNumber,
                })
            }
        })
    }

    const componentTitle = (<h3>{t('viewMfaEnrolmentTitle')}</h3>)
    return (
        <EnterPhone
            // userRegisteredCountry={userRegisteredCountry}
            userRegisteredCountry={null}
            onSubmit={onEnterPhone}
            componentTitle={componentTitle}
            isLoading={isAddingPhone}
            displayCloseButton={false}
            closeButton={null}
        />
    )
}

MfaEnrol.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    enrolUserPhoneMfa: PropTypes.func.isRequired,
    countriesPrefixes: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    countriesPrefixes: getCountriesPrefixesOptions(state),
})

const mapDispatchToProps = {
    enrolUserPhoneMfa: enrolUserPhoneMfaAction,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MfaEnrol))
