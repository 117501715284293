import { MARKET_ID_DEFAULT } from 'constants/enums/MarketEnums'
import { SelectedLocale } from 'selectors/locales'
import { TranslationLanguage } from 'contexts/localisation/translateUtilities'
import { defaultUiConfig, LocaleUiConfig, MarketConfigFormLayout } from './localeUiConfig'

interface ILocaleConfig {
    locale: string
    country: string
    localeWithCountry: string
    availableLocales: SelectedLocale[]
    ui: LocaleUiConfig
}

class LocaleConfig implements ILocaleConfig {
    locale: TranslationLanguage = 'en'
    country: string = 'US'
    localeWithCountry: string = 'en_US'
    availableLocales: SelectedLocale[] = []
    ui: LocaleUiConfig = defaultUiConfig

    constructor(props: ILocaleConfig) {
        Object.assign(this as LocaleConfig, props)
    }

    getLanguage() {
        return this.locale
    }

    getCountry() {
        return this.country
    }

    getFullLanguage() {
        return this.localeWithCountry
    }

    getAvailableLocales() {
        return this.availableLocales
    }

    getFormOrdering(key: keyof MarketConfigFormLayout, marketId: number) {
        const marketObj = this.getMarketConfig(marketId)
        const configForKey = marketObj.formLayout[key]

        if (!configForKey) {
            throw new Error(`Cannot find locale form config for ${key} in market ${marketId || this.localeWithCountry}`)
        }

        return marketObj.formLayout[key]
    }

    getMarketConfig(marketId: number) {
        if (!marketId) {
            marketId = this.getCurrentLocaleMarketId()
        }

        if (this.ui.marketConfig[marketId]) {
            return this.ui.marketConfig[marketId]
        }
        return this.ui.marketConfig[MARKET_ID_DEFAULT]
    }

    getCurrentLocaleMarketId() {
        const allLocalesForCountry = this.getAvailableLocales().filter(l => l.country === this.country)
        let locale = allLocalesForCountry.find(l => l.language === this.locale)
        if (!locale) {
            locale = allLocalesForCountry.find(l => l.language === 'en')
        }

        return locale ? locale.marketId : MARKET_ID_DEFAULT
    }
}

function buildLocaleConfig(language: string, country: string, locales: SelectedLocale[]) {
    if (typeof language === 'undefined' || language === null || language.length !== 2) {
        throw new Error(`Invalid language specified for locale config: ${language}`)
    }

    if (typeof country === 'undefined' || country === null || country.length !== 2) {
        throw new Error(`Invalid country specified for locale config: ${country}`)
    }

    return new LocaleConfig({
        locale: language,
        country,
        localeWithCountry: `${language}_${country}`,
        availableLocales: locales,
        ui: defaultUiConfig,
    })
}

export { LocaleConfig, buildLocaleConfig }
