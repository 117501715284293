import { ApiLocale } from 'models/api/locale'
import { ApiLanguage } from './api/language'
import { ApiCountry } from './api/country'

export class Locale {
    id: number
    language: ApiLanguage
    country: ApiCountry

    constructor(locale: ApiLocale) {
        this.id = locale.id
        this.language = locale.language
        this.country = locale.country
    }
}
