import { connect } from 'react-redux'
import { removeNotification } from 'actions/ToastNotificationsActions'
import ToastNotifications from 'components/ToastNotifications/ToastNotifications'

const mapDispatchToProps = {
    removeNotification,
}

const mapStateToProps = ({ notifications }) => ({
    notifications,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToastNotifications)
