import React from 'react'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import './_mfa.scss'
// eslint-disable-next-line import/no-named-as-default
import MfaVerificationCodeViewBase from 'views/Mfa/MfaVerificationCodeViewBase'

const MfaVerify = () => {
    const { t } = useLocaleContext()
    const componentTitle = (<h3>{ t('viewMfaActivationTitle') }</h3>)
    return (
        <MfaVerificationCodeViewBase
            componentTitle={componentTitle}
        />
    )
}

MfaVerify.propTypes = {}

export default MfaVerify
