import React, { Component } from 'react'
import Input from './Input'

export default class SearchInput extends Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
    }

    getValue() {
        return this.inputRef.current.getValue()
    }

    setValue(value) {
        this.inputRef.current.setValue(value)
    }

    render() {
        return <Input ref={this.inputRef} {...this.props} type="search" />
    }
}
