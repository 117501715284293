import React, { useMemo } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import Pills from 'containers/Navs/Pills'
import useScrollToTopEffect from 'hooks/useScrollToTopEffect'

import WithdrawForm from 'containers/WithdrawForm/WithdrawForm'
import WithdrawalHistory from 'containers/Balance/WithdrawalHistory'
import SalesReportOverview from 'containers/SalesReportOverview/SalesReportOverview'
import SalesReportDetailed from 'containers/SalesReportDetailed/SalesReportDetailed'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { LocaleState } from 'contexts/localisation/localeState'

const getBalanceNavigation = (t: LocaleState['t']) => [
    {
        href: '/balance/withdraw',
        label: t('viewBalanceNavWithdraw'),
    },
    {
        href: '/balance/reports/overview',
        label: t('viewBalanceNavReports'),
        active: (window.location.pathname.indexOf('/balance/reports') === 0),
    },
    {
        href: '/balance/history',
        label: t('viewBalanceNavHistory'),
    },
]

const Balance = () => {
    useScrollToTopEffect()

    const url = '/balance'
    const { t } = useLocaleContext()
    const balanceNavigation = useMemo(() => getBalanceNavigation(t), [window.location.pathname])

    return (
        <div className="v-balance">

            <header>
                <h1>{t('viewEarningsHeading')}</h1>
            </header>

            <Pills items={balanceNavigation} />

            <Switch>
                <Redirect exact from={`${url}/reports`} to={`${url}/reports/overview`} />
                <Redirect exact from={`${url}`} to={`${url}/withdraw`} />

                <Route path={`${url}/withdraw`} render={() => <WithdrawForm />} />
                <Route path={`${url}/history`} render={() => <WithdrawalHistory />} />
                <Route path={`${url}/reports/overview`} render={() => <SalesReportOverview />} />
                <Route path={`${url}/reports/detailed/:year?/:month?`} render={() => <SalesReportDetailed />} />
            </Switch>
        </div>
    )
}

export default Balance
