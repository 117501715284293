import React from 'react'
import * as PropTypes from 'prop-types'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import Button from 'components/Button/Button'
import './_deliveredReleaseSettings.scss'

const DeliveredReleaseSettings = ({ onTakedown }) => {
    const { t } = useLocaleContext()

    return (
        <div className="c-delivered-release-settings">
            <h4>{ t('viewReleaseSettingsHeading') }</h4>
            <TranslateMarkdown id="viewReleaseSettingsCopy" className="c-delivered-takedown-body" />
            <div className="c-delivered-release-actions">
                <Button onClick={onTakedown}>{t('viewReleaseSettingsRemoveReleaseButton')}</Button>
            </div>
        </div>
    )
}

DeliveredReleaseSettings.propTypes = {
    onTakedown: PropTypes.func.isRequired,
}

export default DeliveredReleaseSettings
