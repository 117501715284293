import { useEffect } from 'react'

/**
 * For use in Class based components use in ComponentDidMount()
 *
 * Currently the element with id "root" is the scroll 'root' of the page, so attempt to get that element else fallback
 */
export const scrollUpToTop = () => (document.getElementById('root') || window).scrollTo(0, 0)

/**
 * Will force components to scroll to top on Mounting
 * @warning - this effects the whole window - not just the component viewport
 */
const useScrollToTopEffect = () => {
    useEffect(scrollUpToTop, [])
}

export default useScrollToTopEffect
