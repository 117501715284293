// 8 currently does not exist todo use typescript enums as conversion progresses
export const MARKET_ID_DEFAULT: number = -1
export const MARKET_ID_INTERNATIONAL: number = 1
export const MARKET_ID_GERMAN_SPK: number = 2
export const MARKET_ID_UK: number = 3
export const MARKET_ID_NORWAY: number = 4
export const MARKET_ID_DENMARK: number = 5
export const MARKET_ID_SWEDEN: number = 6
export const MARKET_ID_USA_CANADA: number = 7
export const MARKET_ID_FRANCE: number = 9
export const MARKET_ID_SPANISH_SPK: number = 10
export const MARKET_ID_AUSTRALASIA: number = 11
export const MARKET_ID_SEA: number = 12
export const MARKET_ID_JAPAN: number = 13

export const MARKET_NAME_DEFAULT: string = 'DEFAULT'
export const MARKET_NAME_INTERNATIONAL: string = 'INTERNATIONAL'
export const MARKET_NAME_GERMAN_SPK: string = 'GERMAN_SPK'
export const MARKET_NAME_UK: string = 'UK'
export const MARKET_NAME_NORWAY: string = 'NORWAY'
export const MARKET_NAME_DENMARK: string = 'DENMARK'
export const MARKET_NAME_SWEDEN: string = 'SWEDEN'
export const MARKET_NAME_USA_CANADA: string = 'USA_CANADA'
export const MARKET_NAME_FRANCE: string = 'FRANCE'
export const MARKET_NAME_SPANISH_SPK: string = 'SPANISH_SPK'
export const MARKET_NAME_AUSTRALASIA: string = 'AUSTRALASIA'
export const MARKET_NAME_SEA: string = 'SEA'
export const MARKET_NAME_JAPAN: string = 'JAPAN'

const MARKET_MAP: Record<number, string> = {
    [MARKET_ID_DEFAULT]: MARKET_NAME_DEFAULT,
    [MARKET_ID_INTERNATIONAL]: MARKET_NAME_INTERNATIONAL,
    [MARKET_ID_GERMAN_SPK]: MARKET_NAME_GERMAN_SPK,
    [MARKET_ID_UK]: MARKET_NAME_UK,
    [MARKET_ID_NORWAY]: MARKET_NAME_NORWAY,
    [MARKET_ID_DENMARK]: MARKET_NAME_DENMARK,
    [MARKET_ID_SWEDEN]: MARKET_NAME_SWEDEN,
    [MARKET_ID_USA_CANADA]: MARKET_NAME_USA_CANADA,
    [MARKET_ID_FRANCE]: MARKET_NAME_FRANCE,
    [MARKET_ID_SPANISH_SPK]: MARKET_NAME_SPANISH_SPK,
    [MARKET_ID_AUSTRALASIA]: MARKET_NAME_AUSTRALASIA,
    [MARKET_ID_SEA]: MARKET_NAME_SEA,
    [MARKET_ID_JAPAN]: MARKET_NAME_JAPAN,
}

// todo this is necessary for rendering address details where user market id is not tied to the address country given
// todo this is not nice but the countries request does not have a reverse lookup for it
export const getMarketIdFromCountryCode = (countryCode: string) => {
    if (!countryCode) {
        return MARKET_ID_DEFAULT
    }

    switch (countryCode.toLowerCase()) {
        case 'jp':
            return MARKET_ID_JAPAN
        case 'ca':
        case 'us':
            return MARKET_ID_USA_CANADA
        case 'gb':
            return MARKET_ID_UK
        default:
            return MARKET_ID_INTERNATIONAL
    }
}

export default MARKET_MAP
