import { Record } from 'immutable'

const emptyUser = {
    id: -1,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    emailVerified: false,
    verificationStartedAt: undefined,
    isAdministrator: false,
    localeId: -1,
    gaClientId: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    deletedAt: undefined,
    loginAt: undefined,
    blockedAt: undefined,
    activatedAt: undefined,
    enableAutomaticWithdrawals: false,
    loginCount: -1,
    locale: undefined,
    roles: [],
    hasPendingSubscription: false,
    hasActiveSubscription: false,
    hasSubscriptionForReleasing: false,
    hasPaidForUserSubscription: false,

    // below are transformed from response format payload properties, either of same name or locale. See users reducer
    languageId: undefined,
    countryId: undefined,
    artist: undefined,
    flags: [],
    providers: [],
    urls: {},
    minimumPayment: undefined,
    minimumPaymentFormatted: undefined,
    country: undefined,
    marketId: undefined,
    market: undefined,
}

export default class User extends new Record(emptyUser) {
    constructor(user) {
        super({
            ...user,
        })
    }
}
