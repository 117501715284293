import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateUser } from 'actions/UserActions'
import { UPDATE_USER_SUCCESS } from 'constants/UserActionTypes'
import { userSelector } from 'selectors/users'
import Field from 'components/Field/Field'
import TextInput from 'components/Inputs/TextInput'
import Spinner from 'components/Spinner/Spinner'
import Button from 'components/Button/Button'
import Saved from 'components/Saved/Saved'
import { withLocalisation } from 'contexts/localisation/localeProvider'
import { onPreAuthApiResponse } from 'actions/SignedUrlActions'
import { withRouter } from 'react-router-dom'

class EmailSettingsContainer extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        updateUser: PropTypes.func.isRequired,
        locale: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props)
        this.currentEmailRef = React.createRef()
        this.emailRef = React.createRef()
        this.emailConfirmationRef = React.createRef()
        this.state = { isDone: false }
    }

    onSave = () => {
        this.setState({ isDone: false })

        this.props.updateUser({
            email: this.emailRef.current.getValue(),
            emailConfirmation: this.emailConfirmationRef.current.getValue(),
        }).then((action) => {
            if (action.type === UPDATE_USER_SUCCESS) {
                try {
                    // This will trigger the 'signed url flow' for user email verification (see Start.tsx)
                    onPreAuthApiResponse(action, this.props.history.push, { userEmail: action.payload.email })
                } catch (e) {
                    console.warn('No navigable view in email change response')
                }
            }
        })
    }

    render() {
        const {
            user,
            isLoading,
            errors,
            locale,
        } = this.props
        const { isDone } = this.state
        const { t } = locale

        return (
            <div className="c-email-settings">
                <header>
                    <h1>{t('containerEmailSettingsPanelTitle')}</h1>
                    <div className="c-email-settings-status">
                        {isLoading
                            && (
                                <div className="c-email-settings-saving">
                                    <Spinner size="small" horizontal>{t('globalSaving')}</Spinner>
                                </div>
                            )}
                        {isDone && !isLoading && <Saved>{t('globalSaved')}</Saved>}
                    </div>
                </header>
                <div className="c-email-settings-fields">
                    <Field
                        label={t('containerEmailSettingsLabelCurrentEmail')}
                    >
                        <TextInput
                            disabled
                            ref={this.currentEmailRef}
                            value={user.email}
                        />
                    </Field>

                    <Field
                        label={t('containerEmailSettingsLabelNewEmail')}
                        error={t(errors.email)}
                    >
                        <TextInput
                            ref={this.emailRef}
                        />
                    </Field>

                    <Field
                        label={t('containerEmailSettingsLabelRepeatEmail')}
                        error={t(errors.emailConfirmation)}
                    >
                        <TextInput
                            ref={this.emailConfirmationRef}
                        />
                    </Field>
                </div>
                <Button onClick={this.onSave}>{t('containerEmailSettingsButtonChange')}</Button>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    user: userSelector(state, props),
    isLoading: state.users.isUpdatingUser,
    errors: state.users.errors,
})

const mapDispatchToProps = {
    updateUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLocalisation(EmailSettingsContainer)))
