import React from 'react'
import EditProfileInfoPanel from 'components/EditProfileInfoPanel/EditProfileInfoPanel'
import EditContainer from 'containers/EditProfile/Edit'
import t from 'utilities/translate'
import EditProfileImageCrop from 'containers/EditProfileImageCrop/EditProfileImageCrop'

const ProfileEdit = () => (
    <div className="v-profile-edit">
        <h3 className="v-profile-edit-header">{t('viewProfileHeader')}</h3>
        <EditProfileInfoPanel />
        <EditContainer />
        <EditProfileImageCrop />
    </div>
)

export default ProfileEdit
