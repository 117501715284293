import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import InvisibleRecaptcha from 'components/ReCaptcha/InvisibleRecaptcha'
import t, { tmarkdown } from 'utilities/translate'
import param from 'utilities/param'
import Alert from 'components/Alert/Alert'
import Button from 'components/Button/Button'
import Field from 'components/Field/Field'
import Input from 'components/Inputs/Input'
import Countdown from 'components/Countdown/Countdown'
import ClipoutText from 'components/Frame/ClipoutText'
import { onPreAuthApiResponse } from 'actions/SignedUrlActions'
import { AUTH_FAILURE } from 'constants/AuthActionTypes'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'

const BACKOFF_SEVERITY_NOT_BLOCKED = 0
const BACKOFF_SEVERITY_LOW = 1
const BACKOFF_SEVERITY_MED = 2
const BACKOFF_SEVERITY_HIGH = 3

const PASSWORD_MIN_LENGTH = 7 // User validation has this at 10+, but some test accounts have 7+

class LoginForm extends Component {
    static propTypes = {
        isAuthenticating: PropTypes.bool.isRequired,
        isBlocked: PropTypes.bool.isRequired,
        auth: PropTypes.func.isRequired,
        unblock: PropTypes.func.isRequired,
        error: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.object,
        ]),
        history: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)

        this.recaptchaRef = React.createRef()

        this.state = {
            username: '',
            password: '',
        }

        this.unblockTimerHandle = null
    }

    handleBackoff = () => {
        const { error, unblock } = this.props

        const isBackoffError = this.isBackoffError()

        if (isBackoffError) {
            clearTimeout(this.unblockTimerHandle)
            this.unblockTimerHandle = setTimeout(unblock, error.ttl * 1000)
        }
    }

    isBackoffError = () => {
        const { isBlocked, error } = this.props

        return (
            isBlocked
            && typeof error === 'object'
            && Object.prototype.hasOwnProperty.call(error, 'status')
            && error.status === 'backoff'
            && error.ttl > 0
        )
    }

    getBackoffSeverity = () => {
        const { isBlocked, error } = this.props
        const isBackoffError = this.isBackoffError()

        let severity = BACKOFF_SEVERITY_NOT_BLOCKED
        if (isBackoffError) {
            if (isBlocked) {
                severity = BACKOFF_SEVERITY_LOW
            }
            if (error.attempts > 3 && error.attempts < 7) {
                severity = BACKOFF_SEVERITY_MED
            }
            if (error.attempts >= 7) {
                severity = BACKOFF_SEVERITY_HIGH
            }
        }

        return severity
    }

    onLogin = (e) => {
        e.preventDefault()
        this.recaptchaRef.current.execute() // will call this.verifyCapture(response)
    }

    inputChangeHandler = (fld, e) => {
        e.preventDefault()
        this.setState({ [fld]: e.target.value })
    }

    doLogin = () => {
        const { auth, history: { push } } = this.props
        const { username, password } = this.state
        if (this.recaptchaRef.current.isReady) {
            return auth(username, password, this.recaptchaRef.current.lastResponse)
                .then((authResp) => {
                    if (authResp.type !== AUTH_FAILURE) {
                        onPreAuthApiResponse(authResp, push, { userEmail: username })
                    } else {
                        throw new Error(authResp.error)
                    }
                })
                .catch(() => {
                    if (this.recaptchaRef.current) {
                        this.recaptchaRef.current.reset()
                    }
                    this.handleBackoff()
                })
        }
        return false
    }

    render() {
        const {
            isAuthenticating,
            error,
        } = this.props

        const isPasswordReset = param('passwordReset')
        const backoffSeverity = this.getBackoffSeverity()

        const {
            username,
            password,
        } = this.state

        const hasError = !!error
        let errorComp = null
        if (hasError) {
            const errorMessage = error.httpStatusCode === 403 ? error.message : 'componentLoginInvalidCredentials'
            errorComp = (<TranslateMarkdown id={errorMessage} useSpan />)
        }

        const missingUserOrPassword = !username || username === ''
            || !password || password.length < PASSWORD_MIN_LENGTH

        return (
            <form
                onSubmit={this.onLogin}
                className="c-login-form"
                noValidate
            >
                <h3 className="c-book-side-title-large">
                    {t('containerLoginFormTitle')}
                </h3>
                <ClipoutText
                    type="h3"
                    backgroundImageId="dune-2"
                    className="c-book-side-title-small"
                >
                    {t('containerLoginFormTitle')}
                </ClipoutText>

                {isPasswordReset
                    && (
                        <Alert type="success" small>
                            {t('containerLoginFormPasswordResetSuccess')}
                        </Alert>
                    )}

                {(backoffSeverity === BACKOFF_SEVERITY_HIGH)
                && (
                    <Alert type="error" small>
                        <div dangerouslySetInnerHTML={{
                            __html: tmarkdown('containerLoginFormCannotLoginAlertMsg', '/start/forgot'),
                        }}
                        />
                    </Alert>
                )}

                <Field
                    label={t('containerLoginFormLabelEmail')}
                    error={hasError && errorComp}
                >
                    <Input
                        type="email"
                        value={username}
                        onChange={e => this.inputChangeHandler('username', e)}
                        error={hasError}
                    />
                </Field>

                <Field
                    label={t('containerLoginFormPassword')}
                    error={hasError && errorComp}
                >
                    <Input
                        type="password"
                        value={password}
                        onChange={e => this.inputChangeHandler('password', e)}
                        error={hasError}
                    />
                </Field>

                <InvisibleRecaptcha
                    ref={this.recaptchaRef}
                    onVerify={this.doLogin}
                />

                <Button
                    className="c-book-button c-login-form-submit"
                    disabled={missingUserOrPassword || isAuthenticating || !!backoffSeverity}
                    isSubmit
                    isLoading={isAuthenticating}
                >
                    {t('containerLoginFormButtonLogIn')}
                </Button>

                {backoffSeverity === BACKOFF_SEVERITY_MED
                && (
                    <Countdown
                        className="c-login-form-countdown"
                        start={error.ttl}
                        tickspeed={1000}
                        template="containerLoginFormCannotLoginCountdownText"
                    />
                )}

                <Link
                    className="c-login-form-forgot-password"
                    to={{
                        pathname: '/start/forgot',
                        state: { username },
                    }}
                >
                    {t('containerLoginFormForgot')}
                </Link>
            </form>
        )
    }
}

export default withRouter(LoginForm)
