import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import EditProfileDropzone from 'components/Dropzone/EditProfileDropzone'
import { heroMaxSize } from 'constants/FileSizes'
import { imageTypesExtended } from 'constants/FileTypes'
import { getPopupDataEditProfileRemoveHero } from 'utilities/popups'
import t from 'utilities/translate'
import { isCroppingSupported } from 'constants/browserCompatybility'
import { Label } from '../EditProfileInputs/Inputs'

const heroImageInitialCropState = {
    aspect: 16 / 9,
}

const heroRequirements = {
    minWidth: 500,
}

const HeroUpload = ({
    isProcessing,
    isProcessingForm,
    error,
    hero,
    heroPlacement,
    removeHeroImage,
    uploadHeroImage,
    uploadHeroValidationError,
    openPopup,
    addImagesToCrop,
}) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    useEffect(() => {
        setIsImageLoaded(false)
    }, [isProcessing])

    const validateFile = (file) => {
        const maxFileSize = heroMaxSize
        const { jpg, jpeg, png } = imageTypesExtended
        const acceptImgMimes = [jpg, jpeg, png]
        if (file.size > maxFileSize) {
            uploadHeroValidationError('uploadEditProfileHeroFileMaxSize')
            return false
        }
        if (acceptImgMimes.indexOf(file.type) < 0) {
            uploadHeroValidationError('uploadEditProfileHeroFileType')
            return false
        }
        return true
    }

    const prepareFilesToCrop = (files) => {
        const [file] = files
        if (validateFile(file)) {
            if (isCroppingSupported) {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(file)
                fileReader.onloadend = event => (
                    addImagesToCrop([{
                        src: event.target.result,
                        callback: uploadHeroImage,
                        initialCropState: heroImageInitialCropState,
                        requirements: heroRequirements,
                        validateSize: true,
                        sizeErrorMessage: 'uploadEditProfileHeroImageMinSize',
                    }])
                )
            } else {
                uploadHeroImage(file)
            }
        }
    }

    const handleRemove = (groupId) => {
        const onAccept = () => {
            removeHeroImage(groupId)
            setIsImageLoaded(false)
        }
        openPopup(getPopupDataEditProfileRemoveHero(onAccept))
    }

    const isHeroValid = hero && hero.url
    const isLoading = isProcessing || (isHeroValid && !isImageLoaded)
    const imageGroupId = (isHeroValid && hero.groupId) || ''
    const hasError = !!error

    return (
        <div className="c-epu hero">
            <Label label={t('uploadEditProfileHeroLabel')} />
            <div className="c-epu-dropzone-wrapper hero">
                <EditProfileDropzone
                    uploadImage={prepareFilesToCrop}
                    removeImage={handleRemove}
                    isProcessing={isProcessing}
                    isLoaded={isImageLoaded}
                    isLoading={isLoading}
                    hasError={hasError}
                    hideUploadButton
                    disableDropzone={isProcessingForm}
                    imageGroupId={imageGroupId}
                    background={
                        (isHeroValid && !isProcessing) ? (
                            <div className={classnames({ 'c-epu-cover-loading': !isImageLoaded })}>
                                <img className="c-epu-cover-extend" src={hero.url} alt="" />
                                <img
                                    onLoad={() => { setIsImageLoaded(true) }}
                                    className={`c-epu-cover${heroPlacement === 'centered' ? '-centered' : ''}`}
                                    src={hero.url}
                                    alt=""
                                />
                            </div>
                        ) : null
                    }
                />
            </div>
            {hasError ? <p className="c-epu-errors">{t(error)}</p> : null}
            <p className="c-epu-description">{t('uploadEditProfileHeroDescription')}</p>
        </div>
    )
}

HeroUpload.propTypes = {
    hero: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
        groupId: PropTypes.string,
    }),
    heroPlacement: PropTypes.string,
    isProcessing: PropTypes.bool.isRequired,
    isProcessingForm: PropTypes.bool.isRequired,
    error: PropTypes.string,
    uploadHeroImage: PropTypes.func.isRequired,
    uploadHeroValidationError: PropTypes.func.isRequired,
    removeHeroImage: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    addImagesToCrop: PropTypes.func,
}

export default HeroUpload
