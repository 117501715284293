import PropTypes from 'prop-types'

const countryPrefixPropType = ({
    value: PropTypes.string,
    label: PropTypes.string,
    countryName: PropTypes.string,
    countryCode: PropTypes.string,
    flag: PropTypes.string,
})

export default countryPrefixPropType
