import * as types from 'constants/DspLookupActionTypes'
import { CALL_API } from 'middleware/api'

function getLookup(type, dsp, term, market) {
    return {
        [CALL_API]: {
            types: [
                types.DSP_LOOKUP_REQUEST,
                types.DSP_LOOKUP_SUCCESS,
                types.DSP_LOOKUP_FAILURE,
            ],
            payload: {
                endpoint: 'dsp/lookup',
                method: 'post',
                body: {
                    type,
                    dsp,
                    term,
                    market,
                },
            },
        },
    }
}

export function doLookup(type, dsp, term, market) {
    return dispatch => new Promise((resolve) => {
        if (term) {
            dispatch(getLookup(type, dsp, term, market))
                .then((payload) => {
                    resolve(payload)
                })
        } else {
            resolve({
                payload: {
                    blacklisted: false,
                    results: [],
                },
            })
        }
    })
}

export function doInitialEditProfileLookup(term) {
    return {
        [CALL_API]: {
            types: [
                types.EDIT_PROFILE_INITIAL_DSP_LOOKUP_REQUEST,
                types.EDIT_PROFILE_INITIAL_DSP_LOOKUP_SUCCESS,
                types.EDIT_PROFILE_INITIAL_DSP_LOOKUP_FAILURE,
            ],
            payload: {
                endpoint: 'dsp/lookup',
                method: 'post',
                body: {
                    type: 'artist',
                    dsp: 'spotify',
                    term,
                },
            },
        },
    }
}
