import { connect } from 'react-redux'
import { uploadGallery, uploadGalleryValidationError } from 'actions/ProfileActions'
import { addPopup } from 'actions/PopupActions'
import GalleryUpload from 'components/EditProfileUpload/GalleryUpload'
import { addImagesToCrop } from 'actions/EditImages'

const mapStateToProps = state => ({
    galleryLength: state.draftProfile.data.files.gallery.length,
    isUploading: !!state.draftProfile.uploadGalleryCounter,
    isProcessingForm: state.draftProfile.isSubmitting || state.draftProfile.isPublishing,
    error: state.draftProfile.galleryError,
})

const mapDispatchToProps = dispatch => ({
    uploadGalleryImage: file => dispatch(uploadGallery(file)),
    uploadGalleryValidationError: error => dispatch(uploadGalleryValidationError(error)),
    openPopup: popup => dispatch(addPopup(popup)),
    addImagesToCrop: imagesData => dispatch(addImagesToCrop(imagesData)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GalleryUpload)
