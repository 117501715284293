import Raven from 'raven-js'
import * as types from 'constants/ServiceActionTypes'

import { putUser } from 'actions/UserActions'
import { URL_SETTINGS, URL_SETTINGS_TITLE } from 'constants/AppUrls'
import { sendEventToDataLayer } from 'utilities/analytics'
import { CALL_API } from 'middleware/api'

// FETCH

export function getServices(id, bustCache) {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_SERVICES_REQUEST,
                types.FETCH_SERVICES_SUCCESS,
                types.FETCH_SERVICES_FAILURE,
            ],
            payload: {
                endpoint: `artists/${id}/social/connected`,
                query: bustCache ? { bustCache: Date.now() } : {},
            },
        },
    }
}

export function fetchServices(bustCache = true) {
    return (dispatch, getState) => dispatch(getServices(getState().users.user.artist.id, bustCache))
}

export function setDefaultSocialPage(pages, service) {
    return (dispatch, getState) => {
        const { user } = getState().users

        if (pages.length > 0) {
            const { providers } = user
            if (!providers || typeof providers[service] === 'undefined') {
                // No Facebook/Instagram provider is currently set
                const defaultPage = pages[0]

                defaultPage.getUrl().then((url) => {
                    dispatch(putUser(user.id, {
                        [service]: {
                            id: defaultPage.value,
                            data: {
                                url,
                            },
                        },
                    }))
                })
            }
        }
    }
}

// LOGGER

function logAESettings() {
    Raven.setExtraContext({ aeJS: window.aeJS })
    Raven.captureException(`AE Settings object. Partner ID: ${window.aeJS.settings.partner_id}`)
}

// CONNECT

export function connectService(service, user) {
    return () => {
        const eventObj = {
            pagePath: URL_SETTINGS,
            pageTitle: URL_SETTINGS_TITLE,
            pageUrl: `${window.location.origin}${URL_SETTINGS}`,
            platform: service,
        }
        if (user) {
            eventObj.userId = user.id
            eventObj.country = user.countryId
            eventObj.market = user.market
        }

        sendEventToDataLayer(
            'socialLinkStart',
            eventObj
        )
        logAESettings()

        if (service === 'instagram') {
            window.aeJS.trigger.authenticate(
                'facebook',
                'register',
                window.location.href,
                'instagram_basic,pages_show_list'
            )
        } else if (service === 'facebook') {
            window.aeJS.trigger.authenticate(
                service,
                'register',
                window.location.href,
                'pages_show_list,pages_read_engagement'
            )
        } else {
            window.aeJS.trigger.authenticate(service, 'auth')
        }
    }
}

// DISCONNECT

export function disconnectService(service) {
    return () => {
        logAESettings()
        service = service === 'instagram' ? 'facebook' : service
        window.aeJS.trigger.remove(service, `${window.location.origin}${URL_SETTINGS}`)
    }
}

export function connectAndDisconnectService(service) {
    const onConnect = () => {
        window.aeJS.events.onLogin.removeHandler(onConnect)
        window.aeJS.trigger.remove(service)
    }

    return () => {
        logAESettings()
        window.aeJS.events.onLogin.addHandler(onConnect)
        window.aeJS.trigger.authenticate(service, 'auth')
    }
}

// LOGOUT

export function unauthAE() {
    return () => window.aeJS.trigger.logout()
}
