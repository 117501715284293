import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { hideModal } from 'actions/ModalActions'
import './_modalContainer.scss'

export const ModalContainerComponent = ({ currentModal, onCloseModal }) => {
    const isDisplayingModal = !!currentModal
    const containerBaseClass = 'c-modal-container'
    const layoutWrapperBaseClass = 'c-modal-layout-wrapper'

    const containerClasses = classnames(containerBaseClass, {
        show: isDisplayingModal,
        'with-backdrop': isDisplayingModal && currentModal.useBackdrop,
    })

    let ModalComponent = null
    let onBackgroundClick = () => {}
    let onClose = () => {}

    if (isDisplayingModal) {
        ModalComponent = currentModal.modalComponent
        onClose = () => {
            const providedOnClose = currentModal.modalProps && currentModal.modalProps.onClose

            if (typeof providedOnClose === 'function') {
                providedOnClose()
            }
            onCloseModal(currentModal)
        }
        onBackgroundClick = currentModal.clickBackdropToClose ? (evt) => {
            if (evt.target && (evt.target.classList.contains(containerBaseClass)
                || evt.target.classList.contains(layoutWrapperBaseClass))) {
                onClose()
            }
        } : onBackgroundClick
    }

    return (
        <div className={containerClasses} onClick={onBackgroundClick}>
            <div className={layoutWrapperBaseClass}>
                { isDisplayingModal ? <ModalComponent {...currentModal.modalProps} onCloseModal={onClose} /> : null }
            </div>
        </div>
    )
}

ModalContainerComponent.propTypes = {
    currentModal: PropTypes.shape({
        modalId: PropTypes.string.isRequired,
        modalComponent: PropTypes.elementType.isRequired,
        modalProps: PropTypes.object.isRequired,
        useBackdrop: PropTypes.bool,
        clickBackdropToClose: PropTypes.bool,
    }),
    onCloseModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    currentModal: state.modals.currentModal,
})

const mapDispatchToProps = dispatch => ({
    onCloseModal: currentModal => dispatch(hideModal(currentModal.modalId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainerComponent)
