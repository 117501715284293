import { ADD_NOTIFICATION } from 'constants/NotificationsActionTypes'

export function addNotification(message, error) {
    return {
        type: ADD_NOTIFICATION,
        payload: {
            message,
            error,
        },
    }
}
