import { createSelector } from 'reselect'
import { AppStoreState } from 'store/store-types'
import { Post } from 'models/post'

export const postsSelector = createSelector(
    (state: AppStoreState) => state.posts.posts.map(p => new Post(p)),
    posts => posts
)

export const getActiveWidget = createSelector(
    postsSelector,
    posts => (posts.length > 0 ? posts[0] : null)
)
