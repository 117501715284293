import React from 'react'
import * as PropTypes from 'prop-types'

import t from 'utilities/translate'
import Footer from 'components/Release/Footer'
import Navigation from 'components/Release/Navigation'
import General from 'components/Release/General'
import Settings from 'components/Release/Settings'
import Promote from 'components/Release/Promote'
import SocialDelivery from 'components/Release/SocialDelivery'
import ArtworkContainer from 'containers/Artwork/Artwork'
import DeliveredReleaseSettings from 'components/Release/DeliveredReleaseSettings'

const Delivered = (props) => {
    const {
        tab,
        release,
        user,
        onClickPill,
        onSocialDeliveryRelease,
        onTakedown,
        onDownloadFiles,
    } = props

    const tabContent = () => {
        switch (tab) {
            case 'settings':
                return (
                    <Settings
                        release={release}
                        onTakedown={onTakedown}
                    />
                )
            case 'releaseSettings':
                return (
                    <DeliveredReleaseSettings
                        onTakedown={onTakedown}
                    />
                )
            case 'promote':
                const linkfireUrl = release.linkfire ? release.linkfire.url : null
                return (
                    <Promote
                        linkfireUrl={linkfireUrl}
                        user={user}
                        releaseDateReached={release.linkfire.releaseDateReached}
                    />
                )
            case 'social-delivery':
                return (
                    <SocialDelivery
                        onSocialDeliveryRelease={onSocialDeliveryRelease}
                        release={release}
                    />
                )
            case 'general':
            default:
                return (
                    <div className="c-release-body">
                        <ArtworkContainer album={release.album} className="c-release-cover-art" />
                        <div className="c-release-completed">
                            <General release={release} onDownloadFiles={onDownloadFiles} />
                        </div>
                    </div>
                )
        }
    }

    const renderFooter = () => {
        switch (tab) {
            case 'social-delivery':
                return null
            default:
                return (<Footer icon="faCheck" message={t('selectorAlbumsStepFour')} />)
        }
    }

    return (
        <>
            <Navigation tab={tab} onClickPill={onClickPill} />
            { tabContent(tab) }
            { renderFooter() }
        </>
    )
}

Delivered.propTypes = {
    release: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    tab: PropTypes.string,
    onClickPill: PropTypes.func.isRequired,
    onSocialDeliveryRelease: PropTypes.func.isRequired,
    onTakedown: PropTypes.func.isRequired,
    onDownloadFiles: PropTypes.func.isRequired,
}

export default Delivered
