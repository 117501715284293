import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Spinner from 'components/Spinner/Spinner'
import ArtistNames from 'components/ArtistNames/ArtistNames'

export default class Track extends Component {
    static propTypes = {
        accessToken: PropTypes.string.isRequired,

        /** Track object */
        track: PropTypes.shape({
            title: PropTypes.string,
            explicitLyrics: PropTypes.bool,
            audio: PropTypes.object,
            artists: PropTypes.array,
            formattedTitleNoArtists: PropTypes.string,
        }),

        /** The track title */
        title: PropTypes.string,

        /** Length of the track */
        isrc: PropTypes.string,

        /** Explicit lyrics */
        tag: PropTypes.string,

        position: PropTypes.number,
        withIsrc: PropTypes.bool,
        withTrackPosition: PropTypes.bool,
        withAudioPlayer: PropTypes.bool,
        withArtistSpotifyLink: PropTypes.bool,
    };

    static defaultProps = {
        withArtistSpotifyLink: false,
    };

    constructor(props) {
        super(props)

        this.audioRef = React.createRef()

        this.state = {
            isPlayingAudio: false,
            isBuffering: false,
            hasAddedAudioListeners: false,
        }
    }

    componentDidUpdate() {
        if (!this.state.hasAddedAudioListeners && this.audioRef.current) {
            this.setState({ hasAddedAudioListeners: true }, () => {
                this.audioRef.current.addEventListener('playing', () => this.setState({ isBuffering: false }))
                this.audioRef.current.addEventListener('waiting', () => this.setState({ isBuffering: true }))
            })
        }
    }

    getAuthenticatedAudioUrl = url => `${url}&access_token=${this.props.accessToken}`

    onAudioPlayUpdate = () => {
        const progress = this.audioRef.current.currentTime / this.audioRef.current.duration

        if (progress >= 1) {
            this.stopAudioPlayback()
        }
    }

    toggleAudioPlayback = () => {
        const { isPlayingAudio } = this.state

        if (isPlayingAudio) {
            this.stopAudioPlayback()
        } else {
            this.startAudioPlayback()
        }
    }

    stopAudioPlayback = () => {
        this.setState({
            isPlayingAudio: false,
            isBuffering: false,
        }, () => (this.audioRef.current && this.audioRef.current.pause()))
    }

    startAudioPlayback = () => {
        if (this.audioRef.current) {
            this.setState({ isPlayingAudio: true }, () => {
                this.audioRef.current.play()
            })
        }
    }

    render() {
        const {
            track,
            title,
            isrc,
            tag,
            position,
            withIsrc,
            withTrackPosition,
            withAudioPlayer,
            withArtistSpotifyLink,
        } = this.props

        const { isPlayingAudio, isBuffering } = this.state
        const isPlayingIcon = isBuffering
            ? <Spinner className="c-track-list-audio-spinner" />
            : <FontAwesomeIcon icon="faPauseCircle" />
        return (
            <tr>
                {withAudioPlayer ? (
                    <td onClick={this.toggleAudioPlayback} className="c-track-list-audio-control">
                        <audio ref={this.audioRef}>
                            <source
                                src={this.getAuthenticatedAudioUrl(track.audio.url)}
                                type={track.audio.contentType}
                            />
                        </audio>

                        {isPlayingAudio
                            ? (
                                isPlayingIcon
                            )
                            : <FontAwesomeIcon icon="faPlayCircle" />}
                    </td>
                ) : null}

                {withTrackPosition && <td>{position}</td>}
                <td>
                    { (withArtistSpotifyLink
                        && (
                            <span>
                                {track.formattedTitleNoArtists}
                                { track.artists.length > 0
                                && (
                                    <span>
                                        [feat. <ArtistNames artists={track.artists} />]
                                    </span>
                                )}
                            </span>
                        ))
                    || title}

                    {tag && (
                        <span className="c-track-tag">{tag}</span>
                    )}
                </td>

                {withIsrc && (
                    <td>{isrc || '-'}</td>
                )}
            </tr>
        )
    }
}
