import * as PropTypes from 'prop-types'
import React from 'react'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Field from 'components/Field/Field'
import Select from 'components/Select/Select'

export const ReduxFormSelectField = ({
    input,
    meta: {
        error,
        touched,
    },
    allValues,
    isFetchingValues,
    labelId,
    emptyLabelId,
    fieldClassName,
    disabled,
    disableSearch,
    placeholder,
    disableSort,
}) => {
    const { t } = useLocaleContext()
    return (
        <Field
            label={t(labelId)}
            error={touched ? t(error) : null}
            isFetching={isFetchingValues}
            className={fieldClassName}
        >
            <Select
                {...input}
                options={allValues}
                disabled={!allValues || allValues.length < 1 || isFetchingValues || disabled}
                searchable={!disableSearch}
                emptyValueLabel={emptyLabelId ? t(emptyLabelId) : undefined}
                placeholder={placeholder}
                disableSort={disableSort}
            />
        </Field>
    )
}

ReduxFormSelectField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    isFetchingValues: PropTypes.bool,
    allValues: PropTypes.array.isRequired,
    labelId: PropTypes.string.isRequired,
    emptyLabelId: PropTypes.string,
    fieldClassName: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    disableSearch: PropTypes.bool,
    disableSort: PropTypes.bool,
}
