// FETCH ALL

export const FETCH_ALBUMS_REQUEST = 'FETCH_ALBUMS_REQUEST'
export const FETCH_ALBUMS_SUCCESS = 'FETCH_ALBUMS_SUCCESS'
export const FETCH_ALBUMS_FAILURE = 'FETCH_ALBUMS_FAILURE'

// FETCH ONE

export const FETCH_ALBUM_REQUEST = 'FETCH_ALBUM_REQUEST'
export const FETCH_ALBUM_SUCCESS = 'FETCH_ALBUM_SUCCESS'
export const FETCH_ALBUM_FAILURE = 'FETCH_ALBUM_FAILURE'

export const FETCH_ALBUM_ARTWORK_REQUEST = 'FETCH_ALBUM_ARTWORK_REQUEST'
export const FETCH_ALBUM_ARTWORK_SUCCESS = 'FETCH_ALBUM_ARTWORK_SUCCESS'
export const FETCH_ALBUM_ARTWORK_FAILURE = 'FETCH_ALBUM_ARTWORK_FAILURE'

export const DOWNLOAD_ALBUM_ARTWORK_REQUEST = 'DOWNLOAD_ALBUM_ARTWORK_REQUEST'
export const DOWNLOAD_ALBUM_ARTWORK_SUCCESS = 'DOWNLOAD_ALBUM_ARTWORK_SUCCESS'
export const DOWNLOAD_ALBUM_ARTWORK_FAILURE = 'DOWNLOAD_ALBUM_ARTWORK_FAILURE'

// CREATE

export const CREATE_ALBUM_REQUEST = 'CREATE_ALBUM_REQUEST'
export const CREATE_ALBUM_SUCCESS = 'CREATE_ALBUM_SUCCESS'
export const CREATE_ALBUM_FAILURE = 'CREATE_ALBUM_FAILURE'

// UPDATE

export const UPDATE_ALBUM = 'UPDATE_ALBUM'
export const UPDATE_ALBUM_REQUEST = 'UPDATE_ALBUM_REQUEST'
export const UPDATE_ALBUM_SUCCESS = 'UPDATE_ALBUM_SUCCESS'
export const UPDATE_ALBUM_FAILURE = 'UPDATE_ALBUM_FAILURE'

// REMOVE

export const REMOVE_ALBUM_REQUEST = 'REMOVE_ALBUM_REQUEST'
export const REMOVE_ALBUM_SUCCESS = 'REMOVE_ALBUM_SUCCESS'
export const REMOVE_ALBUM_FAILURE = 'REMOVE_ALBUM_FAILURE'

// UPLOAD

export const UPLOAD_COVER_REQUEST = 'UPLOAD_COVER_REQUEST'
export const UPLOAD_COVER_SUCCESS = 'UPLOAD_COVER_SUCCESS'
export const UPLOAD_COVER_FAILURE = 'UPLOAD_COVER_FAILURE'
export const UPLOAD_COVER_PROGRESS = 'UPLOAD_COVER_PROGRESS'
export const UPLOAD_COVER_UPDATED = 'UPLOAD_COVER_UPDATED'
export const UPLOAD_COVER_ABORT = 'UPLOAD_COVER_ABORT'
export const REMOVE_COVER = 'REMOVE_COVER'
export const CONFIRM_COVER = 'CONFIRM_COVER'
export const UNCONFIRM_COVER = 'UNCONFIRM_COVER'

// VALIDATE

export const VALIDATE_SETTINGS_SUCCESS = 'VALIDATE_SETTINGS_SUCCESS'
export const VALIDATE_SETTINGS_FAILURE = 'VALIDATE_SETTINGS_FAILURE'
export const VALIDATE_PACKAGE_SUCCESS = 'VALIDATE_PACKAGE_SUCCESS'
export const VALIDATE_PACKAGE_FAILURE = 'VALIDATE_PACKAGE_FAILURE'
