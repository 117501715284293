// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { of } from 'rxjs'
import { combineEpics, ofType } from 'redux-observable'
import {
    mergeMap,
    takeUntil,
    catchError,
} from 'rxjs/operators'
import { extend } from 'lodash'

import { fetchAudioLocales } from 'services/spinnup-api/audio-locale'

const APP_PREFIX = 'spinnup'
export const KEY = 'audioLocales'

// ///////////
// DETERMINISTIC ACTIONS
// ///////////

export const REQUEST_FETCH = `${APP_PREFIX}/${KEY}/REQUEST_FETCH`
export const RECEIVE_FETCH_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_FETCH_SUCCESS`
export const RECEIVE_FETCH_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_FETCH_FAILURE`

// ///////////
// ACTION CREATORS
// ///////////

export const requestFetch = () => ({
    type: REQUEST_FETCH,
})

export const receiveFetch = map => ({
    type: RECEIVE_FETCH_SUCCESS,
    payload: {
        map,
    },
})

export const receiveFetchFailure = error => ({
    type: RECEIVE_FETCH_FAILURE,
    payload: {
        error,
    },
})

// ///////////
// REDUCERS
// ///////////

const initialState = {
    map: [],
    isFetching: false,
}

export default (
    state = initialState,
    {
        type,
        payload,
    }
) => {
    const nextState = extend(
        {},
        state
    )

    switch (type) {
        case REQUEST_FETCH:
            extend(nextState, {
                isFetching: true,
            })
            break
        case RECEIVE_FETCH_SUCCESS:
            extend(nextState, {
                isFetching: false,
                map: payload.map,
            })
            break
        case RECEIVE_FETCH_FAILURE:
            extend(nextState, {
                isFetching: false,
            })
            break
        default:
            break
    }

    return nextState
}

// ///////////
// SELECTORS
// ///////////

export const getAudioLocaleMap = state => state[KEY].map
export const isFetchingAudioLocales = state => state[KEY].isFetching

// ///////////
// NON DETERMINISTIC ACTIONS
// ///////////

// ///////////
// ACTION CREATORS
// ///////////

// ///////////
// EPICS
// ///////////
export const requestFecthEpic = (action$, store, { ajax }) => action$.pipe(
    ofType(REQUEST_FETCH),
    mergeMap(() => {
        const state = store.value
        const { credentials } = state.auth

        return fetchAudioLocales(
            {},
            credentials,
            ajax
        ).pipe(
            takeUntil(action$.pipe(ofType(REQUEST_FETCH))),
            mergeMap(({ response }) => of(receiveFetch(response))),
            catchError(({ response }) => of(receiveFetchFailure(response)))
        )
    })
)

export const epic = combineEpics(
    requestFecthEpic
)
