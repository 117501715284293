// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { of, EMPTY } from 'rxjs'
import {
    takeUntil,
    mergeMap,
    filter,
    catchError,
} from 'rxjs/operators'
import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import {
    get, set, isNil, merge, omitBy, isNull, isEmpty,
} from 'lodash'
import {
    actionTypes as formActions,
    change,
    getFormValues,
    startSubmit,
    stopSubmit,
    touch,
} from 'redux-form'

import {
    getErrors as getAlbumErrors,
    getWarnings as getAlbumWarnings,
} from 'validations/album'

import * as trackActions from 'constants/TrackActionTypes'

import { makeAlbumSelector } from 'selectors/albums'
import { makeAlbumTracksSelector } from 'selectors/tracks'

import { putAlbum, patchAlbum } from 'services/spinnup-api/album'

import {
    albumFormRegex,
    getAlbumSettingFormName,
} from 'modules/common/album'

import {
    getAlbumReleaseFormName,
    requestSave as requestSaveRelease,
    syncValidate as syncValidateRelease,
} from 'modules/release'

import t from 'utilities/translate'
import { isPlaceholderTitle } from 'utilities/release'
import {
    RECEIVE_SAVE_SUCCESS as RECEIVE_TRACK_SAVE_SUCCESS,
} from './tracks'
import {
    updateAsyncErrors,
    updateErrors,
    updateWarnings,
} from './forms'

const APP_PREFIX = 'spinnup'
export const KEY = 'album'

// ///////////
// DETERMINISTIC ACTIONS
// ///////////

// already declare in constants files, not duckerise YET !!! (๑˃̵ᴗ˂̵)و

// ///////////
// ACTION CREATORS
// ///////////

// already declare in actionCreator file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و

// ///////////
// REDUCERS
// ///////////

// already declare in reducer file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و
export default combineReducers({})

// ///////////
// SELECTORS
// ///////////

export const getAlbumFormValues = state => Object
    .keys(state.form)
    .filter(formId => albumFormRegex.exec(formId))
    .reduce(
        (acc, formId) => {
            const form = state.form[formId]
            const formValues = form.values || {}
            const album = formValues.album || {}
            const release = formValues.release || {}
            return merge(
                acc,
                {
                    album: omitBy(album, isNull),
                    release: omitBy(release, isNull),
                }
            )
        },
        {}
    )

// ///////////
// NON DETERMINISTIC ACTIONS
// ///////////
export const REQUEST_SAVE = `${APP_PREFIX}/${KEY}/REQUEST_SAVE`
export const RECEIVE_SAVE_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_SUCCESS`
export const RECEIVE_SAVE_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_FAILURE`
export const RECEIVE_PATCH_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_SUCCESS`
export const RECEIVE_PATCH_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_FAILURE`
export const SYNC_VALIDATE = `${APP_PREFIX}/${KEY}/SYNC_VALIDATE`
export const CHECK_TITLE = `${APP_PREFIX}/${KEY}/CHECK_TITLE`

// ///////////
// ACTION CREATORS
// ///////////

export const requestSave = (album, field) => ({
    type: REQUEST_SAVE,
    payload: album,
    meta: {
        field,
    },
})

export const receiveSave = updatedAlbum => ({
    type: RECEIVE_SAVE_SUCCESS,
    payload: updatedAlbum,
})

export const receiveSaveFailure = (error, { id }, field) => ({
    type: RECEIVE_SAVE_FAILURE,
    payload: error,
    meta: {
        id,
        field,
    },
})

export const receivePatch = updatedAlbum => ({
    type: RECEIVE_PATCH_SUCCESS,
    payload: updatedAlbum,
})

export const receivePatchFailure = ({ message }, { id }, field) => ({
    type: RECEIVE_PATCH_FAILURE,
    payload: message,
    meta: {
        field,
        id,
    },
})

export const syncValidate = (formId, field) => ({
    type: SYNC_VALIDATE,
    payload: formId,
    meta: {
        field,
    },
})

export const checkTitle = albumId => ({
    type: CHECK_TITLE,
    payload: albumId,
})

// ///////////
// EPICS
// ///////////
export const requestSaveToStartSubmitEpic = action$ => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(() => [
        startSubmit(getAlbumSettingFormName()),
        startSubmit(getAlbumReleaseFormName()),
    ])
)

export const receiveSaveToStopSumitEpic = action$ => action$.pipe(
    ofType(RECEIVE_SAVE_SUCCESS, RECEIVE_SAVE_FAILURE),
    mergeMap(() => [
        stopSubmit(getAlbumSettingFormName()),
        stopSubmit(getAlbumReleaseFormName()),
    ])
)

const bySameField = (field, action) => field === action.meta.field
export const requestSaveToPutEpic = (action$, store, { ajax }) => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(({
        payload: album,
        meta: { field },
    }) => {
        const state = store.value
        const { credentials } = state.auth

        let action = EMPTY

        // TODO maybe split that in 2 actions
        // REQUEST_PUT_ALBUM
        // REQUEST_PATCH_ALBUM
        if (field === 'album.upc') {
            action = patchAlbum(
                {
                    album,
                    field: field.replace('album.', ''),
                },
                credentials,
                ajax
            ).pipe(
                takeUntil(
                    action$.pipe(
                        ofType(REQUEST_SAVE),
                        filter(bySameField.bind(null, field))
                    )
                    // when we will rely on redux-form we will have a form per album
                    // so a a sameById will be needed, see tracks module
                ),
                mergeMap(({ response }) => of(receivePatch(response))),
                catchError(({ response }) => of(receivePatchFailure(response, album, field)))
            )
        } else {
            action = putAlbum(
                album,
                credentials,
                ajax
            ).pipe(
                takeUntil(action$.pipe(ofType(REQUEST_SAVE))),
                mergeMap(({ response }) => of(receiveSave(response))),
                catchError(({ response }) => of(receiveSaveFailure(response, album, field)))
            )
        }
        return action
    })
)

export const receivePatchFailureEpic = action$ => action$.pipe(
    ofType(RECEIVE_PATCH_FAILURE),
    mergeMap(({ payload: error, meta: { field } }) => {
        // updateAsyncErrors is not an exposed action from redux-form
        // it's usage is sligthly different than updateSyncErrors
        // we need to provide a nested object instead of a path for the error
        const asynErrors = {}
        set(asynErrors, field, t(error))
        return of(updateAsyncErrors(getAlbumSettingFormName(), asynErrors))
    })
)

// Double action - to dispatch the save op and MANUALLY update the redux-form for release-settings
const getSetReleaseTitleActions = (album, newTitle, newTranslations) => [
    requestSave(
        {
            ...album,
            title: newTitle,
            translations: newTranslations,
        },
        'album.title'
    ),
    change(
        getAlbumSettingFormName(),
        'album.title',
        newTitle
    ),
    change(
        getAlbumSettingFormName(),
        'album.translations',
        newTranslations
    ),
]

const translationsDoNotMatch = (albumTranslations, trackTranslations) => {
    if (albumTranslations.length !== trackTranslations.length) {
        return true
    }

    return trackTranslations.map((tt) => {
        const matchingAlbumTranslation = albumTranslations.find(at => at.language === tt.language)
        return matchingAlbumTranslation && matchingAlbumTranslation.value === tt.value
    }).findIndex(v => !v) !== -1
}

export const checkTitleEpic = (action$, store) => action$.pipe(
    ofType(CHECK_TITLE),
    mergeMap(({ payload: albumId }) => {
        let action = EMPTY
        const state = store.value
        const album = makeAlbumSelector(albumId)(state)

        if (!album) {
            return action
        }

        const orderedTracks = makeAlbumTracksSelector(albumId)(state)
        const firstTrack = orderedTracks[0] || {}
        const firstTrackTitle = isPlaceholderTitle(firstTrack.title)
            ? t('selectorUntitledAlbumTitle')
            : firstTrack.title
        const isSingle = orderedTracks.length < 2

        if (isSingle) {
            const isOneOf = (v, targets) => targets.indexOf(v) !== -1
            const mismatchingTranslations = translationsDoNotMatch(
                album.translations || [],
                firstTrack.translations || []
            )
            const value = getFormValues(getAlbumSettingFormName())(state)
            const formTitle = value && value.album && value.album.title

            // If the first track title doesn't match the title in the album (reducer) OR
            // the first track title doesn't match the album (form), force an update
            if ((firstTrackTitle && (album.title !== firstTrackTitle || mismatchingTranslations))
                || (formTitle && formTitle !== firstTrackTitle)) {
                // ALWAYS re-set to first track title if present
                // or any existing value out of release title
                const hasValidFirstTrackTitle = !isPlaceholderTitle(firstTrackTitle)
                    && !isOneOf(firstTrackTitle, ['', null])
                const newTrackTitle = hasValidFirstTrackTitle ? firstTrackTitle : ''
                action = getSetReleaseTitleActions(album, newTrackTitle, firstTrack.translations)
            } else if (isOneOf(album.title, ['', null, t('selectorUntitledAlbumTitle')])) {
                action = getSetReleaseTitleActions(album, '', [])
            }
        }

        return action
    })
)

export const receiveSaveFailureToChangeField = action$ => action$.pipe(
    ofType(RECEIVE_SAVE_FAILURE),
    mergeMap(({ meta: { field } }) => {
        if (field === 'artists') {
            return [
                change(
                    getAlbumSettingFormName(),
                    field,
                    []
                ),
                change(
                    getAlbumReleaseFormName(),
                    field,
                    []
                ),
            ]
        }

        return EMPTY
    })
)

export const changeTrackToUpdateTitleEpic = action$ => action$.pipe(
    ofType(trackActions.CREATE_TRACK_SUCCESS, RECEIVE_TRACK_SAVE_SUCCESS,
        trackActions.REMOVE_TRACK_SUCCESS, SYNC_VALIDATE),
    mergeMap(({ meta: { albumId } }) => of(checkTitle(albumId)))
)

export const albumSyncValidateEpic = (action$, store) => action$.pipe(
    ofType(SYNC_VALIDATE),
    mergeMap(({ payload: formName, meta: { field } }) => {
        const state = store.value
        const value = getFormValues(formName)(state)

        const actions = []
        if (value) {
            actions.push(updateWarnings(formName, getAlbumWarnings(value, { formName, state })))
            actions.push(updateErrors(formName, getAlbumErrors(value, { formName, state })))
        }

        if (!field) {
            const fields = Object.keys(get(state, `form.${formName}.registeredFields`, {}))
            fields.forEach((currentField) => {
                actions.push(touch(formName, currentField))
            })
        }

        return actions
    })
)

export const changeEpic = action$ => action$.pipe(
    ofType(formActions.CHANGE),
    filter(({ meta: { form: formId } }) => albumFormRegex.exec(formId)),
    mergeMap(({ meta: { form: formId, field } }) => [
        syncValidate(formId, field),
        syncValidateRelease(formId, field), // CHECK if that is an issue, cause the formId is not checked
    ])
)

export const blurEpic = (action$, store) => action$.pipe(
    ofType(formActions.BLUR),
    filter(({ meta: { form: formId } }) => albumFormRegex.exec(formId)),
    mergeMap(({ meta: { field } }) => {
        const state = store.value

        let observable = EMPTY

        const {
            album: formAlbum,
            release: formRelease,
        } = getAlbumFormValues(state)
        if (isEmpty(formAlbum)) {
            observable = EMPTY
        } else if (
            !isNil(get({ album: formAlbum }, field))
            || field === 'album.translations'
            || field === 'album.artistTranslations'
        ) {
            const reducerAlbum = state.albums.albums[formAlbum.id]
            const album = {
                ...reducerAlbum,
                ...omitBy(formAlbum, isNull),
            }

            observable = of(requestSave(album, field))
        } else {
            const release = {
                ...state.releases.releases[`${formRelease.id}`],
                ...omitBy(formRelease, isNull),
            }
            observable = of(requestSaveRelease(release, field))
        }

        return observable
    })
)

export const epic = combineEpics(
    requestSaveToStartSubmitEpic,
    requestSaveToPutEpic,
    receiveSaveToStopSumitEpic,
    checkTitleEpic,
    changeTrackToUpdateTitleEpic,
    receiveSaveFailureToChangeField,
    receivePatchFailureEpic,
    albumSyncValidateEpic,
    changeEpic,
    blurEpic
)
