import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import Slider from 'react-slick'
import EditProfileInfoWidget from 'components/EditProfileInfoPanel/EditProfileInfoWidget'
import t from 'utilities/translate'

const infoWidgets = [
    {
        imageId: 'water-2',
        textKey: 'componentEditProfileInfoPanelFirst',
    },
    {
        imageId: 'dune-5',
        textKey: 'componentEditProfileInfoPanelSecond',
    },
    {
        imageId: 'dune-3',
        textKey: 'componentEditProfileInfoPanelThird',
    },
]

const renderInfoWidget = (widgetData, index) => (
    <EditProfileInfoWidget key={index} imageId={widgetData.imageId}>
        {t(widgetData.textKey)}
    </EditProfileInfoWidget>
)

const EditProfileInfoPanel = () => {
    const [isCarouselNeeded, setIsCarouselNeeded] = useState(true)

    const updateDimensions = () => {
        setIsCarouselNeeded(!window.matchMedia('(min-width: 992px)').matches)
    }

    useEffect(() => {
        updateDimensions()
        window.addEventListener('resize', updateDimensions)
    })

    useEffect(() => () => {
        window.removeEventListener('resize', updateDimensions)
    }, [])

    return (
        <Slider
            arrows={false}
            centerMode
            centerPadding="200px"
            className={classnames(
                'c-edit-profile-info-panel', ({
                    'slick-slider-disabled': !isCarouselNeeded,
                })
            )}
            dots
            infinite={false}
            initialSlide={1}
            slidesToScroll={1}
            slidesToShow={1}
            speed={500}
            unslick={!isCarouselNeeded}
            variableWidth
            focusOnSelect
        >
            {infoWidgets.map(renderInfoWidget)}
        </Slider>
    )
}

export default EditProfileInfoPanel
