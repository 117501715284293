// FILTER

export const TOGGLE_FILTER = 'TOGGLE_FILTER'

// VISIBILITY

export const TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY'

// METRICS

export const FETCH_METRICS_REQUEST = 'FETCH_METRICS_REQUEST'
export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS'
export const FETCH_METRICS_FAILURE = 'FETCH_METRICS_FAILURE'
