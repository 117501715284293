import { CLIENT_CALL_API } from 'middleware/api'
import { timeSince } from 'utilities/date'

const oneDayTimeBuffer = 1000 * 60 * 60 * 24

export const types = {
    FETCH_COUNTRIES_PREFIXES_REQUEST: 'FETCH_COUNTRIES_PREFIXES_REQUEST',
    FETCH_COUNTRIES_PREFIXES_SUCCESS: 'FETCH_COUNTRIES_PREFIXES_SUCCESS',
    FETCH_COUNTRIES_PREFIXES_FAILURE: 'FETCH_COUNTRIES_PREFIXES_FAILURE',
}

function getCountriesPrefixes() {
    return {
        [CLIENT_CALL_API]: {
            types: [
                types.FETCH_COUNTRIES_PREFIXES_REQUEST,
                types.FETCH_COUNTRIES_PREFIXES_SUCCESS,
                types.FETCH_COUNTRIES_PREFIXES_FAILURE,
            ],
            payload: {
                endpoint: 'countries?part=with_phone',
            },
        },
    }
}

export function fetchCountriesPrefixes() {
    return (dispatch, getState) => (
        timeSince(getState().countriesPrefixes.fetchedAt) >= oneDayTimeBuffer
    ) && dispatch(getCountriesPrefixes())
}
