import React from 'react'
import { Field as ReduxField } from 'redux-form'
import { FontSelectField, LanguageSelectField } from 'components/EditProfileInputs/Inputs'
import t from 'utilities/translate'

const EditProfileText = () => (
    <div className="c-epf-inputs">
        <div className="c-epf-inputs-wrapper">
            <ReduxField
                label={t('formEditProfileFontLabel')}
                name="fontId"
                placeholder={t('formEditProfileFontPlaceholder')}
                component={FontSelectField}
            />
            <ReduxField
                required
                name="languageId"
                label={t('formEditProfileLanguageLabel')}
                placeholder={t('formEditProfileLanguagePlaceholder')}
                showNativeLanguages
                component={LanguageSelectField}
            />
        </div>
    </div>
)

export default EditProfileText
