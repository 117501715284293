import React from 'react'
import PropTypes from 'prop-types'
import { domainRegex } from 'constants/regularExpressions'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Button from 'components/Button/Button'
import { formatDateLocalised } from 'utilities/date'

const getDomainFromUrl = url => (domainRegex.exec(url) && domainRegex.exec(url)[3])

const EditProfileTourDatesListElement = ({
    venue, city, country: { name: countryName }, date, ticketsUrl, id, toursIdsInDeletingState, removeTour,
}) => {
    const formatedDate = formatDateLocalised(date)
    const location = `${venue}, ${city}, ${countryName} - `
    const ticketsDomain = getDomainFromUrl(ticketsUrl)
    const isLoading = toursIdsInDeletingState.includes(id)
    return (

        <li className="c-epf-tour-dates-list-element">
            <div className="c-epf-tour-dates-list-location">
                <span>
                    <span className="c-epf-tour-dates-list-location-name">
                        {location}
                    </span>
                    <span className="c-epf-tour-dates-list-date">{formatedDate}</span>
                </span>
                <span className="c-epf-tour-dates-list-domain-name">{ticketsDomain}</span>
            </div>
            <div>
                <Button
                    isLoading={isLoading}
                    onClick={() => { removeTour(id) }}
                >
                    <FontAwesomeIcon icon="faTrash" />
                </Button>
            </div>
        </li>
    )
}

EditProfileTourDatesListElement.propTypes = {
    date: PropTypes.string,
    venue: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.shape({
        name: PropTypes.string,
    }),
    ticketsUrl: PropTypes.string,
    id: PropTypes.number,
    toursIdsInDeletingState: PropTypes.arrayOf(PropTypes.number),
    removeTour: PropTypes.func,
}

export default EditProfileTourDatesListElement
