import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { makeStepsSelector } from 'selectors/steps'
import { artistCanCreateRelease } from 'selectors/users'
import Release from 'components/Release/Release'

class ReleaseContainer extends Component {
    static propTypes = {
        release: PropTypes.object.isRequired,
        user: PropTypes.object,
        steps: PropTypes.array.isRequired,
        artistCanCreateRelease: PropTypes.bool.isRequired,
        onTakedown: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onSocialDeliveryRelease: PropTypes.func.isRequired,
        onYoutubeToggleRelease: PropTypes.func.isRequired,
        onDownloadFiles: PropTypes.func.isRequired,
    }

    onTakedown = () => {
        const { id, album: { id: albumId, title } } = this.props.release
        this.props.onTakedown(id, albumId, title)
    }

    onEdit = () => {
        const { id } = this.props.release
        this.props.onEdit(id)
    }

    onDelete = () => {
        const {
            id,
            expiresAt,
            album: { title, status },
        } = this.props.release

        this.props.onDelete(id, title, expiresAt, status)
    }

    onSocialDeliveryRelease = () => {
        const { id } = this.props.release
        this.props.onSocialDeliveryRelease(id, moment.utc().format('YYYY-MM-DD HH:mm:ss'))
    }

    onYoutubeToggleRelease = () => {
        const { id } = this.props.release
        this.props.onYoutubeToggleRelease(id, moment.utc().format('YYYY-MM-DD HH:mm:ss'))
    }

    onDownloadFiles = () => {
        this.props.onDownloadFiles(this.props.release)
    }

    render() {
        const {
            release,
            user,
            steps,
        } = this.props

        return (
            <Release
                release={release}
                user={user}
                steps={steps.map(step => ({ ...step, error: !step.completed }))}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                onTakedown={this.onTakedown}
                onSocialDeliveryRelease={this.onSocialDeliveryRelease}
                artistCanCreateRelease={this.props.artistCanCreateRelease}
                onDownloadFiles={this.onDownloadFiles}
            />
        )
    }
}

const mapStateToProps = (firstState, firstProps) => {
    const stepsSelector = makeStepsSelector(
        firstProps.release.id,
        firstProps.release.providerId,
        false
    )

    return (state, props) => ({
        steps: stepsSelector(state, props),
        artistCanCreateRelease: artistCanCreateRelease(state),
        user: state.users.user,
    })
}

export default connect(mapStateToProps)(ReleaseContainer)
