import { connect } from 'react-redux'
import { auth, unblock } from 'actions/AuthActions'
import LoginForm from 'components/Login/LoginForm'

const mapStateToProps = state => ({
    ...state.auth,
})

const mapDispatchToProps = {
    auth,
    unblock,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
