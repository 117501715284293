import React from 'react'
import * as PropTypes from 'prop-types'
import Pills from 'containers/Navs/Pills'
import t from 'utilities/translate'

const Navigation = (props) => {
    const {
        tab,
        onClickPill,
    } = props

    let pills = [
        {
            href: 'general',
            label: t('componentReleaseReleaseNavGeneral'),
            active: tab === 'general',
        },
        {
            href: 'promote',
            label: t('componentReleaseReleaseNavPromote'),
            active: tab === 'promote',
        },
    ]

    if (window.env.ENABLE_SUBSCRIPTION_MANAGEMENT) {
        pills = [...pills, {
            href: 'releaseSettings',
            label: t('componentReleaseReleaseNavReleaseSettings'),
            active: tab === 'releaseSettings',
        }]
    } else {
        pills = [...pills, {
            href: 'settings',
            label: t('componentReleaseReleaseNavSettings'),
            active: tab === 'settings',
        }]
    }

    return (
        <div className="c-release-nav">
            <Pills items={pills} size="small" onClick={onClickPill} />
        </div>
    )
}

Navigation.propTypes = {
    tab: PropTypes.string,
    onClickPill: PropTypes.func.isRequired,
}

export default Navigation
