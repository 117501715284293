import {
    isEmpty,
    minLength,
    maxLength,
    doesNotMatchRegex,
} from 'validations/rules'
import t from 'utilities/translate'

export const errorRules = {
    houseNameNumber: [
        isEmpty(t('ruleAddressHouseNumberNoEmpty')),
    ],
    street: [
        isEmpty(t('ruleAddressStreetNoEmpty')),
        minLength(3, t('ruleAddressStreetMinLen')),
    ],
    flatApartment: [],
    addressLine1: [
        isEmpty(t('ruleAddressLine1NoEmpty')),
    ],
    city: [
        isEmpty(t('ruleAddressCityNoEmpty')),
    ],
    province: [
        isEmpty(t('ruleAddressProvinceNoEmpty')),
    ],
    state: [
        isEmpty(t('ruleAddressStateNoEmpty')),
    ],
    prefecture: [
        isEmpty(t('ruleAddressPrefectureNoEmpty')),
    ],
    regionGeneric: [
        isEmpty(t('ruleAddressRegionNoEmpty')),
        doesNotMatchRegex(
            /^[\sa-zA-Z0-9\/\-?:().,+#\_;&\@'`\u0080-\u00FF\u0100-\u017F\u0180-\u0233]{1,100}$/,
            t('ruleAddressRegionGenericFormat')
        ),
    ],
    postcode: [
        isEmpty(t('ruleAddressPostcodeNoEmpty')),
        maxLength(10, t('ruleAddressPostcodeMaxLen')),
        doesNotMatchRegex(/^[0-9a-zA-Z ]+$/, t('ruleAddressPostcodeIsAlphaNumeric')),
    ],
    postcodeJP: [
        isEmpty(t('ruleAddressPostcodeNoEmpty')),
        maxLength(10, t('ruleAddressPostcodeMaxLen')),
        doesNotMatchRegex(/^[0-9a-zA-Z -]+$/, t('ruleAddressPostcodeIsJapanFormat')),
    ],
    postcodeCA: [ //
        isEmpty(t('ruleAddressPostcodeNoEmpty')),
        // https://stackoverflow.com/questions/1146202/canadian-postal-code-validation
        doesNotMatchRegex(
            /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/,
            t('ruleAddressPostcodeIsCanadianFormat')
        ),
    ],
    zipcode: [
        isEmpty(t('ruleAddressZipcodeNoEmpty')),
        minLength(5, t('ruleAddressZipcodeMinLen')),
        maxLength(5, t('ruleAddressZipcodeMaxLen')),
        doesNotMatchRegex(/^[0-9]+$/, t('ruleAddressZipcodeIsNumeric')),
    ],
    country: [],
}
