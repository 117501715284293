import { createSelector } from 'reselect'
import * as backgroundTypes from 'constants/enums/BackgroundTypes'

export const backgroundColorsSelector = state => state.backgroundColors

export const solidBackgroundsSelector = createSelector(
    backgroundColorsSelector,
    backgroundColors => backgroundColors.data.filter(color => color.type === backgroundTypes.SOLID)
)

export const gradientBackgroundsSelector = createSelector(
    backgroundColorsSelector,
    backgroundColors => backgroundColors.data.filter(color => color.type === backgroundTypes.GRADIENT)
)
