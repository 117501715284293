import { connect } from 'react-redux'
import { showModal } from 'modules/modal'

import TranslatableArtistLookup from 'components/TranslatableArtistLookup/TranslatableArtistLookup'

const mapStateToProps = state => ({
    marketId: state.users.user.marketId,
})

const mapDispatchToProps = dispatch => ({
    showTrackArtistsTranslationModal: () => dispatch(showModal('translatable-track-artist-lookup')),
    showReleaseArtistsTranslationModal: () => dispatch(showModal('translatable-release-artist-lookup')),
})

export default connect(mapStateToProps, mapDispatchToProps)(TranslatableArtistLookup)
