import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { connect } from 'react-redux'
import { fetchRelease as fetchReleaseAction } from 'actions/ReleaseActions'
import { withRouter } from 'react-router-dom'
import { urlsSelector } from 'selectors/urls'
import PostReleaseGuidance from 'routes/RouteContainers/App/ReleaseConfirmation/PostReleaseGuidance'
import { releaseSelector } from 'selectors/releases'
import Spinner from 'components/Spinner/Spinner'
import './_releaseConfirmation.scss'
import useScrollToTopEffect from 'hooks/useScrollToTopEffect'

export const ReleaseConfirmationComponent = ({
    release,
    urls,
    match,
    fetchRelease,
}) => {
    const { t } = useLocaleContext()
    const { releaseId } = match.params
    const [isLoading, setIsLoading] = useState(true)

    useScrollToTopEffect()

    useEffect(() => {
        if (isLoading) {
            fetchRelease(releaseId).then(() => {
                setIsLoading(false)
            })
        }
    }, [])

    return isLoading || !release ? (
        <Spinner />
    ) : (
        <section className="c-release-confirmation">
            <header>
                <h1 className="heading">
                    {t('paymentSuccessTitle')}
                </h1>
                <TranslateMarkdown id="releaseSuccessSubtitle" />
            </header>

            <PostReleaseGuidance
                release={release}
                urls={urls}
            />
        </section>
    )
}

ReleaseConfirmationComponent.propTypes = {
    release: PropTypes.object,
    urls: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    fetchRelease: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
    user: state.users.user,
    urls: urlsSelector(state),
    release: releaseSelector(state, ownProps.match.params.releaseId),
})

const mapDispatchToProps = ({
    fetchRelease: fetchReleaseAction,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseConfirmationComponent))
