import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    withRouter,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'
import Onboarding from 'views/Onboarding/Onboarding'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { MARKET_NAME_INTERNATIONAL } from 'constants/enums/MarketEnums'
import { marketIDToName } from 'utilities/markets'
import { EVENT_PAGE_VIEW, sendEventToDataLayer } from 'utilities/analytics'
import { getPathTitle } from 'utilities/url'
import ModalContainer from 'containers/ModalContainer/ModalContainer'
import {
    Start,
    App,
    Crm,
    Edit,
    Profile as ArtistProfile,
} from './RouteContainers'

// RegExp paths
const appTopLevel = 'overview|profile|releases|release|create|subscription|payment|trends|balance|competition|settings'
const AppRouteMatch = `/(${appTopLevel})`
const AuthedNotProfile = `/(edit|pay|${appTopLevel})`
const startTopLevel = 'start|verify|mfa|password'
const AppNotAuthedRouteMatch = `/(${startTopLevel})`

const LocalRouterComponent = ({
    isAuthenticated,
    isVerificationCheck,
    isVerificationRequest,
    isOnboardingComplete,
    user,
    location,
}) => {
    const { t } = useLocaleContext()

    useEffect(() => {
        document.title = t('documentTitle')
    })

    const [marketName, setMarketName] = useState(MARKET_NAME_INTERNATIONAL)
    const [userID, setUserID] = useState(null)
    const [lastPathnamePushed, setLastPathnamePushed] = useState(null)
    const recordPageView = () => {
        const pageTitle = getPathTitle(location.pathname)
        if (pageTitle && lastPathnamePushed !== location.pathname) {
            const eventObj = {
                pagePath: location.pathname,
                pageUrl: `${window.location.origin}${location.pathname}`,
                pageTitle,
            }
            eventObj.userId = user.id
            eventObj.country = user.countryId
            eventObj.market = user.market
            sendEventToDataLayer(
                EVENT_PAGE_VIEW,
                eventObj
            )

            setLastPathnamePushed(location.pathname)
        }
    }

    useEffect(() => {
        if (user) {
            setMarketName(marketIDToName(user.marketId))
            setUserID(user.id)
        }
    }, [user])

    useEffect(() => {
        if (user) {
            recordPageView()
        }
    }, [location.pathname, userID])

    let activeRouteSwitch = null
    if (isAuthenticated) {
        if (window.env.ARTIST_ONBOARDING && !isOnboardingComplete
            && user && !isVerificationRequest && !isVerificationCheck) {
            /**
             * Onboarding shown up for all new users and existing users that haven't set their Artist Alias
             */
            activeRouteSwitch = (
                <Switch>
                    <Route path="*" render={() => <Onboarding />} />
                </Switch>
            )
        } else {
            /**
             * if authenticated then all routes start from /overview
             * however if previous path was /start/register (i.e. user has just registered)
             * then redirect to /verify/request (email verification)
             */
            activeRouteSwitch = (
                <div id={`mkt-${marketName}`}>
                    <Switch>
                        <Route path="/crm" render={() => <Crm />} />

                        <Redirect exact from="/" to="/overview" />
                        <Route path="/start" render={() => <Redirect to="/overview" />} />
                        <Route path="/mfa/activate" render={() => <Redirect to="/overview" />} />
                        <Route path="/mfa/verify" render={() => <Redirect to="/overview" />} />

                        <Route path={AppRouteMatch} render={() => <App />} />
                        <Route path="/edit/:releaseId" render={() => <Edit />} />
                        <Route path="/:slug" render={() => <ArtistProfile />} />
                    </Switch>
                </div>
            )
        }
    } else {
        activeRouteSwitch = (
            <Switch>
                <Route path="/crm" render={() => <Crm />} />

                <Redirect exact from="/" to="/start" />
                <Route path={AppNotAuthedRouteMatch} render={() => <Start />} />
                <Redirect from={AuthedNotProfile} to={`/start?next=${window.location.pathname}`} />
                <Route path="/:slug" render={() => <ArtistProfile />} />
            </Switch>
        )
    }

    return (
        <>
            { activeRouteSwitch }
            <ModalContainer />
        </>
    )
}

LocalRouterComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isVerificationCheck: PropTypes.bool,
    isVerificationRequest: PropTypes.bool,
    isOnboardingComplete: PropTypes.bool.isRequired,
    user: PropTypes.object,
    location: PropTypes.object,
}

const mapStateToProps = state => ({
    isOnboardingComplete: state.users.isOnboardingComplete,
    user: state.users.user,
})

export default withRouter(connect(mapStateToProps)(LocalRouterComponent))
