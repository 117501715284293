import React, { Component, createRef } from 'react'
import { Field as ReduxField } from 'redux-form'
import { PropTypes } from 'prop-types'
import { snakeCase } from 'lodash'
import { validateSlug } from 'validations/async'
import t from 'utilities/translate'
import LogoUpload from 'containers/EditProfileUpload/LogoUpload'
import HeroUpload from 'containers/EditProfileUpload/HeroUpload'
import EditProfileArtistInfo from 'components/EditProfileArtistInfo/EditProfileArtistInfo'
import EditProfileGallery from 'containers/EditProfileGallery/EditProfileGallery'
import EditProfileHeroPlacement from 'components/EditProfileHeroPlacement/EditProfileHeroPlacement'
import EditProfileLocation from 'components/EditProfileLocation/EditProfileLocation'
import EditProfileReleases from 'components/EditProfileReleases/EditProfileReleases'
import EditProfileSharing from 'components/EditProfileSharing/EditProfileSharing'
import EditProfileTextOptions from 'components/EditProfileTextOptions/EditProfileTextOptions'
import EditProfileVideos from 'components/EditProfileVideos/EditProfileVideos'
import ColorPicker from 'containers/ColorPicker/ColorPicker'
import FollowInputs from 'components/EditProfileFollowInputs/FollowInputs'
import { editProfileSections, editProfileFields, spotifyProfileFieldName } from 'constants/EditProfileStructure'
import { getUrlWithHttp } from 'utilities/url'
import EditProfileTourDates from 'components/EditProfileTourDates/EditProfileTourDates'
import EditFormButtons from './EditFormButtons'
import BioInput from '../EditProfileBioInput/BioInput'
import SocialMediaInputs from '../EditProfileSocialMediaInputs/SocialMediaInputs'
import Collapse from '../Collapse/Collapse'
import { Label } from '../EditProfileInputs/Inputs'

export default class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sectionsRefs: Object.keys(editProfileSections).reduce((acc, sectionName) => ({
                ...acc,
                [sectionName]: createRef(),
            }), {}),
            biography: this.props.biography,
            previousSlug: this.props.formValues && this.props.formValues.slug,
            isSaveAndPreview: false,
        }
    }

    componentDidMount() {
        const {
            initialValues: { languageId },
            fetchLocales,
        } = this.props
        if (!languageId) {
            fetchLocales()
        }
    }

    customSlugValidation = () => {
        if (this.props.initialValues && this.props.formValues) {
            const {
                initialValues: { slug: initialSlug },
                formValues: { slug },
                form,
            } = this.props
            const hasSlugChanged = `${slug}`.toLowerCase() !== `${this.state.previousSlug}`.toLowerCase()
            const isSlugDifferentThanInitial = `${slug}`.toLowerCase() !== `${initialSlug}`.toLowerCase()

            if (hasSlugChanged && slug) {
                if (isSlugDifferentThanInitial) {
                    validateSlug(slug, t('formEditProfileSlugAlreadyExists')).then((error) => {
                        this.props.setValidationError(form, error)
                    })
                } else {
                    this.props.setValidationError(form, {})
                }
                this.setState({
                    previousSlug: slug,
                })
            }
        }
    }

    changeFalsyValueToNull = value => (
        (!value || (typeof value === 'string' && value.trim().length === 0)) ? null : value
    )

    groupLink = (name, url) => ({
        name: snakeCase(name),
        url: getUrlWithHttp(url),
    })

    prepareVideos = videos => (
        videos.reduce((accumulator, videoUrl) => {
            const notEmptyUrl = this.changeFalsyValueToNull(videoUrl)
            if (notEmptyUrl !== null) {
                accumulator.push(getUrlWithHttp(notEmptyUrl))
            }
            return accumulator
        }, [])
    )

    prepareReleases = releases => (
        releases.reduce((accumulator, release) => (
            release.checked ? [...accumulator, release.id] : accumulator
        ), []).sort((a, b) => a - b)
    )

    prepareSpotifyUrl = (artistRecord = {}) => {
        if (!artistRecord.toJS) {
            return null
        }
        const { identifiers } = artistRecord.toJS()
        const spotifyIdentifier = identifiers.find(identifier => (
            identifier && identifier.name === 'spotify'
        ))
        const artistId = spotifyIdentifier.value.split('spotify:artist:')[1]
        return `https://open.spotify.com/artist/${artistId}`
    }

    prepareFormData = data => Object.entries(data).filter(element => element[0] !== 'updatedAt').reduce(
        (accumulator, element) => {
            const key = element[0]
            const value = this.changeFalsyValueToNull(element[1])
            // Grouping links together as one array of objects
            if (key.includes('Social') && value !== null) {
                accumulator.links.push(this.groupLink(key, value))
            } else if (key === spotifyProfileFieldName && value !== null) {
                const spotifyUrl = this.prepareSpotifyUrl(value.value)
                accumulator.links.push(
                    this.groupLink(key, spotifyUrl)
                )
            } else if (key === 'videos') {
                accumulator.videos = this.prepareVideos(value)
            } else if (key === 'releases') {
                accumulator.releases = this.prepareReleases(value)
            } else {
                accumulator = {
                    ...accumulator,
                    [key]: value,
                }
            }
            return accumulator
        }, ({
            links: [],
            videos: [],
            releases: [],
            // As biography is not done with regular input we have to add it's value in here
            biography: this.state.biography,
        })
    )

    saveDraft = ({ id, ...data }) => {
        const dataToSend = this.prepareFormData(data)
        return this.props.updateDraftProfile(id, dataToSend)
    }

    publishDraft = ({ id, ...data }) => {
        const dataToSend = this.prepareFormData(data)
        return this.props.initiatePublishingProfile(id, dataToSend)
    }

    displayPreview = ({ id }) => {
        this.props.openPreview(id)
        this.updateSavePreviewState(false)
    }

    handleEditorChange = (value) => {
        this.setState({ biography: value })
    }

    updateSavePreviewState = (value) => {
        this.setState({ isSaveAndPreview: value })
    }

    scrollToSectionWithErrors = (errors) => {
        const { sectionsRefs } = this.state
        const firstSectionWithError = Object.keys(editProfileFields).find(sectionName => (
            Object.values(editProfileFields[sectionName]).find(value => errors[value])
        ))

        const firstSectionWithErrorRef = sectionsRefs[firstSectionWithError]
        if (firstSectionWithErrorRef && firstSectionWithErrorRef.current) {
            const { offsetTop } = firstSectionWithErrorRef.current
            window.scrollTo(0, Math.max(offsetTop - 150, 0))
        }
    }

    renderEditFormButtons = (profileSection) => {
        const {
            handleSubmit,
            isSubmitting,
            isPublishing,
            isTokenLoading,
            isProcessingFiles,
            isFetchingDsp,
            openPopup,
            initialValues,
            formValues,
            hasPublicProfile,
            form,
            setValidationError,
            valid,
            user,
        } = this.props

        const {
            isSaveAndPreview,
        } = this.state

        return (
            <EditFormButtons
                saveDraft={handleSubmit(this.saveDraft)}
                publishDraft={handleSubmit(this.publishDraft)}
                showPreview={handleSubmit(this.displayPreview)}
                isSubmitting={isSubmitting}
                isPublishing={isPublishing}
                isTokenLoading={isTokenLoading}
                isFetchingDsp={isFetchingDsp}
                isProcessingFiles={isProcessingFiles}
                openPopup={openPopup}
                initialPrivacy={initialValues && initialValues.profileVisibility}
                actualPrivacy={formValues && formValues.profileVisibility}
                hasPublicProfile={hasPublicProfile}
                form={form}
                setValidationError={setValidationError}
                isFormValid={valid}
                updateSavePreviewState={this.updateSavePreviewState}
                isSaveAndPreview={isSaveAndPreview}
                scrollOnError={this.scrollToSectionWithErrors}
                user={user}
                profileSection={profileSection}
            />
        )
    }

    render() {
        const {
            initialValues: { releases },
            formValues,
            hasPublicProfile,
            addNotification,
            openPopup,
            sectionsWithCheckedErrors,
        } = this.props
        const { sectionsRefs } = this.state
        const slug = formValues ? formValues.slug : ''
        const heroPlacement = formValues ? formValues.heroPlacement : ''
        const displayReleases = releases.length > 0
        const firstSectionWithError = Object.keys(sectionsWithCheckedErrors).find(sectionName => (
            !!sectionsWithCheckedErrors[sectionName]
        ))
        return (
            <div>
                <form>
                    <div className="c-edit-profile c-edit-profile-form">
                        <div
                            className="section"
                            ref={sectionsRefs[editProfileSections.baseSection]}
                        >
                            <p className="c-edit-profile-mandatory-info">{t('componentEditProfileMandatoryInfo')}</p>
                        </div>
                        <hr className="c-edit-profile-separator" />
                        <div className="section">
                            <EditProfileSharing />
                        </div>
                        <hr className="c-edit-profile-separator" />
                        <div className="section">
                            <EditProfileArtistInfo
                                customSlugValidation={this.customSlugValidation}
                                slug={slug}
                                hasPublicProfile={hasPublicProfile}
                                addNotification={addNotification}
                                openPopup={openPopup}
                            />
                        </div>
                        <hr className="c-edit-profile-separator" />
                        <div className="section">
                            <Label label={t('uploadEditProfileLogoLabel')} />
                            <LogoUpload />
                        </div>
                        <hr className="c-edit-profile-separator" />
                        <div className="section">
                            <EditProfileLocation />
                        </div>
                        <hr className="c-edit-profile-separator" />
                        <div className="section">
                            <div className="c-edit-profile-hero-wrapper">
                                <HeroUpload heroPlacement={heroPlacement} />
                                <EditProfileHeroPlacement />
                            </div>
                        </div>
                        <hr className="c-edit-profile-separator" />
                        <div className="section">
                            <ReduxField name={editProfileFields.baseSection.bgColorId} component={ColorPicker} />
                        </div>
                        <hr className="c-edit-profile-separator" />
                        <div className="section">
                            <EditProfileTextOptions />
                        </div>
                        {this.renderEditFormButtons('main')}
                        <div className="section" ref={sectionsRefs[editProfileSections.storeProfiles]}>
                            <Collapse
                                title={t('componentEditProfileSectionTitleFollow')}
                                hasError={sectionsWithCheckedErrors.storeProfiles}
                                shouldForceOpen={editProfileSections.storeProfiles === firstSectionWithError}
                            >
                                <FollowInputs />
                                {this.renderEditFormButtons('follow')}
                            </Collapse>
                        </div>
                        <div className="section" ref={sectionsRefs[editProfileSections.music]}>
                            <Collapse
                                title={t('componentEditProfileSectionTitleReleases')}
                                hasError={sectionsWithCheckedErrors.music}
                                shouldForceOpen={editProfileSections.music === firstSectionWithError}
                            >
                                {displayReleases ? (
                                    <div>
                                        <EditProfileReleases releases={releases} />
                                        {this.renderEditFormButtons('releases')}
                                    </div>
                                ) : <p>{t('formEditProfileFormReleasesErrorNoReleases')}</p>}
                            </Collapse>
                        </div>
                        <div className="section" ref={sectionsRefs[editProfileSections.socialMedia]}>
                            <Collapse
                                title={t('componentEditProfileSectionTitleSocialMedia')}
                                hasError={sectionsWithCheckedErrors.socialMedia}
                                shouldForceOpen={editProfileSections.socialMedia === firstSectionWithError}
                            >
                                <SocialMediaInputs />
                                {this.renderEditFormButtons('socialMedia')}
                            </Collapse>
                        </div>
                        <div className="section" ref={sectionsRefs[editProfileSections.videos]}>
                            <Collapse
                                title={t('componentEditProfileSectionTitleVideo')}
                                hasError={sectionsWithCheckedErrors.videos}
                                shouldForceOpen={editProfileSections.videos === firstSectionWithError}
                            >
                                <EditProfileVideos />
                                {this.renderEditFormButtons('video')}
                            </Collapse>
                        </div>
                        <div className="section" ref={sectionsRefs[editProfileSections.gallery]}>
                            <Collapse
                                title={t('componentEditProfileSectionTitleGallery')}
                                hasError={sectionsWithCheckedErrors.gallery}
                                shouldForceOpen={editProfileSections.gallery === firstSectionWithError}
                            >
                                <EditProfileGallery />
                                {this.renderEditFormButtons('gallery')}
                            </Collapse>
                        </div>
                    </div>
                </form>
                <div className="section" ref={sectionsRefs[editProfileSections.tourDates]}>
                    <Collapse
                        title={t('componentEditProfileSectionTitleTourDates')}
                        hasError={sectionsWithCheckedErrors.tourDates}
                        shouldForceOpen={editProfileSections.tourDates === firstSectionWithError}
                    >
                        <EditProfileTourDates />
                        {this.renderEditFormButtons('tour')}
                    </Collapse>
                </div>
                <div id="bio-editor-section" className="section" ref={sectionsRefs[editProfileSections.about]}>
                    <Collapse
                        title={t('componentEditProfileSectionTitleAbout')}
                        hasError={sectionsWithCheckedErrors.about}
                        shouldForceOpen={editProfileSections.about === firstSectionWithError}
                    >
                        <BioInput biography={this.state.biography} handleEditorChange={this.handleEditorChange} />
                        {this.renderEditFormButtons('about')}
                    </Collapse>
                </div>
            </div>
        )
    }
}

EditProfile.propTypes = {
    updateDraftProfile: PropTypes.func.isRequired,
    initiatePublishingProfile: PropTypes.func.isRequired,
    openPreview: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setValidationError: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.shape({
        profileVisibility: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        heroPlacement: PropTypes.string,
        bgColorId: PropTypes.number,
    }),
    initialValues: PropTypes.shape({
        profileVisibility: PropTypes.string,
        slug: PropTypes.string,
        languageId: PropTypes.string,
        releases: PropTypes.array.isRequired,
    }),
    isSubmitting: PropTypes.bool.isRequired,
    isPublishing: PropTypes.bool.isRequired,
    isTokenLoading: PropTypes.bool.isRequired,
    isProcessingFiles: PropTypes.bool.isRequired,
    isFetchingDsp: PropTypes.bool.isRequired,
    biography: PropTypes.string,
    hasPublicProfile: PropTypes.bool,
    addNotification: PropTypes.func.isRequired,
    urls: PropTypes.shape({
        typeFormNewArtistProfileSurvey: PropTypes.string,
    }),
    sectionsWithCheckedErrors: PropTypes.objectOf(PropTypes.bool).isRequired,
    fetchLocales: PropTypes.func,
    user: PropTypes.object,
}
