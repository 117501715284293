export const MARKET_INTERNATIONAL = 1
export const MARKET_GERMAN_SPEAKING = 2
export const MARKET_UK = 3
export const MARKET_NORWAY = 4
export const MARKET_DENMARK = 5
export const MARKET_SWEDEN = 6
export const MARKET_USA_CANADA = 7
export const MARKET_FRANCE = 9
export const MARKET_SPANISH_SPEAKING = 10
export const MARKET_AUSTRALASIA = 11
export const MARKET_SE_ASIA = 12
export const MARKET_JAPAN = 13
