export function error(errors, key) {
    return (errors[key] && errors[key].error && errors[key].message) || ''
}

export function warning(errors, key) {
    return (errors[key] && !errors[key].error && errors[key].message) || ''
}

export function formatErrors(errors) {
    if (typeof errors !== 'object') return {}

    return Object.keys(errors).reduce((obj, key) => ({
        ...obj,
        [key]: errors[key].join('. '),
    }), {})
}

// todo  this is not ideal and is necessary due to the fact that api errors are processed in the reducer and passed
// todo  through 'formatted' to the component. Long-term it would be ideal to either introduce a variant of t for this
// todo  or have the field component specifically handle error arrays
export function translateFormattedError(errorString, t) {
    if (!errorString) {
        return undefined
    } else if (errorString.indexOf('.') === -1) {
        return t(errorString)
    } else {
        const parts = errorString.split('. ')
        return parts.map(p => t(p)).join('. ')
    }
}

export default error
