import {
    ABORT_UPLOAD_CHUNKED_API,
    ApiAction,
    ApiMeta,
    ApiPayload, CALL_API, DOWNLOAD_API, UPLOAD_CHUNKED_API,
} from 'middleware/api'
import { AppStoreDispatch } from 'store/store-types'

export const createApiAction = (
    apiType: symbol,
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
): ApiAction => ({
    type: 'api-action',
    [apiType]: {
        types: actionTypes,
        payload,
        meta,
    },
})

export const createThunkApiAction = (
    apiType: symbol,
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
) => (dispatch: AppStoreDispatch) => {
    dispatch(createApiAction(apiType, actionTypes, payload, meta))
}

/*
 * CALL_API
 */
export const callApi = (
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
) => createApiAction(CALL_API, actionTypes, payload, meta)

export const callApiThunk = (
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
) => createThunkApiAction(CALL_API, actionTypes, payload, meta)

/*
 * DOWNLOAD_API
 */
export const downloadApi = (
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
) => createApiAction(DOWNLOAD_API, actionTypes, payload, meta)

export const downloadApiThunk = (
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
) => createThunkApiAction(DOWNLOAD_API, actionTypes, payload, meta)

/*
 * UPLOAD_CHUNKED_API
 */
export const uploadChunkedApi = (
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
) => createApiAction(UPLOAD_CHUNKED_API, actionTypes, payload, meta)

export const uploadChunkedApiThunk = (
    actionTypes: string[],
    payload: ApiPayload,
    meta?: ApiMeta
) => createThunkApiAction(UPLOAD_CHUNKED_API, actionTypes, payload, meta)

export const abortChunkedUploadApi = (
    actionType: string,
    payload: ApiPayload,
    meta?: ApiMeta
) => createApiAction(ABORT_UPLOAD_CHUNKED_API, [actionType], payload, meta)
