import { camelizeKeys } from 'humps'

const HTTP_STATUS_NO_CONTENT = 204

type FetchParams = {
    rootUrl: string
    endpoint: string,
    method: string,
    accessToken?: string,
    query?: Record<string, string>,
    body?: any,
    data?: any,
    request?: RequestInit,
    headers?: HeadersInit,
    isBlobResponse?: boolean
}

type IntermediateResponse = {
    response: Response,
    blob?: Blob,
    json?: any
}

type FetchResponse = {
    payload: any,
    isBlobPayload?: boolean
    blobFileName?: string
    length: number,
    headers: Headers,
}

type FetchError = {
    statusCode?: number
    error?: any
}

function getAttachmentNameFromContentDisposition(headerValue: string) {
    const defaultDownloadName = 'download'
    if (!headerValue) {
        return defaultDownloadName
    }

    const regex = new RegExp(/filename\*=UTF-8''((['"]).*?\2|[^;\n]*)/)
    const fileNameMatch = headerValue.match(regex) ?? []
    return fileNameMatch.length > 1
        ? decodeURIComponent(fileNameMatch[1].replace(/"/g, ''))
        : defaultDownloadName
}

const func = ({
    rootUrl = window.location.origin,
    endpoint,
    method = 'get',
    query,
    body,
    data,
    request = {},
    accessToken,
    headers = {},
    isBlobResponse,
}: FetchParams) => {
    let url = rootUrl + endpoint

    const additionalHeaders: Record<string, string> = {}
    if (accessToken) {
        additionalHeaders.Authorization = `Bearer ${accessToken}`
    }

    if (body) {
        additionalHeaders['Content-Type'] = 'application/json'
    }

    const req: RequestInit = {
        ...request,

        headers: {
            Accept: 'application/json',
            ...headers,
            ...additionalHeaders,
        },
        method,
        body: body ?? data,
    }

    if (query) {
        url += `?${Object.keys(query).map(key => `${key}=${query[key]}`).join('&')}`
    }

    return fetch(url, req)
        .then<IntermediateResponse>((response) => {
        if (response.status === HTTP_STATUS_NO_CONTENT) {
            return {
                json: {},
                response,
            }
        }

        if (!isBlobResponse) {
            return response.json()
                .then(json => ({
                    json: camelizeKeys(json),
                    response,
                }))
        }

        return response.blob().then(blob => ({
            blob,
            response,
            json: null,
        }))
    })
        .then<FetchResponse & FetchError>(({ json, blob, response }) => {
        if (!response.ok) {
            return Promise.reject({
                error: json,
                statusCode: response.status,
            })
        }

        const isBlobPayload = !!blob
        const contentDispositionHeader = response.headers.get('Content-Disposition')

        return {
            payload: json || blob,
            isBlobPayload,
            blobFileName: isBlobPayload
                ? getAttachmentNameFromContentDisposition(contentDispositionHeader ?? 'default-content')
                : undefined,
            length: Number(response.headers.get('X-Total-Count')),
            headers: response.headers,
        }
    })
}

export default func
