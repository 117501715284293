import { connect } from 'react-redux'

import { pushScout } from 'actions/ScoutActions'
import { getRelease } from 'actions/ReleaseActions'
import { pushesQuantitySelector } from 'selectors/users'
import { filteredReleasesSelector } from 'selectors/releases'

import PushForm from 'components/PushForm/PushForm'

const mapStateToProps = (state, props) => {
    const releaseFilter = 'competition-valid'
    return {
        isLoading: state.scouts.isPushing,
        errors: state.scouts.errors,
        pushes: pushesQuantitySelector(state, props),
        releaseFilter,
        hasValidReleases: filteredReleasesSelector(state, releaseFilter).length > 0,
    }
}

const mapDispatchToProps = {
    pushScout,
    getRelease,
}

export default connect(mapStateToProps, mapDispatchToProps)(PushForm)
