// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { of } from 'rxjs'
import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { mergeMap, catchError } from 'rxjs/operators'
import {
    startSubmit,
    stopSubmit,
    destroy,
} from 'redux-form'

import {
    updateAsyncErrors,
} from 'modules/forms'

import {
    ALBUM_FORM_KEY,
    albumFormRegex,
    getAlbumSettingFormName,
} from 'modules/common/album'

//
import { patchRelease } from 'services/spinnup-api/release'
//
const APP_PREFIX = 'spinnup'
export const KEY = 'release'
//
export const getAlbumReleaseFormName = () => `${ALBUM_FORM_KEY}_release`

// ///////////
// DETERMINISTIC ACTIONS
// ///////////

// already declare in constants files, not duckerise YET !!! (๑˃̵ᴗ˂̵)و

// ///////////
// ACTION CREATORS
// ///////////

// already declare in actionCreator file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و

// ///////////
// REDUCERS
// ///////////

// already declare in reducer file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و
export default combineReducers({})

// ///////////
// SELECTORS
// ///////////

// /////////
// NON DETERMINISTIC ACTIONS
// /////////
export const REQUEST_SAVE = `${APP_PREFIX}/${KEY}/REQUEST_SAVE`
export const RECEIVE_SAVE_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_SUCCESS`
export const RECEIVE_SAVE_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_FAILURE`
export const RECEIVE_PATCH_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_SUCCESS`
export const RECEIVE_PATCH_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_FAILURE`
export const SYNC_VALIDATE = `${APP_PREFIX}/${KEY}/SYNC_VALIDATE`
export const DESTROY_RELEASE_FORM = `${APP_PREFIX}/${KEY}/DESTROY_RELEASE_FORM`

// ///////////
// ACTION CREATORS
// ///////////

export const requestSave = (release, field) => ({
    type: REQUEST_SAVE,
    payload: release,
    meta: {
        release,
        field,
    },
})

export const receiveSave = updatedRelease => ({
    type: RECEIVE_SAVE_SUCCESS,
    payload: updatedRelease,
})

export const receiveSaveFailure = (error, { id }, field) => ({
    type: RECEIVE_SAVE_FAILURE,
    payload: error,
    meta: {
        id,
        field,
    },
})

export const receivePatch = updatedRelease => ({
    type: RECEIVE_PATCH_SUCCESS,
    payload: updatedRelease,
})

export const receivePatchFailure = ({ message }, { id }, field) => ({
    type: RECEIVE_PATCH_FAILURE,
    payload: message,
    meta: {
        field,
        id,
    },
})

export const syncValidate = (formId, field) => ({
    type: SYNC_VALIDATE,
    payload: formId,
    meta: {
        field,
    },
})

export const destroyReleaseForm = () => ({
    type: DESTROY_RELEASE_FORM,
})

// ///////////
// EPICS
// ///////////
export const requestSaveToStartSubmitEpic = action$ => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(() => [
        startSubmit(getAlbumSettingFormName()),
    ])
)

export const receiveSaveToStopSumitEpic = action$ => action$.pipe(
    ofType(RECEIVE_SAVE_SUCCESS, RECEIVE_SAVE_FAILURE),
    mergeMap(() => [
        stopSubmit(getAlbumSettingFormName()),
    ])
)

export const requestSaveToPutEpic = (action$, store, { ajax }) => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(({
        payload: release,
        meta: { field },
    }) => {
        const state = store.value
        const { credentials } = state.auth

        return patchRelease(
            {
                release,
                field: field.replace('release.', ''),
            },
            credentials,
            ajax
        ).pipe(
            mergeMap(({ response }) => of(receiveSave(response))),
            catchError(({ response }) => of(receiveSaveFailure(response, release, field)))
        )
    })
)

export const receivePatchFailureEpic = action$ => action$.pipe(
    ofType(RECEIVE_PATCH_FAILURE),
    mergeMap(({ payload: error, meta: { field } }) => of(updateAsyncErrors(getAlbumSettingFormName(), {
        [field]: error,
    })))
)

const destroyReleaseFormEpic = (action$, store) => action$.pipe(
    ofType(DESTROY_RELEASE_FORM),
    mergeMap(() => {
        const state = store.value
        const reduxFormNames = Object.keys(state.form)
        const reduxReleaseFormNames = reduxFormNames.filter(reduxFormName => (
            reduxFormName.match(albumFormRegex)
        ))
        return reduxReleaseFormNames.map(reduxFormName => destroy(reduxFormName))
    })
)

export const epic = combineEpics(
    requestSaveToStartSubmitEpic,
    requestSaveToPutEpic,
    receiveSaveToStopSumitEpic,
    receivePatchFailureEpic,
    destroyReleaseFormEpic
)
