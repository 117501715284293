import { OrderedMap } from 'immutable'
import * as types from 'constants/ProfileActionTypes'
import * as albumTypes from 'constants/AlbumActionTypes'
import { hydrateProduct } from 'utilities/hydrator'
import * as authTypes from 'constants/AuthActionTypes'

const initialState = {
    isFetching: false,
    artist: undefined,
    albums: new OrderedMap(),
}

export default function profile(state = initialState, action) {
    switch (action.type) {
        // FETCH

        case types.FETCH_PROFILE_REQUEST_LEGACY:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_PROFILE_SUCCESS_LEGACY:
            return {
                ...state,
                isFetching: false,
                artist: action.payload,
            }

        case types.FETCH_PROFILE_FAILURE_LEGACY:
            return {
                ...state,
                isFetching: false,
            }

            // ALBUMS

        case types.FETCH_ALBUMS_SUCCESS:
            return {
                ...state,
                albums: state.albums.merge(
                    action.payload
                        .sort((a, b) => new Date(b.releaseAt).getTime() - new Date(a.releaseAt).getTime())
                        .reduce((map, product) => ({
                            ...map,
                            [product.id]: hydrateProduct(product),
                        }), {})
                ),
            }

        case albumTypes.FETCH_ALBUM_ARTWORK_SUCCESS:
            if (state.artist !== undefined) {
                return {
                    ...state,
                    albums: state.albums.setIn([action.meta.id, 'coverArt'], action.payload.data),
                }
            }

            return state

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
