import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import ErrorOverlayMessage from 'components/ErrorOverlayMessage/ErrorOverlayMessage'
import t from 'utilities/translate'
import { BalanceState } from 'reducers/wallet'

type DisplayBalanceProps = {
    balance: Partial<BalanceState> | string | number
    balanceErrors: boolean
}

const DisplayBalance: React.FC<DisplayBalanceProps> = (props) => {
    const { balance: balanceProp } = props
    let balance: Partial<BalanceState>

    if (props.balanceErrors) {
        return (
            <section className="c-balance-amount">
                <ErrorOverlayMessage
                    className="c-balance-amount-error"
                    message={
                        t('componentDisplayBalanceFailure')
                    }
                    enabled
                />
            </section>
        )
    }

    if (typeof balanceProp === 'string') {
        balance = {
            amount: balanceProp.replace(/[^0-9.-]+/g, ''),
            formatted: balanceProp,
            formattedLocal: balanceProp,
        }
    } else if (typeof balanceProp === 'number') {
        balance = {
            amount: balanceProp.toString(),
            formatted: balanceProp.toString(),
            formattedLocal: balanceProp.toString(),
        }
    } else {
        balance = balanceProp as Partial<BalanceState>
    }

    const amount = parseFloat(balance.amount || '0')
    const amountValueClassNames = classnames(
        {
            'c-balance-amount-value-tens': (amount <= 99.99),
            'c-balance-amount-value-hundreds': (amount > 99.99) && (amount <= 999.99),
            'c-balance-amount-value-thousands': (amount > 999.99) && (amount <= 99999.99),
            'c-balance-amount-value-hun-thousands': (amount > 99999.99) && (amount <= 999999.99),
            'c-balance-amount-value-more': (amount > 999999.99),
        }
    )

    return (
        <section className="c-balance-amount">
            <p className={amountValueClassNames}>
                {amount === 0 && balance.formattedLocal ? balance.formattedLocal : balance.formatted}
                {amount !== 0 && balance.formatted !== balance.formattedLocal
                    ? <span className="c-balance-amount-value-approx">~ {balance.formattedLocal}</span>
                    : null}
            </p>
        </section>
    )
}

DisplayBalance.propTypes = {
    balance: PropTypes.oneOfType([
        PropTypes.object, PropTypes.string, PropTypes.number,
    ]).isRequired,
    balanceErrors: PropTypes.bool.isRequired,
}

export default DisplayBalance
