import { Field as ReduxField } from 'redux-form'
import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import MarketOrderSwitch from 'components/MarketOrderSwitch/MarketOrderSwitch'
import { errorRules } from 'components/AddressForm/rules'
import { countryRegionFields } from 'components/AddressForm/countryRegions'
import { ReduxFormTextField } from 'components/common/forms/ReduxFormTextField'
import { ReduxFormSelectField } from 'components/common/forms/ReduxFormSelectField'

const renderCountrySpecificRegionalField = (countryCode, disabled, enableGenericRegion) => {
    if (Object.hasOwnProperty.call(countryRegionFields, countryCode)) {
        const {
            fieldName,
            fieldLabel,
            fieldPlaceholder,
            validate,
            options,
        } = countryRegionFields[countryCode]
        return (
            <ReduxField
                name={fieldName}
                component={ReduxFormSelectField}
                allValues={options}
                isFetchingValues={false}
                fieldClassName="c-address-form-region-field"
                labelId={fieldLabel}
                emptyLabelId={fieldPlaceholder}
                validate={validate}
                disabled={disabled}
                disableSort
            />
        )
    } else if (!enableGenericRegion) {
        return ''
    } else {
        return (
            <ReduxField
                name="regionGeneric"
                fieldClassName="c-address-form-region-field"
                labelId="formAddressRegionGeneric"
                disabled={disabled}
                component={ReduxFormTextField}
                validate={errorRules.regionGeneric}
            />
        )
    }
}

const renderCountrySpecificPostcodeField = (currCountrySelected, disabled) => {
    let field = null
    switch (currCountrySelected) {
        case 'US':
            field = (
                <ReduxField
                    name="zipcode"
                    component={ReduxFormTextField}
                    labelId="formAddressZipcode"
                    fieldClassName="c-address-form-postcode-field"
                    validate={errorRules.zipcode}
                    disabled={disabled}
                />
            )
            break
        case 'CA':
            field = (
                <ReduxField
                    name="postcode"
                    component={ReduxFormTextField}
                    labelId="formAddressPostcode"
                    fieldClassName="c-address-form-postcode-field"
                    validate={errorRules.postcodeCA}
                    disabled={disabled}
                />
            )
            break
        case 'JP':
            field = (
                <ReduxField
                    name="postcode"
                    component={ReduxFormTextField}
                    labelId="formAddressPostcode"
                    fieldClassName="c-address-form-postcode-field"
                    validate={errorRules.postcodeJP}
                    disabled={disabled}
                />
            )
            break
        default: field = (
            <ReduxField
                name="postcode"
                component={ReduxFormTextField}
                labelId="formAddressPostcode"
                fieldClassName="c-address-form-postcode-field"
                validate={errorRules.postcode}
                disabled={disabled}
            />
        )
    }
    return field
}

export const AddressFormFields = ({
    marketId,
    initialValues,
    isFetchingCountries,
    allCountries,
    reduxFormChange,
    mergeAddressLine1,
    disabled,
    enableGenericRegion,
}) => {
    const [currCountrySelected, setCurrCountrySelected] = useState(initialValues.country)

    const clearRegionFields = () => {
        reduxFormChange('state', '')
        reduxFormChange('province', '')
        reduxFormChange('prefecture', '')
        reduxFormChange('regionGeneric', '')
        reduxFormChange('postcode', '')
        reduxFormChange('zipcode', '')
    }

    const countryChangeHandler = (event, newValue) => {
        if (!isFetchingCountries) {
            clearRegionFields(newValue)
            setCurrCountrySelected(newValue)
        }
    }

    let formOrderKey = 'billingAddress'
    let components = {
        city: (
            <ReduxField
                name="city"
                component={ReduxFormTextField}
                labelId="formAddressCity"
                validate={errorRules.city}
                disabled={disabled}
            />
        ),
        region: renderCountrySpecificRegionalField(currCountrySelected, disabled, enableGenericRegion),
        zipPostcode: renderCountrySpecificPostcodeField(currCountrySelected, disabled),
        country: (
            <ReduxField
                name="country"
                onChange={countryChangeHandler}
                component={ReduxFormSelectField}
                isFetchingValues={isFetchingCountries}
                allValues={allCountries}
                labelId="formAddressCountry"
                emptyLabelId="formAddressSelectCountryPlaceholder"
                fieldClassName="c-address-form-country-field"
                disabled={disabled}
            />
        ),
    }

    if (mergeAddressLine1) {
        formOrderKey = 'billingAddressMergedLine1'
        components = Object.assign(components, {
            addressLine1: (
                <ReduxField
                    name="addressLine1"
                    component={ReduxFormTextField}
                    labelId="formInputLabelAddressLine1"
                    validate={errorRules.addressLine1}
                    disabled={disabled}
                />
            ),
        })
    } else {
        components = Object.assign(components, {
            houseNameNumber: (
                <ReduxField
                    name="houseNameNumber"
                    component={ReduxFormTextField}
                    labelId="formAddressHouseNameNumber"
                    validate={errorRules.houseNameNumber}
                    props={{ disabled }}
                />
            ),
            street: (
                <ReduxField
                    name="street"
                    component={ReduxFormTextField}
                    labelId="formAddressStreet"
                    validate={errorRules.street}
                    props={{ disabled }}
                />
            ),
            flatApartment: (
                <ReduxField
                    name="flatApartment"
                    component={ReduxFormTextField}
                    labelId="formAddressFlatApartment"
                    props={{ disabled }}
                />
            ),
        })
    }

    return (
        <div>
            <MarketOrderSwitch
                marketId={marketId}
                type={formOrderKey}
                components={components}
            />
        </div>
    )
}

AddressFormFields.propTypes = {
    marketId: PropTypes.number.isRequired,
    initialValues: PropTypes.object.isRequired,
    isFetchingCountries: PropTypes.bool.isRequired,
    allCountries: PropTypes.array.isRequired,
    reduxFormChange: PropTypes.func.isRequired,
    mergeAddressLine1: PropTypes.bool,
    disabled: PropTypes.bool,
    enableGenericRegion: PropTypes.bool,
}
