import { COMPOSITION_TYPE_ORIGINAL } from 'constants/enums/TrackEnums'
import validator, {
    required,
    isChecked,
    contains,
    maxLength,
    matchRegex,
    notMoreThanTwoTitlesAndSameVersion,
    isEmpty,
    doesNotMatchRegex,
    hasTitleMetadataRequiredForRegion,
    hasTrackArtistNameMetadataRequiredForRegion,
} from './rules'

export const errorRules = {
    title: {
        rules: [
            isEmpty('ruleTrackMessageName'),
            maxLength(255, 'track.Title602'),
            matchRegex(/\s{2,}/, 'track.Title605'),
            notMoreThanTwoTitlesAndSameVersion('ruleTrackMessageDuplicate'),
            hasTitleMetadataRequiredForRegion('releaseFlowTrackTranslationError'),
        ],
        touchLinkedFormsByError: ['ruleTrackMessageDuplicate'],
    },
    artists: {
        rules: [
            hasTrackArtistNameMetadataRequiredForRegion('releaseFlowArtistTranslationError'),
        ],
    },
    recordingVersionId: {
        rules: [
            required('ruleTrackMessageNoRecordingVersion'),
        ],
        touches: ['title'],
    },
    // related to PM-268 it is here not to diplay an error per se but it ensure to block the release flow
    hasAudio: [
        required('ruleTrackMessageNoAudioUrl'),
    ],
    audio: [
        required('containerTrackUploadFileMissingText'),
    ],
    // it is here not to diplay an error per se but to ensure we block the release flow while we are uploading
    // it is weird that an upload in progress is considerated as an error though.
    isUploading: {
        rules: [
            ({ isUploading }) => (isUploading ? '' : undefined),
        ],
    },
    isrc: {
        rules: [
            doesNotMatchRegex(/^([A-Z]{2}[A-Z0-9]{3}[0-9]{7})?$/, 'DSP_TRACK_ERROR_ISRC_INVALID'),
        ],
    },
    publishers: {
        rules: [
            isEmpty('ruleTrackAtLeastOnePublishers'),
        ],
    },
    audioLocale: [
        (value, { formName, state }) => {
            let error

            const { hasLyrics } = state.form[formName].values
            if (hasLyrics === true) {
                error = value === false
                    ? 'ruleTrackNoLyricsLocale'
                    : required('ruleTrackNoLyricsLocale')(value)
            }
            return error
        },
    ],
    explicitLyrics: [
        (value, { formName, state }) => {
            let error

            const { hasLyrics } = state.form[formName].values
            if (hasLyrics === true) {
                error = value === undefined
                    ? 'ruleTrackSelectExplicitLyrics'
                    : required('ruleTrackSelectExplicitLyrics')(value)
            }
            return error
        },
    ],
    hasLyrics: [
        required('ruleTrackAcknowledgeLyrics'),
    ],
    compositionTypeId: [
        required('ruleTrackCompositionType'),
    ],
    compositionTermsAgreed: [
        (value, { formName, state }) => {
            let error

            const { compositionTypeId } = state.form[formName].values

            if (compositionTypeId !== COMPOSITION_TYPE_ORIGINAL) {
                error = isChecked('ruleTrackCompositionTermsAgreed')(value)
            }

            return error
        },
    ],
}

const warningRules = {
    title: {
        rules: [
            contains(
                [
                    'feat',
                    'featuring',
                    'ft',
                    'with',
                    'vs',
                    'mit',
                    'med',
                ],
                'ruleTrackMessageArtistInTitle'
            ),
            contains(
                [
                    'ep',
                    'single',
                    'album',
                ],
                'ruleTrackMessageProductInTitle'
            ),
            contains(
                [
                    'wav',
                    'flac',
                    'mp3',
                    'acc',
                ],
                'ruleTrackMessageFileTypeInTitle'
            ),
            contains(
                [
                    'cover',
                ],
                'ruleTrackMessageCoverInTitle'
            ),
            contains(
                [
                    'original',
                ],
                'ruleTrackMessageOriginalInTitle'
            ),
            contains(
                [
                    'clean',
                ],
                'ruleTrackMessageCleanInTitle'
            ),
            contains(
                [
                    'explicit',
                ],
                'ruleTrackMessageExplicitInTitle'
            ),
            contains(
                [
                    'radio',
                ],
                'ruleTrackMessageRadioInTitle'
            ),
            contains(
                [
                    'instrumental',
                ],
                'ruleTrackMessageInstrumentalInTitle'
            ),
            contains(
                [
                    'acoustic',
                    'akustisk',
                    'akustisch',
                ],
                'ruleTrackMessageAccousticInTitle'
            ),
            contains(
                [
                    'limited edition',
                ],
                'ruleTrackMessageLimitedEditionInTitle'
            ),
            contains(
                [
                    'exclusive version',
                ],
                'ruleTrackMessageExclusiveVersionInTitle'
            ),
        ],
    },
}

export const getErrors = validator.bind(null, errorRules)
export const getWarnings = validator.bind(null, warningRules)
