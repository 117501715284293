export const imageTypes = {
    jpg: 'jpg',
    jpeg: 'jpeg',
    png: 'png',
}

export const imageTypesDot = {
    jpg: `.${imageTypes.jpg}`,
    jpeg: `.${imageTypes.jpeg}`,
    png: `.${imageTypes.png}`,
}

const extended = 'image/'
export const imageTypesExtended = {
    jpg: `${extended}${imageTypes.jpg}`,
    jpeg: `${extended}${imageTypes.jpeg}`,
    png: `${extended}${imageTypes.png}`,
}
