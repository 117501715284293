import { connect } from 'react-redux'

import CreateReleaseBanner from 'components/CreateReleaseBanner/CreateReleaseBanner'
import { artistCanCreateRelease } from 'selectors/users'

const mapStateToProps = state => ({
    display: artistCanCreateRelease(state),
})

export default connect(mapStateToProps)(CreateReleaseBanner)
