import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import Frame from 'components/Frame/Frame'

export default class PromoWidget extends Component {
    static propTypes = {
        fetchPosts: PropTypes.func.isRequired,
        data: PropTypes.object,
    }

    componentDidMount() {
        this.props.fetchPosts()
    }

    handleClick = () => {
        const { url } = this.props.data

        if (url && window.matchMedia('(max-width: 992px)').matches) {
            if (this.shouldOpenInNewTab(url)) {
                window.open(url)
            } else {
                window.location.replace(url)
            }
        }
    }

    shouldOpenInNewTab = link => link.indexOf('http') === 0

    renderImage = (img, link) => {
        if (link) {
            const attrs = { href: link }

            if (this.shouldOpenInNewTab(link)) {
                attrs.target = '_blank'
            }

            return <a {...attrs}><img src={img} alt="" /></a>
        } else {
            return <img src={img} alt="" />
        }
    }

    render() {
        if (!this.props.data) {
            return null
        }

        const {
            title, file, text, url, moreText,
        } = this.props.data

        return (
            <section className="c-promoWidget" onClick={this.handleClick}>
                <Frame borderImageId="sun-2" contentTint="light">
                    {file && file.url && this.renderImage(file.url, url)}
                    <div className="inner">
                        <header>
                            <h1 className="h3">{title}</h1>
                        </header>

                        <p>{text}</p>

                        {url && moreText && (
                            <p>
                                <a
                                    href={url}
                                    target={this.shouldOpenInNewTab(url) ? '_blank' : '_self'}
                                    rel="noopener noreferrer"
                                >{moreText}
                                </a>
                            </p>
                        )}
                    </div>
                </Frame>
            </section>
        )
    }
}
