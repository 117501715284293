import { connect } from 'react-redux'
import OverviewProfile from 'components/OverviewProfile/OverviewProfile'
import { getDraftProfile } from 'actions/ProfileActions'
import { addPopup } from 'actions/PopupActions'
import { addNotification } from 'actions/NotificationActions'
import { artistSelector } from 'selectors/users'
import {
    draftProfileDataSelector,
    draftProfileLinksAsObjectSelector,
    isLoadedSelector,
} from 'selectors/draftProfile'
import { filteredReleasesSelector } from 'selectors/releases'
import privacyTypes from 'constants/PrivacyTypes'

const getData = (state) => {
    const {
        name,
        slug,
        hasPublicProfile,
        profileVisibility,
        videos,
        biography,
        tourDates,
        files: {
            hero,
            gallery,
        },
    } = draftProfileDataSelector(state)
    const linksData = draftProfileLinksAsObjectSelector(state)
    const comparer = key => key.toLowerCase().includes('profile')
    return {
        name,
        slug,
        isPublishedAsPublic: hasPublicProfile && profileVisibility === privacyTypes.public,
        releases: filteredReleasesSelector(state, 'live')
            .filter(release => release.linkfire && release.linkfire.url).length,
        profileLinks: Object.keys(linksData).filter(comparer).length,
        socialLinks: Object.keys(linksData).filter(key => !comparer(key)).length,
        videos: videos.length,
        biography: biography && !!biography.replace(/<[^>]*>?/gm, ''),
        tourDates: tourDates.length,
        gallery: gallery.length,
        hero: hero ? hero.url : '',
    }
}

const mapStateToProps = (state) => {
    const isLoaded = isLoadedSelector(state)
    const undefinedData = {
        name: undefined,
        slug: undefined,
        isPublishedAsPublic: false,
        releases: 0,
        profileLinks: 0,
        socialLinks: 0,
        videos: 0,
        biography: false,
        tourDates: 0,
        gallery: 0,
        hero: '',
    }
    const data = isLoaded ? getData(state) : undefinedData
    return {
        isLoaded,
        artistId: artistSelector(state).id,
        data,
    }
}

const mapDispatchToProps = {
    getDraftProfile,
    openPopup: addPopup,
    addNotification,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverviewProfile)
