import React from 'react'
import * as PropTypes from 'prop-types'
import Frame from 'components/Frame/Frame'
import './_mfaFrame.scss'

const MfaFrame = ({ children }) => (
    <Frame
        borderImageId="dune-1"
        borderFlipHorizontaly
        contentTint="light"
        className="c-mfa-frame"
    >
        {children}
    </Frame>
)

MfaFrame.propTypes = {
    children: PropTypes.node,
}

export default MfaFrame
