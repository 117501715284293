import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchLocales } from 'actions/LocaleActions'
import { makeLocalesSelector } from 'selectors/locales'
import Field from 'components/Field/Field'
import Select from 'components/Select/Select'

class LocaleSelectContainer extends Component {
    static propTypes = {
        elementName: PropTypes.string,
        label: PropTypes.string.isRequired,
        locales: PropTypes.array.isRequired,
        value: PropTypes.number,
        error: PropTypes.string,
        fetchLocales: PropTypes.func.isRequired,
        onChange: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.fieldRef = React.createRef()
        this.selectRef = React.createRef()
    }

    componentDidMount() {
        this.props.fetchLocales()
    }

    scrollToIt = () => {
        this.fieldRef.current.scrollToIt()
    }

    render() {
        const {
            label, locales, value, error,
        } = this.props

        const selectClassName = error ? 'select-error' : ''
        return (
            <Field
                ref={this.fieldRef}
                elementName={this.props.elementName}
                label={label}
                error={error || ''}
            >
                <Select
                    ref={this.selectRef}
                    disabled={locales.length === 0}
                    options={locales}
                    searchable
                    selectClassName={selectClassName}
                    value={value}
                    onChange={this.props.onChange}
                />
            </Field>
        )
    }
}

const mapStateToProps = (state, props) => ({
    locales: makeLocalesSelector(props.displayLanguages)(state),
})

const mapDispatchToProps = {
    fetchLocales,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LocaleSelectContainer)
