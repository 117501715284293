import * as types from 'constants/BackgroundColorsActionTypes'
import { CALL_API } from 'middleware/api'

export function fetchBackgroundColors() {
    return {
        [CALL_API]: {
            types: [types.FETCH_BACKGROUNDS_REQUEST, types.FETCH_BACKGROUNDS_SUCCESS, types.FETCH_BACKGROUNDS_FAILURE],
            payload: {
                method: 'get',
                endpoint: '/profile/colors',
            },
        },
    }
}
