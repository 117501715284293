import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import classnames from 'classnames'

/**
 * Inputs
 *
 * Various form elements
 *
 * Styleguide: components.input.textarea
 */

export default class extends Component {
    static propTypes = {
        /** Defaulted value */
        value: PropTypes.string,

        /** Placeholder text */
        placeholder: PropTypes.string,

        /** Disable the input */
        disabled: PropTypes.bool,

        /** Make it look like an error */
        error: PropTypes.bool,

        /** Change handler */
        onChange: PropTypes.func,

        /** Blur handler */
        onBlur: PropTypes.func,

        /** Focus handler */
        onFocus: PropTypes.func,

        /** Handler when pressing ENTER in input */
        onEnter: PropTypes.func,

        /** Number of rows */
        rows: PropTypes.number,

        /** Character limit */
        maxLength: PropTypes.number,
    };

    static defaultProps = {
        rows: 6,
    };

    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
    }

    onKeyUp() {
        return (e) => {
            e.preventDefault()

            const keyCode = e.keyCode || e.which

            if (keyCode === 13 && this.props.onEnter) {
                return this.props.onEnter(e)
            } else {
                return null
            }
        }
    }

    getValue() {
        return this.inputRef.current.value
    }

    setValue(value) {
        this.inputRef.current.value = value
    }

    render() {
        const {
            placeholder,
            value,
            disabled,
            onChange,
            onFocus,
            onBlur,
            rows,
            maxLength,
        } = this.props

        const className = classnames(
            'c-input',
            {
                error: !!this.props.error,
            }
        )

        return (
            <textarea
                ref={this.inputRef}
                rows={rows}
                placeholder={placeholder}
                className={className}
                defaultValue={value || ''}
                disabled={disabled}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyUp={this.onKeyUp()}
                maxLength={maxLength}
            />
        )
    }
}
