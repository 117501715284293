// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { of, EMPTY } from 'rxjs'
import {
    isEqual, uniq, concat, get,
} from 'lodash'
import {
    mergeMap,
    filter,
    takeUntil,
    catchError,
    debounceTime,
} from 'rxjs/operators'
import { combineReducers } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import {
    actionTypes as formActions,
    startSubmit,
    stopSubmit,
    change,
    blur,
    touch,
    getFormSyncErrors,
    getFormValues,
    destroy,
} from 'redux-form'

import {
    getErrors as getTrackErrors,
    getWarnings as getTrackWarnings,
    errorRules as trackErrorRulesMap,
} from 'validations/track'

import { makeAlbumTracksSelector } from 'selectors/tracks'

import { putTrack, patchTrack } from 'services/spinnup-api/track'

import * as trackActions from 'constants/TrackActionTypes'

import {
    selectTrack,
} from 'actions/TrackActions'

import {
    updateAsyncErrors,
    updateErrors,
    updateWarnings,
} from 'modules/forms'

import t from 'utilities/translate'
import {
    tracksFormRegex,
    getTrackForms,
    getTracksFormName,
    KEY,
} from 'modules/common/tracks'

const APP_PREFIX = 'spinnup'

// ///////////
// DETERMINISTIC ACTIONS
// ///////////

// already declare in constants files, not duckerise YET !!! (๑˃̵ᴗ˂̵)و

// ///////////
// ACTION CREATORS
// ///////////

// already declare in actionCreator file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و

// ///////////
// REDUCERS
// ///////////

// already declare in reducer file, not duckerise YET !!! (๑˃̵ᴗ˂̵)و
export default combineReducers({})

// ///////////
// SELECTORS
// ///////////

// TODO use selector to improve perf but no emergency yet ...
export const getTracksState = state => state[KEY]
export const getTracks = state => Object.values(getTracksState(state).tracks)
export const getById = (state, id) => getTracksState(state).tracks[id]

// ///////////
// NON DETERMINISTIC ACTIONS
// ///////////

export const REQUEST_SAVE = `${APP_PREFIX}/${KEY}/REQUEST_SAVE`
export const RECEIVE_SAVE_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_SUCCESS`
export const RECEIVE_SAVE_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_SAVE_FAILURE`
export const RECEIVE_PATCH_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_SUCCESS`
export const RECEIVE_PATCH_START_TIME_SUCCESS = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_START_TIME_SUCCESS`
export const RECEIVE_PATCH_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_FAILURE`
export const RECEIVE_PATCH_START_TIME_FAILURE = `${APP_PREFIX}/${KEY}/RECEIVE_PATCH_START_TIME_FAILURE`
export const SYNC_VALIDATE = `${APP_PREFIX}/${KEY}/SYNC_VALIDATE`
export const ASYNC_VALIDATE = `${APP_PREFIX}/${KEY}/ASYNC_VALIDATE`
export const DESTROY_TRACK_FORM = `${APP_PREFIX}/${KEY}/DESTROY_TRACK_FORM`

// ///////////
// ACTION CREATORS
// ///////////

export const requestSave = (track, field) => ({
    type: REQUEST_SAVE,
    payload: track,
    meta: {
        field,
    },
})

export const receiveSave = (updatedTrack, albumId) => ({
    type: RECEIVE_SAVE_SUCCESS,
    payload: updatedTrack,
    meta: {
        albumId,
    },
})

export const receiveSaveFailure = (error, { id }, field) => ({
    type: RECEIVE_SAVE_FAILURE,
    payload: error,
    meta: {
        id,
        field,
    },
})

export const receivePatch = (updatedTrack, albumId) => ({
    type: RECEIVE_PATCH_SUCCESS,
    payload: updatedTrack,
    meta: {
        albumId,
    },
})

export const receivePatchFailure = ({ errors, message }, { id }, field) => {
    const errorMsg = errors.isrc ? errors.isrc[0] : message

    return {
        type: RECEIVE_PATCH_FAILURE,
        payload: errorMsg,
        meta: {
            field,
            id,
        },
    }
}

export const receivePatchSampleStartTime = (updatedTrack, albumId) => ({
    type: RECEIVE_PATCH_START_TIME_SUCCESS,
    payload: updatedTrack,
    meta: {
        albumId,
    },
})

export const receivePatchSampleStartTimeFailure = ({ errors, message }, { id }, albumId, field) => {
    const errorMsg = errors ? errors.sample_start_time[0] : message

    return {
        type: RECEIVE_PATCH_START_TIME_FAILURE,
        payload: errorMsg,
        meta: {
            field,
            id,
            albumId,
        },
    }
}

export const syncValidate = (formId, field) => ({
    type: SYNC_VALIDATE,
    payload: formId,
    meta: {
        field,
    },
})

export const asyncValidate = formId => ({
    type: ASYNC_VALIDATE,
    payload: formId,
})

export const destroyTrackForm = formId => ({
    type: DESTROY_TRACK_FORM,
    payload: formId,
})

// ///////////
// EPICS
// ///////////
const requestSaveToStartSubmitEpic = action$ => action$.pipe(
    ofType(REQUEST_SAVE),
    mergeMap(({ payload: track }) => {
        const formName = getTracksFormName(track)
        return of(startSubmit(formName))
    })
)

const receiveSaveToStopSumitEpic = action$ => action$.pipe(
    ofType(RECEIVE_SAVE_SUCCESS, RECEIVE_SAVE_FAILURE),
    mergeMap(({ payload: track }) => {
        const formName = getTracksFormName(track)
        if (formName === 'tracks_undefined') {
            return EMPTY
        } else {
            return of(stopSubmit(formName))
        }
    })
)

const bySameId = id => action => id === action.payload.id
const bySameField = field => action => field === action.meta.field
const byFieldDifferentThan = field => action => field !== action.meta.field
const requestSaveToPutEpic = (action$, store, { ajax }) => action$.pipe(
    ofType(REQUEST_SAVE),
    debounceTime(2000),
    mergeMap(({
        payload: track,
        meta: { field },
    }) => {
        const state = store.value
        const { credentials } = state.auth

        let action = EMPTY
        if (field === 'isrc') {
            action = patchTrack(
                { track, field },
                credentials,
                ajax
            ).pipe(
                takeUntil(action$.pipe(
                    ofType(REQUEST_SAVE),
                    filter(bySameId(track.id)),
                    filter(bySameField(field))
                )),
                mergeMap(({ response }) => of(receivePatch(response, track.albumId))),
                catchError(({ response }) => of(receivePatchFailure(response, track, field)))
            )
        } else if (field === 'sampleStartTime') {
            action = patchTrack(
                { track, field },
                credentials,
                ajax
            ).pipe(
                takeUntil(action$.pipe(
                    ofType(REQUEST_SAVE),
                    filter(bySameId(track.id)),
                    filter(bySameField(field))
                )),
                mergeMap(({ response }) => of(receivePatchSampleStartTime(response, track.albumId))),
                catchError(({ response }) => of(receivePatchSampleStartTimeFailure(
                    response,
                    track,
                    track.albumId,
                    field
                )))
            )
        } else {
            return putTrack(track, credentials, ajax).pipe(
                takeUntil(
                    action$.pipe(
                        ofType(REQUEST_SAVE),
                        filter(bySameId(track.id)),
                        filter(byFieldDifferentThan('isrc'))
                    )
                ),
                mergeMap(({ response }) => of(receiveSave(response, track.albumId))),
                catchError(({ response }) => of(receiveSaveFailure(response, track, field)))
            )
        }

        return action
    })
)

export const createTracksSuccessToSelectEpic = (action$, store) => action$.pipe(
    ofType(trackActions.CREATE_TRACK_SUCCESS),
    mergeMap(({ meta: { albumId } }) => {
        const tracks = makeAlbumTracksSelector(albumId)(store.value)

        let action = EMPTY

        if (tracks.length === 1) {
            action = of(selectTrack(tracks[0].id))
        }

        return action
    })
)

const removeTrackToDestroyFormEpic = action$ => action$.pipe(
    ofType(trackActions.REMOVE_TRACK_SUCCESS),
    mergeMap(({ meta: { id } }) => {
        const formName = getTracksFormName({ id })
        return of(destroyTrackForm(formName))
    })
)

export const receivePatchFailureEpic = action$ => action$.pipe(
    ofType(RECEIVE_PATCH_FAILURE),
    mergeMap(({ payload: error, meta: { field, id } }) => of(updateAsyncErrors(getTracksFormName({ id }), {
        [field]: t(error),
    })))
)

export const changeHasLyricsToNoEpic = action$ => action$.pipe(
    ofType(formActions.CHANGE),
    filter(({ meta: { form: formId, field: fieldName }, payload: value }) => tracksFormRegex.exec(formId)
        && fieldName === 'hasLyrics'
        && value === false),
    mergeMap(({ meta: { form: formId } }) => [
        change(formId, 'lyrics', ''),
        blur(formId, 'lyrics'),

        change(formId, 'audioLocale', false),
        blur(formId, 'audioLocale'),

        change(formId, 'explicitLyrics', null),
        blur(formId, 'explicitLyrics'),
    ])
)

export const uploadFileResetCompositionTypeEpic = (action$, store) => action$.pipe(
    ofType(trackActions.UPLOAD_FILE_REQUEST),
    mergeMap(({ meta: { id: trackId } }) => {
        const state = store.value
        const track = getById(state, trackId)
        const formId = `tracks_${trackId}`
        const actions = []
        if (track.audio && track.audio.url) {
            // Only reset composition type when replacing existing audio
            actions.push(change(formId, 'compositionTypeId', null))
            actions.push(change(formId, 'compositionTypeId', null))
        }

        return actions
    })
)

// the form cannot be reinitialized, so the change has to be done that way
export const uploadFileSuccessEpic = action$ => action$.pipe(
    ofType(trackActions.UPLOAD_FILE_SUCCESS),
    mergeMap(({ meta: { id }, payload: { payload: { audio } } }) => [
        change(
            getTracksFormName({ id }),
            'audio',
            audio
        ),
        touch(
            getTracksFormName({ id }),
            'audio'
        ),
    ])
)

export const uploadFileFailureEpic = (action$, store) => action$.pipe(
    ofType(trackActions.UPLOAD_FILE_FAILURE),
    mergeMap(({ meta: { id }, error }) => {
        const state = store.value
        const formId = getTracksFormName({ id })

        const currentErrors = getFormSyncErrors(formId)(state)
        return [
            change(
                formId,
                'audio',
                null
            ),
            updateErrors(
                formId, {
                    ...currentErrors,
                    audio: error ? t(error[0]) : null,
                }
            ),
            touch(
                getTracksFormName({ id }),
                'audio'
            ),
        ]
    })
)

export const trackSyncValidateEpic = (action$, store) => action$.pipe(
    ofType(SYNC_VALIDATE),
    mergeMap(({ payload: formName, meta: { field } }) => {
        const state = store.value
        // cannot use the getFormNames selector, because it does not support multi dimensioned form
        const trackForms = getTrackForms(state)
        const value = getFormValues(formName)(state)

        const actions = []
        // we connot wait to have the action dispatched to know the next errors
        // we already have them here, the other alternative is to do an epic on UPDATE_SYNC_ERRORS
        // but if we do so, we will loose the field name we are working on right now, maybe not that a issue though
        // if this approach does not work, we should think about this epic
        const nextTrackErrorMap = {}
        if (field) {
            trackForms.forEach((trackForm) => {
                const currentValue = trackForm.values

                // Sometimes track forms don't clean up correctly and are either undefined or are missing all properties
                // other than songwriter/publisher, this needs to be fixed thoroughly via component lifecycle really
                if (currentValue && currentValue.id) {
                    const currentFormName = getTracksFormName(currentValue)
                    // /////// ERRORS
                    const currentErrors = trackForm.syncErrors
                    // because the destroy if preceeded by UNREGISTED or others, we could have empty form
                    const nextErrors = currentValue
                        ? getTrackErrors(currentValue, { formName: currentFormName, state })
                        : {}
                    const hasErrorDifferences = !isEqual(nextErrors, currentErrors)
                    // to avoid to dispatch for nothing, was creating perf issues
                    if (hasErrorDifferences) {
                        // Prevent missing audio error from directly overriding "other audio errors" (i.e.
                        // validation from the action). The action directly updates the error state, but
                        // then in uploadFileFailureEpic a touch event is triggered which triggers full
                        // revalidation and overrides the outside (action) audio error validation.
                        if (field === 'audio' && currentErrors && currentErrors.audio
                            && nextErrors.audio && nextErrors.audio === 'containerTrackUploadFileMissingText') {
                            nextErrors.audio = currentErrors.audio
                        }
                        actions.push(updateErrors(currentFormName, nextErrors))
                        nextTrackErrorMap[currentFormName] = nextErrors
                    }

                    // /////// WARNINGS
                    const currentWarnings = trackForm.syncWarnings
                    // because the destroy if preceeded by UNREGISTED or others, we could have empty form
                    const nextWarnings = currentValue
                        ? getTrackWarnings(currentValue, { formName: currentFormName, state })
                        : {}
                    const hasWarningDifferences = !isEqual(nextWarnings, currentWarnings)
                    // to avoid to dispatch for nothing, was creating perf issues
                    if (hasWarningDifferences) {
                        actions.push(updateWarnings(currentFormName, nextWarnings))
                    }
                } else {
                    console.warn('Found track form with no values ', trackForm)
                }
            })

            // TOUCH
            const errorRules = trackErrorRulesMap[field] || {}
            const touches = errorRules.touches || []
            // every field who need to be checked amoung all the tracks
            const concernedFields = uniq(concat([field], touches))

            concernedFields.forEach((currentField) => {
                const currentErrorRules = trackErrorRulesMap[currentField] || {}
                const touchLinkedFormsByError = currentErrorRules.touchLinkedFormsByError || []
                touchLinkedFormsByError.forEach((touchCondition) => {
                    // we are doing a loop again on trackform because we needed to wait that all the error are processed
                    trackForms.forEach((trackForm) => {
                        const currentValue = trackForm.values

                        if (currentValue && currentValue.id) {
                            const currentFormName = getTracksFormName(currentValue)
                            const nextErrors = nextTrackErrorMap[currentFormName] || {}
                            if (nextErrors[currentField] === touchCondition) {
                                actions.push(touch(currentFormName, currentField))
                            }
                        }
                    })
                })
            })
        } else if (value) { // to be sure we still have forms (could be detroy on leave edition release pages /edit)
            actions.push(updateErrors(formName, getTrackErrors(value, { formName, state })))
            actions.push(updateWarnings(formName, getTrackWarnings(value, { formName, state })))
            // if no field we validate ALL of them
            if (!field) {
                const fields = Object.keys(get(state, `form.${formName}.registeredFields`, {}))
                fields.forEach((currentField) => {
                    actions.push(touch(formName, currentField))
                })
            }
        }

        return actions
    })
)

export const changeCompositionTypeEpic = action$ => action$.pipe(
    ofType(formActions.CHANGE),
    filter(({
        meta: {
            form: formId,
            field: fieldName,
        },
    }) => tracksFormRegex.exec(formId) && fieldName === 'compositionTypeId'),
    mergeMap(({ meta: { form: formId } }) => [
        change(formId, 'compositionTermsAgreed', null),
        blur(formId, 'compositionTermsAgreed'),
    ])
)

export const changeEpic = action$ => action$.pipe(
    ofType(formActions.CHANGE),
    filter(({ meta: { form: formId } }) => tracksFormRegex.exec(formId)),
    mergeMap(({ meta: { form: formId, field } }) => of(syncValidate(formId, field)))
)

export const blurEpic = (action$, store) => action$.pipe(
    ofType(formActions.BLUR),
    filter(({ meta: { form: formId } }) => tracksFormRegex.exec(formId)),
    mergeMap(({ meta: { form: formId, field } }) => {
        const state = store.value
        const track = getFormValues(formId)(state)
        return of(requestSave(track, field))
    })
)

const destroyTrackFormEpic = (action$, store) => action$.pipe(
    ofType(DESTROY_TRACK_FORM),
    mergeMap(({ payload }) => {
        const state = store.value
        const reduxFormNames = Object.keys(state.form)
        const reduxReleaseFormNames = reduxFormNames.filter(reduxFormName => (
            reduxFormName === payload
        ))
        return reduxReleaseFormNames.map(reduxFormName => destroy(reduxFormName))
    })
)

export const epic = combineEpics(
    requestSaveToStartSubmitEpic,
    requestSaveToPutEpic,
    receiveSaveToStopSumitEpic,
    removeTrackToDestroyFormEpic,
    createTracksSuccessToSelectEpic,
    receivePatchFailureEpic,
    changeHasLyricsToNoEpic,
    changeCompositionTypeEpic,
    uploadFileResetCompositionTypeEpic,
    uploadFileSuccessEpic,
    uploadFileFailureEpic,
    trackSyncValidateEpic,
    destroyTrackFormEpic,
    blurEpic,
    changeEpic
)
