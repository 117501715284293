import React from 'react'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import Frame from 'components/Frame/Frame'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'

const ForcedPasswordResetEmailBlock = () => {
    const { t } = useLocaleContext()
    return (
        <div className="v-verify v-password-reset-required">
            <Frame
                borderImageId="dune-2"
                contentTint="light"
            >
                <div className="v-verify-content">
                    <header>
                        <h1 className="h3">{t('viewPasswordResetSubTitle')}</h1>
                    </header>

                    <TranslateMarkdown id="viewPasswordResetDescription" />
                    <TranslateMarkdown id="containerForgotFormDone" />
                </div>
            </Frame>
        </div>
    )
}

export default ForcedPasswordResetEmailBlock
