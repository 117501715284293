import React, { useEffect, useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import ArtistInformationContainer from 'containers/ArtistInformation/ArtistInformation'
import AccountSettingsContainer from 'containers/AccountSettings/AccountSettings'
import SocialAccountsContainer from 'containers/SocialAccounts/SocialAccounts'
import EmailSettingsContainer from 'containers/EmailSettings/EmailSettings'
import PasswordSettingsContainer from 'containers/PasswordSettings/PasswordSettings'
import PartnersWidget from 'containers/PartnersWidget/PartnersWidget'
import CrmPreferences from 'containers/CrmSubscriptions/CrmSubscriptions'

import Divider from 'components/Divider/Divider'
import CreateReleaseBanner from 'containers/CreateReleaseBanner/CreateReleaseBanner'
import scrollIntoView from 'utilities/scroll'
import { scrollUpToTop } from 'hooks/useScrollToTopEffect'
import { ROLE_ARTIST_ENHANCED } from 'constants/enums/Roles'

export const SettingsViewComponent = (props) => {
    const { location, user } = props
    useEffect(() => {
        if (location.state && location.state.anchor) {
            scrollIntoView(location.state.anchor)
        } else {
            scrollUpToTop()
        }
    }, [])

    const isEnhanced = useMemo(() => user.roles.indexOf(ROLE_ARTIST_ENHANCED) !== -1, [user])

    return (
        <div>
            { window.env.ARTIST_SETTINGS_INFORMATION
                ? (
                    <>
                        <ArtistInformationContainer />
                        <Divider imageId="spiral-2" />
                    </>
                ) : null }
            <AccountSettingsContainer />
            <Divider imageId="spiral-2" />
            <CrmPreferences />
            <Divider imageId="sun-2" />
            {
                isEnhanced ? (
                    <>
                        <SocialAccountsContainer />
                        <Divider imageId="water-2" />
                    </>
                ) : null
            }
            <EmailSettingsContainer />
            <Divider imageId="paint" />
            <PasswordSettingsContainer />

            <CreateReleaseBanner />
            <PartnersWidget />
        </div>
    )
}

SettingsViewComponent.propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
}

export default withRouter(SettingsViewComponent)
