import { isNil } from 'lodash'
import t from 'utilities/translate'

export const rules = {
    coverArtUrl: [
        {
            test: coverArtUrl => !isNil(coverArtUrl) && coverArtUrl.length > 0,
            message: t('ruleAlbumMessageCoverArt'),
            error: true,
        },
    ],
    coverArtUserConfirmed: [
        {
            test: coverArtUserConfirmed => !!coverArtUserConfirmed,
            message: t('ruleAlbumMessageCoverArtUserConfirmed'),
            error: true,
        },
    ],
}
