import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import {
    setWith, clone, get,
} from 'lodash'

import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Tooltip from 'components/Tooltip/Tooltip'
import t from 'utilities/translate'
import Field from 'components/Field/Field'
import Input from 'components/Inputs/Input'
import {
    translationLanguages,
    translationLanguageStringRequiredErrors,
    translationLanguageStrings,
} from 'constants/enums/TranslationLanguageEnums'

const TranslatableModal = (props) => {
    const {
        modalId,
        title,
        value,
        onClose,
        fieldName,
        headerTooltipTitle,
        headerTooltipText,
        onSave,
        initialTranslationValues,
    } = props

    const [translationStrings, setTranslationStrings] = useState(initialTranslationValues)

    const translationSave = () => {
        onSave(translationStrings)
        onClose()
    }

    const updateTranslation = (index, language) => (event) => {
        setTranslationStrings(
            setWith(clone(translationStrings), `${index}.${language}.value`, event.target.value, clone)
        )
    }

    const canSave = () => {
        const shouldSave = value.reduce((accumulator, originalValue, index) => {
            accumulator = accumulator && translationLanguages.reduce((accumulator2, language) => {
                const languageValue = get(translationStrings, `${index}.${language}.value`, null)
                if (languageValue === '' || languageValue === null) {
                    accumulator2 = accumulator2 && false
                }
                return accumulator2
            }, true)
            return accumulator
        }, true)
        return shouldSave
    }

    return (
        <Modal
            id={modalId}
            onClose={onClose}
            className="c-translatable-modal"
        >
            <header className="c-translatable-modal-header">
                <Tooltip className="c-translatable-modal-popover" title={headerTooltipTitle} text={headerTooltipText}>
                    <h2>{title}</h2>
                </Tooltip>
            </header>

            <div className="c-translatable-modal-content">
                {value.map((originalValue, index) => (
                    <div key={index}>
                        <span className="c-translatable-pill">
                            {(typeof originalValue === 'object') ? originalValue.label : originalValue }
                        </span>
                        {translationLanguages.map((language) => {
                            const languageValue = get(translationStrings, `${index}.${language}.value`, null)
                            const validationKey = translationLanguageStringRequiredErrors[language]
                            return (
                                <Field
                                    label={t(translationLanguageStrings[language])}
                                    key={`${index}-${language}`}
                                    error={!languageValue ? t(validationKey) : undefined}
                                >
                                    <Input
                                        className="c-input"
                                        type="text"
                                        name={fieldName}
                                        onChange={updateTranslation(index, language, originalValue)}
                                        value={languageValue}
                                        disabled={originalValue.isFixed}
                                    />
                                </Field>
                            )
                        })}
                    </div>
                ))}
            </div>

            <footer>
                <Button onClick={translationSave} disabled={!canSave()}>
                    {t('translationModalButtonText')}
                </Button>
            </footer>
        </Modal>
    )
}

TranslatableModal.propTypes = {
    modalId: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.array,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    initialTranslationValues: PropTypes.object,
    fieldName: PropTypes.string,
    headerTooltipTitle: PropTypes.string,
    headerTooltipText: PropTypes.string,
}

export default TranslatableModal
