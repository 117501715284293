import { createSelector } from 'reselect'
import {
    find, get, isEmpty, isNil, isNull, merge, omitBy,
} from 'lodash'

import t from 'utilities/translate'
import validate, { isValid } from 'utilities/validate'

import {
    getTrackFormIds, getTrackForms, getTracksFormName,
} from 'modules/common/tracks'

import { getAlbumSettingFormName } from 'modules/common/album'
import { getAlbumFormValues } from 'modules/album'

import { getAlbumReleaseFormName } from 'modules/release'
import { makeReleaseSelector } from 'selectors/releases'
import { makeTracksSelector } from 'selectors/tracks'
import { errors as trackRules } from '../rules/track'
import { rules as packageRules } from '../rules/package'
import { rules as settingsRules } from '../rules/settings'

function getSteps(
    release,
    tracks,
    formAlbumValue,
    isAlbumUpdating,
    isReleaseUpdating,
    isTrackChanging,
    isAlbumHasAsyncErrors,
    isAlbumHasSyncErrors,
    isTrackHasAsyncErrors,
    pathname,
    inReleaseFlow,
    state
) {
    if (!release || !release.album) return []

    // in case the redux-form is not init yet
    // the omit is to ensure the null value does not override
    // this fix will works until a form want to set a value to null
    const album = merge({}, release.album, omitBy(formAlbumValue, isNull))
    const { artists } = album

    const completedTracks = !isTrackHasAsyncErrors && tracks.length > 0 && !isTrackChanging && !tracks.some((track) => {
        const trackForm = state.form[getTracksFormName(track)] || {}

        const hasSyncErrorTrack = !isEmpty(omitBy(trackForm.syncErrors, isNil) || {})
        const hasAsyncErrorTrack = !isEmpty(omitBy(trackForm.asyncErrors, isNil) || {})

        const reduxFormValidationResult = hasSyncErrorTrack || hasAsyncErrorTrack
        const legacyValidationResult = !!Object.keys(validate(track, trackRules, state)).length
        return legacyValidationResult || reduxFormValidationResult
    })

    const completedSettings = artists.length > 0
        && isValid(album, settingsRules, state)
        && !isAlbumHasAsyncErrors && !isAlbumHasSyncErrors
    // Because the confirmation step can only be completed during the release flow
    // it cannot feed into the step validation for the overview page
    let activePackageRules
    if (inReleaseFlow) {
        activePackageRules = packageRules
    } else {
        activePackageRules = Object.keys(packageRules)
            .filter(k => k !== 'coverArtUserConfirmed')
            .reduce((obj, key) => {
                obj[key] = packageRules[key]
                return obj
            }, {})
    }

    const completedPackage = isValid(album, activePackageRules, state)
    const completedReview = !!release.expiresAt || release.hasPendingPayments

    const isTracksStepUpdating = isAlbumUpdating || isTrackChanging
    const isSettingsStepUpdating = isAlbumUpdating || isReleaseUpdating
    const isPackageStepUpdating = isAlbumUpdating

    const steps = [
        {
            key: 'tracks',
            href: `/edit/${release.id}/tracks`,
            label: t('selectorStepsLabelUpload'),
            completed: completedTracks,
            isUpdating: isTracksStepUpdating,
        },
        {
            key: 'settings',
            href: `/edit/${release.id}/settings`,
            label: t('selectorStepsLabelSettings'),
            completed: completedSettings,
            isUpdating: isSettingsStepUpdating,
        },
        {
            key: 'package',
            href: `/edit/${release.id}/package`,
            label: t('selectorStepsLabelPackage'),
            completed: completedPackage,
            isUpdating: isPackageStepUpdating,
        },
        {
            key: 'review',
            href: `/edit/${release.id}/review`,
            label: t('selectorStepsLabelPayment'),
            completed: completedReview,
            visible: false,
        },
    ]

    const currentStep = steps.find(step => step.href === pathname)
    const currentStepIndex = steps.indexOf(currentStep)

    return steps.map((step, index) => ({
        ...step,

        active: step === currentStep,
        error: index < currentStepIndex && !step.completed,
    }))
}

export const makeStepsSelector = (releaseId, albumId, inReleaseFlow = true) => {
    const trackSelector = makeTracksSelector(albumId)
    return createSelector(
        [
            makeReleaseSelector(releaseId),
            trackSelector,
            state => getAlbumFormValues(state),
            state => state.albums.isUpdating || state.albums.isFetching,
            state => state.releases.isUpdating || state.releases.isFetching,
            state => state.tracks.isUpdating
                || state.tracks.isFetching
                || state.tracks.isAdding
                || state.tracks.isRemoving
                || state.tracks.isCreatingTrack
                || state.tracks.isCreatingTracks,
            state => !isEmpty((state.form[getAlbumSettingFormName()] || {}).asyncErrors),
            (state) => {
                const { syncErrors } = (state.form[getAlbumSettingFormName()] || { syncErrors: { album: {} } })
                return syncErrors && syncErrors.album && !isEmpty(omitBy(syncErrors.album, isNil))
            },
            (state) => {
                // todo   edit release -> expand track -> save & close -> return to releases/all
                // todo   leads to a getTrackForms of an array containing an empty object for expanded tracks
                // todo   What is the lifecycle of the form data intended to be?
                const albumTrackForm = getTrackForms(state)
                    .filter(form => form.values && form.values.albumId === albumId)
                return albumTrackForm
                    .reduce(
                        (acc, formTrack) => {
                            const asyncErrors = formTrack.asyncErrors || {}
                            return acc || !isEmpty(asyncErrors)
                        },
                        false
                    )
            },
            () => window.location.pathname,
            () => inReleaseFlow,
            state => state,
        ],
        getSteps
    )
}

export const firstUncompleteStepHref = (releaseId, albumId) => createSelector(
    [
        makeStepsSelector(releaseId, albumId),
    ],
    steps => (find(steps, { completed: false }) || {}).href
)

// todo optimise into a selector
export const getTotalErrorsCount = state => [
    ...(getTrackFormIds(state) || []),
    getAlbumSettingFormName(state),
    getAlbumReleaseFormName(state),
]
    .reduce((errorCount, formName) => {
        let nextErrorCount = errorCount
        const form = state.form[formName] || {}

        const registeredFields = Object.keys(form.registeredFields || {})

        const visibleSyncErrors = registeredFields.reduce(
            (acc, field) => {
                const error = get(form.syncErrors, field)
                if (error) {
                    acc[field] = error
                }
                return acc
            },
            {}
        )
        const visibleAsyncErrors = registeredFields.reduce(
            (acc, field) => {
                const error = get(form.asyncErrors, field)
                if (error) {
                    acc[field] = error
                }
                return acc
            },
            {}
        )

        const errors = merge({}, visibleSyncErrors, visibleAsyncErrors)

        nextErrorCount += Object.values(errors).length

        return nextErrorCount
    }, 0)

export const anyStepsUploadingSelector = ownProps => (state) => {
    const tracksUploading = Object.values(state.tracks.tracks)
        .map(track => track.isUploading)
        .some(isUploading => isUploading)
    const albumUploading = state.albums.albums[ownProps.release.album.id].isUploading
    return tracksUploading || albumUploading
}
