import { device as d } from 'device.js'
import React from 'react'
import {
    HW_TRANSFER_BANK_ACCOUNT,
    HW_TRANSFER_BANK_CARD, HW_TRANSFER_PAYPAL_ACCOUNT, HW_TRANSFER_PREPAID_CARD, HW_TRANSFER_VENMO_ACCOUNT,
    HW_TRANSFER_WIRE_ACCOUNT,
} from 'constants/HyperWalletTransferTypes'
import { ITransferMethod, TrmParams, TrmType } from 'models/walletAccount'
import { LocaleState } from 'contexts/localisation/localeState'
import { TranslationKey } from 'contexts/localisation/translateUtilities'

const transferTypeLabelTranslationMap: Record<string, TranslationKey> = {
    [HW_TRANSFER_BANK_ACCOUNT]: 'earningsWithdrawSelectTransferBankAccount',
    [HW_TRANSFER_WIRE_ACCOUNT]: 'earningsWithdrawSelectTransferWireTransfer',
    [HW_TRANSFER_BANK_CARD]: 'earningsWithdrawSelectTransferBankCard',
    [HW_TRANSFER_PREPAID_CARD]: 'earningsWithdrawSelectTransferPrepaidCard',
    [HW_TRANSFER_PAYPAL_ACCOUNT]: 'earningsWithdrawSelectTransferPaypalAccount',
    [HW_TRANSFER_VENMO_ACCOUNT]: 'earningsWithdrawSelectTransferVenmoAccount',
    default: 'earningsWithdrawSelectUnknownTransferMethod',
}

type FormatParamMap = {
    // todo could not get this working providing a more specific type i.e. (params: TrmType<Type) => string
    [Type in TrmType]: any
}

const transferTypeFormatParamMap: FormatParamMap = {
    [HW_TRANSFER_BANK_ACCOUNT]: ({ bankAccountId }: TrmParams<typeof HW_TRANSFER_BANK_ACCOUNT>) => {
        const accountStr = (bankAccountId || '').toString()
        return accountStr.substr(-3).padStart(accountStr.length, '*')
    },
    [HW_TRANSFER_WIRE_ACCOUNT]: ({ bankAccountId }: TrmParams<typeof HW_TRANSFER_WIRE_ACCOUNT>) => {
        const accountStr = (bankAccountId || '').toString()
        return accountStr.substr(-3).padStart(accountStr.length, '*')
    },
    // Card number arrives at the client already masked for PSP compliance
    [HW_TRANSFER_BANK_CARD]: (params: TrmParams<typeof HW_TRANSFER_BANK_CARD>) => params.cardNumber.toString(),
    [HW_TRANSFER_PREPAID_CARD]: (params: TrmParams<typeof HW_TRANSFER_PREPAID_CARD>) => params.cardNumber.toString(),
    [HW_TRANSFER_PAYPAL_ACCOUNT]: (params: TrmParams<typeof HW_TRANSFER_PAYPAL_ACCOUNT>) => params.email,
}
const defaultFormat = (params: any) => JSON.stringify(params)

export const renderTrmLabel = (trm: ITransferMethod, t: LocaleState['t']) => {
    const detailsString = t(
        transferTypeLabelTranslationMap[trm.type] || transferTypeLabelTranslationMap.default,
        (transferTypeFormatParamMap[trm.type] || defaultFormat)(trm.params || {})
    )
    return d.desktop ? (
        <span className="select-multi-part-label">
            <span>{trm.name}</span>
            <span className="label-separator">-</span>
            <span>{detailsString}
            </span>
        </span>
    ) : `${trm.name} - ${detailsString}`
}

export const renderTrmLabelTextOnly = (trm: ITransferMethod, t: LocaleState['t']) => {
    const detailsString = t(
        transferTypeLabelTranslationMap[trm.type] || transferTypeLabelTranslationMap.default,
        transferTypeFormatParamMap[trm.type](trm.params || {})
    )
    return `${trm.name} - ${detailsString}`
}
