import { callSignedApiUrlGet, callSignedApiUrlPost } from 'actions/SignedUrlActions'
import { completeAuth } from 'actions/AuthActions'

export const types = {
    MFA_ENROL_REQUEST: 'MFA_ENROL_REQUEST',
    MFA_ENROL_SUCCESS: 'MFA_ENROL_SUCCESS',
    MFA_ENROL_FAILURE: 'MFA_ENROL_FAILURE',

    MFA_VERIFY_REQUEST: 'MFA_VERIFY_REQUEST',
    MFA_VERIFY_SUCCESS: 'MFA_VERIFY_SUCCESS',
    MFA_VERIFY_FAILURE: 'MFA_VERIFY_FAILURE',

    MFA_VERIFICATION_RESEND_REQUEST: 'MFA_VERIFICATION_RESEND_REQUEST',
    MFA_VERIFICATION_RESEND_SUCCESS: 'MFA_VERIFICATION_RESEND_SUCCESS',
    MFA_VERIFICATION_RESEND_FAILURE: 'MFA_VERIFICATION_RESEND_FAILURE',
}

export const enrolUserPhoneMfa = (signedUrl, phoneNumber) => callSignedApiUrlPost([
    types.MFA_ENROL_REQUEST,
    types.MFA_ENROL_SUCCESS,
    types.MFA_ENROL_FAILURE,
], signedUrl, {
    phoneNumber,
})

// Alias the complete auth function as it both serves to complete the auth workflow (see middleware/auth.js)
// but also makes sense in the context of MFA actions
export const validateUserPhoneMfaCode = completeAuth

export const resendUserPhoneMfaCode = signedUrl => callSignedApiUrlGet([
    types.MFA_VERIFICATION_RESEND_REQUEST,
    types.MFA_VERIFICATION_RESEND_SUCCESS,
    types.MFA_VERIFICATION_RESEND_FAILURE,
], signedUrl)
