import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import nl2br from 'utilities/nl2br'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

export default class Tooltip extends Component {
    static propTypes = {
        title: PropTypes.string,
        text: PropTypes.string.isRequired,
        children: PropTypes.node,
        formatted: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        title: null,
        formatted: false,
        children: null,
    };

    constructor(props) {
        super(props)

        this.state = { isOpen: false, openingNow: false }
    }

    componentDidMount() {
        document.addEventListener('click', this.hide, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.hide, false)
    }

    show = () => {
        this.setState({ isOpen: true, openingNow: true })
    }

    hide = (e) => {
        e.stopPropagation()
        const { openingNow } = this.state
        if (!openingNow) {
            this.setState({ isOpen: false })
        }
        this.setState({ openingNow: false })
    }

    render() {
        const {
            children,
            title,
            text,
            formatted,
            className,
        } = this.props

        const bodyClassname = classnames(
            'c-tooltip-popover-body',
            className
        )

        const { isOpen } = this.state

        return (
            <div className="c-tooltip">

                {children}

                <span className="c-tooltip-icon" onClick={isOpen ? this.hide : this.show}>
                    <FontAwesomeIcon icon="faQuestion" mask="faSquareFull" transform="shrink-6" />
                </span>

                { isOpen && (
                    <div className="c-tooltip-popover">

                        <div className={bodyClassname} onClick={this.show}>

                            <span className="c-tooltip-popover-close c-tooltip-icon" onClick={this.hide}>
                                <FontAwesomeIcon icon="faTimes" />
                            </span>

                            <p className="c-tooltip-popover-title">{title}</p>
                            { formatted ? (
                                <p className="c-tooltip-popover-text">
                                    <pre>
                                        { text }
                                    </pre>
                                </p>
                            ) : (
                                <p
                                    className="c-tooltip-popover-text"
                                    dangerouslySetInnerHTML={{ __html: nl2br(text) }}
                                />
                            ) }
                        </div>
                    </div>
                ) }

            </div>
        )
    }
}
