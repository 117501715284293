import { connect } from 'react-redux'
import { releaseTypeSelector } from 'selectors/releaseTypes'
import { userSelector } from 'selectors/users'
import { urlsSelector } from 'selectors/urls'
import { publishRelease } from 'actions/ReleaseActions'
import ReviewRelease from 'components/ReviewRelease/ReviewRelease'

const mapStateToProps = (state, props) => ({
    releaseType: releaseTypeSelector(state, props.release.id),
    user: userSelector(state),
    urls: urlsSelector(state, props),
})

const mapDispatchToProps = {
    publishRelease,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewRelease)
