import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Header from 'containers/Header/Header'
import Footer from 'containers/Footer/Footer'

import useScrollToTopEffect from 'hooks/useScrollToTopEffect'

import ManagePreferences from 'views/Crm/ManagePreferences/ManagePreferences'
import UnsubscribeFromAll from 'views/Crm/UnsubscribeFromAll/UnsubscribeFromAll'
import CompleteDoubleOptIn from 'views/Crm/CompleteDoubleOptIn/CompleteDoubleOptIn'

const Crm = () => {
    const url = '/crm'

    useScrollToTopEffect()

    return (
        <div className="l-start">
            <Header displayMenu={false} />

            <main>
                <Switch>
                    <Route path={`${url}/preferences/complete/:doiSecret`} render={() => <CompleteDoubleOptIn />} />
                    <Route path={`${url}/preferences/unsubscribe/:secret`} render={() => <UnsubscribeFromAll />} />
                    <Route path={`${url}/preferences/:secret`} render={() => <ManagePreferences />} />
                </Switch>
            </main>

            <Footer />
        </div>
    )
}

export default Crm
