import { connect } from 'react-redux'
import {
    reduxForm, getFormValues, change, stopAsyncValidation,
} from 'redux-form'
import EditProfile from 'components/EditProfile/Form'
import { openPreview } from 'actions/PreviewActions'
import {
    updateDraftProfile,
    initiatePublishingProfile,
    markSurveyAsViewed,
} from 'actions/ProfileActions'
import { fetchLocales } from 'actions/LocaleActions'
import { addPopup } from 'actions/PopupActions'
import { addNotification } from 'actions/NotificationActions'

import {
    draftProfileDataSelector,
    draftProfileLinksAsObjectSelector,
    draftProfileSelector,
} from 'selectors/draftProfile'
import { userSelector } from 'selectors/users'
import { gradientBackgroundsSelector } from 'selectors/backgroundColors'
import { fontsDataSelector } from 'selectors/fonts'
import { previewSelector } from 'selectors/preview'
import { releaseTypeSelector } from 'selectors/releaseTypes'
import { getLocaleLanguage } from 'selectors/locales'
import { filteredReleasesSelector } from 'selectors/releases'
import { PROFILE_FORM_NAME, TOUR_DATES_FORM_NAME } from 'constants/ProfileFormName'
import { DEFAULT_FONT_NAME } from 'constants/DefaultFontName'
import { validateSyncEditProfile } from 'validations/sync'
import { editProfileSections, editProfileFields } from 'constants/EditProfileStructure'

const EditProfileReduxForm = reduxForm({
    form: PROFILE_FORM_NAME,
    validate: values => validateSyncEditProfile(values),
    enableReinitialize: true,
    destroyOnUnmount: true,
})(EditProfile)

const checkForErrorsInArray = array => array.reduce((innerAcc, innerKey) => (innerKey ? true : innerAcc), false)
const getFieldNamesWithErrors = (errors = {}) => Object.keys(errors).reduce((acc, key) => {
    if (Array.isArray(errors[key])) {
        return checkForErrorsInArray(errors[key]) ? [...acc, key] : acc
    }
    return errors[key] ? [...acc, key] : acc
}, [])

const mergeErrors = (
    sync = {},
    async = {},
    upload = {}
) => {
    const syncErrors = getFieldNamesWithErrors(sync)
    const asyncErrors = getFieldNamesWithErrors(async)
    const uploadErrors = Object.keys(upload).reduce((acc, key) => (upload[key] ? [...acc, key] : acc), [])
    return [...syncErrors, ...asyncErrors, ...uploadErrors]
}

const checkErrorsForSections = errors => (
    Object.keys(editProfileSections).reduce((acc, sectionName) => {
        const thisSectionFields = editProfileFields[sectionName]
        const hasError = errors.reduce((innerAcc, fieldWithError) => (
            Object.keys(thisSectionFields).find(key => key === fieldWithError) ? true : innerAcc
        ), false)
        return ({
            ...acc,
            [sectionName]: hasError,
        })
    }, {})
)

const mapStateToProps = (state) => {
    const user = userSelector(state)
    const { artist, localeId } = user
    const { id } = artist
    const {
        isSubmitting,
        isPublishing,
        isProcessingLogo,
        isProcessingHero,
        isFetchingDsp,
        uploadGalleryCounter,
        removeGalleryCounter,
        logoError,
        heroError,
        galleryError,
    } = draftProfileSelector(state)
    const {
        updatedAt,
        biography,
        profileVisibility,
        name,
        slug,
        languageId,
        countryId,
        city,
        heroPlacement,
        backgroundColor,
        font,
        videos,
        releases,
        hasPublicProfile,
    } = draftProfileDataSelector(state)
    const linksData = draftProfileLinksAsObjectSelector(state)

    let bgColorId = backgroundColor && backgroundColor.id
    const gradients = gradientBackgroundsSelector(state)
    if (!bgColorId && gradients.length) {
        bgColorId = gradients[0].id
    }

    let fontId = font && font.id
    const fontOptions = fontsDataSelector(state)
    if (!fontId && fontOptions.length) {
        const defaultFont = fontOptions.find(fontOption => fontOption.name === DEFAULT_FONT_NAME)
        fontId = defaultFont ? defaultFont.id : fontOptions[0].id
    }

    const initialFormVideos = () => [...new Array(4)].map((_, index) => videos[index] || '')

    const initialFormReleases = () => filteredReleasesSelector(state, 'live')
        .filter(release => release.linkfire && release.linkfire.url)
        .map(release => ({
            id: release.id,
            title: release.album.title,
            releaseType: releaseTypeSelector(state, release.id).name,
            checked: !!releases.find(releaseId => releaseId === release.id),
        }))

    const { isLoaded: isTokenLoaded } = previewSelector(state)
    const isProcessingGallery = !!uploadGalleryCounter || !!removeGalleryCounter
    const isProcessingFiles = isProcessingLogo || isProcessingHero || isProcessingGallery
    const editProfileSyncErrors = state.form[PROFILE_FORM_NAME] && state.form[PROFILE_FORM_NAME].syncErrors
    const editProfileAsyncErrors = state.form[PROFILE_FORM_NAME] && state.form[PROFILE_FORM_NAME].asyncErrors
    const editProfileUploadErrors = {
        logo: logoError,
        hero: heroError,
        gallery: galleryError,
    }
    const editProfileErrors = mergeErrors(
        editProfileSyncErrors,
        editProfileAsyncErrors,
        editProfileUploadErrors
    )
    const tourDatesAsyncErrors = state.form[TOUR_DATES_FORM_NAME] && state.form[TOUR_DATES_FORM_NAME].asyncErrors
    const hasTourDatesSectionError = !!getFieldNamesWithErrors(tourDatesAsyncErrors).length
    return {
        // redux-form
        initialValues: {
            updatedAt,
            id,
            profileVisibility,
            name,
            slug,
            languageId: languageId || getLocaleLanguage(localeId)(state),
            countryId,
            city,
            heroPlacement,
            bgColorId,
            fontId,
            videos: initialFormVideos(),
            ...linksData,
            releases: initialFormReleases(),

        },
        biography,
        hasPublicProfile,
        isSubmitting,
        isPublishing,
        isTokenLoading: !isTokenLoaded,
        isLookingUpDsp: state.dspLookup.isLookingUp,
        isProcessingFiles,
        isFetchingDsp,
        formValues: getFormValues(PROFILE_FORM_NAME)(state),
        sectionsWithCheckedErrors: {
            ...checkErrorsForSections(editProfileErrors),
            [editProfileSections.tourDates]: hasTourDatesSectionError,
        },
        user: state.users.user,
    }
}

const mapDispatchToProps = {
    updateDraftProfile,
    initiatePublishingProfile,
    openPreview,
    change,
    setValidationError: stopAsyncValidation,
    openPopup: addPopup,
    addNotification,
    markSurveyAsViewed,
    fetchLocales,
}

const EditProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProfileReduxForm)

export default EditProfileContainer
