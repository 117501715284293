import * as types from 'constants/PopupActionTypes'

const initialState = []

const generateID = () => `_${Math.random().toString(36).substr(2, 9)}`

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_POPUP:
            return [
                ...state,
                {
                    id: generateID(),
                    ...action.payload,
                },
            ]
        case types.REMOVE_POPUP:
            return state.filter(popup => popup.id !== action.payload.id)
        default:
            return state
    }
}
