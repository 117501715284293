import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/Inputs/Input'
import t from 'utilities/translate'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Toggle from 'components/Toggle/Toggle'

class SongwriterPublisherInput extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.props.value.songwriter !== this.inputRef.current.getValue()) {
            // The Input class is an uncontrolled component (value is not bound to react state) so we need to set this
            // manually if modified via an 'outside prop' (not the change -> prop cycle)
            this.inputRef.current.setValue(this.props.value.songwriter)
        }
    }

    render() {
        const { value, onChange } = this.props

        const handleChange = (name, pubHouse, hasPublisherDetail) => onChange({
            person: {
                name,
            },
            publishingHouse: pubHouse,
        }, hasPublisherDetail)

        const handleChangeHasPublisherEvent = hasPub => (event) => {
            event.preventDefault()
            handleChange(value.songwriter, '', hasPub)
        }

        return (
            <div className="c-fc-songwriter-publisher-input">
                <div className="c-fi-songwriter-input">
                    <Input
                        ref={this.inputRef}
                        type="text"
                        value={value.songwriter}
                        placeholder={t('modalAddPublisherSongwriterNamePlaceholder')}
                        onChange={(event) => {
                            event.preventDefault()
                            handleChange(event.currentTarget.value, value.publisher, value.hasPublisher)
                        }}
                    />
                </div>
                <div className="c-fi-publisher-input">
                    {!value.hasPublisher
                        ? (
                            <Toggle
                                value
                                options={[
                                    {
                                        label: t('genericYes'),
                                        value: false,
                                        onClick: handleChangeHasPublisherEvent(true),
                                    },
                                    {
                                        label: t('genericNo'),
                                        value: value.hasPublisher === false,
                                        onClick: handleChangeHasPublisherEvent(false),
                                    },
                                ]}
                            />
                        )
                        : (
                            <span className="input-close">
                                <span className="close-icon" onClick={handleChangeHasPublisherEvent(null)}>
                                    <FontAwesomeIcon icon="faTimes" />
                                </span>
                                <Input
                                    type="text"
                                    placeholder={t('modalAddPublisherHouseNamePlaceholder')}
                                    value={value.publisher}
                                    onChange={(event) => {
                                        event.preventDefault()
                                        handleChange(value.songwriter, event.currentTarget.value, value.hasPublisher)
                                    }}
                                />
                            </span>
                        )}
                </div>
            </div>
        )
    }
}

SongwriterPublisherInput.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default SongwriterPublisherInput
