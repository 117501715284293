import { reduxForm, reset, stopAsyncValidation } from 'redux-form'
import { connect } from 'react-redux'
import EditProfileTourDatesForm from 'components/EditProfileTourDatesForm/EditProfileTourDatesForm'
import { addTour } from 'actions/ProfileActions'
import { TOUR_DATES_FORM_NAME } from 'constants/ProfileFormName'
import t from 'utilities/translate'
import { isEmpty } from 'validations/rules'

const validate = (values) => {
    const errors = {
        venue: isEmpty(t('formEditProfileVenueRequired'))(values.venue),
        city: isEmpty(t('formEditProfileCityRequired'))(values.city),
        countryId: isEmpty(t('formEditProfileCountryRequired'))(values.countryId),
    }
    return errors
}

const EditProfileTourDatesReduxForm = reduxForm({
    form: TOUR_DATES_FORM_NAME,
    validate,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(EditProfileTourDatesForm)

const mapStateToProps = (state) => {
    const { isAddingNewTourDate } = state.tourDatesForm
    return {
        isAddingNewTourDate,
    }
}

const mapDispatchToProps = {
    addTour,
    reset,
    setValidationError: stopAsyncValidation,
}

const EditProfileTourDatesContainer = connect(mapStateToProps, mapDispatchToProps)(EditProfileTourDatesReduxForm)

export default EditProfileTourDatesContainer
