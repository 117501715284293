import { createSelector } from 'reselect'

export const countriesSelector = state => state.countries.countries

export const isFetchingCountries = state => state.countries.isFetching

export const getCountrySelectOptions = (noCountry = null) => createSelector(
    countriesSelector,
    (countries) => {
        const newCountries = countries.map(country => ({
            value: country.id,
            label: country.countryName,
        })).toArray().sort((a, b) => (a.label > b.label ? 1 : -1))

        if (newCountries.length && noCountry) {
            return [{
                value: null,
                label: noCountry,
            }, ...newCountries]
        }

        return newCountries
    }
)
