import React, {
    ChangeEvent, useEffect, useLayoutEffect, useState,
} from 'react'
import * as PropTypes from 'prop-types'
import { connect, ConnectedProps } from 'react-redux'
import { Field as ReduxField, reduxForm, InjectedFormProps } from 'redux-form'
import { ReduxFormTextField } from 'components/common/forms/ReduxFormTextField'
import {
    doesNotMatchRegex,
    isGreaterThanNumber,
    isGreaterThanOrEqualToNumber,
    isLessThanNumber,
    isLessThanOrEqualToNumber,
    required,
} from 'validations/rules'
import Translate from 'components/Translate/translate'
import Button from 'components/Button/Button'
import { ReduxFormSelectField } from 'components/common/forms/ReduxFormSelectField'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import {
    HW_TRANSFER_PAYPAL_ACCOUNT,
    HW_TRANSFER_WIRE_ACCOUNT,
} from 'constants/HyperWalletTransferTypes'
import scrollIntoView from 'utilities/scroll'
import TrmWithdrawFeeLabel from 'components/EarningsWithdrawFeesDisplay/TrmWithdrawFeeLabel'
import { renderTrmLabel, renderTrmLabelTextOnly } from 'components/EarningsWalletBalanceWithdraw/trmLabelFormatter'
import { addPopup } from 'actions/PopupActions'
import { confirmationPopup } from 'constants/PopupTypes'
import { cancel as popupButtonCancel, confirm as popupButtonConfirm } from 'constants/PopupButtons'
import {
    camelizeKey,
    HW_TRM_TYPE_FEE_KEY_MAP,
} from 'components/EarningsWithdrawFeesDisplay/WithdrawFeesDisplay'
import { AppStoreDispatch } from 'store/store-types'
import { BalanceState } from 'reducers/wallet'
import { ITransferMethod, TrmType } from 'models/walletAccount'

const FORM_NAME = 'balance-withdraw'
const fieldRequired = required('formInputFieldRequired')
const amountIsNumber = doesNotMatchRegex(/^\d*(\.\d{1,2})?$/, 'earningsWithdrawFormInvalidAmount')
const amountIsBeyondMaxWithdrawal = isGreaterThanOrEqualToNumber(50000, 'earningsWithdrawFormAmountTooLarge')

const HW_WIRE_MIN_WITHDRAWAL_EUR = 35

type BalanceWithdrawGivenProps = {
    balance: Partial<BalanceState>
    availableTransferMethods: ITransferMethod[]
    minWithdrawalAmount: number
    isWithdrawing?: boolean
    createNewTransferMethod: () => void
}

export type BalanceWithdrawFormState = {
    withdrawMethod: string
    withdrawAmount: number
    password: string
}

const mapDispatchToProps = (dispatch: AppStoreDispatch) => ({
    showConfirmationModal: (
        amount: number,
        trmLabel: string,
        onAccept: () => void
    ) => dispatch(addPopup({
        type: confirmationPopup,
        title: 'earningsWithdrawConfirmationModalTitle',
        text: {
            translation: 'earningsWithdrawConfirmationModalBody',
            // Translate Markdown automatically picks up links and emails and renders them as link nodes. Wrapping
            // @ with a node prevents the link rendering and doesn't affect output styling
            params: [amount, trmLabel.replace('@', '<span>@</span>')],
            isMarkupText: true,
        },
        textIsComponent: true,
        onAccept,
        acceptButtonText: popupButtonConfirm,
        closeButtonText: popupButtonCancel,
    })),
})
const connector = connect(null, mapDispatchToProps)

type BalanceWithdrawConnectedProps = ConnectedProps<typeof connector> & BalanceWithdrawGivenProps
type BalanceWithdrawProps = BalanceWithdrawConnectedProps &
InjectedFormProps<BalanceWithdrawFormState, BalanceWithdrawConnectedProps>

export const BalanceWithdrawComponent: React.FC<BalanceWithdrawProps> = ({
    balance,
    availableTransferMethods,
    minWithdrawalAmount,
    isWithdrawing,
    createNewTransferMethod,
    showConfirmationModal,
    invalid,
    pristine,
    initialize,
    handleSubmit,
}) => {
    const { t } = useLocaleContext()

    const buildAmountValidators = (
        userMinAmount: string | number,
        balanceAmount: string | number,
        trmType: TrmType,
        selectedTransferMethod: ITransferMethod
    ) => {
        // Default to userMinAmount until a transfer method is selected
        let minAmount = userMinAmount
        if (selectedTransferMethod.currency) {
            const currencyKey = camelizeKey(selectedTransferMethod.currency)
            const feeTypeKey = HW_TRM_TYPE_FEE_KEY_MAP[selectedTransferMethod.type] || ''
            if (selectedTransferMethod.type === HW_TRANSFER_WIRE_ACCOUNT) {
                minAmount = '11.50'
            } else if (selectedTransferMethod.type === HW_TRANSFER_PAYPAL_ACCOUNT) {
                minAmount = '0.85'
            } else if (selectedTransferMethod.fees[feeTypeKey]) {
                minAmount = selectedTransferMethod.fees[feeTypeKey][currencyKey]
            }
        }
        return {
            newMinValidation: isLessThanOrEqualToNumber(
                minAmount,
                t('earningsWithdrawFormInvalidAmountTransactionFee', minAmount)
            ),
            newMaxValidation: isGreaterThanNumber(balanceAmount, 'earningsWithdrawFormAmountGreaterThanBalance'),
        }
    }

    const buildSelectionState = (transferMethod: ITransferMethod | { type: '' } | null, amount: number) => ({
        transferMethod: transferMethod || { type: '' },
        amount,
        onConfirm: () => {
            (handleSubmit as any)()
        },
    })

    const [amountValidators, setAmountValidators] = useState({
        min: isLessThanNumber(minWithdrawalAmount, 'earningsWithdrawFormInvalidAmount'),
        max: isGreaterThanNumber(balance.amount, 'earningsWithdrawFormAmountGreaterThanBalance'),
    })
    const [selectionState, setFormSelectionState] = useState(buildSelectionState(null, 0))
    const selectValues = availableTransferMethods.map(trm => ({
        label: renderTrmLabel(trm, t),
        value: trm.ref,
    }))

    useEffect(() => {
        const defaultMethod = availableTransferMethods.length === 1 ? availableTransferMethods[0] : null

        setFormSelectionState(buildSelectionState(defaultMethod, 0))
        initialize({
            withdrawMethod: defaultMethod ? defaultMethod.ref : undefined,
        })
    }, [])

    useLayoutEffect(() => {
        scrollIntoView('v-balance')
    }, [])

    useEffect(() => {
        const withdrawType = selectionState.transferMethod.type

        if (withdrawType !== '') {
            const { newMinValidation, newMaxValidation } = buildAmountValidators(
                withdrawType === HW_TRANSFER_WIRE_ACCOUNT ? HW_WIRE_MIN_WITHDRAWAL_EUR : minWithdrawalAmount,
                balance.amount || '0',
                withdrawType,
                selectionState.transferMethod
            )
            setAmountValidators({
                min: newMinValidation,
                max: newMaxValidation,
            })
        }
    }, [selectionState.transferMethod.type, balance.amount, minWithdrawalAmount])

    return (
        <>
            <form
                onSubmit={(evt) => {
                    evt.preventDefault()
                    if (selectionState.transferMethod.type !== '') {
                        showConfirmationModal(
                            selectionState.amount,
                            renderTrmLabelTextOnly(selectionState.transferMethod, t),
                            selectionState.onConfirm
                        )
                    }
                }}
                autoComplete="off"
            >
                <ReduxField
                    name="withdrawMethod"
                    component={ReduxFormSelectField}
                    labelId="earningsWithdrawLabelSelectTransferMethod"
                    fieldClassName="c-field-withdrawal-method"
                    allValues={selectValues}
                    validate={[fieldRequired]}
                    placeholder={t('earningsWithdrawDropdownPlaceholder')}
                    disabled={isWithdrawing}
                    onChange={(trmRef: any) => {
                        const newMethod = availableTransferMethods.find(trm => trm.ref === trmRef)
                        setFormSelectionState(buildSelectionState(
                            newMethod || { type: '' },
                            selectionState.amount
                        ))
                    }}
                    disableSearch
                />
                <div className="c-withdraw-create-new-method">
                    <Button isLink onClick={createNewTransferMethod}>
                        <FontAwesomeIcon icon="faPlus" />
                        <Translate id="earningsWithdrawLabelCreateTransferMethod" />
                    </Button>
                </div>
                <ReduxField
                    name="withdrawAmount"
                    component={ReduxFormTextField}
                    labelId="earningsWithdrawLabelAmount"
                    placeholder={t('earningsWithdrawPlaceholderAmount')}
                    validate={[
                        fieldRequired, amountIsNumber, amountValidators.min,
                        amountValidators.max, amountIsBeyondMaxWithdrawal,
                    ]}
                    onChange={(evt: ChangeEvent<any>) => {
                        setFormSelectionState(
                            buildSelectionState(selectionState.transferMethod, evt.currentTarget.value)
                        )
                    }}
                    disabled={isWithdrawing}
                    isNumeric
                    autocomplete="off"
                />
                <ReduxField
                    name="password"
                    component={ReduxFormTextField}
                    labelId="formInputLabelPassword"
                    validate={[fieldRequired]}
                    disabled={isWithdrawing}
                    isPassword
                    autocomplete="new-password"
                />
                <Button
                    isSubmit
                    isLoading={isWithdrawing}
                    disabled={isWithdrawing || invalid || pristine}
                >
                    <Translate id="earningsWithdrawBtnWithdraw" />
                </Button>
                {
                    selectionState.transferMethod.type !== ''
                        ? <TrmWithdrawFeeLabel transferMethod={selectionState.transferMethod} />
                        : null
                }
            </form>
        </>
    )
}

BalanceWithdrawComponent.propTypes = {
    balance: PropTypes.any.isRequired,
    minWithdrawalAmount: PropTypes.number.isRequired,
    availableTransferMethods: PropTypes.array.isRequired,
    createNewTransferMethod: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.func.isRequired,
    isWithdrawing: PropTypes.bool,
}

// todo as any is a hack as the redux form injected props would not rationalise correctly
export default connector(reduxForm<BalanceWithdrawFormState, BalanceWithdrawConnectedProps>({
    form: FORM_NAME,
})(BalanceWithdrawComponent))
