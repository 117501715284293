import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import * as PropTypes from 'prop-types'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

const Collapse = ({
    children,
    title,
    hasError,
    shouldForceOpen = false,
}) => {
    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => {
        if (!isExpanded && shouldForceOpen) {
            setIsExpanded(true)
        }
    }, [shouldForceOpen])

    const toggleCollapse = () => {
        const isNowExpanded = isExpanded
        setIsExpanded(!isNowExpanded)
    }

    return (
        <div>
            <div className={classnames('c-collapse', { hasError })}>
                <div onClick={toggleCollapse}>
                    <div className="c-collapse-title">
                        <div className="c-collapse-title-wrapper">
                            {title}
                        </div>
                        <div className={classnames('c-collapse-arrow', {
                            'c-arrow-down': isExpanded,
                        })}
                        >
                            <FontAwesomeIcon icon="faAngleDown" />
                        </div>
                    </div>
                </div>
                <div className={classnames('c-collapse-item', { 'c-collapse-item-expanded': isExpanded })}>
                    <div className="c-collapse-content">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

Collapse.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    hasError: PropTypes.bool.isRequired,
    shouldForceOpen: PropTypes.bool,
}

export default Collapse
