import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Field as ReduxField } from 'redux-form'
import classnames from 'classnames'

import ImageDropzone from 'components/Dropzone/ImageDropzone'
import Frame from 'components/Frame/Frame'

import Artwork from 'containers/Artwork/Artwork'
import TrackUploadProgress from 'containers/TrackUpload/TrackUploadProgress'

import t, { tmarkdown } from 'utilities/translate'
import { scrollUpToTop } from 'hooks/useScrollToTopEffect'
import Field from 'components/Field/Field'
import Checkbox from 'components/Checkbox/Checkbox'

export default class ReleasePackageForm extends Component {
    static propTypes = {
        album: PropTypes.object.isRequired,
        uploadCover: PropTypes.func.isRequired,
        coverValidationError: PropTypes.func.isRequired,
        isUploadingArtwork: PropTypes.bool.isRequired,
        uploadProgress: PropTypes.number.isRequired,
        confirmCover: PropTypes.func.isRequired,
        unconfirmCover: PropTypes.func.isRequired,
    };

    componentDidMount() {
        scrollUpToTop()
    }

    onCoverUpload = (file) => {
        const {
            album,
            uploadCover,
            coverValidationError,
        } = this.props

        const image = new Image()
        const url = (window.URL && window.URL.createObjectURL)
            ? window.URL
            : (window.webkitURL && window.webkitURL.createObjectURL ? window.webkitURL : null)

        image.onload = () => {
            const validationErrors = this.validateCoverImage(image)
            if (validationErrors.length === 0) {
                uploadCover(album.id, file)
            } else {
                coverValidationError(album.id, validationErrors)
            }
        }
        image.onerror = () => {
            coverValidationError(album.id, ['ERROR_INVALID_ARTWORK_FILE_TYPE'])
        }
        image.src = url.createObjectURL(file)
    }

    validateCoverImage = (image) => {
        if (image.width !== image.height) {
            return ['ERROR_INVALID_DIMENSIONSV2']
        } else if (image.width < window.env.COVER_ART_MIN_SIZE) {
            return ['ERROR_INVALID_DIMENSIONS_TOO_SMALLV2']
        } else if (image.width > window.env.COVER_ART_MAX_SIZE) {
            return ['ERROR_INVALID_DIMENSIONS_TOO_LARGEV2']
        }

        return []
    }

    getErrorMessage = (album) => {
        const {
            COVER_ART_MIN_SIZE: coverSizeMin,
            COVER_ART_MAX_SIZE: coverSizeMax,
        } = window.env

        const error = album.uploadErrors[0]
        let message

        switch (error) {
            case 'ERROR_INVALID_DIMENSIONSV2':
            case 'ERROR_INVALID_DIMENSIONS_TOO_SMALLV2':
                // Duplicate params because the translation has 2 placeholders
                message = t(error, coverSizeMin, coverSizeMin)
                break

            case 'ERROR_INVALID_DIMENSIONS_TOO_LARGE2':
                // Duplicate params because the translation has 2 placeholders
                message = t(error, coverSizeMax, coverSizeMax)
                break

            default:
                message = t(error)
        }

        return message
    }

    renderReleaseCoverArt = ({
        input: {
            value,
            // the change and blur are not called here
            // it is done via an epic on FETCH_ALBUM_ARTWORK_SUCCESS
        },
        meta: {
            error,
            touched,
        },
        album,
    }) => {
        const { isUploadingArtwork, uploadProgress } = this.props
        const isUploadError = !!album.uploadErrors.length
        const hasError = isUploadError || !!(touched && error)
        const hasConfirmationError = album.packageErrorMessages
            && !!album.packageErrorMessages.coverArtUserConfirmed
            && !!album.packageErrorMessages.coverArtUserConfirmed.error

        const shouldDisplaySpinner = isUploadingArtwork && !hasError

        return (
            <ImageDropzone
                {...this.props}
                hasError={hasError}
                hasExternalError={hasConfirmationError}
                isEmpty={!value}
                onDrop={this.onCoverUpload}
                isLoading={shouldDisplaySpinner}
                progress={uploadProgress}
                background={
                    (value && !shouldDisplaySpinner) ? (
                        <div className="c-release-package-artwork-wrapper">
                            <Artwork
                                album={album}
                                defaultImg=""
                            />
                        </div>
                    ) : (
                        null
                    )
                }
            >
                {shouldDisplaySpinner ? (
                    null
                ) : (
                    <div>
                        <p
                            className={classnames(
                                'c-image-dropzone-title',
                                'h4'
                            )}
                        >
                            {t('componentArtCoverFormDropText')}
                        </p>

                        {hasError ? (
                            <div className="c-dropzone-image-error-wrapper c-profile-settings-dropzone-error-wrapper">
                                <span className="c-profile-settings-dropzone-error-reason">
                                    {this.getErrorMessage(album)}
                                </span>
                            </div>
                        ) : (
                            null
                        )}
                    </div>
                )}
            </ImageDropzone>
        )
    }

    renderConfirmationCheckbox = ({
        input: {
            value,
        },
        album,
    }) => {
        const confirmationError = album.packageErrorMessages
            && !!album.packageErrorMessages.coverArtUserConfirmed
            && album.packageErrorMessages.coverArtUserConfirmed.error
        const {
            confirmCover,
            unconfirmCover,
        } = this.props

        return (
            <Field error={confirmationError || undefined}>
                <Checkbox
                    label={t('componentArtCoverLabelUserConfirmation')}
                    checked={value}
                    onChange={({ target: { checked: newValue } }) => {
                        if (newValue) {
                            confirmCover(album.id, album)
                        } else {
                            unconfirmCover(album.id, album)
                        }
                    }}
                    controlled
                />
            </Field>
        )
    }

    render() {
        const {
            album,
            isUploadingArtwork,
        } = this.props

        const coverArtMin = window.env.COVER_ART_MIN_SIZE
        const guidelines = tmarkdown('componentArtCoverFormGuideLinesV2', coverArtMin, coverArtMin)
        const showConfirmation = !album.coverArtUrl || album.coverArtUrl === '' || !!album.uploadErrors.length
            || isUploadingArtwork
        const confirmation = showConfirmation ? undefined
            : (
                <ReduxField
                    name="album.coverArtUserConfirmed"
                    component={this.renderConfirmationCheckbox}
                    album={album}
                />
            )

        return (
            <article className="c-release-package">
                <header>
                    <h1>{t('componentArtCoverFormHeader')}</h1>
                    <TrackUploadProgress id={album.id} />
                </header>

                <Frame
                    borderImageId="dune-3"
                    contentTint="light"
                    contentClassName="widget-content"
                >
                    <ReduxField
                        name="album.coverArtUrl"
                        album={album}
                        component={this.renderReleaseCoverArt}
                    />
                    <div className="c-release-package-guidelines">
                        <h4>{t('componentArtCoverFormSubHeader')}</h4>
                        <div dangerouslySetInnerHTML={{ __html: guidelines }} />
                        { confirmation }
                    </div>
                </Frame>
            </article>
        )
    }
}
