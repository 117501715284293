import * as types from 'constants/MessageActionTypes'

export const addMessage = id => ({
    type: types.ADD_MESSAGE,
    payload: {
        id,
        hidden: false,
    },
})

export const removeMessage = id => ({
    type: types.REMOVE_MESSAGE,
    payload: {
        id,
    },
})
