import { createSelector } from 'reselect'

export const editImagesSelector = createSelector(
    state => state.editImages,
    editImages => editImages
)

export const firstEditImagesSelector = createSelector(
    editImagesSelector,
    editImages => editImages && editImages.length && editImages[0]
)
