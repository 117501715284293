import * as types from 'constants/FontsActionTypes'
import { CALL_API } from 'middleware/api'

export function fetchFonts() {
    return {
        [CALL_API]: {
            types: [types.FETCH_FONTS_REQUEST, types.FETCH_FONTS_SUCCESS, types.FETCH_FONTS_FAILURE],
            payload: {
                method: 'get',
                endpoint: 'profile/fonts',
            },
        },
    }
}
