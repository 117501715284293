// Quack! This is a duck. https://github.com/erikras/ducks-modular-redux
import { combineEpics } from 'redux-observable'
import { isEmpty } from 'lodash'
import {
    actionTypes,
    reducer,
} from 'redux-form'

export const KEY = 'form' // default one ! DO NOT TOUCH before checking documentation

// ///////////
// DETERMINISTIC ACTIONS
// ///////////

// already provided by the redux-form library
// see actionTypes of redux-form

// ///////////
// ACTION CREATORS
// ///////////

// already provided by the redux-form library
// https://redux-form.com/7.2.0/docs/api/actioncreators.md/
export const updateErrors = (form, syncErrors) => ({
    type: actionTypes.UPDATE_SYNC_ERRORS,
    meta: {
        form,
    },
    payload: {
        syncErrors,
    },
})

export const updateWarnings = (form, syncWarnings) => ({
    type: actionTypes.UPDATE_SYNC_WARNINGS,
    meta: {
        form,
    },
    payload: {
        syncWarnings,
    },
})

export const updateAsyncErrors = (form, errors) => ({
    type: actionTypes.STOP_ASYNC_VALIDATION,
    meta: {
        form,
    },
    payload: errors,
    error: !isEmpty(errors),
})

// ///////////
// REDUCERS
// ///////////

export default reducer

// ///////////
// SELECTORS
// ///////////

// already provided by the redux-form library
// https://redux-form.com/7.2.0/docs/api/selectors.md/

// ///////////
// NON DETERMINISTIC ACTIONS
// ///////////

// ///////////
// ACTION CREATORS
// ///////////

// ///////////
// EPICS
// ///////////

export const epic = combineEpics()
