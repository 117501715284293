import { select } from 'redux-saga/effects'
import * as albumActions from 'constants/AlbumActionTypes'
import { callApi } from './api'

export function* updateAlbum(action) {
    const { payload } = action
    const album = yield select(state => state.albums.albums[payload.id])

    const properties = {
        ...album,
        ...payload.data,
    }

    properties.artists = transformArtists(properties.artists)
    properties.tracks = properties.tracks.map((trackData) => {
        // @todo Work out why tracks aren't always using Immutable
        const track = (typeof trackData.toJS === 'undefined' ? trackData : trackData.toJS())

        track.artists = transformArtists(track.artists)
        track.audioLocale = (track.audioLocale !== null && typeof track.audioLocale === 'object')
            ? track.audioLocale.id
            : null

        return track
    })

    yield callApi(
        [
            albumActions.UPDATE_ALBUM_REQUEST,
            albumActions.UPDATE_ALBUM_FAILURE,
            albumActions.UPDATE_ALBUM_SUCCESS,
        ],
        {
            endpoint: `dsp/product/${album.id}`,
            method: 'put',
            body: properties,
            meta: { id: album.id },
        }
    )
}

function transformArtists(artists) {
    return artists.map(artist => ({
        id: artist.id,
        artistType: artist.artistType,
        artistName: artist.artistName,
        identifiers: artist.identifiers || [],
    }))
}
