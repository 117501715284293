import { Record } from 'immutable'

const defaultCountryPrefix = {
    countryCode: undefined,
    countryName: undefined,
    phonePrefix: undefined,
    flag: undefined,
}

export default class CountryPrefix extends Record(defaultCountryPrefix) {
}
