import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

/**
 * Alert
 *
 * Alert notifications
 *
 * Styleguide: components.alert
 */

export default class Alert extends Component {
    static propTypes = {
        children: PropTypes.node,
        type: PropTypes.oneOf(['info', 'warning', 'error', 'fatal-error', 'success', 'christmas', 'custom']),
        small: PropTypes.bool,
        containerClassNames: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        colourise: PropTypes.bool,
        customIcon: PropTypes.string,
    };

    static defaultProps = {
        type: 'info',
        small: false,
    };

    render() {
        const {
            children,
            type,
            small,
            containerClassNames,
            colourise,
            customIcon,
        } = this.props

        let icon

        switch (type) {
            case 'info':
                icon = 'faInfo'
                break
            case 'warning':
                icon = 'faExclamation'
                break
            case 'error':
                icon = 'faBan'
                break
            case 'fatal-error':
                icon = 'faExclamationTriangle'
                break
            case 'success':
                icon = 'faCheck'
                break
            case 'christmas':
                icon = 'faGift'
                break
            case 'custom':
                icon = customIcon
                break
            default:
                break
        }

        const className = classnames(
            'c-alert',
            containerClassNames,
            `alert-type-${type}`,
            {
                small,
                colourise,
            }
        )

        return (
            <div className={className}>
                <div className="icon">
                    <FontAwesomeIcon icon={icon} fixedWidth />
                </div>

                <div className="message">
                    {children}
                </div>
            </div>
        )
    }
}
