try { window.localStorage.setItem('test', true) } catch (e) {
    const storage = {}

    window.localStorage.setItem = (id, val) => { storage[id] = String(val) }
    window.localStorage.getItem = id => (Object.prototype.hasOwnProperty.call(storage, id) ? storage[id] : undefined)
}

try { window.sessionStorage.setItem('test', true) } catch (e) {
    const sessionStorage = {}

    window.sessionStorage.setItem = (id, val) => { sessionStorage[id] = String(val) }
    window.sessionStorage.getItem = id => (
        Object.prototype.hasOwnProperty.call(sessionStorage, id) ? sessionStorage[id] : undefined
    )
}
