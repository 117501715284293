import React from 'react'
import * as PropTypes from 'prop-types'
import { FontAwesomeIcon as FontAwesomeIconSrc } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import * as faFreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as FaInstagramFill } from 'svg/instagram_fill.svg'

const FontAwesomeIcon = (props) => {
    const faProps = { ...props }

    const getIcon = (p) => {
        const faIconName = (Array.isArray(p) && p.length > 1) ? p[1] : p

        if (Object.hasOwnProperty.call(faFreeSolidSvgIcons, faIconName)) {
            return faFreeSolidSvgIcons[faIconName]
        }

        if (Object.hasOwnProperty.call(fab, faIconName)) { // brands. example:: "faFacebook"
            return fab[faIconName]
        }

        return null
    }

    const ico = getIcon(faProps.icon)

    if (!ico) {
        return null
    }

    if (faProps.mask) {
        faProps.mask = getIcon(faProps.mask)
    }

    // Give the instagram icon the option to colourise via the #instagramRg svg fragment by adding its definition
    if (ico && ico.iconName && ico.iconName === 'instagram'
        && faProps.className && faProps.className.indexOf('colourised') !== -1) {
        return (
            <>
                <FaInstagramFill />
                <FontAwesomeIconSrc {...faProps} icon={ico} />
            </>
        )
    } else {
        return (
            <FontAwesomeIconSrc {...faProps} icon={ico} />
        )
    }
}

FontAwesomeIcon.propTypes = {
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]).isRequired,
    mask: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    className: PropTypes.string,
    fixedWidth: PropTypes.bool,
}

export default FontAwesomeIcon
