import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import TrendsChart from 'components/TrendsChart/TrendsChart'

import t from 'utilities/translate'

export default class TrendsStats extends Component {
    static propTypes = {
        fetchOverviewTrends: PropTypes.func.isRequired,
        toggleOverviewVisibility: PropTypes.func.isRequired,

        artistId: PropTypes.number.isRequired,
        isLoading: PropTypes.bool.isRequired,
        failedFetching: PropTypes.bool.isRequired,

        filterOptions: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
        icons: PropTypes.array.isRequired,
        labels: PropTypes.array.isRequired,
    }

    componentDidMount() {
        const { artistId, filterOptions } = this.props

        this.props.fetchOverviewTrends(artistId, filterOptions)
    }

    render() {
        const {
            toggleOverviewVisibility,
            isLoading,
            failedFetching,
            filterOptions,
            data,
            icons,
            labels,
        } = this.props

        return (
            <TrendsChart
                labels={labels}
                data={data}
                toggleIcons={icons}
                filterOptions={filterOptions}
                toggleVisibility={toggleOverviewVisibility}
                isLoading={isLoading}
                isExampleData={false}
                failedFetching={failedFetching}
                title={t('componentTrendStatsTitle')}
            />
        )
    }
}
