import React from 'react'
import t from 'utilities/translate'
import { editProfileFields } from 'constants/EditProfileStructure'
import SocialMediaInput from './SocialMediaInput'

const {
    socialMedia: {
        facebookSocial,
        instagramSocial,
        twitterSocial,
        soundcloudSocial,
        youtubeSocial,
        spotifySocial,
        appleMusicSocial,
        otherSocial,
    },
} = editProfileFields

const socialMediaInputs = [
    {
        name: facebookSocial,
        labelText: t('formEditProfileFacebookLink'),
        icon: 'faFacebookSquare',
    },
    {
        name: instagramSocial,
        labelText: t('formEditProfileInstagramLink'),
        icon: 'faInstagram',
    },
    {
        name: twitterSocial,
        labelText: t('formEditProfileTwitterLink'),
        icon: 'faTwitter',
    },
    {
        name: soundcloudSocial,
        labelText: t('formEditProfileSoundcloudLink'),
        icon: 'faSoundcloud',
    },
    {
        name: youtubeSocial,
        labelText: t('formEditProfileYoutubeLink'),
        icon: 'faYoutube',
    },
    {
        name: spotifySocial,
        labelText: t('formEditProfileSpotifyLink'),
        icon: 'faSpotify',
    },
    {
        name: appleMusicSocial,
        labelText: t('formEditProfileAppleMusicLink'),
        icon: 'faItunesNote',
    },
    {
        name: otherSocial,
        labelText: t('formEditProfileOtherLink'),
        icon: 'faPlus',
    },
]

const SocialMediaInputs = () => (
    <div>
        <p>{t('socialMediaLinksDescription')}</p>
        {socialMediaInputs.map(input => (
            <SocialMediaInput
                key={input.name}
                {...input}
            />
        ))}
    </div>
)

export default SocialMediaInputs
