import * as types from 'constants/SaleActionTypes'
import * as authTypes from 'constants/AuthActionTypes'
import { ApiSalesOverviewElement } from 'models/api/sales-overview'
import { MonthOverview } from 'models/sales-overview-month'
import { SalesOverview } from 'models/sales-overview'
import { SalesBreakdown } from 'models/sales-breakdown'
import { AnyAction } from 'redux'

type SalesState = {
    isFetching: boolean
    isFetchingOverview: boolean
    failedFetchingOverview: boolean
    isFetchingBreakdown: boolean
    failedFetchingBreakdown: boolean
    overview: SalesOverview[]
    breakdown: SalesBreakdown
    breakdownUnfilteredReleases: SalesBreakdown['releases']
    breakdownUnfilteredCountries: SalesBreakdown['countries']
    breakdownUnfilteredDsps: SalesBreakdown['dsps']
    fetchedAt: number | Date
    selectedDates?: string[]
    selectedRelease?: string
    selectedTrack?: string
    selectedCountry?: string
    selectedDsp?: string
    selectedYear?: number
    selectedOverviewYear?: number
    selectedMonth?: number
}

const initialState: SalesState = {
    isFetching: false,
    isFetchingOverview: false,
    failedFetchingOverview: false,
    isFetchingBreakdown: false,
    failedFetchingBreakdown: false,
    overview: [],
    breakdown: new SalesBreakdown({}),
    breakdownUnfilteredReleases: [],
    breakdownUnfilteredDsps: [],
    breakdownUnfilteredCountries: [],
    fetchedAt: 0,
    selectedDates: [],
    selectedRelease: undefined,
    selectedTrack: undefined,
    selectedCountry: undefined,
    selectedDsp: undefined,
    selectedYear: undefined,
    selectedOverviewYear: undefined,
    selectedMonth: undefined,
}

function transformOverview(payload: ApiSalesOverviewElement[]) {
    return payload.reduce((overviewData, yearOverview) => {
        const monthsData = yearOverview.months.reduce((overviewMonths, monthOverview) => {
            overviewMonths.push(new MonthOverview(monthOverview))
            return overviewMonths
        }, [] as MonthOverview[])

        overviewData.push(new SalesOverview(yearOverview.year, monthsData))

        return overviewData
    }, [] as SalesOverview[])
}

export default function sales(state = initialState, action: AnyAction): SalesState {
    switch (action.type) {
        case types.FETCH_SALES_OVERVIEW_REQUEST:
            return {
                ...state,
                isFetchingOverview: true,
                failedFetchingOverview: false,
            }

        case types.FETCH_SALES_OVERVIEW_SUCCESS:
            const overviewSales = transformOverview(action.payload)
            return {
                ...state,
                isFetchingOverview: false,
                failedFetchingOverview: false,
                overview: overviewSales,
                fetchedAt: new Date(),
            }

        case types.FETCH_SALES_OVERVIEW_FAILURE:
            return {
                ...state,
                isFetchingOverview: false,
                failedFetchingOverview: true,
            }

        case types.FETCH_SALES_BREAKDOWN_REQUEST:
            return {
                ...state,
                isFetchingBreakdown: true,
                failedFetchingBreakdown: false,
            }

        case types.FETCH_SALES_BREAKDOWN_SUCCESS:
            const breakdownSales = new SalesBreakdown(action.payload)

            const isFiltered = Object.keys(action.meta.filter)
                .some(key => action.meta.filter[key] !== '' && !['year', 'month'].includes(key))

            return {
                ...state,
                isFetchingBreakdown: false,
                failedFetchingBreakdown: false,
                breakdown: breakdownSales,
                breakdownUnfilteredReleases: isFiltered ? state.breakdownUnfilteredReleases : breakdownSales.releases,
                breakdownUnfilteredCountries: isFiltered
                    ? state.breakdownUnfilteredCountries : breakdownSales.countries,
                breakdownUnfilteredDsps: isFiltered ? state.breakdownUnfilteredDsps : breakdownSales.dsps,
                fetchedAt: new Date(),
                selectedRelease: action.meta.filter.release,
                selectedTrack: action.meta.filter.track,
                selectedCountry: action.meta.filter.country,
                selectedDsp: action.meta.filter.dsp,
            }

        case types.FETCH_SALES_BREAKDOWN_FAILURE:
            return {
                ...state,
                isFetchingBreakdown: false,
                failedFetchingBreakdown: true,
            }

        case types.SELECT_OVERVIEW_YEAR:
            return {
                ...state,
                selectedOverviewYear: action.payload,
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
