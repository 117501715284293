import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Select } from 'components'

export default class CountrySelect extends Component {
    componentDidMount() {
        if (!this.props.countries.length) {
            this.props.fetchCountries()
        }
    }

    render() {
        const {
            countries, value, placeholder, onChange,
        } = this.props

        return (
            <Select
                disabled={!countries.length}
                options={countries}
                disableSort
                searchable
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        )
    }
}

CountrySelect.propTypes = {
    countries: PropTypes.array.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    fetchCountries: PropTypes.func.isRequired,
    onChange: PropTypes.func,
}
