import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import Chartist from 'chartist'
import abbreviate from 'utilities/abbreviate'

import 'chartist-plugin-tooltips/dist/chartist-plugin-tooltip'
import 'chartist/dist/chartist.min.css'

export default class Chart extends Component {
    static propTypes = {
        /** A tinier chart */
        tiny: PropTypes.bool,

        /** Array of labels */
        labels: PropTypes.array.isRequired,

        /** Array of lines */
        series: PropTypes.array.isRequired,

        /** Handle display of labels */
        displayLabels: PropTypes.func,
    };

    constructor(props) {
        super(props)
        this.chartRef = React.createRef()
        this.state = {
            chartistObj: undefined,
        }
    }

    componentDidMount() {
        const chartObj = this.createChart()
        this.setState({
            chartistObj: chartObj,
        })
    }

    componentDidUpdate(prevProps) {
        const {
            tiny, labels, series, displayLabels,
        } = this.props

        if (prevProps.labels !== labels || prevProps.series !== series
            || prevProps.tiny !== tiny || prevProps.displayLabels !== displayLabels) {
            const maxYAxisValue = this.getMaxYAxisValue(series)
            this.state.chartistObj.update({
                labels,
                series,
            }, {
                axisX: {
                    labelInterpolationFnc: displayLabels || (value => value),
                    labelOffset: {
                        x: -15,
                    },
                },
                axisY: {
                    scaleMinSpace: tiny ? 20 : 40,
                    labelInterpolationFnc: abbreviate,
                    low: 0,
                    high: maxYAxisValue,
                    onlyInteger: true,
                },
            }, true)
        }
    }

    getMaxYAxisValue = (data, minimumYAxis = 5) => {
        const allDspTotalValues = data.reduce((allValues, dspGroup) => {
            const dspTotals = dspGroup.data.reduce((dspValues, dspValue) => [
                ...dspValues,
                dspValue.value,
            ], [])
            return [
                ...allValues,
                ...dspTotals,
            ]
        }, [])
        const maxDspTotal = Math.max(...allDspTotalValues)
        return maxDspTotal > minimumYAxis ? maxDspTotal : minimumYAxis
    }

    createChart() {
        const {
            tiny, labels, series, displayLabels,
        } = this.props

        const maxYAxisValue = this.getMaxYAxisValue(series)

        return new Chartist.Line(this.chartRef.current, { labels, series }, {
            fullWidth: true,
            showLine: true,
            showArea: false,
            showPoint: true,
            axisX: {
                labelInterpolationFnc: displayLabels || (value => value),
                labelOffset: {
                    x: -15,
                },
            },
            axisY: {
                scaleMinSpace: tiny ? 20 : 40,
                labelInterpolationFnc: abbreviate,
                low: 0,
                high: maxYAxisValue,
                onlyInteger: true,
            },
            plugins: [
                Chartist.plugins.tooltip({
                    // class : 'tooltip'
                    // appendToBody : true,
                    // eslint-disable-next-line max-len
                    tooltipFnc: (meta, value) => `<span class="tooltip-meta">${meta}</span><span class="tooltip-value">${value}</span>`,
                }),
            ],
            lineSmooth: Chartist.Interpolation.none({
                fillHoles: true,
            }),
        })
    }

    render() {
        return (
            <div className="c-chart" ref={this.chartRef} />
        )
    }
}
