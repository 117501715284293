import 'rxjs'
import { decamelizeKeys } from 'humps'

const API_URI = window.env.SPINNUP_API

// put is here only for a semantic purpose, indeed we are not doing a proper PUT
// but isntead we are always doing a PATCH, execpt we are providing the WHOLE album
// this is due to a FUGA limitation and the way we are handling the saves (real-time saves)
export const putTrack = (track, credentials, ajax) => {
    const setting = {
        method: 'PATCH',
        url: `${API_URI}dsp/product/${track.albumId}/track/${track.id}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${credentials.accessToken}`,
        },
        body: JSON.stringify(decamelizeKeys({
            title: track.title,
            recordingVersionId: track.recordingVersionId,
            explicitLyrics: track.explicitLyrics,
            artists: track.artists.map((artist, index) => ({
                id: artist.id,
                artistType: artist.artistType,
                artistName: artist.artistName,
                identifiers: artist.identifiers || [],
                translations: (track.artistTranslations && track.artistTranslations[index])
                    ? track.artistTranslations[index] : [],
            })),
            publishers: track.publishers,
            audioLocale: track.audioLocale === false ? false : (track.audioLocale || {}).id,
            lyrics: track.lyrics,
            compositionTypeId: track.compositionTypeId,
            compositionTermsAgreed: track.compositionTermsAgreed,
            isrc: track.isrc,
            translations: track.titleTranslations,
        })),
    }
    return ajax(setting)
}

export const patchTrack = ({ track, field }, credentials, ajax) => {
    const setting = {
        method: 'PATCH',
        url: `${API_URI}dsp/product/${track.albumId}/track/${track.id}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${credentials.accessToken}`,
        },
        body: JSON.stringify(decamelizeKeys({
            [field]: track[field],
        })),
    }

    return ajax(setting)
}
