import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import { upperFirst } from 'lodash'

import t from 'utilities/translate'
import Frame from 'components/Frame/Frame'
import ClipoutText from 'components/Frame/ClipoutText'
import WidgetButton from 'components/WidgetButton/WidgetButton'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import WidgetBannerLoader from 'components/WidgetBanner/WidgetBannerLoader'

export default class SocialWidget extends Component {
    static propTypes = {
        overviewSocialStats: PropTypes.array.isRequired,
        isFetching: PropTypes.bool.isRequired,
        failedFetchingSocial: PropTypes.bool.isRequired,
        connectedServices: PropTypes.array,
    };

    render() {
        const {
            overviewSocialStats,
            isFetching,
            failedFetchingSocial,
            connectedServices,
        } = this.props

        const widgetClassname = classnames(
            'c-social-widget',
            'widget-container'
        )

        return (
            <Frame
                borderImageId="dune-5"
                contentTint="light"
                contentClassName="widget-content"
            >

                {isFetching
                    && <WidgetBannerLoader view="multi" />}

                {!isFetching && failedFetchingSocial && connectedServices.length === 0
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="dune-5"
                            >
                                { t('socialWidgetNoServiceTitle') }
                            </ClipoutText>
                            <WidgetButton
                                href="/settings"
                                text={t('socialWidgetNoServiceButton')}
                                anchor="social-accounts"
                            />
                        </div>
                    )}

                {!isFetching && failedFetchingSocial && connectedServices.length === 1
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="dune-5"
                            >
                                { t('socialWidgetSingleServiceTitle') }
                            </ClipoutText>
                            <div className="services-no-data">
                                <p className="social-icon">
                                    <FontAwesomeIcon
                                        icon={`fa${upperFirst(connectedServices[0].service)}`}
                                        fixedWidth
                                    />
                                </p>
                            </div>
                            <WidgetButton href="/trends/social" text={t('socialWidgetSingleServiceButton')} />
                        </div>
                    )}

                {!isFetching && failedFetchingSocial && connectedServices.length > 1

                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="dune-5"
                            >
                                { t('socialWidgetMultipleServicesTitle') }
                            </ClipoutText>
                            <div className="services-no-data">
                                { connectedServices.slice(0, 2).map(service => (
                                    <div className="social-service" key={service.service}>
                                        <p className="social-icon">
                                            <FontAwesomeIcon icon={`fa${upperFirst(service.service)}`} fixedWidth />
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <WidgetButton href="/trends/social" text={t('socialWidgetMultipleServicesButton')} />
                        </div>
                    )}

                {!isFetching && !failedFetchingSocial && overviewSocialStats.length === 0
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="dune-5"
                            >
                                { t('socialWidgetNoServiceTitle') }
                            </ClipoutText>
                            <WidgetButton
                                href="/settings"
                                text={t('socialWidgetNoServiceButton')}
                                anchor="social-accounts"
                            />
                        </div>
                    )}

                { !isFetching && !failedFetchingSocial && overviewSocialStats.length === 1
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="dune-5"
                            >
                                { t('socialWidgetSingleServiceTitle') }
                            </ClipoutText>
                            <p className="social-icon">
                                <FontAwesomeIcon
                                    icon={overviewSocialStats[0].icon.iconId}
                                    fixedWidth
                                />
                            </p>
                            <p className="stats-followers">{overviewSocialStats[0].total}</p>
                            <WidgetButton href="/trends/social" text={t('socialWidgetSingleServiceButton')} />
                        </div>
                    )}

                { !isFetching && !failedFetchingSocial && overviewSocialStats.length > 1
                    && (
                        <div className={widgetClassname}>
                            <ClipoutText
                                type="h3"
                                backgroundImageId="dune-5"
                            >
                                { t('socialWidgetMultipleServicesTitle') }
                            </ClipoutText>
                            <div className="multiple-services">
                                { overviewSocialStats.map(service => (
                                    <div className="social-service" key={service.icon.iconId}>
                                        <p className="social-icon">
                                            <FontAwesomeIcon icon={service.icon.iconId} fixedWidth />
                                        </p>
                                        <p className="stats-followers">{service.total}</p>
                                    </div>
                                ))}
                            </div>
                            <WidgetButton href="/trends/social" text={t('socialWidgetMultipleServicesButton')} />
                        </div>
                    )}
            </Frame>
        )
    }
}
