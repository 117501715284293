import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import t from 'utilities/translate'

import Button from 'components/Button/Button'

class Steps extends Component {
    static propTypes = {
        release: PropTypes.object.isRequired,
        steps: PropTypes.array.isRequired,
        tracks: PropTypes.array.isRequired,
        validateTrack: PropTypes.func.isRequired,
        validateSettings: PropTypes.func.isRequired,
        validatePackage: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        enableNavigation: PropTypes.bool.isRequired,
        isUploadingReleaseContent: PropTypes.bool.isRequired,
        totalReleaseFlowErrors: PropTypes.number.isRequired,
        showErrorModal: PropTypes.func,
        openTracksRemainingWarning: PropTypes.func,
        checkReleaseTitle: PropTypes.func,
    };

    constructor(props) {
        super(props)

        this.state = {
            defer: {
                stepIndex: null,
                stepIndexToValidate: null,
            },
        }
    }

    componentDidUpdate() {
        if (this.state.defer.stepIndex !== null) {
            this.goTo(this.state.defer.stepIndex, this.state.defer.stepIndexToValidate)
        }
    }

    onNext = () => {
        const activeIndex = this.getActiveIndex()
        this.goTo(activeIndex + 1, activeIndex)
    }

    onPrev = () => {
        const activeIndex = this.getActiveIndex()

        this.goTo(activeIndex - 1, activeIndex - 1)
    }

    onExitStep(from, to) {
        const vector = `${from}->${to}`
        switch (vector) {
            case 'tracks->settings':
                this.props.checkReleaseTitle(this.props.release.album.id)
                break
            default:
                break
        }
    }

    getActiveStep() {
        return this.props.steps.find(step => step.active)
    }

    getActiveIndex() {
        return this.props.steps.findIndex(step => step.active)
    }

    getVisibleSteps() {
        return this.props.steps.filter(step => step.visible !== false)
    }

    isToReview = (index, activeIndex) => (index === 3 && activeIndex === 2)

    goTo(index, validationIndex) {
        const {
            steps,
            tracks,
            history,
            release: {
                releaseType: {
                    name: releaseTypeName,
                    maxTracks,
                },
            },
            openTracksRemainingWarning,
            totalReleaseFlowErrors,
        } = this.props

        const step = steps[index]
        const activeStep = this.getActiveStep()
        const activeStepIndex = this.getActiveIndex()
        const { showErrorModal } = this.props

        if (activeStep.isUpdating) {
            // Defer navigation until current step has finished updating
            if (index !== this.state.defer.stepIndex) {
                this.setState({
                    defer: {
                        stepIndex: index,
                        stepIndexToValidate: validationIndex,
                    },
                })
            }
        } else {
            this.validate(this.props.steps[validationIndex])

            this.setState({
                defer: {
                    stepIndex: null,
                    stepIndexToValidate: null,
                },
            }, () => {
                if (index !== validationIndex && activeStepIndex === 2) {
                    this.validate(this.props.steps[activeStepIndex])

                    const firstIncompleteStep = this.getVisibleSteps().find(currentStep => !currentStep.completed)
                    const completedAllSteps = !firstIncompleteStep

                    if (!completedAllSteps) {
                        showErrorModal(totalReleaseFlowErrors, firstIncompleteStep.href)
                        return
                    }

                    const tracksRemaining = maxTracks - tracks.length
                    if (
                        this.isToReview(index, activeStepIndex)
                        && tracksRemaining > 0
                        && !window.env.ENABLE_SUBSCRIPTION_MANAGEMENT
                    ) {
                        openTracksRemainingWarning(
                            releaseTypeName,
                            maxTracks,
                            tracks.length,
                            () => {
                                this.onExitStep(this.props.steps[activeStepIndex].key, step.key)
                                history.push(step.href)
                            },
                            () => this.goTo(0, 0)
                        )
                        return
                    }
                }
                this.onExitStep(this.props.steps[activeStepIndex].key, step.key)
                history.push(step.href)
            })
        }
    }

    validate(step) {
        const { tracks, release } = this.props

        switch (step.key) {
            case 'tracks':
                tracks.forEach(track => this.props.validateTrack(track.id))
                break
            case 'settings':
                this.props.validateSettings(release.providerId, release.album)
                break
            case 'package':
                this.props.validatePackage(release.providerId, release.album)
                break
            default:
                break
        }
    }

    render() {
        const { steps, enableNavigation, isUploadingReleaseContent } = this.props
        const { defer } = this.state

        const activeStepIndex = this.getActiveIndex()
        const disableButtons = defer.stepIndex !== null || !enableNavigation
        const disableReviewStep = steps[2].active && isUploadingReleaseContent

        if (steps[3].active) {
            return null
        }

        return (
            <div className="c-steps">
                {steps[0].active ? (
                    <Button
                        href="/releases"
                        disabled={disableButtons}
                    >
                        {t('containerStepsButtonLeave')}
                    </Button>
                ) : (
                    <Button
                        onClick={this.onPrev}
                        disabled={disableButtons}
                        isLoading={disableButtons && this.state.defer.stepIndex < activeStepIndex}
                    >
                        {t('containerStepsButtonPrevious')}
                    </Button>
                )}

                <div className="c-steps-list">
                    <div className="c-steps-list-link" />
                    {this.getVisibleSteps().map(({
                        key,
                        active,
                        error,
                    }) => (
                        <div
                            key={key}
                            className={classnames(
                                'c-steps-list-step', {
                                    'c-steps-list-step-active': active,
                                    'c-steps-list-step-error': error,
                                }
                            )}
                        />
                    ))}
                </div>

                <Button
                    onClick={this.onNext}
                    disabled={disableButtons || disableReviewStep}
                    isLoading={
                        (disableButtons && this.state.defer.stepIndex > activeStepIndex)
                        || !enableNavigation || disableReviewStep
                    }
                >
                    {(steps[2].active ? t('containerStepsButtonGoTo') : t('containerStepsButtonNext'))}
                </Button>
            </div>
        )
    }
}

export default Steps
