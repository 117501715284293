import {
    MARKET_ID_DEFAULT, MARKET_ID_JAPAN, MARKET_ID_SEA, MARKET_ID_USA_CANADA,
} from 'constants/enums/MarketEnums'

type BillingAddressOrder = (
    'country' | 'houseNameNumber' | 'street' | 'flatApartment' | 'city' | 'region' | 'zipPostcode'
)[]
type BillingAddressOrderMergedLine1 = ('country' | 'addressLine1' | 'city' | 'region' | 'zipPostcode')[]

export interface MarketConfigFormLayout {
    firstNameLastName: ('firstName' | 'lastName')[],
    billingAddress: BillingAddressOrder,
    billingAddressMergedLine1: BillingAddressOrderMergedLine1,
    displayAddress: ('houseNameNumber' | 'street' | 'flatApartment' | 'city' | 'region' | 'zipPostcode' | 'country')[],
    displayAddressMergedLine1: ('addressLine1' | 'city' | 'region' | 'zipPostcode' | 'country')[],
    dateOfBirth: ('day' | 'month' | 'year')[],
}

export interface MarketConfig {
    formLayout: MarketConfigFormLayout
}

export interface LocaleUiConfig {
    marketConfig: {
        [marketId: number]: MarketConfig
    }
}

const defaultMarketConfig: MarketConfig = {
    formLayout: {
        firstNameLastName: ['firstName', 'lastName'],
        billingAddress: ['country', 'houseNameNumber', 'street', 'flatApartment', 'city', 'region', 'zipPostcode'],
        billingAddressMergedLine1: ['country', 'addressLine1', 'city', 'region', 'zipPostcode'],
        displayAddress: ['houseNameNumber', 'street', 'flatApartment', 'city', 'region', 'zipPostcode', 'country'],
        displayAddressMergedLine1: ['addressLine1', 'city', 'region', 'zipPostcode', 'country'],
        dateOfBirth: ['day', 'month', 'year'],
    },
}

const jpBillingAddressOrder: BillingAddressOrder = [
    'country',
    'zipPostcode',
    'region',
    'city',
    'street',
    'houseNameNumber',
    'flatApartment',
]

const jpBillingAddressMergedLine1Order: BillingAddressOrderMergedLine1 = [
    'country',
    'zipPostcode',
    'region',
    'city',
    'addressLine1',
]

export const defaultUiConfig: LocaleUiConfig = {
    marketConfig: {
        [MARKET_ID_DEFAULT]: defaultMarketConfig,
        [MARKET_ID_JAPAN]: {
            formLayout: {
                firstNameLastName: ['lastName', 'firstName'],
                billingAddress: jpBillingAddressOrder,
                billingAddressMergedLine1: jpBillingAddressMergedLine1Order,
                displayAddress: jpBillingAddressOrder,
                displayAddressMergedLine1: jpBillingAddressMergedLine1Order,
                dateOfBirth: ['year', 'month', 'day'],
            },
        },
        [MARKET_ID_SEA]: {
            formLayout: {
                ...defaultMarketConfig.formLayout,
                dateOfBirth: ['month', 'day', 'year'],
            },
        },
        [MARKET_ID_USA_CANADA]: {
            formLayout: {
                ...defaultMarketConfig.formLayout,
                dateOfBirth: ['month', 'day', 'year'],
            },
        },
    },
}
