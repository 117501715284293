import React, { useEffect } from 'react'

import Header from 'containers/Header/Header'
import Footer from 'containers/Footer/Footer'
import Frame from 'components/Frame/Frame'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const Maintenance = () => {
    const { t } = useLocaleContext()
    useEffect(() => {
        document.title = t('documentTitle')
    })

    return (
        <div className="l-maintenance">
            <Header isMaintenanceMode />

            <main role="main">
                <Frame
                    borderImageId="dune-2"
                    contentTint="light"
                    className="c-maintenance"
                >
                    <div className="c-maintenance-content">
                        <div className="c-maintenance-content-player">
                            <iframe
                                title="spotifyPlayer"
                                key="spotify:user:spinnup.com:playlist:2KlKyggdlvONZqNCrrJa58"
                                src="https://embed.spotify.com/?uri=spotify:user:spinnup.com:playlist:2KlKyggdlvONZqNCrrJa58"
                                frameBorder="0"
                                // eslint-disable-next-line react/no-unknown-property
                                allowtransparency="true"
                            />
                        </div>

                        <div className="c-maintenance-content-message">
                            <h3>{t('maintenanceHeading')}</h3>
                            <p>{t('maintenanceContentP1')}</p>
                            <p>{t('maintenanceContentP2')}</p>
                            <p>{t('maintenanceContentP3')}</p>
                        </div>
                    </div>

                </Frame>
            </main>

            <Footer isMaintenanceMode />
        </div>
    )
}

export default Maintenance
