import React from 'react'
import { getLanguage } from 'utilities/translate'
import {
    enabled,
    scriptUri,
    bustCache,
    settings,
} from './_config'

import { setZendeskWidgetLanguage } from './language'

const w = window

/**
 * :: ZenDeskWidget :: [HOC]
 * @see - https://developer.zendesk.com/embeddables/docs/widget/help_center
 *
 */
type PropsObject<T> = { [K in keyof T]?: any }

const withZenDeskWidget = <T extends PropsObject<T>>(WrappedComponent: React.ComponentType<T>): React.FC<T> => (
    (props: T): React.ReactElement<T> => {
        const key = w.env.ZENDESK_WEBWIDGET_KEY
        if (!key || key.length === 0) {
            console.error('Zendesk:: Web Widget Key not found or is not valid')
        } else if (!w.zE) {
            if (enabled) {
                (
                    (window) => {
                        window.zESettings = settings
                        const script = window.document.createElement('script')
                        script.type = 'text/javascript'
                        script.async = true
                        script.id = 'ze-snippet'
                        script.src = !bustCache
                            ? `${scriptUri}?key=${key}`
                            : `${scriptUri}?key=${key}&bc=${(new Date()).getTime()}`

                        const first = window.document.getElementsByTagName('script')[0]
                        first.parentNode?.insertBefore(script, first)
                    }
                )(w)
            }
            const timer = setInterval(() => {
                if (window.zE && window.zE.setLocale) {
                    clearInterval(timer)
                    setZendeskWidgetLanguage(getLanguage())
                }
            }, 100)
        }
        return <WrappedComponent {...props} />
    }
)

export default withZenDeskWidget
