import React from 'react'
import * as PropTypes from 'prop-types'
import Toast from './Toast'

const ToastNotifications = ({ notifications, removeNotification }) => (
    <div className="c-toasts">
        <div className="c-toasts-container">
            {notifications.map(notification => (
                <Toast
                    remove={() => {
                        removeNotification(notification.id)
                    }}
                    key={notification.id}
                    {...notification}
                />
            ))}
        </div>
    </div>
)

export default ToastNotifications

ToastNotifications.propTypes = {
    removeNotification: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
}
