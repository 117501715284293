import { TOAST_TYPES } from 'constants/ToastNotificationsConstants'

const createToast = type => content => ({
    id: uuid(),
    type,
    content,
})

export const createSuccessToast = createToast(TOAST_TYPES.success)
export const createErrorToast = createToast(TOAST_TYPES.error)

function uuid() {
    return (
        Math.random()
            .toString(36)
            .substring(2) + Date.now().toString(36)
    )
}
