import React from 'react'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { translateString, TranslationKey } from 'contexts/localisation/translateUtilities'

type TranslateProps = {
    id: TranslationKey
    args?: any[]
}

const Translate: React.FC<TranslateProps> = ({ id, args }) => {
    const { localeConfig } = useLocaleContext()
    return (
        translateString(id, args, localeConfig.getLanguage())
    )
}

export default Translate
