import { connect } from 'react-redux'
import { showModal } from 'modules/modal'

import TranslatableInput from 'components/TranslatableInput/TranslatableInput'

const mapStateToProps = state => ({
    marketId: state.users.user.marketId,
})

const mapDispatchToProps = dispatch => ({
    showTrackTitleTranslationModal: () => dispatch(showModal('translatable-track-title-input')),
    showReleaseTitleTranslationModal: () => dispatch(showModal('translatable-release-title-input')),
})

export default connect(mapStateToProps, mapDispatchToProps)(TranslatableInput)
