import { REHYDRATE } from 'redux-persist/constants'
import * as types from 'constants/ResourceActionTypes'

export const initialState = {
    isLoaded: false,
}

export default function app(
    state = initialState,
    action
) {
    switch (action.type) {
        // FETCH
        case types.FETCH_RESOURCES_REQUEST:
            return {
                ...state,
                isLoaded: false,
            }

        case types.FETCH_RESOURCES_SUCCESS:
            return {
                ...state,
                isLoaded: true,
            }

        case types.FETCH_RESOURCES_FAILURE:
            return {
                ...state,
                isLoaded: false,
            }

        case REHYDRATE:
            return {
                ...state,
                isLoaded: true,
            }

            // DEFAULT

        default:
            return state
    }
}
