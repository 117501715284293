import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import TimebarConfig from './TimebarConfig'

const TimebarAxis = ({ axis, format }) => {
    const renderEveryOtherLabel = axis.length > TimebarConfig.MANY_AXIS_POINT_THRESHOLD

    return (
        <div className="c-timebar-row c-timebar-segmented-row">
            { axis.map((axisPoint, i) => (
                <div
                    className={classnames('c-timebar-axis-seg', `c-timebar-axis-count-${axis.length}`)}
                    key={axisPoint}
                >
                    {
                        (!renderEveryOtherLabel || (renderEveryOtherLabel && i % TimebarConfig.AXIS_LABEL_SKIPS === 0))
                        && (<label>{moment(axisPoint, 'x').utc().format(format(axisPoint))}</label>)
                    }
                </div>
            )) }
        </div>
    )
}

TimebarAxis.propTypes = {
    axis: PropTypes.array.isRequired,
    format: PropTypes.func.isRequired,
}

export default memo(TimebarAxis)
