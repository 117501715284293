import React, { useEffect, useState, useRef } from 'react'

import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import t, { isTranslatableMarket } from 'utilities/translate'

import Button from 'components/Button/Button'
import ArtistTranslationModalManager from 'containers/ArtistTranslationModalManager/ArtistTranslationModalManager'
import Popover from 'components/Popover/Popover'

const TranslatableArtistLookup = (props) => {
    const {
        marketId,
        children,
        artists,
        showReleaseArtistsTranslationModal,
        showTrackArtistsTranslationModal,
        source,
        onTranslationSave,
        artistTranslations,
        disableTranslations,
    } = props
    const [enableTranslateBtn, setEnableTranslateBtn] = useState(false)
    const [selectedArtists, setSelectedArtists] = useState(artists || [])
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const displayTranslationOptions = isTranslatableMarket(marketId) && !disableTranslations
    const artistInputRef = useRef(null)

    const buttonClassName = classnames(
        'c-button-inline',
        {
            'c-button-inline-disabled': !enableTranslateBtn,
        }
    )

    useEffect(() => {
        if (displayTranslationOptions) {
            setEnableTranslateBtn(selectedArtists.length > 0
                && selectedArtists.filter(artist => artist.isFixed === true).length !== selectedArtists.length)
        }
    }, [selectedArtists])

    const onChangeInput = (updatedArtists) => {
        setSelectedArtists(updatedArtists)
        setEnableTranslateBtn(selectedArtists.length > 0)
    }

    const onNewOptionClick = () => {
        // If a new option is added then the button will always be enabled
        // Can't access the total from this event only the new option
        // This is fine as the updated list will be supplied to the onChange event
        setEnableTranslateBtn(true)
    }

    const handleHover = (isHover) => {
        setIsPopoverOpen(isHover)
    }

    const onButtonClick = () => {
        setSelectedArtists(artistInputRef.current.props.value)
        if (enableTranslateBtn) {
            return (source === 'release.artistLookup')
                ? showReleaseArtistsTranslationModal()
                : showTrackArtistsTranslationModal()
        }
        return false
    }

    const hasTranslations = artistTranslations.length > 0

    const getInputGroup = () => (
        <div className="input-group">
            {React.Children.map(children, child => React.cloneElement(child, {
                className: classnames(child.props.className, 'c-artist-lookup-translate'),
                ref: artistInputRef,
                onChange: (...params) => {
                    child.props.onChange(...params)
                    onChangeInput(...params)
                },
                onNewOptionClick: (...params) => {
                    child.props.onNewOptionClick(...params)
                    onNewOptionClick()
                },
            }))}
            <div
                className="input-group-append"
                onMouseEnter={() => {
                    handleHover(true)
                }}
                onMouseLeave={() => {
                    handleHover(false)
                }}
            >
                <Button
                    className={buttonClassName}
                    onClick={onButtonClick}
                >
                    { hasTranslations ? t('editTranslationsButtonText') : t('inputTranslateButtonText')}
                </Button>
            </div>
        </div>
    )

    if (displayTranslationOptions) {
        const modalId = (source === 'release.artistLookup') ? 'translatable-release-artist-lookup'
            : 'translatable-track-artist-lookup'

        return (
            <div className="c-translatable-artist-lookup">
                { isPopoverOpen ? (
                    <Popover
                        className="c-translatable-popover"
                        text={t('inputArtistLookupTranslateTooltipText')}
                        fixBodyToBottom
                        rightArrow
                    >
                        {getInputGroup()}
                    </Popover>
                ) : (
                    <>
                        {getInputGroup()}
                    </>
                )}
                <ArtistTranslationModalManager
                    modalId={modalId}
                    source={source}
                    data={{
                        value: selectedArtists,
                    }}
                    onTranslationSave={onTranslationSave}
                    artists={artists}
                    artistTranslations={artistTranslations}
                />
            </div>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}

TranslatableArtistLookup.propTypes = {
    marketId: PropTypes.number.isRequired,
    artists: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    children: PropTypes.node,
    showTrackArtistsTranslationModal: PropTypes.func,
    showReleaseArtistsTranslationModal: PropTypes.func,
    onTranslationSave: PropTypes.func,
    disableTranslations: PropTypes.bool,
}

export default TranslatableArtistLookup
