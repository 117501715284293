import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './_radioGroup.module.scss'

/**
 * Radio
 *
 * Radio buttons
 *
 * Styleguide: components.inputs.radio
 */
export default class RadioGroup extends Component {
    static propTypes = {
        checkedValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        children: PropTypes.node.isRequired,
        grid: PropTypes.bool,
        name: PropTypes.string.isRequired,
        align: PropTypes.oneOf(['row', 'column']),
        onChange: PropTypes.func,
        className: PropTypes.string,
    }

    static defaultProps = {
        align: 'row',
    }

    render() {
        const {
            name,
            checkedValue,
            onChange,
            children,
            grid,
            align,
            className: customClassName,
        } = this.props

        const className = classnames(
            customClassName,
            {
                [styles.grid]: !!grid,
                [styles.column]: !grid && align === 'column',
            }
        )

        return (
            <div className={className}>
                {React.Children.map(children, child => React.cloneElement(child, {
                    name,
                    onChange,
                    checked: child.props.value === checkedValue,
                }))}
            </div>
        )
    }
}
