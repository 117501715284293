import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { currentDate } from 'utilities/currentDate'
import t from 'utilities/translate'
import EditProfileTourDatesListElement from './EditProfileTourDatesListElement'

const EditProfileTourDatesList = ({
    tourDates, removeTour, toursIdsInDeletingState,
}) => {
    const [upcomingDatesSelected, setUpcomingDatesSelected] = useState(true)
    const { upcomingTourDates, pastTourDates } = tourDates.reduce((accumulator, tourDate) => (
        moment(tourDate.date, 'YYYY-MM-DD').valueOf() - currentDate() >= 0
            ? { ...accumulator, upcomingTourDates: [...accumulator.upcomingTourDates, tourDate] }
            : { ...accumulator, pastTourDates: [tourDate, ...accumulator.pastTourDates] }
    ),
    { upcomingTourDates: [], pastTourDates: [] })
    const tourDatesList = upcomingDatesSelected ? upcomingTourDates : pastTourDates
    const upcomingTourDatesButtonClasses = classNames(
        'c-epf-tour-dates-list-tabs-button',
        {
            ' c-epf-tour-dates-list-tabs-button--active': upcomingDatesSelected,
        }
    )
    const pastTourDatesButtonClasses = classNames(
        'c-epf-tour-dates-list-tabs-button',
        {
            ' c-epf-tour-dates-list-tabs-button--active': !upcomingDatesSelected,
        }
    )
    return (
        <div>
            <ul className="c-epf-tour-dates-list-tabs">
                <li className="c-epf-tour-dates-list-tabs-item">
                    <button
                        className={upcomingTourDatesButtonClasses}
                        onClick={() => { setUpcomingDatesSelected(true) }}
                    >
                        {t('formEditProfileFormUpcomingToursButtonText')}
                    </button>
                </li>
                <li className="c-epf-tour-dates-list-tabs-item">
                    <button
                        className={pastTourDatesButtonClasses}
                        onClick={() => { setUpcomingDatesSelected(false) }}
                    >
                        {t('formEditProfileFormPastToursButtonText')}
                    </button>
                </li>
            </ul>
            <ul className="c-epf-tour-dates-list">
                {tourDatesList.map(tourDate => (
                    <EditProfileTourDatesListElement
                        key={tourDate.id}
                        {...tourDate}
                        removeTour={removeTour}
                        toursIdsInDeletingState={toursIdsInDeletingState}
                    />
                ))}
            </ul>
        </div>
    )
}

EditProfileTourDatesList.propTypes = {
    tourDates: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string,
            venue: PropTypes.string,
            city: PropTypes.string,
            country: PropTypes.shape({
                name: PropTypes.string,
            }),
            ticketsUrl: PropTypes.string,
            id: PropTypes.number,
        })
    ),
    removeTour: PropTypes.func,
    toursIdsInDeletingState: PropTypes.arrayOf(PropTypes.number),
}

export default EditProfileTourDatesList
