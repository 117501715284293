import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import t, { tmarkdown } from 'utilities/translate'
import { releasesSelector, filterReleases } from 'selectors/releases'
import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'
import Pills from 'containers/Navs/Pills'
import ReleasesContainer from 'containers/Releases/Releases'
import { artistCanCreateRelease } from 'selectors/users'
import ButtonBanner from 'components/ButtonBanner/ButtonBanner'
import Frame from 'components/Frame/Frame'
import CreateReleaseBanner from 'containers/CreateReleaseBanner/CreateReleaseBanner'
import Alert from 'components/Alert/Alert'
import PartnersWidget from 'containers/PartnersWidget/PartnersWidget'
import { scrollUpToTop } from 'hooks/useScrollToTopEffect'

class Releases extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        numReleases: PropTypes.number.isRequired,
        numReleasesNotCompleted: PropTypes.number.isRequired,
        numReleasesInProgress: PropTypes.number.isRequired,
        numReleasesLive: PropTypes.number.isRequired,
        numReleasesTakenDown: PropTypes.number.isRequired,
        artistCanCreateReleases: PropTypes.bool.isRequired,
    }

    componentDidMount() {
        scrollUpToTop()
    }

    getPills() {
        const {
            numReleases,
            numReleasesNotCompleted,
            numReleasesInProgress,
            numReleasesLive,
            numReleasesTakenDown,
        } = this.props

        return [
            {
                href: '/releases/all',
                label: `${t('viewReleasesNavAll')} (${numReleases})`,
                active: true,
            },
            {
                href: '/releases/uncomplete',
                label: `${t('viewReleasesNavUncompleted')} (${numReleasesNotCompleted})`,
            },
            {
                href: '/releases/progress',
                label: `${t('viewReleasesNavProgress')} (${numReleasesInProgress})`,
            },
            {
                href: '/releases/live',
                label: `${t('viewRelasesNavLive')} (${numReleasesLive})`,
            },
            {
                href: '/releases/takedown',
                label: `${t('viewReleaseNavTakedown')} (${numReleasesTakenDown})`,
            },
        ]
    }

    render() {
        const {
            isLoading,
            location: { pathname },
            match: { params: { filter } },
            numReleases,
            numReleasesNotCompleted,
            numReleasesInProgress,
            numReleasesLive,
            numReleasesTakenDown,
            artistCanCreateReleases,
        } = this.props

        const pills = this.getPills().map(pill => ({
            ...pill,
            value: pill.href,
            active: pill.href === pathname,
        }))

        let noReleasesMessage = null
        switch (filter) {
            case 'uncomplete':
                noReleasesMessage = numReleasesNotCompleted < 1 ? t('viewReleaseUncompletedEmptyTitle') : null
                break
            case 'progress':
                noReleasesMessage = numReleasesInProgress < 1 ? t('viewReleaseProgressEmptyTitle') : null
                break
            case 'live':
                noReleasesMessage = numReleasesLive < 1 ? t('viewReleaseLiveEmptyTitle') : null
                break
            case 'takedown':
                noReleasesMessage = numReleasesTakenDown < 1 ? t('viewReleaseTakedownEmptyTitle') : null
                break
            default:
                break
        }

        const className = classnames('v-releases')

        const renderDisplay = () => {
            if (isLoading) {
                return (
                    <div className="v-releases-loading">
                        <Spinner size="medium">{t('globalLoading')}</Spinner>
                    </div>
                )
            } else if (numReleases < 1) {
                return (
                    <div className="v-releases">
                        <div className="v-releases-empty">
                            <h3 className="v-releases-title">
                                {t('viewReleasesNoReleases')}
                            </h3>
                            <div
                                dangerouslySetInnerHTML={{ __html: tmarkdown('viewReleasesNoReleasesText') }}
                                className="v-releases-empty-desc"
                            />
                            <Frame
                                borderImageId="dune-3"
                                contentTint="light"
                                className="v-releases-empty-frame"
                            >
                                <Button
                                    href="/create"
                                    size="large"
                                    className="v-releases-empty-frame-button"
                                    disabled={!artistCanCreateReleases}
                                >
                                    <h3>
                                        {t('viewReleasesCreateRelease')}
                                    </h3>
                                </Button>
                            </Frame>
                        </div>
                    </div>
                )
            } else if (noReleasesMessage) {
                return (
                    <Alert type="info" centerContent>
                        <span>{noReleasesMessage}</span>
                    </Alert>
                )
            } else {
                return <ReleasesContainer filter={filter} noReleasesMessage={noReleasesMessage} />
            }
        }

        return (
            <div className={className}>
                { numReleases > 0 ? (
                    <div className="v-releases-header">
                        {artistCanCreateReleases && (
                            <ButtonBanner href="/create" borderImageId="water-2">
                                {t('viewReleasesCreateButton')}
                            </ButtonBanner>
                        )}

                        <h1>{t('viewReleasesTitle')}</h1>
                        <Pills items={pills} />
                    </div>

                ) : null }

                { renderDisplay() }

                { numReleases > 0 && (
                    <div>
                        <CreateReleaseBanner />
                        <PartnersWidget />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const releases = releasesSelector(state)

    return {
        isLoading: state.releases.isFetching || state.albums.isFetching || state.tracks.isFetching,
        numReleases: releases.length,
        numReleasesNotCompleted: filterReleases(releases, 'uncomplete').length,
        numReleasesInProgress: filterReleases(releases, 'progress').length,
        numReleasesLive: filterReleases(releases, 'live').length,
        numReleasesTakenDown: filterReleases(releases, 'takedown').length,
        artistCanCreateReleases: artistCanCreateRelease(state),
    }
}

export default connect(mapStateToProps)(withRouter(Releases))
