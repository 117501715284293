import { modalActionTypes } from 'actions/ModalActions'

/**
 * @type {{currentModal: { modalId: '', modalComponent: ReactComponent, modalProps: object,
 *          useBackdrop: bool, clickBackdropToClose: bool }|null, displayingModal: boolean,
 *          modalQueue: []}}
 */
const initialState = {
    modalQueue: [],
    displayingModal: false,
    currentModal: null,
}

export default (state = initialState, action) => {
    if (action.type === modalActionTypes.QUEUE_MODAL) {
        const newQueue = [...state.modalQueue, action.payload]
        return {
            modalQueue: newQueue,
            displayingModal: true,
            currentModal: newQueue[0],
        }
    } else if (action.type === modalActionTypes.HIDE_MODAL) {
        const newQueue = state.modalQueue.filter(modal => modal.modalId !== action.payload.modalId)
        const displayingModal = newQueue.length > 0
        return {
            modalQueue: newQueue,
            displayingModal,
            currentModal: displayingModal ? newQueue[0] : null,
        }
    }

    return state
}
