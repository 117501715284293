import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as PropTypes from 'prop-types'

import Icon from 'components/Icon/Icon'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

export default class Footer extends Component {
    static propTypes = {
        urls: PropTypes.object,
        isAuthenticated: PropTypes.bool,
        isMaintenanceMode: PropTypes.bool,
        menuItems: PropTypes.object,
        actions: PropTypes.object,
        locale: PropTypes.object.isRequired,
        isGermanLocale: PropTypes.bool.isRequired,
        artistIsEnhanced: PropTypes.bool,
        isUserLoaded: PropTypes.bool,
    }

    componentDidMount() {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'reactFooterLoaded',
            })
        }
    }

    render() {
        const {
            urls,
            isAuthenticated,
            isMaintenanceMode,
            menuItems: {
                primary: primaryItems,
                secondary: secondaryItems,
            },
            actions,
            isGermanLocale,
            locale,
            artistIsEnhanced,
            isUserLoaded,
        } = this.props
        const { t } = locale

        let firstMenu
        let secondMenu

        if (isMaintenanceMode || !isUserLoaded) {
            firstMenu = []
            secondMenu = null
        } else if (isAuthenticated) {
            firstMenu = [
                ...secondaryItems.desktop[artistIsEnhanced ? 'enhancedLinks' : 'offboardedLinks'],
                ...primaryItems.desktop.auth,
            ]
            secondMenu = primaryItems.desktop[artistIsEnhanced ? 'enhancedLinks' : 'offboardedLinks']
        } else {
            firstMenu = [
                ...primaryItems.offboardedLinks,
            ]
        }

        const firstMenuLinks = () => firstMenu && firstMenu.map((menuItem) => {
            const {
                label, hrefKey, action, to,
            } = menuItem

            return (
                <li key={label}>
                    { action && <a onClick={() => actions[action]()}>{t(label)}</a> }
                    { !action && (
                        (typeof to !== 'undefined')
                            ? <Link to={to}>{t(label)}</Link>
                            : <a href={urls[hrefKey]}>{t(label)}</a>
                    )}
                </li>
            )
        })

        const secondMenuLinks = () => secondMenu && secondMenu.map((menuItem) => {
            const {
                label, hrefKey, to,
            } = menuItem
            const linksOpts = {}

            const target = (typeof to !== 'undefined') ? to : urls[hrefKey]
            if (hrefKey) {
                linksOpts.target = '_blank'
            }
            return (
                <li key={label}>
                    {hrefKey
                        ? <a href={target} {...linksOpts}>{t(label)}</a>
                        : <Link to={target}>{t(label)}</Link>}
                </li>
            )
        })

        const logoId = window.env.SPINNUP_IS_PRODUCTION ? 'spinnup_logo_2018' : 'spinnup_logo_2018_test'
        return (
            <footer className="c-footer">
                <div className="c-footer-container">
                    <a target="_blank" rel="noopener noreferrer" className="c-footer-home-logo" href="/">
                        <Icon id={logoId} fill="#FFFFFF" />
                    </a>

                    {firstMenu
                    && (
                        <nav className="c-footer-nav c-footer-nav-primary" role="navigation">
                            <ul>
                                { firstMenuLinks() }
                            </ul>
                        </nav>
                    )}
                    {secondMenu
                    && (
                        <nav className="c-footer-nav c-footer-nav-secondary" role="navigation">
                            <ul>
                                { secondMenuLinks() }
                            </ul>
                        </nav>
                    )}
                </div>

                <div>
                    <ul className="c-footer-social">
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href={urls.facebook}>
                                <FontAwesomeIcon
                                    id="facebook-icon"
                                    className="c-footer-social-icon"
                                    icon="faFacebookSquare"
                                    color="white"
                                />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href={urls.instagram}>
                                <FontAwesomeIcon
                                    id="instagram-logo"
                                    className="c-footer-social-icon"
                                    icon="faInstagram"
                                    color="white"
                                />
                            </a>
                        </li>
                        { !isGermanLocale
                        && (
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href={urls.twitter}>
                                    <Icon id="twitter_logo" />
                                </a>
                            </li>
                        )}
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href={urls.spotify}>
                                <Icon id="spotify_logo" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href={urls.youtube}>
                                <FontAwesomeIcon icon="faYoutube" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="c-footer-bottom-bar">
                    { !isMaintenanceMode
                        && (
                            <ul>
                                <li>{t('componentFooterAllRights')}</li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href={urls.terms}>
                                        {t('componentFooterTerm')}
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href={urls.privacy}>
                                        {t('componentFooterPrivacy')}
                                    </a>
                                </li>
                                <li>
                                    <div className="evidon-notice-link" />
                                </li>
                            </ul>
                        )
                    || (
                        <ul>
                            <li>{t('componentFooterAllRights')}</li>
                        </ul>
                    )}
                </div>
            </footer>
        )
    }
}
