import React from 'react'
import { Field as ReduxField } from 'redux-form'
import { Label, InputField, CountrySelectField } from 'components/EditProfileInputs/Inputs'
import { editProfileFields } from 'constants/EditProfileStructure'
import t from 'utilities/translate'

const { baseSection: { countryId, city } } = editProfileFields

const EditProfileLocation = () => (
    <div className="c-epf-inputs">
        <Label label={t('formEditProfileLocationLabel')} />
        <div className="c-epf-inputs-wrapper">
            <ReduxField
                name={countryId}
                placeholder={t('formEditProfileCountryPlaceholder')}
                component={CountrySelectField}
            />
            <ReduxField name={city} placeholder={t('formEditProfileCityPlaceholder')} component={InputField} />
        </div>
    </div>
)

export default EditProfileLocation
