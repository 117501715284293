import React from 'react'
import * as PropTypes from 'prop-types'
import { without } from 'lodash'
import SongwriterPublisher from 'components/TrackForm/Fields/SongwriterPublisher'
import { songWriterPublisherNonIdIdentifier } from 'utilities/SongwriterPublisher'

const SongwriterPublisherList = ({
    track,
    numTracks,
    publishers,
    onCopyPopup,
    onDeletePopup,
    publisherIsGlobal,
    copyPublisherToSiblings,
    onBlur,
    onChange,
}) => (
    <>
        {publishers.length > 0 && publishers.map((p, i) => (
            <SongwriterPublisher
                key={`${songWriterPublisherNonIdIdentifier(p, track)}-${i}`}
                publisher={p}
                showCopy={!publisherIsGlobal(p) && numTracks > 1}
                copyHandler={(event) => {
                    event.preventDefault()
                    onCopyPopup(() => {
                        copyPublisherToSiblings(p)
                        onBlur()
                    })
                }}
                deleteHandler={() => {
                    onDeletePopup(() => {
                        onChange(without(publishers, p))
                        onBlur()
                    })
                }}
            />
        ))}
    </>
)

SongwriterPublisherList.propTypes = {
    track: PropTypes.object.isRequired,
    numTracks: PropTypes.number.isRequired,
    publishers: PropTypes.array.isRequired,
    onCopyPopup: PropTypes.func.isRequired,
    onDeletePopup: PropTypes.func.isRequired,
    publisherIsGlobal: PropTypes.func.isRequired,
    copyPublisherToSiblings: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default SongwriterPublisherList
