import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import Divider from 'components/Divider/Divider'
import Spinner from 'components/Spinner/Spinner'
import DisplayBalance from 'components/Balance/DisplayBalance'
import Translate from 'components/Translate/translate'
import { BalanceContainerProps } from 'containers/Balance/Balance'

export default class Balance extends Component<BalanceContainerProps> {
    static propTypes = {
        fetchBalance: PropTypes.func.isRequired,
        balance: PropTypes.object.isRequired,
        isFetchingBalance: PropTypes.bool.isRequired,
        balanceErrors: PropTypes.bool.isRequired,
    }

    componentDidMount() {
        this.props.fetchBalance()
    }

    render() {
        const { isFetchingBalance, balance, balanceErrors } = this.props

        return (
            <article className="c-balance">
                <header>
                    <h2><Translate id="componentWithdrawFormHeading" /></h2>
                </header>

                { isFetchingBalance
                    ? (
                        <section className="c-balance-amount c-balance-loading">
                            <Spinner size="large" />
                        </section>
                    ) : <DisplayBalance balance={balance} balanceErrors={balanceErrors} />}

                <Divider imageId="dune-1" />
            </article>
        )
    }
}
