import validator, {
    isEmpty,
    matchRegex,
} from './rules'

const simpleErroRules = {
    name: [
        isEmpty('formArtistValidationNameNotEmpty'),
    ],
    slug: [
        // TODO once the BE will respond a KEY, we need to change that !!!!!
        isEmpty('formArtistValidationSlugNotEmpty'),
        matchRegex(/[^A-Za-z0-9\-]+/, 'formArtistValidationSlugChars'),
    ],
}

const warningRules = {}

export const getErrors = validator.bind(null, simpleErroRules)
export const getWarnings = validator.bind(null, warningRules)
