import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import t from 'utilities/translate'
import Spinner from 'components/Spinner/Spinner'
import TotalsList from 'components/TotalsList/TotalsList'
import ErrorOverlayMessage from 'components/ErrorOverlayMessage/ErrorOverlayMessage'

export default class TrendDspTotals extends Component {
    static propTypes = {
        dspTotals: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        saleType: PropTypes.oneOf(['stream', 'download']),
        hasReleases: PropTypes.bool.isRequired,
        failedFetching: PropTypes.bool.isRequired,
    };

    render() {
        const {
            dspTotals,
            isLoading,
            saleType,
            hasReleases,
            failedFetching,
        } = this.props

        const title = saleType === 'stream' ? t('totalStreamsTitle') : t('totalDownloadsTitle')
        const isDisabled = (failedFetching || (!hasReleases && !failedFetching))

        return (
            <div className="c-trends-dsp-totals">
                <h1>{title}</h1>
                {isLoading
                    && <Spinner size="small">{t('globalLoading')}</Spinner>
                || (
                    <div>
                        <TotalsList data={dspTotals} displayTotalRow imageId="dune-2" />

                        <ErrorOverlayMessage
                            message={
                                !hasReleases && !failedFetching
                                    ? t('trendsTotalExample')
                                    : t('trendsTotalUnavailable')
                            }
                            enabled={isDisabled}
                        />
                    </div>
                )}
            </div>

        )
    }
}
