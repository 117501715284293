import { ApiPost } from 'models/api/post'

export class Post implements ApiPost {
    active: boolean = false

    file: string | null = null

    id: number = -1

    languageId: string = ''

    marketId: number = -1

    moreText: string = ''

    text: string = ''

    title: string = ''

    url: string = ''

    constructor(apiPost: ApiPost) {
        Object.assign(this as Post, apiPost)
    }
}
