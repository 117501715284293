import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import Frame from 'components/Frame/Frame'
import Button from 'components/Button/Button'

export default class ButtonBanner extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        href: PropTypes.string,
        borderImageId: PropTypes.string,
    };

    render() {
        const {
            children,
            className,
            href,
            borderImageId,
        } = this.props

        const buttonClassName = classnames(
            'c-button-banner',
            className
        )

        return (
            <div className={buttonClassName}>
                <Frame borderImageId={borderImageId}>
                    <Button href={href}>{children}</Button>
                </Frame>
            </div>
        )
    }
}
