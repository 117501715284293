import React, { useEffect, useRef } from 'react'
import * as PropTypes from 'prop-types'
import Translate from 'components/Translate/translate'
import HyperWalletTransferMethodDropIn
    from 'components/HyperwalletTransferMethodDropin/HyperWalletTransferMethodDropIn'
import ViewWalletAccountDetails from 'components/EarningsViewWalletAccountDetails/ViewWalletAccountDetails'
import { Button, TextInput } from 'components/index'
import Field from 'components/Field/Field'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import WithdrawFeesDisplay from 'components/EarningsWithdrawFeesDisplay/WithdrawFeesDisplay'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import Alert from 'components/Alert/Alert'
import {
    URL_EARNINGS_ACCOUNT_CREATE_TRM_TITLE,
} from 'constants/AppUrls'
import { EVENT_PAGE_VIEW, sendEventToDataLayer } from 'utilities/analytics'

const DEFAULT_TRANSFER_METHOD_NAME = 'My Withdrawal Method'

export const formatHyperwalletDropInTrmObject = trmObject => ({
    name: trmObject.transferMethodName,
    ref: trmObject.token,
    type: trmObject.type,
})

const UpdateWalletAccountTransferMethod = ({
    user,
    walletAccount,
    countries,
    onCancel,
    onFetchWalletAccount,
    onEditDetails,
    onSubmit,
}) => {
    const { t } = useLocaleContext()
    const inputRef = useRef()
    const onTransferMethodComplete = (formData) => {
        let selectedName = inputRef.current.getValue()

        if (!selectedName || selectedName.length === '') {
            selectedName = DEFAULT_TRANSFER_METHOD_NAME
        }

        onSubmit({
            transferMethodName: selectedName,
            ...formData,
        })
    }

    useEffect(() => {
        const { pathname, origin } = window.location
        const pagePath = `${pathname}/create`
        const pageUrl = `${origin}${pagePath}`
        const pageTitle = URL_EARNINGS_ACCOUNT_CREATE_TRM_TITLE
        const eventObj = {
            pagePath,
            pageUrl,
            pageTitle,
            userId: user.id,
            country: user.countryId,
            market: user.market,
        }

        sendEventToDataLayer(EVENT_PAGE_VIEW, eventObj)
    }, [])

    const hasExistingMethods = walletAccount.hasTransferMethod()

    return (
        <>
            <Alert type="custom" customIcon="faPaypal">
                <TranslateMarkdown id="earningsWithdrawInfoPaypalWithdraw" />
            </Alert>
            <h3 className="c-wallet-transfer-method-title">
                <span><Translate id="earningsWithdrawHeaderCreateTransferMethod" /></span>
                {
                    hasExistingMethods ? <Button onClick={onCancel}><Translate id="formButtonCancel" /></Button> : null
                }
            </h3>
            {
                hasExistingMethods
                    ? null : (
                        <ViewWalletAccountDetails
                            countries={countries}
                            onEdit={onEditDetails}
                            walletAccount={walletAccount}
                        />
                    )
            }
            <div className="c-wallet-transfer-method-form">
                <Field
                    label={t('earningsWithdrawLabelTransferMethodName')}
                    helpText={t('earningsWithdrawTextTransferMethodName')}
                >
                    <TextInput ref={inputRef} />
                </Field>
                <HyperWalletTransferMethodDropIn
                    userToken={walletAccount.getPspRef()}
                    authToken={walletAccount.getAuth().token}
                    userCountry={walletAccount.getAddress().country}
                    userId={user.id}
                    localeId={user.languageId.toLowerCase()}
                    fetchWalletAccount={onFetchWalletAccount}
                    onTransferMethodComplete={onTransferMethodComplete}
                />
            </div>
            <WithdrawFeesDisplay userCountryId={walletAccount.getAddress().country} />
        </>
    )
}

UpdateWalletAccountTransferMethod.propTypes = {
    user: PropTypes.object.isRequired,
    walletAccount: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onFetchWalletAccount: PropTypes.func.isRequired,
    onEditDetails: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default UpdateWalletAccountTransferMethod
