import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import {
    resendUserPhoneMfaCode as resendUserPhoneMfaCodeAction, types as mfaTypes,
    validateUserPhoneMfaCode as validateUserPhoneMfaCodeAction,
} from 'actions/MfaActions'
import { connect } from 'react-redux'
import VerifyCode from 'components/mfa/VerifyCode'
import { Redirect, withRouter } from 'react-router-dom'
import './_mfa.scss'
import { SubmissionError } from 'redux-form'

export const MfaVerificationCodeViewBase = ({
    componentTitle,
    location,
    validateUserPhoneMfaCode,
    resendUserPhoneMfaCode,
}) => {
    const {
        userPhone,
        apiMFAValidate,
        apiMFAResend,
    } = (location.state || {})

    const [isVerifyLoading, setIsVerifyLoading] = useState(false)
    const [isResendLoading, setIsResendLoading] = useState(false)

    if (!apiMFAValidate) {
        return (<Redirect to="/start" />)
    }

    const onVerifyCode = (formValues) => {
        setIsVerifyLoading(true)
        // Upon successful validate the MFA_VERIFY_SUCCESS action will trigger the authenticated state and index.tsx
        // will redirect to the correct page
        return validateUserPhoneMfaCode(apiMFAValidate.url, formValues.verificationCode).then((action) => {
            if (action && action.type === mfaTypes.MFA_VERIFY_SUCCESS) {
                setIsVerifyLoading(false)
            }
        }).catch((action) => {
            if (action && action.type === mfaTypes.MFA_VERIFY_FAILURE) {
                setIsVerifyLoading(false)
                throw new SubmissionError({
                    verificationCode: 'viewMfaValidationVerificationCodeInvalid',
                })
            }
        })
    }

    const onResendCode = () => {
        setIsResendLoading(true)
        return resendUserPhoneMfaCode(apiMFAResend.url).then((action) => {
            if (setIsResendLoading) {
                setIsResendLoading(false)
            }
            return action
        })
    }

    return (
        <VerifyCode
            componentTitle={componentTitle}
            phoneNumber={userPhone}
            isVerifyLoading={isVerifyLoading}
            isResendLoading={isResendLoading}
            onSubmit={onVerifyCode}
            onResendCode={onResendCode}
            displayCloseButton={false}
            closeButton={null}
        />
    )
}

MfaVerificationCodeViewBase.propTypes = {
    componentTitle: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    validateUserPhoneMfaCode: PropTypes.func.isRequired,
    resendUserPhoneMfaCode: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
    validateUserPhoneMfaCode: validateUserPhoneMfaCodeAction,
    resendUserPhoneMfaCode: resendUserPhoneMfaCodeAction,
}

export default withRouter(connect(null, mapDispatchToProps)(MfaVerificationCodeViewBase))
