import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field as ReduxField, propTypes as formPropTypes, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import MfaFrame from 'components/mfa/MfaFrame'
import Button from 'components/Button/Button'
import VerifyCodeInput from 'components/mfa/VerifyCodeInput'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { mfaResendTimeout } from 'constants/Mfa'
import { mfa, enrolment, enterPhoneNumber } from 'constants/MfaRoutes'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { mfaVerifyCodeFormName } from 'constants/MfaFormNames'
import { validateVerificationCode } from 'validations/mfa'

const VerifyCode = ({
    handleSubmit,
    invalid,
    componentTitle,
    isEnrolling,
    closeButton,
    displayCloseButton = false,
    displayBackButton = false,
    phoneNumber,
    isVerifyLoading,
    isResendLoading,
    onResendCode,
}) => {
    const { t } = useLocaleContext()

    const [resendError, setResendError] = useState(null)
    const [resent, setResent] = useState(false)
    const [resendCodeTimeout, setResendCodeTimeout] = useState(-1)

    useEffect(() => (
        () => {
            if (resendCodeTimeout > 0) {
                clearTimeout(resendCodeTimeout)
            }
        }
    ), [])

    const isLoading = isVerifyLoading || isResendLoading

    const handleResend = () => {
        onResendCode()
            .then((action) => {
                setResendCodeTimeout(
                    setTimeout(() => {
                        setResendError(null)
                    }, mfaResendTimeout)
                )

                // todo  need to encapsulate this form better but this'll catch a failure of consistently named actions
                // todo  for now at least
                if (action.type.endsWith('FAILURE')) {
                    setResendError('viewMfaResendCodeError')
                } else {
                    setResent(true)
                }

                return action
            })
    }

    const escapedPhoneNumber = (phoneNumber || '').replace(/\*/g, '\\*')
    return (
        <div className="v-mfa">
            <div className="v-mfa-text">
                {componentTitle}
            </div>
            <MfaFrame>
                <form className="v-mfa-content" onSubmit={handleSubmit}>
                    {
                        // todo this will need updating in line with a v2 JIRA
                        displayBackButton ? (
                            <p className="v-mfa-back">
                                <Link to={isEnrolling ? `/${mfa}/${enrolment}/${enterPhoneNumber}` : '/start'}>
                                    <FontAwesomeIcon className="v-mfa-back-icon" icon="faArrowLeft" />
                                    {t('viewMfaButtonBack')}
                                </Link>
                            </p>
                        ) : null
                    }
                    <TranslateMarkdown id="viewMfaVerifyDescription" args={[escapedPhoneNumber]} />
                    <ReduxField
                        required
                        name="verificationCode"
                        label={t('viewMfaVerifyLabel')}
                        placeholder={t('viewMfaVerifyPlaceholder')}
                        disabled={isLoading}
                        component={VerifyCodeInput}
                    />
                    <div className="v-mfa-resend">
                        {resendError && <p className="v-mfa-resend-error">{t(resendError)}</p>}
                        {resent ? (
                            <span>{t('viewMfaVerifyResent')}</span>
                        ) : (
                            <Button
                                type="button"
                                className="v-mfa-resend-button"
                                onClick={handleResend}
                                isLink
                                disabled={isLoading || !!resendError}
                            >
                                {t('viewMfaVerifyResend')}
                            </Button>
                        )}
                    </div>
                    <div className="v-mfa-buttons-wrapper">
                        <Button
                            isSubmit
                            isLoading={isVerifyLoading}
                            disabled={isLoading || invalid}
                            className="v-mfa-button"
                        >
                            {t('viewMfaButtonVerify')}
                        </Button>
                        {displayCloseButton && closeButton}
                    </div>
                    <div className="v-mfa-support-link">
                        <TranslateMarkdown id="viewMfaVerificationSupport" useSpan />
                    </div>
                </form>
            </MfaFrame>
        </div>
    )
}

VerifyCode.propTypes = {
    ...formPropTypes,
    componentTitle: PropTypes.node.isRequired,
    closeButton: PropTypes.node,
    displayCloseButton: PropTypes.bool,
    phoneNumber: PropTypes.string,
    isVerifyLoading: PropTypes.bool.isRequired,
    isResendLoading: PropTypes.bool.isRequired,
    onResendCode: PropTypes.func.isRequired,
}

export default reduxForm({
    form: mfaVerifyCodeFormName,
    validate: validateVerificationCode,
})(VerifyCode)
