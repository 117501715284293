import React from 'react'
import * as PropTypes from 'prop-types'
import { sample } from 'lodash'

import classnames from 'classnames'

const Divider = ({
    imageId,
}) => {
    let dividerImageId = imageId
    if (dividerImageId === null) {
        dividerImageId = Divider.getRandomImageId()
    }

    const classname = classnames(
        'c-divider',
        `background-${dividerImageId}`
    )

    return <hr className={classname} />
}

Divider.imageIds = [
    'clear',
    'dune-1',
    'dune-5',
    'paint',
    'sun-1',
    'distortion-1',
    'dune-2',
    'equalizer-1',
    'spiral-1',
    'sun-2',
    'distortion-2',
    'dune-3',
    'equalizer-2',
    'spiral-2',
    'water-1',
    'distortion-3',
    'dune-4',
    'equalizer-3',
    'sreen-noise',
    'water-2',
]

Divider.propTypes = {
    imageId: PropTypes.oneOf(Divider.imageIds),
}

Divider.getRandomImageId = () => sample(Divider.imageIds)

Divider.defaultProps = {
    imageId: null,
}

export default Divider
