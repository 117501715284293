import albums from 'reducers/albums'
import app from 'reducers/app'
import auth from 'reducers/auth'
import countries from 'reducers/countries'
import genres from 'reducers/genres'
import languages from 'reducers/languages'
import locales from 'reducers/locales'
import posts from 'reducers/posts'
import profile from 'reducers/profile'
import releases from 'reducers/releases'
import releaseTypes from 'reducers/releaseTypes'
import reset from 'reducers/reset'
import sales from 'reducers/sales'
import scouts from 'reducers/scouts'
import services from 'reducers/services'
import socialTrends from 'reducers/socialTrends'
import tracks from 'reducers/tracks'
import trends from 'reducers/trends'
import urls from 'reducers/urls'
import users from 'reducers/users'
import userStatistics from 'reducers/userStatistics'
import versions from 'reducers/versions'
import wallet from 'reducers/wallet'
import dspLookup from 'reducers/dspLookup'
import alerts from 'modules/alert'
import artist from 'modules/artist'
import audioLocales from 'modules/audio-locales'
import form from 'modules/forms'
import menus from 'modules/menus'
import modal from 'modules/modal'
import modals from 'reducers/modals'
import draftProfile from 'reducers/draftProfile'
import backgroundColors from 'reducers/backgroundColors'
import fonts from 'reducers/fonts'
import translationLanguages from 'reducers/translationLanguages'
import preview from 'reducers/preview'
import notifications from 'reducers/notifications'
import popups from 'reducers/popups'
import crmPreferences from 'reducers/crmPreferences'
import tourDatesForm from 'reducers/tourDatesForm'
import editImages from 'reducers/editImages'
import messages from 'reducers/messages'
import countriesPrefixes from 'reducers/countriesPrefixes'

export default {
    albums,
    app,
    auth,
    countries,
    genres,
    languages,
    locales,
    posts,
    releaseTypes,
    profile,
    releases,
    reset,
    sales,
    scouts,
    services,
    socialTrends,
    tracks,
    trends,
    urls,
    users,
    userStatistics,
    versions,
    wallet,
    dspLookup,
    alerts,
    artist,
    audioLocales,
    form,
    menus,
    modal,
    modals,
    draftProfile,
    backgroundColors,
    fonts,
    translationLanguages,
    preview,
    notifications,
    popups,
    crmPreferences,
    tourDatesForm,
    editImages,
    messages,
    countriesPrefixes,
}
