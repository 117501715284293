import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Table from 'components/Table/Table'
import Column from 'components/Table/Column'
import Alert from 'components/Alert/Alert'
import Spinner from 'components/Spinner/Spinner'
import t from 'utilities/translate'
import { WithdrawalHistoryContainerProps } from 'containers/Balance/WithdrawalHistory'

type WithdrawalHistoryProps = WithdrawalHistoryContainerProps

export default class WithdrawalHistory extends Component<WithdrawalHistoryProps> {
    static propTypes = {
        isFetching: PropTypes.bool.isRequired,
        transactions: PropTypes.array.isRequired,
        fetchTransactions: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.props.fetchTransactions()
    }

    renderHistory = () => {
        const { transactions } = this.props

        const formattedTransactions = transactions.map((transaction) => {
            const {
                type, currency, amount,
            } = transaction
            const formattedAmount = `${currency} ${(parseFloat(amount) * -1).toFixed(2)}`
            let description
            if (type === 'balance_adjustment') {
                description = t('earningsWithdrawHistoryBalanceAdjustment')
            } else if (transaction.psp) {
                if (transaction.psp.type === 'paypal') {
                    const { params } = transaction.psp
                    description = t('earningsWithdrawHistoryTransactionDescription', params.email)
                } else if (transaction.psp.type === 'hyperwallet') {
                    const { params } = transaction.psp
                    description = t('earningsWithdrawHistoryTransactionDescription', params.transferMethod.name)
                } else {
                    description = t('earningsWithdrawHistoryTransactionDescriptionUnknown')
                }
            } else {
                description = t('earningsWithdrawHistoryTransactionDescriptionUnknown')
            }

            return {
                ...transaction,
                formattedAmount,
                description,
                status: t(`componentWithdrawalHistoryStatus_${transaction.status}`),
            }
        })

        return (
            formattedTransactions.length === 0
                ? (
                    <Alert type="info">
                        <p><strong>{t('componentWithdrawalHistoryEmptyTitle')}</strong></p>
                        {
                            t('componentWithdrawalHistoryEmptyBody').length > 0
                            && <p>{t('componentWithdrawalHistoryEmptyBody')}</p>
                        }
                    </Alert>
                )
                : (
                    <div className="c-withdrawal-history-table">
                        <Table rows={formattedTransactions}>
                            <Column
                                prop="date"
                                heading={t('containerTransactionsColumnDate')}
                            />
                            <Column
                                prop="description"
                                heading={t('containerTransactionsColumnNote')}
                            />
                            <Column
                                prop="status"
                                heading={t('componentWithdrawalHistoryStatus')}
                            />
                            <Column
                                prop="formattedAmount"
                                heading={t('containerTransactionsColumnAmount')}
                            />
                        </Table>
                    </div>
                )
        )
    }

    render() {
        const { isFetching } = this.props

        return (
            <div className="c-withdrawal-history">
                {isFetching
                    ? <Spinner size="medium" />
                    : this.renderHistory()}
            </div>
        )
    }
}
