import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import Spinner from 'components/Spinner/Spinner'
import OverviewProfileNoDraft from 'components/OverviewProfile/OverviewProfileNoDraft'
import OverviewProfileUncompleted from 'components/OverviewProfile/OverviewProfileUncompleted'
import OverviewProfileCompleted from 'components/OverviewProfile/OverviewProfileCompleted'

const OverviewProfile = ({
    isLoaded,
    data: {
        name,
        slug,
        isPublishedAsPublic,
        releases,
        profileLinks,
        socialLinks,
        videos,
        biography,
        tourDates,
        gallery,
        hero,
    },
    openPopup,
    addNotification,
    getDraftProfile,
    artistId,
}) => {
    useEffect(() => {
        if (!isLoaded) {
            getDraftProfile(artistId)
        }
    }, [])

    const url = `${window.env.SPINNUP_PROFILE_URL}${slug}`

    const completedSections = !!releases + !!profileLinks + !!socialLinks
        + !!videos + !!gallery + !!tourDates + !!biography

    const hasDraftProfile = !!name && !!slug

    const getOverviewProfileComponent = () => {
        if (hasDraftProfile) {
            if (isPublishedAsPublic && completedSections >= 4) {
                return (
                    <OverviewProfileCompleted
                        name={name}
                        hero={hero}
                        url={url}
                        openPopup={openPopup}
                        addNotification={addNotification}
                        slug={slug}
                    />
                )
            }
            if (completedSections >= 2) {
                return (
                    <OverviewProfileUncompleted
                        name={name}
                        isPublishedAsPublic={isPublishedAsPublic}
                        url={url}
                        openPopup={openPopup}
                    />
                )
            }
        }
        return <OverviewProfileNoDraft />
    }

    return (isLoaded
        ? (
            <section className="c-overview-profile">
                {getOverviewProfileComponent()}
            </section>
        )
        : (
            <section className="c-overview-profile-loading">
                <Spinner size="medium" />
            </section>
        ))
}

OverviewProfile.propTypes = {
    getDraftProfile: PropTypes.func.isRequired,
    artistId: PropTypes.number.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    data: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        isPublishedAsPublic: PropTypes.bool,
        releases: PropTypes.number,
        profileLinks: PropTypes.number,
        socialLinks: PropTypes.number,
        videos: PropTypes.number,
        biography: PropTypes.bool,
        tourDates: PropTypes.number,
        gallery: PropTypes.number,
        hero: PropTypes.string,
    }),
    openPopup: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
}

export default OverviewProfile
