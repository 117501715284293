import React from 'react'
import * as PropTypes from 'prop-types'
import { useLocaleContext, withLocalisation } from 'contexts/localisation/localeProvider'

const MarketOrderSwitch = ({ type, components, marketId }) => {
    const { getLocaleFormOrder } = useLocaleContext()
    return getLocaleFormOrder(type, marketId).map((fieldName) => {
        if (!components[fieldName]) {
            // todo  this would be nice to re-add for bug spotting purposes but the adyen version of the address
            // todo  form is hitting this frequently
            // console.warn(`Market Order Switch - Provided key ${fieldName} with no matching component`)
            return (
                ''
            )
        } else {
            return React.cloneElement(
                components[fieldName],
                { key: `switchField_${marketId}_${type}_${fieldName}` }
            )
        }
    })
}

MarketOrderSwitch.propTypes = {
    type: PropTypes.string.isRequired,
    components: PropTypes.object.isRequired,
    marketId: PropTypes.number.isRequired,
}

export default withLocalisation(MarketOrderSwitch)
