import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
    Redirect, Route, RouteComponentProps, Switch, withRouter,
} from 'react-router-dom'
import classnames from 'classnames'
import withZenDeskWidget from 'hoc/Zendesk/ZenDeskWidget'
import Header from 'containers/Header/Header'
import Footer from 'containers/Footer/Footer'
import { fetchUsers } from 'actions/UserActions'
import { fetchReleaseTypes } from 'actions/ReleaseTypeActions'
import { fetchReleases } from 'actions/ReleaseActions'
import { unauth, logoutAndRedirectTo } from 'actions/AuthActions'
import { userSelector, artistEnhancedSelector } from 'selectors/users'
import useAppreciationEngine from 'hooks/useAppreciationEngine'
import Overview from 'views/Overview/Overview'
import ProfileEdit from 'views/Profile/Edit'
import Releases from 'views/Releases/Releases'
import Competition from 'views/Competition/Competition'
import Settings from 'views/Settings/Settings'
import ToastNotifications from 'containers/ToastNotifications/ToastNotifications'
import Popup from 'containers/Popup/Popup'
import { filterVisibleMessages } from 'selectors/messages'
import { URL_HOME } from 'constants/AppUrls'
import { FETCH_USERS_FAILURE } from 'constants/UserActionTypes'
import Spinner from 'components/Spinner/Spinner'
import ReleaseCreationGuidance from 'routes/RouteContainers/App/ReleaseCreationGuidance/ReleaseCreationGuidance'
import ReleaseConfirmation from 'routes/RouteContainers/App/ReleaseConfirmation/ReleaseConfirmation'
import { AppStoreState } from 'store/store-types'
import Trends from './App/Trends'
import Balance from './App/Balance'

const mapStateToProps = (state: AppStoreState) => ({
    user: userSelector(state),
    artistIsEnhanced: artistEnhancedSelector(state),
    messages: filterVisibleMessages(state),
    isEditingRelease: (window.location || { pathname: '' }).pathname.startsWith('/edit'),
    isAEReady: state.services.isReady,
})

const mapDispatchToProps = {
    fetchUsers,
    fetchReleaseTypes,
    fetchReleases,
    unauth,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ContainerProps = ConnectedProps<typeof connector>

type AppProps = ContainerProps & RouteComponentProps<any>

const App: React.FC<AppProps> = (props: AppProps) => {
    const {
        isAEReady,
        user,
        messages,
        isEditingRelease,
        history,
        artistIsEnhanced,
    } = props
    useAppreciationEngine(user, isAEReady)
    useEffect(() => {
        // mount
        props.fetchUsers().then((result: any) => {
            if (result.type === FETCH_USERS_FAILURE && result.error.httpStatusCode === 401) {
                logoutAndRedirectTo(URL_HOME, props.unauth, history)
            }
        })
        props.fetchReleaseTypes()
        props.fetchReleases()
    }, [])

    // todo isEditingRelease doesn't seem to be possible? Need to investigate refactoring this...
    const appClasses = classnames(
        {
            'l-app': !isEditingRelease,
            'l-edit-release': isEditingRelease,
            '-show-message': messages.length > 0 && !isEditingRelease,
        }
    )
    return (
        <div className={appClasses}>
            <Header artistIsEnhanced={!!artistIsEnhanced} isUserLoaded={!!user} />

            <main role="main">
                {
                    !user ? (
                        <Spinner />
                    ) : (
                        <Switch>
                            <Route path="/overview" render={() => <Overview />} />

                            {window.env.SPINNUP_ARTIST_SITE_CMS_ENABLED
                                ? (<Route path="/profile" render={() => <ProfileEdit />} />)
                                : null}

                            <Redirect exact from="/releases" to="/releases/all" />
                            <Route path="/releases/:filter" render={() => <Releases />} />
                            <Route path="/create" render={() => <ReleaseCreationGuidance />} />
                            <Route
                                path="/release/:releaseId/success"
                                render={() => <ReleaseConfirmation />}
                            />
                            <Route path="/competition/:scoutId" render={() => <Competition />} />
                            <Route path="/settings" render={() => <Settings user={user} />} />
                            <Route path="/balance" render={() => <Balance />} />
                            <Route path="/trends" render={() => <Trends artistIsEnhanced={artistIsEnhanced} />} />
                        </Switch>
                    )
                }

            </main>

            <ToastNotifications />
            <Popup />

            <Footer
                artistIsEnhanced={!!artistIsEnhanced}
                isUserLoaded={!!user}
                actions={{ logout: () => logoutAndRedirectTo(URL_HOME, props.unauth, history) }}
            />
        </div>
    )
}

export default connector(withRouter(
    withZenDeskWidget(App)
))
