import { FACEBOOK_GRAPH_URL } from 'constants/Services'

export const warnFetchError = (error) => {
    console.warn(error)
}

export const getFacebookPermissions = (userId, token) => fetch(`${FACEBOOK_GRAPH_URL}${userId}
/permissions?access_token=${token}`)
    .then(response => response.json())
    .catch((error) => {
        console.warn(error)
    })

export const getFacebookPages = (userId, token) => fetch(`${FACEBOOK_GRAPH_URL}${userId}`
    + `/accounts?access_token=${token}`)
    .then(response => response.json())

export const getInstagramBusinessAccount = (pageID, token) => fetch(`${FACEBOOK_GRAPH_URL}${pageID}`
    + `?fields=instagram_business_account&access_token=${token}`)
    .then(response => response.json())

export const mapInstagramPages = async (pages, token) => Promise.all(
    pages.map(async page => getInstagramBusinessAccount(page.id, token)
        .then(async (jsonInstagram) => {
            if (jsonInstagram.length < 1) {
                return Promise.reject()
            }

            if ('instagram_business_account' in jsonInstagram) {
                const instagramAccountID = jsonInstagram.instagram_business_account.id
                const label = await fetch(`${FACEBOOK_GRAPH_URL}${instagramAccountID}`
                + `/?fields=username&access_token=${token}`)
                    .then(response => response.json())
                    .then((jsonInstagramUsername) => {
                        if (jsonInstagramUsername.length < 1) {
                            return Promise.reject()
                        }
                        return jsonInstagramUsername.username
                    })
                    .catch(warnFetchError)
                return ({
                    value: instagramAccountID,
                    label,
                    getUrl: () => Promise.resolve(`https://instagram.com/${label}`),
                })
            }

            return false
        })
        .catch(warnFetchError))
)

export const getInstagramPages = async (pages, token) => mapInstagramPages(pages, token)
    .then(pages2 => pages2.filter(page => page !== false))

export const getSpotifyUrlFromArtistId = (artistId) => {
    const placeholderUrl = 'https://open.spotify.com/artist/{artistId}'
    const cleanIdClean = String(artistId).match(/^spotify\:artist\:(.*)$/i)

    if (!cleanIdClean) {
        return null
    }
    return placeholderUrl.replace('{artistId}', cleanIdClean[1])
}

export const getAppleUrlFromArtistId = (artistId) => {
    const placeholderUrl = 'https://music.apple.com/us/artist/{artistId}'

    return placeholderUrl.replace('{artistId}', artistId)
}

export const isAppleMusicUrlValid = url => /((https|http):\/\/)?music.apple.com\/([A-z]{2}\/)?artist\/[A-z\-]+\/[0-9]+/
    .test(url)

export const getSpotifyIdFromUrl = (url) => {
    const result = /^((https|http):\/\/)?open\.spotify\.com\/artist\/([a-zA-Z0-9]+)/gm.exec(url)

    if (result && typeof result[3] !== 'undefined') {
        return `spotify:artist:${result[3]}`
    }
    return null
}

export const getAppleIdFromUrl = (url) => {
    if (!url) {
        return null
    }
    const result = /https:\/\/music.apple.com\/([A-z]{2}\/)?artist\/([a-zA-Z0-9\/-]+)$/gm.exec(url)

    if (result && typeof result[2] !== 'undefined') {
        return result[2]
    }
    return null
}
