import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect, ConnectedProps } from 'react-redux'
import Alert from 'components/Alert/Alert'
import { fetchWithdrawFees as fetchWithdrawFeesAction } from 'actions/WalletActions'
import Translate from 'components/Translate/translate'
import Spinner from 'components/Spinner/Spinner'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import {
    HW_TRANSFER_BANK_ACCOUNT, HW_TRANSFER_BANK_CARD,
    HW_TRANSFER_PAYPAL_ACCOUNT, HW_TRANSFER_PREPAID_CARD,
    HW_TRANSFER_WIRE_ACCOUNT,
} from 'constants/HyperWalletTransferTypes'
import { TrmType } from 'models/walletAccount'
import { AppStoreState } from 'store/store-types'

export const camelizeKey = (id: string) => id.substr(0, 1).toLowerCase() + id.substr(1)
export const PAYPAL_FEES_KEY = camelizeKey('PP')
export const BANK_FEES_KEY = camelizeKey('BANK')
export const WIRE_FEES_KEY = camelizeKey('WIRE')

export const HW_TRM_TYPE_FEE_KEY_MAP: Record<TrmType, string | undefined> = {
    [HW_TRANSFER_BANK_ACCOUNT]: BANK_FEES_KEY,
    [HW_TRANSFER_PAYPAL_ACCOUNT]: PAYPAL_FEES_KEY,
    [HW_TRANSFER_WIRE_ACCOUNT]: WIRE_FEES_KEY,
    [HW_TRANSFER_BANK_CARD]: undefined,
    [HW_TRANSFER_PREPAID_CARD]: undefined,
}

type WithdrawFeesDisplayGivenProps = {
    userCountryId: string
}

const mapStateToProps = (state: AppStoreState) => ({
    withdrawFees: state.wallet.withdrawFees,
})

const mapDispatchToProps = {
    fetchWithdrawFees: fetchWithdrawFeesAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type WithdrawFeesDisplayProps = ConnectedProps<typeof connector> & WithdrawFeesDisplayGivenProps

export const WithdrawFeesDisplayComponent: React.FC<WithdrawFeesDisplayProps> = ({
    userCountryId,
    withdrawFees,
    fetchWithdrawFees,
}) => {
    const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false)

    useEffect(() => {
        if (!hasAttemptedFetch && !withdrawFees.isLoaded && !withdrawFees.isLoading) {
            fetchWithdrawFees().then(() => {
                setHasAttemptedFetch(true)
            }, () => {
                setHasAttemptedFetch(true)
            })
        }
    })

    let contentElem
    const countryKey = camelizeKey(userCountryId)
    if (withdrawFees.isLoading) {
        contentElem = <Spinner size="small" />
    } else if (withdrawFees.isLoaded) {
        const countryFees = withdrawFees.fees[countryKey]
        const paypalFees = countryFees ? countryFees[PAYPAL_FEES_KEY] : null
        const bankFees = countryFees ? countryFees[BANK_FEES_KEY] : null
        const wireFees = countryFees ? countryFees[WIRE_FEES_KEY] : null

        type FormattedFee = { currency: string, value: string }
        const formattedBankFees = bankFees
            ? Object.keys(bankFees)
                .reduce((acc, currency) => {
                    acc.push({
                        currency: currency.toUpperCase(),
                        value: bankFees[currency],
                    })
                    return acc
                }, [] as FormattedFee[])
            : [] as FormattedFee[]

        contentElem = (
            <div className="c-fee-body">
                <TranslateMarkdown id="earningsWithdrawFeesBody" />
                { paypalFees ? <TranslateMarkdown id="earningsWithdrawFeesBodyPaypal" /> : null }
                {formattedBankFees.map((fee, ind) => {
                    const key = `${ind}_${fee.currency}`
                    return (
                        <TranslateMarkdown
                            key={key}
                            id="earningsWithdrawFeesBodyBankAccount"
                            args={[fee.currency, fee.value]}
                        />
                    )
                })}
                { wireFees ? <TranslateMarkdown id="earningsWithdrawFeesBodyWireTransfer" /> : null }
            </div>
        )
    } else {
        contentElem = (
            <div className="c-fee-body">
                <TranslateMarkdown id="earningsWithdrawFeesLoadError" />
            </div>
        )
    }

    return (
        <Alert type="warning">
            <p><strong><Translate id="earningsWithdrawFeesHeader" /></strong></p>
            {
                contentElem
            }
        </Alert>
    )
}

WithdrawFeesDisplayComponent.propTypes = {
    userCountryId: PropTypes.string.isRequired,
    withdrawFees: PropTypes.any.isRequired,
    fetchWithdrawFees: PropTypes.func.isRequired,
}

export default connector(WithdrawFeesDisplayComponent)
