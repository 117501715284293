import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'

import Dropzone from 'components/Dropzone/Dropzone'
import Icon from 'components/Icon/Icon'
import Spinner from 'components/Spinner/Spinner'

export default class AudioDropzone extends Component {
    static propTypes = {
        uploadAudio: PropTypes.func.isRequired,
        isCreatingTracks: PropTypes.bool.isRequired,
        className: PropTypes.string,
        children: PropTypes.node,
    };

    onUpload = (files) => {
        this.props.uploadAudio(files)
    }

    render() {
        const {
            isCreatingTracks,
            children,
            className,
        } = this.props

        return (
            <Dropzone
                className={classnames(
                    'c-dropzone-audio',
                    className
                )}
                uploading={isCreatingTracks}
                onDrop={this.onUpload}
                fileTypes=".wav,.flac"
                multiple
            >
                {isCreatingTracks ? (
                    <div className="c-dropzone-spinner-wrapper">
                        <Spinner
                            className="c-dropzone-spinner"
                            size="medium"
                        />
                    </div>
                ) : (
                    <Icon
                        id="upload_icon"
                        className="c-dropzone-icon"
                    />
                )}

                {children}
            </Dropzone>
        )
    }
}
