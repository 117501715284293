import { scroller } from 'react-scroll'

export default function scrollIntoView(elementName, options = {}) {
    const offset = !window.matchMedia('(min-width: 960px)').matches ? -120 : -150

    scroller.scrollTo(elementName, {
        offset,
        duration: 250,
        smooth: true,
        ...options,
    })
}
