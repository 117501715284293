import React from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import { sample } from 'lodash'
import t from 'utilities/translate'

const TotalsList = ({
    data,
    displayTotalRow,
    imageId,
}) => {
    const listImageId = imageId === null
        ? TotalsList.getRandomImageId()
        : imageId

    const totalAmount = data.reduce((acc, item) => acc + item.total, 0)

    const className = classnames(
        'index',
        `background-${listImageId}`
    )

    return (
        <ul className="c-totals-list">
            { data.map((item, index) => {
                const percentageType = classnames(
                    'percentage-change',
                    {
                        positive: item.percentageIndicator === 'positive',
                        negative: item.percentageIndicator === 'negative',
                    }
                )
                return (
                    <li key={index}>
                        <div className={className}>{index + 1}</div>
                        <div className="totals">
                            <span className="name">{item.name}</span>
                            <span className="total">
                                {(item.percentageChange !== 0 && item.total !== 0)
                                && (item.percentageChange && item.percentageIndicator)
                                    && <span className={percentageType}>{item.percentageChange}%</span>}
                                {item.total}
                            </span>
                        </div>
                    </li>
                )
            })}
            {displayTotalRow
                && (
                    <li className="total-row">
                        <div className="totals">
                            <span className="total-title">{ t('totalListTotalLabel') }</span>
                            <span className="total">{totalAmount}</span>
                        </div>
                    </li>
                )}
        </ul>
    )
}

TotalsList.getRandomImageId = () => sample(TotalsList.imageIds)

TotalsList.imageIds = [
    'dune-5',
    'sun-1',
    'distortion-1',
    'dune-2',
    'sun-2',
    'dune-3',
    'water-1',
    'distortion-3',
]

TotalsList.propTypes = {
    data: PropTypes.array.isRequired,
    displayTotalRow: PropTypes.bool,
    imageId: PropTypes.oneOf(TotalsList.imageIds),
}

TotalsList.defaultProps = {
    displayTotalRow: true,
    imageId: null,
}

export default TotalsList
