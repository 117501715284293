import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userSelector } from 'selectors/users'
import { updateUser } from 'actions/UserActions'
import Field from 'components/Field/Field'
import TextInput from 'components/Inputs/TextInput'
import Spinner from 'components/Spinner/Spinner'
import Saved from 'components/Saved/Saved'
import MarketOrderSwitch from 'components/MarketOrderSwitch/MarketOrderSwitch'
import { withLocalisation } from 'contexts/localisation/localeProvider'
import { fetchReleaseTypes } from 'actions/ReleaseTypeActions'
import LocaleSelect from '../LocaleSelect/LocaleSelect'

class AccountSettingsContainer extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        user: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        updateUser: PropTypes.func.isRequired,
        fetchProducts: PropTypes.func.isRequired,
        locale: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props)
        this.state = { isDone: false }
    }

    onChangeLocaleId = (localeId) => {
        this.save({ localeId })
    }

    save(body) {
        this.setState({ isDone: false })

        return this.props.updateUser(body).then((action) => {
            if (action.payload) {
                this.props.fetchProducts(true)
                this.setState({ isDone: true })
            }
        })
    }

    render() {
        const {
            user, isLoading, errors, locale,
        } = this.props
        const { isDone } = this.state
        const { t } = locale

        return (
            <div className="c-account-settings">
                <header>
                    <h1>{t('containerAccountSettingsPanelTitleInfo')}</h1>
                    <div className="c-account-settings-status">
                        {isLoading
                            && (
                                <div className="c-account-settings-saving">
                                    <Spinner size="small" horizontal>{t('globalSaving')}</Spinner>
                                </div>
                            )}
                        {isDone && !isLoading && <Saved>{t('globalSaved')}</Saved>}
                    </div>
                </header>
                <div>

                    <MarketOrderSwitch
                        type="firstNameLastName"
                        marketId={user.marketId}
                        components={{
                            firstName: (
                                <Field
                                    label={t('containerAccountSettingsLabelFirstName')}
                                    error={errors.firstName}
                                >
                                    <TextInput
                                        value={user.firstName || ''}
                                        disabled
                                    />
                                </Field>
                            ),
                            lastName: (
                                <Field
                                    label={t('containerAccountSettingsLabelLastName')}
                                    error={errors.lastName}
                                >
                                    <TextInput
                                        value={user.lastName || ''}
                                        disabled
                                    />
                                </Field>
                            ),
                        }}
                    />

                    <LocaleSelect
                        label={t('containerAccountSettingsLabelCountry')}
                        value={user.localeId}
                        onChange={this.onChangeLocaleId}
                        displayLanguages
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    user: userSelector(state, props),
    isLoading: state.users.isUpdatingUser,
    errors: state.users.errors,
})

const mapDispatchToProps = {
    updateUser,
    fetchProducts: fetchReleaseTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalisation(AccountSettingsContainer))
