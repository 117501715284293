import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MultiSelect from 'components/Select/MultiSelect'

class CountryInputContainer extends Component {
    static propTypes = {
        countries: PropTypes.array.isRequired,
        selectedCountries: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    handleChange = (selectedCountries) => {
        this.props.onChange(selectedCountries)
    }

    render() {
        const {
            countries,
            selectedCountries,
        } = this.props

        const formatedCountries = countries
            .sort((a, b) => {
                if (a.countryName < b.countryName) return -1
                if (a.countryName > b.countryName) return 1
                return 0
            })
            .map(country => ({
                value: country.countryCode,
                label: country.countryName,
            }))

        const formatedSelectedCountries = selectedCountries
            .map(country => ({
                value: country.countryCode,
                label: country.countryName,
            }))

        return (
            <MultiSelect
                options={formatedCountries}
                value={formatedSelectedCountries}
                onChange={this.handleChange}
            />
        )
    }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CountryInputContainer)
