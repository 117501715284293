import { dropRightWhile, isEmpty } from 'lodash'

import * as types from 'constants/UserActionTypes'
import * as profileTypes from 'constants/ProfileActionTypes'
import { PUSH_SCOUT_SUCCESS } from 'constants/ScoutActionTypes'
import {
    REQUEST_SAVE,
    RECEIVE_SAVE_SUCCESS,
    RECEIVE_SAVE_FAILURE,
} from 'modules/artist'
import { formatErrors } from 'utilities/error'
import { setAppLocale } from 'utilities/translate'
import * as verificationTypes from 'constants/VerificationActionTypes'
import * as artistAliasTypes from 'constants/ArtistAliasActionTypes'
import { addDaysToDate, getDateUTC } from 'utilities/date'
import * as authTypes from 'constants/AuthActionTypes'
import User from 'models/user'

const initialState = {
    isFetching: false,
    isCreating: false,
    isUpdatingUser: false,
    isUpdatingFlags: false,
    isUpdatingPass: false,
    isUpdatingProfile: false,
    isUploadingProfile: false,
    isVerifyingEmail: false,
    gaClientId: null,
    successfullyVerifiedEmail: false,
    successfullyCreatedUser: false,
    user: undefined,
    fetchedAt: 0,
    passErrorMessage: undefined,
    passErrors: {},
    createErrors: {},
    errors: {},
    profileUploadErrors: {},
    profileUploadProgress: 0,
    isUnverifiedBlock: false,
    isOnboardingComplete: false,
}

function transform(payload) {
    return (new User({
        ...payload,
        languageId: payload.locale.languageId,
        countryId: payload.locale.countryId,
        artist: transformArtist(payload.artist),
        flags: payload.flags.reduce((obj, flag) => ({
            ...obj,
            [flag.flag.key]: flag.value,
        }), {}),
        providers: payload.providers.reduce((obj, provider) => ({
            ...obj,
            [provider.name]: provider,
        }), {}),
        urls: payload.locale.country.market.links.reduce((obj, link) => ({
            ...obj,
            [link.key]: link.value,
        }), {}),
        minimumPayment: payload.locale.country.minimumPayment,
        minimumPaymentFormatted: payload.locale.country.minimumPaymentFormatted,
        market: payload.locale.country.market.name,
        marketId: payload.locale.country.market.id,
        country: payload.locale.country.id,
    })).toJS() // todo eventually remove toJS to enable use of class functions
}

function transformArtist(artist) {
    const socialLinks = artist.links.filter(({ key }) => !key.startsWith('website'))
    const extraLinks = artist.links.filter(({ key }) => key.startsWith('website')).sort()

    const filteredExtraLinks = dropRightWhile(
        extraLinks,
        ({ value, key }) => key.startsWith('website') && !value
    )

    if (!isEmpty(extraLinks) && filteredExtraLinks.length < 5) {
        filteredExtraLinks.push({ key: `website${filteredExtraLinks.length + 1}`, value: '' })
    }

    if (isEmpty(extraLinks)) {
        filteredExtraLinks.push({ key: 'website1', value: '' })
    }

    return {
        ...artist,
        links: [
            ...socialLinks,
            ...filteredExtraLinks,
        ],
        files: artist.files.reduce((obj, file) => ({
            ...obj,
            [file.type]: file.url,
        }), {}),
    }
}

export default function user(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.FETCH_USERS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }

        case types.FETCH_USERS_SUCCESS:
            const payload = {
                ...action.payload[0],
                verificationStartedAt: action.payload[0].verificationStartedAt || getDateUTC(new Date()),
            }

            setAppLocale(payload.locale.languageId, payload.locale.countryId)

            const verificationExpiry = addDaysToDate(
                payload.verificationStartedAt,
                window.env.SPINNUP_EMAIL_VERIFICATION_TIMEOUT
            )

            const verificationExpired = getDateUTC(new Date()) > verificationExpiry
            return {
                ...state,
                isFetching: false,
                user: transform(payload),
                isUnverifiedBlock: !payload.emailVerified && verificationExpired,
                isOnboardingComplete: (
                    !window.env.ARTIST_ONBOARDING
                    || (payload.artist && typeof payload.artist.aliases !== 'undefined'
                        && payload.artist.aliases !== null)
                ),
                fetchedAt: new Date(),
            }

        case types.FETCH_USERS_FAILURE:
            return {
                ...state,
                isFetching: false,
            }

        // CREATE
        case types.CREATE_USER_REQUEST:
            return {
                ...state,
                isCreating: true,
                successfullyCreatedUser: false,
                createErrors: {},
            }

        case types.CREATE_USER_SUCCESS:
            return {
                ...state,
                isCreating: false,
                successfullyCreatedUser: true,
            }

        case types.CREATE_USER_FAILURE:
            return {
                ...state,
                isCreating: false,
                successfullyCreatedUser: false,
                createErrors: formatErrors(action.error.errors),
            }

        // UPDATE
        case types.UPDATE_USER_REQUEST:
            return {
                ...state,
                isUpdatingUser: true,

                errors: {},
            }

        case types.UPDATE_USER_SUCCESS:
            setAppLocale(action.payload.locale.languageId, action.payload.locale.countryId)
            const userRecord = transform(action.payload)
            // We don't retrieve the users role, so set them from state
            userRecord.roles = state.user.roles
            return {
                ...state,
                isUpdatingUser: false,
                user: userRecord,
            }

        case types.UPDATE_USER_FAILURE:
            return {
                ...state,
                isUpdatingUser: false,
                errors: formatErrors(action.error.errors),
            }

        // UPDATE FLAGS
        case types.UPDATE_USER_FLAGS_REQUEST:
            return {
                ...state,
                isUpdatingFlags: true,

                errors: {},
            }

        case types.UPDATE_USER_FLAGS_SUCCESS:
            setAppLocale(action.payload.locale.languageId, action.payload.locale.countryId)

            return {
                ...state,
                isUpdatingFlags: false,
                user: {
                    ...state.user,
                    languageId: action.payload.locale.languageId,
                    countryId: action.payload.locale.countryId,
                    flags: action.payload.flags.reduce((obj, flag) => ({
                        ...obj,
                        [flag.flag.key]: flag.value,
                    }), {}),
                },
            }

        case types.UPDATE_USER_FLAGS_FAILURE:
            return {
                ...state,
                isUpdatingFlags: false,
                errors: formatErrors(action.error.errors),
            }

        // PASSWORD
        case types.PASSWORD_REQUEST:
            return {
                ...state,
                isUpdatingPass: true,
                passErrorMessage: undefined,
                passErrors: {},
            }

        case types.PASSWORD_SUCCESS:
            return {
                ...state,
                isUpdatingPass: false,
            }

        case types.PASSWORD_FAILURE:
            return {
                ...state,
                isUpdatingPass: false,
                passErrorMessage: action.error.message,
                passErrors: formatErrors(action.error.errors),
            }

        // PROFILE
        case REQUEST_SAVE:
        case profileTypes.UPDATE_PROFILE_REQUEST_LEGACY:
            return {
                ...state,
                isUpdatingProfile: true,
                errors: {},
            }
        case RECEIVE_SAVE_SUCCESS:
        case profileTypes.UPDATE_PROFILE_SUCCESS_LEGACY:
            return {
                ...state,
                isUpdatingProfile: false,
                user: {
                    ...state.user,
                    artist: transformArtist(action.payload),
                },
            }
        case RECEIVE_SAVE_FAILURE:
        case profileTypes.UPDATE_PROFILE_FAILURE_LEGACY:
            return {
                ...state,
                isUpdatingProfile: false,
                errors: formatErrors(action.payload),
            }

        // UPLOAD PROFILE
        case profileTypes.UPLOAD_PROFILE_REQUEST:
            return {
                ...state,
                isUploadingProfile: true,
            }
        case profileTypes.UPLOAD_PROFILE_SUCCESS:
            return {
                ...state,
                isUploadingProfile: false,
                user: {
                    ...state.user,
                    artist: transformArtist({
                        ...state.user.artist,
                        files: action.payload,
                    }),
                },
                profileUploadErrors: initialState.profileUploadErrors,
            }

        case profileTypes.UPLOAD_PROFILE_FAILURE:
            return {
                ...state,
                isUploadingProfile: false,
                profileUploadErrors: { file: ['containerProfileUploadFileError'] },
            }

        case profileTypes.UPLOAD_PROFILE_PROGRESS:
            return {
                ...state,
                profileUploadProgress: action.payload,
            }

        case profileTypes.UPLOAD_PROFILE_VALIDATION_ERROR:
            return {
                ...state,
                isUploadingProfile: false,
                profileUploadErrors: { file: [action.payload.error] },
            }

        // PUSH
        case PUSH_SCOUT_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    artist: {
                        ...state.user.artist,
                        pushesQuantity: state.user.artist.pushesQuantity - 1,
                    },
                },
            }

        case verificationTypes.VERIFICATION_CHECK_REQUEST:
            return {
                ...state,
                isVerifyingEmail: true,
            }

        case verificationTypes.VERIFICATION_CHECK_SUCCESS:
            return {
                ...state,
                isVerifyingEmail: false,
                successfullyVerifiedEmail: true,
                isUnverifiedBlock: false,
            }

        case verificationTypes.VERIFICATION_CHECK_FAILURE:
            return {
                ...state,
                isVerifyingEmail: false,
                successfullyVerifiedEmail: false,
            }

        case artistAliasTypes.CREATE_ARTIST_ALIAS_SUCCESS:
            return {
                ...state,
                isOnboardingComplete: true,
            }

        case artistAliasTypes.UPDATE_ARTIST_ALIAS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    artist: {
                        ...state.user.artist,
                        aliases: action.payload,
                    },
                },
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        // DEFAULT
        default:
            return state
    }
}
