import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Fields as ReduxFields } from 'redux-form'
import Field from 'components/Field/Field'
import countryPrefixPropType from 'constants/CountryPrefixPropType'
// import importantCountries from 'constants/CountryPrefixImportantCountries'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
// import PhonePrefixSelect from './PhonePrefixSelect'
import PhoneNumberInput from './PhoneNumberInput'
import './_phoneInputGroup.scss'

export const PhoneInputGroupComponent = (props) => {
    const {
        countriesPrefixes,
        // isConfirmation,
        // disableCountryCode,
        label,
        numberPlaceholder,
        isLoading,
    } = props

    // const {
    //     input: prefixInput,
    //     meta: prefixMeta,
    // } = props.phonePrefixCountryCode

    const {
        input: numberInput,
        meta: numberMeta,
    } = (props.phoneNumber || props.phoneNumberConfirmation)

    const { touched, error: numberError } = numberMeta
    // const { error: prefixError } = prefixMeta

    const { t } = useLocaleContext()

    // const error = touched && (numberError || prefixError)
    const error = touched && numberError
    const disabled = isLoading || !countriesPrefixes.length
    const phoneInputGroupClassName = classnames('c-phone-input-group', {
        disabled,
        error: !!error,
    })
    // const phonePrefixSelectClassName = classnames('c-phone-prefix-select', {
    //     'select-error': !!error,
    // })
    const phoneNumberInputClassName = classnames('c-input', 'c-phone-number-input', {
        error: !!error,
    })

    return (
        <div className="c-phone-input-group-field">
            <Field
                elementName="field-phone-input-group"
                label={label}
                error={error ? t(error) : null}
            >
                <div className={phoneInputGroupClassName}>
                    {/* <PhonePrefixSelect */}
                    {/*    input={prefixInput} */}
                    {/*    meta={prefixMeta} */}
                    {/*    required */}
                    {/*    name="phonePrefixCountryCode" */}
                    {/*    className={phonePrefixSelectClassName} */}
                    {/*    disabled={disabled || isConfirmation || disableCountryCode} */}
                    {/*    countriesPrefixes={countriesPrefixes} */}
                    {/*    importantCountries={importantCountries} */}
                    {/* /> */}
                    <PhoneNumberInput
                        input={numberInput}
                        meta={numberMeta}
                        required
                        placeholder={numberPlaceholder}
                        className={phoneNumberInputClassName}
                        disabled={disabled}
                    />
                </div>
            </Field>
        </div>
    )
}

const basePropTypes = {
    countriesPrefixes: PropTypes.arrayOf(PropTypes.shape(countryPrefixPropType)),
    isConfirmation: PropTypes.bool,
    disableCountryCode: PropTypes.bool,
    label: PropTypes.string,
    numberPlaceholder: PropTypes.string,
    isLoading: PropTypes.bool,
}

PhoneInputGroupComponent.propTypes = {
    ...basePropTypes,
}

const PhoneInputGroup = ({
    countriesPrefixes,
    isConfirmation,
    label,
    numberPlaceholder,
    isLoading,
    disableCountryCode,
}) => {
    const numberFieldName = `phoneNumber${isConfirmation ? 'Confirmation' : ''}`
    return (
        <ReduxFields
            names={['phonePrefixCountryCode', numberFieldName]}
            component={PhoneInputGroupComponent}
            countriesPrefixes={countriesPrefixes}
            label={label}
            numberPlaceholder={numberPlaceholder}
            isLoading={isLoading}
            isConfirmation={isConfirmation}
            disableCountryCode={disableCountryCode}
        />
    )
}

PhoneInputGroup.propTypes = {
    ...basePropTypes,
}

export default PhoneInputGroup
