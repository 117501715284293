import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import VerificationResendButton from 'components/VerificationResendButton/VerificationResendButton'
import Frame from 'components/Frame/Frame'
import {
    URL_VERIFY_MAIL_BLOCKED,
    URL_VERIFY_MAIL_BLOCKED_TITLE,
} from 'constants/AppUrls'
import { EVENT_PAGE_VIEW, sendEventToDataLayer } from 'utilities/analytics'
import TranslateMarkdown from 'components/Translate/TranslateMarkdown'
import { useLocaleContext } from 'contexts/localisation/localeProvider'
import { Redirect, withRouter } from 'react-router-dom'

VerifyBlock.propTypes = {
    location: PropTypes.object.isRequired,
}

function VerifyBlock({ location }) {
    const { t } = useLocaleContext()

    const {
        userEmail,
        apiEmailResend,
    } = (location.state || {})

    useEffect(() => {
        const eventObj = {
            pagePath: URL_VERIFY_MAIL_BLOCKED,
            pageUrl: `${window.location.origin}${URL_VERIFY_MAIL_BLOCKED}`,
            pageTitle: URL_VERIFY_MAIL_BLOCKED_TITLE,
        }

        sendEventToDataLayer(
            EVENT_PAGE_VIEW,
            eventObj
        )
    }, [])

    if (!apiEmailResend) {
        return (<Redirect to="/start" />)
    }

    return (
        <div className="v-verify v-verify-block">
            <Frame
                borderImageId="dune-2"
                contentTint="light"
            >
                <div className="v-verify-content">
                    <header>
                        <h1 className="h3">{t('verificationBlockPageTitle')}</h1>
                    </header>

                    <TranslateMarkdown id="verificationBlockPageEmailLink" args={[userEmail]} />
                    <p><VerificationResendButton signedUrl={apiEmailResend.url} /></p>
                </div>
            </Frame>
        </div>
    )
}

export default withRouter(VerifyBlock)
