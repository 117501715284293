import { compose } from 'redux'
import { connect } from 'react-redux'
import {
    reduxForm, getFormValues, blur, change, touch,
} from 'redux-form'
import { getTracksFormName } from 'modules/common/tracks'
import { getAudioLocaleMap, isFetchingAudioLocales } from 'modules/audio-locales'
import { getById, syncValidate } from 'modules/tracks'
import {
    removeTrack,
    selectTrack,
    uploadFile,
} from 'actions/TrackActions'
import { isPlaceholderTitle } from 'utilities/release'
import { songWriterPublisherNonIdIdentifier } from 'utilities/SongwriterPublisher'

import { versionsSelector, isFetchingVersions as isFetchingVersionSelector } from 'selectors/versions'

import { addPopup } from 'actions/PopupActions'
import { getPopupDataConfirmCopyPublisher, getPopupDataDeletePublisher } from 'utilities/popups'

import TrackForm from 'components/TrackForm/TrackForm'
import { queueModal } from 'actions/ModalActions'
import Dialog from 'components/common/modals/Dialog'

const mapStateToProps = (state, { track, allForms }) => {
    const formName = getTracksFormName(track)
    const formValues = getFormValues(formName)(state) || {}
    const hasIsrc = !!track.isrc || !!formValues.isrc
    return {
        form: formName,
        numForms: allForms.length,
        getTrackById: id => getById(state, id),
        initialValues: {
            ...track,
            title: isPlaceholderTitle(track.title) ? '' : track.title,
            translations: track.translations,
            artistTranslations: [],
        },
        shouldExpandAdvanceSetting: hasIsrc,
        accessToken: state.auth.credentials.accessToken,
        getTrackFormById: id => state.form[id],
        versions: versionsSelector(state),
        isFetchingVersions: isFetchingVersionSelector(state),
        audioLocaleMap: getAudioLocaleMap(state),
        isFetchingAudioLocales: isFetchingAudioLocales(state),
    }
}

const mapDispatchToProps = dispatch => ({
    removeTrack: () => dispatch(removeTrack()),
    selectTrack: trackId => dispatch(selectTrack(trackId)),
    showCopyPopup: (onAccept) => { dispatch(addPopup(getPopupDataConfirmCopyPublisher(onAccept))) },
    showDeleteSongwriterPopup: onAccept => dispatch(queueModal(
        'delete-songwriter',
        Dialog,
        getPopupDataDeletePublisher(onAccept),
        { useBackdrop: true, clickBackdropToClose: true }
    )),
    uploadFile: (albumId, trackId, file) => dispatch(uploadFile(albumId, trackId, file)),
    addPublisherToTrack: (track, trackForm, newPublisher) => {
        if (!track.publishers
            .map(p => songWriterPublisherNonIdIdentifier(p, track))
            .includes(songWriterPublisherNonIdIdentifier(newPublisher, track))
        ) {
            dispatch(change(
                getTracksFormName(trackForm.values),
                'publishers',
                [...track.publishers, newPublisher]
            ))
            dispatch(blur(
                getTracksFormName(trackForm.values),
                'publishers',
                [...track.publishers, newPublisher]
            ))
            return true
        }
        return false
    },
    addTranslatedTitleToTrack: (titleTranslations, track) => {
        dispatch(change(
            getTracksFormName(track),
            'titleTranslations',
            titleTranslations
        ))
        dispatch(blur(
            getTracksFormName(track),
            'titleTranslations',
            titleTranslations
        ))
        dispatch(touch(
            getTracksFormName(track),
            'title'
        ))
        return true
    },
    addTranslatedArtistsToTrack: (artistTranslations, track) => {
        dispatch(change(
            getTracksFormName(track),
            'artistTranslations',
            artistTranslations
        ))
        dispatch(blur(
            getTracksFormName(track),
            'artistTranslations',
            artistTranslations
        ))
        dispatch(touch(
            getTracksFormName(track),
            'artists'
        ))
        return true
    },
    validateTrackTitleWithMetadata: (track) => {
        dispatch(syncValidate(getTracksFormName(track), 'title'))
    },
    validateArtistNamesWithMetadata: (track) => {
        dispatch(syncValidate(getTracksFormName(track), 'artists'))
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const savePublisherToTrackId = (publisher, trackForm) => dispatchProps.addPublisherToTrack(
        stateProps.getTrackById(trackForm.values.id).toObject(),
        trackForm,
        publisher
    )

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        copyPublisherToSiblings: (publisher) => {
            const effectedTracks = []
            ownProps.allForms
                .filter(form => getTracksFormName(form.values) !== stateProps.form) // Not the current Form
                .forEach(
                    (trackForm) => {
                        dispatchProps.selectTrack(trackForm.values.id)
                        if (savePublisherToTrackId(publisher, trackForm)) {
                            effectedTracks.push(trackForm.id)
                        }
                    }
                )
            dispatchProps.selectTrack(stateProps.form)
            return effectedTracks
        },
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        enableReinitialize: false,
        destroyOnUnmount: false,
        shouldValidate: () => false,
        shouldError: () => false,
    })
)(TrackForm)
