import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Button from 'components/Button/Button'
import t, { tmarkdown } from 'utilities/translate'

export default class Settings extends Component {
    static propTypes = {
        release: PropTypes.object.isRequired,
        onTakedown: PropTypes.func.isRequired,
    }

    renderManagement = () => {
        const { onTakedown } = this.props

        return (
            <>
                <div className="c-release-settings-actions">
                    <div className="c-release-settings-remove">
                        <h4>{t('componentReleaseSettingsHeadingRemove')}</h4>
                        <p>{t('componentReleaseSettingsBulletCaution')}</p>
                        <Button onClick={onTakedown}>{t('componentReleaseSettingsButtonRemove')}</Button>
                    </div>
                </div>
            </>
        )
    }

    renderRecordContract = () => {
        const { release: { recordContract: { contactEmail } } } = this.props

        return (
            <div className="c-release-settings-recordContract">
                <h4>{t('componentReleaseSettingsRecordContractHeading')}</h4>
                <div dangerouslySetInnerHTML={{
                    __html: tmarkdown('componentReleaseSettingsRecordContractBody', contactEmail),
                }}
                />
            </div>
        )
    }

    render() {
        const { release } = this.props

        return (
            <div className="c-release-settings">
                { release.hasRecordContract ? this.renderRecordContract() : this.renderManagement() }
            </div>
        )
    }
}
