import { connect } from 'react-redux'

import PartnersWidget from 'components/PartnersWidget/PartnersWidget'
import { urlsSelector } from 'selectors/urls'
import { withLocalisation } from 'contexts/localisation/localeProvider'

const mapStateToProps = (state) => {
    const urls = urlsSelector(state)

    return {
        url: urls.publicWebsitePartners,
    }
}

export default connect(mapStateToProps)(withLocalisation(PartnersWidget))
