import React, { Component } from 'react'

export default class extends Component {
    render() {
        return (
            <div>
                Loading...
            </div>
        )
    }
}
