import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'

import { usePrevious } from 'utilities/hooks'
import Field from 'components/Field/Field'
import Checkbox from 'components/Checkbox/Checkbox'
import Button from 'components/Button/Button'
import Spinner from 'components/Spinner/Spinner'
import Saved from 'components/Saved/Saved'
import { useLocaleContext } from 'contexts/localisation/localeProvider'

const CrmSubscriptions = (props) => {
    const {
        isFetchingData,
        isUpdating,
        failedToFetch,

        getUserSubscriptions,
        subscribeToMailingLists,
        unsubscribeFromMailingLists,
        unsubscribeFromAll,
        userSubscriptions,
    } = props

    const [enableSaveBtn, setEnableSaveBtn] = useState(false)
    const [showSavedTick, setShowSavedTick] = useState(false)
    const [references, setReferences] = useState({})
    const { t, tmarkdown } = useLocaleContext()

    useEffect(() => {
        // Get all available mailing lists
        getUserSubscriptions()
    }, [])

    useEffect(() => {
        // Create references for each mailing list
        setReferences(userSubscriptions.reduce((acc, current) => ({
            ...acc,
            [current.providerId]: React.createRef(),
        }), {}))
    }, [userSubscriptions])

    // Display Saved tick for 5 seconds
    const previousIsUpdating = usePrevious(isUpdating)
    useEffect(() => {
        if (previousIsUpdating && !isUpdating) {
            setShowSavedTick(true)
            setTimeout(() => setShowSavedTick(false), 5000)
        }
    }, [isUpdating])

    const onSave = () => {
        const requiresSubscription = userSubscriptions
            .filter((mailingList) => {
                const checkboxRef = references[mailingList.providerId].current
                return mailingList.subscribed !== checkboxRef.getValue() && checkboxRef.getValue() === true
            })
            .map(mailingList => mailingList.providerId)

        const requiresUnsubscription = userSubscriptions
            .filter((mailingList) => {
                const checkboxRef = references[mailingList.providerId].current
                return mailingList.subscribed !== checkboxRef.getValue() && checkboxRef.getValue() === false
            })
            .map(mailingList => mailingList.providerId)

        if (requiresSubscription.length) {
            subscribeToMailingLists(requiresSubscription)
        }

        if (requiresUnsubscription.length) {
            unsubscribeFromMailingLists(requiresUnsubscription)
        }

        setEnableSaveBtn(false)
    }

    const resendDoiEmail = mailingList => () => subscribeToMailingLists(mailingList.providerId)

    const onUnsubscribeFromAll = () => {
        Object.keys(references).forEach(key => references[key].current.setValue(false))
        unsubscribeFromAll()
    }

    return (
        <div className="c-crm-subscriptions">
            <header>
                <h1 dangerouslySetInnerHTML={{ __html: tmarkdown('componentCrmSubscriptionsHeading') }} />
                <div className="c-crm-subscriptions-status">
                    {!isUpdating ? null : (
                        <div className="c-crm-subscriptions-saving">
                            <Spinner size="small" horizontal>{t('globalSaving')}</Spinner>
                        </div>
                    )}

                    {(!isUpdating && showSavedTick) && <Saved>{t('globalSaved')}</Saved>}
                </div>
            </header>

            <div
                className="c-crm-subscriptions-preamble"
                dangerouslySetInnerHTML={{ __html: tmarkdown('componentCrmSubscriptionsPreamble') }}
            />

            {isFetchingData && <Spinner />}

            {!isFetchingData && failedToFetch && (
                <div
                    className="c-crm-subscriptions-error"
                    dangerouslySetInnerHTML={{ __html: tmarkdown('componentCrmSubscriptionsFailedToFetch') }}
                />
            )}

            {(!isFetchingData && !failedToFetch) && (
                <>
                    {userSubscriptions.map(mailingList => (
                        <Field
                            key={mailingList.providerId}
                            className={mailingList.isDoubleOptInPending ? 'c-crm-subscriptions-doi' : ''}
                        >
                            <Checkbox
                                ref={references[mailingList.providerId]}
                                label={t(`componentCrmSubscriptionsList_${mailingList.identifier}`)}
                                checked={mailingList.subscribed}
                                disabled={mailingList.isDoubleOptInPending}
                                onChange={() => setEnableSaveBtn(true)}
                            />
                            {mailingList.isDoubleOptInPending && (
                                <div className="c-crm-subscriptions-doi-info">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: tmarkdown('componentCrmSubscriptionsDoiText'),
                                        }}
                                    />

                                    <Button onClick={resendDoiEmail(mailingList)} disabled={isUpdating}>
                                        {t('componentCrmSubscriptionsBtnResendDoi')}
                                    </Button>
                                </div>
                            )}
                        </Field>
                    ))}

                    <div className="c-crm-subscriptions-btns">
                        <Button onClick={onSave} disabled={isUpdating || !enableSaveBtn}>
                            {t('componentCrmSubscriptionsBtnSave')}
                        </Button>

                        <Button onClick={onUnsubscribeFromAll} disabled={isUpdating}>
                            {t('componentCrmSubscriptionsBtnUnsubscribeAll')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}

CrmSubscriptions.propTypes = {
    isFetchingData: PropTypes.bool,
    isUpdating: PropTypes.bool,
    failedToFetch: PropTypes.bool.isRequired,

    getUserSubscriptions: PropTypes.func.isRequired,
    subscribeToMailingLists: PropTypes.func.isRequired,
    unsubscribeFromMailingLists: PropTypes.func.isRequired,
    unsubscribeFromAll: PropTypes.func.isRequired,
    userSubscriptions: PropTypes.array,
}

export default CrmSubscriptions
