import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getMenu } from 'modules/menus'
import { urlsSelector } from 'selectors/urls'
import { unauth } from 'actions/AuthActions'
import { withLocalisation } from 'contexts/localisation/localeProvider'
import Header from 'components/Header/Header'

const mapStateToProps = (state, ownProps) => {
    const { isAuthenticated } = state.auth

    const menuItems = getMenu({
        isAuthenticated,
    })(state)

    return {
        artistIsEnhanced: ownProps.artistIsEnhanced,
        menuItems,
        isAuthenticated,
        displayMenu: ownProps.displayMenu,
        urls: urlsSelector(state),
        isShowingModal: !!state.modals.currentModal && state.modals.currentModal.useBackdrop,
    }
}

const mapDispatchToProps = {
    unauth,
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalisation(withRouter(Header)))
