import { createSelector } from 'reselect'
import { blacklistedGenres } from 'constants/enums/GenreEnums'
import { AppStoreState } from 'store/store-types'

export const getGenres = createSelector(
    (state: AppStoreState) => state.genres.genres,
    (state: AppStoreState) => state.users.user,
    (genres, user) => genres
        .filter((genre) => {
            if (undefined !== user) {
                return !(blacklistedGenres[user.marketId]
                    && blacklistedGenres[user.marketId].includes(`genre_${genre.id}`))
            }
            return true
        })
)

export const isFetchingGenres = (state: AppStoreState) => state.genres.isFetching
