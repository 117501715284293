import * as types from 'constants/CrmActionTypes'
import { hydrateModel } from 'models/mailingList'
import * as authTypes from 'constants/AuthActionTypes'

const initialState = {
    mailingLists: [],
    isFetchingMailingLists: false,
    failedFetchingMailingLists: false,

    userSubscriptions: [],
    isFetchingUserSubscriptions: false,
    failedFetchingUserSubscriptions: false,

    isUpdating: false,
    failedToUpdate: false,
}

export default function crmPreferences(state = initialState, action) {
    switch (action.type) {
        // Mailing lists
        case types.CRM_FETCH_MAILINGLISTS_REQUEST:
            return {
                ...state,
                isFetchingMailingLists: true,
                failedFetchingMailingLists: false,
            }

        case types.CRM_FETCH_MAILINGLISTS_SUCCESS:
            return {
                ...state,
                isFetchingMailingLists: false,
                failedFetchingMailingLists: false,
                mailingLists: action.payload.map(data => hydrateModel(data)),
            }

        case types.CRM_FETCH_MAILINGLISTS_FAILURE:
            return {
                isFetchingMailingLists: false,
                failedFetchingMailingLists: true,
            }

        // User preferences
        case types.CRM_FETCH_USER_SUBSCRIPTIONS_REQUEST:
            return {
                ...state,
                isFetchingUserSubscriptions: true,
                failedFetchingUserSubscriptions: false,
            }

        case types.CRM_FETCH_USER_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                isFetchingUserSubscriptions: false,
                failedFetchingUserSubscriptions: false,
                userSubscriptions: action.payload.map(data => hydrateModel(data)),
            }

        case types.CRM_FETCH_USER_SUBSCRIPTIONS_FAILURE:
            return {
                isFetchingUserSubscriptions: false,
                failedFetchingUserSubscriptions: true,
            }

        // Subscribe
        case types.CRM_SUBSCRIBE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            }

        case types.CRM_SUBSCRIBE_SUCCESS: {
            return {
                ...state,
                userSubscriptions: [
                    ...state.userSubscriptions,
                    ...action.payload.map(data => hydrateModel(data)),
                ],
                isUpdating: false,
                failedToUpdate: false,
            }
        }

        case types.CRM_SUBSCRIBE_FAILURE:
            return {
                ...state,
                isUpdating: false,
                failedToUpdate: true,
            }

        // Unsubscribe
        case types.CRM_UNSUBSCRIBE_REQUEST:
        case types.CRM_UNSUBSCRIBE_ALL_REQUEST:
            return {
                ...state,
                isUpdating: true,
            }

        case types.CRM_UNSUBSCRIBE_SUCCESS: {
            const userSubscriptions = state.userSubscriptions
                .filter(subscription => subscription.providerId !== action.meta.id)

            return {
                ...state,
                userSubscriptions,
                isUpdating: false,
                failedToUpdate: false,
            }
        }

        case types.CRM_UNSUBSCRIBE_ALL_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                failedToUpdate: false,
                userSubscriptions: [],
            }

        case types.CRM_UNSUBSCRIBE_FAILURE:
        case types.CRM_UNSUBSCRIBE_ALL_FAILURE:
            return {
                ...state,
                isUpdating: false,
                failedToUpdate: true,
            }

        // Double opt-in
        case types.CRM_COMPLETE_DOI_REQUEST:
            return {
                ...state,
                isUpdating: true,
                failedToUpdate: false,
            }

        case types.CRM_COMPLETE_DOI_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                failedToUpdate: false,
            }

        case types.CRM_COMPLETE_DOI_FAILURE:
            return {
                ...state,
                isUpdating: false,
                failedToUpdate: true,
            }

        case authTypes.UNAUTH:
            return { ...initialState }

        default:
            return state
    }
}
