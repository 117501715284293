import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styles from './draggableList.module.scss'

class DraggableList extends Component {
    static propTypes = {
        indexes: PropTypes.array.isRequired,
        children: PropTypes.node.isRequired,
        onMove: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)

        this.state = { indexes: props.indexes, lastMovedIndex: -1 }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.indexes !== this.props.indexes) {
            this.setState({ indexes: this.props.indexes })
        }
    }

    onMove = (srcIndex, targetIndex) => {
        this.setState((prevState) => {
            const indexes = prevState.indexes.slice()
            const tmpIndex = indexes[srcIndex]

            indexes[srcIndex] = indexes[targetIndex]
            indexes[targetIndex] = tmpIndex

            return { indexes, lastMovedIndex: targetIndex }
        })
    }

    onDrop = () => {
        if (this.state.lastMovedIndex !== -1) {
            this.props.onMove(this.state.indexes, this.state.lastMovedIndex)
            this.setState({ lastMovedIndex: -1 })
        }
    }

    getIndexes = props => React.Children.map(props.children, child => child.props.id)

    render() {
        const { children } = this.props
        const { indexes } = this.state

        const sortedChildren = React.Children
            .map(children, child => React.cloneElement(child, {
                move: this.onMove,
                drop: this.onDrop,
            }))
            // eslint-disable-next-line max-len
            .sort((firstChild, secondChild) => indexes.indexOf(firstChild.props.id) - indexes.indexOf(secondChild.props.id))

        return (
            <DndProvider backend={HTML5Backend}>
                <div className={styles.root}>
                    {sortedChildren.map((child, index) => React.cloneElement(child, { index }))}
                </div>
            </DndProvider>
        )
    }
}

export default DraggableList
