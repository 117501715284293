import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

export default class Spinner extends Component {
    static propTypes = {
        children: PropTypes.node,
        percentage: PropTypes.number,
        size: PropTypes.oneOf(['auto', 'small', 'medium', 'large', 'xLarge']),
        className: PropTypes.string,
        horizontal: PropTypes.bool,
    };

    static defaultProps = {
        size: 'medium',
        horizontal: false,
    };

    render() {
        const {
            children,
            percentage,
            size,
            className: extraClassName,
            horizontal,
        } = this.props

        const className = classnames(
            'c-spinner',
            `c-spinner-${size}`,
            extraClassName, {
                'c-spinner-horizontal': horizontal,
            }
        )

        return (
            <div className={className}>
                <FontAwesomeIcon className="c-spinner-icon" icon="faCircleNotch" spin />

                {typeof percentage === 'number' && (
                    <span className="c-spinner-percentage">{percentage}%</span>
                )}

                {children && <span className="c-spinner-content">{children}</span>}
            </div>
        )
    }
}
