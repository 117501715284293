export const cancel = 'componentPopupButtonCancel'
export const close = 'componentPopupButtonClose'
export const continueUploading = 'componentPopupButtonContinueUploading'
export const continueFlow = 'componentPopupButtonContinue'
export const ok = 'componentPopupButtonOk'
export const publish = 'componentPopupButtonPublish'
export const remove = 'componentPopupButtonRemove'
export const unpublish = 'componentPopupButtonUnpublish'
export const save = 'componentPopupButtonSave'
export const confirm = 'componentPopupButtonConfirm'
