import React from 'react'
import * as PropTypes from 'prop-types'

const ErrorOverlayMessage = ({ enabled, message, className }) => (enabled ? (
    <div className={`c-error-overlay-message ${className}`}>
        <span>{message}</span>
    </div>
) : null)

ErrorOverlayMessage.propTypes = {
    className: PropTypes.string,
    enabled: PropTypes.bool,
    message: PropTypes.string.isRequired,
}

ErrorOverlayMessage.defaultProps = {
    enabled: false,
}

export default ErrorOverlayMessage
