export type TrmType = 'BANK_ACCOUNT' | 'WIRE_ACCOUNT' | 'BANK_CARD' | 'PAYPAL_ACCOUNT' | 'PREPAID_CARD'

export type BankAccountParams = {
    bankAccountId: string
    branchId: string
}

export type BankCardParams = {
    cardType: 'PERSONALIZED' | 'VIRTUAL' | 'DEBIT'
    cardNumber: string // Masked
    cardBrand: 'VISA' | 'MASTERCARD'
    dateOfExpiry: string
}

export type PaypalParams = {
    email: string
}

export type TrmParams<T extends TrmType> = T extends 'BANK_ACCOUNT' | 'WIRE_ACCOUNT' ? BankAccountParams : (
    T extends 'BANK_CARD' | 'PREPAID_CARD' ? BankCardParams : PaypalParams
)

export type WalletAddress = {
    addressLine1: string
    city: string
    stateProvince: string
    country: string
    postalCode: string
}

interface TrmBase<T extends TrmType> {
    name: string // 'The Best Transfer Method'
    ref: string // trf-xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
    type: T // BANK_ACCOUNT, BANK_CARD, PAYPAL_ACCOUNT
    isDefault: boolean // whether this is the one that should be selected by the UI for withdraw
    createdAt: string // ISO date time
    currency: string // EUR, GBP, USD, etc
    country: string // GB, US, FR, etc
    fees: Record<string, {
        [currency: string]: string
    }> // todo Object for the country of the TRM containing fees keyed by currency
    params: TrmParams<T>
}

export type ITransferMethod =
    TrmBase<'BANK_ACCOUNT'> |
    TrmBase<'WIRE_ACCOUNT'> |
    TrmBase<'BANK_CARD'> |
    TrmBase<'PAYPAL_ACCOUNT'> |
    TrmBase<'PREPAID_CARD'>

interface IWalletAccount {
    pspRefId: string
    firstName: string
    lastName: string
    dateOfBirth: string
    minWithdrawal: number
    auth: {
        token: string
    }
    address: WalletAddress
    transferMethods: ITransferMethod[]
}

export class WalletAccount implements IWalletAccount {
    address: WalletAddress = {
        addressLine1: '',
        city: '',
        stateProvince: '',
        country: '',
        postalCode: '',
    }

    auth: { token: string } = { token: '' }
    dateOfBirth: string = ''
    firstName: string = ''
    lastName: string = ''
    minWithdrawal: number = 0
    pspRefId: string = ''
    transferMethods: ITransferMethod[] = []

    constructor(apiWallet: Partial<IWalletAccount>) {
        Object.assign(this as WalletAccount, apiWallet)
    }

    hasBeenCreated() {
        return !!this.pspRefId && this.pspRefId !== ''
    }

    hasTransferMethod() {
        return this.transferMethods && this.transferMethods.length > 0
    }

    getPspRef() {
        return this.pspRefId
    }

    getFirstName() {
        return this.firstName
    }

    getLastName() {
        return this.lastName
    }

    getDateOfBirth() {
        return this.dateOfBirth
    }

    getMinWithdrawalAmount() {
        return this.minWithdrawal
    }

    getAuth() {
        return this.auth
    }

    getAddress() {
        return this.address
    }

    getTransferMethods() {
        return this.transferMethods
    }
}
