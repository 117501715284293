import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'utilities/translate'
import { makeTracksSelector } from 'selectors/tracks'
import Field from 'components/Field/Field'
import Select from 'components/Select/Select'

class TrackSelectContainer extends Component {
    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        label: PropTypes.string,
        tracks: PropTypes.array.isRequired,
        error: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)
        this.selectRef = React.createRef()
    }

    setValue(value) {
        this.selectRef.current.setValue(value)
    }

    render() {
        const {
            label, tracks, value, error,
        } = this.props

        const options = tracks.map(track => ({
            value: track.id,
            label: track.title,
        }))

        return (
            <Field
                label={label}
                error={error || ''}
            >
                <Select
                    ref={this.selectRef}
                    value={value || ''}
                    options={options}
                    onChange={this.props.onChange}
                    placeholder={t('containerTrackSelectLabelSelect')}
                    emptyValueLabel={t('containerTrackSelectLabelSelect')}
                />
            </Field>
        )
    }
}

const mapStateToProps = (state, props) => ({
    tracks: makeTracksSelector(props.id)(state, props),
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackSelectContainer)
