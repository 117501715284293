import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import t from 'utilities/translate'
import Frame from 'components/Frame/Frame'
import Rejected from 'components/Release/Rejected'
import NotCompleted from 'components/Release/NotCompleted'
import Spinner from 'components/Spinner/Spinner'
import NonEditableRelease from 'components/Release/NonEditableRelease'
import * as statuses from 'constants/enums/ReleaseStatusTypes'

export default class Release extends Component {
    static propTypes = {
        release: PropTypes.object.isRequired,
        user: PropTypes.object,
        releaseType: PropTypes.object,
        steps: PropTypes.array.isRequired,
        artistCanCreateRelease: PropTypes.bool,
        onTakedown: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onSocialDeliveryRelease: PropTypes.func,
        onDownloadFiles: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            href: 'general',
        }
    }

    onClickPill = (href) => {
        this.setState({ href })
    }

    renderDeleteReleaseSpinner = (isRemoving) => {
        if (!isRemoving) {
            return null
        }
        return (
            <div className="c-release-deletion-wrapper">
                <Spinner
                    className="c-dropzone-spinner"
                    size="medium"
                >
                    {t('componentReleaseNotCompletedIsDeleting')}
                </Spinner>
            </div>
        )
    }

    displayInprogress = () => {
        const { release } = this.props

        return [statuses.RELEASE_STATUS_NOT_COMPLETED].indexOf(release.album.status) !== -1
            && !release.hasPendingPayments
            && !release.hasCompletedPayments
            && !release.isProcessing
    }

    displayRejected = () => {
        const { release } = this.props

        return release.album.isRejected && !release.isProcessing
    }

    renderReleaseView = () => {
        const {
            release,
            user,
            artistCanCreateRelease,
            steps,
            releaseType,
            onTakedown,
            onEdit,
            onDelete,
            onSocialDeliveryRelease,
            onDownloadFiles,
        } = this.props

        const { href } = this.state

        if (this.displayRejected()) {
            return (
                <Rejected
                    release={release}
                    user={user}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    isDisabled={!artistCanCreateRelease}
                />
            )
        }

        if (this.displayInprogress()) {
            return (
                <NotCompleted
                    release={release}
                    steps={steps}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    isDisabled={!artistCanCreateRelease}
                />
            )
        } else {
            return (
                <NonEditableRelease
                    release={release}
                    user={user}
                    releaseType={releaseType}
                    tab={href}
                    artistCanCreateRelease={artistCanCreateRelease}
                    onTakedown={onTakedown}
                    onClickPill={this.onClickPill}
                    onSocialDeliveryRelease={onSocialDeliveryRelease}
                    onDownloadFiles={onDownloadFiles}
                />
            )
        }
    }

    render() {
        const { release } = this.props

        const className = classnames(
            'c-release', {
                'c-release-is-deleting': release.isRemoving,
            }
        )

        return (
            <div className="c-release-wrapper">
                <Frame
                    borderImageId="sun-2"
                    contentTint="light"
                    className={className}
                    borderFlipVerticaly={release.id % 2 === 0}
                >
                    <header>
                        <div className="c-release-title">
                            <span>{release.album.title}</span>
                        </div>
                    </header>

                    {this.renderReleaseView()}

                </Frame>
                {this.renderDeleteReleaseSpinner(release.isRemoving)}
            </div>
        )
    }
}
