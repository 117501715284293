import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

/**
 * Modal
 *
 * Show stuff in a modal
 *
 * IframeHeight: 200
 *
 * Styleguide: components.modal
 */

export default class Modal extends Component {
    static propTypes = {
        centerContent: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        children: PropTypes.node,
        onClose: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        size: 'medium',
    };

    render() {
        const {
            centerContent,
            size,
            children,
            onClose,
            className,
        } = this.props

        const modalClassName = classnames(
            'c-modal-dialog',
            { centerContent },
            size
        )

        return (
            <div
                className="c-modal"
                onClick={this.onClose}
            >
                <div className={modalClassName}>
                    <FontAwesomeIcon icon="faTimes" fixedWidth onClick={onClose} className="closeBtn" />
                    <div className={className}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}
