import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import classnames from 'classnames'
import { Element } from 'react-scroll'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Popover from 'components/Popover/Popover'
import Spinner from 'components/Spinner/Spinner'
import scrollIntoView from 'utilities/scroll'

export default class Field extends Component {
    static propTypes = {
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        fieldName: PropTypes.string,
        isFetching: PropTypes.bool,
        helpText: PropTypes.string,
        error: PropTypes.any,
        warning: PropTypes.string,
        children: PropTypes.node,
        popover: PropTypes.object,
        className: PropTypes.string,
        elementName: PropTypes.string,
        short: PropTypes.bool,
    };

    constructor(props) {
        super(props)

        this.state = { isPopoverOpen: false }

        this.handleClickOnPopoverToggler = this.handleClickOnPopoverToggler.bind(this)
        this.handleClickOutsidePopover = this.handleClickOutsidePopover.bind(this)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutsidePopover, false)
    }

    handleClickOnPopoverToggler = () => {
        if (!this.state.isPopoverOpen) {
            document.addEventListener('click', this.handleClickOutsidePopover, false)
        } else {
            document.removeEventListener('click', this.handleClickOutsidePopover, false)
        }

        this.setState(prevState => ({ isPopoverOpen: !prevState.isPopoverOpen }))
    }

    handleClickOutsidePopover(e) {
        // eslint-disable-next-line react/no-find-dom-node
        const htmlNode = findDOMNode(this)
        if (!htmlNode.contains(e.target)) {
            this.handleClickOnPopoverToggler()
        }
    }

    scrollToIt = () => {
        scrollIntoView(this.elementName)
    }

    render() {
        const { isPopoverOpen } = this.state

        const {
            fieldName,
            label,
            helpText,
            error,
            warning,
            children,
            popover,
            isFetching,
            className,
            elementName,
            short,
        } = this.props

        this.elementName = this.props.elementName || `field.${Date.now()}`

        const fieldClassName = classnames(
            'c-field',
            className,
            {
                error: !!error,
                warning: !!warning,
                short: !!short,
            }
        )

        const field = (
            <div
                className={fieldClassName}
                data-name={fieldName}
            >
                {elementName && <Element name={elementName} />}
                {label
                    && (
                        <label className="c-field-label">
                            <span>
                                {label}

                                {popover && (
                                    <span className="icon" onClick={this.handleClickOnPopoverToggler}>
                                        <FontAwesomeIcon icon="faQuestion" mask="faSquareFull" transform="shrink-6" />
                                    </span>
                                )}
                            </span>

                            {isFetching && <Spinner size="auto" />}
                        </label>
                    )}

                {children}

                {error
                    && <p className="msg-error">{error}</p>}

                {(!error && warning)
                    && <p className="msg-warning">{warning}</p>}

                {(!error && !warning && helpText)
                    && <p className="msg-helptext">{helpText}</p>}
            </div>
        )

        if (isPopoverOpen) {
            return (
                <Popover {...popover} onToggle={this.handleClickOnPopoverToggler} ref={(node) => { this.node = node }}>
                    {field}
                </Popover>
            )
        }

        return field
    }
}
