import { connect, ConnectedProps } from 'react-redux'

import {
    getSalesOverview,
    selectOverviewYear,
} from 'actions/SaleActions'

import {
    getOverviewByYear,
    getCalendarOverview,
    getSelectedOverviewYear,
    getMostRecentOverviewActivityYear,
    salesOverviewSelector,
} from 'selectors/sales'

import SalesReportOverview from 'components/SalesReportOverview/SalesReportOverview'
import { AppStoreDispatch, AppStoreState } from 'store/store-types'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const mapStateToProps = (state: AppStoreState) => {
    const selectedYear = getSelectedOverviewYear(state) || getMostRecentOverviewActivityYear(state)
    const salesOverview = salesOverviewSelector(state)

    return {
        isLoading: state.sales.isFetchingOverview,
        failedFetchingOverview: state.sales.failedFetchingOverview,
        someOverviewDataExists: salesOverview.length > 0,
        calendarDataSummary: getCalendarOverview()(state),
        data: getOverviewByYear(selectedYear)(state),
        selectedYear,
    }
}

const mapDispatchToProps = (dispatch: AppStoreDispatch) => ({
    getData: () => dispatch(getSalesOverview()),
    changeYear: (year: number) => dispatch(selectOverviewYear(year)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export type SalesReportOverviewContainerProps = ConnectedProps<typeof connector> & RouteComponentProps<any>
export default withRouter(connector(SalesReportOverview))
