import moment from 'moment'

import { earliestReleaseDateDays } from 'constants/enums/ReleaseEnums'
import { getRestrictedMinReleaseDate } from 'modules/releaseDate'
import validator, {
    isDifferentThan,
    isEmpty,
    matchRegex,
    doesNotMatchRegex,
    maxLength,
    required,
    isDateAfter,
    isDateSameOrAfter,
    hasAlbumTitleMetadataRequiredForRegion,
    hasAlbumArtistNameMetadataRequiredForRegion,
} from './rules'

const simpleErrorRules = {
    'album.title': [
        isEmpty('album.Title600'),
        maxLength(255, 'album.Title602'),
        isDifferentThan('Untitled', 'ruleAlbumMessageNotUntitled'),
        matchRegex(/\s{2,}/, 'album.Title605'),
        hasAlbumTitleMetadataRequiredForRegion('releaseFlowReleaseTranslationError'),
    ],
    'album.artists': [
        isEmpty('ruleAlbumMessageArtists'),
        hasAlbumArtistNameMetadataRequiredForRegion('releaseFlowArtistTranslationError'),
    ],
    'album.genreId': [
        required('ruleAlbumMessageGenre'),
    ],
    'album.languageId': [
        required('ruleAlbumMessageLanguage'),
    ],
    'album.releaseDate': [
        value => (value
            ? isDateAfter(moment().subtract(1, 'd'), 'ruleAlbumReleaseDateInThePast')(value)
            : undefined),
        (value) => {
            const restrictedMinDate = getRestrictedMinReleaseDate()
            if (!value) {
                return undefined
            }

            if (restrictedMinDate) {
                return isDateSameOrAfter(restrictedMinDate.startOf('day'), 'ruleAlbumReleaseDateNotInRange')(value)
            } else {
                return isDateAfter(moment().add(earliestReleaseDateDays, 'd'), 'ruleAlbumReleaseDateTooSoon')(value)
            }
        },
    ],
    'album.upc': [
        doesNotMatchRegex(/^([0-9]{12,13})?$/, 'DSP_PRODUCT_ERROR_UPC_INVALID'),
    ],
}

const warningRules = {}

export const getErrors = validator.bind(null, simpleErrorRules)
export const getWarnings = validator.bind(null, warningRules)
