import React, { Component } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import {
    de,
    fr,
    es,
    ja,
    enGB,
    enUS,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'date-fns/locale'
import { getLanguage, getFullLanguage } from 'utilities/translate'
import FontAwesomeIcon from 'components/FontAwesomeIcon'

// DatePicker doesn't use moment it uses date-fns: https://date-fns.org/v2.4.1/docs/format
// If you are adding a new language, import the locale file above and call the
// registerLocale() function in the constructor
export default class extends Component {
    static defaultProps = {
        dateFormat: 'PPP', // Long localized date see: https://date-fns.org/v2.4.1/docs/format
        fixedHeight: true,
    }

    constructor(props) {
        super(props)

        registerLocale('de', de)
        registerLocale('fr', fr)
        registerLocale('es', es)
        registerLocale('ja', ja)
        registerLocale('enUS', enUS)
        registerLocale('enGB', enGB)
    }

    render() {
        const language = getLanguage()
        const fullLanguage = getFullLanguage()

        const locale = language === 'en' && (fullLanguage === 'en_US' || fullLanguage === 'en_GB')
            ? fullLanguage.replace('_', '')
            : language

        return (
            <div className="c-date-picker">
                <ReactDatePicker
                    locale={locale}
                    ref={(node) => { this.datepicker = node }}
                    className="picker"
                    {...this.props}
                />

                <div
                    className="icon"
                    onClick={() => {
                        this.datepicker.setOpen(true)
                    }}
                >
                    <FontAwesomeIcon icon="faTh" />
                </div>
            </div>
        )
    }
}
