import * as types from 'constants/SocialActionTypes'
import { CALL_API } from 'middleware/api'

// VISIBILITY

export function toggleVisibility(metric, site) {
    return (dispatch, getState) => {
        const { isVisible } = getState().socialTrends[metric].sites[site]

        return dispatch({
            type: types.TOGGLE_SOCIAL_TRENDS_VISIBILITY,
            payload: !isVisible,
            meta: { metric, site },
        })
    }
}

export function togglePlaysChartVisibility(site) {
    return dispatch => dispatch(toggleVisibility('plays', site))
}

export function toggleFansChartVisibility(site) {
    return dispatch => dispatch(toggleVisibility('fans', site))
}

function getSocialTrends(artistId, metric, filter = 'week') {
    const filterOptions = { days: filter }

    return {
        [CALL_API]: {
            types: [
                types.FETCH_SOCIAL_TRENDS_REQUEST,
                types.FETCH_SOCIAL_TRENDS_SUCCESS,
                types.FETCH_SOCIAL_TRENDS_FAILURE,
            ],
            payload: { endpoint: `artists/${artistId}/social/${metric}/${filter}` },
            meta: { metric, filter: filterOptions },
        },
    }
}

export function fetchSocialTrends(metrics = [], filter = 'week') {
    return (dispatch, getState) => {
        const { artist } = getState().users.user
        metrics.forEach(metric => dispatch(getSocialTrends(artist.id, metric, filter)))
    }
}
