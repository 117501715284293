import envProd from './env.prod'
import envTest from './env.tests'
import envCi from './env.ci'
import envDev from './env.dev'

if (process.env.REACT_APP_USE_CI_ENV) {
    window.env = envCi
} else if (process.env.NODE_ENV === 'production') {
    window.env = envProd
} else if (process.env.NODE_ENV === 'test') {
    window.env = envTest
} else {
    window.env = envDev
}
