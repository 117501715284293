import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class ScrollableContent extends Component {
    wrapperRef = null

    contentRef = null

    static propTypes = {
        children: PropTypes.node.isRequired,
        shadow: PropTypes.bool,
        disabled: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            isScrollable: true,
        }
    }

    componentDidMount() {
        this.recalculateIsScrollable()
        window.addEventListener('resize', this.recalculateIsScrollable)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.recalculateIsScrollable)
    }

    recalculateIsScrollable = () => {
        const isScrollable = this.contentRef.clientWidth < this.contentRef.scrollWidth
        this.setState({
            isScrollable,
        })
    }

    setContentRef = (el) => {
        this.contentRef = el
    }

    setWrapperRef = (el) => {
        this.wrapperRef = el
    }

    renderShadow = (jsx) => {
        const { shadow } = this.props
        const { isScrollable } = this.state
        const forceShadow = (typeof shadow === 'boolean') ? shadow : true
        return (
            (forceShadow && isScrollable)
                ? <div className="c-scrollablecontent-shadow">{jsx}</div>
                : <div className="c-scrollablecontent-no-shadow">{jsx}</div>
        )
    }

    render() {
        const { children, disabled } = this.props
        const isEnabled = (typeof disabled === 'boolean') ? disabled : true

        return isEnabled ? (
            <div ref={this.setWrapperRef} className="c-scrollablecontent-wrapper">
                { this.renderShadow(
                    <div ref={this.setContentRef} className="c-scrollablecontent-content">
                        {children}
                    </div>
                ) }
            </div>
        ) : children
    }
}

export default ScrollableContent
