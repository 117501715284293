import React from 'react'

import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Background from 'components/Frame/Background'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import Button from 'components/Button/Button'
import * as MessageTypes from 'constants/MessageTypes'
import { removeMessage } from 'actions/MessageActions'
import VerificationMessage from 'components/Messages/VerificationMessage'

MessageBanner.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node,
    hideMessage: PropTypes.func.isRequired,
}

function getMessage(id, children) {
    let message
    switch (id) {
        case MessageTypes.VERIFY_EMAIL_MESSAGE:
            message = (
                <VerificationMessage />
            )
            break
        default:
            message = children
    }
    return message
}

function MessageBanner(props) {
    const {
        id,
        children,
    } = props

    const handleClick = () => {
        props.hideMessage(props.id)
    }

    return (
        <div className="c-message-banner">
            <Background imageId="sky-1">
                <div className="c-message-banner-inner">
                    {getMessage(id, children)}
                </div>
                <Button
                    className="c-message-banner-close"
                    isIcon
                    onClick={handleClick}
                >
                    <FontAwesomeIcon icon="faTimes" />
                </Button>
            </Background>
        </div>
    )
}

const mapDispatchToProps = {
    hideMessage: removeMessage,
}

export default connect(null, mapDispatchToProps)(MessageBanner)
