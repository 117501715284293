import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import TimebarConfig from './TimebarConfig'

class TimebarChannelSegment extends Component {
    static propTypes = {
        seg: PropTypes.object.isRequired,
        scope: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            config: TimebarConfig,
        }
    }

    formatTs = (ts, f) => moment(ts, 'x').utc().format(f)

    renderLabel = (seg, labelStyle) => {
        const { scope } = this.props

        if (seg.run) {
            const runStart = this.formatTs(
                seg.run.startsWith ? seg.run.startsWith.run.start : seg.run.start,
                scope.labelFormat
            )

            // Label dates are From (inclusive) - Until (exclusive)
            // example 01/01 -> 31/01 will display 1st Jan -> 1st Feb
            const runEnd = moment((seg.run.endsWith ? seg.run.endsWith.run.end : seg.run.end), 'x')
                .utc()
                .add(1, 'days')
                .format(scope.labelFormat)

            const label = (
                <label style={labelStyle}>
                    {runStart} - {runEnd}
                </label>
            )

            if (seg.run.isStart) {
                return label
            }
        }

        return null
    }

    applyAnalogSegmentStyles = (seg) => {
        const { config } = this.state

        const classes = classnames('c-timebar-channels-bar-seg', `c-timebar-axis-count-${seg.range.length}`, {
            'c-timebar-channels-bar-seg-init': seg.run && seg.run.isStart,
        })

        const segWidthAsPercentageOfRange = (100 / seg.range.length)

        const segRunMargin = {
            left: 0,
            right: 0,
        }

        const precision = 15
        const getDiffAsPercentOfSegment = (diff) => {
            const asPercentOfSegmentWidth = parseFloat((diff / (seg.segment.length / 100)).toFixed(precision))
            return parseFloat(asPercentOfSegmentWidth.toFixed(precision))
        }

        if (config.ANALOG_SEGMENT_WIDTHS && seg.run) {
            if (seg.run.isStart && (seg.run.start > seg.segment.start)) {
                segRunMargin.left = getDiffAsPercentOfSegment(seg.run.start - seg.segment.start)
            }

            if (seg.run.isEnd && (seg.segment.end > seg.run.end)) {
                segRunMargin.right = getDiffAsPercentOfSegment(seg.segment.end - seg.run.end)
            }
        }

        const axisSegmentStyle = {
            width: `${segWidthAsPercentageOfRange}%`,
        }

        const fillStyle = {
            fill: {
                marginLeft: segRunMargin.left < 1 ? 0 : `${segRunMargin.left}%`,
                marginRight: segRunMargin.right < 1 ? 0 : `${segRunMargin.right}%`,
            },
            header: {
                marginLeft: segRunMargin.left < 1 ? 0 : `${segRunMargin.left}%`,
                paddingLeft: '10px',
            },
            label: {
                marginLeft: segRunMargin.left < 1 ? 0 : `${segRunMargin.left}%`,
                paddingLeft: '0',
            },
        }

        return [
            classes,
            axisSegmentStyle,
            fillStyle,
        ]
    }

    render() {
        const { seg } = this.props

        const [classes, axisSegmentStyle, fillStyle] = this.applyAnalogSegmentStyles(seg)

        const segKey = `${seg.channel}_${seg.segment.start}`

        const label = seg.run && this.renderLabel(seg, fillStyle.label)

        const isRunner = jsx => ((seg.run) ? jsx : null)

        return (
            <div
                className={classes}
                key={segKey}
                style={axisSegmentStyle}
            >
                {isRunner(
                    <div
                        className="c-timebar-channels-bar-seg-fill"
                        style={fillStyle.fill}
                    />
                )}

                {seg.run && seg.run.isStart && isRunner(
                    <header
                        style={fillStyle.header}
                    >
                        {seg.channel}
                    </header>
                )}

                {label}
            </div>
        )
    }
}

export default TimebarChannelSegment
