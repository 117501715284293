import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Alert from 'components/Alert/Alert'
import SocialDisconnected from 'components/Social/SocialDisconnected'
import TrendsChart from 'components/TrendsChart/TrendsChart'
import TrendsSocialFilter from 'components/TrendsSocialFilter/TrendsSocialFilter'

import t from 'utilities/translate'
import { getDisconnectedServicesSelector } from 'selectors/services'
import {
    fetchSocialTrends,
    toggleFansChartVisibility,
    togglePlaysChartVisibility,
} from 'actions/SocialTrendsActions'
import {
    siteIconsSelector, socialTrendsFansSelector,
    socialTrendsFilterSelector, socialTrendsPlaysSelector,
} from 'selectors/socialTrends'
import Divider from 'components/Divider/Divider'

class Social extends Component {
    static propTypes = {
        hasFans: PropTypes.bool.isRequired,
        hasPlays: PropTypes.bool.isRequired,
        fans: PropTypes.object.isRequired,
        isFansLoading: PropTypes.bool.isRequired,
        failedFetchingFans: PropTypes.bool.isRequired,
        fanIcons: PropTypes.array.isRequired,
        plays: PropTypes.object.isRequired,
        playIcons: PropTypes.array.isRequired,
        isPlaysLoading: PropTypes.bool.isRequired,
        failedFetchingPlays: PropTypes.bool.isRequired,
        filterOptions: PropTypes.object.isRequired,
        fetchSocialTrends: PropTypes.func.isRequired,
        toggleFansChartVisibility: PropTypes.func.isRequired,
        togglePlaysChartVisibility: PropTypes.func.isRequired,
        disconnectedServices: PropTypes.array,
    };

    componentDidMount() {
        this.props.fetchSocialTrends(['fans', 'plays'])
    }

    getExampleData = data => data.map(item => ({
        ...item,
        data: item.data.map(point => ({
            ...point,
            value: Math.floor(Math.random() * (1000 - 100 + 1) + 100),
        })),
    }))

    render() {
        const {
            hasPlays,
            hasFans,
            disconnectedServices,
            fans,
            plays,
            fanIcons,
            playIcons,
            filterOptions,
            isFansLoading,
            isPlaysLoading,
            failedFetchingFans,
            failedFetchingPlays,
        } = this.props

        const socialSettingsAnchor = {
            anchor: 'social-accounts',
        }

        return (
            <div className="c-trends-social">
                {disconnectedServices.length
                    ? <SocialDisconnected /> : ''}

                {(hasFans || hasPlays)
                    && (
                        <Alert type="info">
                            <p><strong>{t('viewSocialAlertTextFirst')}</strong></p>
                            <p>{t('viewSocialAlertTextSecond')}&nbsp;
                                <Link to={{
                                    pathname: '/settings',
                                    state: { socialSettingsAnchor },
                                }}
                                >
                                    {t('viewSocialAlertTextThird')}
                                </Link>
                            </p>
                            <p>{t('viewSocialAlertTextFourth')}</p>
                        </Alert>
                    )}
                {!hasFans && !hasPlays
                    && (
                        <Alert type="info">
                            <p><strong>{t('viewSocialPanelHeading')}</strong></p>
                            <p>{t('viewSocialPanelText')}&nbsp;
                                <Link to={{
                                    pathname: '/settings',
                                    state: { socialSettingsAnchor },
                                }}
                                >
                                    {t('viewSocialPanelLink')}
                                </Link>
                            </p>
                        </Alert>
                    )}

                <TrendsSocialFilter
                    filterTrends={this.props.fetchSocialTrends}
                    filterOptions={filterOptions}
                    isLoading={isFansLoading || isPlaysLoading}
                    failedFetching={(failedFetchingFans && failedFetchingPlays) || (!hasFans && !hasPlays)}
                />

                <Divider imageId="spiral-2" />

                <TrendsChart
                    data={(!hasFans && this.getExampleData(fans.data)) || fans.data}
                    labels={fans.labels}
                    toggleIcons={fanIcons}
                    filterOptions={filterOptions}
                    toggleVisibility={this.props.toggleFansChartVisibility}
                    isLoading={isFansLoading}
                    isExampleData={!hasFans}
                    failedFetching={failedFetchingFans}
                    title={t('fansTrendsHeading')}
                />

                <Divider imageId="sun-2" />

                <TrendsChart
                    data={(!hasPlays && this.getExampleData(plays.data)) || plays.data}
                    labels={plays.labels}
                    toggleIcons={playIcons}
                    filterOptions={filterOptions}
                    toggleVisibility={this.props.togglePlaysChartVisibility}
                    isLoading={isPlaysLoading}
                    isExampleData={!hasPlays}
                    failedFetching={failedFetchingPlays}
                    title={t('playsTrendsHeading')}
                />
            </div>
        )
    }
}

const socialTrendsFans = socialTrendsFansSelector
const siteIconsFans = siteIconsSelector('fans')
const socialTrendsPlays = socialTrendsPlaysSelector
const siteIconsPlays = siteIconsSelector('plays')

const mapStateToProps = state => ({
    hasFans: !!['facebook', 'twitter', 'soundcloud', 'youtube', 'instagram']
        .find(site => state.socialTrends.fans.sites[site].data.length > 0),
    hasPlays: !!['soundcloud', 'youtube'].find(site => state.socialTrends.plays.sites[site].data.length > 0),
    disconnectedServices: getDisconnectedServicesSelector(state),
    fans: socialTrendsFans(state),
    isFansLoading: state.socialTrends.fans.isFetching,
    failedFetchingFans: state.socialTrends.fans.failedFetching,
    fanIcons: siteIconsFans(state),
    plays: socialTrendsPlays(state),
    playIcons: siteIconsPlays(state),
    isPlaysLoading: state.socialTrends.plays.isFetching,
    failedFetchingPlays: state.socialTrends.plays.failedFetching,
    filterOptions: socialTrendsFilterSelector(state),
})

const mapDispatchToProps = {
    fetchSocialTrends, toggleFansChartVisibility, togglePlaysChartVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(Social)
