import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { anyStepsUploadingSelector, getTotalErrorsCount, makeStepsSelector } from 'selectors/steps'
import { orderedTracksSelector } from 'selectors/tracks'

import { validateTrack } from 'actions/TrackActions'
import { validateSettings, validatePackage } from 'actions/AlbumActions'
import { addPopup } from 'actions/PopupActions'
import {
    syncValidate as syncValidateTrackForm,
} from 'modules/tracks'
import { getTracksFormName } from 'modules/common/tracks'
import { getAlbumSettingFormName } from 'modules/common/album'
import {
    checkTitle,
    syncValidate as syncValidateAlbumForm,
} from 'modules/album'
import { getAlbumReleaseFormName } from 'modules/release'

import Steps from 'components/Steps/Steps'
import { getPopupDataWarnEmptyProductTracks } from 'utilities/popups'
import ReleaseFlowErrorsModal from 'components/common/modals/ReleaseFlowErrorsModal'
import { queueModal } from 'actions/ModalActions'

const mapStateToProps = (state, ownProps) => {
    const stepsSelector = makeStepsSelector(ownProps.release.id, ownProps.release.providerId)
    const uploadingSelector = anyStepsUploadingSelector(ownProps)
    const errorCount = getTotalErrorsCount(state)

    return {
        steps: stepsSelector(state, ownProps),
        tracks: orderedTracksSelector(state, ownProps.release.providerId),
        isUploadingReleaseContent: uploadingSelector(state),
        totalReleaseFlowErrors: errorCount,
    }
}

const mapDispatchToProps = dispatch => ({
    checkReleaseTitle: albumId => dispatch(checkTitle(albumId)),
    openTracksRemainingWarning: (releaseTypeName, releaseTypeMax, numUsed, onContinue, onReEdit) => {
        dispatch(addPopup(
            getPopupDataWarnEmptyProductTracks(releaseTypeName, releaseTypeMax, numUsed, onContinue, onReEdit)
        ))
    },
    validateTrack: (id, body) => {
        dispatch(validateTrack(id, body))
        dispatch(syncValidateTrackForm(getTracksFormName({ id })))
    },
    validateSettings: (id, fields) => {
        dispatch(validateSettings(id, fields))
        dispatch(syncValidateAlbumForm(getAlbumSettingFormName()))
        dispatch(syncValidateAlbumForm(getAlbumReleaseFormName()))
    },
    validatePackage: (id, fields, fieldsToValidate) => {
        dispatch(validatePackage(id, fields, fieldsToValidate))
        dispatch(syncValidateAlbumForm(getAlbumSettingFormName()))
        dispatch(syncValidateAlbumForm(getAlbumReleaseFormName()))
    },
    showErrorModal: (errorCount, firstIncompleteStep) => dispatch(queueModal(
        'release-review-error-details',
        ReleaseFlowErrorsModal,
        {
            errorCount,
            editReleaseHref: firstIncompleteStep,
        },
        { useBackdrop: true, clickBackdropToClose: true }
    )),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Steps))
