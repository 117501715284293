import * as types from 'constants/TrackVersionActionTypes'
import { CALL_API } from 'middleware/api'

// FETCH

function getTrackVersions() {
    return {
        [CALL_API]: {
            types: [
                types.FETCH_TRACK_VERSIONS_REQUEST,
                types.FETCH_TRACK_VERSIONS_SUCCESS,
                types.FETCH_TRACK_VERSIONS_FAILURE,
            ],
            payload: {
                endpoint: 'refdata/trackversions',
            },
        },
    }
}

export function fetchTrackVersions() {
    return (dispatch, getState) => getState().versions.versions.length === 0
        && !getState().versions.isFetching
        && dispatch(getTrackVersions())
}
