import { createSelector } from 'reselect'

export const isUpdating = state => state.crmPreferences.isUpdating
export const isFetchingMailingLists = state => state.crmPreferences.isFetchingMailingLists
export const isFetchingUserSubscriptions = state => state.crmPreferences.isFetchingUserSubscriptions
export const getMailingLists = state => state.crmPreferences.mailingLists

export const hasFailedToUpdate = state => state.crmPreferences.failedToUpdate
export const hasFailedToFetch = state => state.crmPreferences.failedFetchingMailingLists
    || state.crmPreferences.failedFetchingUserSubscriptions

export const getUserSubscriptions = createSelector(
    getMailingLists,
    state => state.crmPreferences.userSubscriptions,
    (mailingLists, userSubscriptions) => (mailingLists || []).map((ml) => {
        const subscribedMailingList = (userSubscriptions || [])
            .find(subscription => subscription.providerId === ml.providerId)

        const isDoubleOptInPending = subscribedMailingList ? subscribedMailingList.isDoubleOptInPending : null
        const isSubscribed = subscribedMailingList !== undefined

        return ml.merge({
            subscribed: isSubscribed,
            isDoubleOptInPending,
        })
    })
)
